package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreditClientOrder(
    @SerialName("id")
    val id: String?,
    @SerialName("invoiceId")
    val invoiceId: String?,
    @SerialName("total")
    val total: Float?,
    @SerialName("creditClient")
    val creditClient: CreditClient?,
    @SerialName("cashRegister")
    val cashRegister: CashRegister?,
    @SerialName("items")
    val items: List<OrderItem>?,
    @SerialName("__typename")
    val typename: String?
)