package org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class BillSearchStatisticDataItem(
    @SerialName("cancellationUserId")
    val cancellationUserId: String?,
    @SerialName("cancellationUserName")
    val cancellationUserName: String?,
    @SerialName("cardName")
    val cardName: String?,
    @SerialName("cardNumber")
    val cardNumber: String?,
    @SerialName("cashRegisterId")
    val cashRegisterId: String?,
    @SerialName("cashRegisterName")
    val cashRegisterName: String?,
    @SerialName("dateTime")
    val dateTime: String?,
    @SerialName("groupId")
    val groupId: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("isCancelled")
    val isCancelled: Boolean?,
    @SerialName("items")
    val items: List<Item?>?,
    @SerialName("loyaltycardId")
    val loyaltycardId: String?,
    @SerialName("number")
    val number: String?,
    @SerialName("timer")
    val timer: Int?,
    @SerialName("total")
    val total: Double?,
    @SerialName("totalWithoutCancellation")
    val totalWithoutCancellation: Double?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("userName")
    val userName: String?
)