package org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ListShopItem(
    @SerialName("cashRegisters")
    val cashRegisters: List<CashRegister?>?=null,
    @SerialName("id")
    val id: String?=null,
    @SerialName("name")
    val name: String?=null,
    @SerialName("regionId")
    val regionId: String?=null,
    @SerialName("__typename")
    val typename: String?=null
)