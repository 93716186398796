package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.filtered_transaction


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ListLoyaltyCardItems(
    @SerialName("hasNextPage")
    val hasNextPage: Boolean?,
    @SerialName("items")
    val items: List<FilteredLoyaltyCardItem?>?,
    @SerialName("total")
    val total: Int?,
    @SerialName("__typename")
    val typename: String?
)