package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_reports

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics.ShopStaticItem
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.DashboardDialogs
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.DashboardFilter
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_reports.component.DashboardReports
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.mvi.DashboardEvent
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.mvi.DashboardState

@Composable
fun DashboardList(
    windowSizeMode: WindowSizeModeEnum,
    state: DashboardState,
    event:(DashboardEvent)->Unit,
) {
    LaunchedEffect(Unit){
        event(DashboardEvent.GetDashboardConfigs)
    }
    LaunchedEffect(state.listShop){
        event(DashboardEvent.InitShopStatics)
    }


    Column(
        modifier = Modifier
            .scrollBehavior(ScrollBehavior.Smooth)
            .fillMaxSize()
            .padding(
                topBottom = Dimen.normalPadding,
                leftRight = Dimen.largePadding,
            )
    )
    {
        MediumContentTitle(
            title = Strings.allRegions,
        )

        DashboardFilter(
            windowSizeMode = windowSizeMode,
            listShop = state.listShop,
            selectedDate = state.selectedDate,
            setCalendarStatus = {
                event(DashboardEvent.SetCalendarStatus(it))
            },
            filterBasedOnRegion = {
                event(DashboardEvent.FilterBasedOnRegion(it))
            }

        )

        DashboardReports(
            windowSizeMode = windowSizeMode,
            listShop =state.listShop,
            shopStaticsData =
                if (state.selectedShopId == null) {
                    state.shopStaticsData
                } else {
                    state.shopStaticsData?.filter { it.shopId == state.selectedShopId }
                },
            currencyType = state.currencyType ?: "",
            timeType = state.timeType ?: "",
            onItemClick = { item: ShopStaticItem, itemName:String->
                event(DashboardEvent.OnDashboardItemClick(
                    item=item,
                    itemName=itemName
                ))
            }
        )


    }

    DashboardDialogs(
        windowSizeMode=windowSizeMode,
        showCalendarDialog = state.showCalendarDialog,
        selectedDate=state.selectedDate,
        calendarData = state.calendarData,
        onSelectDay = {
            event(DashboardEvent.GetShopListItems(it))
        },
        onBackgroundClick = {
            event(DashboardEvent.SetCalendarStatus(false))
        },
        closeCalendarDialog = {
            event(DashboardEvent.SetCalendarStatus(false))
        }
    )

}