package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.product_general_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.GetProduct

@Composable
fun ProductPriceContent(
    windowSizeMode: WindowSizeModeEnum,
    selectedProduct: GetProduct?,
    profileCountryId: String,
    selectedVat: String?,
    selectedVat2: String?,
    updatePrice: (List<Double?>) -> Unit,
    updatePriceInCafe: (List<Double?>) -> Unit,
    updateVatCode: (String) -> Unit,
    updateVatCode2: (String) -> Unit
) {
    Column(
        modifier = Modifier
            .margin(
                top = Dimen.largePadding)
    )
    {
        SpanText(
            text = Strings.prices,
            modifier = Modifier
                .color(Theme.Gray3E.rgb)
                .fontFamily(FontFamily.Jakarta)
                .fontWeight(FontWeight.Medium)
                .fontSize(FontSizes.extraExtraLargeFontSize)
                .margin(bottom = Dimen.extraSmallPadding)
        )

        if (windowSizeMode == WindowSizeModeEnum.Desktop) {
            Column(
                modifier = Modifier,
                horizontalAlignment = Alignment.Start
            )
            {
                Price1(
                    price = selectedProduct?.price?.get(0),
                    selectedVat = selectedVat,
                    windowSizeMode = windowSizeMode,
                    countryId = profileCountryId,
                    updatePrice = {
                        updatePrice(it)
                    },
                    updateVatCode = {
                        updateVatCode(it)
                    },
                )

                Price2(
                    selectedVat2 = selectedVat2,
                    windowSizeMode=windowSizeMode,
                    priceInCafe = selectedProduct?.priceInCafe?.get(0),
                    countryId = profileCountryId,
                    updatePriceInCafe = {
                        updatePriceInCafe(it)
                    },
                    updateVatCode2 = {
                        updateVatCode2(it)
                    }
                )

            }
        } else {
            Column(
                modifier = Modifier
                    .margin(top = Dimen.normalPadding),
            )
            {
                Price1(
                    price = selectedProduct?.price?.get(0),
                    windowSizeMode=windowSizeMode,
                    selectedVat = selectedVat,
                    countryId = profileCountryId,
                    updatePrice = {
                        updatePrice(it)
                    },
                    updateVatCode = {
                        updateVatCode(it)
                    },
                )
                if (windowSizeMode==WindowSizeModeEnum.PHONE)
                    Box(
                        modifier = Modifier.height(Dimen.mediumPadding)
                    )

                Price2(
                    selectedVat2 = selectedVat2,
                    priceInCafe = selectedProduct?.priceInCafe?.get(0),
                    countryId = profileCountryId,
                    windowSizeMode = windowSizeMode,
                    updatePriceInCafe = {
                        updatePriceInCafe(it)
                    },
                    updateVatCode2 = {
                        updateVatCode2(it)
                    }
                )
            }
        }

    }
}