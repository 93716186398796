package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.apple

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config.GetVirtualCardConfig
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.CardInfo

@Composable
fun AppleCardInfoPreview(
    info: GetVirtualCardConfig?
)
{
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(Dimen.mediumPadding),
    )
    {
        CardInfo(
            title = Strings.qrCode,
            value = Strings.fakeQRCode,
        )
        CardInfo(
            title = Strings.description,
            value = info?.accountIdLabel?:"",
        )
        CardInfo(
            title = Strings.discountTier ,
            value = info?.rewardsTier?:"",
        )
        CardInfo(
            title = Strings.specialDiscountTier ,
            value = info?.secondaryRewardsTier?:"",
        )


        CardInfo(
            title = Strings.phone,
            value = "${info?.linkPhoneUri?.link}" +
                    " ${info?.linkPhoneUri?.description}",
        )
        CardInfo(
            title = Strings.latestPurchasesAndMore,
            value=Strings.fakeLatestPurchasesAndMore,
        )




    }


}