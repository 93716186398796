package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.mvi

import org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics.ShopStaticItem
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel

sealed class DashboardEvent{

    


    data object OnDetailBackClick:DashboardEvent()

    data class OnDashboardItemClick(
        val item: ShopStaticItem,
        val itemName:String,
    ):DashboardEvent()

    data object InitShopStatics:DashboardEvent()

    data object GetShopStatics:DashboardEvent()
    
    data class GetOtherDaysCustomerFrequency(val selectedDate: MonthModel)
        :DashboardEvent()

    data class GetShopListItems(val selectedDate: MonthModel): DashboardEvent()

    data class SetCalendarStatus(val status:Boolean): DashboardEvent()


    data object GetDashboardConfigs:DashboardEvent()



    data class SetCompareChartDialogStatus(val status:Boolean)
        :DashboardEvent()

    data class FilterBasedOnRegion(val id:String?)
        :DashboardEvent()




}