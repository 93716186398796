package org.hs_soft.runmynesto.data.api_datasource.user

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.user.single_user.SingleUserModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.SingleUserRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class SingleUserRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): SingleUserRepository {

    override suspend fun getSingleUser(id: String): SingleUserModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
        query getUser {
          cashAssist {
            getUser(id: $id) {
              ${ApiUtils.Field.id}
              ${ApiUtils.Field.isActive}
              ${ApiUtils.Field.name}
              ${ApiUtils.Field.printName}
              ${ApiUtils.Field.password}
              ${ApiUtils.Field.shortName}
              ${ApiUtils.Field.isManager}
              ${ApiUtils.Field.isTimeTracking}
              ${ApiUtils.Field.isDayFinStatements}
              ${ApiUtils.Field.isFinStatements} 
              ${ApiUtils.Field.cancellationPermissions}
              ${ApiUtils.Field.isAdmin}
              ${ApiUtils.Field.waiterKey}
              ${ApiUtils.Field.isManager}
              ${ApiUtils.Field.isViewSales}
              ${ApiUtils.Field.cashRegisterId}
              
              
              img{
                ${ApiUtils.Field.url}
              }
            }
          }
        }
    """.trimIndent())
    }


        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<SingleUserModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }



    }

}