package org.hs_soft.runmynesto.domain.usecase.product.tab_checkout

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_checkout.update_checkout.UpdateProductCheckoutModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_checkout.UpdateProductCheckoutRepository


class UpdateProductCheckoutUseCase(
    private val repository: UpdateProductCheckoutRepository
) {
    suspend operator fun invoke(
        userId:String,
        dataValue: MutableSet<String>,
    ): UpdateProductCheckoutModel {
        return repository.updateProductCheckout(
            userId=userId,
            dataValue=dataValue
        )
    }
}