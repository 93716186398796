package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.series_tab.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail.LoyaltyCardDetail
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.series_tab.getCustomerCardSeriesBorderColor
import org.jetbrains.compose.web.css.px

@Composable
fun LoadingLabeling(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    updateLoadingWorkCards: (Int, Int, Int, String) -> Unit,
    updateLabelingWorkCards: (Int, Int, String) -> Unit,
    windowSizeMode: WindowSizeModeEnum,
)
{
    Column(
        modifier = Modifier
            .margin(top = Dimen.mediumPadding)
    )
    {
        MediumContentTitle(
            title = Strings.loadingLabeling,
        )
        if (windowSizeMode==WindowSizeModeEnum.WINDOWS){
            Row(
                modifier = Modifier.margin(top = Dimen.normalPadding)
            )
            {
                LeftContent(
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    updateRangeLoyaltyCard={from:Int,to:Int,balance:Int,desc:String->
                        updateLoadingWorkCards(
                            from,to,balance,desc
                        )
                    },
                )


                RightContent(
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    updateLabelingWorkCards={ from:Int,to:Int,name:String->
                        updateLabelingWorkCards(from,to,name)
                    }
                )
            }
        }else{
            Column (
                modifier = Modifier.margin(top = Dimen.normalPadding)
            )
            {
                LeftContent(
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    updateRangeLoyaltyCard={from:Int,to:Int,balance:Int,desc:String->
                        updateLoadingWorkCards(
                            from,to,balance,desc
                        )
                    },
                )


                RightContent(
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    updateLabelingWorkCards={ from:Int,to:Int,name:String->
                        updateLabelingWorkCards(from,to,name)
                    }
                )
            }
        }







    }
}

@Composable
private fun RightContent(
    updateLabelingWorkCards: (Int, Int, String) -> Unit,
    selectedLoyaltyCard: LoyaltyCardDetail?,
) {
    val from= remember { mutableStateOf("") }
    val until= remember { mutableStateOf("") }
    val name= remember { mutableStateOf("") }

    LaunchedEffect(selectedLoyaltyCard){
        name.value=""
        until.value=""
        from.value=""
    }
    Column(
        modifier = Modifier
            .margin(left = Dimen.normalPadding)
    )
    {
        SpanText(
            text = Strings.labelingWorkCards,
            modifier = Modifier
                .fontSize(FontSizes.semiMediumFontSize)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Montserrat)

        )

        Row(
            verticalAlignment = Alignment.CenterVertically
        )
        {
            CustomEditText(
                modifier = Modifier,
                label = Strings.from,
                value = from.value,
                marginRight = Dimen.normalPadding,
                isDecimal = true,
                defaultBorderColor = getCustomerCardSeriesBorderColor(
                    from=from.value,
                    until=until.value
                ),
                width = Dimen.semiNormalEditTextWidth,
                marginBottom = Dimen.smallPadding,
                isRequired = false,
                hint = "",
                onFocusIn = {

                },
                onFocusOut = {},
                onInputChange = {
                    from.value=it
                },
            )


            CustomEditText(
                modifier = Modifier,
                label = Strings.until,
                value = until.value,
                width = Dimen.semiNormalEditTextWidth,
                marginRight = Dimen.normalPadding,
                isDecimal = true,
                defaultBorderColor = getCustomerCardSeriesBorderColor(
                    from=from.value,
                    until=until.value
                ),
                isRequired = false,
                hint = "",
                marginBottom = Dimen.smallPadding,
                onFocusIn = {

                },
                onFocusOut = {},
                onInputChange = {
                    until.value=it
                },
            )


        }




        CustomEditText(
            modifier = Modifier,
            label = Strings.cardHolder,
            value = name.value,
            marginBottom = Dimen.smallPadding,
            isRequired = false,
            hint = "",
            onFocusIn = {

            },
            onFocusOut = {},
            onInputChange = {
                name.value=it
            },
        )


        CustomButton(
            modifier = Modifier,
            topBottomMargin = Dimen.mediumPadding,
            leftRightMargin = 0.px,
            isClickable = (
                    name.value.isNotEmpty()
                    && from.value.isNotEmpty()
                    && until.value.isNotEmpty()
                    && from.value.toInt()<=until.value.toInt()
                ),
            hoveredBackgroundColor = Theme.Blue.rgb,
            title = Strings.loadLabels,
            shadowColor = Theme.GrayCD.rgb,
            defaultTextColor = Theme.Secondary.rgb,
            onButtonClick = {
                updateLabelingWorkCards(
                    from.value.toInt(),
                    until.value.toInt(),
                    name.value
                )
            }

        )
    }
}

@Composable
private fun LeftContent(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    updateRangeLoyaltyCard: (Int, Int, Int, String) -> Unit,
) {
    val from= remember { mutableStateOf("") }
    val until= remember { mutableStateOf("") }
    val balance= remember { mutableStateOf("") }
    val desc= remember { mutableStateOf("") }



    LaunchedEffect(selectedLoyaltyCard){
        desc.value=Strings.loadingWorkCards
        from.value=""
        until.value=""
        balance.value=""

    }

    Column()
    {
        SpanText(
            text = Strings.loadingWorkCards,
            modifier = Modifier
                .fontSize(FontSizes.semiMediumFontSize)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Montserrat)

        )
        Row(
            verticalAlignment = Alignment.CenterVertically
        )
        {
            CustomEditText(
                modifier = Modifier,
                label = Strings.from,
                value = from.value,
                marginRight = Dimen.normalPadding,
                isDecimal = true,
                defaultBorderColor = getCustomerCardSeriesBorderColor(
                    from=from.value,
                    until=until.value
                ),
                width = Dimen.semiNormalEditTextWidth,
                marginBottom = Dimen.smallPadding,
                isRequired = false,
                hint = "",
                onFocusIn = {  },
                onFocusOut = {},
                onInputChange = {
                    from.value=it
                },
            )


            CustomEditText(
                modifier = Modifier,
                label = Strings.until,
                value = until.value,
                width = Dimen.semiNormalEditTextWidth,
                isDecimal = true,
                defaultBorderColor = getCustomerCardSeriesBorderColor(
                    from=from.value,
                    until=until.value
                ),
                marginRight = Dimen.normalPadding,
                isRequired = false,
                hint = "",
                marginBottom = Dimen.smallPadding,
                onFocusIn = {

                },
                onFocusOut = {},
                onInputChange = {
                    until.value=it
                },
            )


        }

        CustomEditText(
            modifier = Modifier,
            label = Strings.balance,
            value = balance.value,
            marginRight = Dimen.normalPadding,
            isDecimal = true,
            width = Dimen.semiNormalEditTextWidth,
            marginBottom = Dimen.smallPadding,
            isRequired = false,
            hint = "",
            onFocusIn = {

            },
            onFocusOut = {},
            onInputChange = {
                balance.value=it
            },
        )


        CustomEditText(
            modifier = Modifier,
            label = Strings.name,
            value = desc.value,
            marginBottom = Dimen.smallPadding,
            isRequired = false,
            hint = "",
            onFocusIn = {

            },
            onFocusOut = {},
            onInputChange = {
                desc.value=it
            },
        )


        CustomButton(
            modifier = Modifier,
            topBottomMargin = Dimen.mediumPadding,
            leftRightMargin = 0.px,
            isClickable = (
                    from.value.isNotEmpty()
                    && until.value.isNotEmpty()
                    && balance.value.isNotEmpty()
                    && from.value.toInt()<=until.value.toInt()
            ),
            title = Strings.loadMaps,
            shadowColor = Theme.GrayCD.rgb,
            defaultTextColor = Theme.Secondary.rgb,
            onButtonClick = {
                updateRangeLoyaltyCard(
                    from.value.toInt()
                    ,until.value.toInt()
                    ,balance.value.toInt()
                    ,desc.value
                )
            }

        )


    }
}