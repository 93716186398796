package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.update_cash_bid_img


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Img(
    @SerialName("id")
    val id: String?,
    @SerialName("key")
    val key: String?,
    @SerialName("uid")
    val uid: String?
)