package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.update_restaurant_data


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.restaurant_data.AdditionModel

@Serializable
data class UpdateProduct(
    @SerialName("additions")
    val additions: List<AdditionModel> ?= listOf(),
    @SerialName("id")
    val id: String,
    @SerialName("isAdditionRequired")
    val isAdditionRequired: Boolean?=false,
    @SerialName("isPrintOnlyInCafe")
    val isPrintOnlyInCafe: Boolean?=false,
    @SerialName("isServiceCall")
    val isServiceCall: Boolean?=false,
    @SerialName("mod_ver")
    val modVer: Int,
    @SerialName("printerLocations")
    val printerLocations: List<Boolean ?> = listOf(),
    @SerialName("__typename")
    val typename: String
)