package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel

@Composable
fun CalendarDayNumbers(
    currentMonth: MonthModel,
    onDayClick:(MonthModel)->Unit,
    selectedDay: MonthModel?,
)
{

    Column(
        modifier = Modifier
            .width(Dimen.DateDaysWidth),
    ) {

        currentMonth.days.chunked(7).forEachIndexed { index, week ->

            Row {
                week.forEach { day ->
                    DateNumberItem(
                        number = day.dayNumber,
                        onSelectNumber = {
                            onDayClick(it)
                        },
                        selectedDate=selectedDay,
                        currentMonth =currentMonth.monthName ,
                        currentYear =currentMonth.yearNumber ,
                        monthNumber = currentMonth.monthNumber,


                    )
                }
            }
        }
    }


}