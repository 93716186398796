package org.hs_soft.runmynesto.domain.model.home.product.tabs

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.TitleEnum

enum class ProductInfoHeaderEnum(override val title: String)
    : TitleEnum {
        GENERAL(Strings.general.uppercase()),
        CHECKOUT(Strings.devices.uppercase()),
        RESTAURANT(Strings.restaurant.uppercase()),
        CLICK_AND_CONNECT(Strings.clickAndCollect.uppercase()),
        ALLERGENES(Strings.allergens.uppercase())
}