package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.sales_group

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.GridOptionBar
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.sales_group.component.OptionSaleGroupTabContent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsState

@Composable
fun OptionSaleGroupTab(
    windowSizeMode: WindowSizeModeEnum,
    state:OptionsState,
    event: (OptionsEvent) -> Unit,
)
{
    LaunchedEffect(Unit){
        event(OptionsEvent.GetProductSalesGroup)
    }
    Row(
        modifier = Modifier
            .fillMaxSize()
    )
    {
        if (state.firstPageActive) {
            GridOptionBar(
                windowSizeMode=windowSizeMode,
                selectedItem = state.selectedSaleGroup,
                searchValue = state.searchedSaleValue,
                infoList = state.productSalesGroup?: listOf(),
                titleDescending = state.isSaleGroupNameSortedAsc,
                numberDescending = state.isSaleGroupNumberSortedAsc,
                showNumberSortIcon = state.showSaleGroupNumberSortIcon,
                showTitleSortIcon = state.showSaleGroupTitleSortIcon,
                onSearchValueChange = {
                    event(OptionsEvent.OnSearchSaleGroupInputChange(it))
                },
                onAddRecord = {
                    event(OptionsEvent.OnAddRecordIconClick)
                },
                onTitleSortClick = {
                    event(OptionsEvent.OnSaleGroupTitleSortClick)
                },
                onNumberSortClick = {
                    event(OptionsEvent.OnSaleGroupNumberSortClick)
                },
                onSaleItemClick = { id ->
                    event(
                        OptionsEvent.OnSaleGroupItemClick(
                        id = id,
                        callBack = {
                            if (windowSizeMode == WindowSizeModeEnum.PHONE)
                                event(OptionsEvent.OnChangePageClick)
                        }
                    ))
                },
                onSwipeRight = {
                    event(OptionsEvent.OnChangePageClick)
                },
                refreshList = {
                    event(OptionsEvent.RefreshSaleGroupList)
                }
            )

            if (windowSizeMode != WindowSizeModeEnum.PHONE) {
                OptionSaleGroupTabContent(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=event
                )

            }

        } else {

            OptionSaleGroupTabContent(
                windowSizeMode=windowSizeMode,
                state=state,
                event=event,
                onSwipeLeft = {
                    event(OptionsEvent.OnChangePageClick)
                }

            )


        }

    }

}

