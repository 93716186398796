package org.hs_soft.runmynesto.pages.home.sub_page.options.mvi

import org.hs_soft.runmynesto.domain.model.home.options.OptionInfoHeaders
import org.hs_soft.runmynesto.domain.model.home.options.cash_flow.app_config_model.GetAppConfig
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.CashRegisterConfigHeaders
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.GetCashRegister
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config.GetVirtualCardConfig
import org.hs_soft.runmynesto.domain.model.home.options.operation.option_model.OptionModel
import org.hs_soft.runmynesto.domain.model.home.options.single_sale_group.GetSalesGroup
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group.SaleItem
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location.PrinterLocation
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenXX
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenXXX

data class OptionsState(
    val selectedTab: OptionInfoHeaders = OptionInfoHeaders.OPERATION,
    val selectedCashRegisterConfigTab: CashRegisterConfigHeaders =
        CashRegisterConfigHeaders.OPERATION,
    val showColorPalletDialog:Boolean=false,
    val selectedColorField:String?=null,
    val virtualCardInfo: GetVirtualCardConfig?=null,
    val cashRegisterConfigData: GetCashRegister?=null,
    val selectedCashRegisterConfigItemId:String?=null,
    val cashList: List<ChildrenX> ?= null,
    val appConfig: GetAppConfig?=null,
    val optionModel: OptionModel ?= null,
    val dataLoadingProgressVisible:Boolean=false,
    val productSalesGroup:List<SaleItem> ?=null,
    val selectedSaleGroup: GetSalesGroup?=null,
    val searchedSaleValue:String="",
    val isSaleGroupNameSortedAsc:Boolean=true,
    val isSaleGroupNumberSortedAsc:Boolean=true,
    val showSaleGroupNumberSortIcon:Boolean=true,
    val showSaleGroupTitleSortIcon:Boolean=false,
    val saleGroupSearchValue:String="",
    val firstPageActive:Boolean=true,
    val newRecordName:String?=null,
    val newRecordNumber:String="",
    val isAddNewRecordSubmitClickable:Boolean=false,
    val addRecordErrorMessage:String="",
    val addRecordLoading:Boolean=false,
    val showAddSaleGroupRecordDialog:Boolean=false,
    val showKitchenLocationRecordDialog:Boolean=false,
    val printersLocation:List<PrinterLocation?> ? =null,
    val printerLocationIndex:Int?=null,
    val showGoogleWalletDialog:Boolean=false,
    val showAppleWalletDialog:Boolean=false,
    val priceLevels:List<String> ?=null,
    val selectedPriceLevelIndex:Int?=null,
    val selectedOffice:ChildrenX?=null,
    val selectedRegion:ChildrenXX?=null,
    val selectedBranch:ChildrenXXX?=null,

)