package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.update_cash_bid_img


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Data(
    @SerialName("cashAssist")
    val cashAssist: CashAssist?
)