package org.hs_soft.runmynesto.domain.model.home.template.product_categories

import kotlinx.serialization.Serializable

@Serializable
data class TemplateCategoryModel(
    val id: String,
    val name: String?,
    val position: Int,
    val isActive:Boolean?,
)