package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Appearance
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Width
import com.varabyte.kobweb.compose.css.appearance
import com.varabyte.kobweb.compose.css.borderBottom
import com.varabyte.kobweb.compose.css.borderLeft
import com.varabyte.kobweb.compose.css.borderRight
import com.varabyte.kobweb.compose.css.borderTop
import com.varabyte.kobweb.compose.css.boxShadow
import com.varabyte.kobweb.compose.css.fontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexDirection
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.backgroundPosition
import org.jetbrains.compose.web.css.backgroundRepeat
import org.jetbrains.compose.web.css.backgroundSize
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontFamily
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Option
import org.jetbrains.compose.web.dom.Select
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.events.SyntheticInputEvent
import org.w3c.dom.HTMLSelectElement

@Composable
fun DropDownItem(
    modifier: Modifier = Modifier,
    options: List<dynamic>,
    height: CSSSizeValue<CSSUnit.px> = Dimen.dropDownHeight,
    label: String,
    fillMaxWidth: Boolean = false,
    disable: Boolean = false,
    selectedItem: dynamic,
    borderLessDropDown: Boolean = false,
    fontSize: CSSSizeValue<CSSUnit.px> = FontSizes.mediumFontSize,
    fontWeight: FontWeight = FontWeight.Normal,
    content: @Composable (dynamic) -> Unit,
    onOptionClick: (dynamic) -> Unit,
    itemWidth: CSSSizeValue<CSSUnit.px> = Dimen.mediumEditTextWidth,
    borderTopLeft: CSSSizeValue<CSSUnit.px> = Dimen.lowBorderRadius,
    borderBottomLeft: CSSSizeValue<CSSUnit.px> = Dimen.lowBorderRadius,
    borderTopRight: CSSSizeValue<CSSUnit.px> = Dimen.lowBorderRadius,
    borderBottomRight: CSSSizeValue<CSSUnit.px> = Dimen.lowBorderRadius,
    borderLeftWidth: CSSSizeValue<CSSUnit.px> = Dimen.smallThickness,
    borderRightWidth: CSSSizeValue<CSSUnit.px> = Dimen.smallThickness,
    borderTopWidth: CSSSizeValue<CSSUnit.px> = Dimen.smallThickness,
    borderBottomWidth: CSSSizeValue<CSSUnit.px> = Dimen.smallThickness,
) {
    val isHovered = remember { mutableStateOf(false) }

    Column(modifier = modifier) {

        Box(
            modifier = Modifier
                .onMouseOver {
                    isHovered.value = true
                }
                .onMouseLeave {
                    isHovered.value = false
                }
                .height(height)
                .then(if (fillMaxWidth) Modifier.fillMaxWidth() else Modifier)
        ) {
            SpanText(
                text = label,
                modifier = Modifier
                    .align(Alignment.TopStart)
                    .margin(left = Dimen.mediumPadding)
                    .color(Theme.Gray3E.rgb)
                    .zIndex(Constants.ZIndex.smallZIndex)
                    .padding(leftRight = Dimen.smallPadding)
                    .backgroundColor(Theme.White.rgb)
                    .fontWeight(FontWeight.Normal)
                    .fontFamily(FontFamily.Jakarta)
                    .fontSize(FontSizes.semiMediumFontSize)
            )

            Box(
                modifier = Modifier
                    .align(Alignment.BottomStart)
                    .height(Dimen.dropDownInnerHeight)
            ) {
                Box(
                    modifier = Modifier
                        .display(DisplayStyle.Flex)
                        .flexDirection(FlexDirection.Column)
                        .width(itemWidth)
                        .height(Dimen.mediumOutlinedEditTextHeight)
                        .fontSize(fontSize)
                        .fontWeight(fontWeight)
                        .fontFamily(FontFamily.Jakarta)
                        .borderRadius(
                            topLeft = borderTopLeft,
                            topRight = borderTopRight,
                            bottomRight = borderBottomRight,
                            bottomLeft = borderBottomLeft,
                        )
                        .backgroundColor(Theme.Transparent.rgb)
                ) {
                    Select(
                        attrs = {
                            style {
                                if (borderLessDropDown) {
                                    border(0.px, LineStyle.None, Theme.Transparent.rgb)
                                    backgroundColor(Theme.Transparent.rgb)
                                } else {
                                    borderLeft(
                                        borderLeftWidth, LineStyle.Solid,
                                        if (isHovered.value) Theme.Primary.rgb else Theme.GrayCD.rgb
                                    )
                                    borderRight(
                                        borderRightWidth, LineStyle.Solid,
                                        if (isHovered.value) Theme.Primary.rgb else Theme.GrayCD.rgb
                                    )
                                    borderBottom(
                                        borderBottomWidth, LineStyle.Solid,
                                        if (isHovered.value) Theme.Primary.rgb else Theme.GrayCD.rgb
                                    )
                                    borderTop(
                                        borderTopWidth, LineStyle.Solid,
                                        if (isHovered.value) Theme.Primary.rgb else Theme.GrayCD.rgb
                                    )
                                    borderRadius(
                                        topLeft = borderTopLeft,
                                        topRight = borderTopRight,
                                        bottomRight = borderBottomRight,
                                        bottomLeft = borderBottomLeft,
                                    )
                                    backgroundColor(Theme.White.rgb)
                                }
                                width(itemWidth)
                                backgroundPosition("right 0.5em center")
                                backgroundRepeat("no-repeat")
                                backgroundSize("1.5em 1.5em")
                                paddingLeft(Dimen.mediumPadding)
                                paddingRight(Dimen.mediumPadding)
                                paddingTop(Dimen.semiMediumPadding)
                                paddingBottom(Dimen.semiMediumPadding)
                                fontFamily(FontFamily.Jakarta)
                                backgroundImage(Res.PathIcon.dropdownIcon)
                                color(Theme.Primary.rgb)
                                appearance(Appearance.None)
                            }
                            onInput { event: SyntheticInputEvent<*, *> ->
                                val target = event.target as HTMLSelectElement
                                onOptionClick(target.value)
                            }
                        }
                    ) {
                        if (selectedItem == null) {
                            Option(
                                attrs = {
                                    attr("selected", "selected")
                                    style {
                                        padding(Dimen.mediumPadding)
                                        fontSize(FontSizes.mediumFontSize)
                                        width(itemWidth)
                                        fontFamily(FontFamily.Jakarta)
                                    }
                                },
                                value = ""
                            ) {
                                Text("")
                            }
                        }

                        options.forEach { option ->
                            Option(
                                attrs = {
                                    if (disable) {
                                        attr("disabled", "disabled")
                                    }
                                    if (option == selectedItem) {
                                        attr("selected", "selected")
                                    }
                                    style {
                                        padding(Dimen.mediumPadding)
                                        fontSize(FontSizes.mediumFontSize)
                                        if (fillMaxWidth) {
                                            width(100.percent)
                                        } else {
                                            width(itemWidth)
                                        }
                                        fontFamily(FontFamily.Jakarta)
                                    }
                                },
                                value = option
                            ) {
                                content(option)
                            }
                        }
                    }
                }
            }
        }
    }
}