package org.hs_soft.runmynesto.domain.model.home.product.add_new_product


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreateMutation(
    @SerialName("id")
    val id: String,
    @SerialName("name")
    val name: String?,
    @SerialName("isActive")
    val isActive:Boolean,
    @SerialName("price")
    val price:List<Double?>,
    @SerialName("priceInCafe")
    val priceInCafe:List<Double?>,
    @SerialName("cashRegisterId")
    val cashRegisterId:List<String>,


)