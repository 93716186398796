package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.product_general_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.CountriesEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.AppConstantsEnum
import org.hs_soft.runmynesto.domain.util.toTwoDecimalString
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.DropdownComponent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun Price1(
    price: Double?,
    windowSizeMode: WindowSizeModeEnum,
    countryId:String,
    selectedVat:String?,
    updatePrice: (List<Double?>) -> Unit,
    updateVatCode: (String) -> Unit,
){
    if (windowSizeMode==WindowSizeModeEnum.PHONE){
        Column(modifier = Modifier)
        {
            content(
                price=price,
                windowSizeMode =windowSizeMode ,
                countryId=countryId,
                updatePrice=updatePrice,
                selectedVat=selectedVat,
                updateVatCode=updateVatCode
            )

        }
    }else{
        Row(modifier = Modifier)
        {
            content(
                price=price,
                windowSizeMode =windowSizeMode ,
                countryId=countryId,
                updatePrice=updatePrice,
                selectedVat=selectedVat,
                updateVatCode=updateVatCode
            )


        }
    }



}

@Composable
private fun content(
    price: Double?,
    windowSizeMode: WindowSizeModeEnum,
    countryId: String,
    updatePrice: (List<Double?>) -> Unit,
    selectedVat: String?,
    updateVatCode: (String) -> Unit
) {
    CustomEditText(
        modifier = Modifier,
        marginBottom = if (windowSizeMode==WindowSizeModeEnum.PHONE)
            0.px else Dimen.mediumPadding
        ,
        width = Dimen.normalEditTextWidth,
        height = Dimen.mediumOutlinedEditTextHeight,
        label = Strings.price1,
        isOutlinedEditText = true,
        textAlign = TextAlign.End,
        value = price.toTwoDecimalString(useDotDigitStatus(countryId)),
        isDecimal = true,
        isRequired = false,
        usingDotForDigit = useDotDigitStatus(countryId),
        defaultBorderColor = Theme.GrayCD.rgb,
        onFocusIn = {},
        onFocusOut = {
            updatePrice(
                listOf<Double?>(
                    if (it.isEmpty()) null
                    else (it.replace(',', '.')).toDouble()
                )
            )

        },
        onInputChange = {

        },

    )


    DropdownComponent(
        modifier = Modifier
        .margin(left =if (windowSizeMode==WindowSizeModeEnum.PHONE)
            0.px else
            Dimen.normalPadding
        ),
        options = AppConstantsEnum.entries,
        label = Strings.vat,
        selectedItem = AppConstantsEnum.entries.find {
            it.value == selectedVat
        },
        content = {
            if (it is AppConstantsEnum)
                Text(it.label)
        },
        onOptionClick = {
            val option: AppConstantsEnum? = AppConstantsEnum.entries.find { item ->
                item.toString() == it
            }

            if (option != null) {
                updateVatCode(option.value)
            }

        }
    )
}


fun useDotDigitStatus(countryId: String): Boolean {
    if (countryId== CountriesEnum.GERMANY.id)
        return false
    else return true
}



