package org.hs_soft.runmynesto.data.api_datasource.product.tabs

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.add_new_product.AddNewProductModel
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.AppConstantsEnum
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.AddProductRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class AddProductRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): AddProductRepository {



    override suspend fun addProduct(
        name: String,
        code: String,
        categoryId: String?
    ): AddNewProductModel {

        val abortController = requestManager.createController()

        val query = """
            mutation createProduct(
                ${'$'}required: ProductReqFields!,
                ${'$'}fields: ProductFields,
                ${'$'}copyId:ID
            ) {
                  cashAssist {
                    createMutation: createProduct(
                        required: ${'$'}required, 
                        fields: ${'$'}fields,
                         copyId:${'$'}copyId
                        ) 
                        {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.name}
                            ${ApiUtils.Field.isActive}
                            ${ApiUtils.Field.price}
                            ${ApiUtils.Field.priceInCafe}
                            ${ApiUtils.Field.cashRegisterId}
                    }
                  }
                }
        """.trimIndent()

        val variables = buildJsonObject {
            putJsonObject("required") {
                put("name", name)
                put("code", code)
            }
            putJsonObject("fields") {
                put("isActive", true)
                put("categoryId",categoryId)
                put("codeMWSt", AppConstantsEnum.VAT_NORMAL.value)
                put("codeMWSt2", AppConstantsEnum.VAT_NORMAL.value)
            }
        }

        val graphQLQuery = buildJsonObject {
            put("query", query)
            put("variables", variables)
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<AddNewProductModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }

    }

}
