package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model.ClosingReportDetailModel
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model.ClosingReportItem
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.animatedWidth
import org.hs_soft.runmynesto.domain.util.getCheckoutWidth
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.ReportsTable
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.ReportsTableFilterOptions
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.ReportsTableSearchBar
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsEvent
import org.jetbrains.compose.web.css.px

@Composable
fun ClosingReportsTable(
    modifier: Modifier = Modifier,
    showReportsTableAndDevices:Boolean,
    selectedClosingReport: ClosingReportDetailModel?,
    closingReportsList: List<ClosingReportItem>?,
    selectedDate: MonthModel?,
    selectedFrom: String,
    selectedTo: String,
    selectedCalendarHeaderYear:Int,
    windowSizeMode: WindowSizeModeEnum,
    selectedPeriodType: PeriodDaysEnum?,
    showCalendarFilterDialog: Boolean,
    showDaysFilterDialog: Boolean,
    cashRegisterIds: List<String>,
    event:(ClosingReportsEvent)->Unit,
)
{
    Column(
        modifier = modifier
            .margin(
//                left = Dimen.normalPadding,
                top = Dimen.normalPadding
            )
            .thenIf(
                windowSizeMode==WindowSizeModeEnum.TABLET,
                Modifier.width(Constants.Responsive.ClosingReportsTableTablet.px)
            )
            .thenIf(
                windowSizeMode==WindowSizeModeEnum.PHONE,
                Modifier.width(Constants.Responsive.ClosingReportsTablePhone.px)
            )
            .thenIf(
                windowSizeMode==WindowSizeModeEnum.Desktop,
                Modifier
                    .animatedWidth(
                        widthValue =
                        if (showReportsTableAndDevices)
                        {
                            getCheckoutWidth(windowSizeMode)
                        } else 0.px,
                        duration = Constants.mediumExpandingTime
                    )
            )

    )
    {
        ReportsTableFilterOptions(
            selectedPeriodType = selectedPeriodType,
            showDaysFilterDialog=showDaysFilterDialog,
            selectedDateFrom = selectedDate,//TODO(Must be fixed)
            selectedDateTo = selectedDate,//TODO(Must be fixed)
            showToCalendarFilterDialog = showCalendarFilterDialog,//TODO(Must be fixed)
            showFromCalendarFilterDialog = showCalendarFilterDialog,//TODO(Must be Fixed)
            selectedTo = selectedTo,
            selectedFrom = selectedFrom,
            selectedCalendarHeaderYear =selectedCalendarHeaderYear,
            setDaysFilterDialogStatus = {
               event(ClosingReportsEvent.SetDaysFilterDialogStatus(it))
            },
            submitFilterItemsBasedOnDays = { from:String, to:String, type:PeriodDaysEnum ->
                event(
                    ClosingReportsEvent.SubmitClosingReportsDaysFilter(
                        from=from,
                        to=to,
                        periodDaysType=type,
                        cashRegisterIds =cashRegisterIds,
                    )
                )
            },
            setFromCalendarFilterDialogStatus = {
                event(ClosingReportsEvent.SetCalendarFilterDialogStatus(status = it))//TODO(Must be fixed)
            },
            setToCalendarFilterDialogStatus = {
                event(ClosingReportsEvent.SetCalendarFilterDialogStatus(status = it))//TODO(Must be fixed)
            },
            onSelectFromDate = {
                //TODO(Must be Fixed)
                event(
                    ClosingReportsEvent.OnSelectDate(
                        selectedDate = it,
                        cashRegisterIds = cashRegisterIds
                    )
                )
            },
            onSelectToDate = {
                //TODO(Must be Fixed)
                event(
                    ClosingReportsEvent.OnSelectDate(
                        selectedDate = it,
                        cashRegisterIds = cashRegisterIds
                    )
                )
            },
            filterDate = {from:String,to:String->
                event(
                    ClosingReportsEvent.SubmitClosingReportsCalendarFilter(
                        from,
                        to,
                        cashRegisterIds
                    )
                )
            },
            closeFromCalendarDialog = {
                //TODO(Must be fixed)
               event(
                    ClosingReportsEvent.SetCalendarFilterDialogStatus(false)
                )
            },
            closeToCalendarDialog = {
                //TODO(Must be Fixed)
                event(
                    ClosingReportsEvent.SetCalendarFilterDialogStatus(false)
                )
            },
            setNewCalendarHeaderYear = {
                event(
                    ClosingReportsEvent.SetNewCalendarHeaderYear(it)
                )
            }
        )

        ReportsTableSearchBar(
            windowSizeMode=windowSizeMode,
            onSearchInfo={
                event(ClosingReportsEvent.SearchInClosingReports(it))
            }
        )

        ReportsTable(
            windowSizeMode=windowSizeMode,
            closingReportsList=closingReportsList,
            selectedClosingReport=selectedClosingReport,
            onClosingReportClick = {
                event(
                    ClosingReportsEvent.OnClosingReportClick(
                        id=it
                    )
                )

            }
        )


    }


}






