package org.hs_soft.runmynesto.domain.model.home.user

import org.hs_soft.runmynesto.domain.config.Strings

enum class UserCancellationReasonEnum(val title: String,val id:String) {
    NO_AUTHORIZATION(title= Strings.noAuthorization,id="0"),
    ONLY_OWN_RECEIPTS(title= Strings.onlyOwnReceipts,id="1"),
    ALL_RECEIPTS(title= Strings.allReceipts,id="2");

    companion object {
        fun fromDisplayName(title: String): UserCancellationReasonEnum? =
            UserCancellationReasonEnum.entries.firstOrNull { it.title == title }
    }
}

