package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics.ShopStaticItem
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.domain.util.showSelectedDate
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_reports.component.DashboardReportInfo
import org.jetbrains.compose.web.css.px

@Composable
fun DashboardGeneralInfo(
    windowSizeMode: WindowSizeModeEnum,
    currencyType: String,
    dashboardItemName:String,
    dashboardItem: ShopStaticItem?,
    cashRegistersCount: Int?,
    selectedDate: MonthModel,
    setCalendarStatus: (Boolean) -> Unit,
    onBackClick:()->Unit,
)
{
    Column(modifier = Modifier.fillMaxWidth())
    {
        when(windowSizeMode)
        {

            WindowSizeModeEnum.Desktop ->{
                GeneralInfoWindowsMode(
                    dashboardItemName=dashboardItemName,
                    windowSizeMode=windowSizeMode,
                    selectedDate=selectedDate,
                    dashboardItem=dashboardItem,
                    currencyType=currencyType,
                    cashRegistersCount=cashRegistersCount,
                    onBackClick={
                        onBackClick()
                    },
                    setCalendarStatus={
                        setCalendarStatus(it)
                    },
                )
            }

            WindowSizeModeEnum.TABLET->{
                GeneralInfoTabletMode(
                    dashboardItemName=dashboardItemName,
                    windowSizeMode=windowSizeMode,
                    selectedDate=selectedDate,
                    dashboardItem=dashboardItem,
                    currencyType=currencyType,
                    cashRegistersCount=cashRegistersCount,
                    onBackClick={
                        onBackClick()
                    },
                    setCalendarStatus={
                        setCalendarStatus(it)
                    },
                )
            }

            WindowSizeModeEnum.PHONE->{
                GeneralInfoMobileMode(
                    dashboardItemName=dashboardItemName,
                    selectedDate=selectedDate,
                    dashboardItem=dashboardItem,
                    currencyType=currencyType,
                    cashRegistersCount=cashRegistersCount,
                    onBackClick={
                        onBackClick()
                    },
                    setCalendarStatus={
                        setCalendarStatus(it)
                    },
                )
            }
        }


    }


}

@Composable
private fun GeneralInfoWindowsMode(
    dashboardItemName: String,
    onBackClick: () -> Unit,
    windowSizeMode: WindowSizeModeEnum,
    selectedDate: MonthModel,
    setCalendarStatus: (Boolean) -> Unit,
    dashboardItem: ShopStaticItem?,
    currencyType: String,
    cashRegistersCount: Int?
) {
    TopDashboardChartToolbar(
        name = dashboardItemName,
        onBackClick = {
            onBackClick()
        },
    )

    Row(
        modifier = Modifier
            .fillMaxWidth()
    )
    {
        CustomEditText(
            modifier = Modifier,
            marginLeft = if (windowSizeMode != WindowSizeModeEnum.PHONE)
                Dimen.mediumPadding else 0.px,
            width = if (windowSizeMode == WindowSizeModeEnum.Desktop)
                Dimen.semiLargeEditTextWidth else Dimen.largeEditTextWidth,
            label = Strings.date,
            value = showSelectedDate(selectedDate = selectedDate),
            startIconPath = Res.PathIcon.calendar,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            marginBottom = 0.px,
            isOutlinedEditText = true,
            onFocusIn = {
                setCalendarStatus(true)
            },
            onFocusOut = {},
            onInputChange = {},
        )


        Spacer()

        Row()
        {
            DashboardReportInfo(
                modifier = Modifier
                    .margin(leftRight = Dimen.smallPadding),
                title = Strings.turnOver,
                value = "${
                    formatToTwoDecimals(
                        dashboardItem?.total ?: 0.0
                    )
                } $currencyType",
                color = Theme.DarkGreen.hex,
                icon = Res.PathIcon.turnOver,
                hasBorder = true
            )

            DashboardReportInfo(
                modifier = Modifier
                    .margin(leftRight = Dimen.smallPadding),
                title = Strings.averageCheck,
                value = "${dashboardItem?.totalPerBill?:0.0} $currencyType",
                color = Theme.Yellow700.hex,
                icon = Res.PathIcon.averageCheck,
                hasBorder = true
            )


            DashboardReportInfo(
                modifier = Modifier
                    .margin(leftRight = Dimen.smallPadding),
                title = Strings.clients,
                value = dashboardItem?.customerCount?.toString() ?: "",
                color = Theme.Blue.hex,
                icon = Res.PathIcon.clients,
                hasBorder = true
            )


            DashboardReportInfo(
                modifier = Modifier
                    .margin(leftRight = Dimen.smallPadding),
                title = Strings.cashRegisters,
                value = "${cashRegistersCount ?: 0}/${cashRegistersCount ?: 0}",
                color = Theme.GrayAlpha56.hex,
                icon = Res.PathIcon.cashRegister,
                hasBorder = true
            )

        }


    }
}

@Composable
private fun GeneralInfoTabletMode(
    dashboardItemName: String,
    windowSizeMode: WindowSizeModeEnum,
    selectedDate: MonthModel,
    dashboardItem: ShopStaticItem?,
    currencyType: String,
    cashRegistersCount: Int?,
    setCalendarStatus: (Boolean) -> Unit,
    onBackClick: () -> Unit,
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
    )
    {
        Row(
            modifier = Modifier
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            TopDashboardChartToolbar(
                name = dashboardItemName,
                onBackClick = {
                    onBackClick()
                },
            )

            Spacer()

            CustomEditText(
                modifier = Modifier,
                marginLeft = if (windowSizeMode != WindowSizeModeEnum.PHONE)
                    Dimen.mediumPadding else 0.px,
                width = if (windowSizeMode == WindowSizeModeEnum.Desktop)
                    Dimen.semiLargeEditTextWidth else Dimen.largeEditTextWidth,
                label = Strings.date,
                value = showSelectedDate(selectedDate = selectedDate),
                startIconPath = Res.PathIcon.calendar,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                hint = "",
                marginBottom = 0.px,
                isOutlinedEditText = true,
                onFocusIn = {
                    setCalendarStatus(true)
                },
                onFocusOut = {},
                onInputChange = {},
            )
        }

        Row(
            modifier = Modifier
                .margin(top = Dimen.normalPadding)
                .fillMaxWidth()
        ) {
            DashboardReportInfo(
                modifier = Modifier
                    .weight(1f),
                title = Strings.turnOver,
                value = "${
                    formatToTwoDecimals(
                        dashboardItem?.total ?: 0.0
                    )
                } $currencyType",
                color = Theme.DarkGreen.hex,
                icon = Res.PathIcon.turnOver,
                hasBorder = true
            )

            DashboardReportInfo(
                modifier = Modifier
                    .margin(left = Dimen.normalPadding)
                    .weight(1f),
                title = Strings.averageCheck,
                value = "${dashboardItem?.totalPerBill} $currencyType",
                color = Theme.Yellow700.hex,
                icon = Res.PathIcon.averageCheck,
                hasBorder = true
            )

            DashboardReportInfo(
                modifier = Modifier
                    .margin(left = Dimen.normalPadding)
                    .weight(1f),
                title = Strings.clients,
                value = dashboardItem?.customerCount?.toString() ?: "",
                color = Theme.Blue.hex,
                icon = Res.PathIcon.clients,
                hasBorder = true
            )

            DashboardReportInfo(
                modifier = Modifier
                    .margin(left = Dimen.normalPadding)
                    .weight(1f),
                title = Strings.cashRegisters,
                value = "${cashRegistersCount ?: 0}/${cashRegistersCount ?: 0}",
                color = Theme.GrayAlpha56.hex,
                icon = Res.PathIcon.cashRegister,
                hasBorder = true
            )
        }
    }

}


@Composable
private fun GeneralInfoMobileMode(
    dashboardItemName: String,
    selectedDate: MonthModel,
    dashboardItem: ShopStaticItem?,
    currencyType: String,
    cashRegistersCount: Int?,
    setCalendarStatus: (Boolean) -> Unit,
    onBackClick: () -> Unit,
) {

    val toolbarWidth= Dimen.chartWidth.px

    Column(
        modifier = Modifier
            .fillMaxWidth()
    )
    {
        Column(
            modifier = Modifier
                .fillMaxWidth(),
            horizontalAlignment = Alignment.Start
        )
        {
            TopDashboardChartToolbar(
                name = dashboardItemName,
                onBackClick = {
                    onBackClick()
                },
            )
            CustomEditText(
                modifier = Modifier,
                width = toolbarWidth,
                label = Strings.date,
                value = showSelectedDate(selectedDate = selectedDate),
                startIconPath = Res.PathIcon.calendar,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                hint = "",
                marginBottom = 0.px,
                isOutlinedEditText = true,
                onFocusIn = {
                    setCalendarStatus(true)
                },
                onFocusOut = {},
                onInputChange = {},
            )

        }

        Row(
            modifier = Modifier
                .margin(top = Dimen.normalPadding)
                .width(toolbarWidth),
            horizontalArrangement = Arrangement.Center
        )
        {
            DashboardReportInfo(
                modifier = Modifier ,
                title = Strings.turnOver,
                value = "${
                    formatToTwoDecimals(
                        dashboardItem?.total ?: 0.0
                    )
                } $currencyType",
                color = Theme.DarkGreen.hex,
                icon = Res.PathIcon.turnOver,
                hasBorder = true
            )

            Spacer()

            DashboardReportInfo(
                modifier = Modifier ,
                title = Strings.averageCheck,
                value = "${dashboardItem?.totalPerBill} $currencyType",
                color = Theme.Yellow700.hex,
                icon = Res.PathIcon.averageCheck,
                hasBorder = true
            )

        }

        Row(
            modifier = Modifier
                .margin(top = Dimen.normalPadding)
                .width(toolbarWidth),
            horizontalArrangement = Arrangement.Center
        )
        {
            DashboardReportInfo(
                modifier = Modifier,
                title = Strings.clients,
                value = dashboardItem?.customerCount?.toString() ?: "",
                color = Theme.Blue.hex,
                icon = Res.PathIcon.clients,
                hasBorder = true
            )

            Spacer()

            DashboardReportInfo(
                modifier = Modifier,
                title = Strings.cashRegisters,
                value = "${cashRegistersCount ?: 0}/${cashRegistersCount ?: 0}",
                color = Theme.GrayAlpha56.hex,
                icon = Res.PathIcon.cashRegister,
                hasBorder = true
            )
        }





    }

}