package org.hs_soft.runmynesto.navigation

sealed class Screen(val route:String)
{
    data object Login:Screen(route = "/login")
    data object HomePage: Screen(route = "/")
    data object Product:Screen(route = "/product")
    data object Template:Screen(route = "/template")
    data object User:Screen(route = "/user")
    data object CustomerCard:Screen(route = "/customerCard")
    data object ClosingReports:Screen(route = "/closingReports")
    data object Option:Screen(route = "/option")

}