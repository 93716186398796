package org.hs_soft.runmynesto.data.api_datasource.user.tab_checkout

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.update_checkout.UpdateUserCheckoutModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.tab_checkout.UpdateUserCheckoutRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils


class UpdateUserCheckoutRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateUserCheckoutRepository {

    override suspend fun updateUserCheckout(
        userId: String,
        dataValue: MutableSet<String>
    ): UpdateUserCheckoutModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation updateUser_cashRegisters(${'$'}id: ID!, ${'$'}fields: UserFields!) {
                  cashAssist {
                    updateUser(id: ${'$'}id, fields: ${'$'}fields) {
                      ${ApiUtils.Field.id}
                      ${ApiUtils.Field.mod_ver}
                      ${ApiUtils.Field.cashRegisterId}
                      ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                  }
                }
            """.trimIndent())

            putJsonObject("variables") {
                put("id", userId)
                putJsonObject("fields") {
                    put(ApiUtils.Field.cashRegisterId,Json.encodeToJsonElement(dataValue))
                }
            }
        }


        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController
        )

        return if (result != null) {

            Json.decodeFromString<UpdateUserCheckoutModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }

}
