package org.hs_soft.runmynesto.data.api_datasource.template.tab_checkout

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_checkout.update_template_checkout.UpdateTemplateCheckoutModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_ckechout.UpdateTemplateCheckoutRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateTemplateCheckoutRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateTemplateCheckoutRepository {
    override suspend fun updateTemplateCheckout(
        userId: String,
        dataValue: MutableSet<String>
    ): UpdateTemplateCheckoutModel {


        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation updateCategory_cashRegisters(${'$'}id: ID!, ${'$'}fields: CategoryFields!) {
                  cashAssist {
                    updateCategory(id: ${'$'}id, fields: ${'$'}fields) {
                      ${ApiUtils.Field.id}
                      ${ApiUtils.Field.mod_ver}
                      ${ApiUtils.Field.cashRegisterId}
                      ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                  }
                }
            """.trimIndent())

            putJsonObject("variables") {
                put("id", userId)
                putJsonObject("fields") {
                    put(ApiUtils.Field.cashRegisterId,Json.encodeToJsonElement(dataValue))
                }
            }
        }


        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {

            Json.decodeFromString<UpdateTemplateCheckoutModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }

}

