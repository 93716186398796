package org.hs_soft.runmynesto.domain.usecase.user

import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.CheckoutModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.CheckoutDataRepository

class CheckOutDataUseCase(
    private val repository: CheckoutDataRepository
) {
    suspend operator fun invoke(): CheckoutModel {
       return repository.getCheckout()
    }
}