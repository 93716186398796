package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.config.transitionBorder
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun DateNumberItem(
    number:Int,
    currentMonth:String,
    currentYear:Int,
    monthNumber:Int,
    onSelectNumber:(MonthModel)->Unit,
    selectedDate: MonthModel?,
)
{
    val isHovered = remember { mutableStateOf(false) }

    Box(
        modifier = Modifier
            .size(Dimen.DateItemSize)
            .onClick {
                onSelectNumber(
                    MonthModel(
                        dayNumber = number,
                        monthName = currentMonth,
                        yearNumber = currentYear,
                        days = listOf(),
                        monthNumber =monthNumber
                    )
                )
            }
            .borderRadius(50.percent)
            .onMouseEnter { isHovered.value=true}
            .onMouseLeave { isHovered.value=false }
            .cursor(Cursor.Pointer)
            .transitionBackground()
            .backgroundColor(
                if(selectedDate?.dayNumber ==number
                    && selectedDate.monthNumber == monthNumber
                    && selectedDate.yearNumber==currentYear
                )
                    Theme.Blue.rgb
                else Theme.Transparent.rgb
            )
            .border(
                width =Dimen.smallThickness,
                style = LineStyle.Solid,
                color =if(selectedDate?.dayNumber ==number
                            && selectedDate.monthNumber == monthNumber
                    && selectedDate.yearNumber==currentYear
                        ){
                        Theme.Blue.rgb
                    }

                    else{
                            if(isHovered.value) Theme.GrayAlpha56.rgb
                            else Theme.Transparent.rgb
                        }

            )
            .transitionBorder()

    )
    {
        SpanText(
            text =number.toString(),
            modifier = Modifier
                .align(Alignment.Center)
                .color(
                    if(selectedDate?.dayNumber ==number
                        && selectedDate.monthNumber == monthNumber
                        && selectedDate.yearNumber==currentYear
                    ){
                        Theme.White.rgb
                    }
                    else Theme.Primary.rgb
                )
                .fontSize(FontSizes.semiMediumFontSize)
                .fontFamily(FontFamily.Montserrat)
        )
    }



}