package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card

import org.hs_soft.runmynesto.domain.config.Strings

enum class TransactionColumnsEnum(
    val title: String,
    val value: Boolean,
) {
    FROM_DATE(Strings.FROM_DATE, true),
    GROUP(Strings.GROUP, false),
    USER_NAME(Strings.USER_NAME, true),
    BALANCE(Strings.BALANCE, false),
    BOOKING_MODE(Strings.BOOKING_MODE, false),
    UNTIL_DATE(Strings.UNTIL_DATE, true),
    CHECKOUT(Strings.CHECKOUT, true),
    CREDIT(Strings.CREDIT, true),
    POINTS(Strings.POINTS, false),
    CHECKOUT_DATE(Strings.CHECKOUT_DATE, false),
    DESIGNATION(Strings.DESIGNATION, true),
    DOCUMENT_NUMBER(Strings.DOCUMENT_NUMBER, true),
    BURDEN(Strings.BURDEN, false),
    POINTS_BALANCE(Strings.POINTS_BALANCE, false),
    CASH_REGISTER_TIME(Strings.CASH_REGISTER_TIME, false)
}