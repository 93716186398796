package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetVirtualCardConfig(
    @SerialName("accountIdLabel")
    val accountIdLabel: String?,
    @SerialName("backgroundColor")
    val backgroundColor: String?,
    @SerialName("classSuffix")
    val classSuffix: String?,
    @SerialName("foregroundColor")
    val foregroundColor: String?,
    @SerialName("heroGoogleImg")
    val heroGoogleImg: String?,
    @SerialName("homepageUri")
    val homepageUri: HomepageUri?,
    @SerialName("isActive")
    val isActive: Boolean?,
    @SerialName("isDefault")
    val isDefault: Boolean?,
    @SerialName("issuerEmail")
    val issuerEmail: String?,
    @SerialName("issuerName")
    val issuerName: String?,
    @SerialName("labelColor")
    val labelColor: String?,
    @SerialName("linkPhoneUri")
    val linkPhoneUri: LinkPhoneUri?,
    @SerialName("logoImg")
    val logoImg: String?,
    @SerialName("programName")
    val programName: String?,
    @SerialName("rewardsTier")
    val rewardsTier: String?,
    @SerialName("rewardsTierLabel")
    val rewardsTierLabel: String?,
    @SerialName("secondaryRewardsTier")
    val secondaryRewardsTier: String?,
    @SerialName("secondaryRewardsTierLabel")
    val secondaryRewardsTierLabel: String?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("wideLogoGoogleImg")
    val wideLogoGoogleImg: String?,
    @SerialName("wideLogoImg")
    val wideLogoImg: String?
)