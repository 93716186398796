package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Device(
    @SerialName("appManagerVersion")
    val appManagerVersion: String?,
    @SerialName("deviceStatus")
    val deviceStatus: String?,
    @SerialName("__typename")
    val typename: String?
)