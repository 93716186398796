package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components

import androidx.compose.runtime.Composable
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.LoyaltyCardTypeEnum
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.dialogs.SelectTransactionColumnsDialog
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardEvent
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardState

@Composable
fun CustomerCardDialogs(
    windowSizeMode: WindowSizeModeEnum,
    state: CustomerCardState,
    event:(CustomerCardEvent)->Unit,
) {
    if (state.showAddNewCustomerCardDialog)
    {
        AddNewCustomerCardDialog(
            loading = state.dataLoadingProgressVisible,
            onCancelClick = {
               event(CustomerCardEvent.CloseAddCustomerCardDialog)
            },
            onSubmitClick = {cardType:LoyaltyCardTypeEnum,cardName:String,phoneNumber:String->
                when(cardType)
                {
                    LoyaltyCardTypeEnum.DIGITAL->{
                        event(CustomerCardEvent.CreateLoyaltyCard(
                            validPhoneNumber = phoneNumber,
                            isVirtual = true,
                            name = cardName

                        ))
                    }

                    LoyaltyCardTypeEnum.RFID->{
                        event(CustomerCardEvent.CreateLoyaltyCard(
                            validPhoneNumber = phoneNumber,
                            isActive = true

                        ))
                    }
                }
            },
            onBackgroundClick = {

            }
        )
    }


    if (state.showTransactionColumnsDialog)
    {
        SelectTransactionColumnsDialog(
            windowSizeMode=windowSizeMode,
            initialTransactionColumns=state.selectedTransactionColumns,
            onBackgroundClick = {
                event(CustomerCardEvent.CloseTransactionColumnsDialog)
            },
            onCloseDialog = {
                event(CustomerCardEvent.CloseTransactionColumnsDialog)
            },
            toggleTransactionColumn = {
                event(CustomerCardEvent.ToggleTransactionColumn(it))
            }

        )
    }










}