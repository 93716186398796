package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.calendar_filter_dialog.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.percent

@Composable
fun Pressets(
    onClick:()->Unit,
) {
    Row(
        modifier = Modifier
            .rippleEffect {
                onClick()
            }
            .padding(Dimen.mediumPadding),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    )
    {
        VectorIcon(
            pathData = Res.PathIcon.pressets,
            color = Theme.Blue.hex,
            width = Dimen.extraSmallIcon,
            height = Dimen.extraSmallIcon
        )

        SpanText(
            text = Strings.pressets.uppercase(),
            modifier = Modifier
                .cursor(Cursor.Pointer)
                .padding(Dimen.normalPadding )
                .borderRadius(50.percent)
                .margin(left = Dimen.normalPadding)
                .fontSize(FontSizes.semiMediumFontSize)
                .fontWeight(FontWeight.Medium)
                .fontFamily(FontFamily.Montserrat)
        )

    }
}