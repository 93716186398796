package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("getCashRegister")
    val getCashRegister: GetCashRegister?=null,
    @SerialName("updateCashRegister")
    val updateCashRegister: GetCashRegister?=null,
    @SerialName("__typename")
    val typename: String?
)