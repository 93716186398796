package org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi

import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.StatisticHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.ABCStatisticGroupByEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data.BillSearchStatisticDataItem
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel

sealed class StatisticEvent
{
    data class SetPdfPreviewDialogStatus(
        val status: Boolean
    ):StatisticEvent()

    data object CloseAllDialogs:StatisticEvent()

    data class ExportExcelFile(
        val data: List<BillSearchStatisticDataItem>
    ):StatisticEvent()

    data class BillPdf(
        val billDate:String,
        val billNumber:Int,
        val cashRegisterId:String,
    ):StatisticEvent()

    data class OnSelectBillSearchStatisticItem(
        val item: BillSearchStatisticDataItem
    ):StatisticEvent()

    data class OnSearchInSelectedBillSearchItem(
        val searchValue:String
    ):StatisticEvent()


    data class OnSearchInBillSearchItems(
        val searchValue:String,
    ):StatisticEvent()

    data class SetCheckoutDialogStatus(
        val status: Boolean
    ):StatisticEvent()

    data class UpdateSelectedCheckout(
        val cashRegisterIds: List<String>
    ):StatisticEvent()

    data class OnTabHeaderClick(val tab: StatisticHeaderEnum)
        :StatisticEvent()

    data class SetDaysFilterDialogStatus(val show:Boolean)
        :StatisticEvent()

    data class SubmitStatisticDaysFilter(
        val from:String,
        val to:String,
        val periodDaysType: PeriodDaysEnum,
    ):StatisticEvent()

    data class SetFromCalendarFilterDialogStatus(val status:Boolean)
        :StatisticEvent()

    data class SetToCalendarFilterDialogStatus(val status:Boolean)
        :StatisticEvent()



    data class OnSelectFromDate(
        val selectedFromDate: MonthModel,
    ):StatisticEvent()

    data class OnSelectToDate(
        val selectedToDate: MonthModel,
    ):StatisticEvent()

    data class SubmitStatisticCalendarFilter(
        val from:String,
        val to:String,
    ):StatisticEvent()

    data class SetNewCalendarHeaderYear(val year:Int):StatisticEvent()


    data object GetCashRegisterIds: StatisticEvent()

    data class InitBillSearch(
        val billNumberFrom: Int?,
        val billNumberTo: Int?,
        val totalFrom: Int?,
        val totalTo: Int?,
    ):StatisticEvent()

    data object InitABCStatistic:StatisticEvent()

    data class UpdateGroupBy(
        val groupBy:ABCStatisticGroupByEnum
    ):StatisticEvent()

    data class OnSearchCheckoutItems(val value:String):
        StatisticEvent()

}