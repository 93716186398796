package org.hs_soft.runmynesto.domain.model.home.product.products_list_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ProductItem(
    @SerialName("checkOutCommandId")
    val checkOutCommandId: String?,
    @SerialName("code")
    val code: String?,
    @SerialName("FA_ProductId")
    val fAProductId: String?,
    @SerialName("id")
    val id: String,
    @SerialName("isActive")
    val isActive: Boolean?=null,
    @SerialName("name")
    val name: String?,
    @SerialName("price")
    val price: List<Double?>?,
    @SerialName("priceInCafe")
    val priceInCafe: List<Double?>?,
)