package org.hs_soft.runmynesto.domain.model.menu

import org.hs_soft.kobweb_aws_cognito.domain.model.menu.SubMenuModel

data class MenuItemModel(
    val id: MenuItemsEnum,
    val title:String,
    val img:String,
    val subData:List<SubMenuModel>,
)
