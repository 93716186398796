package org.hs_soft.runmynesto.data.api_datasource.template.tab_general

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.set_grid_product.SetGridProductModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.SetGridProductRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class SetGridProductRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
) : SetGridProductRepository {


    override suspend fun setGridProduct(
        categoryId: String,
        position: Int,
        productId: String?
    ): SetGridProductModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation setGridProduct(
                    ${'$'}categoryId: ID!,
                    ${'$'}position: Int!, 
                    ${'$'}productId: ID
                ) {
                  cashAssist {
                    setGridProduct(
                      categoryId:${'$'}categoryId
                      position:${'$'}position
                      productId:${'$'}productId
                    ) {
                      ...gridProduct
                      ${ApiUtils.Field.__typename}
                    }
                     ${ApiUtils.Field.__typename}
                  }
                }

                fragment gridProduct on GridProduct {
                   ${ApiUtils.Field.id}
                   ${ApiUtils.Field.mod_ver}
                   ${ApiUtils.Field.categoryId}
                   ${ApiUtils.Field.productId}
                   ${ApiUtils.Field.position}
                  product {
                     ${ApiUtils.Field.id}
                     ${ApiUtils.Field.name}
                     ${ApiUtils.Field.code}
                     ${ApiUtils.Field.price}
                    img {
                       ${ApiUtils.Field.uid}
                       ${ApiUtils.Field.key}
                       ${ApiUtils.Field.__typename}
                    }
                    category {
                       ${ApiUtils.Field.id}
                       ${ApiUtils.Field.color}
                       ${ApiUtils.Field.__typename}
                    }
                     ${ApiUtils.Field.__typename}
                  }
                   ${ApiUtils.Field.__typename}
                }

           
            """.trimIndent())

            putJsonObject("variables") {
                put("categoryId", categoryId)
                put("position",position)
                if (productId!=null)
                    put("productId",productId)

            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }

    }



}