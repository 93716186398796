package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_allergens

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.addJsonObject
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonArray
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.update_ingredient.UpdateIngredientModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_allergens.UpdateAllergenRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils


class UpdateAllergenRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateAllergenRepository {


    override suspend fun updateAllergen(
        productId: String,
        dataValue: Boolean,
        ingredientId: String
    ): UpdateIngredientModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            mutation updateProduct_ingredients(${'$'}id: ID!, ${'$'}fields: ProductFields) {
              cashAssist {
                updateProduct(id: ${'$'}id, fields: ${'$'}fields) {
                    ingredients {
                        ${ApiUtils.Field.id} 
                        ${ApiUtils.Field.contains}
                        ${ApiUtils.Field.ingredientId} 
                    } 
                }
              }
            }
        """.trimIndent())

            putJsonObject("variables") {
                put("id", productId)
                putJsonObject("fields") {
                    putJsonArray("ingredients") {
                        addJsonObject {
                            put("id", ingredientId)
                            put("contains", dataValue)
                        }
                    }
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<UpdateIngredientModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }

}



