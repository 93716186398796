package org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general

import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.upload_img.UploadTemplateCategoryImgModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.UpdateTemplateCategoryImgRepository

class UpdateTemplateCategoryImgUseCase(
    private val repository: UpdateTemplateCategoryImgRepository
) {
    suspend operator fun invoke(
        id:String,
        img:String
    ): UploadTemplateCategoryImgModel {
       return repository.updateTemplateCategoryImg(
            img=img,
            id=id
        )
    }
}