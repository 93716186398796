package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.select_days

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderTop
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun CheckBoxDay(
    isChecked:Boolean,
    title:String,
    onChecked:()->Unit,
    onUnChecked:()->Unit,
)
{
    Column(
       modifier = Modifier.fillMaxWidth()
    ) {
        Row(
            modifier = Modifier
                .borderTop(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayCD.rgb
                )
                .padding(Dimen.normalPadding)
                .fillMaxWidth()
        ){
            CheckboxComponent(
                modifier = Modifier,
                checkStatus = isChecked,
                title =title ,
                onChecked = {
                    onChecked()
                },
                onUnChecked = {
                    onUnChecked()
                },

            )


        }

    }

    
}