package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.periods.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.color
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodMonthEnum
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.RadioGroup
import org.jetbrains.compose.web.dom.RadioInput

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun PeriodsMonth(
    selectedPeriodType: PeriodMonthEnum?,
    onSelectPeriodType:(PeriodMonthEnum)->Unit,
)
{

    RadioGroup(selectedPeriodType?.title) {
        PeriodMonthEnum.entries.chunked(2).forEachIndexed { rowIndex, rowItems ->
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .margin(
                        topBottom = Dimen.smallPadding,
                    ),
            )
            {
                rowItems.forEachIndexed { colIndex, model ->

                    Row(
                        modifier = Modifier
                            .cursor(Cursor.Pointer)
                            .rippleEffect {
                                if (model != selectedPeriodType)
                                    onSelectPeriodType(model)
                            }
                            .width(Dimen.periodMonthWidth),
                        verticalAlignment = Alignment.CenterVertically,
                    )
                    {
                        RadioInput(
                            value = model.title,
                            attrs = Modifier
                                .padding(Dimen.normalPadding)
                                .cursor(Cursor.Pointer)
                                .toAttrs {
                                    style {
                                        color(Theme.Secondary.hex)
                                        width(Dimen.mediumRadioButton)
                                        height(Dimen.mediumRadioButton)
                                    }

                                    checked(model == selectedPeriodType)

                                },
                        )

                        SpanText(
                            text = model.title,
                            modifier = Modifier
                                .cursor(Cursor.Pointer)
                                .margin(left = Dimen.normalPadding)
                                .fontSize(FontSizes.semiLargeFontSize)
                                .color(Theme.Secondary.rgb)
                                .fontWeight(FontWeight.Medium)
                                .fontFamily(FontFamily.Montserrat)
                        )
                    }

                }

            }


        }
    }


}
