package org.hs_soft.runmynesto.domain.usecase.product.tab_product_general

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.update_product.UpdateProductModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.UpdateProductRepository


class UpdateProductUseCase(
    private val repository: UpdateProductRepository
) {
    suspend operator fun invoke(
        productId: String,
        dataTitle:String,
        dataValue:dynamic,
    ): UpdateProductModel {
        return repository.updateProduct(
            productId=productId,
            dataTitle=dataTitle,
            dataValue=dataValue,
        )
    }
}