package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreditCards(
    @SerialName("clientButtonName")
    val clientButtonName: List<ClientButtonName?>?,
    @SerialName("isPiecemeal")
    val isPiecemeal: List<IsPiecemeal?>?,
    @SerialName("receiptCopies")
    val receiptCopies: List<ReceiptCopy?>?,
    @SerialName("startPiecemeal")
    val startPiecemeal: List<StartPiecemeal?>?,
    @SerialName("__typename")
    val typename: String?
)