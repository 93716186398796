package org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.customer_card_tab

import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.credit_client.CreditClientModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.customer_card_tab.CreditClientRepository

class CreditClientUseCase(
    private val repository: CreditClientRepository
)
{
    suspend operator fun invoke(): CreditClientModel {
        return repository.getCreditClient()
    }

}