package org.hs_soft.runmynesto.data.api_datasource.customer_card.tabs.customer_card_tab

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail.LoyaltyCardDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.customer_card_tab.UpdateLoyaltyCardRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateLoyaltyCardRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateLoyaltyCardRepository
{
    override suspend fun updateLoyaltyCard(
        id:String,
        dataTitle:String,
        dataValue:dynamic,
    ): LoyaltyCardDetailModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation  updateLoyaltyCard(${'$'}id: ID!,${'$'}fields:LoyaltyCardFields) {
                  cashAssist {
                    updateLoyaltyCard(id: ${'$'}id,fields:${'$'}fields) {
                      ${ApiUtils.Field.id}: idTemp
                      ${ApiUtils.Field.mod_ver}
                      ...immutable
                      ...loyaltyCardMain
                      ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                  }
                }
            
                fragment loyaltyCardMain on LoyaltyCard {
                  ${ApiUtils.Field.name}
                  ${ApiUtils.Field.isActive}
                  ${ApiUtils.Field.lastUpdatedAt}
                  ${ApiUtils.Field.isPointsActive}
                  ${ApiUtils.Field.limit}
                  ${ApiUtils.Field.dayLimit}
                  ${ApiUtils.Field.clientName}
                  ${ApiUtils.Field.clientSurname}
                  ${ApiUtils.Field.salutation}
                  ${ApiUtils.Field.additive}
                  ${ApiUtils.Field.isUser}
                  ${ApiUtils.Field.userId}
                  ${ApiUtils.Field.user} {
                    ${ApiUtils.Field.id}
                    ${ApiUtils.Field.name}
                    ${ApiUtils.Field.__typename}
                  }
                  ${ApiUtils.Field.street}
                  ${ApiUtils.Field.postcode}
                  ${ApiUtils.Field.city}
                  ${ApiUtils.Field.validPhoneNumber}
                  ${ApiUtils.Field.email}
                  ${ApiUtils.Field.type}
                  ${ApiUtils.Field.creditClientId}
                  ${ApiUtils.Field.creditClient} {
                    ${ApiUtils.Field.id}
                    ${ApiUtils.Field.name}
                    ${ApiUtils.Field.__typename}
                  }
                  ${ApiUtils.Field.discount} {
                    ${ApiUtils.Field.freePercent}
                    ${ApiUtils.Field.masterPercent}
                    ${ApiUtils.Field.numberOfPercent}
                    ${ApiUtils.Field.__typename}
                  }
                  ${ApiUtils.Field.balanceDiscount}
                  ${ApiUtils.Field.alwaysDiscount}
                  ${ApiUtils.Field.cashLoad}
                  ${ApiUtils.Field.balancePoints}
                  ${ApiUtils.Field.barCode}
                  ${ApiUtils.Field.statusVirtual} {
                    ${ApiUtils.Field.type}
                    ${ApiUtils.Field.__typename}
                  }
                  ${ApiUtils.Field.__typename}
                }
            
                fragment immutable on LoyaltyCard {
                  ${ApiUtils.Field.accessCode}
                  ${ApiUtils.Field.balance}
                  ${ApiUtils.Field.points}
                  ${ApiUtils.Field.rfid}
                  ${ApiUtils.Field.virtualCardLink}
                  ${ApiUtils.Field.__typename}
                }
            """.trimIndent())

            putJsonObject("variables") {
                put("id",id)
                putJsonObject("fields"){
                    when(dataValue){
                        is String -> put(dataTitle,dataValue as String)
                        is Boolean ->put(dataTitle,dataValue as Boolean )
                        is Int -> put(dataTitle,dataValue as Int)
                        is JsonObject -> put(dataTitle, dataValue as JsonElement)
                    }
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }
    }

}