package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.update_products_list_position


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("replaceGridProducts")
    val replaceGridProducts: List<ReplaceGridProduct?>
)