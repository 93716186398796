package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.select_days

import org.hs_soft.runmynesto.domain.config.Strings

enum class DayWeekEnum(val value: Int, val title: String) {
    ALL_DAYS(127, Strings.allDays),
    MONDAY(1, Strings.mondayFull),
    TUESDAY(2, Strings.tuesdayFull),
    WEDNESDAY(4, Strings.wednesdayFull),
    THURSDAY(8, Strings.thursdayFull),
    FRIDAY(16, Strings.fridayFull),
    SATURDAY(32, Strings.saturdayFull),
    SUNDAY(64, Strings.sundayFull);
}
