package org.hs_soft.runmynesto.pages.home.sub_page.options.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.pages.components.AddItem
import org.hs_soft.runmynesto.pages.components.FilterComponent
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnitLength


@Composable
fun FilterRow(
    onAddRecordClick:()->Unit,
    width: CSSSizeValue<out CSSUnitLength> = Dimen.gridWidth,
    onFilterClick:()->Unit,
)
{
    Row(
        modifier = Modifier
            .width(width),
        verticalAlignment = Alignment.CenterVertically
    )
    {
        FilterComponent(
            onFilterClick={
                onFilterClick()
            }
        )

        Spacer()

        AddItem {
            onAddRecordClick()
        }


    }
}

