package org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChildrenXXX(
    @SerialName("deviceType")
    val deviceType: String?,
    @SerialName("hardwareId")
    val hardwareId: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("name")
    val name: String?,
    @SerialName("tseRemoteCashRegisterId")
    val tseRemoteCashRegisterId: String?,
    @SerialName("__typename")
    val typename: String?
)