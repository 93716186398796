package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_restaurant

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.create_product_addition.ProductAdditionModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.CreateProductAdditionRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.UpdateProductAdditionRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateProductAdditionRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateProductAdditionRepository {



    override suspend fun updateProductAddition(
        id:String,
        name: String,
        variants: List<String?>
    ): ProductAdditionModel {

        val abortController = requestManager.createController()


        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation updateProductAddition( ${'$'}id:ID!, ${'$'}fields: ProductAdditionInput) {
                  cashAssist {
                    updateProductAddition(id:${'$'}id ,fields: ${'$'}fields) {
                      ${ApiUtils.Field.id}
                      ${ApiUtils.Field.name}
                      ${ApiUtils.Field.variants} 
                    } 
                  }
                }
            """.trimIndent())

            putJsonObject("variables") {
                put("id",id)
                putJsonObject("fields") {
                    put("name", name)
                    put("variants",Json.encodeToJsonElement(variants as List<String?>))

                }
            }

        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<ProductAdditionModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }

}
