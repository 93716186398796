package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.TimeZone
import kotlinx.datetime.minus
import kotlinx.datetime.toLocalDateTime
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.model.home.closing_reports.FinalReportsGroupEnum
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model.ClosingReportItem
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.usecase.closing_report.ClosingReportDetailUseCase
import org.hs_soft.runmynesto.domain.usecase.closing_report.ClosingReportsUseCase
import org.hs_soft.runmynesto.domain.usecase.closing_report.PrintClosingReportsUseCase
import org.hs_soft.runmynesto.domain.usecase.home.GetCompanyProfileIdUseCase
import org.hs_soft.runmynesto.domain.usecase.home.GetSavedCheckOutUseCase
import org.hs_soft.runmynesto.domain.util.downloadFile
import org.hs_soft.runmynesto.domain.util.extractAllDevicesIds
import org.hs_soft.runmynesto.domain.util.getFromDate
import org.hs_soft.runmynesto.domain.util.getToDate
import org.hs_soft.runmynesto.domain.util.getUpdatedSelectedYear
import org.hs_soft.runmynesto.domain.util.handleError
import org.hs_soft.runmynesto.domain.util.helper.BaseViewModel
import org.hs_soft.runmynesto.domain.util.withTokenRefresh

class ClosingReportsViewModel(
    private val requestManager: RequestManager,
    private val refreshToken: () -> Unit,
    private val getSavedCheckOutUseCase: GetSavedCheckOutUseCase,
    private val closingReportsUseCase: ClosingReportsUseCase,
    private val closingReportDetailUseCase: ClosingReportDetailUseCase,
    private val getCompanyProfileIdUseCase: GetCompanyProfileIdUseCase,
    private val printClosingReportsUseCase: PrintClosingReportsUseCase,
):BaseViewModel()
{
    private val _state = MutableStateFlow(ClosingReportsState())
    val state: StateFlow<ClosingReportsState> = _state
    private var tempCheckoutList: List<Children>? = listOf()
    private var tempClosingReportsList:List<ClosingReportItem> ?= null


    override  fun clear() {
        super.clear()
        requestManager.cancelAll()
    }


    fun onEvent(event: ClosingReportsEvent) {
        when (event) {


            is ClosingReportsEvent.ExtractAllDevicesIds->{
                _state.value=_state.value.copy(
                    selectedCashRegisterIds = extractAllDevicesIds( state.value.cashList)
                )
            }

            is ClosingReportsEvent.UpdateCashRegisterIds->{
                updateCashRegisterIds(cashRegisterIds=event.newCashRegisterIds)
            }


            is ClosingReportsEvent.SubmitFinancialGroup->{
                submitSelectingFinancialGroup(
                    group=event.group
                )
            }

            is ClosingReportsEvent.SetFinancialGroupStatus->{
                setFinancialGroup(status=event.status)
            }

            is ClosingReportsEvent.OnChangePageClick->{
                _state.value=_state.value.copy(
                    showFirstPage = !state.value.showFirstPage
                )

            }

            is ClosingReportsEvent.SetCompanyProfileId->{
                _state.value=_state.value.copy(
                    companyProfileId = getCompanyProfileIdUseCase()
                )
            }

            is ClosingReportsEvent.SetNewCalendarHeaderYear->{
                _state.value=_state.value.copy(
                    selectedCalendarHeaderYear = event.year
                )
            }

            is ClosingReportsEvent.OnSelectDate->{

                onSelectDate(
                    selectedDate = event.selectedDate,
                    cashRegisterIds = event.cashRegisterIds,
                )

            }

            is ClosingReportsEvent.OnClosingReportClick->{
                onClosingReportClick(id = event.id)
            }
            is ClosingReportsEvent.SearchInClosingReports->{
                searchInClosingReports(value = event.value)
            }

            is ClosingReportsEvent.OnSearchCheckoutItems->{
                searchCheckoutItems(value=event.value)
            }

            is ClosingReportsEvent.SetCalendarFilterDialogStatus->{
                _state.value=_state.value.copy(
                    showCalendarFilterDialog =event.status,
                    showDaysFilterDialog = false
                )
            }
            is ClosingReportsEvent.SubmitClosingReportsCalendarFilter->{
                submitClosingReportsCalendarFilter(
                    from=event.from,
                    to=event.to,
                    cashRegisterIds=event.cashRegisterIds,
                )

            }

            is ClosingReportsEvent.SubmitClosingReportsDaysFilter->{
                submitClosingReportsDaysFilter(
                    from=event.from,
                    to=event.to,
                    selectedPeriodType=event.periodDaysType,
                    cashRegisterIds = event.cashRegisterIds,

                )


            }

            is ClosingReportsEvent.GetCheckoutData -> {
                getCheckoutData()
            }
            is ClosingReportsEvent.GetClosingReports -> {
                getClosingReports(
                    from=event.from,
                    to=event.to,
                    cashRegisterIds = event.cashRegisterIds,
                )
            }


            is ClosingReportsEvent.SetReportsTableAndDevicesVisibilityStatus->{
                _state.value=_state.value.copy(
                    showReportsTableAndDevices = event.showReportsTableAndDevices
                )
            }

            is ClosingReportsEvent.SetDaysFilterDialogStatus->{
                _state.value=_state.value.copy(
                    showDaysFilterDialog = event.show,
                    showCalendarFilterDialog = false
                )
            }
        }
    }

    private fun updateCashRegisterIds(cashRegisterIds: List<String>) {
         _state.value=_state.value.copy(
             selectedCashRegisterIds = cashRegisterIds
         )

        if (state.value.selectedFromDate != null) {

            getClosingReports(
                from = state.value.selectedFromDate?:"",
                to=state.value.selectedToDate?:"",
                cashRegisterIds=state.value.selectedCashRegisterIds?: listOf(),
            )

        }

    }

    private fun submitSelectingFinancialGroup(
        group: FinalReportsGroupEnum,
    ) {
        _state.value=_state.value.copy(
            selectedFinancialGroup = group
        )

        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()

                    val checkOutData=getSavedCheckOutUseCase()?.data?.cashAssist?.children

                    val departments = mutableSetOf<String>()

                    checkOutData?.forEach { children ->
                        // Check if any childrenXXX.id is in selectedCashRegisterIds
                        val hasMatchingChild = children.children?.any { childrenX ->
                            childrenX.children?.any { childrenXX ->
                                childrenXX.children.any { childrenXXX ->
                                    state.value.selectedCashRegisterIds?.contains(childrenXXX.id) == true
                                }
                            } == true
                        } == true

                        // If a match is found, add the department name to the set
                        if (hasMatchingChild && children.name!=null) {
                            departments.add(children.name)
                        }
                    }
 

                    //---------------------------------------------------------

                    val result= printClosingReportsUseCase(
                        from =state.value.selectedFromDate?:"" ,
                        to=state.value.selectedToDate?:"",
                        departments =departments.toMutableList(),
                        groupBy =group.value,
                        cacheKey =state.value.printedClosingReports?.data
                            ?.reports?.getClosingsReport?.pagination?.cacheKey,
                    )

                    _state.value=_state.value.copy(
                        printedClosingReports = result
                    )
                    val url=result.data?.reports?.getClosingsReport?.url

                    if (url != null) {
                        val fileName = "closingReports.pdf"
                        downloadFile(
                            url = url,
                            fileName= fileName,
                        )
                        _state.value=_state.value.copy(
                            showFinancialGroupDialog = false
                        )
                        hideProgress()
                    }else{
                        submitSelectingFinancialGroup(group=group)

                    }

                }

            }catch (e:Exception){
                hideProgress()
                _state.value=_state.value.copy(
                    showFinancialGroupDialog = false
                )
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun setFinancialGroup(status: Boolean) {
        _state.value=_state.value.copy(
            showFinancialGroupDialog = status
        )
    }

    private fun onSelectDate(
        selectedDate: MonthModel,
        cashRegisterIds: List<String>
    ) {
        _state.value=_state.value.copy(
            selectedDate = selectedDate,
            selectedFromDate = getFromDate( selectedDate),
            selectedToDate = getToDate( selectedDate)
        )

        getClosingReports(
            from=state.value.selectedFromDate?:"",
            to=state.value.selectedToDate?:"",
            cashRegisterIds = cashRegisterIds,
        )
    }

    private fun submitClosingReportsDaysFilter(
        from: String,
        to: String,
        selectedPeriodType: PeriodDaysEnum,
        cashRegisterIds: List<String>
    ) {
        _state.value=_state.value.copy(
            showDaysFilterDialog = false,
            selectedFromDate = from,
            selectedToDate = to,
            selectedPeriodType = selectedPeriodType,
        )
        val today = Clock.System.now().toLocalDateTime(TimeZone.UTC).date

        if (selectedPeriodType == PeriodDaysEnum.TODAY) {
            _state.value = _state.value.copy(
                selectedDate = MonthModel(
                    dayNumber = today.dayOfMonth,
                    monthName = today.month.name,
                    yearNumber = today.year,
                    monthNumber = today.monthNumber
                )
            )
        } else if (selectedPeriodType == PeriodDaysEnum.YESTERDAY) {
            val yesterday = today.minus(1, DateTimeUnit.DAY)
            _state.value = _state.value.copy(
                selectedDate = MonthModel(
                    dayNumber = yesterday.dayOfMonth,
                    monthName = yesterday.month.name,
                    yearNumber = yesterday.year,
                    monthNumber = yesterday.monthNumber
                )
            )
        }else{
            _state.value=_state.value.copy(
                selectedDate = null
            )
        }
        getClosingReports(
            from= from,
            to= to,
            cashRegisterIds = cashRegisterIds,
        )

    }

    private fun submitClosingReportsCalendarFilter(
        from: String,
        to: String,
        cashRegisterIds: List<String>
    ) {

        _state.value=_state.value.copy(
            selectedToDate =  to.getUpdatedSelectedYear(state.value.selectedCalendarHeaderYear),
            selectedFromDate = from.getUpdatedSelectedYear(state.value.selectedCalendarHeaderYear),
        )

        getClosingReports(
            from= _state.value.selectedFromDate?:"",
            to= _state.value.selectedToDate?:"",
            cashRegisterIds = cashRegisterIds,
        )
    }


    private fun searchInClosingReports(value: String) {
        _state.value = _state.value.copy(closingReports = tempClosingReportsList)

        val newData = _state.value.closingReports?.filter { model ->
            val dateMatches = model.dateTime?.contains(value, ignoreCase = true) ?: false
            val numberMatches = model.number.toString().contains(value, ignoreCase = true)
            val cashRegisterMatches = model.cashRegister?.name?.contains(value, ignoreCase = true) ?: false
            val totalMatches = model.total.toString().contains(value, ignoreCase = true)

            dateMatches || numberMatches || cashRegisterMatches || totalMatches
        }

        _state.value = _state.value.copy(
            closingReports = newData
        )
    }
    private fun searchCheckoutItems(value: String)
    {
        _state.value= _state.value.copy(cashList =tempCheckoutList )

        if (value == "") {
            _state.value = _state.value.copy(isDevicesExpandAll = false,)
        } else {
            val searchedList = ArrayList<Children>()
            state.value.cashList?.forEach { model ->
                var matchFound = model.name?.lowercase()?.contains(value.lowercase()) ?: false
                model.children?.forEach { childX ->
                    if (childX.name?.lowercase()?.contains(value.lowercase()) == true) {
                        matchFound = true
                    }
                    childX.children?.forEach { childXX ->
                        if (childXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                            matchFound = true
                        }
                        childXX.children.forEach {childXXX->
                            if (childXXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                                matchFound = true
                            }
                        }
                    }
                }
                if (matchFound) {
                    searchedList.add(model)
                }
            }


            _state.value=_state.value.copy(
                isDevicesExpandAll = true,
                cashList = searchedList
            )

        }
    }

    private fun onClosingReportClick(
        id:String
    ){
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result= closingReportDetailUseCase(
                        id=id
                    )
                    _state.value=_state.value.copy(
                        selectedClosingReport= result,
                        showFirstPage = false
                    )
                    tempClosingReportsList=state.value.closingReports
                    hideProgress()

                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }
    }

    private fun getClosingReports(
        from: String,
        to: String,
        cashRegisterIds:List<String>,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result= closingReportsUseCase(
                        from=from,
                        to = to,
                        cashRegisterIds=cashRegisterIds,

                    )
                    _state.value=_state.value.copy(
                        closingReports=result.data?.cashAssist?.listClosings?.items,
                        showCalendarFilterDialog = false,
                        showDaysFilterDialog = false,
                    )
                    if (!_state.value.closingReports.isNullOrEmpty()){
                        onClosingReportClick(
                            _state.value.closingReports?.get(0)?.id?:""
                        )
                    }else{
                        _state.value=_state.value.copy(
                            selectedClosingReport = null
                        )
                        hideProgress()
                    }


                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }

    }


    private fun getCheckoutData() {
         launch {
            if (state.value.cashList==null){

                val result=getSavedCheckOutUseCase()

                _state.value=_state.value.copy(
                    cashList = result?.data?.cashAssist?.children
                )
                tempCheckoutList=state.value.cashList

            }

        }

    }

    private fun showProgress()
    {
        _state.value=_state.value.copy(
            loadingProgress = true
        )
    }

    private fun hideProgress()
    {
        _state.value=_state.value.copy(
            loadingProgress = false
        )
    }

}