package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component.mobile

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.Job
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.overflowText
import org.hs_soft.runmynesto.domain.model.home.closing_reports.CurrencyTypeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.ArticleModel
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component.WaiterHoverBackground
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun MobileWaiterTilesTypeItem(
    model: ArticleModel,
    currencyType: CurrencyTypeEnum,
    showImgUrl: suspend (String) -> String,
    deleteGridProduct:()->Unit,
)
{
    val imageUrl= remember { mutableStateOf("") }

    LaunchedEffect(model.product) {
        model.product?.img?.key?.let { key ->
            imageUrl.value = showImgUrl(key)
        }
    }
    val isHovered= remember { mutableStateOf(false) }

    Box(
        modifier = Modifier
            .onMouseOver { isHovered.value=true }
            .onMouseLeave { isHovered.value=false }
            .margin(Dimen.semiSemiNormalPadding)
            .size(Dimen.mobileWaiterTilesTypeItemSize)
    )
    {
        Box(
            modifier = Modifier.fillMaxSize()
        )
        {
            if (model.product?.img!=null){
                Image(
                    src = imageUrl.value,
                    modifier = Modifier
                        .borderRadius(Dimen.normalBorderRadius)
                        .fillMaxSize()
                        .border(
                            width = Dimen.smallThickness,
                            style = LineStyle.Solid,
                            color = Theme.GrayCD.rgb
                        )
                )
            }else{
                Box(
                    modifier = Modifier
                        .borderRadius(Dimen.normalBorderRadius)
                        .fillMaxSize()
                        .border(
                            width = Dimen.smallThickness,
                            style = LineStyle.Solid,
                            color = Theme.GrayCD.rgb
                        )
                )
            }

            Box(
                modifier = Modifier
                    .fillMaxSize()
                    .borderRadius(Dimen.normalBorderRadius)
                    .backgroundColor(Theme.GrayAlpha60.rgb)
            )

        }

        Column(
            modifier = Modifier
                .padding(Dimen.semiMediumPadding)
                .fillMaxSize()
        )
        {
            SpanText(
                text =model.product?.name?:"" ,
                modifier = Modifier
                    .fontFamily(FontFamily.Jakarta)
                    .fontSize(FontSizes.semiMediumFontSize)
                    .fontWeight(FontWeight.SemiBold)
//                    .overflowText()
                    .fillMaxWidth()
                    .margin(right = Dimen.semiMediumPadding)
                    .color(Theme.White.rgb)

            )

            Spacer()

            SpanText(
                text =currencyType.title + " "+ formatToTwoDecimals(
                    model.product?.price?.filterNotNull()?.minOrNull() ?: 0.00
                ),
                modifier = Modifier
                    .fontFamily(FontFamily.Jakarta)
                    .fontSize(FontSizes.highNormalFontSize)
                    .fontWeight(FontWeight.Medium)
                    .color(Theme.White.rgb)

            )




        }

        WaiterHoverBackground(
            isHovered = isHovered.value,
            onDeleteClick = {
                deleteGridProduct()
            }
        )

    }

}