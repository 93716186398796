package org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.create_product_addition.ProductAdditionModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.CreateProductAdditionRepository


class CreateProductAdditionUseCase(
    private val repository: CreateProductAdditionRepository
) {
    suspend operator fun invoke(
        name:String,
        variants:List<String?>,
    ): ProductAdditionModel {
        return repository.createProductAddition(
            name=name,
            variants=variants
        )
    }


}