package org.hs_soft.runmynesto.pages.home.sub_page.dashboard

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart.DashboardCharts
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_reports.DashboardList
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.mvi.DashboardViewModel

@Composable
fun DashBoard(
    windowSizeMode: WindowSizeModeEnum,
    viewModel: DashboardViewModel
)
{
    val state by viewModel.state.collectAsState()


    if (!state.showDashboardDetail){

        DashboardList(
            windowSizeMode=windowSizeMode,
            state=state,
            event=viewModel::onEvent
        )


    }else{
        DashboardCharts(
            state=state,
            event=viewModel::onEvent,
            windowSizeMode = windowSizeMode,
        )
    }


    LoadingProgress(
        visibility = state.dataLoadingProgressVisible
    )



}

