package org.hs_soft.runmynesto.data.api_datasource.product.tabs

import kotlinx.coroutines.suspendCancellableCoroutine
import org.hs_soft.runmynesto.Storage
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.ImgUrlRepository
import org.hs_soft.runmynesto.domain.util.jsObject
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException

class GetImageUrlRepositoryImpl: ImgUrlRepository {

    override suspend fun getImgUrl(key: String): String =
            suspendCancellableCoroutine { continuation ->
        val options = jsObject {
            this["key"] = key
        }

        Storage.getUrl(options).then { result ->
            continuation.resume(result.url)
        }.catch { error: dynamic ->
            console.log(error)
            continuation.resumeWithException(
                Exception("Failed to get image URL: $error")
            )
        }


        TODO("Add Cancellable Logic")
    }

}
