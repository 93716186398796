package org.hs_soft.runmynesto.pages.components.top_toolbar.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.home.user_profile_info.GetUserProfileData
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun TopToolbarUserInfo(
    windowSizeMode: WindowSizeModeEnum,
    profileInfo: GetUserProfileData?,
    showSearchBarInMobileMode: Boolean,
    changeShowingSearchBarInMobileStatus: () -> Unit,
    onToolbarSettingIconClick: () -> Unit,
    onToolbarCompaniesMenuIconClick:()->Unit,
)
{
    val isSettingHovered= remember { mutableStateOf(false) }
    val isCompaniesButtonHovered= remember { mutableStateOf(false) }

    Column(
        modifier = Modifier
            .fillMaxHeight(),
        verticalArrangement = Arrangement.Center
    )
    {
        Row(
            modifier = Modifier,
            verticalAlignment = Alignment.CenterVertically,
        )
        {
            if (
                windowSizeMode!= WindowSizeModeEnum.PHONE
                ||
                (windowSizeMode== WindowSizeModeEnum.PHONE
                        && showSearchBarInMobileMode
                )
            ){
                CustomEditText(
                    modifier = Modifier,
                    height = Dimen.normalOutlinedEditTextHeight,
                    marginRight = Dimen.mediumPadding,
                    marginTop = 0.px,
                    marginBottom = Dimen.smallPadding,
                    hasLabelSpace = false,
                    label = "",
                    value = "",
                    startIconPath = Res.PathIcon.searchBox,
                    endIconPath = Res.PathIcon.mediumClose,
                    isRequired = false,
                    defaultBorderColor = Theme.GrayCD.rgb,
                    hint = Strings.globalSearch,
                    onFocusIn = {},
                    onFocusOut = {},
                    onEndIconClick = {
                        changeShowingSearchBarInMobileStatus()
                    },
                    onInputChange = {

                    },
                )
            }

            if (
                windowSizeMode== WindowSizeModeEnum.PHONE
                && !showSearchBarInMobileMode
            ){
                Box(
                    modifier = Modifier
                        .padding(Dimen.smallPadding)
                        .onClick {
                            changeShowingSearchBarInMobileStatus()
                        }
                )
                {
                    VectorIcon(
                        modifier = Modifier ,
                        color = Theme.GrayAlpha56.hex,
                        pathData = Res.PathIcon.searchBox,
                        width = Dimen.mediumIconSize,
                        height = Dimen.mediumIconSize,
                    )
                }

            }

            if (windowSizeMode!=WindowSizeModeEnum.PHONE ||
                (!showSearchBarInMobileMode && windowSizeMode==WindowSizeModeEnum.PHONE)
            ){

                Box(
                    modifier = Modifier
                        .borderRadius(50.percent)
                        .backgroundColor(
                            if (isCompaniesButtonHovered.value)
                                Theme.GrayAlpha12.rgb
                            else Theme.Transparent.rgb
                        )
                        .margin(leftRight = Dimen.largePadding)
                        .padding(Dimen.normalPadding)
                        .onMouseOver {
                            isCompaniesButtonHovered.value=true
                        }
                        .onMouseLeave {
                            isCompaniesButtonHovered.value=false
                        }
                        .onClick {
                            onToolbarCompaniesMenuIconClick()
                        }
                )
                {
                    VectorIcon(
                        modifier = Modifier ,
                        color = Theme.GrayAlpha56.hex,
                        pathData = Res.PathIcon.appsMenu,
                        width = Dimen.mediumIconSize,
                        height = Dimen.mediumIconSize,
                    )
                }

                if (profileInfo?.avatarUrl!=null){
                    VectorIcon(
                        modifier = Modifier,
                        pathData = profileInfo.avatarUrl ,
                        width = Dimen.semiLargeIconSize,
                        height = Dimen.semiLargeIconSize,

                    )
                }

            }



            if (windowSizeMode== WindowSizeModeEnum.WINDOWS)
            {
                Column(
                    modifier = Modifier
                        .margin(leftRight = Dimen.normalPadding)
                ) {
                    SpanText(
                        text =profileInfo?.name?:"",
                        modifier = Modifier
                            .fontSize(FontSizes.normalFontSize)
                            .color(Theme.Primary.rgb)
                            .fontWeight(FontWeight.SemiBold)
                            .fontFamily(FontFamily.Montserrat)

                    )
                    SpanText(
                        text = profileInfo?.company?:"",
                        modifier = Modifier
                            .fontSize(FontSizes.semiNormalFontSize)
                            .color(Theme.GrayAlpha56.rgb)
                            .fontWeight(FontWeight.Normal)
                            .fontFamily(FontFamily.Montserrat)
                    )
                }

                Box(
                    modifier = Modifier
                        .borderRadius(50.percent)
                        .backgroundColor(
                            if (isSettingHovered.value)
                                Theme.GrayAlpha12.rgb
                            else Theme.Transparent.rgb
                        )
                        .onMouseOver {
                            isSettingHovered.value=true
                        }
                        .onMouseLeave {
                            isSettingHovered.value=false
                        }
                        .onClick {
                            onToolbarSettingIconClick()
                        }
                        .padding(Dimen.normalPadding)
                ){
                    VectorIcon(
                        modifier = Modifier
                            .align(Alignment.Center),
                        color = Theme.GrayAlpha56.hex,
                        pathData = Res.PathIcon.options,
                        width = Dimen.normalIconSize,
                        height = Dimen.normalIconSize,
                    )
                }

            }




        }




    }


}