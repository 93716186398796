package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.restaurant_tab.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.kitchen_info.ProductAdditions
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.components.dialog.component.SubmitButtons
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px

@Composable
fun KitchenAddItemDialog(
    windowSizeMode: WindowSizeModeEnum,
    loading: Boolean,
    onCancelClick: () -> Unit,
    onSubmitClick: (String,List<String?>) -> Unit,
    selectedItem: ProductAdditions?=null,
    activateKitchenItem:(String)->Unit,
    deActivateKitchenItem:(String)->Unit,
)
{
    val item:MutableState<ProductAdditions?> = remember {
        mutableStateOf(selectedItem ?:
            ProductAdditions(variants = listOf("","","","","","","","","","")))
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {

            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(2f)
    ){
        Column(
            modifier = Modifier
                .onClick {
                     event: SyntheticMouseEvent -> event.stopPropagation()
                }
                .align(Alignment.Center)
                .width(
                    if (windowSizeMode==WindowSizeModeEnum.PHONE)
                        (Dimen.kitchenAddItemDialogWidth.value.toInt()*0.5).px
                   else Dimen.kitchenAddItemDialogWidth
                )
                .height(Dimen.kitchenAddItemDialogHeight)
                .backgroundColor(Theme.White.rgb)
                .zIndex(1)
                .boxShadow(
                    color = Theme.GrayCD.rgb,
                    blurRadius = Dimen.smallBlurRadius,
                )
                .padding(bottom = Dimen.normalPadding)
                .borderRadius(r= Dimen.lowBorderRadius),
            horizontalAlignment = Alignment.Start
        ){



            SpanText(
                text = Strings.addNewItem,
                modifier = Modifier
                    .margin(Dimen.mediumPadding)
                    .cursor(Cursor.Pointer)
                    .textAlign(TextAlign.Start)
                    .fontSize(FontSizes.mediumFontSize)
                    .fontFamily(FontFamily.Montserrat)
                    .fontWeight(FontWeight.Medium)
                    .color(Theme.Primary.rgb)
            )

            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(bottom = Dimen.normalPadding)
                    .height(Dimen.smallThickness)
                    .backgroundColor(Theme.GrayAlpha12.rgb)
            )

           if (windowSizeMode==WindowSizeModeEnum.PHONE)
           {
               OneColumnFields(
                   item=item,
                   activateKitchenItem={
                       activateKitchenItem(it)
                   },
                   selectedItem=selectedItem
               )

           }else{
               TwoColumnsFields(
                   item=item,
                   activateKitchenItem={
                       activateKitchenItem(it)
                   },
                   selectedItem=selectedItem
               )

           }


            Spacer()

            SubmitButtons(
                windowSize=windowSizeMode,
                onCancelClick = {
                    onCancelClick()
                },
                onSubmitClick = {
                    onSubmitClick(
                        item.value?.name?:"",
                        item.value?.variants?: listOf()
                    )
                },
                isSubmitClickable = item.value?.name?.isNotEmpty()?:false,
                arrangement=Arrangement.Center
            )




        }


        if (loading){
            LoadingProgress(
                visibility = loading,
                modifier = Modifier
                    .zIndex(2)
                    .borderRadius(
                        Dimen.lowBorderRadius
                    )
                , backgroundColor = Theme.Transparent.rgb
            )
        }




    }

}

@Composable
private fun TwoColumnsFields(
    item: MutableState<ProductAdditions?>,
    activateKitchenItem: (String) -> Unit,
    selectedItem: ProductAdditions?
) {
    Column(
        modifier = Modifier
            .padding(Dimen.mediumPadding)
            .fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,

        )
    {

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Start,
            verticalAlignment = Alignment.CenterVertically
        )
        {
            CustomEditText(
                width = Dimen.selectRectPhoneWidth,
                value = item.value?.name ?: "",
                inputType = InputType.Text,
                label = Strings.title,
                isRequired = true,
                defaultBorderColor = Theme.GrayCD.rgb,
                isOutlinedEditText = true,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    item.value = item.value?.copy(
                        name = it
                    )

                },
            )
            Box(modifier = Modifier.width(Dimen.extraLargePadding))

            CheckboxComponent(
                title = Strings.activate,
                checkStatus = false,
                onChecked = {
                    activateKitchenItem(selectedItem?.id ?: "")
                },
                onUnChecked = {

                },
            )
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Start,
        )
        {
            CustomEditText(
                width = Dimen.selectRectPhoneWidth,
                value = item.value?.variants?.get(0) ?: "",
                inputType = InputType.Text,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                label = Strings.variant1,
                isOutlinedEditText = true,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {

                    item.value = item.value?.copy(
                        variants = getEditedVariants(
                            item = item,
                            newValue = it,
                            position = 0
                        )
                    )
                },
            )

            Spacer()

            CustomEditText(
                width = Dimen.selectRectPhoneWidth,
                value = item.value?.variants?.get(1) ?: "",
                inputType = InputType.Text,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                label = Strings.variant2,
                isOutlinedEditText = true,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    item.value = item.value?.copy(
                        variants = getEditedVariants(
                            item = item,
                            newValue = it,
                            position = 1
                        )
                    )
                },
            )
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Start,
        )
        {
            CustomEditText(
                width = Dimen.selectRectPhoneWidth,
                value = item.value?.variants?.get(2) ?: "",
                inputType = InputType.Text,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                label = Strings.variant3,
                isOutlinedEditText = true,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    item.value = item.value?.copy(
                        variants = getEditedVariants(
                            item = item,
                            newValue = it,
                            position = 2
                        )
                    )
                },
            )

            Spacer()

            CustomEditText(
                width = Dimen.selectRectPhoneWidth,
                value = item.value?.variants?.get(3) ?: "",
                inputType = InputType.Text,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                label = Strings.variant4,
                isOutlinedEditText = true,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    item.value = item.value?.copy(
                        variants = getEditedVariants(
                            item = item,
                            newValue = it,
                            position = 3
                        )
                    )
                },
            )
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Start,
        )
        {
            CustomEditText(
                width = Dimen.selectRectPhoneWidth,
                value = item.value?.variants?.get(4) ?: "",
                inputType = InputType.Text,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                label = Strings.variant5,
                isOutlinedEditText = true,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    item.value = item.value?.copy(
                        variants = getEditedVariants(
                            item = item,
                            newValue = it,
                            position = 4
                        )
                    )
                },
            )

            Spacer()

            CustomEditText(
                width = Dimen.selectRectPhoneWidth,
                value = item.value?.variants?.get(5) ?: "",
                inputType = InputType.Text,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                label = Strings.variant6,
                isOutlinedEditText = true,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    item.value = item.value?.copy(
                        variants = getEditedVariants(
                            item = item,
                            newValue = it,
                            position = 5
                        )
                    )
                },
            )
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Start,
        )
        {
            CustomEditText(
                width = Dimen.selectRectPhoneWidth,
                value = item.value?.variants?.get(6) ?: "",
                inputType = InputType.Text,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                label = Strings.variant7,
                isOutlinedEditText = true,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    item.value = item.value?.copy(
                        variants = getEditedVariants(
                            item = item,
                            newValue = it,
                            position = 6
                        )
                    )
                },
            )

            Spacer()

            CustomEditText(
                width = Dimen.selectRectPhoneWidth,
                value = item.value?.variants?.get(7) ?: "",
                inputType = InputType.Text,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                label = Strings.variant8,
                isOutlinedEditText = true,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    item.value = item.value?.copy(
                        variants = getEditedVariants(
                            item = item,
                            newValue = it,
                            position = 7
                        )
                    )
                },
            )
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.Start,
        )
        {
            CustomEditText(
                width = Dimen.selectRectPhoneWidth,
                value = item.value?.variants?.get(8) ?: "",
                inputType = InputType.Text,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                label = Strings.variant9,
                isOutlinedEditText = true,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    item.value = item.value?.copy(
                        variants = getEditedVariants(
                            item = item,
                            newValue = it,
                            position = 8
                        )
                    )
                },
            )

            Spacer()

            CustomEditText(
                width = Dimen.selectRectPhoneWidth,
                value = item.value?.variants?.get(9) ?: "",
                inputType = InputType.Text,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                label = Strings.variant10,
                isOutlinedEditText = true,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    item.value = item.value?.copy(
                        variants = getEditedVariants(
                            item = item,
                            newValue = it,
                            position = 9
                        )
                    )
                },
            )
        }


    }
}


@Composable
private fun OneColumnFields(
    item: MutableState<ProductAdditions?>,
    activateKitchenItem: (String) -> Unit,
    selectedItem: ProductAdditions?
) {
    Column(
        modifier = VerticalScrollBehavior
            .toModifier()
            .padding(Dimen.mediumPadding)
            .height((Dimen.kitchenAddItemDialogHeight.value.toInt()*.75).px),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        CustomEditText(
            width = Dimen.selectRectPhoneWidth,
            value = item.value?.name ?: "",
            inputType = InputType.Text,
            label = Strings.title,
            isRequired = true,
            defaultBorderColor = Theme.GrayCD.rgb,
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {
                item.value = item.value?.copy(
                    name = it
                )

            },
        )
        Box(modifier = Modifier.fillMaxWidth().margin(bottom = Dimen.normalPadding))
        {
            CheckboxComponent(
                modifier = Modifier.align(Alignment.CenterStart),
                title = Strings.activate,
                checkStatus = false,
                onChecked = {
                    activateKitchenItem(selectedItem?.id ?: "")
                },
                onUnChecked = {

                },
            )
        }

        CustomEditText(
            width = Dimen.selectRectPhoneWidth,
            value = item.value?.variants?.get(0) ?: "",
            inputType = InputType.Text,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            label = Strings.variant1,
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {

                item.value = item.value?.copy(
                    variants = getEditedVariants(
                        item = item,
                        newValue = it,
                        position = 0
                    )
                )
            },
        )

        CustomEditText(
            width = Dimen.selectRectPhoneWidth,
            value = item.value?.variants?.get(1) ?: "",
            inputType = InputType.Text,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            label = Strings.variant2,
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {
                item.value = item.value?.copy(
                    variants = getEditedVariants(
                        item = item,
                        newValue = it,
                        position = 1
                    )
                )
            },
        )

        CustomEditText(
            width = Dimen.selectRectPhoneWidth,
            value = item.value?.variants?.get(2) ?: "",
            inputType = InputType.Text,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            label = Strings.variant3,
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {
                item.value = item.value?.copy(
                    variants = getEditedVariants(
                        item = item,
                        newValue = it,
                        position = 2
                    )
                )
            },
        )

        CustomEditText(
            width = Dimen.selectRectPhoneWidth,
            value = item.value?.variants?.get(3) ?: "",
            inputType = InputType.Text,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            label = Strings.variant4,
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {
                item.value = item.value?.copy(
                    variants = getEditedVariants(
                        item = item,
                        newValue = it,
                        position = 3
                    )
                )
            },
        )

        CustomEditText(
            width = Dimen.selectRectPhoneWidth,
            value = item.value?.variants?.get(4) ?: "",
            inputType = InputType.Text,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            label = Strings.variant5,
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {
                item.value = item.value?.copy(
                    variants = getEditedVariants(
                        item = item,
                        newValue = it,
                        position = 4
                    )
                )
            },
        )


        CustomEditText(
            width = Dimen.selectRectPhoneWidth,
            value = item.value?.variants?.get(5) ?: "",
            inputType = InputType.Text,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            label = Strings.variant6,
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {
                item.value = item.value?.copy(
                    variants = getEditedVariants(
                        item = item,
                        newValue = it,
                        position = 5
                    )
                )
            },
        )


        CustomEditText(
            width = Dimen.selectRectPhoneWidth,
            value = item.value?.variants?.get(8) ?: "",
            inputType = InputType.Text,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            label = Strings.variant9,
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {
                item.value = item.value?.copy(
                    variants = getEditedVariants(
                        item = item,
                        newValue = it,
                        position = 8
                    )
                )
            },
        )

        CustomEditText(
            width = Dimen.selectRectPhoneWidth,
            value = item.value?.variants?.get(9) ?: "",
            inputType = InputType.Text,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            label = Strings.variant10,
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {
                item.value = item.value?.copy(
                    variants = getEditedVariants(
                        item = item,
                        newValue = it,
                        position = 9
                    )
                )
            },
        )

    }
}


private fun getEditedVariants(
    item: MutableState<ProductAdditions?>,
    newValue: String,
    position:Int,
): MutableList<String?>? {
    val newVariants = item.value?.variants?.toMutableList()
    newVariants?.set(position,newValue)
    return newVariants
}