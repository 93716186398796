package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.closing_reports_content.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.percent


@Composable
fun ClosingReportsContentHeader(
    windowSizeMode: WindowSizeModeEnum,
    title:String,
    showTableAndDevices:Boolean,
    onSweepPageClick:()->Unit,
) {
    val isHovered= remember { mutableStateOf(false) }


    Row(
        verticalAlignment = Alignment.CenterVertically
    )
    {
        if (windowSizeMode!=WindowSizeModeEnum.PHONE)
        {
            Box(
                modifier = Modifier
                    .onClick {
                        onSweepPageClick()
                    }
                    .padding(Dimen.normalPadding)
                    .borderRadius(50.percent)
                    .onMouseOver {
                        isHovered.value = true
                    }
                    .onMouseLeave {
                        isHovered.value = false
                    }
                    .backgroundColor(
                        if (isHovered.value) {
                            Theme.GrayAlpha04.rgb
                        } else {
                            Theme.Transparent.rgb
                        }
                    )
            ){

                VectorIcon(
                    modifier = Modifier,
                    pathData =if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                              {
                                  if (showTableAndDevices)
                                      Res.PathIcon.horizontalExpandSwift
                                  else
                                      Res.PathIcon.horizontalUnexpandedSwift
                              }else {
                                  if (showTableAndDevices)
                                      Res.PathIcon.verticalUnexpandedSwift
                                  else
                                      Res.PathIcon.verticalExpandedSwift
                              }
                    ,
                    color = Theme.GrayAlpha56.hex,
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                )
            }
        }



        SpanText(
            text = title,
            modifier = Modifier.margin(left = Dimen.normalPadding)
                .minHeight(Dimen.minimumContentTitleHeight)
                .fontSize(FontSizes.extraLargeFontSize)
                .fontWeight(FontWeight.Normal)
                .lineHeight(FontLineHeight.lineLarge)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Montserrat)
        )

    }
}