package org.hs_soft.runmynesto.data.api_datasource.options.virtual_card

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config.VirtualCardConfigModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.virtual_card.UpdateVirtualCardInfoRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateVirtualCardInfoRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
):UpdateVirtualCardInfoRepository {


    override suspend fun updateVirtualCardInfo(
        dataTitle: String,
        dataValue: dynamic
    ): VirtualCardConfigModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation updateVirtualCardConfig(${'$'}fields: VirtualCardConfigFields) {
                  cashAssist {
                    updateVirtualCardConfig(fields: ${'$'}fields) {
                      ${ApiUtils.Field.isActive}
                      ${ApiUtils.Field.classSuffix}
                      ${ApiUtils.Field.issuerName}
                      ${ApiUtils.Field.issuerEmail}
                      ${ApiUtils.Field.programName}
                      ${ApiUtils.Field.accountIdLabel}
                      ${ApiUtils.Field.rewardsTierLabel}
                      ${ApiUtils.Field.rewardsTier}
                      ${ApiUtils.Field.secondaryRewardsTierLabel}
                      ${ApiUtils.Field.secondaryRewardsTier}
                      ${ApiUtils.Field.logoImg}
                      ${ApiUtils.Field.wideLogoImg}
                      ${ApiUtils.Field.wideLogoGoogleImg}
                      ${ApiUtils.Field.heroGoogleImg}
                      ${ApiUtils.Field.backgroundColor}
                      ${ApiUtils.Field.foregroundColor}
                      ${ApiUtils.Field.labelColor}
                      ${ApiUtils.Field.homepageUri} {
                        ${ApiUtils.Field.description}
                        ${ApiUtils.Field.link}
                        ${ApiUtils.Field.__typename}
                      }
                      ${ApiUtils.Field.linkPhoneUri} {
                        ${ApiUtils.Field.description}
                        ${ApiUtils.Field.link}
                        ${ApiUtils.Field.__typename}
                      }
                      ${ApiUtils.Field.isDefault}
                      ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                  }
                }

            """.trimIndent())

            putJsonObject("variables") {
                putJsonObject("fields") {
                    when(dataValue){
                        is Boolean ->put(dataTitle, dataValue as Boolean)
                        is String ->put(dataTitle,dataValue as String)
                        is JsonObject -> put(dataTitle, dataValue as JsonElement)
                    }
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }

    }

}
