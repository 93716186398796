package org.hs_soft.runmynesto.data.api_datasource.customer_card.tabs.customer_card_tab

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.credit_client.CreditClientModel
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.filtered_transaction.FilteredLoyaltyCardTransactionModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.customer_card_tab.CreditClientRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.customer_card_tab.FilteredTransactionLoyaltyCardRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class FilteredLoyaltyCardTransactionRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): FilteredTransactionLoyaltyCardRepository
{
    override suspend fun filteredTransactionLoyaltyCards(
      cardId:String,
      from:String,
      to:String,
    ):FilteredLoyaltyCardTransactionModel
    {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            query listLoyaltyCardItems(${'$'}filter: filterLoyaltyCarItems) {
                  cashAssist {
                    listLoyaltyCardItems(filter: ${'$'}filter) {
                       ${ApiUtils.Field.total}
                       ${ApiUtils.Field.hasNextPage}
                       items {
                         ${ApiUtils.Field.dateTime}
                         ${ApiUtils.Field.name}
                         ${ApiUtils.Field.groupId}
                         cashRegister {
                           ${ApiUtils.Field.id}
                           ${ApiUtils.Field.name}
                           ${ApiUtils.Field.__typename}
                         }
                         ${ApiUtils.Field.billNumber}
                         ${ApiUtils.Field.userName}
                         ${ApiUtils.Field.increase}
                         ${ApiUtils.Field.decrease}
                         ${ApiUtils.Field.balance}
                         group {
                           ${ApiUtils.Field.id}
                           ${ApiUtils.Field.name}
                           ${ApiUtils.Field.__typename}
                         }
                         ${ApiUtils.Field.points}
                         ${ApiUtils.Field.pointsTotal}
                         ${ApiUtils.Field.mode}
                         ${ApiUtils.Field.transactionDate}
                         ${ApiUtils.Field.isCancelled}
                         ${ApiUtils.Field.__typename}
                       }
                       ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                  }
                }

                
                """.trimIndent())

            putJsonObject("variables") {
                putJsonObject("filter"){
                    put("cardId",cardId)
                    put("isDeleted",false)
                    putJsonObject("period"){
                        put("from",from)
                        put("to",to)
                    }
                }

            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }
    }

}