package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.credit_client


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreditClientModel(
    @SerialName("data")
    val `data`: Data?
)