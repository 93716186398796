package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.hoverBackgroundStyle
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.dialog.dialog.SetImgFromLocal
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.backgroundPosition
import org.jetbrains.compose.web.css.backgroundRepeat
import org.jetbrains.compose.web.css.backgroundSize
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.width


@Composable
fun UploadImage(
    modifier: Modifier = Modifier,
    width: CSSSizeValue<CSSUnit.px> = Dimen.selectRectPhoneWidth,
    height: CSSSizeValue<CSSUnit.px> = Dimen.selectRectPhoneHeight,
    imgUrl: String,
    setImageDirectlyStatus: Boolean = false,
    circleShape: Boolean = false,
    onImgContentClick: () -> Unit,
    setImageDirectly: (String) -> Unit = {},
    imgId: String="",

) {
    val isHovered= remember { mutableStateOf(false) }

    if (setImageDirectlyStatus){
        SetImgFromLocal(
            id = imgId,
            getImg = {url:String ->
                setImageDirectly(url)
            }
        )
    }

    Box(
        modifier = modifier
            .onMouseOver { isHovered.value=true }
            .onMouseLeave { isHovered.value=false }
            .width(width)
            .height(height)
            .borderRadius(if(circleShape) 50.percent else Dimen.lowBorderRadius)
            .border(
                Dimen.smallThickness,
                LineStyle.Solid,
                Theme.GrayCD.rgb
            )
    )
    {
        Image(
            src = imgUrl,
            modifier = Modifier
                .thenIf(
                    imgUrl!=Res.PathIcon.defaultRectPhoto,
                    Modifier
                        .borderRadius(Dimen.lowBorderRadius)
                        .border(
                            width = Dimen.smallThickness,
                            style = LineStyle.Solid,
                            color = Theme.GrayCD.rgb
                        )
                )

                .objectFit(ObjectFit.Cover)
                .attrsModifier
                {
                    style {
                        width(width)
                        height(height)
                        backgroundPosition("center")
                        backgroundSize("2.5em 2.5em")
                        backgroundRepeat("no-repeat")
                        backgroundImage(Res.PathIcon.galleryBackground)
                        borderRadius(if(circleShape) 50.percent else Dimen.lowBorderRadius)
                    }
                },

            )


            Box(
                modifier = hoverBackgroundStyle.toModifier()
                    .position(Position.Relative)
                    .rippleEffect {
                        onImgContentClick()
                    }
                    .width(width)
                    .height(height)
                    .transitionBackground()
                    .backgroundColor(
                       if (isHovered.value)
                           Theme.GrayAlpha56.rgb
                        else Theme.Transparent.rgb
                    )
                    .borderRadius(if(circleShape) 50.percent else Dimen.lowBorderRadius)

            ){
                if(isHovered.value){
                    VectorIcon(
                        modifier = Modifier.align(Alignment.Center),
                        pathData = Res.PathIcon.add,
                        width= Dimen.mediumIconSize,
                        height= Dimen.mediumIconSize,
                        color = Theme.White.hex,
                    )
                }

            }



    }



}

