package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.update_product


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateProduct(
    @SerialName("altName")
    val altName: String?,
    @SerialName("category")
    val category: Category?,
    @SerialName("categoryId")
    val categoryId: String?,
    @SerialName("code")
    val code: String?,
    @SerialName("codeMWSt")
    val codeMWSt: String?,
    @SerialName("codeMWSt2")
    val codeMWSt2: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("isActive")
    val isActive: Boolean?,
    @SerialName("isEmployeeArticle")
    val isEmployeeArticle: Boolean?,
    @SerialName("isNetto")
    val isNetto: Boolean?,
    @SerialName("isNettoForUser")
    val isNettoForUser: Boolean?,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("name")
    val name: String?,
    @SerialName("nestedCategoryId")
    val nestedCategoryId: String?,
    @SerialName("price")
    val price: List<Double?>?,
    @SerialName("priceInCafe")
    val priceInCafe: List<Double?>?,
    @SerialName("salesGroupId")
    val salesGroupId: String?,
    @SerialName("unitSellCode")
    val unitSellCode: String?
)