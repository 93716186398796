package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Constants.Responsive.closingReportsCheckoutHeight
import org.hs_soft.runmynesto.domain.util.animatedWidth
import org.hs_soft.runmynesto.domain.util.getCheckoutWidth
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.components.checkout.CheckoutTabContent
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.closing_reports_content.ClosingReportsContent
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.ClosingReportsTable
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsState
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsViewModel
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh

@Composable
fun ClosingReports(
    windowSizeMode:WindowSizeModeEnum,
    viewModel: ClosingReportsViewModel,
)
{
    val state by viewModel.state.collectAsState()
    val cashRegisterIds = remember { mutableStateOf<List<String>>(listOf()) }

    LaunchedEffect(state.cashList){
        cashRegisterIds.value= extractAllIds(state.cashList)

    }
    LaunchedEffect(state.selectedClosingReport){
        cashRegisterIds.value= extractAllIds(state.cashList)
    }
    LaunchedEffect(Unit){
        viewModel.onEvent(ClosingReportsEvent.SetCompanyProfileId)
    }


    when(windowSizeMode)
    {
        WindowSizeModeEnum.WINDOWS ->{
            Row(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(
                        topBottom = Dimen.normalPadding,
                        leftRight = Dimen.largePadding,
                    ),
            )
            {
                CheckoutAndClosingReportsTable(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    cashRegisterIds=cashRegisterIds,
                    event =viewModel::onEvent
                )



                ClosingReportsContent(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=viewModel::onEvent
                )

            }
        }

        WindowSizeModeEnum.TABLET ->{
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(
                        topBottom = Dimen.normalPadding,
                        leftRight = Dimen.largePadding,
                    ),
            )
            {
                CheckoutAndClosingReportsTable(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    cashRegisterIds=cashRegisterIds,
                    event =viewModel::onEvent
                )



                ClosingReportsContent(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=viewModel::onEvent
                )

            }
        }

        WindowSizeModeEnum.PHONE ->{
            Row(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(
                        topBottom = Dimen.normalPadding,
                        leftRight = Dimen.largePadding,
                    ),
            )
            {
                if (state.showFirstPage)
                {
                    CheckoutAndClosingReportsTable(
                        windowSizeMode=windowSizeMode,
                        state=state,
                        cashRegisterIds=cashRegisterIds,
                        event =viewModel::onEvent
                    )
                }else{
                    ClosingReportsContent(
                        windowSizeMode=windowSizeMode,
                        state=state,
                        event=viewModel::onEvent
                    )
                }



            }
        }
    }





    LoadingProgress(
        visibility = state.loadingProgress
    )


}

@Composable
private fun CheckoutAndClosingReportsTable(
    windowSizeMode: WindowSizeModeEnum,
    state: ClosingReportsState,
    cashRegisterIds: MutableState<List<String>>,
    event:(ClosingReportsEvent)->Unit,
) {
    if (windowSizeMode == WindowSizeModeEnum.TABLET) {

        val targetHeight = if (state.showReportsTableAndDevices)
            "${Dimen.closingReportsTabletModeHeight}"
            else "0px"
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .attrsModifier {
                    style {
                        property("height", targetHeight)
                        property("opacity",
                            if (state.showReportsTableAndDevices) "1" else "0")
                        property("overflow",
                            "hidden")
                        property("transition",
                            "height ${Constants.mediumTransition} ease, " +
                                    "opacity ${Constants.mediumTransition} ease")
                    }
                }
        )
        {
            DevicesAndClosingReportsTable(
                windowSizeMode = windowSizeMode,
                state = state,
                cashRegisterIds = cashRegisterIds,
                event = event
            )
        }
    } else {
        Column(
            modifier = Modifier
                .fillMaxHeight()
                .thenIf(
                    windowSizeMode==WindowSizeModeEnum.WINDOWS,
                    Modifier
                        .animatedWidth(
                            widthValue =
                            if (state.showReportsTableAndDevices)
                            {
                                (getCheckoutWidth(windowSizeMode).value
                                    + Dimen.largePadding.value).px
                            } else 0.px,
                            duration = Constants.mediumExpandingTime
                    )
                )

        )
        {
            DevicesAndClosingReportsTable(
                windowSizeMode = windowSizeMode,
                state = state,
                cashRegisterIds = cashRegisterIds,
                event = event
            )


        }
    }
}


@Composable
private fun DevicesAndClosingReportsTable(
    windowSizeMode: WindowSizeModeEnum,
    state: ClosingReportsState,
    cashRegisterIds: MutableState<List<String>>,
    event: (ClosingReportsEvent)->Unit,
) {
    CheckoutTabContent(
        modifier = Modifier,
        fillMaxWidth = true,
        windowSizeMode = windowSizeMode,
        marginBottom = if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                Dimen.normalPadding
            else 0.px,
        cashList = state.cashList,
        width =when(windowSizeMode){
            WindowSizeModeEnum.TABLET -> Constants.Responsive.ClosingReportsCheckoutTablet.px
            WindowSizeModeEnum.WINDOWS ->getCheckoutWidth(windowSizeMode)
            WindowSizeModeEnum.PHONE ->Constants.Responsive.ClosingReportsTablePhone.px
        } ,
        height = closingReportsCheckoutHeight.vh,
        borderLess = true,
        cashRegisterIds = cashRegisterIds.value,
        updateCheckout = {
            cashRegisterIds.value = it.toMutableList()
            if (state.selectedFromDate != null) {
               event(
                    ClosingReportsEvent.GetClosingReports(
                        from = state.selectedFromDate,
                        to = state.selectedToDate!!,
                        cashRegisterIds = cashRegisterIds.value,
                    )
                )
            }

        },
        isExpandAll = state.isDevicesExpandAll,
        searchValue = "",// checkoutSearchValue,
        onSearchValueChange = {
            event(
                ClosingReportsEvent.OnSearchCheckoutItems(it)
            )
        },
        getCheckoutData = {
            event(ClosingReportsEvent.GetCheckoutData)
        }

    )

    ClosingReportsTable(
        windowSizeMode = windowSizeMode,
        event = event,
        cashRegisterIds = cashRegisterIds.value,
        selectedClosingReport = state.selectedClosingReport,
        closingReportsList = state.closingReports,
        selectedPeriodType = state.selectedPeriodType,
        showDaysFilterDialog = state.showDaysFilterDialog,
        showCalendarFilterDialog = state.showCalendarFilterDialog,
        selectedDate = state.selectedDate,
        selectedFrom = state.selectedFromDate ?: "",
        selectedTo = state.selectedToDate ?: "",
        selectedCalendarHeaderYear = state.selectedCalendarHeaderYear,
        showReportsTableAndDevices = state.showReportsTableAndDevices,

    )
}



fun extractAllIds(cashList: List<ChildrenX>?): List<String> {
    val ids = mutableListOf<String>()

    cashList?.forEach { childrenX ->
        childrenX.children?.forEach { childrenXX ->
            childrenXX.children.forEach { childrenXXX ->
                childrenXXX.id?.let { ids.add(it) }
            }
        }
    }

    return ids
}

