package org.hs_soft.runmynesto.domain.model.home.product.product_item_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Img(
    @SerialName("key")
    val key: String,
    @SerialName("uid")
    val uid: String
)