package org.hs_soft.runmynesto.data.api_datasource.template.tab_general

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.TemplateCategoryDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.SingleTemplateCategoryRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class SingleTemplateCategoryRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
) : SingleTemplateCategoryRepository {


    override suspend fun fetchSingleTemplateCategoryData(id: String):
            TemplateCategoryDetailModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            query getClosing {
              cashAssist {
                getCategory(${ApiUtils.Field.id}: $id) {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.position}
                  ${ApiUtils.Field.name}
                  ${ApiUtils.Field.isActive}
                  ${ApiUtils.Field.cashRegisterId}
                  ${ApiUtils.Field.priceLevel}
                  ${ApiUtils.Field.validAfter}
                  ${ApiUtils.Field.surchargeAmount}
                  ${ApiUtils.Field.surchargeFrom}
                  ${ApiUtils.Field.surchargePercent}
                  ${ApiUtils.Field.color}
                  ${ApiUtils.Field.weekDays}
                  
                  img {
                    ${ApiUtils.Field.url}
                  }
                   gridProducts {
                        ${ApiUtils.Field.id}
                        ${ApiUtils.Field.position}
                        ${ApiUtils.Field.productId}
                        ${ApiUtils.Field.categoryId}
                        product {
                          ${ApiUtils.Field.id}
                          ${ApiUtils.Field.name}
                          ${ApiUtils.Field.code}
                          ${ApiUtils.Field.price}
                          img {
                            ${ApiUtils.Field.uid}
                            ${ApiUtils.Field.key}
                            ${ApiUtils.Field.__typename}
                          }
                          category {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.color}
                            ${ApiUtils.Field.__typename}
                          }
                          ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.__typename}
                      }
                }
              }
            }
        """.trimIndent())
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<TemplateCategoryDetailModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }

    }

}