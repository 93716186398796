package org.hs_soft.runmynesto.domain.usecase.options.sales_group

import org.hs_soft.runmynesto.domain.model.home.options.single_sale_group.SingleSaleGroupModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.sales_group.UpdateSaleGroupRepository


class UpdateSaleGroupUseCase
    (private val repository: UpdateSaleGroupRepository) {
    suspend operator fun invoke(
        id: String,
        dataTitle:String,
        dataValue:String,
    ): SingleSaleGroupModel {
        return repository.updateSaleGroup(
            id=id,
            dataTitle=dataTitle,
            dataValue=dataValue,
        )
    }
}