package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general

import org.hs_soft.runmynesto.domain.config.Strings

enum class WaiterDeviceTypeEnum(
    val title:String,
    val listingLayouts: List<ListingLayoutsEnum>,
)
{
    MOBILE_WAITER(
        title = Strings.mobileWaiter,
        listingLayouts = ListingLayoutsEnum.entries,
    ),
    DESKTOP_WAITER(
        title = Strings.desktopWaiter,
        listingLayouts = listOf<ListingLayoutsEnum>()
    )

}