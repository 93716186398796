package org.hs_soft.runmynesto.domain.model.home.customer_card

import org.hs_soft.runmynesto.domain.config.Strings

enum class CustomerCardHeaderEnum(
    val title:String
)
{
    CUSTOMER_CARD(
        title = Strings.customerCard,
    ),
    SERIES(
        title = Strings.series,
    )
}