package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Taxe(
    @SerialName("MWSt")
    val mWSt: Double?,
    @SerialName("percent")
    val percent: Double?,
    @SerialName("sales")
    val sales: Double?,
    @SerialName("total")
    val total: Double?,
    @SerialName("__typename")
    val typename: String?
)