package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme

@Composable
fun CardHeader(
    title: String,
    modifier: Modifier=Modifier
) {
    SpanText(
        text = title,
        modifier = modifier
            .fontFamily(FontFamily.Montserrat)
            .color(Theme.Primary.rgb)
            .fontWeight(FontWeight.Medium)
            .fontSize(FontSizes.semiLargeFontSize)
    )
}