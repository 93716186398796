package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.abc_statistic.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenXXX
import org.hs_soft.runmynesto.pages.components.AddItem
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.TableItemRow
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.ReportsTableFilterOptions
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi.StatisticEvent
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun FilterABCStatistic(
    windowSizeMode: WindowSizeModeEnum,
    selectedDateFrom: MonthModel?,
    selectedDateTo: MonthModel?,
    cashRegisters: List<ChildrenXXX>?,
    selectedFrom: String,
    selectedTo: String,
    selectedCalendarHeaderYear: Int,
    selectedPeriodType: PeriodDaysEnum?,
    showFromCalendarFilterDialog: Boolean,
    showToCalendarFilterDialog:Boolean,
    showDaysFilterDialog: Boolean,
    event: (StatisticEvent) -> Unit,
)
{
    Column(
        modifier = Modifier
            .thenIf(
                windowSizeMode==WindowSizeModeEnum.PHONE,
                Modifier.margin(top=Dimen.mediumPadding)
            )
    )
    {
        MediumContentTitle(
            title = Strings.filter,
            marginTop = Dimen.normalPadding,
            marginBottom = Dimen.normalPadding,
        )


        Column()
        {
            ReportsTableFilterOptions(
                selectedPeriodType = selectedPeriodType,
                showDaysFilterDialog=showDaysFilterDialog,
                selectedDateFrom = selectedDateFrom,
                selectedDateTo = selectedDateTo,
                showFromCalendarFilterDialog = showFromCalendarFilterDialog,
                showToCalendarFilterDialog=showToCalendarFilterDialog,
                selectedTo = selectedTo,
                selectedFrom = selectedFrom,
                selectedCalendarHeaderYear =selectedCalendarHeaderYear,
                setDaysFilterDialogStatus = {
                    event(StatisticEvent.SetDaysFilterDialogStatus(it))
                },
                submitFilterItemsBasedOnDays = { from:String, to:String, type: PeriodDaysEnum ->
                    event(
                        StatisticEvent.SubmitStatisticDaysFilter(
                            from=from,
                            to=to,
                            periodDaysType=type,
                        )
                    )
                },
                setFromCalendarFilterDialogStatus = {
                    event(StatisticEvent.SetFromCalendarFilterDialogStatus(status = it))
                },
                setToCalendarFilterDialogStatus = {
                    event(StatisticEvent.SetToCalendarFilterDialogStatus(status = it))
                },
                onSelectFromDate = {
                    event(
                        StatisticEvent.OnSelectFromDate(
                            selectedFromDate = it,
                        )
                    )
                },
                onSelectToDate = {
                    event(
                        StatisticEvent.OnSelectToDate(
                            selectedToDate = it,
                        )
                    )
                },
                filterDate = {from:String,to:String->
                    event(
                        StatisticEvent.SubmitStatisticCalendarFilter(
                            from,
                            to,
                        )
                    )
                },
                closeFromCalendarDialog = {
                    event(
                        StatisticEvent.SetFromCalendarFilterDialogStatus(false)
                    )
                },
                closeToCalendarDialog = {
                    event(
                        StatisticEvent.SetToCalendarFilterDialogStatus(false)
                    )
                },
                setNewCalendarHeaderYear = {
                    event(
                        StatisticEvent.SetNewCalendarHeaderYear(it)
                    )
                }
            )


            CashRegistersFilter(
                modifier = Modifier,
                cashRegisters=cashRegisters,
                title = Strings.cashRegisters,
                showCheckoutDialog = {
                    event(StatisticEvent.SetCheckoutDialogStatus(it))
                }
            )

        }


    }



}

@Composable
fun CashRegistersFilter(
    modifier: Modifier,
    title: String,
    cashRegisters: List<ChildrenXXX>?,
    showCheckoutDialog:(Boolean)->Unit,
) {

    Column(
        modifier = modifier
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
            .fillMaxWidth()
            .height(Dimen.filterABCStatisticHeight)
    )
    {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .backgroundColor(Theme.GrayAlpha04.rgb)
                .padding(
                    left = Dimen.normalPadding,
                )
                .borderBottom(
                    width = Dimen.smallThickness,
                    color = Theme.GrayCD.rgb,
                    style = LineStyle.Solid,
                ),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            HeaderTitle(
                title =title,
                fillMaxWidth = true
            )

            Spacer()


            AddItem(
                padding = Dimen.smallPadding,
                onAddClick = {
                    showCheckoutDialog(true)
                }
            )

        }
        Column(
            modifier = VerticalScrollBehavior.toModifier()
                .fillMaxWidth()
                .weight(1f)
        )
        {
            cashRegisters?.forEach {

                TableItemRow(
                    id = it.id?:"",
                    isSelected =false,
                    onItemClick = {

                    }
                ){
                    HeaderTitle(
                        title=it.name,
                        fillMaxWidth = true
                    )
                }

            }


        }



    }



}
