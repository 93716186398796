package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Currency(
    @SerialName("amount")
    val amount: Double?,
    @SerialName("amountConverted")
    val amountConverted: Double?,
    @SerialName("cashInTransit")
    val cashInTransit: Double?,
    @SerialName("difference")
    val difference: Double?,
    @SerialName("exchangeRate")
    val exchangeRate: Double?,
    @SerialName("name")
    val name: String?,
    @SerialName("present")
    val present: Double?,
    @SerialName("presentConverted")
    val presentConverted: Double?,
    @SerialName("__typename")
    val typename: String?
)