package org.hs_soft.runmynesto.domain.usecase.closing_report

import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model.ClosingReportDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.closing_report.ClosingReportDetailRepository

class ClosingReportDetailUseCase(
    private val repository:ClosingReportDetailRepository
)
{
    suspend operator fun invoke(
        id:String,
    ): ClosingReportDetailModel {
        return repository.getClosingReportDetail(
            id=id
        )
    }

}