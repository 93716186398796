package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.checked_ingredients_list


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CheckedIngredientData(
    @SerialName("data")
    val data: Data?
)