package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.ArticleModel
import org.hs_soft.runmynesto.pages.components.grid_function.GridFunction
import org.hs_soft.runmynesto.pages.components.grid_function.GridItemsList

@Composable
fun ArticleItemsGridView(
    windowSizeMode: WindowSizeModeEnum,
    selectedArticles: List<ArticleModel>?,
    articleList: List<ProductItem>?,
    showTitleSortIcon: Boolean,
    onRefreshClick: () -> Unit,
    titleDescending: Boolean,
    onArticleItemClick: (String) -> Unit,
    updateListAfterDragAndDrop: (List<dynamic>, List<String>, List<Int>, List<String>) -> Unit,
    onTitleSortClick: () -> Unit,
    onDragEndProductItem: (ProductItem) -> Unit,
) {
    Column(
        modifier = Modifier
            .height(Dimen.TemplateArticleListHeight)
            .width(Dimen.semiLargeEditTextWidth)
            .thenIf(
                windowSizeMode!=WindowSizeModeEnum.Desktop,
                Modifier.fillMaxWidth()
            )
    )
    {


        GridFunction(
            modifier = Modifier,
            fillMaxWidth = windowSizeMode!=WindowSizeModeEnum.Desktop,
            title = Strings.name,
            contentHeight = Dimen.articleListContentHeight,
            infoTitle = Strings.numberDot,
            footerTitle = Strings.article,
            width = Dimen.semiLargeEditTextWidth,
            infoList = articleList?: listOf(),
            showHeaderSeparator = false,
            titleDescending = titleDescending,
            numberDescending = false,
            onNumberSortClick = {},
            showTitleSortIcon = showTitleSortIcon,
            showNumberSortIcon = false,
            refreshList = {
                onRefreshClick()
            },
            onTitleSortClick = {
                onTitleSortClick()
            },
            content = {
                GridItemsList(
                    items = articleList ?: listOf(),
                    selectedItems=selectedArticles?.map { it.productId }?.toList(),
                    id = "",
                    onItemClick = {
                        onArticleItemClick(it)
                    },
                    onReorder = { newList, idList, positionList, productIdList ->
//                        updateListAfterDragAndDrop(
//                            newList,
//                            idList,
//                            positionList,
//                            productIdList,
//                        )
                    },
                    onDragEnd = {
                        if (it is ProductItem)
                            onDragEndProductItem(it)
                    },
                )

            },
        )

    }
}