package org.hs_soft.runmynesto.domain.model.home.dashboard.init_shop_statics


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("initShopStatistics")
    val initShopStatistics: String?,
    @SerialName("__typename")
    val typename: String?
)