package org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.customer_card_tab

import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.filtered_transaction.FilteredLoyaltyCardTransactionModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.customer_card_tab.FilteredTransactionLoyaltyCardRepository

class FilteredTransactionLoyaltyCardUseCase(
    private val repository: FilteredTransactionLoyaltyCardRepository
)
{
    suspend operator fun invoke(
        cardId:String,
        from:String,
        to:String,
    ): FilteredLoyaltyCardTransactionModel {
        return repository.filteredTransactionLoyaltyCards(
            cardId=cardId,
            from=from,
            to=to,
        )
    }

}