package org.hs_soft.runmynesto.pages.components.checkout.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.getGeneralItemRatio
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.hs_soft.runmynesto.pages.components.checkout.getArrowImg
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnitLength

@Composable
fun DeviceLocation(
    checkedValue: Boolean,
    width: CSSSizeValue<out CSSUnitLength>,
    title: String,
    ratioChecked: String,
    unCheckItem: () -> Unit,
    checkItem: () -> Unit,
    content: @Composable () -> Unit,
    isExpandAll: Boolean,
    windowSizeMode: WindowSizeModeEnum,
    showCheckBox: Boolean,
)
{
    val isExpanded= remember{ mutableStateOf(isExpandAll) }
    LaunchedEffect(isExpandAll){
        isExpanded.value=isExpandAll
    }

    Column(
        modifier = Modifier
            .cursor(Cursor.Pointer)
            .onClick {
                    event: SyntheticMouseEvent -> event.stopPropagation()
                isExpanded.value=!isExpanded.value
            },
        horizontalAlignment = Alignment.End
    ) {
        Row(
            modifier = Modifier
                .width(width)
                .backgroundColor(Theme.GrayAlpha08.rgb)
                .padding(leftRight = Dimen.normalPadding)
                .boxShadow(color = Theme.GrayAlpha12.rgb)
                .height(Dimen.checkOutItemHeight),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            if (showCheckBox){
                CheckboxComponent(
                    modifier = Modifier,
                    checkStatus = checkedValue,
                    title = "",
                    onChecked = {checkItem()},
                    onUnChecked = {unCheckItem()},
                    fontSize = getGeneralItemRatio(
                        windowSizeMode=windowSizeMode,
                        size = FontSizes.mediumFontSize,
                    )
                )
            }


            VectorIcon(
                modifier = Modifier,
                pathData = Res.PathIcon.location,
                width = Dimen.normalIconSize,
                height = Dimen.normalIconSize,
                color = Theme.GrayAlpha38.hex
            )

            SpanText(
                text = title,
                modifier = Modifier
                    .margin(left = Dimen.semiNormalPadding)
                    .fontWeight(FontWeight.Medium)
                    .fontFamily(FontFamily.Montserrat)
                    .fontSize(
                        getGeneralItemRatio(
                            windowSizeMode=windowSizeMode,
                            size = FontSizes.mediumFontSize,
                        )
                    )
                    .color(Theme.Primary.rgb),
            )

            Spacer()

            SpanText(
                text = ratioChecked,
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .margin(leftRight = Dimen.smallPadding)
                    .fontWeight(FontWeight.Medium)
                    .fontFamily(FontFamily.Montserrat)
                    .fontSize( FontSizes.normalFontSize)
                    .color(Theme.Primary.rgb),
            )


            VectorIcon(
                modifier = Modifier,
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
                pathData= getArrowImg(expanded = isExpanded.value),
                color = Theme.Primary.hex
            )

        }

        if (isExpanded.value){
            Column(
                modifier = Modifier,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                content()
            }
        }


    }


}