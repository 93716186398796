package org.hs_soft.runmynesto.di.user

import org.hs_soft.runmynesto.domain.usecase.user.AddUserUseCase
import org.hs_soft.runmynesto.domain.usecase.user.CheckOutDataUseCase
import org.hs_soft.runmynesto.domain.usecase.user.SingleUserUseCase
import org.hs_soft.runmynesto.domain.usecase.user.UpdateUserDataUseCase
import org.hs_soft.runmynesto.domain.usecase.user.UsersDataUseCase
import org.hs_soft.runmynesto.domain.usecase.user.tab_checkout.UpdateUserCheckoutUseCase
import org.hs_soft.runmynesto.domain.usecase.user.tab_right.UpdateUserPermissionUseCase
import org.hs_soft.runmynesto.domain.usecase.user.tab_right.UpdateUserWaiterUseCase
import org.hs_soft.runmynesto.domain.usecase.user.tab_general.UpdateUserImageUseCase
import org.koin.dsl.module

val userUseCaseModule= module {
    single {
        UpdateUserCheckoutUseCase(get())
    }

    single {
        UpdateUserPermissionUseCase(get())
    }


    single {
        AddUserUseCase(get())
    }

    single {
        CheckOutDataUseCase(get())
    }

    single {
        SingleUserUseCase(get())
    }
    single {
        UsersDataUseCase(get())
    }

    single {
        UpdateUserWaiterUseCase(get())
    }

    single {
        UpdateUserImageUseCase(get())
    }

    single {
        UpdateUserDataUseCase(get())
    }






}