package org.hs_soft.runmynesto.domain

import com.varabyte.kobweb.browser.http.AbortController
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject

interface ApiClient {
    suspend fun requestResult(
        graphQLQuery: JsonObject,
        abortController: AbortController ,
        resource: String,
        json:Json=Json,
    ): ByteArray?
}