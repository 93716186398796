package org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Discount(
    @SerialName("freePercent")
    val freePercent: Int?,
    @SerialName("masterPercent")
    val masterPercent: Int?,
    @SerialName("numberOfPercent")
    val numberOfPercent: Int?,
    @SerialName("__typename")
    val typename: String?
)