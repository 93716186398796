package org.hs_soft.runmynesto.data.api_datasource.statistic.tabs.bill_search

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.billPdfModel.BillPdfModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.search_bill.BillPdfRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class BillPdfRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
):BillPdfRepository{


    override suspend fun getBillPdf(
        billDate: String,
        billNumber: Int,
        cashRegisterId: String
    ): BillPdfModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """
                    query CA_gitBillPdf(
                        ${'$'}billNumber: Int!,
                         ${'$'}cashRegisterId: ID!,
                         ${'$'}billDate: DateTime
                    ) {
                      cashAssist {
                        getBillPdf(
                          billNumber: ${'$'}billNumber
                          cashRegisterId: ${'$'}cashRegisterId
                          billDate: ${'$'}billDate
                        ) {
                          url
                          __typename
                        }
                        __typename
                      }
                    }


                """.trimIndent()
            )
            putJsonObject("variables") {
                put("billNumber",billNumber)
                put("cashRegisterId",cashRegisterId)
                put("billDate",billDate)


            }


        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }





    }


}