package org.hs_soft.runmynesto.domain.model.home.template.add_new_category


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Data(
    @SerialName("cashAssist")
    val cashAssist: CashAssist
)