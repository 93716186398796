package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.operation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.CountriesEnum
import org.hs_soft.runmynesto.domain.model.home.options.LanguageEnum
import org.hs_soft.runmynesto.domain.model.home.options.operation.option_model.OptionModel
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.editTextBoxBackground
import org.hs_soft.runmynesto.domain.util.editTextLabelBackground
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.DropdownComponent
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text


@Composable
fun OptionOperationTab(
    windowSizeMode:WindowSizeModeEnum,
    optionModel: OptionModel?,
    onChangeDataOption:(String,dynamic)->Unit,
    saveCompanyProfile:(String)->Unit,
    loadOperationData:()->Unit,
)
{

    //TODO(NEEDS TO BE OPTIMIZED)
    LaunchedEffect(optionModel){
        loadOperationData()
        saveCompanyProfile(optionModel?.countryId?:"")
    }
    Column(
        Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .padding(Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            ),
    )
    {
        OperationContent(
            optionModel=optionModel,
            onChangeDataOption = {title:String,value:dynamic->
                onChangeDataOption(title,value)
            }
        )




    }

}

@Composable
private fun OperationContent(
    optionModel: OptionModel?,
    onChangeDataOption: (String, dynamic) -> Unit
) {

    MediumContentTitle(
        title = Strings.operation,
        marginTop = Dimen.mediumPadding,
        marginBottom = Dimen.mediumPadding,
    )
    CustomEditText(
        modifier = Modifier,
        label = Strings.companyName,
        value = optionModel?.name?:"",
        defaultBorderColor = Theme.GrayCD.rgb,
        hint = "",
        isOutlinedEditText = true,
        onFocusIn = {},
        onFocusOut = {
            onChangeDataOption(ApiUtils.Field.name, it)
        },
        onInputChange = {

        },
    )



    CustomEditText(
        modifier = Modifier,
        label = Strings.street,
        value = optionModel?.street?:"",
        defaultBorderColor = Theme.GrayCD.rgb,
        hint = "",
        isOutlinedEditText = true,

        onFocusIn = {

        },
        onFocusOut = {
            onChangeDataOption(ApiUtils.Field.street, it)
        },
        onInputChange = {

        },
    )

    Row()
    {
        CustomEditText(
            modifier = Modifier,
            width = Dimen.smallEditTextWidth,
            label = Strings.zip,
            value = optionModel?.postCode?:"",
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            isOutlinedEditText = true,
            onFocusIn = {

            },
            onFocusOut = {
                onChangeDataOption(ApiUtils.Field.postCode, it)
            },
            onInputChange = {

            },
        )

        Box(modifier = Modifier.padding(Dimen.smallPadding))

        CustomEditText(
            modifier = Modifier,
            width = (Dimen.mediumEditTextWidth.value.toInt()
                    - Dimen.normalPadding.value.toInt()
                    - Dimen.smallEditTextWidth.value.toInt()).px,
            label = Strings.city,
            value = optionModel?.city?:"",
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            isOutlinedEditText = true,
            onFocusIn = {

            },
            onFocusOut = {
                onChangeDataOption(ApiUtils.Field.city, it)
            },
            onInputChange = {

            },
        )
    }




    DropdownComponent(
        modifier = Modifier,
        options = LanguageEnum.entries,
        label = Strings.language,
        selectedItem = LanguageEnum.fromDisplayName(optionModel?.language?:"")
            ?: LanguageEnum.English,
        content = {
            Text(it)
        },
        onOptionClick = {
            onChangeDataOption(ApiUtils.Field.language, it)
        },
    )


    DropdownComponent(
        modifier = Modifier.margin(top = Dimen.largePadding),
        options = CountriesEnum.entries,
        label = Strings.companyProfile,
        selectedItem = CountriesEnum.entries.find {
            it.id == optionModel?.countryId
        },
        content = {
            Text(it)
        },
        onOptionClick = {

            val option: CountriesEnum? = CountriesEnum.entries.find { item ->
                item.toString() == it
            }

            if (option != null) {
                onChangeDataOption(ApiUtils.Field.countryId, option.id)
            }


        },
    )
}

