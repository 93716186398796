package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.allergens_tab

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.GetProduct
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.checked_ingredients_list.CheckedIngredient
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.ingeredient_list.Ingredients
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.grid_function.GridFooter
import org.hs_soft.runmynesto.pages.components.SearchBarWithAddIcon
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.allergens_tab.component.AllergensHeader
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.allergens_tab.component.AllergensItem
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.vh

@Composable
fun Allergens(
    selectedProduct: GetProduct?,
    windowSizeMode: WindowSizeModeEnum,
    numberAscending: Boolean,
    nameAscending: Boolean,
    showNumberSortIcon: Boolean,
    showNameSortIcon: Boolean,
    updateAllergenData: (Boolean,String) -> Unit,
    ingredients: List<Ingredients>?,
    checkedIngredients: List<CheckedIngredient?>?,
    onSearchValueChange: (String) -> Unit,
    getIngredients: () -> Unit,
    onNumberSortClick: () -> Unit,
    onNameSortClick: () -> Unit,
    getCheckedIngredients: () -> Unit,
)
{
    LaunchedEffect(Unit){
        getIngredients()
    }
    LaunchedEffect(selectedProduct?.id){
        getCheckedIngredients()
    }

    Column(
        Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .padding(Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
        , horizontalAlignment = Alignment.Start
    )
    {
        SearchBarWithAddIcon(
            onSearchInfo={
                onSearchValueChange(it)
            },
            onAddClick={

            },
        )


        Column(
            modifier = Modifier
                .weight(1f)
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayCD.rgb
                )
                .borderRadius(
                    Dimen.lowBorderRadius,
                )
        )
        {
            AllergensHeader(
                windowSizeMode=windowSizeMode,
                numberAscending =numberAscending ,
                showNumberSortIcon =showNumberSortIcon,
                onNumberSortClick = {
                    onNumberSortClick()
                },
                onContainSortClick = {

                },
                nameAscending =nameAscending,
                showNameSortIcon =showNameSortIcon,
                onNameSortClick = {
                    onNameSortClick()
                },
            )



            Column(
                modifier= VerticalScrollBehavior
                    .toModifier()
                    .height(
                        when(windowSizeMode){
                            WindowSizeModeEnum.Desktop->
                                Constants.Responsive.windowsAllergensHeightRatio.vh
                            WindowSizeModeEnum.TABLET ->
                                Constants.Responsive.tabletAllergensHeightRatio.vh
                            WindowSizeModeEnum.PHONE ->
                                Constants.Responsive.phoneAllergensHeightRatio.vh
                        }
                    )
            ) {
                ingredients?.forEachIndexed {index,model->
                    val checkedStatus = checkedIngredients
                        ?.any { it?.ingredientId == model.id
                                && it?.contains == true } ?: false

                    AllergensItem(
                        checkedStatus = checkedStatus ,
                        windowSizeMode=windowSizeMode,
                        index=index,
                        ingredientModel=model,
                        onItemClick = {

                        },
                        onCheckAllergenItem = {content,ingredientId->
                            updateAllergenData(
                                content,ingredientId
                            )
                        },
                    )
                }

            }

            Spacer()

            GridFooter(
                title = Strings.allergens,
                width = null,
                dataSize = ingredients?.size ?: 0,
                onRefreshClick = {

                }
            )
        }

    }
}