package org.hs_soft.runmynesto.domain.usecase.template

import org.hs_soft.runmynesto.domain.model.home.template.product_categories.TemplateCategoryData
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.TemplateCategoryRepository


class GetTemplateCategoriesUseCase
    (private val repository: TemplateCategoryRepository) {
    suspend operator fun invoke(): TemplateCategoryData {
        return repository.fetchTemplateCategoryList()
    }
}