package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model.ClosingReportDetailModel
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model.ClosingReportItem
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.util.Constants

data class ClosingReportsState(
    val closingReports:List<ClosingReportItem> ?=null,
    val selectedClosingReport: ClosingReportDetailModel?=null,
    val showFirstPage:Boolean=true,
    val loadingProgress:Boolean=false,
    val cashList: List<ChildrenX> ?= null,
    val selectedCalendarHeaderYear:Int=Constants.closingReportsCalendarEndYear,
    val showReportsTableAndDevices:Boolean=true,
    val showDaysFilterDialog:Boolean=false,
    val showCalendarFilterDialog:Boolean=false,
    val selectedFromDate:String?=null,
    val selectedToDate:String?=null,
    val isDevicesExpandAll:Boolean=false,
    val selectedDate: MonthModel ?=null,
    val selectedPeriodType:PeriodDaysEnum=PeriodDaysEnum.TODAY,
    val companyProfileId:String?=null,


)
