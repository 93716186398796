package org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ShopStaticModel(
    @SerialName("data")
    val `data`: Data?
)