package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ClientDiscount(
    @SerialName("number")
    val number: Int?,
    @SerialName("percent")
    val percent: Int?,
    @SerialName("__typename")
    val typename: String?
)