package org.hs_soft.runmynesto.data.api_datasource.template.tab_general

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.addJsonObject
import kotlinx.serialization.json.buildJsonArray
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.update_products_list_position.UpdateProductsPositionModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.UpdateProductsPositionsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateProductsPositionsRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
) : UpdateProductsPositionsRepository {

    override suspend fun updateProductsPosition(
        categoryId: String,
        idsList: List<String>,
        positionsList: List<Int>,
        productIdList: List<String>
    ): UpdateProductsPositionModel {

        val abortController = requestManager.createController()

        val recordsJsonArray = buildJsonArray {
            idsList.forEachIndexed{index,id->
                addJsonObject {
                    put("id", id)
                    putJsonObject("fields") {
                        put("categoryId", categoryId)
                        put("productId", productIdList[index])
                        put("position", positionsList[index])
                    }
                }
            }
        }

        val graphQLQuery = buildJsonObject {
            put("query", """
            mutation ReplaceGridProducts(${'$'}records: [NewGridProductInput!]!) {
              cashAssist {
                replaceGridProducts(records: ${'$'}records) {
                  ${ApiUtils.Field.categoryId}
                  product {
                    ${ApiUtils.Field.id}
                    ${ApiUtils.Field.name}
                  }
                  ${ApiUtils.Field.position}
                }
              }
            }
        """.trimIndent())
            putJsonObject("variables") {
                put("records", recordsJsonArray)
            }
        }


        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<UpdateProductsPositionModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }


}



