package org.hs_soft.runmynesto.pages.home.sub_page.options

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.transitionPageBackground
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.OptionInfoHeaders
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.components.TopTabItems
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.OptionsDialogs
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_flow.CashFlow
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.CashRegisterConfiguration
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.kitchen_locations.KitchenLocationsTab
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.menu_card_import.MenuCardImportTab
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.operation.OptionOperationTab
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.RestaurantManager
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.sales_group.OptionSaleGroupTab
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.VirtualCard
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsViewModel


@Composable
fun Options(
    windowSizeMode: WindowSizeModeEnum,
    viewModel: OptionsViewModel,
)
{
    val state by viewModel.state.collectAsState()

    LaunchedEffect(Unit){
        viewModel.onEvent(OptionsEvent.GetAppConfig)
        viewModel.onEvent(OptionsEvent.GetCheckoutData)
    }

    Column(
        modifier = Modifier
            .fillMaxHeight() 
            .fillMaxWidth()
    ) {

        TopTabItems(
            windowSizeMode=windowSizeMode,
            selectedTab=state.selectedTab,
            tabItems = OptionInfoHeaders.entries,
            onTabClick={
                viewModel.onEvent(OptionsEvent.OnTopTabClick(it))
            }
        )


        Box(
            modifier = Modifier
                .fillMaxHeight()
                .fillMaxWidth()
        ){
            when(state.selectedTab){
                OptionInfoHeaders.OPERATION->{

                    OptionOperationTab(
                        windowSizeMode=windowSizeMode,
                        optionModel =state.optionModel,
                        onChangeDataOption = {title,value->
                            viewModel.onEvent(OptionsEvent.UpdateOptionData(title,value))
                        },
                        loadOperationData = {
                            viewModel.onEvent(OptionsEvent.OnLoadingOperationData)
                        }
                    )


                }

                OptionInfoHeaders.SALES_GROUP->{
                    OptionSaleGroupTab(
                        state =state,
                        event = viewModel::onEvent,
                        windowSizeMode = windowSizeMode,
                    )

                }

                OptionInfoHeaders.KITCHEN_LOCATIONS->{
                    KitchenLocationsTab(
                        state=state,
                        event=viewModel::onEvent,
                        windowSizeMode=windowSizeMode
                    )
                }

                OptionInfoHeaders.CASH_FLOW->{
                    CashFlow(
                        state=state,
                        event=viewModel::onEvent,
                        windowSizeMode=windowSizeMode,
                    )
                }

                OptionInfoHeaders.VIRTUAL_CARD->{
                    VirtualCard(
                        state=state,
                        event=viewModel::onEvent,
                        windowSizeMode=windowSizeMode,
                    )

                }

                OptionInfoHeaders.RESTAURANT_MANAGER->{
                    RestaurantManager(
                        state=state,
                        event=viewModel::onEvent,
                        windowSizeMode=windowSizeMode,
                    )
                }

                OptionInfoHeaders.CASH_REGISTER_CONFIG->{
                    CashRegisterConfiguration(
                        state=state,
                        event=viewModel::onEvent,
                        windowSizeMode=windowSizeMode,
                    )
                }
                OptionInfoHeaders.MENU_CARD_IMPORT->{
                    MenuCardImportTab(
                        state=state,
                        event=viewModel::onEvent,
                        windowSizeMode=windowSizeMode,
                    )
                }
            }
        }



    }

    LoadingProgress(
        visibility = state.dataLoadingProgressVisible
    )

    OptionsDialogs(
        state=state,
        event=viewModel::onEvent,
        windowSizeMode = windowSizeMode
    )
}




