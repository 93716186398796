package org.hs_soft.runmynesto.domain.usecase.dashboard

import org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics.ShopStaticModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.ShopStaticsRepository

class ShopStaticsUseCase(
    private val repository: ShopStaticsRepository
) {
    suspend operator fun invoke(
        requestId:String,
    ): ShopStaticModel {
        return repository.getShopStatics(
            requestId=requestId,
        )
    }
}