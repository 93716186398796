package org.hs_soft.runmynesto.domain.usecase.product.tab_product_general

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.update_cash_bid_img.UpdatedCashBidImgModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.UpdateProductCashBidImageRepository


class UpdateProductCashBidImageUseCase(
    private val repository: UpdateProductCashBidImageRepository
) {
    suspend operator fun invoke(
        image:String,
        id:String,
    ): UpdatedCashBidImgModel {
        return repository.updateProductCashBidImage(
            image=image,
            id=id,
        )
    }
}