package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.dom.svg.Path
import com.varabyte.kobweb.compose.dom.svg.Svg
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.visibility
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.px
import org.hs_soft.runmynesto.domain.config.Dimen

@Composable
fun VectorIcon(
    modifier: Modifier = Modifier,
    pathData: String,
    color: String,
    width:Int= Dimen.smallIconSize,
    height:Int= Dimen.smallIconSize,
    visibilityStatus:Boolean=true
) {

    Svg(
        attrs = modifier
            .visibility(
                if (visibilityStatus) Visibility.Visible
                else Visibility.Hidden
            )
            .width(width.px)
            .height(height.px)
            .toAttrs {
                attr("viewBox", "0 0 $width $height")
            }
    ) {
        Path(
            attrs = Modifier
                .toAttrs {
                    attr("d", pathData)
                    attr("fill",color)
                    attr("stroke-width", "2")
                    attr("stroke-Linecap", "round")
                    attr("stroke-Linejoin", "round")
                }
        )

    }
}

@Composable
fun VectorIcon(
    modifier: Modifier = Modifier,
    pathData: String,
    width:Int,
    height:Int,
) {
    Svg(
        attrs = modifier
            .width(width.px)
            .height(height.px)
            .toAttrs {
                attr("viewBox", "0 0 $width $height")
            }
    ) {
        Path(
            attrs = Modifier
                .toAttrs {
                    attr("d", pathData)
                    attr("stroke-width", "2")
                    attr("stroke-Linecap", "round")
                    attr("stroke-Linejoin", "round")
                }
        )

    }
}

@Composable
fun MenuVectorIcon(
    modifier: Modifier = Modifier,
    pathData: String,
    color: String,
) {
    Svg(
        attrs = modifier
            .width(Dimen.mediumIconSize.px)
            .height(Dimen.mediumIconSize.px)
            .toAttrs {
                attr("viewBox", "0 0 20 20")
            }
    ) {
        Path(
            attrs = Modifier
                .toAttrs {
                    attr("d", pathData)
                    attr("fill",color)
                    attr("stroke-width", "2")
                    attr("stroke-Linecap", "round")
                    attr("stroke-Linejoin", "round")
                }
        )

    }
}