package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("listClosings")
    val listClosings: ListClosings?,
    @SerialName("__typename")
    val typename: String?
)