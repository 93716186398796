package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.update_cash_bid_img.UpdatedCashBidImgModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.UpdateProductCashBidImageRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateProductCashBidImageRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateProductCashBidImageRepository {


    override suspend fun updateProductCashBidImage(
        image: String,
        id: String
    ): UpdatedCashBidImgModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            mutation uploadImgBase64(${'$'}file: String!, ${'$'}type: ImgType!, ${'$'}id: ID!) {
              cashAssist {
                img: uploadImgBase64(file: ${'$'}file, type: ${'$'}type, id: ${'$'}id) {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.uid}
                  ${ApiUtils.Field.key} 
                } 
              }
            }
        """.trimIndent())
            put("variables", buildJsonObject {
                put("file", image)
                put("type", "Product")
                put("id", id)
            })
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<UpdatedCashBidImgModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }

}
