package org.hs_soft.runmynesto.domain.model.home.options.operation.option_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class OptionData(
    @SerialName("data")
    val data: Data = Data()
)