package org.hs_soft.runmynesto.data.api_datasource.closing_report

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonArray
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model.ClosingReportsModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.closing_report.ClosingReportsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class ClosingReportsRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): ClosingReportsRepository
{
    override suspend fun getClosingReports(
        from: String,
        to: String,
        cashRegisterIds: List<String>
    ): ClosingReportsModel {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                  query listClosings(${'$'}filter: closingsFilterArgs, ${'$'}params: ListQueryArgs) {
                    cashAssist {
                      listClosings(filter:${'$'}filter, params: ${'$'}params) {
                        ${ApiUtils.Field.total}
                        ${ApiUtils.Field.totalAmount}
                        items {
                          ${ApiUtils.Field.id}
                          ${ApiUtils.Field.total}
                          ${ApiUtils.Field.dateTime}
                          ${ApiUtils.Field.number}
                          ${ApiUtils.Field.inactive}
                          cashRegister {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.name}
                            ${ApiUtils.Field.__typename}
                          }
                          salesGroups {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.amount}
                            ${ApiUtils.Field.total}
                            ${ApiUtils.Field.mWStNormal}
                            ${ApiUtils.Field.mWStReduced}
                            ${ApiUtils.Field.mWStFree}
                            ${ApiUtils.Field.discount}
                            ${ApiUtils.Field.reduction}
                            salesGroup {
                              ${ApiUtils.Field.id}
                              ${ApiUtils.Field.name}
                              ${ApiUtils.Field.acountNum}
                              ${ApiUtils.Field.__typename}
                            }
                            ${ApiUtils.Field.__typename}
                          }
                          ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.hasNextPage}
                        ${ApiUtils.Field.__typename}
                      }
                      ${ApiUtils.Field.__typename}
                    }
                  }

            """.trimIndent())

            putJsonObject("variables") {
                putJsonObject("filter"){
                    put("cashRegisterIds",Json.encodeToJsonElement(cashRegisterIds))
                    putJsonObject("period"){
                        put("from",from)
                        put("to",to)
                    }
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }

    }



}