package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.update_restaurant_data


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("__typename")
    val typename: String,
    @SerialName("updateProduct")
    val updateProduct: UpdateProduct
)