package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general

enum class AppConstantsEnum(
    val id:String,
    val value:String,
    val label:String,
) {
    VAT_NORMAL(id ="1", label = "VAT normal",value="normal"),
    VAT_REDUCED(id ="1", label = "VAT reduced",value="reduced"),
    VAT_FREE(id ="1", label = "No VAT",value="free"),
    VAT_SPECIAL(id ="1", label = "VAT special",value="special"),

}