package org.hs_soft.runmynesto.domain.model.home.options.single_sale_group


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("getSalesGroup")
    val getSalesGroup: GetSalesGroup?=null,
    @SerialName("updateSalesGroup")
    val updateSalesGroup: GetSalesGroup?=null,
    @SerialName("createMutation")
    val createMutation: GetSalesGroup?=null,
    @SerialName("__typename")
    val typename: String
)