package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.bill_search.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data.BillSearchStatisticDataItem

@Composable
fun BillSearchComponent(
    windowSizeMode: WindowSizeModeEnum,
    statisticData: List<BillSearchStatisticDataItem>?,
    selectedBillSearchStatisticItem: BillSearchStatisticDataItem?,
    onSelectStatisticItem: (BillSearchStatisticDataItem) -> Unit,
    searchBillSearchItems: (String) -> Unit,
    onSearchInSelectedBillSearchItem: (String) -> Unit,
    getBillPdf: (String,String,String) -> Unit,
    exportStatisticReports:(List<BillSearchStatisticDataItem>?)->Unit,
)
{
    Column(
        modifier = Modifier
            .fillMaxWidth()
    )
    {
        BillSearchTableContent(
            modifier = Modifier,
            windowSizeMode=windowSizeMode,
            statisticData = statisticData,
            selectedBillSearchStatisticItem =selectedBillSearchStatisticItem,
            onSelectStatisticItem = {
                onSelectStatisticItem(it)
            },
            searchBillSearchItems = {
                searchBillSearchItems(it)
            },
            getBillPdf = {number:String,dateTime:String,cashRegisterId:String->
                getBillPdf(
                    number,
                    dateTime,
                    cashRegisterId,
                )
            },
            exportStatisticReports = {
                exportStatisticReports(it)
            }

        )

        if (selectedBillSearchStatisticItem!=null)
        {
            SelectedBillSearchItemDetailContent(
                modifier = Modifier,
                windowSizeMode=windowSizeMode,
                selectedBillSearchItem = selectedBillSearchStatisticItem,
                onSearchInSelectedBillSearchItem={
                    onSearchInSelectedBillSearchItem(it)
                }
            )
        }


    }


}



