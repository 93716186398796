package org.hs_soft.runmynesto.domain.usecase.statistic.search_bill

import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.init_search_bill.InitSearchBillModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.search_bill.InitSearchBillRepository

class InitSearchBillUseCase(
    private val repository: InitSearchBillRepository
) {
    suspend operator fun invoke(
        billNumberFrom: Int?,
        billNumberTo: Int?,
        from: String,
        to: String,
        totalFrom: Int?,
        totalTo: Int?,
        cashRegisterIds: List<String>,
        products: List<String>
    ): InitSearchBillModel {

        return repository.initSearchBill(
            billNumberFrom = billNumberFrom,
            billNumberTo = billNumberTo,
            from = from,
            to = to,
            totalFrom = totalFrom,
            totalTo = totalTo,
            cashRegisterIds = cashRegisterIds,
            products = products
        )
    }


}