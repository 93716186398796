package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.dialogs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.pages.components.Separator
import org.jetbrains.compose.web.css.Position


@Composable
fun ClosingReportFinancialFilterDialog(
    isActive:Boolean,
    onPrintFinancialStatementClick:()->Unit,
    onCloseDailyReportClick:()->Unit,
)
{
    val isPrintStatementsHovered= remember { mutableStateOf(false) }
    val isCloseDailyReportHovered= remember { mutableStateOf(false) }
    Column(
        modifier = Modifier
            .margin(top = Dimen.normalPadding)
            .generalDialogSetting()
    )
    {
        Row(
            modifier = Modifier
                .cursor(Cursor.Pointer)
                .backgroundColor(
                    if (isPrintStatementsHovered.value) Theme.GrayAlpha08.rgb
                    else Theme.Transparent.rgb
                )
                .padding(
                    left = Dimen.mediumPadding,
                    top = Dimen.mediumPadding,
                    bottom = Dimen.mediumPadding,
                    right = Dimen.extraLargePadding,
                )
                .position(Position.Relative)
                .rippleEffect {
                    onPrintFinancialStatementClick()
                }
                .onMouseOver { isPrintStatementsHovered.value=true }
                .onMouseLeave { isPrintStatementsHovered.value=false }
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            SpanText(
                text = Strings.printFinancialStatements,
                modifier = Modifier
                    .fontSize(FontSizes.mediumFontSize)
                    .color(Theme.Secondary.rgb)
                    .fontFamily(FontFamily.Jakarta)
            )
        }
        Separator(
            modifier = Modifier.fillMaxWidth()
        )
        Row(
            modifier = Modifier
                .cursor(
                    if (isActive)
                        Cursor.Pointer
                    else Cursor.NotAllowed
                )
                .padding(
                    left = Dimen.mediumPadding,
                    top = Dimen.mediumPadding,
                    bottom = Dimen.mediumPadding,
                    right = Dimen.extraLargePadding,
                )
                .backgroundColor(
                    if (!isActive){
                        Theme.Transparent.rgb
                    }else{
                        if (isCloseDailyReportHovered.value) Theme.GrayAlpha08.rgb
                        else Theme.Transparent.rgb
                    }

                )
                .position(Position.Relative)
                .rippleEffect(isClickable = isActive) {
                    onCloseDailyReportClick()
                }
                .onMouseOver { isCloseDailyReportHovered.value=true }
                .onMouseLeave { isCloseDailyReportHovered.value=false }
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            SpanText(
                text = Strings.deactivateDailyClosing,
                modifier = Modifier
                    .fontSize(FontSizes.mediumFontSize)
                    .color(
                        if (!isActive) Theme.GrayAlpha56.rgb
                        else Theme.Secondary.rgb
                    )
                    .fontFamily(FontFamily.Jakarta)
            )
        }


    }


}