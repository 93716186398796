package org.hs_soft.runmynesto.pages.home.sub_page.product.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onTouchEnd
import com.varabyte.kobweb.compose.ui.modifiers.onTouchMove
import com.varabyte.kobweb.compose.ui.modifiers.onTouchStart
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.GetProduct
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.getGridWidth
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.grid_function.FilterGridContent
import org.hs_soft.runmynesto.pages.components.grid_function.GridFunction
import org.hs_soft.runmynesto.pages.components.grid_function.GridItemsList
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.FilterRow
import org.w3c.dom.get

@Composable
fun GridProductBar(
    titleDescending: Boolean,
    numberDescending: Boolean,
    showNumberSortIcon: Boolean,
    showTitleSortIcon: Boolean,
    showFilterComponent:Boolean,
    selectedFilterOption: GridItemsFilterEnum,
    selectedItem: GetProduct?,
    searchValue: String,
    windowSizeMode: WindowSizeModeEnum,
    infoList: List<ProductItem>,
    onTitleSortClick: () -> Unit,
    onNumberSortClick: () -> Unit,
    onSearchValueChange: (String) -> Unit,
    refreshList: () -> Unit,
    onProductClick: (String) -> Unit,
    onAddRecord: () -> Unit,
    onFilterComponentClick:()->Unit,
    selectFilterType:(GridItemsFilterEnum)->Unit,
    onCloseFilterClick:()->Unit,
    submitFilterItems:()->Unit,
    onSwipeRight:()->Unit,
) {
    var startX by remember { mutableStateOf(0.0) }
    var endX by remember { mutableStateOf(0.0) }
    var tracking by remember { mutableStateOf(false) }
    Column(
        modifier = Modifier
            .fillMaxHeight()
            .then(
                if (windowSizeMode== WindowSizeModeEnum.PHONE){
                    Modifier.onTouchStart { event ->
                        startX = event.touches[0]!!.clientX.toDouble()
                        endX = event.touches[0]!!.clientX.toDouble()
                        tracking = true
                    }
                        .onTouchMove { event ->
                            if (tracking) {
                                endX = event.touches[0]!!.clientX.toDouble()
                            }
                        }
                        .onTouchEnd {
                            if (tracking && endX + Constants.Responsive.SwipeWidth < startX) {
//                                onSwipeRight()
                            }
                            tracking = false
                        }
                }
                else Modifier
            )
    )
    {
        FilterRow(
            width = getGridWidth(windowSizeMode),
            onAddRecordClick = {
                onAddRecord()
            },
            onFilterClick = {
                onFilterComponentClick()
            }
        )

        Box(modifier = Modifier
            .weight(1f)
            .margin(
                top = Dimen.normalPadding
            )
        )
        {
            if (showFilterComponent){
                FilterGridContent(
                    selectFilterType = {
                        selectFilterType(it)
                    },
                    selectedFilterType=selectedFilterOption,
                    onCloseFilterClick ={
                        onCloseFilterClick()
                    },
                    submitFilterItems = {
                        submitFilterItems()
                    }

                )
            }

            Column(
                modifier = Modifier
                    .fillMaxHeight()
            ) {
                CustomEditText(
                    width = getGridWidth(windowSizeMode),
                    label = "",
                    value = searchValue,
                    endIconPath = Res.PathIcon.searchBox,
                    isRequired = false,
                    defaultBorderColor = Theme.GrayCD.rgb,
                    hint = Strings.search,
                    onFocusIn = {},
                    onFocusOut = {},
                    onInputChange = {
                        onSearchValueChange(it)
                    },
                )

                GridFunction(
                    modifier = Modifier ,
                    width = getGridWidth(windowSizeMode),
                    title = Strings.name,
                    infoTitle = Strings.numberDot,
                    footerTitle = Strings.products,
                    infoList = infoList,
                    onTitleSortClick = {
                        onTitleSortClick()
                    },
                    onNumberSortClick = {
                        onNumberSortClick()
                    },
                    refreshList = { refreshList() },
                    content = {
                        GridItemsList(
                            id = selectedItem?.id?:"",
                            items = infoList,
                            onItemClick = {
                                onProductClick(it)
                            },
                        )
                    },
                    titleDescending = titleDescending ,
                    numberDescending = numberDescending,
                    showTitleSortIcon = showTitleSortIcon,
                    showNumberSortIcon = showNumberSortIcon,

                )
            }
        }




    }
}