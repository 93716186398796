package org.hs_soft.runmynesto.domain.usecase.product.tab_product_general

import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.UpdateProductPriceRepository


class UpdateProductPriceUseCase(
    private val repository: UpdateProductPriceRepository
) {
    suspend operator fun invoke(
        dataTitle: String,
        dataValue: List<Double?>,
        productId: String,
    ) {
        repository.updateProductPrice(
             dataTitle=dataTitle,
            dataValue = dataValue,
            productId = productId
        )
    }
}