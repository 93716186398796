package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model.ClosingReportDetailModel
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model.ClosingReportItem
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.hs_soft.runmynesto.pages.components.InfiniteScrollGrid
import org.hs_soft.runmynesto.pages.components.ShowWhenListIsEmpty
import org.hs_soft.runmynesto.pages.components.TableItemRow
import org.hs_soft.runmynesto.pages.components.grid_function.GridFooter
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun ReportsTable(
    windowSizeMode: WindowSizeModeEnum,
    closingReportsList: List<ClosingReportItem>?,
    selectedClosingReport: ClosingReportDetailModel?,
    onClosingReportClick: (String) -> Unit,
)
{
    val selectedItemId = remember { mutableStateOf<String?>(null) }
    LaunchedEffect(selectedClosingReport) {
        selectedItemId.value = selectedClosingReport?.data?.cashAssist?.getClosing?.id
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb
            )
            .borderRadius(Dimen.lowBorderRadius)
    )
    {

        Column(
            modifier = HorizontalScrollBehavior.toModifier()
                .fillMaxWidth()
        )
        {
            ClosingReportsTableHeaders(
                windowSizeMode=windowSizeMode,
            )

            Column(
                modifier = VerticalScrollBehavior
                    .toModifier()
                    .thenIf(
                        windowSizeMode!=WindowSizeModeEnum.PHONE
                                || closingReportsList.isNullOrEmpty(),
                        VerticalScrollBehavior
                            .toModifier().fillMaxWidth()
                    )
                    .height(Dimen.closingReportsTableHeight)
            ){
                if (closingReportsList.isNullOrEmpty()){
                    ShowWhenListIsEmpty()
                }else{
                    InfiniteScrollGrid(
                        items = closingReportsList,
                        onLoadMore = {}
                    ){
                        val model= closingReportsList[it]
                        ReportsTableItem(
                            model = model,
                            isSelected= selectedItemId.value==model.id,
                            onItemClick = {id->
                                onClosingReportClick(id)
                            }
                        )
                    }
                }
            }

        }


        Spacer()

        GridFooter(

            title = Strings.reports,
            width = null,
            dataSize =closingReportsList?.size?:0,
            onRefreshClick = {

            }
        )





    }

}

@Composable
private fun ClosingReportsTableHeaders(
    windowSizeMode: WindowSizeModeEnum
) {

    Row(
        modifier = Modifier
            .thenIf(
                windowSizeMode!=WindowSizeModeEnum.PHONE,
                Modifier.fillMaxWidth()
            )
            .backgroundColor(Theme.GrayAlpha04.rgb)
            .padding(
                leftRight = Dimen.normalPadding,
                topBottom = Dimen.smallPadding,
            )
            .borderBottom(
                width = Dimen.smallThickness,
                color = Theme.GrayCD.rgb,
                style = LineStyle.Solid,
            )
    )
    {
        HeaderTitle(
            title = Strings.date,
            width = Dimen.headerSmallWidth,
        )
        HeaderTitle(
            title = Strings.time,
            width = Dimen.headerSmallWidth,
        )
        HeaderTitle(
            title = Strings.numberDot,
            width = Dimen.headerSmallWidth,
            arrangement = Arrangement.End
        )
        HeaderTitle(
            title = Strings.devices,
            width = Dimen.headerExtraLargeWidth,
        )

        Spacer()

        HeaderTitle(
            title = Strings.salesVolume,
            arrangement = Arrangement.End
        )
    }
}