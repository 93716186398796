package org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LoyaltyCardItem(
    @SerialName("balance")
    val balance: Double?,
    @SerialName("id")
    val id: String?,
    @SerialName("isActive")
    val isActive: Boolean?,
    @SerialName("name")
    val name: String?,
    @SerialName("points")
    val points: Int?,
    @SerialName("type")
    val type: Int?,
    @SerialName("__typename")
    val typename: String?
)