package org.hs_soft.runmynesto.domain.model.home.user.tabs.user_rights


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateUser(
    @SerialName("cancelationPermissions")
    val cancelationPermissions: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("isAdmin")
    val isAdmin: Boolean?,
    @SerialName("isDayFinStatements")
    val isDayFinStatements: Boolean?,
    @SerialName("isFinStatements")
    val isFinStatements: Boolean?,
    @SerialName("isManager")
    val isManager: Boolean?,
    @SerialName("isTimeTracking")
    val isTimeTracking: Boolean?,
    @SerialName("isViewSales")
    val isViewSales: Boolean?,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("typeRFID")
    val typeRFID: String?,
    @SerialName("__typename")
    val typename: String?
)