package org.hs_soft.runmynesto.data.api_datasource.template.tab_options

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_options.PriceLevelModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_options.PriceLevelsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils
import kotlin.math.abs

class PriceLevelsRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): PriceLevelsRepository
{
    override suspend fun getPriceLevels(): PriceLevelModel {


        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {

            put("query", """
                query initialize {
                  cashAssist {
                    getAppConfigAdditional {
                      ${ApiUtils.Field.priceLevels}  
                    }
                    getAppConfigAdditional {
                      ${ApiUtils.Field.priceLevels}  
                    }
                  }
                }
        
            """.trimIndent())
    }



        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController
        )

        return if (result != null) {
            Json.decodeFromString<PriceLevelModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }

    }



}
