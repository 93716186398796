package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.series_tab

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.series_tab.components.LoadingLabeling
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.series_tab.components.ResetBooking
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.series_tab.components.SeriesCard
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardEvent
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardState
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.overflowY
import org.jetbrains.compose.web.css.vh

@Composable
fun SeriesTab(
    modifier: Modifier=Modifier,
    windowSizeMode: WindowSizeModeEnum,
    state: CustomerCardState,
    event: (CustomerCardEvent) -> Unit
)
{
    Column(
        modifier = modifier
            .fillMaxWidth()
            .height(Constants.Responsive.templateGridContentHeight.vh)
            .styleModifier {
                flexGrow(1)
                overflowY("auto")
            }
            .margin(top = Dimen.normalPadding)
            .padding(Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    )
    {
        SeriesCard(
            selectedLoyaltyCard=state.selectedLoyaltyCard,
            createCustomerCard = {type:Int,from:Int,to:Int->
                event(CustomerCardEvent.CreateCustomerCard(
                    type=type,
                    from=from,
                    to=to,
                ))
            }
        )

        LoadingLabeling(
            windowSizeMode=windowSizeMode,
            selectedLoyaltyCard=state.selectedLoyaltyCard,
            updateLoadingWorkCards = { from:Int, to:Int, balance:Int, desc:String->
                event(CustomerCardEvent.UpdateRangeLoyaltyCard(
                    from=from,
                    to=to,
                    balance=balance,
                    description=desc,
                ))
            },
            updateLabelingWorkCards={ from:Int, to:Int, name:String->
                event(CustomerCardEvent.UpdateRangeLoyaltyCard(
                    from=from,
                    to=to,
                    name=name
                ))
            }

        )

        ResetBooking(
            selectedLoyaltyCard=state.selectedLoyaltyCard,
            resetRangeLoyaltyCard={from:Int, to:Int->
                event(CustomerCardEvent.ResetRangeLoyaltyCard(
                    from =from,
                    to=to,
                ))
            }

        )
    }



}

fun getCustomerCardSeriesBorderColor(
    from:String,until:String
) = if (from.isNotEmpty() && until.isNotEmpty() && from.toInt() > until.toInt() )
    Theme.Red.rgb else Theme.GrayCD.rgb