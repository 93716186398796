package org.hs_soft.runmynesto.pages.home

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import org.hs_soft.runmynesto.di.GlobalKoin
import org.hs_soft.runmynesto.domain.model.menu.MenuItemsEnum
import org.hs_soft.runmynesto.domain.model.menu.MenuModel
import org.hs_soft.runmynesto.domain.util.helper.BaseViewModel
import org.hs_soft.runmynesto.navigation.Screen
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.components.RememberWindowSize
import org.hs_soft.runmynesto.pages.components.menu.Menu
import org.hs_soft.runmynesto.pages.components.top_toolbar.TopPageToolbar
import org.hs_soft.runmynesto.pages.home.mvi.HomeEvent
import org.hs_soft.runmynesto.pages.home.mvi.HomeState
import org.hs_soft.runmynesto.pages.home.mvi.HomeViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.ClosingReports
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.CustomerCard
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardEvent
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.DashBoard
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.mvi.DashboardViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.options.Options
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.product.Product
import org.hs_soft.runmynesto.pages.home.sub_page.product.mvi.ProductEvent
import org.hs_soft.runmynesto.pages.home.sub_page.product.mvi.ProductViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.Statistic
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi.StatisticViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.template.Template
import org.hs_soft.runmynesto.pages.home.sub_page.template.mvi.TemplateEvent
import org.hs_soft.runmynesto.pages.home.sub_page.template.mvi.TemplateViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.user.User
import org.hs_soft.runmynesto.pages.home.sub_page.user.mvi.UserEvent
import org.hs_soft.runmynesto.pages.home.sub_page.user.mvi.UserViewModel
import org.koin.core.parameter.parametersOf

@Page
@Composable
fun Home()
{
    val context= rememberPageContext()
    val viewModel = remember {
        GlobalKoin.get<HomeViewModel> { parametersOf() }
    }

    val state by viewModel.state.collectAsState()

    viewModel.onEvent(
        HomeEvent.InitWindowsSize(windowsSize = RememberWindowSize())
    )

    LaunchedEffect(Unit){
        viewModel.onEvent(HomeEvent.SetIsUserLoggedIn)

    }
    LaunchedEffect(state.isLoggedIn){
        if (!state.isLoggedIn){
            context.router.navigateTo(
                Screen.Login.route,
                updateHistoryMode = UpdateHistoryMode.REPLACE
            )
        }
    }



    if (state.idToken!=null){
        LoadHomePage(
            state=state,
            event=viewModel::onEvent,
        )
    }else viewModel.onEvent(HomeEvent.FetchIdToken)

    LoadingProgress(
        visibility = state.isLoading
    )


}

@Composable
private fun LoadHomePage(
    state: HomeState,
    event: (HomeEvent) -> Unit,
) {
    LaunchedEffect(Unit){
        event(HomeEvent.GetCheckoutData)
        event(HomeEvent.UserProfileInfo)
        event(HomeEvent.GetHSSoftApps)
    }


    var baseViewModel:BaseViewModel?=null
    var productViewModel:ProductViewModel?=null
    var templateViewModel:TemplateViewModel?=null
    var userViewModel:UserViewModel?=null
    var customerCardViewModel:CustomerCardViewModel?=null
    var closingReportsViewModel:ClosingReportsViewModel?=null
    var optionsViewModel:OptionsViewModel?=null
    var dashboardViewModel: DashboardViewModel?=null
    var statisticViewModel:StatisticViewModel?=null

    DisposableEffect(state.activePage) {
        onDispose {
            baseViewModel?.clear()
        }
    }


    Menu(
        isMenuExpandedHorizontally = state.isMenuExpandedHorizontally,
        closeMenu = {
            event(HomeEvent.CloseMenu)
        },
        changeMenuStatus = {
            event(HomeEvent.ChangeMenuStatus)
        },
        windowSizeMode = state.windowSizeMode,
        data = MenuModel(),
        selectedItem = state.activePage,
        onMenuItemClick = {
           event(HomeEvent.OnMenuItemClick(it))
        },
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
        ) {
            TopPageToolbar(
                windowSizeMode =state.windowSizeMode,
                title = state.activePage.title,
                listOfHSSoftApps=state.listOfHSSortsApps,
                profileInfo = state.profileInfo,
                iconPath = state.activePage.iconPath,
                showSearchBarInMobileMode = state.showSearchBarInMobileMode,
                showSettingDialog = state.showSettingDialog,
                showCompanies = state.showCompaniesDialog,
                carouselVisibility = when(state.activePage){
                    MenuItemsEnum.DASHBOARD ->false
                    else-> true
                },
                changeShowingSearchBarInMobileStatus = {
                    event(HomeEvent.ChangeShowingSearchBarInMobileStatus)
                },
                onToolbarSettingIconClick = {
                    event(HomeEvent.OnToolbarSettingIconClick)
                },
                onToolbarCompaniesMenuIconClick ={
                    event(HomeEvent.OnToolbarCompaniesMenuIconClick)
                },
                onLogoutClick = {
                    event(HomeEvent.SignOut)
                },
                onChangePageClick = {
                    when(state.activePage)
                    {
                        MenuItemsEnum.PRODUCT ->{
                            productViewModel?.onEvent(ProductEvent.OnChangePageClick)
                        }
                        MenuItemsEnum.TEMPLATE ->{
                            templateViewModel?.onEvent(TemplateEvent.OnChangePageClick)
                        }
                        MenuItemsEnum.USER ->{
                            userViewModel?.onEvent(UserEvent.OnChangePageClick)
                        }
                        MenuItemsEnum.CUSTOMER_CARD->{
                            customerCardViewModel?.onEvent(CustomerCardEvent.OnChangePageClick)
                        }
                        MenuItemsEnum.CLOSING_REPORTS ->{
                            closingReportsViewModel?.onEvent(ClosingReportsEvent.OnChangePageClick)
                        }
                        MenuItemsEnum.STATISTIC->{
//                            statisticViewModel?.onEvent(StatisticEvent.OnChangePageClick)
                        }

                        MenuItemsEnum.OPTIONS ->{
                            optionsViewModel?.onEvent(OptionsEvent.OnChangePageClick)

                        }
                        MenuItemsEnum.DASHBOARD->{

                        }


                    }

                }
            )

            Box(
                modifier = Modifier
                    .fillMaxSize()
            )
            {
                when (state.activePage) {
                    MenuItemsEnum.PRODUCT -> {
                        productViewModel = remember {
                            GlobalKoin.get<ProductViewModel> {
                                parametersOf(event(HomeEvent.FetchIdToken))
                            }
                        }
                        baseViewModel=productViewModel
                        Product(
                            windowSizeMode = state.windowSizeMode,
                            viewModel = productViewModel!!,
                        )
                    }
                    MenuItemsEnum.TEMPLATE -> {
                        templateViewModel = remember {
                            GlobalKoin.get<TemplateViewModel> {
                                parametersOf(event(HomeEvent.FetchIdToken))
                            }
                        }
                        baseViewModel=templateViewModel
                        Template(
                            windowSizeMode = state.windowSizeMode,
                            viewModel = templateViewModel!!,
                        )
                    }
                    MenuItemsEnum.USER -> {

                        userViewModel = remember {
                            GlobalKoin.get<UserViewModel> {
                                parametersOf(event(HomeEvent.FetchIdToken))
                            }
                        }
                        baseViewModel=userViewModel
                        User(
                            windowSizeMode = state.windowSizeMode,
                            viewModel = userViewModel!!,
                        )
                    }
                    MenuItemsEnum.CUSTOMER_CARD->{
                        customerCardViewModel= remember {
                            GlobalKoin.get<CustomerCardViewModel>{
                                parametersOf(event(HomeEvent.FetchIdToken))
                            }
                        }
                        baseViewModel=customerCardViewModel
                        CustomerCard(
                            windowSizeMode = state.windowSizeMode,
                            viewModel = customerCardViewModel!!,
                        )
                    }


                    MenuItemsEnum.CLOSING_REPORTS -> {
                        closingReportsViewModel = remember {
                            GlobalKoin.get<ClosingReportsViewModel> {
                                parametersOf(event(HomeEvent.FetchIdToken))
                            }
                        }
                        baseViewModel=closingReportsViewModel
                        ClosingReports(
                            windowSizeMode = state.windowSizeMode,
                            viewModel = closingReportsViewModel!!,
                        )
                    }
                    MenuItemsEnum.STATISTIC->{
                        statisticViewModel = remember {
                            GlobalKoin.get<StatisticViewModel> {
                                parametersOf(event(HomeEvent.FetchIdToken))
                            }
                        }
                        baseViewModel=statisticViewModel
                        Statistic(
                            windowSizeMode = state.windowSizeMode,
                            viewModel = statisticViewModel!!,
                        )
                    }

                    MenuItemsEnum.OPTIONS -> {

                        optionsViewModel = remember {
                            GlobalKoin.get<OptionsViewModel> {
                                parametersOf(event(HomeEvent.FetchIdToken))
                            }
                        }
                        baseViewModel=optionsViewModel
                        Options(
                            windowSizeMode = state.windowSizeMode,
                            viewModel = optionsViewModel!!,
                        )
                    }

                    MenuItemsEnum.DASHBOARD->{
                        dashboardViewModel = remember {
                            GlobalKoin.get<DashboardViewModel> {
                                parametersOf(event(HomeEvent.FetchIdToken))
                            }
                        }
                        baseViewModel=dashboardViewModel
                        DashBoard(
                            windowSizeMode = state.windowSizeMode,
                            viewModel = dashboardViewModel!!,
                        )
                    }
                }
            }



        }




    }


}




