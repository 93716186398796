package org.hs_soft.runmynesto.domain.model.home.product

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.TitleEnum

enum class ProductInfoHeaderEnum(override val title: String)
    : TitleEnum {
        GENERAL(Strings.general ),
        CHECKOUT(Strings.devices ),
        RESTAURANT(Strings.restaurant),
        CLICK_AND_CONNECT(Strings.clickAndCollect),
        ALLERGENES(Strings.allergens)
}