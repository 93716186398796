package org.hs_soft.runmynesto.data.api_datasource.options.operation

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.operation.option_model.OptionData
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.operation.OptionDataRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class OptionsDataRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): OptionDataRepository {
    override suspend fun optionData(): OptionData {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
           query getAppConfig {
             cashAssist {
               getAppConfig {
                 ${ApiUtils.Field.name}
                 ${ApiUtils.Field.street}
                 ${ApiUtils.Field.postCode}
                 ${ApiUtils.Field.city}
                 ${ApiUtils.Field.language}
                 ${ApiUtils.Field.countryId}
                 ${ApiUtils.Field.language} 
                 ${ApiUtils.Field.mainCurrency}  
                 ${ApiUtils.Field.isShopFieldActive}    
               }
             }
           }
        """.trimIndent())
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<OptionData>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }

    }

}
