package org.hs_soft.runmynesto.domain.usecase.user.tab_right

import org.hs_soft.runmynesto.domain.repository.network_datasource.user.tab_right.UpdateUserWaiterKeyRepository

class UpdateUserWaiterUseCase(
    private val repository: UpdateUserWaiterKeyRepository
) {
    suspend operator fun invoke(
        dataValue: String,
        userId: String
    ){
       return repository.updateUserWaiterKey(
           dataValue=dataValue,
           userId=userId,
        )
    }
}