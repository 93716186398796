package org.hs_soft.runmynesto.di.closing_reports

import org.hs_soft.runmynesto.domain.usecase.closing_report.ClosingReportDetailUseCase
import org.hs_soft.runmynesto.domain.usecase.closing_report.ClosingReportsUseCase
import org.hs_soft.runmynesto.domain.usecase.closing_report.PrintClosingReportsUseCase
import org.koin.dsl.module

val closingReportsUseCaseModule= module {

    single {
        ClosingReportsUseCase(get())
    }
    single {
        ClosingReportDetailUseCase(get())
    }
    single {
        PrintClosingReportsUseCase(get())
    }



}
