package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CircleIcon
import org.hs_soft.runmynesto.pages.components.VectorIcon

@Composable
fun CalendarMonthRow(
    selectedItem: MonthModel,
    onPreviousMonthClick: () -> Unit,
    onNextMonthClick: () -> Unit,
    onCloseFilterClick:()->Unit,
) {


    Row(
        modifier = Modifier
            .margin(bottom = Dimen.mediumPadding)
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically

    )
    {

        VectorIcon(
            modifier = Modifier
                .onClick {
                    onPreviousMonthClick()
                }
                .margin(right = Dimen.normalPadding),
            pathData = Res.PathIcon.previous,
            color = Theme.GrayAlpha56.hex,
            width = Dimen.mediumIconSize,
            height = Dimen.mediumIconSize,
        )
        SpanText(
            text = selectedItem.monthName+","
                    +selectedItem.yearNumber.toString(),
            modifier = Modifier
                .fontFamily(FontFamily.Jakarta)
                .fontSize(FontSizes.mediumFontSize)
                .fontWeight(FontWeight.Medium)
                .color(Theme.Primary.rgb)

        )

        VectorIcon(
            modifier = Modifier
                .onClick {
                    onNextMonthClick()
                }
                .margin(left = Dimen.normalPadding),
            pathData = Res.PathIcon.next,
            color = Theme.GrayAlpha56.hex,
            width = Dimen.mediumIconSize,
            height = Dimen.mediumIconSize,
        )

        Spacer()

        CircleIcon(
            size = Dimen.semiLargeIconSize,
            img = Res.PathIcon.largeClose,
            borderColor = Theme.Transparent.rgb,
            onIconClick = {
                onCloseFilterClick()
            },
            modifier = Modifier
        )




    }


}