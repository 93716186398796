package org.hs_soft.runmynesto.data.api_datasource.options.cash_flow

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.cash_flow.app_config_model.AppConfigModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.cash_flow.UpdateAppConfigRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateAppConfigRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateAppConfigRepository
{
    override suspend fun updateAppConfig(
        title:String,
        value:List<String?> ,
    ): AppConfigModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """
                    query getAppConfig {
                      cashAssist {
                       	 getAppConfig { 
                           ${ApiUtils.Field.creditCards}
                      	   ${ApiUtils.Field.specialProductExpenses}
                         cashRegisterGlobal {
                                printerLocations {
                                  ${ApiUtils.Field.dbIndex}
                                  ${ApiUtils.Field.value}
                                  __typename
                                }
                                ${ApiUtils.Field.pointsSystemName}
                                ${ApiUtils.Field.isActivePointsSystem}
                                __typename
                              }
                              __typename
                            }
                        } 
                      
                    }
                """.trimIndent()
            )

            putJsonObject("variables") {
                putJsonObject("fields") {
                    put(title, Json.encodeToJsonElement(value))
                }
            }

        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }

}
