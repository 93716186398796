package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_allergens

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.checked_ingredients_list.CheckedIngredientData
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_allergens.CheckedIngredientsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class CheckedIngredientsRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): CheckedIngredientsRepository {


    override suspend fun getCheckedIngredients(productId: String):
            CheckedIngredientData? {

       val abortController = requestManager.createController()


        val graphQLQuery = buildJsonObject {
            put(
                "query", """
                    query getProduct_Ingrs(${'$'}id: ID!) {
                      cashAssist {
                        getProduct(id: ${'$'}id) { 
                          ...ingredients 
                        } 
                      }
                    } 
                    fragment ingredients on Product {
                      ingredients {
                        ${ApiUtils.Field.id} 
                        ${ApiUtils.Field.contains}
                        ${ApiUtils.Field.ingredientId} 
                      } 
                    }
                """.trimIndent()
            )

            put("variables", buildJsonObject {
                put("id", productId)
            })
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<CheckedIngredientData?>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }
}
