package org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.abc_statistic_data


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("getStatisticsData")
    val getStatisticsData: GetStatisticsData?,
    @SerialName("__typename")
    val typename: String?
)