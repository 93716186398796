package org.hs_soft.runmynesto.data.api_datasource.dashboard

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config.DashboardConfigModel
import org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics.ShopStaticModel
import org.hs_soft.runmynesto.domain.model.home.dashboard.init_shop_statics.InitShopStaticsModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.DashboardConfigRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.InitShopStaticsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.ShopStaticsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils


class DashboardConfigRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): DashboardConfigRepository {


    override suspend fun getDashboardConfig(): DashboardConfigModel {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """
                    {
                      ${ApiUtils.Field.cashAssist} {
                        ${ApiUtils.Field.clientTree} {
                          ${ApiUtils.Field.regions} {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.name}
                            ${ApiUtils.Field.shops} {
                              ${ApiUtils.Field.id}
                              ${ApiUtils.Field.typename}
                            }
                            ${ApiUtils.Field.typename}
                          }
                          ${ApiUtils.Field.typename}
                        }
                        ${ApiUtils.Field.listShops} {
                          ${ApiUtils.Field.items} {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.name}
                            ${ApiUtils.Field.regionId}
                            ${ApiUtils.Field.cashRegisters} {
                              ${ApiUtils.Field.id}
                              ${ApiUtils.Field.name}
                              ${ApiUtils.Field.shopId}
                              ${ApiUtils.Field.isAvailableInWebApp}
                              ${ApiUtils.Field.typename}
                            }
                            ${ApiUtils.Field.typename}
                          }
                          ${ApiUtils.Field.typename}
                        }
                        ${ApiUtils.Field.getAppConfig} {
                          ${ApiUtils.Field.language}
                          ${ApiUtils.Field.mainCurrency}
                          ${ApiUtils.Field.typename}
                        }
                        ${ApiUtils.Field.getDashboardDSGeo} {
                          ${ApiUtils.Field.id}
                          ${ApiUtils.Field.coordinates}
                          ${ApiUtils.Field.googleMapUrl}
                          ${ApiUtils.Field.typename}
                        }
                        ${ApiUtils.Field.typename}
                      }
                    }
                """.trimIndent()
            )


        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }


}

