package org.hs_soft.runmynesto.data.api_datasource.user.tab_right

import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.tab_right.UpdateUserWaiterKeyRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateUserWaiterKeyRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateUserWaiterKeyRepository {

    override suspend fun updateUserWaiterKey(
        dataValue: String,
        userId: String
    ) {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            mutation updateUser_WaiterKey(${'$'}id: ID!, ${'$'}fields: UserFields!) {
              cashAssist {
                updateUser(id: ${'$'}id, fields: ${'$'}fields) {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.mod_ver}
                  ${ApiUtils.Field.waiterKey}
                  ${ApiUtils.Field.__typename}
                }
                ${ApiUtils.Field.__typename}
              }
            }
        """.trimIndent())

            putJsonObject("variables") {
                put("id", userId)
                putJsonObject("fields") {
                    put(ApiUtils.Field.waiterKey, dataValue)
                }
            }
        }

        apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        ) ?: throw Exception(Strings.timeoutErr)

    }


}

