package org.hs_soft.runmynesto.domain.model.home.closing_reports

import org.hs_soft.runmynesto.domain.util.ApiUtils

enum class CurrencyTypeEnum(
    val title:String
){
    CHF(
        title = ApiUtils.CURRENCY_TYPE.CHF
    ),

    EUR(
        title = ApiUtils.CURRENCY_TYPE.EUR
    )

}