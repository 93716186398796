package org.hs_soft.runmynesto.domain.model.home.template

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.TitleEnum

enum class GridItemsFilterEnum(override val title: String, val value:Boolean?) : TitleEnum {

    ALL_ITEMS(title= Strings.allItems, value = null),
    ACTIVE_ITEMS(title = Strings.activeItems, value = true),
    INACTIVE_ITEMS(title= Strings.inactiveItems, value = false),


}