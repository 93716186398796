package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components.dialogs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.RestaurantProject
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.dialog.component.SubmitButtons
import org.jetbrains.compose.web.attributes.InputType

@Composable
fun RestaurantFloorDialog(
    windowSizeMode: WindowSizeModeEnum,
    selectedProject: RestaurantProject?,
    onDismissDialog: () -> Unit,
    onSubmitClick:(Int)->Unit,
)
{
    val floorNumber= remember{ mutableStateOf<Int?>(null) }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                onDismissDialog()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(Constants.ZIndex.normalZIndex)
    )
    {

        Column(
            modifier = Modifier
                .align(Alignment.Center)
                .boxShadow(
                    blurRadius = Dimen.smallBlurRadius,
                    color = Theme.Gray3E.rgb,
                )
                .generalDialogSetting()
        )
        {
            SpanText(
                text = Strings.addNewFloor ,
                modifier = Modifier
                    .padding(
                        topBottom = Dimen.normalPadding,
                        leftRight = Dimen.normalPadding
                    )
                    .fontSize(FontSizes.largeFontSize)
                    .fontWeight(FontWeight.Medium)
                    .color(Theme.Secondary.rgb)
                    .fontFamily(FontFamily.Jakarta)
                    .lineHeight(FontLineHeight.lineLarge)
                    .letterSpacing(LetterSpacing.small)
            )

            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(Dimen.smallThickness)
                    .backgroundColor(Theme.GrayAlpha12.rgb)
            )

            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(
                        topBottom = Dimen.mediumPadding,
                    )
            )
            {


                CustomEditText(
                    width =Dimen.highNormalEditTextWidth,
                    label = Strings.floorNumber,
                    value =floorNumber.value?.toString()?:"",
                    isRequired = true,
                    inputType = InputType.Number,
                    marginLeft = Dimen.normalPadding,
                    defaultBorderColor = Theme.GrayCD.rgb,
                    isOutlinedEditText = true,
                    onFocusIn = {},
                    onFocusOut = {},
                    onInputChange = {
                        if (it.isEmpty()) floorNumber.value=null
                        else floorNumber.value=it.toInt()
                    },
                )


                Spacer()



                SubmitButtons(
                    windowSize=windowSizeMode,
                    isSubmitClickable = !selectedProject?.floors?.map { it?.number }
                            !!.contains(floorNumber.value) && floorNumber.value!=null
                    ,
                    arrangement= Arrangement.Center,
                    onCancelClick = {
                        onDismissDialog()
                    },
                    onSubmitClick = {
                        floorNumber.value?.let {
                            onSubmitClick(it)
                        }
                    },
                )


            }



        }

    }


}