package org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general

import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.update_template_category.UpdateTemplateCategoryModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.UpdateTemplateCategoryRepository

class UpdateTemplateCategoryUseCase(
    private val repository: UpdateTemplateCategoryRepository
) {
    suspend operator fun invoke(
        dataValue:dynamic,
        dataTitle:String,
        id:String,
    ): UpdateTemplateCategoryModel {
        return repository.updateTemplateCategory(
            dataValue = dataValue,
            dataTitle=dataTitle,
            id=id
        )
    }
}