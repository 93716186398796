package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.visibility
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.scrollBar
import org.hs_soft.runmynesto.domain.config.transitionColor
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.Floor
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.RestaurantProject
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.Room
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.getCheckoutWidth
import org.hs_soft.runmynesto.domain.util.getGeneralItemRatio
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.Separator
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.CSSUnitLength
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh

@Composable
fun RestaurantProject(
    modifier: Modifier = Modifier,
    fillMaxWidth: Boolean = true,
    selectedRoom: Room?,
    selectedRestaurantProject: RestaurantProject?,
    restaurantProjects: List<RestaurantProject?>?,
    marginBottom: CSSSizeValue<CSSUnit.px> = Dimen.normalPadding,
    windowSizeMode: WindowSizeModeEnum,
    searchValue: String,
    width: CSSSizeValue<out CSSUnitLength> = getCheckoutWidth(windowSizeMode),
    height: CSSSizeValue<CSSUnit.vh> =
        Constants.Responsive.templateGridContentHeight.vh,
    onSearchValueChange: (String) -> Unit,
    onProjectClick: (RestaurantProject?) -> Unit,
    onRoomClick: (Room?, RestaurantProject?) -> Unit,
    onFloorClick: (RestaurantProject?) -> Unit,
) {

    Column(
        modifier = modifier
            .fillMaxHeight()
            .margin(
                bottom = marginBottom,
            )
            .then(
                if (fillMaxWidth) Modifier.fillMaxWidth()
                else Modifier
            )
            .padding(
                leftRight = Dimen.normalPadding,
                topBottom = Dimen.normalPadding,
            ),
        horizontalAlignment = if (windowSizeMode== WindowSizeModeEnum.Desktop) Alignment.Start
        else Alignment.CenterHorizontally
    ) {

        CustomEditText(
            modifier=Modifier,
            width = width,
            label = "",
            value = searchValue,
            endIconPath = Res.PathIcon.searchBox,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = Strings.search,
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {
                onSearchValueChange(it)
            },
        )


        Projects(
            modifier =VerticalScrollBehavior
                .toModifier()
                .weight(1f)
            ,
            windowSizeMode=windowSizeMode,
            selectedRestaurantProject=selectedRestaurantProject,
            selectedRestaurantRoom=selectedRoom,
            restaurantProjects=restaurantProjects,
            width = width,
            height = height,
            onProjectItemClick = {
                onProjectClick(it)
            },
            onFloorClick = {restaurantProjects->
                onFloorClick(restaurantProjects)
            },
            onRoomClick = {room,restaurantProject->
                onRoomClick(room,restaurantProject)
            }
        )
    }
}

@Composable
private fun Projects(
    windowSizeMode: WindowSizeModeEnum,
    modifier: Modifier = VerticalScrollBehavior.toModifier(),
    selectedRestaurantRoom: Room?,
    selectedRestaurantProject: RestaurantProject?,
    height: CSSSizeValue<CSSUnit.vh>,
    restaurantProjects: List<RestaurantProject?>?,
    width: CSSSizeValue<out CSSUnitLength>,
    onProjectItemClick: (RestaurantProject?) -> Unit,
    onFloorClick: (RestaurantProject?) -> Unit,
    onRoomClick: (Room?, RestaurantProject?) -> Unit,
) {
    Column(
        modifier = modifier
            .overflow(Overflow.Auto)
            .height(height)
            .width(width)
            .border(
                color = Theme.GrayCD.rgb,
                style = LineStyle.Solid,
                width = Dimen.smallThickness
            )
            .scrollBar()
            .borderRadius(Dimen.lowBorderRadius)

    )
    {
        restaurantProjects?.forEach {restaurantProject->
            if (!restaurantProject?.floors.isNullOrEmpty()){

                ProjectItem(
                    windowSizeMode = windowSizeMode,
                    projectItemIsSelected=selectedRestaurantProject?.id==restaurantProject?.id,
                    model =restaurantProject,
                    ratioChecked = "",
                    isExpandAll = true,
                    onProjectItemClick = {
                         onProjectItemClick(it)
                    },
                    content = {
                        Floors(
                            floors=restaurantProject?.floors?: listOf(),
                            selectedRestaurantRoom=selectedRestaurantRoom,
                            windowSizeMode = windowSizeMode,
                            isExpandAll = true,
                            onFloorClick = {
                                onFloorClick(restaurantProject)
                            },
                            onRoomClick = {
                                onRoomClick(it,restaurantProject)
                            }

                        )
                    }
                )
                Separator()
            }


        }

    }

}


@Composable
fun ProjectItem(
    model: RestaurantProject?,
    windowSizeMode: WindowSizeModeEnum,
    projectItemIsSelected: Boolean,
    ratioChecked: String,
    isExpandAll: Boolean,
    content: @Composable () -> Unit,
    onProjectItemClick: (RestaurantProject?) -> Unit,
)
{
    val isExpanded= remember{ mutableStateOf(isExpandAll) }
    LaunchedEffect(isExpandAll){
        isExpanded.value=isExpandAll
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .cursor(Cursor.Pointer)
            .onClick {
                event: SyntheticMouseEvent -> event.stopPropagation()
            },
        horizontalAlignment = Alignment.End
    ) {
        Row(
            modifier = Modifier
                .rippleEffect {
                    onProjectItemClick(model)
                }
                .fillMaxWidth()
                .backgroundColor(Theme.GrayAlpha08.rgb)
                .padding(leftRight = Dimen.normalPadding)
                .boxShadow(color = Theme.GrayAlpha12.rgb)
                .height(Dimen.checkOutItemHeight),
            verticalAlignment = Alignment.CenterVertically
        )
        {

            SpanText(
                text = model?.caption?:"",
                modifier = Modifier
                    .margin(left = Dimen.semiNormalPadding)
                    .fontWeight(FontWeight.Medium)
                    .fontFamily(FontFamily.Jakarta)
                    .fontSize(
                        getGeneralItemRatio(
                            windowSizeMode=windowSizeMode,
                            size = FontSizes.mediumFontSize,
                        )
                    )
                    .transitionColor()
                    .color(
                        if (projectItemIsSelected) Theme.Blue.rgb
                        else Theme.Primary.rgb
                    ),
            )

            Spacer()

            SpanText(
                text = ratioChecked,
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .margin(leftRight = Dimen.smallPadding)
                    .fontWeight(FontWeight.Medium)
                    .fontFamily(FontFamily.Jakarta)
                    .fontSize( FontSizes.normalFontSize)
                    .color(Theme.Primary.rgb),
            )


            VectorIcon(
                modifier = Modifier
                    .onClick {
                        isExpanded.value=!isExpanded.value
                    },
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
                pathData= getArrowImg(expanded = isExpanded.value),
                color = Theme.Primary.hex
            )

        }

        if (isExpanded.value){
            Column(
                modifier = Modifier
                    .fillMaxWidth(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                content()
            }
        }


        Separator()


    }


}


@Composable
fun Floors(
    isExpandAll: Boolean,
    windowSizeMode: WindowSizeModeEnum,
    floors: List<Floor?>,
    selectedRestaurantRoom: Room?,
    onFloorClick: () -> Unit,
    onRoomClick: (Room?) -> Unit,

) {
    floors.forEachIndexed {index,model->

        if (!model?.rooms.isNullOrEmpty()){
            FloorItem(
                windowSizeMode=windowSizeMode,
                isExpandAll = isExpandAll,
                floor =  model,
                content = {
                    Rooms(
                        rooms=model?.rooms?: listOf(),
                        selectedRestaurantRoom=selectedRestaurantRoom,
                        windowSizeMode=windowSizeMode,
                        isExpandAll=true,
                        onRoomClick = {
                            onRoomClick(it)
                        }
                    )
                },
                onFloorClick = {
                    onFloorClick()
                }
            )
        }

    }

}

@Composable
fun Rooms(
    rooms: List<Room?>,
    selectedRestaurantRoom: Room?,
    windowSizeMode: WindowSizeModeEnum,
    isExpandAll: Boolean,
    onRoomClick: (Room?) -> Unit,
) {

    val isExpanded= remember{ mutableStateOf(false) }
    LaunchedEffect(isExpandAll){
        isExpanded.value=isExpandAll
    }
    rooms.forEachIndexed {index,it->
        RoomItem(
            isExpanded =isExpanded,
            isSelected=it?.id==selectedRestaurantRoom?.id,
            room =it,
            windowSizeMode=windowSizeMode,
            isLastItem = index==rooms.size - 1,
            onRoomClick = {
                onRoomClick(it)
            }
        )
        Separator()
    }



}

@Composable
private fun RoomItem(
    isExpanded: MutableState<Boolean>,
    isSelected: Boolean,
    room: Room?,
    isLastItem: Boolean,
    windowSizeMode: WindowSizeModeEnum,
    onRoomClick: (Room?) -> Unit,
)
{

    val isHovered= remember{ mutableStateOf(false) }


    Row(
        modifier = Modifier
            .position(Position.Relative)
            .rippleEffect {
                onRoomClick(room)
            }
            .cursor(Cursor.Pointer)
            .fillMaxWidth()
            .backgroundColor(
                if (isHovered.value) Theme.GrayAlpha04.rgb
                else Theme.Transparent.rgb
            )
            .onMouseOver {isHovered.value=true}
            .onMouseLeave { isHovered.value=false }
            .padding(
                left = Dimen.largePadding,
                right = Dimen.normalPadding
            )
            .boxShadow(color = Theme.GrayAlpha12.rgb)
            .height(Dimen.checkOutItemHeight),
        verticalAlignment = Alignment.CenterVertically,
    )
    {
        Box(
            modifier = Modifier
                .height(Dimen.checkOutItemHeight)
                .margin(left = Dimen.semiNormalPadding)
        )
        {
            Box(
                modifier = Modifier
                    .align(Alignment.Center)
                    .width(Dimen.checkOutNormalLineWidth)
                    .backgroundColor(Theme.Gray9E.rgb)
                    .height(Dimen.smallThickness)
            )

            Box(
                modifier = Modifier
                    .align(Alignment.TopStart)
                    .height(
                        if(!isLastItem)
                            Dimen.checkOutItemHeight
                        else (Dimen.checkOutItemHeight.value.toInt()/2).px
                    )
                    .backgroundColor(Theme.Gray9E.rgb)
                    .width(Dimen.smallThickness)
            )



        }

        SpanText(
            text = room?.caption?:"",
            modifier = Modifier
                .margin(left = Dimen.semiNormalPadding)
                .fontWeight(FontWeight.Normal)
                .fontFamily(FontFamily.Jakarta)
                .fontSize(
                    getGeneralItemRatio(
                        windowSizeMode=windowSizeMode,
                        size = FontSizes.mediumFontSize,
                    )
                )
                .transitionColor()
                .color(
                    if (isSelected) Theme.Blue.rgb
                    else Theme.Primary.rgb
                ),
        )


        Spacer()


        VectorIcon(
            modifier = Modifier,
            width = Dimen.mediumIconSize,
            height = Dimen.mediumIconSize,
            pathData = getArrowImg(expanded = isExpanded.value),
            color = Theme.Primary.hex
        )





 }
}

@Composable
private fun FloorItem(
    isExpandAll: Boolean,
    windowSizeMode: WindowSizeModeEnum,
    floor: Floor?,
    content: @Composable () -> Unit,
    onFloorClick: () -> Unit,
)
{
    val isHovered= remember{ mutableStateOf(false) }

    val isExpanded= remember{ mutableStateOf(false) }
    LaunchedEffect(isExpandAll){
        isExpanded.value=isExpandAll
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .cursor(Cursor.Pointer)
            .onClick{ event: SyntheticMouseEvent -> event.stopPropagation()

            },
        horizontalAlignment = Alignment.End
    ) {


        Box(
            modifier = Modifier
                .rippleEffect {
                    onFloorClick()
                }
                .fillMaxWidth()
                .backgroundColor(
                    if (isHovered.value) Theme.GrayAlpha04.rgb
                    else Theme.Transparent.rgb
                )
                .onMouseOver {isHovered.value=true}
                .onMouseLeave { isHovered.value=false }
                .padding(
                    left = Dimen.largePadding,
                    right = Dimen.normalPadding
                )
                .boxShadow(color = Theme.GrayAlpha12.rgb)
                .height(Dimen.checkOutItemHeight),
        )
        {
            if (!floor?.rooms.isNullOrEmpty()){
                Box(
                    modifier = Modifier
                        .visibility(
                            if(isExpanded.value) Visibility.Visible
                            else Visibility.Hidden
                        )

                        .align(Alignment.BottomStart)
                        .margin(
                            left = Dimen.semiNormalPadding,
                        )
                        .height(((Dimen.checkOutItemHeight.value.toInt())/2).px)
                        .backgroundColor(Theme.Gray9E.rgb)
                        .width(Dimen.smallThickness)
                )
                Box(
                    modifier = Modifier
                        .visibility(
                            if(isExpanded.value) Visibility.Visible
                            else Visibility.Hidden
                        )
                        .margin(left = Dimen.semiNormalPadding)
                        .align(Alignment.CenterStart)
                        .height(Dimen.smallThickness)
                        .backgroundColor(Theme.Gray9E.rgb)
                        .width(Dimen.smallPadding)
                )


            }



            SpanText(
                text = "${floor?.typename} ${floor?.number}",
                modifier = Modifier
                    .align(Alignment.CenterStart)
                    .margin(left = Dimen.mediumPadding)
                    .fontWeight(FontWeight.Normal)
                    .fontFamily(FontFamily.Jakarta)
                    .fontSize(
                        getGeneralItemRatio(
                            windowSizeMode=windowSizeMode,
                            size = FontSizes.mediumFontSize,
                        )
                    )
                    .color(Theme.Primary.rgb),
            )




            Spacer()



            VectorIcon(
                modifier = Modifier
                    .onClick {
                        isExpanded.value=!isExpanded.value
                    }
                    .align(Alignment.CenterEnd),
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
                pathData = getArrowImg(expanded = isExpanded.value),
                color = Theme.Primary.hex
            )





        }

        Separator()

        if (isExpanded.value){
            Column(
                modifier = Modifier.fillMaxWidth() ,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                content()
            }
        }


    }


}

fun getArrowImg(expanded:Boolean): String {
    return if (expanded)
        Res.PathIcon.arrowTop
    else Res.PathIcon.arrowBelow

}




