package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.customer_card.CustomerCardHeaderEnum
import org.hs_soft.runmynesto.domain.usecase.customer_card.CreateLoyaltyCardUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.customer_card_tab.CreditClientUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.GetLoyaltyCardsUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.LoyaltyCardDetailUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.customer_card_tab.FilteredTransactionLoyaltyCardUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.customer_card_tab.UpdateLoyaltyCardUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.series.CreateCustomerCardUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.series.ResetRangeLoyaltyCardUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.series.UpdateRangeLoyaltyCardUseCase
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.handleError
import org.hs_soft.runmynesto.domain.util.helper.BaseViewModel
import org.hs_soft.runmynesto.domain.util.withTokenRefresh
import kotlin.coroutines.CoroutineContext

class CustomerCardViewModel(
    private val requestManager: RequestManager,
    private val refreshToken: () -> Unit,
    private val loyaltyCardsUseCase: GetLoyaltyCardsUseCase,
    private val loyaltyCardDetailUseCase: LoyaltyCardDetailUseCase,
    private val creditClientUseCase: CreditClientUseCase,
    private val updateLoyaltyCardUseCase: UpdateLoyaltyCardUseCase,
    private val createCustomerCardUseCase: CreateCustomerCardUseCase,
    private val updateRangeLoyaltyCardUseCase: UpdateRangeLoyaltyCardUseCase,
    private val resetRangeLoyaltyCardUseCase: ResetRangeLoyaltyCardUseCase,
    private val createLoyaltyCardUseCase: CreateLoyaltyCardUseCase,
    private val filteredTransactionLoyaltyCardUseCase: FilteredTransactionLoyaltyCardUseCase,
): BaseViewModel()
{
    private val _state = MutableStateFlow(CustomerCardState())
    val state: StateFlow<CustomerCardState> = _state
    private var currentOffset = 0

    override fun clear() {
        super.clear()
        requestManager.cancelAll()
    }

    fun onEvent(event: CustomerCardEvent)
    {
        when (event) {
            is CustomerCardEvent.SetGridCustomerFilterType->{
                _state.value=_state.value.copy(
                    gridCustomerCardFilterType = event.type,
                )
            }

            is CustomerCardEvent.FilterLoyaltyCards->{
                filterLoyaltyCards(
                    cardId=event.cardId?:"",
                    from=state.value.selectedDaysPeriod?.from?:"",
                    to=state.value.selectedDaysPeriod?.to?:"",
                )
            }

            is CustomerCardEvent.FilterGridCustomerCards->{
                currentOffset=0
                _state.value=_state.value.copy(
                    showGridCustomerFilter = false,
                    loyaltyCards = emptyList()
                )
                getLoyaltyCards(
                    offset = currentOffset,
                    searchValue = state.value.loyaltyCardSearchValue,
                    isActive =state.value.gridCustomerCardFilterType.value
                )
            }

            is CustomerCardEvent.SetGridCustomerFilterVisibility->{
                _state.value=_state.value.copy(
                    showGridCustomerFilter = event.status
                )
            }


            is CustomerCardEvent.ToggleTransactionColumn -> {
                _state.value = _state.value.copy(
                    selectedTransactionColumns = event.columns,
                    showTransactionColumnsDialog = false

                )
            }

            is CustomerCardEvent.OnSelectPeriodDays->{
                _state.value=_state.value.copy(
                    selectedDaysPeriod = event.period
                )
            }

            is CustomerCardEvent.ShowTransactionColumnsDialog->{
                _state.value=_state.value.copy(
                    showTransactionColumnsDialog = true
                )
            }
            is CustomerCardEvent.CloseTransactionColumnsDialog->{
                _state.value=_state.value.copy(
                    showTransactionColumnsDialog = false
                )
            }



            is CustomerCardEvent.GetLoyaltyCards->{
                getLoyaltyCards(
                    offset = currentOffset,
                    searchValue = state.value.loyaltyCardSearchValue,
                    isActive =event.isActive
                )
            }

            is CustomerCardEvent.CreateLoyaltyCard->{
                createLoyaltyCard(
                    validPhoneNumber=event.validPhoneNumber,
                    isVirtual=event.isVirtual,
                    name=event.name,
                    isActive=event.isActive,
                )
            }

            is CustomerCardEvent.ShowAddCustomerCardDialog->{
                _state.value=_state.value.copy(
                    showAddNewCustomerCardDialog = true
                )
            }
            is CustomerCardEvent.CloseAddCustomerCardDialog->{
                _state.value=_state.value.copy(
                    showAddNewCustomerCardDialog = false
                )
            }


            is CustomerCardEvent.ResetRangeLoyaltyCard->{
                resetRangeLoyaltyCard(
                    from=event.from,
                    to=event.to,
                    description= Strings.zeroSetting
                )
            }

            is CustomerCardEvent.UpdateRangeLoyaltyCard->{
                updateRangeLoyaltyCard(
                    from=event.from,
                    to=event.to,
                    balance=event.balance,
                    description=event.description,
                    name = event.name
                )
            }
            is CustomerCardEvent.CreateCustomerCard->{
                createCustomerCard(
                    type=event.type,
                    from=event.from,
                    to=event.to,
                )
            }

            is CustomerCardEvent.UpdateLoyalCards->{
                updateLoyaltyCard(
                    id=state.value.selectedLoyaltyCard?.id?:"",
                    dataTitle = event.dataTitle,
                    dataValue = event.dataValue,
                )
            }
            is CustomerCardEvent.OnSearchInLoyaltyCards->{
                searchInLoyaltyCards(searchValue = event.searchValue)
            }

            is CustomerCardEvent.OnSelectLoyaltyCard->{
                onSelectLoyaltyCard(
                    id = event.id,
                    switchTheSecondPage =event.switchTheSecondPage
                )
            }

            is CustomerCardEvent.OnCustomerTabClick->{
                setCustomerTab(tab=event.tab)
            }
            is CustomerCardEvent.GetCreditClient->{
                getCreditClient()
            }



            is CustomerCardEvent.OnChangePageClick-> {
                _state.value=_state.value.copy(
                    firstPageActive = !state.value.firstPageActive
                )
            }
        }
    }

    private fun filterLoyaltyCards(
        cardId: String,
        from: String,
        to: String
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result = filteredTransactionLoyaltyCardUseCase(
                        cardId=cardId,
                        from=from,
                        to=to
                    )

                    _state.value = _state.value.copy(
                         filteredLoyaltyCardItems = result.data?.cashAssist
                             ?.listLoyaltyCardItems?.items?: listOf()
                    )


                    hideProgress()

                }
            } catch (e: Exception) {
                hideProgress()
                handleError(error = e)
            }
        }

    }

    private fun createLoyaltyCard(
        validPhoneNumber: String,
        isVirtual: Boolean?,
        name: String?,
        isActive: Boolean?
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()

                   createLoyaltyCardUseCase(
                        validPhoneNumber=validPhoneNumber,
                        isVirtual=isVirtual,
                        name=name,
                        isActive=isActive,
                    )
                    _state.value=_state.value.copy(
                        showAddNewCustomerCardDialog = false
                    )
                    currentOffset=0
                    getLoyaltyCards(
                        searchValue = state.value.loyaltyCardSearchValue,
                        offset = currentOffset
                    )

                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }


        }

    }

    private fun resetRangeLoyaltyCard(
        from: Int,
        to: Int,
        description: String
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    resetRangeLoyaltyCardUseCase(
                        from=from,
                        to = to,
                        description = description,
                    )
                    hideProgress()

                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }


        }

    }

    private fun updateRangeLoyaltyCard(
        from: Int,
        to: Int,
        balance: Int?=null,
        description: String?=null,
        name:String?=null,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    updateRangeLoyaltyCardUseCase(
                        from=from,
                        to = to,
                        balance = balance,
                        description = description,
                        name = name
                    )
                    hideProgress()

                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }


        }


    }

    private fun createCustomerCard(
        type: Int,
        from: Int,
        to: Int
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    createCustomerCardUseCase(
                        type=type,
                        from=from,
                        to=to,
                    )
                    hideProgress()

                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }


        }


    }
    private fun updateLoyaltyCard(
        id: String,
        dataTitle:String,
        dataValue:dynamic,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=updateLoyaltyCardUseCase(
                        id=id,
                        dataTitle=dataTitle,
                        dataValue=dataValue,
                    )
                    _state.value=_state.value.copy(
                        selectedLoyaltyCard =  result.data?.cashAssist?.updatedLoyaltyCard
                    )
                    hideProgress()

                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }


        }

    }

    private fun getCreditClient() {
        if (state.value.creditClient==null){
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()
                        val result=creditClientUseCase()
                        _state.value=_state.value.copy(
                            creditClient =  result
                        )
                        hideProgress()

                    }


                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }


            }
        }


    }

    private fun setCustomerTab(tab: CustomerCardHeaderEnum) {
        _state.value=_state.value.copy(selectedCustomerCardTab = tab)
    }


    private fun <T> debounce(
        waitMs: Long = Constants.SearchRequestDelay,
        coroutineContext: CoroutineContext = Dispatchers.Main,
        action: (T) -> Unit
    ): (T) -> Unit {
        var debounceJob: Job? = null

        return { param: T ->
            debounceJob?.cancel()
            debounceJob = CoroutineScope(coroutineContext).launch {
                delay(waitMs)
                action(param)
            }
        }
    }


    private val searchDebounce = debounce<String>(Constants.SearchRequestDelay) { searchValue ->
        getLoyaltyCards(
            searchValue=searchValue,
            offset =currentOffset
        )
    }

    private fun searchInLoyaltyCards(searchValue: String) {
        currentOffset=0
        _state.value=_state.value.copy(
            loyaltyCards = listOf(),
            loyaltyCardSearchValue=searchValue,
        )
        searchDebounce(searchValue)

    }


    private fun getLoyaltyCards(
        searchValue:String,
        offset: Int,
        isActive: Boolean?=null,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result = loyaltyCardsUseCase(
                        searchValue = searchValue,
                        offset = offset,
                        isActive=isActive,
                    )
                    val newItems = result.data?.cashAssist?.
                        listLoyaltyCards?.loyaltyCardItems ?: listOf()

                    _state.value = _state.value.copy(
                        loyaltyCards = (_state.value.loyaltyCards ?: emptyList())
                            .toMutableList().apply {
                            addAll(newItems)
                        }
                    )

                    currentOffset += newItems.size
                    if (state.value.selectedLoyaltyCard == null
                        && !state.value.loyaltyCards.isNullOrEmpty()) {
                        onSelectLoyaltyCard(id = state.value.loyaltyCards?.get(0)?.id)
                    } else {
                        hideProgress()
                    }
                }
            } catch (e: Exception) {
                hideProgress()
                handleError(error = e)
            }
        }
    }

    private fun onSelectLoyaltyCard(
        id: String?,
        switchTheSecondPage:() -> Unit ={},
    ) {
        if (id!=state.value.selectedLoyaltyCard?.id)
        {
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()
                        val result=loyaltyCardDetailUseCase(id=id?:"")
                        _state.value=_state.value.copy(
                            selectedLoyaltyCard = result.data?.cashAssist?.getLoyaltyCard
                        )

                        switchTheSecondPage()
                        hideProgress()
                    }


                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }


            }

        }else{
            switchTheSecondPage()
        }



    }

    private fun showProgress()
    {
        _state.value=_state.value.copy(
            dataLoadingProgressVisible = true
        )
    }
    private fun hideProgress()
    {
        _state.value=_state.value.copy(
            dataLoadingProgressVisible = false
        )
    }

}


