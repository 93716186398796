package org.hs_soft.runmynesto.pages.components.top_toolbar.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.delay
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.MenuVectorIcon
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.px

@Composable
fun TopToolbarPageInfo(
    iconPath: String,
    title: String,
    windowSizeMode: WindowSizeModeEnum,
    showSearchBarInMobileMode: Boolean
) {

    var showRow by remember { mutableStateOf(!showSearchBarInMobileMode) }

    LaunchedEffect(showSearchBarInMobileMode) {
        if (showSearchBarInMobileMode) {
            showRow = false
        } else {
            delay((Constants.fastExpandingTime * 1000).toLong())
            showRow = true
        }
    }

    if( windowSizeMode==WindowSizeModeEnum.TABLET || showRow ){
        Row(
            modifier = Modifier
                .height(32.px)
                .thenIf(
                    windowSizeMode==WindowSizeModeEnum.PHONE,
                    Modifier
                        .margin(left = Dimen.extraExtraLargePadding)
                        .attrsModifier {
                            style {
                                property("transition",
                                    "all ${Constants.fastExpandingTime}s ease-in-out")
                                property("opacity",
                                    if (!showSearchBarInMobileMode) "1" else "0")
                                property("display",
                                    if (!showSearchBarInMobileMode) "flex" else "none")

                            }
                        }
                )

                .padding(left = Dimen.mediumPadding),
            verticalAlignment = Alignment.CenterVertically,
        )
        {

            VectorIcon(
                modifier = Modifier
                    .margin(
                        right = Dimen.normalPadding,
                    ),
                pathData = iconPath,
                color = Theme.Blue.hex,
                width = Dimen.normalIconSize,
                height = Dimen.normalIconSize,
            )


            SpanText(
                text = title,
                modifier = Modifier
                    .height(32.px)
                    .textAlign(TextAlign.Center)
                    .fontSize(FontSizes.semiExtraLargeFontSize  )
                    .fontWeight(FontWeight.Medium)
//                    .lineHeight(FontLineHeight.lineLarge)
                    .letterSpacing(LetterSpacing.large)
                    .color(Theme.Gray3E.rgb)
                    .fontFamily(FontFamily.Jakarta)
            )
        }
    }

}
