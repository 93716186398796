package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.google

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config.GetVirtualCardConfig
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.CardHeader
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.CardInfo
import org.jetbrains.compose.web.css.percent

@Composable
fun GoogleCardInfoPreview(
    info: GetVirtualCardConfig?,
)
{
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(Dimen.mediumPadding),
    )
    {
        Image(
            modifier = Modifier
                .borderRadius(r=50.percent),
            src =info?.logoImg?: Res.PathIcon.pickImgCircleSmall,
            width = Dimen.extraLargeIconSize ,
            height =  Dimen.extraLargeIconSize,
        )

        CardHeader(
            modifier = Modifier.margin(top = Dimen.mediumPadding),
            title= Strings.customerCard,
        )
        CardInfo(
            title = Strings.cardName,
            value = Strings.customerCard,
        )
        CardInfo(
            title = Strings.description,
            value = info?.accountIdLabel?:"",
        )
        CardInfo(
            title = Strings.discountTier ,
            value = info?.rewardsTier?:"",
        )
        CardInfo(
            title = Strings.specialDiscountTier ,
            value = info?.secondaryRewardsTier?:"",
        )
        CardInfo(
            title = Strings.balance,
            value = "0000.0",
        )

        CardInfo(
            title = Strings.cashAssistCard,
            value = "0",
        )

        InfoWithIcon(
            icon=Res.PathIcon.phone,
            value=info?.linkPhoneUri?.description?:"",
        )


        InfoWithIcon(
            icon=Res.PathIcon.net,
            value= info?.homepageUri?.description?:"",
        )

    }


}

@Composable
private fun InfoWithIcon(
    modifier: Modifier=Modifier,
    value:String,
    icon:String,
) {
    Row(
        modifier = modifier.margin(top = Dimen.mediumPadding),
        verticalAlignment = Alignment.CenterVertically
    ) {
        VectorIcon(
            modifier = Modifier
                .margin(right = Dimen.semiNormalPadding),
            pathData = icon,
            color = Theme.Gray3E.hex,
            width = Dimen.normalIconSize,
            height = Dimen.normalIconSize,

        )


        SpanText(
            text = value,
            modifier = Modifier
                .fontFamily(FontFamily.Jakarta)
                .color(Theme.Secondary.rgb)
                .fontWeight(FontWeight.Normal)
                .fontSize(FontSizes.semiMediumFontSize)
        )
    }
}