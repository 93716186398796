package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_options


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PriceLevelModel(
    @SerialName("data")
    val data: Data
)