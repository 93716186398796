package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onTouchEnd
import com.varabyte.kobweb.compose.ui.modifiers.onTouchMove
import com.varabyte.kobweb.compose.ui.modifiers.onTouchStart
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.single_sale_group.GetSalesGroup
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group.SaleItem
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.getGridWidth
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.grid_function.GridFunction
import org.hs_soft.runmynesto.pages.components.grid_function.GridItemsList
import org.w3c.dom.get

@Composable
fun GridOptionBar(
    searchValue: String,
    windowSizeMode: WindowSizeModeEnum,
    infoList: List<SaleItem>,
    titleDescending: Boolean,
    numberDescending: Boolean,
    showNumberSortIcon: Boolean,
    showTitleSortIcon: Boolean,
    selectedItem: GetSalesGroup?,
    onSearchValueChange: (String) -> Unit,
    onTitleSortClick: () -> Unit,
    onNumberSortClick: () -> Unit,
    onSaleItemClick: (String) -> Unit,
    onAddRecord: () -> Unit,
    refreshList:()->Unit,
    onSwipeRight:()->Unit,
) {
    var startX by remember { mutableStateOf(0.0) }
    var endX by remember { mutableStateOf(0.0) }
    var tracking by remember { mutableStateOf(false) }
    Column(
        modifier = Modifier
            .fillMaxHeight()
            .then(
                if (windowSizeMode== WindowSizeModeEnum.PHONE){
                    Modifier.onTouchStart { event ->
                        startX = event.touches[0]!!.clientX.toDouble()
                        endX = event.touches[0]!!.clientX.toDouble()
                        tracking = true
                    }
                        .onTouchMove { event ->
                            if (tracking) {
                                endX = event.touches[0]!!.clientX.toDouble()
                            }
                        }
                        .onTouchEnd {
                            if (tracking && endX + Constants.Responsive.SwipeWidth < startX) {
//                                onSwipeRight()
                            }
                            tracking = false
                        }
                }else Modifier
            )
    )
    {

        Column(
            modifier = Modifier
                .weight(1f)
        ){

            CustomEditText(
                width = getGridWidth(windowSizeMode),
                label = "",
                value = searchValue,
                endIconPath = Res.PathIcon.searchBox,
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                hint = Strings.search,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    onSearchValueChange(it)
                },
            )

            GridFunction(
                modifier = Modifier,
                width =  getGridWidth(windowSizeMode),
                title = Strings.name,
                contentHeight = Constants.Responsive.templateGridContentHeight,
                infoTitle = Strings.number,
                footerTitle = Strings.items,
                showAddItemIcon = true,
                infoList = infoList,
                titleDescending = titleDescending ,
                numberDescending = numberDescending,
                showTitleSortIcon = showTitleSortIcon,
                showNumberSortIcon = showNumberSortIcon,
                onTitleSortClick = {
                    onTitleSortClick()
                },
                onNumberSortClick = {
                    onNumberSortClick()
                },
                onAddItemClick = {
                     onAddRecord()
                },
                refreshList = {
                    refreshList()
                },
                content = {
                    GridItemsList(
                        id = selectedItem?.id?:"",
                        items = infoList,
                        onItemClick = {
                            onSaleItemClick(it)
                        },
                    )
                },


            )
        }






    }
}