package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart.component.chart.component

import Chart
import androidx.compose.runtime.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.css.*
import kotlinx.browser.document
import kotlinx.browser.window
import org.hs_soft.runmynesto.React
import org.hs_soft.runmynesto.ReactDom
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.jsObject
import org.w3c.dom.HTMLElement
import kotlin.js.json

const val FIRST_CHART="firstChart"
const val SECOND_CHART="secondChart"

@Composable
fun AreaChart(
    windowSizeMode:WindowSizeModeEnum,
    id: String,
    firstFillColor: String,
    firstStrokeColor: String,
    secondFillColor: String,
    secondStrokeColor: String,
    mainData: List<Double?>?,
    comparedData: List<Double?>?,
    interval: List<String?>?,
) {

    Div(attrs = {
        id(id)
        style {
            width(
                if(windowSizeMode==WindowSizeModeEnum.PHONE)
                    window.innerWidth.px else
                Dimen.chartWidth.px
            )
            height(Dimen.chartHeight.px)
        }
    }) {
        DisposableEffect(mainData,comparedData,interval) {
            val container = document.getElementById(id) as HTMLElement
            createChart(
                mainData=mainData,
                comparedData=comparedData,
                interval=interval,
                container=container,
                firstFillColor = firstFillColor,
                firstStrokeColor = firstStrokeColor,
                secondFillColor = secondFillColor,
                secondStrokeColor = secondStrokeColor,
            )
            onDispose { }
        }
    }
}



private fun createChart(
    container: HTMLElement,
    firstFillColor: String,
    firstStrokeColor: String,
    secondFillColor: String,
    secondStrokeColor: String,
    mainData: List<Double?>?,
    comparedData: List<Double?>?,
    interval: List<String?>?
) {
    val jsonList = mainData?.mapIndexed { index, value ->
        val intervalLabel = interval?.getOrNull(index)
            ?.split("-")?.firstOrNull() ?: "N/A"
        json(
            "name" to intervalLabel,
            FIRST_CHART to (value ?: 0.0),
            SECOND_CHART to (comparedData?.getOrNull(index) ?: 0.0),
            "amt" to 2400
        )
    }?.toTypedArray() ?: emptyArray()

    renderChart(
        container = container,
        data = jsonList,
        firstFillColor = firstFillColor,
        firstStrokeColor = firstStrokeColor,
        secondFillColor =secondFillColor ,
        secondStrokeColor =secondStrokeColor ,

    )
}

@NoLiveLiterals
private fun renderChart(
    container: HTMLElement,
    data: Array<dynamic>,
    firstFillColor:String,
    firstStrokeColor:String,
    secondFillColor:String,
    secondStrokeColor:String,
) {


    val root = ReactDom.createRoot(container)

    root.render(
        React.createElement(Chart.ResponsiveContainer, jsObject {
            width =Dimen.chartWidth
            height = Dimen.chartHeight
        },
            React.createElement(Chart.AreaChart, jsObject {
                    this.data = data
                    margin = jsObject {
                        top = 5
                        right = 30
                        left = 20
                        bottom = 5
                    }
                },
                React.createElement(Chart.CartesianGrid, jsObject {
                    strokeDasharray = "0 0"
                }),
                React.createElement(Chart.XAxis, jsObject {
                    dataKey = "name"
                }),
                React.createElement(Chart.YAxis, jsObject {}),
                React.createElement(Chart.Tooltip, null),
//                React.createElement(Chart.Legend, null),//Showing title beneath the chart
                React.createElement(Chart.Area, jsObject {
                    type = "monotone"
                    dataKey = FIRST_CHART
                    stroke = firstStrokeColor
                    fill = firstFillColor
                }),
                React.createElement(Chart.Area, jsObject {
                    type = "monotone"
                    dataKey = SECOND_CHART
                    stroke = secondStrokeColor
                    fill = secondFillColor
                })
            )
        )
    )
}
