package org.hs_soft.runmynesto.domain.model.home.options.cash_flow

import org.hs_soft.runmynesto.domain.config.Strings

enum class IncomeExpansesInfoEnum(
    val titleShown: String,
    val title: String,
) {
    INC_EXPANSES_1(
        titleShown = Strings.incomeExpanses1,
        title = ""
    ),
    INC_EXPANSES_2(
        titleShown = Strings.incomeExpanses2,
        title = ""
    ),
    INC_EXPANSES_3(
        titleShown = Strings.incomeExpanses3,
        title = ""
    ),
    INC_EXPANSES_4(
        titleShown = Strings.incomeExpanses4,
        title = ""
    ),
    INC_EXPANSES_5(
        titleShown = Strings.incomeExpanses5,
        title = ""
    ),


}