package org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ListShops(
    @SerialName("items")
    val items: List<ListShopItem?>?,
    @SerialName("__typename")
    val typename: String?
)