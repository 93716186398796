package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_restaurant

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.kitchen_info.KitchenInfoModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.KitchenInfoRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class KitchenInfoRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): KitchenInfoRepository {


    override suspend fun kitchenInfo(): KitchenInfoModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            query listAdditions {
              cashAssist {
                listProductAdditions {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.name}
                  ${ApiUtils.Field.variants} 
                } 
              }
            }
        """.trimIndent())

            putJsonObject("variables") {}
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<KitchenInfoModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }

}
