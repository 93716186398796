package org.hs_soft.runmynesto.data.api_datasource.authentication

import androidx.compose.runtime.NoLiveLiterals
import kotlinx.browser.document
import org.hs_soft.runmynesto.domain.repository.network_datasource.home.FetchIdTokenRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.modules.Auth
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.coroutines.suspendCoroutine
import kotlin.js.Date


class FetchIdTokenRepositoryImpl : FetchIdTokenRepository {

    @NoLiveLiterals
    override suspend fun getIdToken(): String = suspendCoroutine { continuation ->

        Auth.getCurrentUser().then { result->
            val idToken = getCookie(name = constructCookieName(result.username))

            console.log(result.username)

            if (idToken != null && !isTokenExpired(idToken)) {
                continuation.resume(idToken)
            } else {
                Auth.fetchAuthSession().then { result2 ->
                    continuation.resume(result2.tokens.idToken)

                }.catch { error: dynamic ->
                    console.log("Fetch AuthData Error: ${error}")
                    continuation.resumeWithException(
                        Exception("Failed to get AuthData: $error")
                    )
                }

            }

        }.catch { error->
            console.log(error)
        }

//
    }

    private fun getCookie(name: String): String? {
        val cookies = document.cookie.split("; ")
        for (cookie in cookies) {
            val parts = cookie.split("=")
            if (parts[0] == name) {
                return parts[1]
            }
        }
        return null
    }

    private fun isTokenExpired(token: String): Boolean {
        val parts = token.split(".")
        if (parts.size < 3) return true
        val payload = JSON.parse<dynamic>(js("atob")(parts[1]))
        val exp = payload.exp as? Int ?: return true
        val now = (Date().getTime() / 1000).toInt()
        return now >= exp
    }

}




fun constructCookieName(userName:String): String {
    return "CognitoIdentityServiceProvider.${ApiUtils.Cognito.USER_POOL_CLIENT_ID}" +
            ".$userName.idToken"
}


//class FetchIdTokenRepositoryImpl:FetchIdTokenRepository{
//
//    @NoLiveLiterals
//    override suspend fun getIdToken(): String =
//        suspendCancellableCoroutine { continuation ->
//
//
//            Auth.fetchAuthSession().then { result ->
//                console.log(result.tokens.idToken)
//                continuation.resume(result.tokens.idToken)
//
//            }.catch { error: dynamic ->
//                console.log("Fetch AuthData Error: ${error}")
//                continuation.resumeWithException(
//                    Exception("Failed to get AuthData: $error")
//                )
//            }
//        }
//
//}
//