package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SaleItem(
    @SerialName("acountNum")
    val acountNum: String?,
    @SerialName("clientDiscount")
    val clientDiscount: List<ClientDiscount?>?,
    @SerialName("discount")
    val discount: List<Int?>?,
    @SerialName("id")
    val id: String,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("name")
    val name: String,
    @SerialName("__typename")
    val typename: String?
)