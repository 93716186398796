package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Printer(
    @SerialName("autoPrintBills")
    val autoPrintBills: Boolean?,
    @SerialName("automaticLabelPrint")
    val automaticLabelPrint: Boolean?,
    @SerialName("connectionType")
    val connectionType: String?,
    @SerialName("defaultLabelID")
    val defaultLabelID: Int?,
    @SerialName("ip")
    val ip: String?,
    @SerialName("isKitchenPrinterActive")
    val isKitchenPrinterActive: Boolean?,
    @SerialName("isPrintEFTReceipts")
    val isPrintEFTReceipts: Boolean?,
    @SerialName("isPrintKitchenBillAfterCompletionOnly")
    val isPrintKitchenBillAfterCompletionOnly: Boolean?,
    @SerialName("isPrintKitchenBillForTableOnly")
    val isPrintKitchenBillForTableOnly: Boolean?,
    @SerialName("labelTitle")
    val labelTitle: String?,
    @SerialName("locations")
    val locations: List<Location?>?,
    @SerialName("name")
    val name: String?,
    @SerialName("notPrintRFIDBills")
    val notPrintRFIDBills: Boolean?,
    @SerialName("printBillFromAmount")
    val printBillFromAmount: Int?,
    @SerialName("printCartQR")
    val printCartQR: Boolean?,
    @SerialName("separateKitchenBill")
    val separateKitchenBill: Boolean?,
    @SerialName("topMargin")
    val topMargin: Int?,
    @SerialName("__typename")
    val typename: String?
)