package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.ObjectKindTypes
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.ObjectKinds
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.backgroundPosition
import org.jetbrains.compose.web.css.backgroundRepeat
import org.jetbrains.compose.web.css.backgroundSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

@Composable
fun InteriorDecor(
    onSubmitClick:(ObjectKinds)->Unit,
) {

    val selectedDecor= remember { mutableStateOf<ObjectKinds?>(null) }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .borderSetting()
            .margin(top = Dimen.normalPadding)
    )
    {
        SpanText(
            text = Strings.interiorDecor,
            modifier = Modifier
                .minHeight(Dimen.minimumContentTitleHeight)
                .margin(left = Dimen.normalPadding)
                .fontWeight(FontWeight.Normal)
                .lineHeight(FontLineHeight.lineLarge)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Jakarta)
        )

        ObjectKinds.entries.filter {
            it.type==ObjectKindTypes.INTERIOR_DECOR
        }.chunked(4).forEach {row ->
            Row()
            {
                row.forEach {
                    DecorItem(
                        decor=it,
                        selectedDecor=selectedDecor.value,
                        onDecorClick = {
                            selectedDecor.value = it
                        }
                    )

                }
            }

        }

        CustomButton(
            modifier = Modifier,
            topBottomMargin = Dimen.normalPadding,
            title = Strings.add,
            leftRightMargin = 0.px,
            isClickable = selectedDecor.value!=null,
            defaultTextColor = Theme.White.rgb,
            onButtonClick = {
                selectedDecor.value?.let {
                    onSubmitClick(it)
                }
            },
        )


    }


}

@Composable
private fun DecorItem(
    selectedDecor: ObjectKinds?,
    decor: ObjectKinds,
    onDecorClick: (ObjectKinds)->Unit,
)
{
    Box(
        modifier = Modifier
            .generalDialogSetting()
            .rippleEffect {
                onDecorClick(decor)
            }
            .backgroundColor(
                if (selectedDecor==decor) Theme.YellowAlpha20.rgb
                else Theme.Transparent.rgb
            )
            .margin(Dimen.smallPadding)
            .size(Dimen.restaurantDecorSize)
    ){
        if (decor.img!=null){
            Div(
                attrs = {
                    style {
                        position(Position.Absolute)
                        backgroundImage(decor.img)
                        width(Dimen.restaurantDecorImgSize)
                        height(Dimen.restaurantDecorImgSize)
                        margin(Dimen.extraLargePadding)
                        backgroundSize("contain")
                        backgroundRepeat("no-repeat")
                        backgroundPosition("center")
                    }
                }
            )
        }




//        Image(
//            src = Res.PathIcon.plant1,
//            modifier = Modifier
//                .align(Alignment.Center),
//            width = Dimen.largeIconSize,
//            height = Dimen.largeIconSize,
//        )


    }
}
