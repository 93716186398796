package org.hs_soft.runmynesto.domain.config

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseDown
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.onMouseUp
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import kotlinx.browser.document
import kotlinx.browser.window
import org.hs_soft.runmynesto.domain.util.Constants
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.w3c.dom.HTMLElement


fun Modifier.rippleEffect(
    isClickable:Boolean=true,
    onClick: (SyntheticMouseEvent) -> Unit
): Modifier = this.then(
    Modifier
        .attrsModifier {
        onClick { event ->
            if (isClickable){
                val target = event.currentTarget as? HTMLElement ?: return@onClick
                val rect = target.getBoundingClientRect()
                val ripple = document.createElement("div") as HTMLElement

                ripple.style.apply {
                    position = "absolute"
                    borderRadius = "inherit"
                    background = Theme.GrayCD.rgb.toString()
                    width = "${rect.width}px"
                    height = "${rect.height}px"
                    left = "0"
                    top = "0"
                    transform = "scale(0)"
                    transition = "transform ${Constants.mediumTransition}, " +
                            "opacity ${Constants.mediumTransition}"
                    zIndex = "1"
                }

                target.style.position = "relative"
                target.style.overflowY = "hidden"
                target.style.overflowX = "hidden"

                target.appendChild(ripple)

                // Animate the ripple
                window.setTimeout({
                    ripple.style.transform = "scale(1)"
                    ripple.style.opacity = "0"
                }, 0)

                // Remove the ripple after animation
                window.setTimeout({
                    target.removeChild(ripple)
                }, Constants.expandingTimeOut)

                onClick(event)
            }

        }
    }
)

fun Modifier.overflowText():Modifier{
    return this.then(
        Modifier
            .styleModifier {
                property("white-space", "nowrap")
                property("overflow", "hidden")
                property("text-overflow", "ellipsis")

//                property("display", "block")
//                property("max-height", "1.2em")
//                property("line-height", "1.2em")
//                property("word-break", "break-all")
        }

    )
}


fun Modifier.scrollBar():Modifier{
   return this.then(
           Modifier.attrsModifier {
           style {
               property("scrollbar-width", "auto")
               property("scrollbar-color",
                   "${Theme.GrayCD.rgb} transparent")
           }
       }
   )
}

@Composable
fun Modifier.circleHoverAroundItem(
    padding: CSSSizeValue<CSSUnit.px> =Dimen.normalPadding,
    showHover:Boolean=true,
    showMouseDownHover:Boolean=true,
    onClick:()->Unit,
):Modifier{

    val isHovered= remember { mutableStateOf(false) }
    val isMouseDown= remember { mutableStateOf(false) }

    return this.then(
        Modifier
            .padding(padding)
            .transitionBackground()
            .borderRadius(50.percent)
            .onMouseOver {
                isHovered.value = true
            }
            .onMouseLeave {
                isHovered.value = false
            }
            .onMouseDown {
                isMouseDown.value=true
            }
            .onMouseUp {
                isMouseDown.value=false
            }
            .backgroundColor(
                if(isMouseDown.value && showMouseDownHover) Theme.GrayAlpha12.rgb
                else if (isHovered.value && showHover) Theme.GrayAlpha04.rgb
                else Theme.Transparent.rgb
            )
            .onClick {
                event: SyntheticMouseEvent -> event.stopPropagation()
                onClick()
            }
    )

}


fun Modifier.transitionBackground(): Modifier {
    return this.then(
        Modifier.transition(
            Transition.of(
                property = "background-color",
                duration = Constants.fastTransition
            )
        )
    )
}
fun Modifier.transitionColor(): Modifier {
    return this.then(
        Modifier.transition(
            Transition.of(
                property = "color",
                duration = Constants.fastTransition
            )
        )
    )
}

fun Modifier.generalDialogSetting(): Modifier {
    return this.then(
        Modifier
            .backgroundColor(Theme.White.rgb)
            .zIndex(Constants.ZIndex.smallZIndex)
            .boxShadow(
                color = Theme.GrayAlpha12.rgb,
                blurRadius = Dimen.normalBlurRadius,
                spreadRadius = Dimen.normalSpreadRadius
            )
            .borderRadius(r= Dimen.lowBorderRadius)
            .onClick {
                event: SyntheticMouseEvent -> event.stopPropagation()
            },
    )
}


fun Modifier.borderSetting(): Modifier {
    return this.then(
        Modifier
            .padding(Dimen.largePadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    )
}

fun Modifier.transitionPageBackground(isVisible:Boolean):Modifier{
    return this.then(
        Modifier
            .attrsModifier {
                style {
                    property("transition",
                        "opacity ${Constants.fastTransition} ease-in-out")
                    property("opacity", if (isVisible) "1" else "0")
                }
            }
    )
}

fun Modifier.transitionBorder(): Modifier {
    return this.then(
        Modifier.transition(
            Transition.of(
                property = "border",
                duration = Constants.fastTransition,
            )
        )
    )
}

val hoverBackgroundStyle by ComponentStyle {
    base {
        Modifier.transition(
            Transition.of(
                property = "background",
                duration = Constants.fastTransition
            )
        )
    }
}

val VerticalScrollBehavior by ComponentStyle {
    base {
        Modifier
            .styleModifier {
                property("overflow-y", "auto")
                property("overflow-x", "hidden")
                property("scrollbar-width", "none")
                property("::-webkit-scrollbar", "width: 0px;")
                property("::-webkit-scrollbar-thumb", "background-color: darkgrey;")
                property("::-webkit-scrollbar-track", "background: lightgrey;")
            }

    }

}


val HorizontalScrollBehavior by ComponentStyle {
    base {
        Modifier
            .styleModifier {
                property("overflow-y", "hidden")
                property("overflow-x", "auto")
                property("scrollbar-width", "none")
                property("::-webkit-scrollbar", "width: 0px;")
                property("::-webkit-scrollbar-thumb", "background-color: darkgrey;")
                property("::-webkit-scrollbar-track", "background: lightgrey;")
            }

    }

}
const val gradientStyle = "linear-gradient(to right, red, orange, yellow, green, cyan, blue, violet)"
