package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.bill_search

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.bill_search.components.BillSearchComponent
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.bill_search.components.BillSearchSetting
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi.StatisticEvent
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi.StatisticState

@Composable
fun BillSearch(
    windowSizeMode: WindowSizeModeEnum,
    state: StatisticState,
    event:(StatisticEvent)->Unit,
) {

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .borderSetting()
    )
    {
        BillSearchSetting(
            windowSizeMode=windowSizeMode,
            event = event,
            selectedPeriodType = state.selectedPeriodType,
            showDaysFilterDialog = state.showDaysFilterDialog,
            showCalendarFilterDialog = state.showCalendarFilterDialog,
            selectedDate = state.selectedDate,
            selectedFrom = state.selectedFromDate ?: "",
            selectedTo = state.selectedToDate ?: "",
            selectedCalendarHeaderYear = state.selectedCalendarHeaderYear,
        )

        BillSearchComponent(
            windowSizeMode=windowSizeMode,
            statisticData = state.billSearchStatisticData,
            selectedBillSearchStatisticItem = state.selectedBillSearchStatisticItem,
            onSelectStatisticItem = {
                event(StatisticEvent.OnSelectBillSearchStatisticItem(it))
            },
            searchBillSearchItems = {
                event(StatisticEvent.OnSearchInBillSearchItems(it))
            },
            onSearchInSelectedBillSearchItem={
                event(StatisticEvent.OnSearchInSelectedBillSearchItem(it))
            },
            getBillPdf = {number:String,dateTime:String,cashRegisterId:String->
                event(
                    StatisticEvent.BillPdf(
                        billNumber =number.toInt(),
                        billDate=dateTime,
                        cashRegisterId=cashRegisterId
                    )
                )
            },
            exportStatisticReports = {
                event(
                    StatisticEvent.ExportExcelFile(
                        data = it?: listOf()
                    )
                )
            }
        )

    }

}

