package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.restaurant_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location.PrinterLocation
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.restaurant_data.GetRestaurant
import org.hs_soft.runmynesto.domain.util.getGeneralItemRatio
import org.hs_soft.runmynesto.pages.components.CheckboxComponent

@Composable
fun KitchenLocations(
    printerLocation: List<PrinterLocation?>?,
    restaurantData: GetRestaurant?,
    printerLocationArray: ArrayList<Boolean?>?,
    updatePrinterLocation: (List<Boolean?>?) -> Unit,
    windowSizeMode: WindowSizeModeEnum
) {
    SpanText(
        text = Strings.kitchenLocations,
        modifier = Modifier
            .color(Theme.Gray3E.rgb)
            .fontFamily(FontFamily.Jakarta)
            .fontWeight(FontWeight.Normal)
            .fontSize(FontSizes.extraExtraLargeFontSize)
            .margin(top = Dimen.mediumPadding)
    )

    Column(
        modifier = Modifier
            .margin(left = Dimen.normalPadding)
    ){
        printerLocation?.forEach {
            if (it != null) {
                CheckboxComponent(
                    modifier = Modifier.margin(top = Dimen.smallPadding),
                    checkStatus = restaurantData?.printerLocations?.get(it.dbIndex) ?: false,
                    title = it.value,
                    fontSize = getGeneralItemRatio(
                        windowSizeMode = windowSizeMode,
                        size = FontSizes.mediumFontSize,
                    ),
                    onChecked = {
                        printerLocationArray?.set(it.dbIndex, true)
                        updatePrinterLocation(printerLocationArray)
                    },
                    onUnChecked = {
                        printerLocationArray?.set(it.dbIndex, false)
                        updatePrinterLocation(printerLocationArray)
                    },
                )
            }
        }
    }
}