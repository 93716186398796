package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import kotlinx.browser.window
import org.hs_soft.runmynesto.domain.model.WindowSizeModel

@Composable
fun RememberWindowSize(): WindowSizeModel {
    val width = window.innerWidth
    val height = window.innerHeight
    return remember { WindowSizeModel(width, height) }
}