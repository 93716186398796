package org.hs_soft.runmynesto.di.dashboard

import org.hs_soft.runmynesto.domain.usecase.dashboard.CustomerFrequencyUseCase
import org.hs_soft.runmynesto.domain.usecase.dashboard.DashboardConfigUseCase
import org.hs_soft.runmynesto.domain.usecase.dashboard.InitShopStaticsUseCase
import org.hs_soft.runmynesto.domain.usecase.dashboard.ShopStaticsUseCase
import org.koin.dsl.module

val dashBoardUseCaseModule= module{

    single {
        InitShopStaticsUseCase(get())
    }
    single {
        ShopStaticsUseCase(get())
    }
    single {
        DashboardConfigUseCase(get())
    }
    single {
        CustomerFrequencyUseCase(get())
    }


}