package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.update_ingredient


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("updateProduct")
    val updateProduct: UpdateProduct?
)