package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.abc_statistic.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenXXX
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.getCheckoutWidth
import org.hs_soft.runmynesto.pages.components.checkout.CheckoutTabContent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh

@Composable
fun CheckoutDialog(
    windowSizeMode: WindowSizeModeEnum,
    cashList:List<Children>,
    isDevicesExpandAll:Boolean,
    cashRegisters:List<ChildrenXXX>,
    onBackgroundClick:()->Unit,
    updateSelectedCheckout:(List<String>)->Unit,
    onSearchCheckoutItems:(String)->Unit,

    ) {
    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                onBackgroundClick()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(Constants.ZIndex.normalZIndex)
    ){
        Box(
            modifier = Modifier
                .align(Alignment.Center)
                .generalDialogSetting()
        ){

            CheckoutTabContent(
                modifier = Modifier,
                fillMaxWidth = true,
                windowSizeMode = windowSizeMode,
                marginBottom = if (windowSizeMode== WindowSizeModeEnum.WINDOWS)
                    Dimen.normalPadding
                else 0.px,
                cashList = cashList,
                width =when(windowSizeMode){
                    WindowSizeModeEnum.TABLET -> Constants.Responsive.ClosingReportsCheckoutTablet.px
                    WindowSizeModeEnum.WINDOWS -> getCheckoutWidth(windowSizeMode)
                    WindowSizeModeEnum.PHONE -> Constants.Responsive.ClosingReportsTablePhone.px
                } ,
                height = Constants.Responsive.closingReportsCheckoutHeight.vh,
                borderLess = true,
                cashRegisterIds = cashRegisters.mapNotNull { it.id } ,
                updateCheckout = {
                    updateSelectedCheckout(it.toMutableList())

                },
                isExpandAll = isDevicesExpandAll,
                searchValue = "",// checkoutSearchValue,
                onSearchValueChange = {
                    onSearchCheckoutItems(it)

                },

                )

        }

    }




}
