package org.hs_soft.runmynesto.data.api_datasource.user

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.CheckoutModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.CheckoutDataRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class CheckoutDataRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): CheckoutDataRepository {


    override suspend fun getCheckout(): CheckoutModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """
                    query initialize {
                      cashAssist {
                        ...clientTree
                      }
                    }

            fragment clientTree on CA_Query {
                      children: clientTree {
                        ${ApiUtils.Field.id}
                        ${ApiUtils.Field.mod_ver}
                        ${ApiUtils.Field.name}
                        children: regions {
                          ${ApiUtils.Field.id}
                          ${ApiUtils.Field.mod_ver}
                          ${ApiUtils.Field.name}
                          children: shops {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.mod_ver}
                            ${ApiUtils.Field.name}
                            children: cashRegisters {
                                ${ApiUtils.Field.id}
                                ${ApiUtils.Field.mod_ver}
                                ${ApiUtils.Field.name}
                                ${ApiUtils.Field.hardwareId}
                                ${ApiUtils.Field.deviceType}
                                ${ApiUtils.Field.tseRemoteCashRegisterId}
                                ${ApiUtils.Field.__typename} 
                            }
                             ${ApiUtils.Field.__typename} 
                          }
                           ${ApiUtils.Field.__typename} 
                        }
                         ${ApiUtils.Field.__typename} 
                      }
                       ${ApiUtils.Field.__typename} 
                    }
                """.trimIndent()
            )


        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<CheckoutModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }

}

