package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_surcharge


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateSurchargeModel(
    @SerialName("data")
    val  data : Data
)