package org.hs_soft.runmynesto.domain.usecase.user

import org.hs_soft.runmynesto.domain.model.home.user.single_user.SingleUserModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.SingleUserRepository

class SingleUserUseCase(
    private val repository: SingleUserRepository
) {
    suspend operator fun invoke(
        id:String,
    ): SingleUserModel {
       return repository.getSingleUser(
           id=id
       )
    }
}