package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.kitchen_info


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ProductAdditions(
    @SerialName("id")
    val id: String?=null,
    @SerialName("name")
    val name: String?=null,
    @SerialName("variants")
    val variants: List<String?>?= null
)