package org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_options

import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_options.PriceLevelModel
 import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_options.PriceLevelsRepository

class GetPriceLevelsUseCase
    (private val repository: PriceLevelsRepository) {
    suspend operator fun invoke(): PriceLevelModel {
        return repository.getPriceLevels()
    }
}
