package org.hs_soft.runmynesto.data.api_datasource.user.tab_general

import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.tab_general.UpdateUserImageRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateUserImageRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateUserImageRepository {

    override suspend fun updateUserImage(
        image: String,
        id: String,
        type: String
    ) {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            mutation uploadImgBase64(${"$"}file: String!, ${"$"}type: ImgType!, ${"$"}id: ID!) {
              cashAssist {
                uploadImgBase64(file: ${"$"}file, type: ${"$"}type, id: ${"$"}id) {
                  ${ApiUtils.Field.id}
                }
              }
            }
        """.trimIndent())
            put("variables", buildJsonObject {
                put("file", image)
                put("type", type)
                put("id", id)
            })
        }

        apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        ) ?: throw Exception(Strings.timeoutErr)

    }
}