package org.hs_soft.runmynesto.domain.model.home.options.virtual_card

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.util.ApiUtils

enum class CustomerCardInfoEnum(
    val titleShown: String,
    val title: String,
) {
    COMPANY_NAME(
        titleShown = Strings.companyName,
        title = ApiUtils.VirtualCardFields.ISSUER_NAME
    ),
    EMAIL_COMPANY(
        titleShown = Strings.emailCompany,
        title = ApiUtils.VirtualCardFields.ISSUER_EMAIL
    ),
    DESIGNATION(
        titleShown = Strings.name,
        title = ApiUtils.VirtualCardFields.PROGRAM_NAME
    ),
    DESCRIPTION(
        titleShown = Strings.description,
        title = ApiUtils.VirtualCardFields.ACCOUNT_ID_LABEL
    ),
    TITLE_FIELD_1(
        titleShown = Strings.titleField1,
        title = ApiUtils.VirtualCardFields.REWARDS_TIER_LABEL
    ),
    TITLE_1(
        titleShown = Strings.title1,
        title = ApiUtils.VirtualCardFields.REWARDS_TIER
    ),
    TITLE_FIELD_2(
        titleShown = Strings.titleField2,
        title = ApiUtils.VirtualCardFields.SECONDARY_REWARDS_TIER_LABEL
    ),
    TITLE_2(
        titleShown = Strings.title2,
        title = ApiUtils.VirtualCardFields.SECONDARY_REWARDS_TIER
    ),
    TITLE_CONTACT(
        titleShown = Strings.titleContact,
        title = ApiUtils.VirtualCardFields.PHONE_DESCRIPTION
    ),
    TELEPHONE_NUMBER(
        titleShown = Strings.telephoneNumber,
        title = ApiUtils.VirtualCardFields.PHONE_LINK
    ),
    TITLE_WEBSITE(
        titleShown = Strings.titleWebsite,
        title = ApiUtils.VirtualCardFields.HOME_PAGE_DESCRIPTION
    ),
    WEBSITE(
        titleShown = Strings.website,
        title = ApiUtils.VirtualCardFields.HOME_PAGE_LINK,
    )
}