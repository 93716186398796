package org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.kitchen_info.KitchenInfoModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.KitchenInfoRepository


class KitchenInfoUseCase(
    private val repository: KitchenInfoRepository
) {
    suspend operator fun invoke(): KitchenInfoModel {
        return repository.kitchenInfo()
    }


}