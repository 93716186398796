package org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LoyaltyCardDetailModel(
    @SerialName("data")
    val `data`: Data?
)