package org.hs_soft.runmynesto.data.api_datasource

import com.varabyte.kobweb.browser.http.AbortController
import com.varabyte.kobweb.browser.http.http
import kotlinx.browser.window
import kotlinx.coroutines.withTimeoutOrNull
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.TokenProvider

class ApiClientImpl(
    private val tokenProvider: TokenProvider,
) : ApiClient {

    override suspend fun requestResult(
        graphQLQuery: JsonObject,
        abortController: AbortController,
        resource: String,
        json: Json
    ): ByteArray? {

        return withTimeoutOrNull(Constants.timeout) {
            window.http.post(
                resource = resource,
                body = json.encodeToString<JsonObject>(graphQLQuery).encodeToByteArray(),
                headers = mapOf(
                    "Authorization" to tokenProvider.token,
                    "Content-Type" to "application/json",
                ),
                abortController = abortController,

            )
        }
    }
}