package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail.LoyaltyCardDetail
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.MediumContentTitle

@Composable
fun Balance(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    setIsPointsActiveStatus: (Boolean) -> Unit,
    setCardLimit: (Int) -> Unit,
    setDailyLimit: (Int) -> Unit,
    windowSizeMode: WindowSizeModeEnum,
)
{
    Column()
    {
        MediumContentTitle(
            title = Strings.balance,
        )

        if (windowSizeMode==WindowSizeModeEnum.Desktop)
        {
            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {
                Content(
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    setCardLimit={
                        setCardLimit(it)
                    },
                    setDailyLimit={
                        setDailyLimit(it)
                    },
                    setIsPointsActiveStatus={
                        setIsPointsActiveStatus(it)
                    }
                )
            }
        }else{
            Column() {
                Content(
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    setCardLimit={
                        setCardLimit(it)
                    },
                    setDailyLimit={
                        setDailyLimit(it)
                    },
                    setIsPointsActiveStatus={
                        setIsPointsActiveStatus(it)
                    }
                )
            }
        }





    }



}

@Composable
private fun Content(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    setCardLimit: (Int) -> Unit,
    setDailyLimit: (Int) -> Unit,
    setIsPointsActiveStatus: (Boolean) -> Unit
) {
    Row()
    {
        CustomEditText(
            modifier = Modifier,
            label = Strings.balance,
            isEnabled = false,
            value = selectedLoyaltyCard?.balance?.toString() ?: "",
            marginRight = Dimen.normalPadding,
            textAlign = TextAlign.End,
            marginBottom = Dimen.smallPadding,
            width = Dimen.normalEditTextWidth,
            isRequired = false,
            hint = "",
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {},
        )


        CustomEditText(
            modifier = Modifier,
            isEnabled = false,
            label = Strings.points,
            value = formatNumberWithCommas(
                selectedLoyaltyCard?.points
            ),
            width = Dimen.normalEditTextWidth,
            marginRight = Dimen.normalPadding,
            textAlign = TextAlign.End,
            isRequired = false,
            hint = "",
            marginBottom = Dimen.smallPadding,
            onFocusIn = {

            },
            onFocusOut = {},
            onInputChange = {},
        )
    }
    Row()
    {
        CustomEditText(
            modifier = Modifier,
            label = Strings.cardLimit,
            marginRight = Dimen.normalPadding,
            width = Dimen.normalEditTextWidth,
            value = selectedLoyaltyCard?.limit?.toString() ?: "",
            isRequired = false,
            isDecimal = true,
            hint = "",
            marginBottom = Dimen.smallPadding,
            onFocusIn = { },
            onFocusOut = {
                setCardLimit(it.toInt())
            },
            onInputChange = {},
        )


        CustomEditText(
            modifier = Modifier,
            label = Strings.dailyLimit,
            marginRight = Dimen.mediumPadding,
            value = selectedLoyaltyCard?.dayLimit?.toString() ?: "",
            width = Dimen.normalEditTextWidth,
            isRequired = false,
            isDecimal = true,
            hint = "",
            marginBottom = Dimen.smallPadding,
            onFocusIn = {},
            onFocusOut = {
                setDailyLimit(it.toInt())
            },
            onInputChange = {},
        )
    }


    CheckboxComponent(
        modifier = Modifier,
        checkStatus = selectedLoyaltyCard?.isPointsActive ?: false,
        title = Strings.activate,
        onChecked = {
            setIsPointsActiveStatus(true)
        },
        onUnChecked = {
            setIsPointsActiveStatus(false)
        },
    )
}

fun formatNumberWithCommas(number: Int?): String {
        if (number==null)
            return ""
    return number.asDynamic().toLocaleString()
}