package org.hs_soft.runmynesto.domain.usecase.user

import org.hs_soft.runmynesto.domain.repository.network_datasource.user.UpdateUserDataRepository

class UpdateUserDataUseCase(
    private val repository: UpdateUserDataRepository
) {
    suspend operator fun invoke(
        dataValue: dynamic,
        dataTitle: String,
        userId: String
    ){
       return repository.updateUserData(
           dataValue=dataValue,
           dataTitle=dataTitle,
           userId=userId,
        )
    }
}