package org.hs_soft.runmynesto.pages.home.sub_page.template.mvi

import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.model.home.template.TemplateInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.ListingLayoutsEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.WaiterDeviceTypeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel


sealed class TemplateEvent {
    //Fetching Data
    data object GetTemplateCategoryList:TemplateEvent()

    data class SetGridProduct(
        val position:Int,
        val productId:String?=null,
    ):TemplateEvent()

    data object GetProductListBasedOnCategoryId:TemplateEvent()

    data class ChooseWaiterDeviceType(val type:WaiterDeviceTypeEnum)
        :TemplateEvent()

    data class ChooseWaiterLayoutType(val type:ListingLayoutsEnum)
        :TemplateEvent()


    data class SetLocalCategoryImg(
        val url:String,
    ): TemplateEvent()

    data class OnTemplateCategoryClick(
        val id:String,
        val switchToSecondPage:()->Unit,
    ):TemplateEvent()

    data class OnUpdateTemplateCategoryInfo(
        val id:String,
        val dataTitle:String,
        val dataValue:dynamic,
    ):TemplateEvent()


    data object OnAddToArticleIconClick:TemplateEvent()

    data class AddNewProductToArticleList(val id:String):TemplateEvent()


    data class UpdateTemplateCategoryListAfterDragDrop(
        val newList:List<dynamic>,
        val idList:List<String>,
        val positionList:List<Int>
    ) :TemplateEvent()

    data class UpdateCategoryBasedProductListAfterDragDrop(
        val newList: List<dynamic>,
        val idList: List<String>,
        val positionList: List<Int>,
        val productIdList: List<String>
    ) :TemplateEvent()

    data object GetCheckoutData: TemplateEvent()
    data class OnSearchCheckoutItems(val value:String):TemplateEvent()

    data class UpdateCheckout(
        val dataValue:MutableSet<String>
    ): TemplateEvent()

    data object GetPriceLevels:TemplateEvent()

    data class UpdatePriceLevel(val index:Int):TemplateEvent()

    data class UpdateSurcharge(
        val values:List<Int?>,
        val title:String,
    ):TemplateEvent()

    //Searching Event

    data object RefreshFoodList: TemplateEvent()

    //Article Searching Event
    data class OnSearchArticleInputChange(val value:String): TemplateEvent()

//    data object OnArticleTitleSortClick: TemplateEvent()
    data object RefreshArticleList: TemplateEvent()


    data class OnSubmitNewImage(val url:String, val id:String): TemplateEvent()

    data object OnChangePageClick:TemplateEvent()


    data class OnTabClick(val tab: TemplateInfoHeaderEnum):TemplateEvent()

    data object OnAddRecordIconClick:TemplateEvent()
    data object OnAddRecordDialogBackgroundClick:TemplateEvent()
    data object OnCancelRecordDialogClick:TemplateEvent()
    data class OnSubmitRecordDialogClick(val windowMode: WindowSizeModeEnum):TemplateEvent()

    data class OnChangeRecordName(val value:String):TemplateEvent()

    data class OnSelectDate(val selectedDate: MonthModel):TemplateEvent()
    data class SetCalendarStatus(val status:Boolean):TemplateEvent()

    data class OnPalletColorClick(val hexColor:String):TemplateEvent()
    data class SetColorPalletStatus(val status:Boolean):TemplateEvent()


    data class SetGenerateAIImgDialogStatus(
        val status:Boolean
    ):TemplateEvent()

    data class GenerateTemplateImg(
        val description:String
    ):TemplateEvent()


    data class OnChangeAiImageDescription(
        val value:String
    ):TemplateEvent()

    data object OnAddProductToArticleDialogBackgroundClick:
        TemplateEvent()


    //Filter
    data class SetFilterItemsComponentStatus(val status:Boolean): TemplateEvent()

    data object SubmitFilterItems: TemplateEvent()
    data class SelectFilterType(val type: GridItemsFilterEnum):TemplateEvent()

    data class SelectWaiterDeviceType(
        val type: WaiterDeviceTypeEnum
    ):TemplateEvent()



}