package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_flow.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_flow.IncomeExpansesInfoEnum
import org.hs_soft.runmynesto.domain.util.editTextBoxBackground
import org.hs_soft.runmynesto.domain.util.editTextLabelBackground
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.MediumContentTitle

@Composable
fun IncomeExpanses(
    windowSizeMode: WindowSizeModeEnum,
    specialProductExpenses: List<String?>?,
    updateIncomeExpanses:(List<String?>)->Unit,
)
{
    val data = specialProductExpenses?.toMutableList()

    Column {
        MediumContentTitle(
            title = Strings.incomeExpanses,
            marginTop = Dimen.mediumPadding,
            marginBottom = Dimen.mediumPadding,
        )

        IncomeExpansesInfoEnum.entries.chunked(2).forEachIndexed {rowIndex, rowItems ->
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = if (rowItems.size == 1)
                    Arrangement.Center else Arrangement.Start
            ) {
                rowItems.forEachIndexed {  colIndex, item ->
                    val index = rowIndex * 2 + colIndex
                    CustomEditText(
                        modifier = Modifier.weight(1f),
                        width = if (windowSizeMode==WindowSizeModeEnum.PHONE)
                                Dimen.normalEditTextWidth
                            else Dimen.mediumEditTextWidth,
                        label = item.titleShown,
                        value = specialProductExpenses?.getOrNull(index)?:"",
                        isRequired = false,
                        defaultBorderColor = Theme.GrayCD.rgb,
                        hint = "",
                        isOutlinedEditText = true,
                        onFocusIn = {},
                        onFocusOut = {
                            data?.set(index = index, element = it)
                            updateIncomeExpanses(
                                data?.toList()?: listOf()
                            )
                        },
                        onInputChange = {}
                    )

                    if (rowItems.size > 1 && item != rowItems.last()) {
                        Box(modifier = Modifier.width(Dimen.mediumPadding))
                    }
                }
            }

        }
    }

}