package org.hs_soft.runmynesto.pages.components.dialog.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px

@Composable
fun CustomButton(
    modifier: Modifier=Modifier,
    windowSizeMode: WindowSizeModeEnum=WindowSizeModeEnum.WINDOWS,
    width:CSSSizeValue<CSSUnit.px> =if (windowSizeMode!= WindowSizeModeEnum.PHONE)
            Dimen.mediumSubmitButtonWidth
        else Dimen.submitButtonPhoneWidth,
    height:CSSSizeValue<CSSUnit.px> = Dimen.submitButtonHeight,
    shadowColor: CSSColorValue=Theme.GrayCD.rgb,
    borderColor: CSSColorValue = Theme.Transparent.rgb,
    defaultTextColor: CSSColorValue=Theme.Secondary.rgb,
    hoveredTextColor:CSSColorValue=Theme.White.rgb,
    clickableTextColor:CSSColorValue=Theme.White.rgb,
    topBottomMargin: CSSSizeValue<CSSUnit.px> = 0.px,
    leftRightMargin: CSSSizeValue<CSSUnit.px> =Dimen.normalPadding,
    backgroundColor: CSSColorValue = Theme.Blue.rgb,
    hoveredBackgroundColor: CSSColorValue = Theme.DarkBlue.rgb,
    title: String,
    isClickable: Boolean = true,
    onButtonClick: () -> Unit,
) {
    val isHovered= remember { mutableStateOf(false) }
    Box(
        modifier = modifier
            .minWidth(width)
            .position(Position.Relative)
            .height(height)
            .margin(
                leftRight = leftRightMargin,
                topBottom = topBottomMargin
            )
            .padding(leftRight = Dimen.normalPadding)
            .onMouseOver {isHovered.value=true }
            .onMouseLeave { isHovered.value=false }
            .cursor(Cursor.Pointer)
            .boxShadow(
                offsetX = Dimen.smallOffsetX,
                offsetY = Dimen.smallOffsetY,
                blurRadius = Dimen.smallBlurRadius,
                color = if (isClickable) shadowColor else Theme.Transparent.rgb
            )
            .rippleEffect(isClickable) {
                onButtonClick()
            }
            .backgroundColor(
                if (!isClickable) Theme.GrayAlpha12.rgb
                else if(isHovered.value) hoveredBackgroundColor
                else backgroundColor
            )
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = borderColor
            )
            .borderRadius(Dimen.lowBorderRadius)
            .transitionBackground()
    )
    {
        SpanText(
            text = title,
            modifier = Modifier
                .align(Alignment.Center)
                .fontSize(FontSizes.semiMediumFontSize)
                .fontWeight(FontWeight.Medium)
                .letterSpacing(LetterSpacing.extraLarge)
                .lineHeight(FontLineHeight.lineSemiLarge)
                .fontFamily(FontFamily.Montserrat)
                .color(
                    if (isHovered.value && isClickable) hoveredTextColor
                    else if (isClickable) clickableTextColor
                    else defaultTextColor
                )
        )
    }
}