package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.bill_search.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data.BillSearchStatisticDataItem
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.ReportsTableSearchBar

@Composable
fun SelectedBillSearchItemDetailContent(
    windowSizeMode: WindowSizeModeEnum,
    modifier: Modifier=Modifier,
    selectedBillSearchItem:BillSearchStatisticDataItem?,
    onSearchInSelectedBillSearchItem:(String)->Unit,
)
{
    Column(
        modifier = modifier
            .fillMaxWidth()
    )
    {
        MediumContentTitle(
            title = Strings.selectedItem
        )

        ReportsTableSearchBar(
            windowSizeMode=windowSizeMode,
            onSearchInfo={
                onSearchInSelectedBillSearchItem(it)
            }
        )

        SelectedBillSearchItemDetailTable(
            windowSizeMode=windowSizeMode,
            selectedBillSearchItem = selectedBillSearchItem
        )



    }


}