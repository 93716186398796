package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.update_template_category


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("updateCategory")
    val updateCategory: UpdateCategory?
)