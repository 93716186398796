package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart.component.chart.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.color
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.dashboard.CompareChartDay
import org.hs_soft.runmynesto.domain.model.home.dashboard.CompareChartDaysEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.showSelectedDate
import org.hs_soft.runmynesto.pages.components.Separator
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.RadioInput

@Composable
fun TopChartToolbar(
    title: String,
    showCompareChartDialogStatus: Boolean,
    selectedDate: MonthModel,
    comparedDate:MonthModel,
    showCompareButton: Boolean,
    todayCircleColor:CSSColorValue,
    comparedCircleColor:CSSColorValue,
    windowSizeMode: WindowSizeModeEnum,
    showCompareChartDialog: () -> Unit,
    onDayClick: (MonthModel) -> Unit,
) {
//    val day = remember { selectedDate }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .height(Dimen.topToolbarHeight),
        horizontalAlignment = Alignment.End
    )
    {
        Row(
            modifier =Modifier
                .fillMaxWidth()
                .height(Dimen.topToolbarHeight)
                .padding(
                    leftRight = Dimen.mediumPadding,
                    topBottom = Dimen.normalPadding
                ),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            SpanText(
                text = title,
                modifier =Modifier
                    .fontFamily(FontFamily.Jakarta)
                    .fontSize(FontSizes.largeFontSize)
                    .color(Theme.Secondary.rgb)
                    .fontWeight(FontWeight.SemiBold)
            )

            Spacer()

            if (comparedDate!=selectedDate)
            {
                DayInfo(
                    date=selectedDate,
                    color = todayCircleColor,
                    dayInfo = Strings.today+" : "
                )
                DayInfo(
                    date=comparedDate,
                    color = comparedCircleColor
                )

            }

            if (showCompareButton){
                CompareCharts(
                    windowSizeMode=windowSizeMode,
                    showCompareChartDialog = {
                        showCompareChartDialog()
                    }
                )

            }

        }

        Separator(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = Dimen.normalPadding)
        )

        if (showCompareChartDialogStatus){
            Column(
                modifier = Modifier
                    .margin(top = Dimen.smallPadding)
                    .zIndex(Constants.ZIndex.smallZIndex)
                    .boxShadow(
                        color = Theme.GrayAlpha12.rgb,
                        blurRadius = Dimen.normalBlurRadius,
                        spreadRadius = Dimen.normalSpreadRadius
                    )
                    .backgroundColor(Theme.White.rgb) ,
                horizontalAlignment = Alignment.Start
            )
            {
                CompareChartDaysEnum.create(selectedDate).forEach {
                    DayItem(
                        model =it,
                        isChecked = it.date.monthNumber==comparedDate.monthNumber
                                && it.date.dayNumber==comparedDate.dayNumber
                                && it.date.yearNumber==comparedDate.yearNumber,
                        onDayClick = { date:MonthModel->
                            onDayClick(date)
                        }
                    )
                }


            }
        }


    }





}

@Composable
private fun DayItem(
    model: CompareChartDay,
    onDayClick: (MonthModel) -> Unit,
    isChecked: Boolean,
)
{
    Row(
        modifier =Modifier
            .cursor(Cursor.Pointer)
            .rippleEffect {
                onDayClick(model.date)
            }
            .padding(
                topBottom = Dimen.smallPadding,
                leftRight = Dimen.normalPadding,
            )
            .width(Dimen.CompareChartDayItemWidth),
        verticalAlignment = Alignment.CenterVertically
    )
    {

        RadioInput(
            attrs = Modifier
                .padding(Dimen.normalPadding)
                .toAttrs {
                    style {
                        color(Theme.Secondary.hex)
                        width(Dimen.mediumRadioButton)
                        height(Dimen.mediumRadioButton)
                    }
                    checked(isChecked)

                }
        )

        SpanText(
            text = model.title,
            modifier = Modifier
                .cursor(Cursor.Pointer)
                .margin(left = Dimen.normalPadding)
                .fontFamily(FontFamily.Jakarta)
                .fontSize(FontSizes.semiLargeFontSize)
                .color(Theme.Secondary.rgb)
        )

    }
}


@Composable
private fun DayInfo(
    color: CSSColorValue,
    date: MonthModel,
    dayInfo:String=""
)
{
    Row(
        modifier = Modifier
            .margin(leftRight = Dimen.normalPadding),
        verticalAlignment = Alignment.CenterVertically
    )
    {
        Box(
            modifier = Modifier
                .margin(right = Dimen.smallPadding)
                .size(Dimen.smallCircle)
                .borderRadius(50.percent)
                .backgroundColor(color)
        )

        SpanText(
            text = dayInfo+showSelectedDate(date),
            modifier = Modifier
                .fontFamily(FontFamily.Jakarta)
                .fontSize(FontSizes.semiLargeFontSize)
                .color(Theme.Secondary.rgb)

        )

    }


}