package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.upload_img


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("uploadImgBase64")
    val uploadImgBase64: UploadImgBase64?
)