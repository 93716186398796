package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.pages.components.checkout.CheckoutTabContent
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.CashRegisterConfigContent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsState

@Composable
fun CashRegisterConfiguration(
    state: OptionsState,
    event: (OptionsEvent) -> Unit,
    windowSizeMode: WindowSizeModeEnum,
)
{
    LaunchedEffect(state.selectedCashRegisterConfigItemId){
        event(OptionsEvent.GetCashRegisterConfigData)
    }

    Row(
        Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .padding(Dimen.normalPadding)

    )
    {
        if (windowSizeMode==WindowSizeModeEnum.PHONE)
        {
            if (state.firstPageActive)
            {
                CheckoutTabContent(
                    borderLess = true,
                    fillMaxWidth = false,
                    showCheckBoxes = false,
                    cashList =state.cashList,
                    cashRegisterIds = listOf(),
                    windowSizeMode = windowSizeMode,
                    updateCheckout = {},
                    isExpandAll = true,
                    searchValue ="" ,
                    onSearchValueChange = {
                        event(OptionsEvent.OnSearchCheckoutItems(it))
                    },
                    getCheckoutData = {
                        event(OptionsEvent.GetCheckoutData)
                    },
                    onItemClick = {
                        event(OptionsEvent.OnCashRegisterConfigItemClick(it))
                    }
                )
            }else{
                CashRegisterConfigContent(
                    state =state,
                    event =event,
                    windowSizeMode =windowSizeMode,
                )
            }


        }else{
            CheckoutTabContent(
                borderLess = true,
                fillMaxWidth = false,
                showCheckBoxes = false,
                cashList =state.cashList,
                cashRegisterIds = listOf(),
                windowSizeMode = windowSizeMode,
                updateCheckout = {},
                isExpandAll = true,
                searchValue ="" ,
                onSearchValueChange = {
                    event(OptionsEvent.OnSearchCheckoutItems(it))
                },
                getCheckoutData = {
                    event(OptionsEvent.GetCheckoutData)
                },
                onItemClick = {
                    event(OptionsEvent.OnCashRegisterConfigItemClick(it))
                }
            )

            CashRegisterConfigContent(
                state =state,
                event =event,
                windowSizeMode =windowSizeMode,
            )
        }






    }

}



