package org.hs_soft.runmynesto.pages.components.dialog.dialog

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.disabled
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.window
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.overflowText
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.home.ApplicationsInfoEnum
import org.hs_soft.runmynesto.domain.model.home.home.hssoft_apps_list.HSWebApps
import org.hs_soft.runmynesto.domain.util.Constants
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px

@Composable
fun ApplicationsDialog(
    modifier: Modifier=Modifier,
    windowSizeMode: WindowSizeModeEnum,
    listOfHSSoftApps: List<HSWebApps?>?,
    onItemClick: (String) -> Unit,
)
{
    Column(
        modifier = modifier
            .padding(Dimen.smallPadding)
            .boxShadow(
                color = Theme.GrayAlpha12.rgb,
                blurRadius = Dimen.normalBlurRadius,
                spreadRadius = Dimen.normalSpreadRadius,
            )
            .thenIf(
                windowSizeMode==WindowSizeModeEnum.PHONE,
                Modifier.boxShadow(
                    color = Theme.GrayAlpha12.rgb,
                    blurRadius = Dimen.normalBlurRadius,
                    offsetY = Dimen.normalSpreadRadius,
                    spreadRadius =0.px,
                )
            )

            .width( Dimen.applicationsDialogWidth)
            .thenIf(
                windowSizeMode==WindowSizeModeEnum.PHONE,
                Modifier.width(window.innerWidth.px)
            )
            .thenIf(
                windowSizeMode!=WindowSizeModeEnum.PHONE,
                Modifier
                    .borderRadius(Dimen.semiMediumPadding)
            )
            .minHeight(
                if (windowSizeMode==WindowSizeModeEnum.PHONE)
                    Dimen.applicationsDialogPhoneHeight
                else Dimen.applicationsDialogHeight
            )
            .backgroundColor(Theme.White.rgb)
            .zIndex(Constants.ZIndex.largeZIndex)
            .onClick {
                it.stopPropagation()
            },
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally,
    )
    {

        ApplicationsInfoEnum.entries.chunked(3)
            .forEachIndexed { rowIndex, rowItems ->
                Row()
                {
                    rowItems.forEachIndexed { colIndex, model ->
                        val (title1, title2) = splitTitle(model.title)
                        ApplicationItem(
                            img =model.img ,
                            isActive = listOfHSSoftApps?.any {
                                it?.isAvailable==true &&
                                        it.appType?.lowercase()==model.title.lowercase()
                            }?:false,
                            title1 = title1,
                            title2 = title2 ,
                            onItemClick = {
                                onItemClick(model.url)

                            }
                        )

                    }
                }

            }


    }
}

fun splitTitle(title: String): Pair<String, String> {
    val parts = title.split("_", limit = 2)
    return if (parts.size == 2) {
        parts[0] to parts[1]
    } else {
        title to ""
    }
}

@Composable
fun ApplicationItem(
    title1:String,
    title2: String="",
    img:String,
    isActive:Boolean,
    onItemClick: () -> Unit,
)
{
    val isHovered= remember { mutableStateOf(false) }
    Column(
        modifier = Modifier
            .position(Position.Relative)
            .margin(Dimen.smallPadding)
            .cursor(
                if (isActive) Cursor.Pointer
                else Cursor.NotAllowed
            )
            .borderRadius(Dimen.mediumBorderRadius)
            .onMouseLeave { isHovered.value=false}
            .onMouseOver { isHovered.value=true }
            .backgroundColor(
                if (isActive){
                    if (isHovered.value) Theme.GrayAlpha08.rgb
                    else Theme.Transparent.rgb
                }else Theme.Transparent.rgb
            )
            .overflowText()
            .transitionBackground()
            .width(Dimen.companyItemWidth)
            .height(Dimen.companyItemHeight)
            .rippleEffect {
                onItemClick()
            },
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {


        Image(
            src = img,
            modifier = Modifier
                .thenIf(
                    !isActive,
                    Modifier
                        .opacity(Constants.opacityForDisableItems)
                )
                .width(Dimen.companyImgWidth)
                .height(Dimen.companyImgHeight)
        )



        SpanText(
            text = "$title1$title2",
            modifier = Modifier
                .margin(top = Dimen.smallPadding)
                .overflowText()
                .textAlign(TextAlign.Center)
                .width(Dimen.companyItemWidth)
                .fontSize(FontSizes.semiMediumFontSize)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Jakarta)
                .fontWeight(FontWeight.Bold)
                .thenIf(
                    !isActive,
                    Modifier
                        .disabled(true)
                        .color(Theme.GrayAlpha38.rgb)
                )
        )

    }


}
