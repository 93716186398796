package org.hs_soft.runmynesto.pages.home.sub_page.options.mvi

import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.OptionInfoHeaders
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.CashRegisterConfigHeaders
import org.hs_soft.runmynesto.domain.model.home.options.menu_card_import.menu_card_model.MenuCardModel
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenXX
import org.w3c.files.File

sealed class OptionsEvent
{
    data class OnSelectShop(
        val branch:ChildrenXX?
    ):OptionsEvent()

    data class OnSelectRegion(
        val region:ChildrenX?
    ):OptionsEvent()

    data class OnSelectOffice(
        val office: Children?
    ):OptionsEvent()

    data class OnColorBoxClick(
        val selectedColorField:String
    ):OptionsEvent()
    data class OnPalletColorClick(
        val hexColor:String,
    ):OptionsEvent()



    data object GetPriceLevels: OptionsEvent()


    data class SetColorPalletStatus(val status:Boolean):OptionsEvent()

    data object GetVirtualCardInfo:OptionsEvent()

    data class OnCashRegisterConfigTabClick(
        val tab:CashRegisterConfigHeaders):OptionsEvent()

    data object OnPreviewGoogleWalletClick:OptionsEvent()

    data object OnPreviewAppleWalletClick:OptionsEvent()

    data object OnCloseAppleWallet:OptionsEvent()

    data object OnCloseGoogleWallet:OptionsEvent()

    data class UpdateCashRegisterConfigData(
        val title:String,
        val value:dynamic,
        val valueIsInParameters:Boolean ?=null,
    ):OptionsEvent()

    data class OnCashRegisterConfigItemClick(val id:String):OptionsEvent()

    data object GetCashRegisterConfigData :OptionsEvent()

    data object GetCheckoutData: OptionsEvent()

    data class OnSearchCheckoutItems(val value:String): OptionsEvent()

    data class UpdateAppConfig(
        val title:String,
        val value:List<String?>,
    ):OptionsEvent()

    data object GetAppConfig:OptionsEvent()

    data class UpdateVirtualCardInfo(
        val dataTitle:String,
        val dataValue:dynamic,
    ):OptionsEvent()

    data object OnLoadingOperationData:OptionsEvent()

    data class UpdateOptionData(
        val dataTitle:String,
        val dataValue:dynamic
    ): OptionsEvent()

    data class SaveCompanyProfileId(val id:String):
            OptionsEvent()

    data class OnTopTabClick(val tab: OptionInfoHeaders)
        :OptionsEvent()

    data object GetProductSalesGroup:OptionsEvent()

    data class OnSearchSaleGroupInputChange(val value:String):OptionsEvent()


    data object OnSaleGroupTitleSortClick:OptionsEvent()

    data object OnSaleGroupNumberSortClick:OptionsEvent()

    data class OnSaleGroupItemClick(
        val id:String,
        val callBack:()->Unit,
    ): OptionsEvent()

    data object OnChangePageClick:OptionsEvent()

    data class UpdateSaleGroup(
        val dataTitle: String,
        val dataValue:String,
    ):OptionsEvent()


    data object OnAddRecordIconClick: OptionsEvent()
    data object OnAddRecordDialogBackgroundClick: OptionsEvent()
    data object OnCancelRecordDialogClick: OptionsEvent()
    data class OnSubmitAddSaleGroupRecordDialogClick
        (val windowMode: WindowSizeModeEnum): OptionsEvent()
    data class OnChangeRecordNumber(val value:String): OptionsEvent()
    data class OnChangeRecordName(
        val value:String?,
        val isNumberOptional:Boolean=false,
    ): OptionsEvent()


    data class OnSubmitAddKitchenLocationRecordDialogClick(
        val location:String
    ): OptionsEvent()

    data class OnSubmitUpdateKitchenLocationRecordDialogClick(
        val location:String?,
    ): OptionsEvent()



    data object RefreshSaleGroupList:OptionsEvent()

    data object GetPrintersLocation:OptionsEvent()

    data object ShowAddPrinterLocationRecordDialog:OptionsEvent()

    data class ShowUpdatePrinterLocationRecordDialog(
        val status:Boolean,
        val dbIndex:Int?,
        val name:String?
    ):OptionsEvent()

    data class SetGenerateAIMenuCardDialogStatus(
        val status:Boolean
    ): OptionsEvent()

    data object ResetGenerateAIMenuCard: OptionsEvent()

    data class GenerateMenuCard(
        val files:List<File>
    ): OptionsEvent()

    data class SaveMenuCard(
        val menuCard:MenuCardModel
    ): OptionsEvent()

    data object OnGenerateMenuCardButtonClick: OptionsEvent()

    data class OnMenuProductItemValueChange(
        val menuCard:MenuCardModel,
        val id:Int,
        val key:String,
        val value:String
    ): OptionsEvent()

}
