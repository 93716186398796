package org.hs_soft.runmynesto.domain.config

import kotlinx.browser.window
import org.hs_soft.runmynesto.domain.util.isMacDevice
import org.jetbrains.compose.web.css.px

object Dimen
{
    val menuItemHeight=24.px
    val abcTablePhoneWidth=1000.px
    val billSearchPhoneTabletWidth=1200.px
    val filterABCStatisticHeight=220.px
    val filterABCStatisticWidth=338.px
    val CompareChartDayItemWidth=320.px
    val smallCircle=14.px
    val dashboardReportItemInfoWidth=168.px
    val dashboardReportItemInfoHeight=53.px
    val dashboardReportItemHeaderHeight=65.px
    val dashboardReportItemHeight=400.px
    val dashboardReportItemWidth=280.px
    val dashboardReportItemTabletPhoneWidth=348.px
    val closingReportsTabletModeHeight=800.px
    val daysFilterContentHeight=224.px
    val daysFilterDialogHeight=357.px
    val daysFilterDialogWidth=300.px
    val closingReportsTableHeight=320.px
    val closingReportTable3Height=164.px
    val closingReportTable1Height=216.px
    val abcStatisticTableHeight=264.px
    val transactionColumnItemPhoneWidth=140.px
    val transactionColumnItemWidth=200.px
    val selectTransactionDialogWidth=650.px
    val selectTransactionDialogHeight=400.px
    val selectTransactionDialogPhoneHeight=400.px
    val selectTransactionDialogPhoneWidth=320.px
    val addNewCustomerWidth=330.px
    val periodMonthWidth=167.px
    val headerExtraSmallWidth=6.px
    val tabletTabsWidth=(window.innerWidth*0.55).px
    val tabletTabsSmallWidth=(window.innerWidth*0.4).px
    val printLocationNameWidth=150.px
    val companyItemWidth=120.px
    val companyItemHeight=100.px
    val companyImgWidth=50.px
    val companyImgHeight=30.px
    val mobileTabsWidth= (window.innerWidth*0.9).px
    val chartWidth=(window.innerWidth*0.9)
    val chartHeight=(window.innerHeight*0.55)
    val tabItemWidth=130.px
    val walletCardParentHeight=500.px
    const val barcodeSize=120
    const val googleWalletPickImgHeight=100
    const val galleryRectSmallHeight=35
    const val galleryRectSmallWidth=107
    val walletCardWidth=300.px
    val walletCardHeight=440.px
    val minimumContentTitleHeight=32.px
    val normalTextAreaHeight=97.px
    val mediumTextAreaHeight = 100.px
    val largeTextAreaHeight=290.px


    val daysChosenWidth=500.px
    val mobileDaysChosenWidth=320.px
    val kitchenInfoTitleHeight = 25.px
    val kitchenAddItemDialogWidth=650.px
    val kitchenAddItemDialogHeight=560.px
    val walletDialogHeight=600.px
    val walletDialogWidth=650.px
    val mediumRadioButton=12.px
    val imageAIDialogWidth=350.px
    val imageAIDialogHeight=580.px
    val outlinedBoxHeight=50.px
    val customChooseColorBoxWidth=230.px
    val customColorBoxWidth=72.px
    val customColorBoxHeight=28.px
    val rectColor = 24.px
    val datePickerWidth=320.px
    val datePickerHeight=360.px
    val allergensHeaderWidth=530.px
    val allergensHeaderItemWidth=160.px
    val checkOutSmallLineWidth=8.px
    val mediumTextAreaWidth=300.px
    val userTabHeight= 45.px
    val smallBlurRadius=1.px
    val normalBlurRadius=2.px
    val mediumBlurRadius=3.px
    val smallSpreadRadius=1.px
    val normalSpreadRadius=2.px
    val mediumSpreadRadius=3.px
    val addRecordDialogWidth=464.px
    val addRecordDialogPhoneWidth=330.px
    val addRecordDialogHeight=270.px
    val articleListWidth=750.px
    val selectCirclePhotoSize=145.px
    val selectRectPhoneWidth=300.px
    val selectRectPhoneHeight=170.px
    val circleItemProgressSize=6.px
    val gridHeaderNumberWidth=64.px
    val gridCustomerCardNumberWidth=45.px
    val gridCustomerCardSmallHeaderWdith=30.px
    val headerSmallWidth=85.px
    val headerMediumWidth=125.px
    val headerLargeWidth=145.px
    val headerExtraLargeWidth=165.px
    val kitchenInfoItemsHeight=140.px
    val gridHeaderNameWidth=200.px
    val gridWidth = 320.px
    val gridCustomerCardWidth=360.px
    val gridHeaderHeight=36.px
    val mediumGridFooterHeight=46.px
    val normalGridFooterHeight=32.px
    val labelHeight=10.px
    val DateItemSize = 42.px
    val DateDaysWidth= (7* DateItemSize.value.toInt()).px
    val mediumTableItemHeight=32.px

    val extraLargeEditTextWidth=430.px
    val largeEditTextWidth=330.px
    val semiLargeEditTextWidth=300.px
    val mediumEditTextWidth=250.px
    val semiMediumEditTextWidth=200.px
    val highNormalEditTextWidth=170.px
    val normalEditTextWidth=140.px
    val semiNormalEditTextWidth=120.px
    val smallEditTextWidth=80.px
    val extraSmallEditTextWidth=72.px


    val dropDownHeight=41.px
    val dropDownInnerHeight=33.px
    val mediumOutlinedEditTextHeight=40.px
    val topToolbarHeight=48.px
    val normalOutlinedEditTextHeight=36.px
    val checkOutItemHeight=36.px
    val checkOutItemWidth=520.px
    val itemSeparatorHeight=20.px
    const val activePageSelectorWidth=15
    const val activePageSelectorHeight=21
    const val inactivePageSelectorWidth=3
    const val inactivePageSelectorHeight=18

    val submitButtonHeight=36.px
    val normalCustomerButtonWidth=130.px
    val mediumSubmitButtonWidth=160.px
    val generateButtonWidth=170.px
    val submitButtonPhoneWidth=110.px


    //Padding
    val extraExtraSmallPadding = 1.px
    val extraSmallPadding = 2.px
    val smallPadding=4.px
    val semiNormalPadding=6.px
    val normalPadding=8.px
    val mediumPadding=12.px
    val largePadding=16.px
    val extraLargePadding=24.px
    val extraExtraLargePadding=40.px
    val veryExtraExtraPadding=56.px


    //Border
    val lowBorderRadius=4.px
    val mediumBorderRadius=8.px
    val largeBorderRadius=12.px

    val smallThickness=if(isMacDevice()) 1.px else 0.7.px
    val mediumThickness=2.px
    val largeThickness=4.px

    //-Menu-------------------------------------
    val menuItemsHeight=500.px
    val menuItemRowWidth=300.px
    val menuItemRowHeight=48.px
    const val subMenuItemRowHeight=36
    val menuFooterHeight=142.px
    val footerInfoHeight=70.px
    //Offset------------------------------------
    val smallOffsetX=1.px
    val smallOffsetY=1.px
    val mediumOffsetX=1.px
    val mediumOffsetY=1.px
    //Icon Sizes----------------
    const val extraSmallIcon=14
    const val smallIconSize=16
    const val normalIconSize=20
    const val mediumIconSize=24
    const val highMediumIconSize=30
    const val semiLargeIconSize=32
    const val largeIconSize=36
    const val extraLargeIconSize=40

    val menuIconSize = 48.px

    //--------------------------------------------
    //Login
    val loginDialogCorner=16.px
    val loginDialogWidth=550.px
    val loginDialogHeight=450.px
    val lowHeightLine=1.px
    val mediumHeightLine=3.px
    val loginSubmitHeight=46.px
    val loginInputWidth=300.px
    val fieldErrorMsgHeight=10.px




}
