package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun BoxColor(
    modifier:Modifier=Modifier,
    title:String,
    color:String?,
    onColorBoxClick:()->Unit,
)
{
    Column(modifier = modifier){
        SpanText(
            text = title,
            modifier = Modifier
                .fontSize(FontSizes.semiMediumFontSize)
                .fontWeight(FontWeight.Normal)
                .fontFamily(FontFamily.Jakarta)
                .color(Theme.Primary.rgb)

        )

        Box(
            modifier = Modifier
                .rippleEffect {
                    onColorBoxClick()
                }
                .backgroundColor(Color(color?:Theme.Transparent.hex))
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayCD.rgb
                )
                .width(Dimen.normalEditTextWidth)
                .height(Dimen.mediumOutlinedEditTextHeight)
                .borderRadius(Dimen.lowBorderRadius)
        )
        {
            if (color==null){
                SpanText(
                    text = Strings.colorIsMissing,
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                        .align(Alignment.Center)
                        .fontSize(FontSizes.mediumFontSize)
                        .fontWeight(FontWeight.Normal)
                        .fontFamily(FontFamily.Jakarta)
                        .color(Theme.Primary.rgb)

                )
            }


        }
    }


}