package org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.init_search_bill


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("initSearchBills")
    val initSearchBills: String?,
    @SerialName("__typename")
    val typename: String?
)