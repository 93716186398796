package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.jetbrains.compose.web.css.Color

@Composable
fun WalletCardItem(
    title: String,
    content: @Composable () -> Unit,
    backgroundColor: String?,
)
{
    Column(
        modifier = Modifier
            .margin(Dimen.normalPadding),
        horizontalAlignment = Alignment.Start
    )
    {
        MediumContentTitle(
            title=title,
            marginBottom =Dimen.mediumPadding,
        )

        Column(
            modifier = Modifier
                .boxShadow(
                    color = Theme.GrayAlpha12.rgb,
                    blurRadius = Dimen.normalBlurRadius,
                    spreadRadius = Dimen.normalSpreadRadius
                )
                .backgroundColor(
                   Color(backgroundColor?:Theme.White.hex)
                )
                .borderRadius(Dimen.mediumBorderRadius)
                .width(Dimen.walletCardWidth)
                .height(Dimen.walletCardHeight),
            horizontalAlignment = Alignment.CenterHorizontally

        )
        {
            content()
        }

    }

}