package org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics

import org.hs_soft.runmynesto.domain.config.Strings

enum class ABCStatisticGroupByEnum(
    val title: String,
    val value: String
) {
    ARTICLE(
        title = Strings.product,
        value = "product"
    ),
    BRANCH_ARTICLE(
        title = Strings.shopProduct,
        value = "shopProduct"
    ),
    ARTICLE_BRANCH(
        title = Strings.productShop,
        value = "productShop"
    ),
    DATE_ARTICLE(
        title = Strings.dateProduct,
        value = "dateProduct"
    ),
    ARTICLE_DATE(
        title = Strings.productDate,
        value = "productDate"
    ),
    ARTICLE_DATE_BRANCH(
        title = Strings.productDateShop,
        value = "productDateShop"
    )
}