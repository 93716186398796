package org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("clientTree")
    val clientTree: List<ClientTree?>?,
    @SerialName("getAppConfig")
    val getAppConfig: GetAppConfig?,
    @SerialName("getDashboardDSGeo")
    val getDashboardDSGeo: List<GetDashboardDSGeo?>?,
    @SerialName("listShops")
    val listShops: ListShops?,
    @SerialName("__typename")
    val typename: String?
)