package org.hs_soft.runmynesto.domain.model.home.template

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.TitleEnum



enum class TemplateInfoHeaderEnum(override val title: String) : TitleEnum {
    GENERAL(Strings.general.uppercase()),
    CHECKOUT(Strings.devices.uppercase()),
    SURCHARGE_IN_HOUSE(Strings.surchargeInHouse.uppercase()),
    OPTIONS(Strings.options.uppercase())
}