package org.hs_soft.runmynesto.domain.model.home.closing_reports.print_closing_reports_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetClosingsReport(
    @SerialName("pagination")
    val pagination: Pagination?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("url")
    val url: String?
)