package org.hs_soft.runmynesto.domain.model.home.closing_reports

import org.hs_soft.runmynesto.domain.config.Strings

enum class FinalReportsGroupEnum(
    val title:String,
    val value:String?,
){
    IN_TOTAL(
        title = Strings.inTotal,
        value=null,
    ),
    GROUP_BY_DATE(
        title=Strings.groupByDate,
        value ="date" ,
    ),
    GROUP_BY_SHOP(
        title = Strings.groupByShop,
        value ="shop",
    ),
    GROUP_BY_DATE_SHOP(
        title = Strings.groupByDateShop,
        value ="dateShop"
    )

}