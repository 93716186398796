package org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.abc_statistic_data


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ABCStatisticItem(
    @SerialName("billAmount1")
    val billAmount1: Double?,
    @SerialName("billAmount2")
    val billAmount2: Double?,
    @SerialName("billAmount3")
    val billAmount3: Double?,
    @SerialName("billAmount4")
    val billAmount4: Double?,
    @SerialName("breakfastNumber")
    val breakfastNumber: Double?,
    @SerialName("breakfastTotal")
    val breakfastTotal: Double?,
    @SerialName("code")
    val code: String?,
    @SerialName("contributionMargin")
    val contributionMargin: Double?,
    @SerialName("creditCustomer")
    val creditCustomer: Double?,
    @SerialName("creditCustomerTotal")
    val creditCustomerTotal: Double?,
    @SerialName("creditCustomerWeight")
    val creditCustomerWeight: Double?,
    @SerialName("dateTime")
    val dateTime: String?,
    @SerialName("deliver")
    val deliver: Double?,
    @SerialName("difference")
    val difference: Double?,
    @SerialName("differenceAmount")
    val differenceAmount: Double?,
    @SerialName("discountNumber")
    val discountNumber: Double?,
    @SerialName("discountTotal")
    val discountTotal: Double?,
    @SerialName("firstSale")
    val firstSale: String?,
    @SerialName("g1Abc")
    val g1Abc: String?,
    @SerialName("g1Percent")
    val g1Percent: Double?,
    @SerialName("g1PercentT")
    val g1PercentT: Double?,
    @SerialName("gNumber")
    val gNumber: Double?,
    @SerialName("grossProfit")
    val grossProfit: Double?,
    @SerialName("group1")
    val group1: String?,
    @SerialName("group2")
    val group2: String?,
    @SerialName("group3")
    val group3: String?,
    @SerialName("inCafeNumber")
    val inCafeNumber: Double?,
    @SerialName("inCafeTotal")
    val inCafeTotal: Double?,
    @SerialName("lastSale")
    val lastSale: String?,
    @SerialName("name")
    val name: String?,
    @SerialName("number")
    val number: Double?,
    @SerialName("oldStock")
    val oldStock: Double?,
    @SerialName("onlyThis")
    val onlyThis: Double?,
    @SerialName("order1")
    val order1: Double?,
    @SerialName("order1Amount")
    val order1Amount: Double?,
    @SerialName("productCount")
    val productCount: Double?,
    @SerialName("productId")
    val productId: String?,
    @SerialName("promotionNumber")
    val promotionNumber: Double?,
    @SerialName("promotionTotal")
    val promotionTotal: Double?,
    @SerialName("salesGroup")
    val salesGroup: String?,
    @SerialName("shopName")
    val shopName: String?,
    @SerialName("stock")
    val stock: Double?,
    @SerialName("stockAmount")
    val stockAmount: Double?,
    @SerialName("total")
    val total: Double?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("unsold")
    val unsold: Double?,
    @SerialName("unsoldAmount")
    val unsoldAmount: Double?,
    @SerialName("unsoldPercent")
    val unsoldPercent: Double?,
    @SerialName("weight")
    val weight: Double?
)