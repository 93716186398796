package org.hs_soft.runmynesto.domain.usecase.closing_report

import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model.ClosingReportsModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.closing_report.ClosingReportsRepository

class ClosingReportsUseCase(
    private val repository:ClosingReportsRepository
)
{
    suspend operator fun invoke(
        from:String,
        to:String,
        cashRegisterIds:List<String>,
    ): ClosingReportsModel {
        return repository.getClosingReports(
            from = from,
            to=to,
            cashRegisterIds=cashRegisterIds,
        )
    }

}