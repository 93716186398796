package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.select_days

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.select_days.DayWeekEnum
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.parseWeekDaysNum
import org.hs_soft.runmynesto.pages.components.OutlinedBox
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.px

@Composable
fun SelectDays(
    windowSizeMode:WindowSizeModeEnum,
    selectedDaysNumber: Int,
    onChangeDayCheckStatus:(Int)->Unit,
) {

    val selectedDays = parseWeekDaysNum(selectedDaysNumber)
    val isExpanded= remember { mutableStateOf(false) }

    OutlinedBox(
        label = Strings.selectDays,
        height = Dimen.outlinedBoxHeight,
        width = if (windowSizeMode==WindowSizeModeEnum.PHONE)
            Dimen.mobileDaysChosenWidth
        else (2*Dimen.mediumEditTextWidth.value.toInt()).px,
        boxContent = {
            DaysChosenList(
                windowSizeMode = windowSizeMode,
                isExpanded = isExpanded.value,
                selectedDaysNumber = selectedDaysNumber,
                onRowClick = {
                    isExpanded.value = !isExpanded.value
                },
                selectedDays = selectedDays,
                onChangeDayCheckStatus = {
                    onChangeDayCheckStatus(it)
                },
            )

        },
        expandableList = {
            if (isExpanded.value) {
                DaysCheckBoxList(
                    selectedDays = selectedDays,
                    onChangeDayCheckStatus = {
                        onChangeDayCheckStatus(it)
                    },
                    selectedDaysNumber = selectedDaysNumber
                )
            }
        }
    )



}


@Composable
private fun DaysChosenList(
    windowSizeMode:WindowSizeModeEnum,
    selectedDays: List<DayWeekEnum>,
    onChangeDayCheckStatus: (Int) -> Unit,
    selectedDaysNumber: Int,
    isExpanded: Boolean,
    onRowClick: () -> Unit
) {
    Row(
        modifier = Modifier
            .minHeight(Dimen.mediumOutlinedEditTextHeight)
            .width(
                if (windowSizeMode==WindowSizeModeEnum.PHONE)
                    Dimen.mobileDaysChosenWidth
                else Dimen.daysChosenWidth
            )
            .onClick {
                onRowClick()
            },
        verticalAlignment = Alignment.CenterVertically
    )
    {

        Column(
            modifier = Modifier
                .padding(Dimen.smallPadding)
            ,verticalArrangement = Arrangement.Center

        ) {
            if (selectedDays.contains(DayWeekEnum.ALL_DAYS)) {
                SelectedDayItem(
                    dayName = DayWeekEnum.ALL_DAYS.title,
                    onDeleteClick = {
                        onChangeDayCheckStatus(0)
                    },
                )
            } else {
                if (windowSizeMode==WindowSizeModeEnum.PHONE){
                    DayWeekEnum.entries
                        .windowed(size = 4, step = 4)
                        .forEach { newRow ->
                            Row()
                            {
                                newRow.forEach { day ->
                                    if (selectedDays.contains(day)) {
                                        SelectedDayItem(
                                            dayName = day.title,
                                            onDeleteClick = {
                                                onChangeDayCheckStatus(selectedDaysNumber - day.value)
                                            },
                                        )
                                    }
                                }
                            }


                        }
                }else{
                    Row()
                    {
                        DayWeekEnum.entries.forEach {day->
                            if (selectedDays.contains(day)) {
                                SelectedDayItem(
                                    dayName = day.title,
                                    onDeleteClick = {
                                        onChangeDayCheckStatus(selectedDaysNumber - day.value)
                                    },
                                )
                            }
                        }
                    }
                }


            }


        }



        Spacer()

        VectorIcon(
            modifier = Modifier,
            width = Dimen.mediumIconSize,
            height = Dimen.mediumIconSize,
            pathData = if (isExpanded)
                Res.PathIcon.arrowTop
            else Res.PathIcon.arrowBelow,
            color = Theme.Primary.hex
        )
    }

}

@Composable
private fun DaysCheckBoxList(
    selectedDays: List<DayWeekEnum>,
    onChangeDayCheckStatus: (Int) -> Unit,
    selectedDaysNumber: Int
) {

    DayWeekEnum.entries.forEach { dayEnum ->
        CheckBoxDay(
            isChecked = selectedDays.contains(dayEnum),
            title = dayEnum.title,
            onChecked = {
                if (dayEnum == DayWeekEnum.ALL_DAYS)
                    onChangeDayCheckStatus(DayWeekEnum.ALL_DAYS.value)
                else onChangeDayCheckStatus(selectedDaysNumber + dayEnum.value)

            },
            onUnChecked = {
                if (dayEnum == DayWeekEnum.ALL_DAYS)
                    onChangeDayCheckStatus(0)
                else onChangeDayCheckStatus(selectedDaysNumber - dayEnum.value)
            }

        )
    }

}
