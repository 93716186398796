package org.hs_soft.runmynesto.data.api_datasource.user

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.user.users_info.UsersData
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.UsersDataRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class GetUsersDataRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UsersDataRepository {

    override suspend fun getUsersData(): UsersData {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                query listUsers {
                  cashAssist {
                    listUsers {
                      items {
                         ${ApiUtils.Field.id}
                         ${ApiUtils.Field.code}
                         ${ApiUtils.Field.name}
                         ${ApiUtils.Field.isActive}
                         
                      }
                    }
                  }
                }
            """.trimIndent())
        }


        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<UsersData>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }

    }

}