package org.hs_soft.runmynesto.data.api_datasource.authentication

import androidx.compose.runtime.NoLiveLiterals
import kotlinx.coroutines.suspendCancellableCoroutine
import org.hs_soft.runmynesto.Auth
import org.hs_soft.runmynesto.domain.repository.network_datasource.home.FetchIdTokenRepository
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException

class FetchIdTokenRepositoryImpl:FetchIdTokenRepository{

    @NoLiveLiterals
    override suspend fun getIdToken(): String =
        suspendCancellableCoroutine { continuation ->

            Auth.fetchAuthSession().then { result ->
                continuation.resume(result.tokens.idToken)
            }.catch { error: dynamic ->
                console.log("Fetch AuthData Error: ${error}")
                continuation.resumeWithException(
                    Exception("Failed to get AuthData: $error")
                )
            }
        }

}

