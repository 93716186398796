package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.util.getEndOfDay
import org.hs_soft.runmynesto.domain.util.getEndOfWeek
import org.hs_soft.runmynesto.domain.util.getStartOfDay
import org.hs_soft.runmynesto.domain.util.getStartOfWeek

enum class PeriodDaysEnum(
    val title:String,
    val from:String,
    val to:String,
){
    TODAY(
        title = Strings.today,
        from = getStartOfDay(0),
        to = getEndOfDay(0)
    ),
    YESTERDAY(
        title = Strings.yesterday,
        from = getStartOfDay(-1),
        to = getEndOfDay(-1)
    ),
    THIS_WEEK(
        title = Strings.thisWeek,
        from = getStartOfWeek(0),
        to = getEndOfWeek(0)
    ),
    PREVIOUS_WEEK(
        title = Strings.previousWeek,
        from = getStartOfWeek(-1),
        to = getEndOfWeek(-1)
    );

}