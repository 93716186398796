package org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class User(

    @SerialName("id")
    val id:String,
    @SerialName("name")
    val name:String,
    @SerialName("__typename")
    val typename:String,
)
