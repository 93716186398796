package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class VirtualCardConfigModel(
    @SerialName("data")
    val `data`: Data?
)