package org.hs_soft.runmynesto.data.api_datasource.template

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.product_categories.TemplateCategoryData
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.TemplateCategoryRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils


class TemplateCategoryRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
//    private val apolloClient: ApolloClient,
) : TemplateCategoryRepository {

    override suspend fun fetchTemplateCategoryList():
            TemplateCategoryData {

        val abortController = requestManager.createController()
//
//
//        val response: ApolloResponse<ListCategoriesQuery.Data> =
//            apolloClient.query(ListCategoriesQuery()).execute()
//
//        if (response.hasErrors()) {
//            throw Exception(response.errors?.firstOrNull()?.message
//                ?: "Unknown error occurred")
//        }
//
//        val categories = response.data?.cashAssist?.listCategories?.items?.map { category ->
//            TemplateCategoryData.Category(
//                id = category?.id,
//                position = category?.position,
//                name = category?.name,
//                isActive = category?.isActive
//            )
//        } ?: emptyList()
//
//        return  TemplateCategoryData(categories)






        val graphQLQuery = buildJsonObject {
            put("query", """
                query listCategories {
                  cashAssist {
                    listCategories {
                      items {
                        ${ApiUtils.Field.id}
                        ${ApiUtils.Field.position}
                        ${ApiUtils.Field.name}
                        ${ApiUtils.Field.isActive}
                      }
                    }
                  }
                }
            """.trimIndent())
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<TemplateCategoryData>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }
}