package org.hs_soft.runmynesto.domain.model.home.closing_reports.print_closing_reports_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Pagination(
    @SerialName("cacheKey")
    val cacheKey: String?,
    @SerialName("expectedUrl")
    val expectedUrl: String?,
    @SerialName("hasNext")
    val hasNext: Boolean?,
    @SerialName("__typename")
    val typename: String?
)