package org.hs_soft.runmynesto.pages.components.grid_function

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.draggable
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onDragEnd
import com.varabyte.kobweb.compose.ui.modifiers.onDragEnter
import com.varabyte.kobweb.compose.ui.modifiers.onDragStart
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.onTouchEnd
import com.varabyte.kobweb.compose.ui.modifiers.onTouchMove
import com.varabyte.kobweb.compose.ui.modifiers.onTouchStart
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.hoverBackgroundStyle
import org.hs_soft.runmynesto.domain.config.overflowText
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_model.MediaItem
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group.SaleItem
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location.PrinterLocation
import org.hs_soft.runmynesto.domain.model.home.template.product_categories.TemplateCategoryModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.ArticleModel
import org.hs_soft.runmynesto.domain.model.home.user.users_info.UserModel
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Constants.TemplateDefaultPositionAdded
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.extractTime
import org.hs_soft.runmynesto.pages.components.InfiniteScrollGrid
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.w3c.dom.get
import kotlin.js.Date

@Composable
fun GridItemsList(
    id: String,
    items: List<dynamic>,
    selectedItems: List<String>? = listOf(),
    onItemClick: (dynamic) -> Unit,
    onReorder: (List<dynamic>, List<String>, List<Int>, List<String>) -> Unit = { newList, idList, positionList, itemIdList -> },
    onDragEnd: (dynamic) -> Unit = {},

) {
    val selectedItemId = remember { mutableStateOf<String?>(null) }
    var newItems by remember { mutableStateOf<List<dynamic>>(emptyList()) }
    val hoverIndex = remember { mutableStateOf(-1) }

    LaunchedEffect(id) {
        selectedItemId.value = id
    }
    LaunchedEffect(items){
        newItems=items
    }


    val moveTemplateCategoryItem = moveTemplateCategoryItems(
        newItems = newItems,
        onReorder={newList, idList, positionList,itemIdList ->
            onReorder(
                newList,idList,positionList, listOf()
            )
        }
    )

    val moveTemplateProductItem= moveProductItems(
        newItems=newItems,
        onReorder={newList, idList, positionList,itemIdList ->
            onReorder(
                newList,idList,positionList,itemIdList
            )
        }
    )

    InfiniteScrollGrid(
        items = items,
        onLoadMore = {}
    ){index->
        val model = items[index]
        GridItem(
            model = model,
            index = index,
            hoverIndex = hoverIndex,
            itemsSize = items.size,
            isSelected = when (model) {
                is TemplateCategoryModel -> model.id == selectedItemId.value
                is UserModel -> model.id == selectedItemId.value
                is ProductItem -> model.id == selectedItemId.value ||
                        selectedItems?.contains(model.id) == true
                is ArticleModel -> model.id == selectedItemId.value
                is SaleItem -> model.id == selectedItemId.value
                is MediaItem -> model.id==selectedItemId.value
                else -> false
            },
            onItemClick = {
                id -> onItemClick(id)
           },
            onDragEnd = { from, to ->
                when (model) {
                    is TemplateCategoryModel -> moveTemplateCategoryItem(from, to)
                    is ArticleModel -> moveTemplateProductItem(from, to)
                    is ProductItem ->{
                        if (selectedItems?.contains(model.id) == false)
                            onDragEnd(model)
                    }
                }
            },
            onItemDraggedOver = { overIndex ->
                hoverIndex.value = overIndex
            },
        )
    }



}

private fun moveTemplateCategoryItems(
    newItems: List<TemplateCategoryModel>,
    onReorder: (List<dynamic>, List<String>, List<Int>,List<String>) -> Unit
): (Int, Int) -> Unit {
    var newItems1 = newItems
    val moveItem = { from: Int, to: Int ->
        if (from != to) {
            newItems1 = newItems1.toMutableList().apply {
                add(to, removeAt(from))
            }.mapIndexed { index, item ->
                if ((index in from..to) || (index in to..from)) {
                    item.copy(position = index + TemplateDefaultPositionAdded)
                } else item

            }
            val changedItems = newItems1.filterIndexed { index, item ->
                item.position == index + TemplateDefaultPositionAdded &&
                        ((index in from..to) || (index in to..from))
            }

            val idsList = changedItems.map { it.id }
            val newPositions = changedItems.map { it.position }

            onReorder(newItems1, idsList, newPositions, listOf())

        }
    }
    return moveItem
}

private fun moveProductItems(
    newItems: List<ArticleModel>,
    onReorder: (List<dynamic>, List<String>, List<Int>,List<String>) -> Unit
): (Int, Int) -> Unit
{

    var newItems1 = newItems
    val moveItem = { from: Int, to: Int ->
        if (from != to) {
            newItems1 = newItems1.toMutableList().apply {
                add(to, removeAt(from))
            }.mapIndexed { index, item ->
                if ((index in from..to) || (index in to..from)) {
                    item.copy(position = index )
                } else item

            }
            val changedItems = newItems1.filterIndexed { index, item ->
                item.position == index  &&
                        ((index in from..to) || (index in to..from))
            }

            val productIdsList = changedItems.map { it.productId }
            val newPositions = changedItems.map { it.position }
            val idList=changedItems.map { it.id }


            onReorder(newItems1,idList,newPositions, productIdsList, )

        }
    }
    return moveItem
}


@Composable
private fun GridItem(
    model: dynamic,
    isSelected: Boolean,
    hoverIndex:MutableState<Int>,
    index: Int,
    itemsSize: Int,
    onItemClick: (dynamic) -> Unit,
    onDragEnd: (Int, Int) -> Unit,
    onItemDraggedOver: (Int) -> Unit,

)
{
    val isHovered=  remember { mutableStateOf(false) }
    val startDrag= remember { mutableStateOf(-1) }
    val isDragging = remember { mutableStateOf(false) }

    val itemHeight= Dimen.gridItemHeight.value.toInt()+
            Dimen.smallThickness.value.toInt()

    var touchStartY by remember { mutableStateOf(0f) }
    var touchStartTime by remember { mutableStateOf(0L) }

    Column(
        modifier = hoverBackgroundStyle
            .toModifier()
            .position(Position.Relative)
            .fillMaxWidth()
            .minHeight(
                Dimen.gridItemMinHeight
            )
            .scrollBehavior(ScrollBehavior.Smooth)
            .draggable(
              if (
                  model is TemplateCategoryModel
                  || model is ArticleModel || model is ProductItem
              )
                  Draggable.True
                else Draggable.False
            )
            .onDragStart {
                startDrag.value = index
                isDragging.value = true
            }
            .onDragEnter {
                onItemDraggedOver(index)
            }
            .onDragEnd {
                onDragEnd(startDrag.value, hoverIndex.value)
                isDragging.value = false
            }
            .transitionBackground()
            .backgroundColor(
                when {
                    isSelected -> Theme.GrayAlpha12.rgb
                    isHovered.value -> Theme.GrayAlpha08.rgb
                    else -> Theme.Transparent.rgb
                }
            )
            .thenIf(
                isSelected,
                hoverBackgroundStyle
                    .toModifier()
                    .onClick{
                        when(model){
                            is UserModel ->onItemClick(model.id)
                            is TemplateCategoryModel ->onItemClick(model.id)
                            is ProductItem ->onItemClick(model.id)
                            is ArticleModel ->onItemClick(model.id)
                            is SaleItem ->onItemClick(model.id)
                            is PrinterLocation ->onItemClick(model)
                            is MediaItem ->onItemClick(model.id)
                            else ->{}
                        }
                    }
            )
            .thenIf(
                !isSelected,
                hoverBackgroundStyle
                    .toModifier()
                    .rippleEffect{
                        when(model){
                            is UserModel ->onItemClick(model.id)
                            is TemplateCategoryModel ->onItemClick(model.id)
                            is ProductItem ->onItemClick(model.id)
                            is ArticleModel ->onItemClick(model.id)
                            is SaleItem ->onItemClick(model.id)
                            is PrinterLocation ->onItemClick(model)
                            is MediaItem ->onItemClick(model.id)
                            else ->{}
                        }
                    }
            )

            .onMouseOver { isHovered.value=true }
            .onMouseLeave { isHovered.value=false }



    ){
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .cursor(
                    if (model is TemplateCategoryModel || model is ArticleModel)
                        Cursor.Move
                    else Cursor.Pointer
                )
//                .height(
//                    (Dimen.gridItemHeight.value.toInt()
//                            -Dimen.smallThickness.value.toInt()).px
//                )
                .minHeight(
                    (Dimen.gridItemHeight.value.toInt()
                            -Dimen.smallThickness.value.toInt()).px
                ),
            verticalAlignment = Alignment.CenterVertically
        )
        {

            if(model is TemplateCategoryModel || model is ArticleModel){
                Box(
                    modifier = Modifier
                            .width((Dimen.gridHeaderNumberWidth.value.toInt()+
                                        Dimen.mediumIconSize).px)
                            .onTouchStart { event ->
                                if (model is TemplateCategoryModel || model is ArticleModel) {
                                    touchStartY = event.touches[0]?.clientY?.toFloat() ?: 0f
                                    touchStartTime = Date.now().toLong()
                                    startDrag.value = index
                                    isDragging.value = true
                                    event.preventDefault()
                                }
                            }
                            .onTouchMove { event ->
                                if (isDragging.value) {
                                    val currentY = event.touches[0]?.clientY?.toFloat() ?: 0f
                                    val deltaY = currentY - touchStartY
                                    val newIndex = (index + (deltaY / itemHeight).toInt())
                                        .coerceIn(0, itemsSize - 1)
                                    hoverIndex.value = newIndex
                                    onItemDraggedOver(newIndex)
                                    event.preventDefault()
                                }
                            }
                            .onTouchEnd { event ->
                                if (isDragging.value) {
                                    val touchEndTime = Date.now().toLong()
                                    if (touchEndTime - touchStartTime < Constants.touchTime) {
                                        // Short touch, treat as click
                                        when(model) {
                                            is UserModel -> onItemClick(model.id)
                                            is TemplateCategoryModel -> onItemClick(model.id)
                                            is ProductItem -> onItemClick(model.id)
                                            is ArticleModel -> onItemClick(model.id)
                                            is PrinterLocation -> onItemClick(model)
                                            is SaleItem -> onItemClick(model.id)
                                        }
                                    } else { // Long touch, treat as drag end
                                        onDragEnd(startDrag.value, hoverIndex.value)
                                    }
                                    isDragging.value = false
                                    event.preventDefault()
                                }
                            }
                )
                {
                    VectorIcon(
                        modifier = Modifier.align(Alignment.Center),
                        pathData = Res.PathIcon.dots6,
                        width = Dimen.normalIconSize,
                        height = Dimen.normalIconSize ,
                    )
                }



            }else{
                SpanText(
                    text = when(model){
                        is UserModel ->model.code?:""
                        is TemplateCategoryModel ->model.position.toString()
                        is ProductItem ->model.code?:""
                        is SaleItem ->model.acountNum?:""
                        is PrinterLocation ->model.dbIndex.toString()
                        is MediaItem -> extractTime(model.timeInterval?.from?:"")?:""
                        else -> ""
                    },
                    modifier = Modifier
                        .margin(left = Dimen.normalPadding)
                        .cursor(Cursor.Pointer)
                        .width(
                            (Dimen.gridHeaderNumberWidth.value.toInt()+
                                        Dimen.mediumIconSize
                                ).px
                        )
                        .fontWeight(FontWeight.Normal)
                        .fontSize(FontSizes.mediumFontSize)
                        .fontFamily(FontFamily.Jakarta)
                        .color(Theme.Secondary.rgb)
                        .overflowText()
                        .styleModifier{
                            property("display", "block")
                            property("max-height", "1.2em")
                            property("line-height", "1.2em")
                            property("word-break", "break-all")
                        }

                )
                Box(
                    modifier = Modifier
                        .margin(leftRight = Dimen.normalPadding)
                        .width(Dimen.smallThickness)
                )
            }


            SpanText(
                text = when(model){
                    is UserModel ->model.name?:""
                    is TemplateCategoryModel -> model.name?:""
                    is ProductItem ->model.name?:""
                    is ArticleModel ->model.product?.name?:""
                    is SaleItem ->model.name
                    is PrinterLocation ->model.value
                    is MediaItem ->model.name?:""
                    else -> ""
                },
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .textAlign(TextAlign.Start)
                    .fillMaxWidth()
                    .weight(1f)
                    .fontSize(FontSizes.mediumFontSize)
                    .fontFamily(FontFamily.Jakarta)
                    .fontWeight(FontWeight.Normal)
                    .overflowText()
                    .color(Theme.Secondary.rgb)
                    .styleModifier{
                        property("display", "block")
                        property("max-height", "1.2em")
                        property("line-height", "1.2em")
                        property("word-break", "break-all")
                    }

                ,

            )

            Spacer()

        }


        Box(
            modifier = Modifier
                .fillMaxWidth()
                .height(Dimen.smallThickness)
                .backgroundColor(Theme.GrayCD.rgb)
        )




    }



}

