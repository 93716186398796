package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_click_collect.update_properties


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("properties")
data class UpdatedProperties(
    @SerialName("ClickCollectLeadDaysCount")
    val clickCollectLeadDaysCount: Double?=null,
    @SerialName("ClickCollectOrderMax")
    val clickCollectOrderMax: Double?=null,
    @SerialName("ClickCollectOrderMin")
    val clickCollectOrderMin: Double?=null,
    @SerialName("__typename")
    val typeName:String?="",
)