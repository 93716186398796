package org.hs_soft.runmynesto.data.api_datasource.template

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.add_new_category.AddNewCategory
import org.hs_soft.runmynesto.domain.repository.network_datasource.AddCategoryRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class AddCategoryRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
) : AddCategoryRepository {

    override suspend fun addCategory(name:String): AddNewCategory {

        val abortController = requestManager.createController()

        val query = """
            mutation createCategory(${'$'}required: CategoryReqFields!, 
            ${'$'}fields: CategoryFields, ${'$'}copyId: ID) {
              cashAssist {
                createMutation: createCategory(required: ${'$'}required,
                 fields: ${'$'}fields, copyId: ${'$'}copyId) {
                  id
                  name
                }
              }
            }
        """.trimIndent()

        val variables = buildJsonObject {
            putJsonObject("required") {
                put("name", name)
//            put("position", position)
            }
            putJsonObject("fields") {
                put("isActive", true)
            }
        }

        val graphQLQuery = buildJsonObject {
            put("query", query)
            put("variables", variables)
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<AddNewCategory>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }

}

