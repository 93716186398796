package org.hs_soft.runmynesto.data.api_datasource.dashboard

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.dashboard.init_shop_statics.InitShopStaticsModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.InitShopStaticsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils


class InitShopStaticsRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): InitShopStaticsRepository {

    override suspend fun initShopStatics(
        cashRegisterIds: List<String>,
        from: String,
        to: String,
    ):InitShopStaticsModel
    {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """
                   query initShopStatistics(${'$'}params: StatisticShopParams!) {
                     cashAssist {
                       initShopStatistics(params: ${'$'}params)
                       __typename
                     }
                   }

                   
                """.trimIndent()
            )


            putJsonObject("variables") {
                putJsonObject("params") {
                    put("cashRegisterIds",
                        Json.encodeToJsonElement(cashRegisterIds as List<String>)
                    )
                    put("from",from)
                    put("to",to)
                    put("groupBy","SHOP")
                }
            }

        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }

    }



}

