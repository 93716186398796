package org.hs_soft.runmynesto.pages.components.dialog.dialog

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.delay
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.menu_card_import.menu_card_model.MenuCardModel
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.multiple
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import org.w3c.files.File


@Composable
fun MenuCardAIDialog(
    windowSizeMode: WindowSizeModeEnum,
    menuCardAIGenerated:MenuCardModel,
    menuCardPDFFilesUploaded: Int,
    onAddPDFFile: (Int) -> Unit,
    loading:Boolean,
    onBackgroundClick: () -> Unit,
    onCancelGenerateMenuCardClick: () -> Unit,
    onGenerateMenuCardClick: (List<File>) -> Unit,
)
{
    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                onBackgroundClick()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(2f)
    ){

        Column(
            modifier = Modifier
                .onClick {
                        event: SyntheticMouseEvent -> event.stopPropagation()
                }
                .align(Alignment.Center)
                .padding(Dimen.mediumPadding)
                .width(Dimen.imageAIDialogWidth)
                .height(Dimen.imageAIDialogHeight)
                .backgroundColor(Theme.White.rgb)
                .zIndex(1)
                .boxShadow(
                    color = Theme.GrayCD.rgb,
                    blurRadius = Dimen.smallBlurRadius,
                )
                .borderRadius(r= Dimen.lowBorderRadius),
            horizontalAlignment = Alignment.CenterHorizontally
        ){

            Header()

            GenerateMenuCard(
                onGenerateMenuCardClick = {
                    onGenerateMenuCardClick(it)
                },
                menuCardPDFFilesUploaded = menuCardPDFFilesUploaded,
                onAddPDFFile = {
                    onAddPDFFile(it)
                }
            )

            if (loading){
                LoadingComponent(loading=loading, menuCardPDFFilesUploaded = menuCardPDFFilesUploaded)
            }


            Spacer()

            CustomButton(
                windowSizeMode = windowSizeMode,
                onButtonClick ={
                    onCancelGenerateMenuCardClick()
                },
                shadowColor =  Theme.DarkBlue.rgb,
                borderColor = Theme.Blue.rgb,
                defaultTextColor = Theme.Blue.rgb,
                backgroundColor = Theme.White.rgb,
                hoveredBackgroundColor = Theme.GrayAlpha12.rgb,
                clickableTextColor = Theme.Blue.rgb,
                title = Strings.cancel,
            )
        }


        if (loading){
            LoadingProgress(
                visibility = loading,
                modifier = Modifier
                    .zIndex(2)
                    .borderRadius(
                        Dimen.lowBorderRadius
                    )
                , backgroundColor = Theme.Transparent.rgb
            )
        }


    }



}


@Composable
private fun LoadingComponent(loading: Boolean, menuCardPDFFilesUploaded: Int) {
    var currentStage by remember { mutableStateOf(0) }
    val stageMessages = listOf(
        Strings.uploadFilesProcess,
        Strings.generateMenuCardProcess,
        Strings.clearMenuCardProcess,
        Strings.prepareResultProcess
    )
    val stageDelays = listOf(
        if (menuCardPDFFilesUploaded != 0) Constants.delayDefaultProcess * menuCardPDFFilesUploaded else Constants.delayDefaultProcess / 2,
        Constants.delayGenerateMenuCard * (menuCardPDFFilesUploaded + 1),
        Constants.delayDefaultProcess,
        Constants.timeout
    )

    LaunchedEffect(currentStage, loading) {
        while (loading && currentStage < stageMessages.size) {
            delay(stageDelays[currentStage])
            currentStage = (currentStage + 1) % (stageMessages.size + 1)
        }
    }

    if (loading && currentStage < stageMessages.size) {
        val progressMessage = "${currentStage}/${stageMessages.size} ${Strings.menuCardProgressMessage}"
        Column(
            modifier = Modifier.margin(top = Dimen.normalPadding),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SpanText(
                text = stageMessages[currentStage],
                modifier = Modifier
                    .fontSize(FontSizes.mediumFontSize)
                    .fontFamily(FontFamily.Jakarta)
                    .fontWeight(FontWeight.Medium)
                    .color(Theme.DarkBlue.rgb)
                    .padding(leftRight = Dimen.smallPadding)
            )
            SpanText(
                text = progressMessage,
                modifier = Modifier
                    .fontSize(FontSizes.mediumFontSize)
                    .fontFamily(FontFamily.Jakarta)
                    .fontWeight(FontWeight.Medium)
                    .color(Theme.DarkBlue.rgb)
                    .padding(top = Dimen.smallPadding)
            )
        }
    }
}


@Composable
fun GenerateMenuCard(
    onGenerateMenuCardClick: (List<File>) -> Unit,
    menuCardPDFFilesUploaded: Int,
    onAddPDFFile: (Int) -> Unit
)
{
    var selectedFiles by remember { mutableStateOf<List<File>>(emptyList()) }

    Column {
        var inputElement by remember { mutableStateOf<HTMLInputElement?>(null) }
        val fileCountText = if (selectedFiles.isEmpty()) Strings.menuCardSelectFilesMessages else "${selectedFiles.size} ${Strings.filesChosen}"

        Div {
            Text(fileCountText)
        }

        Input(type = InputType.File, attrs = {
            ref { element ->
                inputElement = element
                onDispose { }
            }
            multiple()
            onInput {
                val input = it.target as HTMLInputElement
                val files = input.files
                val fileList = selectedFiles.toMutableList()
                if (files != null) {
                    for (i in 0 until files.length) {
                        val file = files.item(i)
                        if (file != null) {
                            val extension = file.name.substringAfterLast('.', "")
                            if (extension == "pdf") {
                                onAddPDFFile(menuCardPDFFilesUploaded + 1)
                            }
                            fileList.add(file)
                        }
                    }
                }
                selectedFiles = fileList
                input.value = "" // Clear the input after adding files
            }
            margin(top = Dimen.smallPadding)
        })

        selectedFiles.forEachIndexed { index, file ->
            Div(attrs = {
                style {
                    marginTop(Dimen.semiNormalPadding)
                    marginBottom(Dimen.semiNormalPadding) // Increase space between each file
                }
            }) {
                Text(file.name)
                SpanText(" ")
                Button(attrs = {
                    style { marginLeft(Dimen.normalPadding) }
                    onClick {
                        val extension = file.name.substringAfterLast('.', "")
                        if (extension == "pdf") {
                            onAddPDFFile(menuCardPDFFilesUploaded - 1)
                        }
                        selectedFiles = selectedFiles.toMutableList().apply { removeAt(index) }
                    }
                }) {
                    Text(Strings.remove)
                }
            }
        }

        Div(attrs = {
            style { marginTop(Dimen.largePadding) }
        }) {
            Button(attrs = {
                onClick { onGenerateMenuCardClick(selectedFiles) }
                if (selectedFiles.isEmpty()) {
                    attr("disabled", "true")
                }
            }) {
                Text(Strings.generate)
            }
        }
    }
}


@Composable
private fun Header() {
    SpanText(
        text = Strings.newMenuCard,
        modifier = Modifier
            .width(Dimen.selectRectPhoneWidth)
            .cursor(Cursor.Pointer)
            .textAlign(TextAlign.Start)
            .fontSize(FontSizes.mediumFontSize)
            .fontFamily(FontFamily.Jakarta)
            .fontWeight(FontWeight.Medium)
            .color(Theme.Primary.rgb)
    )

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .margin(top=Dimen.normalPadding)
            .height(Dimen.smallThickness)
            .backgroundColor(Theme.GrayCD.rgb)
    )
}