package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.calendar_filter_dialog.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import kotlinx.datetime.internal.JSJoda.Clock
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.YearModel
import org.hs_soft.runmynesto.domain.util.getCurrentMonth

@Composable
fun ClosingReportDatePicker(
    modifier: Modifier = Modifier,
    selectedCalendarHeaderYear:Int,
    date: YearModel,
    showFirstCalendarType: Boolean,
    selectedDay: MonthModel?,
    selectedFrom:String,
    selectedTo:String,
    onSelectDay: (MonthModel) -> Unit,
    filterDate:(String,String)->Unit,
    closeCalendarDialog:()->Unit,
    setNewCalendarHeaderYear:(Int)->Unit,
)
{

    val currentMonth = remember {
        mutableStateOf(
            date.monthsOfYear.firstOrNull { monthModel ->
                monthModel.monthNumber == selectedDay?.monthNumber
            } ?: date.monthsOfYear.firstOrNull { monthModel ->
                monthModel.monthNumber == getCurrentMonth(Clock.systemDefaultZone())
            }?:date.monthsOfYear.first()
        )
    }
    val currentIndex= remember{ mutableStateOf(0) }


    Column(
        modifier = modifier
            .onClick {
                    event: SyntheticMouseEvent -> event.stopPropagation()
            }
            .padding(Dimen.mediumPadding)
            .width(Dimen.datePickerWidth)
            .height(Dimen.datePickerHeight)
            .backgroundColor(Theme.White.rgb)
            .zIndex(1),
        horizontalAlignment = Alignment.CenterHorizontally
    )
    {


        if (showFirstCalendarType){

            TopCalendarHeaderSelectMonth(
                currentMonth=currentMonth,
                currentIndex=currentIndex,
                date=date,
                closeCalendarDialog = {
                    closeCalendarDialog()
                }
            )

            CalendarDays(
                currentMonth=currentMonth,
                onSelectDay = {
                    onSelectDay(it)
                },
                selectedDay =selectedDay
            )

        }
        else{

            SelectYearTopCalendarHeader(
                selectedCalendarHeaderYear=selectedCalendarHeaderYear,
                setNewCalendarHeaderYear = {
                    setNewCalendarHeaderYear(it)
                },
                onCloseFilterClick = {
                    closeCalendarDialog()
                }
            )

            CalendarYearAndMonthFilter(
                selectedFrom = selectedFrom,
                selectedYear = selectedCalendarHeaderYear,
                selectedTo = selectedTo,
                filterDate = { from:String, to:String->
                    filterDate(
                        from,
                        to,
                    )
                },
            )
        }


    }




}

