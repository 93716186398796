package org.hs_soft.runmynesto.data.api_datasource.user

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.user.add_new_user.AddNewUser
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.AddUserRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class AddUserRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): AddUserRepository {

    override suspend fun addUser(name: String, code: String): AddNewUser {

        val abortController = requestManager.createController()

        val query = """
            mutation createUser(${'$'}required: UserReqFields!, ${'$'}fields: UserFields) {
              cashAssist {
                createMutation: createUser(required: ${'$'}required, fields: ${'$'}fields) {
                  id
                  name
                }
              }
            }
        """.trimIndent()

        val variables = buildJsonObject {
            putJsonObject("required") {
                put("name", name)
                put("code", code)
            }
            putJsonObject("fields") {
                put("isActive", true)
            }
        }

        val graphQLQuery = buildJsonObject {
            put("query", query)
            put("variables", variables)
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<AddNewUser>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }
}

