package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Cashbook(
    @SerialName("description")
    val description: String?,
    @SerialName("have")
    val have: Float?,
    @SerialName("should")
    val should: Float?,
    @SerialName("__typename")
    val typename: String?
)