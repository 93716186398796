package org.hs_soft.runmynesto.domain.usecase.login

import org.hs_soft.runmynesto.domain.repository.network_datasource.login.IsLoggedInRepository


class IsLoggedInUseCase(
    private val repository: IsLoggedInRepository
) {
    suspend operator fun invoke():Boolean {
        return repository.isLoggedIn()
    }
}