package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi

import org.hs_soft.runmynesto.domain.model.home.customer_card.CustomerCardHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.TransactionColumnsEnum
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum

sealed class CustomerCardEvent
{

    data class SetGridCustomerFilterType(
        val type:GridItemsFilterEnum
    ):CustomerCardEvent()

    data class ToggleTransactionColumn(
        val columns:MutableSet<TransactionColumnsEnum>
    ):CustomerCardEvent()

    data class SetGridCustomerFilterVisibility(
        val status:Boolean
    ):CustomerCardEvent()

    data class OnSelectPeriodDays(
        val period:PeriodDaysEnum
    ):CustomerCardEvent()

    data object ShowTransactionColumnsDialog:CustomerCardEvent()

    data object CloseTransactionColumnsDialog:CustomerCardEvent()

    data object ShowAddCustomerCardDialog:CustomerCardEvent()

    data object CloseAddCustomerCardDialog:CustomerCardEvent()

    data class CreateLoyaltyCard(
        val validPhoneNumber: String,
        val isVirtual: Boolean?=null,
        val name: String?=null,
        val isActive: Boolean?=null
    ):CustomerCardEvent()


    data class GetLoyaltyCards(
        val searchValue:String="",
        val isActive: Boolean?=null,
    ):CustomerCardEvent()

    data object FilterGridCustomerCards:CustomerCardEvent()

    data class FilterLoyaltyCards(
        val cardId:String?,
        val from:String?,
        val to:String?,
    ):CustomerCardEvent()

    data class UpdateRangeLoyaltyCard(
        val from:Int,
        val to:Int,
        val balance:Int?=null,
        val description:String?=null,
        val name:String?=null
    ):CustomerCardEvent()


    data class ResetRangeLoyaltyCard(
        val from:Int,
        val to:Int,
    ):CustomerCardEvent()

    data class CreateCustomerCard(
        val type:Int,
        val from:Int,
        val to:Int
    ):CustomerCardEvent()

    data class UpdateLoyalCards(
        val dataTitle:String,
        val dataValue:dynamic,
    ):CustomerCardEvent()

    data class OnSearchInLoyaltyCards(
        val searchValue: String
    ):CustomerCardEvent()

    data class OnCustomerTabClick(
        val tab:CustomerCardHeaderEnum
    ):CustomerCardEvent()

    data class OnSelectLoyaltyCard(
        val id:String,
    ):CustomerCardEvent()

    data object GetCreditClient:CustomerCardEvent()


    data object OnChangePageClick: CustomerCardEvent()
}