package org.hs_soft.runmynesto.domain.util.helper

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.launch
import kotlin.coroutines.cancellation.CancellationException

open class BaseViewModel {
    private var job: Job = SupervisorJob()
    private var scope = CoroutineScope(job + Dispatchers.Default)

    fun launch(block: suspend CoroutineScope.() -> Unit): Job {
        return scope.launch {
            try {
                block()
            } catch (e: CancellationException) {
                throw e
            }
        }
    }

    open fun clear() {
        job.cancel()
        job = SupervisorJob()
    }
}