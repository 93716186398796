package org.hs_soft.runmynesto.pages.components.grid_function

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.AddItem
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.hs_soft.runmynesto.pages.home.sub_page.user.component.ItemSeparator
import org.jetbrains.compose.web.css.LineStyle


@Composable
fun GridHeader(
    title: String,
    infoTitle: String,
    titleDescending:Boolean,
    numberDescending:Boolean,
    showTitleSortIcon:Boolean,
    showNumberSortIcon:Boolean,
    onTitleSortClick: () -> Unit,
    onNumberSortClick: () -> Unit,
    showAddItemIcon:Boolean=false,
    onAddItemClick:()->Unit={},
    showSeparator: Boolean,
)
{
    Row(
        modifier = Modifier
            .fillMaxWidth()
//            .height(Dimen.gridHeaderHeight)
            .borderBottom(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
            .backgroundColor(Theme.GrayAlpha04.rgb)
            .padding(leftRight = Dimen.normalPadding, topBottom = Dimen.smallPadding),
        verticalAlignment = Alignment.CenterVertically
    )
    {
        Row(
            modifier = Modifier
                .onClick {
                    onNumberSortClick()
                 }
            , verticalAlignment = Alignment.CenterVertically
        )
        {
            SpanText(
                text = infoTitle,
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .width(
                       if(infoTitle.isNotEmpty()) Dimen.gridHeaderNumberWidth
                        else Dimen.extraExtraLargePadding
                    )
                    .fontSize(FontSizes.semiMediumFontSize)
                    .lineHeight(FontLineHeight.lineMedium)
                    .letterSpacing(LetterSpacing.semiMedium)
                    .fontFamily(FontFamily.Montserrat)
                    .color(Theme.Primary.rgb)
            )

            VectorIcon(
                modifier = Modifier.padding(Dimen.smallPadding),
                pathData = if(numberDescending)
                    Res.PathIcon.sortDesc
                else Res.PathIcon.sortAsc
                ,
                height = Dimen.mediumIconSize,
                width = Dimen.mediumIconSize,
                color = Theme.GrayAlpha56.hex,
                visibilityStatus = showNumberSortIcon
            )

        }


        ItemSeparator(
            color = if(showSeparator) Theme.GrayCD.rgb
            else Theme.Transparent.rgb
        )

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .onClick {
                    onTitleSortClick()
                 }
            , verticalAlignment = Alignment.CenterVertically
        )
        {
            SpanText(
                text = title,
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .fontSize(FontSizes.mediumFontSize)
                    .lineHeight(FontLineHeight.lineMedium)
                    .letterSpacing(LetterSpacing.semiMedium)
                    .fontFamily(FontFamily.Montserrat)
                    .color(Theme.Primary.rgb)
            )

            VectorIcon(
                modifier = Modifier.padding(Dimen.smallPadding),
                pathData = if(titleDescending)
                    Res.PathIcon.sortDesc
                else Res.PathIcon.sortAsc
                ,
                height = Dimen.mediumIconSize,
                width = Dimen.mediumIconSize,
                color = Theme.GrayAlpha56.hex,
                visibilityStatus = showTitleSortIcon,
            )

            if (showAddItemIcon){
                Spacer()
                AddItem {
                    onAddItemClick()

                }

            }






        }







    }



}