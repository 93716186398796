package org.hs_soft.runmynesto.domain.usecase.product

import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductListModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.ProductCategoriesRepository

class ProductCategoriesUseCase(
    private val repository: ProductCategoriesRepository
) {
    suspend operator fun invoke(
        searchQuery:String="",
        isActive:Boolean?=true,
    ): ProductListModel {
        return repository.getProductCategories(
             searchQuery=searchQuery,
            isActive=isActive
        )
    }


}