package org.hs_soft.runmynesto.domain.usecase.home

import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.CheckoutModel
import org.hs_soft.runmynesto.domain.repository.local_datasource.home.SaveCheckOutRepository

class SaveCheckOutUseCase(
    private val repository: SaveCheckOutRepository
) {
    suspend operator fun invoke(model: CheckoutModel) {
        return repository.saveCashList(model)
    }
}