package org.hs_soft.runmynesto.pages.components.dialog.dialog

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.model.home.home.CompaniesInfoEnum
import org.hs_soft.runmynesto.domain.model.home.home.hssoft_apps_list.HSWebApps
import org.hs_soft.runmynesto.domain.util.Constants
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position

@Composable
fun CompaniesDialog(
    onItemClick: (String) -> Unit,
    listOfHSSoftApps: List<HSWebApps?>?,
)
{
    Column(
        modifier = Modifier
            .padding(Dimen.smallPadding)
            .zIndex(Constants.ZIndex.largeZIndex)
            .backgroundColor(Theme.White.rgb)
            .border(
                width = Dimen.smallThickness,
                color = Theme.GrayCD.rgb,
                style = LineStyle.Solid
            )
    )
    {
        CompaniesInfoEnum.entries.chunked(3)
                .forEachIndexed { rowIndex, rowItems ->
            Row()
            {
                rowItems.forEachIndexed { colIndex, model ->
                    val (title1, title2) = splitTitle(model.title)
                    CompanyItem(
                        img =model.img ,
                        active = listOfHSSoftApps?.any {
                           it?.isAvailable==true &&
                                   it.appType?.lowercase()==model.title.lowercase()
                        }?:false,
                        title1 = title1,
                        title2 = title2 ,
                        onItemClick = {
                            onItemClick(model.url)

                        }
                    )

                }
            }

        }


    }
}

fun splitTitle(title: String): Pair<String, String> {
    val parts = title.split("_", limit = 2)
    return if (parts.size == 2) {
        parts[0] to parts[1]
    } else {
        title to ""
    }
}

@Composable
fun CompanyItem(
    title1:String,
    title2: String="",
    img:String,
    active:Boolean,
    onItemClick: () -> Unit,
)
{
    val isHovered= remember { mutableStateOf(false) }
    Column(
        modifier = Modifier
            .cursor(
                if (active)
                    Cursor.Pointer
                else Cursor.NotAllowed
            )
            .position(Position.Relative)
            .borderRadius(Dimen.mediumBorderRadius)
            .onMouseLeave { isHovered.value=false}
            .onMouseOver { isHovered.value=true }
            .backgroundColor(
                if(active){
                    if (isHovered.value) Theme.ExtraLightBlue.rgb
                    else Theme.Transparent.rgb
                }else{
                    Theme.Transparent.rgb
                }

            )
            .transitionBackground()
            .width(Dimen.companyItemWidth)
            .height(Dimen.companyItemHeight)
            .thenIf(
                condition = active,
                other = Modifier.rippleEffect {
                    onItemClick()
                }
            ),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        Image(
            src =img ,
            modifier =Modifier
                .width(Dimen.companyImgWidth)
                .height(Dimen.companyImgHeight)
        )
        Row()
        {
            SpanText(
                text = title1,
                modifier = Modifier
                    .fontSize(FontSizes.mediumFontSize)
                    .color(Theme.Secondary.rgb)
                    .fontFamily(FontFamily.Montserrat)
                    .fontWeight(FontWeight.SemiBold)
            )
            SpanText(
                text = title2,
                modifier = Modifier
                    .fontSize(FontSizes.mediumFontSize)
                    .color(Theme.Secondary.rgb)
                    .fontFamily(FontFamily.Montserrat)
                    .fontWeight(FontWeight.Normal)
            )
        }


    }


}