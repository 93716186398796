package org.hs_soft.runmynesto.pages.login

import org.hs_soft.runmynesto.domain.util.ApiUtils


data class LoginState(
    val userName:String ="",//   ApiUtils.Cognito.USER_NAME,
    val password:String ="",// ApiUtils.Cognito.PASSWORD,
    val errorMsg:String="",
    val isFirstSignInStepPassed:Boolean=false,
    val isHoveredOnButton:Boolean=false,
    val isLoading:Boolean=false,
    val isCheckingLoggedInLoading:Boolean=false,
    val isLoggedIn:Boolean=false,
    val mfaCode:String="",
)