package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general


import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.UpdateProductPriceRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateProductPriceRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateProductPriceRepository {

    override suspend fun updateProductPrice(
        productId: String,
        dataTitle: String,
        dataValue: List<Double?>
    ) {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            mutation updateProduct_product(${'$'}id: ID!, ${'$'}fields: ProductFields) {
              cashAssist {
                updateProduct(id: ${'$'}id, fields: ${'$'}fields) {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.mod_ver}
                  ...productmain
                  category {
                    ${ApiUtils.Field.id}
                    ${ApiUtils.Field.name}
                    ${ApiUtils.Field.__typename}
                  }
                  ${ApiUtils.Field.__typename}
                }
              }
            }
            
            fragment productmain on Product {
              ${ApiUtils.Field.code}
              ${ApiUtils.Field.name}
              ${ApiUtils.Field.isActive}
              ${ApiUtils.Field.altName}
              ${ApiUtils.Field.categoryId}
              ${ApiUtils.Field.nestedCategoryId}
              ${ApiUtils.Field.salesGroupId}
              ${ApiUtils.Field.unitSellCode}
              ${ApiUtils.Field.isNetto}
              ${ApiUtils.Field.isNettoForUser}
              ${ApiUtils.Field.isEmployeeArticle}
              ${ApiUtils.Field.price}
              ${ApiUtils.Field.priceInCafe}
              ${ApiUtils.Field.codeMWSt}
              ${ApiUtils.Field.codeMWSt2}
              ${ApiUtils.Field.__typename}
            }
            
        """.trimIndent())



            putJsonObject("variables")
            {
                put("id", productId)
                putJsonObject("fields") {
                    put(dataTitle, Json.encodeToJsonElement(dataValue))
                }
            }
        }

        apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        ) ?: throw Exception(Strings.timeoutErr)

    }

}
