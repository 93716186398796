package org.hs_soft.runmynesto.pages.home.sub_page.user.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.jetbrains.compose.web.css.CSSColorValue

@Composable
fun ItemSeparator(
    modifier: Modifier=Modifier,
    color:CSSColorValue= Theme.GrayCD.rgb
)
{
    Box(
        modifier = modifier
            .width(Dimen.smallThickness)
            .height(Dimen.itemSeparatorHeight)
            .backgroundColor(color)
            .margin(leftRight = Dimen.normalPadding)
    )

}