package org.hs_soft.runmynesto.domain.usecase.customer_card

import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail.LoyaltyCardDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.CreateLoyaltyCardRepository

class CreateLoyaltyCardUseCase(
    private val repository: CreateLoyaltyCardRepository
)
{
    suspend operator fun invoke(
        validPhoneNumber: String,
        isVirtual: Boolean?=null,
        name: String?=null,
        isActive: Boolean?=null
    ): LoyaltyCardDetailModel {
        return repository.createLoyaltyCard(
            validPhoneNumber=validPhoneNumber,
            isVirtual=isVirtual,
            name=name,
            isActive=isActive,
        )
    }

}