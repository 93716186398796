package org.hs_soft.runmynesto.domain.usecase.dashboard

import org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config.DashboardConfigModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.DashboardConfigRepository

class DashboardConfigUseCase(
    private val repository: DashboardConfigRepository
) {
    suspend operator fun invoke(): DashboardConfigModel {
        return repository.getDashboardConfig()
    }
}