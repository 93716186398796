package org.hs_soft.runmynesto.data.local_datasource.local_storage.product

import kotlinx.browser.window
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.AddProductCategoryToDBRepository
import org.hs_soft.runmynesto.domain.util.IdUtils

class AddProductCategoryToDBRepositoryImpl: AddProductCategoryToDBRepository
{

    override fun addItem(newItem: ProductItem) {
        val jsonString = window.localStorage.getItem(IdUtils.productsDB)
        val items: MutableList<ProductItem> = if (jsonString != null) {
            Json.decodeFromString(jsonString)
        } else {
            mutableListOf()
        }
        items.add(newItem)

        val updatedJsonString = Json.encodeToString(items)
        window.localStorage.setItem(IdUtils.productsDB, updatedJsonString)

    }

}