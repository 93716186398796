package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.calendar_filter_dialog.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.component.CalendarDayNumbers
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.component.CalendarWeekRow

@Composable
fun CalendarDays(
    currentMonth: MutableState<MonthModel>,
    onSelectDay: (MonthModel) -> Unit,
    selectedDay: MonthModel?
) {
    Column(
        modifier= Modifier
            .width(Dimen.datePickerWidth),
        horizontalAlignment = Alignment.CenterHorizontally
    )
    {
        CalendarWeekRow(
            currentMonth=currentMonth
        )

        CalendarDayNumbers(
            currentMonth=currentMonth.value,
            onDayClick = {
                onSelectDay(it)
            },
            selectedDay =selectedDay
        )

    }

}
