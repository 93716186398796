package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_restaurant


import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.restaurant_data.RestaurantDataModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.ProductRestaurantRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class ProductRestaurantRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): ProductRestaurantRepository {

    override suspend fun productRestaurant(id: String): RestaurantDataModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                query getProduct_Restaurant(${'$'}id: ID!) {
                  cashAssist {
                    getProduct(id: ${'$'}id) {
                      ...productRequired
                      ...restaurant
                      ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                  }
                }
        
                fragment productRequired on Product {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.mod_ver}
                  ${ApiUtils.Field.name}
                  ${ApiUtils.Field.checkOutCommandId} 
                  ${ApiUtils.Field.__typename}
                }
        
                fragment restaurant on Product {
                  ${ApiUtils.Field.isPrintOnlyInCafe}
                  ${ApiUtils.Field.isAdditionRequired}
                  ${ApiUtils.Field.isServiceCall}
                  ${ApiUtils.Field.printerLocations}
                  additions {
                    ${ApiUtils.Field.id}
                    ${ApiUtils.Field.mod_ver}
                    ${ApiUtils.Field.productAddionId}
                    ${ApiUtils.Field.__typename}
                  }
                  ${ApiUtils.Field.__typename}
                }
            """.trimIndent())

            putJsonObject("variables") {
                put("id", id)
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<RestaurantDataModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }

}

