package org.hs_soft.runmynesto.domain.usecase.home

import org.hs_soft.runmynesto.domain.repository.network_datasource.home.SignOutRepository

class SignOutUseCase(
    private val repository: SignOutRepository
) {
   operator fun invoke(
        onSuccess: () -> Unit,
        onError: (String) -> Unit
    ) {
         repository.signOut(
            onSuccess={
                onSuccess()
            },
            onError={
                onError(it)
            }
        )
    }
}