package org.hs_soft.runmynesto.domain.model.home.home.user_profile_info


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Data(
    @SerialName("getUserProfileData")
    val getUserProfileData: GetUserProfileData?
)