package org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi

import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.StatisticHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.ABCStatisticGroupByEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.abc_statistic_data.ABCStatisticItem
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data.BillSearchStatisticDataItem
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenXXX
import org.hs_soft.runmynesto.domain.util.Constants

data class StatisticState(
    val windowSizeMode: WindowSizeModeEnum=WindowSizeModeEnum.WINDOWS,
    val selectedTab: StatisticHeaderEnum =StatisticHeaderEnum.ABC_STATISTIC,
    val loadingProgress:Boolean=false,
    val selectedCalendarHeaderYear:Int= Constants.closingReportsCalendarEndYear,
    val showReportsTableAndDevices:Boolean=true,
    val showDaysFilterDialog:Boolean=false,
    val showCalendarFilterDialog:Boolean=false,
    val selectedFromDate:String?=null,
    val selectedToDate:String?=null,
    val selectedDate: MonthModel?=null,
    val selectedPeriodType: PeriodDaysEnum = PeriodDaysEnum.TODAY,
    val billSearchStatisticData:List<BillSearchStatisticDataItem> ?=null,
    val abcStatisticData:List<ABCStatisticItem>?=null,
    val selectedGroupBy: ABCStatisticGroupByEnum=ABCStatisticGroupByEnum.ARTICLE,
    val isDevicesExpandAll:Boolean=false,
    val cashRegisters:List<ChildrenXXX> ?=null,
    val cashList:List<Children>?=null,
    val selectedCashRegisterIds:List<String>?=null,
    val showCheckoutDialog:Boolean=false,
    val selectedBillSearchStatisticItem:BillSearchStatisticDataItem?=null,
)