package org.hs_soft.runmynesto.domain.usecase.options.menu_card_import

import org.hs_soft.runmynesto.domain.repository.network_datasource.options.menu_card_import.GetMenuCardRepository
import org.w3c.files.File

class GetMenuCardUseCase
    (private val repository: GetMenuCardRepository) {
    suspend operator fun invoke(files: List<File>): String {
        return repository.getMenuCard(files = files)
    }


}

