package org.hs_soft.runmynesto.pages.components.grid_function

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.util.Constants
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnitLength
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.overflowY
import org.jetbrains.compose.web.css.vh

@Composable
fun GridFunction(
    modifier: Modifier = Modifier,
    title: String,
    showFooter:Boolean=true,
    showAddItemIcon:Boolean=false,
    infoTitle: String,
    footerTitle: String,
    showHeaderSeparator:Boolean=true,
    titleDescending: Boolean=false,
    numberDescending: Boolean=false,
    showNumberSortIcon: Boolean=false,
    showTitleSortIcon: Boolean=false,
    width: CSSSizeValue<out CSSUnitLength> = Dimen.gridWidth,
    contentHeight: Int= Constants.Responsive.gridContentHeight,
    infoList: List<dynamic>,
    onTitleSortClick: () -> Unit={},
    onNumberSortClick: () -> Unit = {},
    onAddItemClick:()->Unit={},
    refreshList: () -> Unit={},
    content: @Composable () -> Unit,
)
{

    Column(
        modifier = modifier
            .styleModifier {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Column)
            }
            .fillMaxHeight()
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width= Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
            .width(width)
    )
    {

        GridHeader(
            title=title,
            infoTitle=infoTitle,
            showSeparator=showHeaderSeparator,
            titleDescending = titleDescending,
            numberDescending = numberDescending,
            showNumberSortIcon = showNumberSortIcon,
            showTitleSortIcon = showTitleSortIcon,
            showAddItemIcon = showAddItemIcon,
            onAddItemClick = {
                onAddItemClick()
            },
            onTitleSortClick={
                onTitleSortClick()
            },
            onNumberSortClick={
                onNumberSortClick()
            },
        )


        Box(
            modifier=
              VerticalScrollBehavior
                  .toModifier()
                  .height(contentHeight.vh)
                  .styleModifier {
                      flexGrow(1)
                      overflowY("auto")
                  }
                .fillMaxWidth()

        ) {
            content()
        }


        Spacer()




        if (showFooter){
            GridFooter(
                title =footerTitle,
                width = width,
                dataSize = infoList.size,
                onRefreshClick = {
                    refreshList()
                }
            )
        }


    }



}





