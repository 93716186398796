package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_click_collect.update_properties


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Data(
    @SerialName("cashAssist")
    val cashAssist: CashAssist
)