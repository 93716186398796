package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model.ClosingReportItem
import org.hs_soft.runmynesto.domain.util.extractDate
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.hs_soft.runmynesto.pages.components.TableItemRow


@Composable
fun ReportsTableItem(
    model: ClosingReportItem,
    onItemClick: (String) -> Unit,
    isSelected: Boolean,
)
{

    TableItemRow(
        id = model.id?:"",
        isSelected=isSelected,
        onItemClick = {
            onItemClick(it)
        }
    ){
        HeaderTitle(
            title = extractDate(model.dateTime?:""),
            width = Dimen.headerSmallWidth,
            fontWeight = FontWeight.Normal,
        )
        HeaderTitle(
            title = extractDate(model.dateTime?:""),
            width = Dimen.headerSmallWidth,
            fontWeight = FontWeight.Normal,
        )
        HeaderTitle(
            title =model.number.toString() ,
            width = Dimen.headerSmallWidth,
            fontWeight = FontWeight.Normal,
            arrangement = Arrangement.End
        )
        HeaderTitle(
            title =model.cashRegister?.name?:"",
            width = Dimen.headerExtraLargeWidth,
            fontWeight = FontWeight.Normal,
        )

        Spacer()

        HeaderTitle(
            title = formatToTwoDecimals(model.total) ,
            arrangement = Arrangement.End
        )
    }



}

