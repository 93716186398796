package org.hs_soft.runmynesto.domain.model.home.closing_reports.print_closing_reports_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Reports(
    @SerialName("getClosingsReport")
    val getClosingsReport: GetClosingsReport?,
    @SerialName("__typename")
    val typename: String?
)