package org.hs_soft.runmynesto.pages.home.mvi

import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.home.hssoft_apps_list.HSWebApps
import org.hs_soft.runmynesto.domain.model.home.home.user_profile_info.GetUserProfileData
import org.hs_soft.runmynesto.domain.model.menu.MenuItemsEnum
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.px

data class HomeState(
    val activePage: MenuItemsEnum = MenuItemsEnum.PRODUCT,
    val idToken:String?=null,
    val listOfHSSortsApps:List<HSWebApps?> ?=null,
    val isLoggedIn:Boolean=true,
    val showSettingDialog:Boolean=false,
    val showCompaniesDialog:Boolean=false,
    val isMenuExpandedHorizontally:Boolean=false,
    val profileInfo: GetUserProfileData? = null,
    val username: String = "",
    val password: String = "",
    val isLoading: Boolean = false,
    val errorMessage: String? = null,
    val loginSuccessful: Boolean = false,
    val showSearchBarInMobileMode:Boolean=false,
    val windowSizeMode: WindowSizeModeEnum = WindowSizeModeEnum.WINDOWS,
    val scrollXValue:CSSSizeValue<CSSUnit.px> = 0.px,
)