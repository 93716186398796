package org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general

import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.GetDishPictureRepository

class GetDishPictureUseCase
    (private val repository: GetDishPictureRepository) {
    suspend operator fun invoke(description:String): String {
        return repository.getDishPicture(description = description)
    }


}
