package org.hs_soft.runmynesto.pages.login

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.hs_soft.runmynesto.domain.usecase.login.IsLoggedInUseCase
import org.hs_soft.runmynesto.domain.usecase.login.SignInUseCase

class LoginViewModel(
    private val signInUseCase: SignInUseCase,
    private val isLoggedInUseCase: IsLoggedInUseCase,
)
{
    private val _state = MutableStateFlow(LoginState())
    val state: StateFlow<LoginState> = _state
    private val scope = CoroutineScope(SupervisorJob() + Dispatchers.Default)

    fun onEvent(event: Any) {
        when (event)
        {
            is LoginEvent.PassFirstSignInStep->{
                _state.value=_state.value.copy(isFirstSignInStepPassed = true)
            }

            is LoginEvent.UpdateIsLoggedInStatus->{
                scope.launch {
                    _state.value=_state.value.copy(
                        isCheckingLoggedInLoading = true
                    )
                    try {
                        val result=isLoggedInUseCase()
                        _state.value=_state.value.copy(
                            isCheckingLoggedInLoading = false, isLoggedIn = result
                        )
                    }catch (e:Exception){
                        _state.value=_state.value.copy(
                            isCheckingLoggedInLoading = false, isLoggedIn = false
                        )
                    }
                }

            }

            is LoginEvent.OnMouseLeaveSubmit->{
                _state.value=_state.value.copy(
                    isHoveredOnButton = false
                )
            }
            is LoginEvent.OnMouseEnterSubmit->{
                _state.value=_state.value.copy(
                    isHoveredOnButton = true
                )
            }
            is LoginEvent.OnPasswordChange ->{
                _state.value=_state.value.copy(
                    password = event.value
                )
            }
            is LoginEvent.OnMFAChange->{
                _state.value=_state.value.copy(
                    mfaCode = event.value
                )
            }

            is LoginEvent.OnUserNameChange ->{
                _state.value=_state.value.copy(
                    userName = event.value
                )
            }

            is LoginEvent.OnSignInClick->{
                signIn(onSuccess = {
                    event.goToHomePage()
                })



            }
            is LoginEvent.OnFailureLogin->{
                _state.value=_state.value.copy(
                    errorMsg =event.error,
                    isLoading = false,
                )
            }
            is LoginEvent.StartLoading ->{
                _state.value=_state.value.copy(
                    isLoading = true
                )
            }



            else -> {}
        }



    }

    private fun signIn(
        onSuccess:()->Unit,
    ) {
        scope.launch {
            _state.value = _state.value.copy(isLoading = true)
            try {
                signInUseCase(
                    userName = state.value.userName,
                    password = state.value.password,
                    mfaCode = state.value.mfaCode,
                    onSuccess = {
                        onSuccess()
                    },
                    onError = {
                        _state.value = _state.value.copy(
                            errorMsg = it,
                            isLoading = false,
                        )
                    }
                )
            } catch (e: Exception) {
                _state.value = _state.value.copy(
                    errorMsg = e.message.toString(),
                    isLoading = false,
                )
            }
        }
    }


}

