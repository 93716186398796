package org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_checkout

import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_checkout.update_template_checkout.UpdateTemplateCheckoutModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_ckechout.UpdateTemplateCheckoutRepository

class UpdateTemplateCheckoutUseCase(
    private val repository: UpdateTemplateCheckoutRepository
) {
    suspend operator fun invoke(
        userId:String,
        dataValue: MutableSet<String>,
    ): UpdateTemplateCheckoutModel {
       return repository.updateTemplateCheckout(
           userId=userId,
           dataValue=dataValue,
        )
    }
}