package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.general

enum class PriceLevelHouseButtonEnum(
    val title: String,
    val value: Int
) {
    NONE(
        title = "None",
        value = 0
    ),
    STORE1(
        title = "Store1",
        value = 1
    ),
    LIFECUSTOMER(
        title = "Life Customer",
        value = 2
    ),
    NETTO(
        title = "Netto",
        value = 3
    ),
    INHOUSE(
        title = "In House",
        value = 4
    ),
    GROSS(
        title = "Gross",
        value = 5
    ),
    PRICES_6(
        title = "Prices 6",
        value = 6
    ),
    COFFEE_REDEMPTION(
        title = "Coffee Redemption",
        value = 7
    ),
    COFFEE_PASS_REDEMPTION(
        title = "Coffee Pass Redemption",
        value = 8
    ),
    PRICES_9(
        title = "Prices 9",
        value = 9
    ),
    SUNDAY(
        title = "Sunday",
        value = 10
    ),
    NONE_11(
        title = "None 11",
        value = 11
    ),
}