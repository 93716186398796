package org.hs_soft.runmynesto.domain.usecase.user

import org.hs_soft.runmynesto.domain.model.home.user.add_new_user.AddNewUser
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.AddUserRepository

class AddUserUseCase(
    private val repository: AddUserRepository
) {
    suspend operator fun invoke(
        name:String,
        code:String,
    ): AddNewUser {
       return repository.addUser(
           name=name,
           code=code,
        )
    }
}