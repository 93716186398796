package org.hs_soft.runmynesto.pages.home.sub_page.media_center.component.media_center_content.tabs

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.DisplaySizeEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.TimeIntervalEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.app_constants.LoadAppConstant
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail.GetMedia
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_tab.MediaTag
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.shop_list.ShopItem
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.parseDateStringToMonthModel
import org.hs_soft.runmynesto.pages.components.AddItem
import org.hs_soft.runmynesto.pages.components.CalendarBox
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.DropDownItem
import org.hs_soft.runmynesto.pages.home.sub_page.media_center.component.media_center_content.tabs.components.Advertising
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.select_days.SelectDays
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.dom.Text

@Composable
fun Media(
    windowSizeMode: WindowSizeModeEnum,
    selectedMedia: GetMedia?,
    mediaTags: List<MediaTag>?,
    shopList: List<ShopItem>?,
    mediaImg169: String?,
    selectedDisplaySize:DisplaySizeEnum,
    mediaImg: String?,
    animationType: List<LoadAppConstant>?,
    onChangeDayCheckStatus: (Int) -> Unit,
    updateMediaInfo: (String, dynamic) -> Unit,
    showToCalendarDialog: () -> Unit,
    showFromCalendarDialog: () -> Unit,
    updateMediaBase64Img: (String, String) -> Unit,
    showAddGroupDialog:()->Unit,
    setDisplaySize:(DisplaySizeEnum)->Unit,
    showArticleDialog:()->Unit,
    deleteArticle:(String)->Unit,
)
{
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .borderSetting()
    ){

        if (windowSizeMode==WindowSizeModeEnum.Desktop){
            Row()
            {
                NameDateAndSelectedDays(
                    selectedMedia=selectedMedia,
                    showFromCalendarDialog=showFromCalendarDialog,
                    showToCalendarDialog=showToCalendarDialog,
                    windowSizeMode=windowSizeMode,
                    updateMediaInfo={title:String,value:dynamic->
                        updateMediaInfo(title,value)
                    },
                    onChangeDayCheckStatus={
                        onChangeDayCheckStatus(it)
                    }
                )
            }
        }else{
            Column()
            {
                NameDateAndSelectedDays(
                    selectedMedia=selectedMedia,
                    showFromCalendarDialog=showFromCalendarDialog,
                    showToCalendarDialog=showToCalendarDialog,
                    windowSizeMode=windowSizeMode,
                    updateMediaInfo={title:String,value:dynamic->
                        updateMediaInfo(title,value)
                    },
                    onChangeDayCheckStatus={
                        onChangeDayCheckStatus(it)
                    }
                )
            }
        }


        Row(
            modifier = Modifier
                .margin(top = Dimen.normalPadding)
        )
        {
            CheckboxComponent(
                modifier = Modifier.margin(
                    right = Dimen.largePadding
                ),
                checkStatus = selectedMedia?.isActive?:false,
                title = Strings.activate,
                onChecked = {
                    updateMediaInfo(
                        ApiUtils.Field.isActive,
                        true
                    )
                },
                onUnChecked = {
                    updateMediaInfo(
                        ApiUtils.Field.isActive,
                        false
                    )
                },
            )

            CheckboxComponent(
                modifier = Modifier ,
                checkStatus =selectedMedia?.stayActive?:false,
                title = Strings.stayActive,
                onChecked = {
                    updateMediaInfo(
                        ApiUtils.Field.stayActive,
                        true
                    )
                },
                onUnChecked = {
                    updateMediaInfo(
                        ApiUtils.Field.stayActive,
                        false
                    )
                },
            )
        }


        if (windowSizeMode==WindowSizeModeEnum.Desktop){
            Row(
                modifier = Modifier
                    .margin(topBottom = Dimen.normalPadding)
            )
            {
                GroupAndDate(
                    windowSizeMode=windowSizeMode,
                    mediaTags=mediaTags,
                    selectedMedia=selectedMedia,
                    updateMediaInfo={dataTitle:String,dataVaule:dynamic->
                        updateMediaInfo(
                            dataTitle,dataVaule
                        )
                    },
                    showAddGroupDialog={
                        showAddGroupDialog()
                    }
                )
            }
        }else{
            Column(
                modifier = Modifier
                    .margin(topBottom = Dimen.normalPadding)
            )
            {
                GroupAndDate(
                    windowSizeMode=windowSizeMode,
                    mediaTags=mediaTags,
                    selectedMedia=selectedMedia,
                    updateMediaInfo={dataTitle:String,dataValue:dynamic->
                        updateMediaInfo(
                            dataTitle,dataValue
                        )
                    },
                    showAddGroupDialog={
                        showAddGroupDialog()
                    }
                )
            }
        }

        if (windowSizeMode==WindowSizeModeEnum.Desktop)
        {
            Row(
                modifier = Modifier
                    .margin(topBottom = Dimen.normalPadding)
            )
            {
                RefreshAndShops(
                    selectedMedia=selectedMedia,
                    animationType=animationType,
                    shopList=shopList,
                    updateMediaInfo={title:String,value:dynamic->
                        updateMediaInfo(title,value)
                    },
                )
            }
        }else{
            Column(
                modifier = Modifier
                    .margin(topBottom = Dimen.normalPadding)
            )
            {
                RefreshAndShops(
                    selectedMedia=selectedMedia,
                    animationType=animationType,
                    shopList=shopList,
                    updateMediaInfo={title:String,value:dynamic->
                        updateMediaInfo(title,value)
                    },
                )
            }
        }


        if (windowSizeMode==WindowSizeModeEnum.Desktop){
            Row()
            {
                Advertising(
                    mediaImg169=mediaImg169,
                    mediaImg=mediaImg,
                    windowSizeMode = windowSizeMode,
                    selectedDisplaySize = selectedDisplaySize,
                    selectedArticles=selectedMedia?.withProducts,
                    updateMediaBase64Img={ file:String,type:String->
                        updateMediaBase64Img(
                            file,
                            type
                        )
                    },
                    setDisplaySize = {
                        setDisplaySize(it)
                    },
                    showArticleDialog = {
                        showArticleDialog()
                    },
                    deleteArticle = {
                        deleteArticle(it)
                    }
                )
            }
        }else{
            Column(){
                Advertising(
                    mediaImg169=mediaImg169,
                    mediaImg=mediaImg,
                    windowSizeMode = windowSizeMode,
                    selectedDisplaySize = selectedDisplaySize,
                    selectedArticles=selectedMedia?.withProducts,
                    updateMediaBase64Img={ file:String,type:String->
                        updateMediaBase64Img(
                            file,
                            type
                        )
                    },
                    setDisplaySize = {
                        setDisplaySize(it)
                    },
                    showArticleDialog = {
                        showArticleDialog()
                    },
                    deleteArticle = {
                        deleteArticle(it)
                    }
                )
            }
        }


    }




}


@Composable
private fun RefreshAndShops(
    selectedMedia: GetMedia?,
    updateMediaInfo: (String, dynamic) -> Unit,
    animationType: List<LoadAppConstant>?,
    shopList: List<ShopItem>?
) {
    Row(
        modifier = Modifier
            .margin(right = Dimen.normalPadding)
            .width(Dimen.semiLargeEditTextWidth)
    )
    {
        CustomEditText(
            modifier = Modifier,
            width = Dimen.normalEditTextWidth,
            label = Strings.refreshTime,
            value = selectedMedia?.refreshTime.toString(),
            isRequired = false,
            inputType = InputType.Number,
            hint = "",
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {
                updateMediaInfo(
                    ApiUtils.Field.refreshTime,
                    it.toInt()
                )
            },
            onInputChange = {

            },
        )

        Spacer()

        DropDownItem(
            modifier = Modifier,
            itemWidth = Dimen.normalEditTextWidth,
            options = animationType ?: listOf(),
            label = "",
            selectedItem = animationType?.find {
                it.value == selectedMedia?.animationType
            },
            content = {
                if (it is LoadAppConstant)
                    Text(it.label ?: "")
            },
            onOptionClick = {
                val option = animationType?.find { item ->
                    item.toString() == it
                }
                updateMediaInfo(
                    ApiUtils.Field.animationType,
                    option?.value
                )
            }
        )
    }


    DropDownItem(
        modifier = Modifier,
        itemWidth = Dimen.semiLargeEditTextWidth,
        options = shopList ?: listOf(),
        label = Strings.shopaffiliation,
        selectedItem = shopList?.find {
            selectedMedia?.shopId == it.id
        },
        content = {
            if (it is ShopItem)
                Text(it.name ?: "")
        },
        onOptionClick = {
            val option = shopList?.find { item ->
                item.toString() == it
            }

            updateMediaInfo(
                ApiUtils.Field.shopId,
                option?.id
            )

        }
    )
}

@Composable
private fun GroupAndDate(
    windowSizeMode: WindowSizeModeEnum,
    mediaTags: List<MediaTag>?,
    selectedMedia: GetMedia?,
    updateMediaInfo: (String, dynamic) -> Unit,
    showAddGroupDialog: () -> Unit
) {
    Row(
        modifier = Modifier
            .width(Dimen.semiLargeEditTextWidth),
        verticalAlignment = Alignment.Bottom,
    )
    {
        DropDownItem(
            modifier = Modifier,
            itemWidth = Dimen.mediumEditTextWidth,
            options = mediaTags ?: listOf(),
            label = Strings.group,
            selectedItem = mediaTags?.find {
                it.id == selectedMedia?.tagId
            },
            content = {
                if (it is MediaTag)
                    Text(it.name ?: "")
            },
            onOptionClick = {
                val option = mediaTags?.find { item ->
                    item.toString() == it
                }

                updateMediaInfo(
                    ApiUtils.Field.tagId,
                    option?.id
                )
            }
        )

        Spacer()

        AddItem(
            modifier = Modifier,
            onAddClick = {
                showAddGroupDialog()
            }
        )
    }


    Row(
        modifier = Modifier
            .thenIf(
                windowSizeMode==WindowSizeModeEnum.Desktop,
                Modifier.margin(left = Dimen.normalPadding)
            )
            .thenIf(
                windowSizeMode!=WindowSizeModeEnum.Desktop,
                Modifier.margin(top = Dimen.mediumPadding)
            )
            .width(Dimen.semiLargeEditTextWidth)
    )
    {
        DropDownItem(
            modifier = Modifier,
            itemWidth = Dimen.normalEditTextWidth,
            options = TimeIntervalEnum.entries,
            label = Strings.from,
            selectedItem = TimeIntervalEnum.entries.find {
                it.value == selectedMedia?.timeInterval?.from
            },
            content = {
                if (it is TimeIntervalEnum)
                    Text(it.title)
            },
            onOptionClick = {
                val option: TimeIntervalEnum? = TimeIntervalEnum.entries.find { item ->
                    item.toString() == it
                }
                val json = buildJsonObject {
                    put(ApiUtils.Field.from, option?.value)
                }
                updateMediaInfo(
                    ApiUtils.Field.timeInterval,
                    json
                )
            }
        )

        Spacer()


        DropDownItem(
            modifier = Modifier,
            itemWidth = Dimen.normalEditTextWidth,
            options = TimeIntervalEnum.entries,
            label = Strings.until,
            selectedItem = TimeIntervalEnum.entries.find {
                it.value == selectedMedia?.timeInterval?.to
            },
            content = {
                if (it is TimeIntervalEnum)
                    Text(it.title)
            },
            onOptionClick = {
                val option: TimeIntervalEnum? = TimeIntervalEnum.entries.find { item ->
                    item.toString() == it
                }
                val json = buildJsonObject {
                    put(ApiUtils.Field.to, option?.value)
                }
                updateMediaInfo(
                    ApiUtils.Field.timeInterval,
                    json
                )
            }
        )
    }
}

@Composable
private fun NameDateAndSelectedDays(
    selectedMedia: GetMedia?,
    windowSizeMode: WindowSizeModeEnum,
    updateMediaInfo: (String, dynamic) -> Unit,
    showFromCalendarDialog: () -> Unit,
    showToCalendarDialog: () -> Unit,
    onChangeDayCheckStatus: (Int) -> Unit
) {
    CustomEditText(
        modifier = Modifier,
        width = Dimen.semiLargeEditTextWidth,
        label = Strings.name,
        value = selectedMedia?.name ?: "",
        isRequired = false,
        hint = "",
        isOutlinedEditText = true,
        onFocusIn = {},
        onFocusOut = {
            updateMediaInfo(
                ApiUtils.Field.name,
                it
            )
        },
        onInputChange = {

        },
    )



    Row(
        modifier = Modifier
            .thenIf(
                windowSizeMode==WindowSizeModeEnum.Desktop,
                Modifier.margin(leftRight = Dimen.normalPadding)
            )
            .width(Dimen.semiLargeEditTextWidth)
    )
    {
        CalendarBox(
            modifier = Modifier,
            width = Dimen.normalEditTextWidth,
            selectedDate = parseDateStringToMonthModel(
                selectedMedia?.dateInterval?.from
            ),
            label = Strings.from,
            onClick = {
                showFromCalendarDialog()
            }
        )

        Spacer()

        CalendarBox(
            modifier = Modifier,
            width = Dimen.normalEditTextWidth,
            selectedDate = parseDateStringToMonthModel(
                selectedMedia?.dateInterval?.to
            ),
            label = Strings.until,
            onClick = {
                showToCalendarDialog()
            }
        )
    }



    SelectDays(
        windowSizeMode = windowSizeMode,
        selectedDaysNumber = selectedMedia?.weekDays ?: 0,
        onChangeDayCheckStatus = {
            onChangeDayCheckStatus(it)
        }
    )
}

