package org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill

import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit

enum class BillSearchTableHeadersEnum(
    val title:String,
    val arrangement: Arrangement.Horizontal,

)
{
    DATE(
        title = Strings.date,
        arrangement = Arrangement.Start,
    ),
    TIME(
        title =Strings.time ,
        arrangement = Arrangement.Start,
    ),
    PDF(
        title =Strings.pdf,
        arrangement = Arrangement.Start,
    ),
    DEVICES(
        title =Strings.devices ,
        arrangement = Arrangement.Start,
    ),
    NUMBER(
        title =Strings.number ,
        arrangement = Arrangement.Start,
    ),
    USER_NAME(
        title =Strings.userName ,
        arrangement = Arrangement.Start,
    ),
    CANCELLATION(
        title =Strings.cancellation ,
        arrangement = Arrangement.Start,
    ),
    TOTAL(
        title =Strings.total ,
        arrangement = Arrangement.End,
    )

}