package org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.cache

import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.GetCachedProductCategoriesRepository

class GetCachedProductCategoriesUseCase(
    private val repository: GetCachedProductCategoriesRepository
) {
     operator fun invoke(
         callback: (List<ProductItem>?)->Unit
    ) {
       repository.getProductCategories {
             callback(it)
        }
    }


}