package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail.LoyaltyCardDetail
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.extractDate
import org.hs_soft.runmynesto.domain.util.extractTime
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.MediumContentTitle

@Composable
fun CustomerCardTabCard(
    windowSizeMode: WindowSizeModeEnum,
    selectedLoyaltyCard: LoyaltyCardDetail?,
    setIsActiveStatus:(Boolean)->Unit,
)
{
    Column()
    {
        MediumContentTitle(
            title = Strings.card,
        )

        if (windowSizeMode==WindowSizeModeEnum.Desktop)
        {
            Row(
                verticalAlignment = Alignment.CenterVertically
            ){
                content(
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    setIsActiveStatus={
                        setIsActiveStatus(it)
                    }
                )
            }
        }else{
            Column()
            {
                content(
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    setIsActiveStatus={
                        setIsActiveStatus(it)
                    }
                )
            }
        }




    }
}

@Composable
private fun content(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    setIsActiveStatus: (Boolean) -> Unit
) {
    Row()
    {
        CustomEditText(
            modifier = Modifier,
            isEnabled = false,
            label = Strings.cardNumber,
            value = selectedLoyaltyCard?.id ?: "",
            marginRight = Dimen.normalPadding,
            marginBottom = Dimen.smallPadding,
            isDecimal = true,
            width = Dimen.normalEditTextWidth,
            isRequired = false,
            hint = "",
            onFocusIn = {

            },
            onFocusOut = {},
            onInputChange = {},
        )


        CustomEditText(
            modifier = Modifier,
            isEnabled = false,
            label = Strings.internetAccessCode,
            value = selectedLoyaltyCard?.accessCode ?: "",
            width = Dimen.normalEditTextWidth,
            marginRight = Dimen.normalPadding,
            isRequired = false,
            hint = "",
            marginBottom = Dimen.smallPadding,
            onFocusIn = {

            },
            onFocusOut = {},
            onInputChange = {},
        )
    }

    Row()
    {
        CustomEditText(
            modifier = Modifier,
            isEnabled = false,
            label = Strings.lastUsed,
            marginRight = Dimen.normalPadding,
            width = Dimen.normalEditTextWidth,
            value = extractDate(selectedLoyaltyCard?.lastUpdatedAt ?: "") ?: "",
            endIconPath = Res.PathIcon.calendar,
            isRequired = false,
            hint = "",
            marginBottom = Dimen.smallPadding,
            onFocusIn = { },
            onFocusOut = {},
            onInputChange = {},
        )


        CustomEditText(
            modifier = Modifier,
            label = Strings.time,
            isEnabled = false,
            marginRight = Dimen.mediumPadding,
            value = extractTime(selectedLoyaltyCard?.lastUpdatedAt ?: "") ?: "",
            width = Dimen.normalEditTextWidth,
            endIconPath = Res.PathIcon.calendar,
            isRequired = false,
            hint = "",
            marginBottom = Dimen.smallPadding,
            onFocusIn = {

            },
            onFocusOut = {},
            onInputChange = {},
        )
    }


    CheckboxComponent(
        modifier = Modifier,
        checkStatus = selectedLoyaltyCard?.isActive ?: false,
        title = Strings.activate,
        onChecked = {
            setIsActiveStatus(true)
        },
        onUnChecked = {
            setIsActiveStatus(false)
        },
    )
}


