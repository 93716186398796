package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model.ClosingReportItem
import org.hs_soft.runmynesto.domain.util.extractDate
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position


@Composable
fun ReportsTableItem(
    model: ClosingReportItem,
    onItemClick: (String) -> Unit,
    isSelected: Boolean,
)
{
    val isHovered= remember { mutableStateOf(false) }

    Row(
        modifier = Modifier

            .position(Position.Relative)
            .rippleEffect {
                onItemClick(model.id?:"")
            }
            .fillMaxWidth()
            .height(Dimen.mediumTableItemHeight)
            .padding(
                leftRight = Dimen.normalPadding,
                topBottom = Dimen.smallPadding,
            )
            .backgroundColor(
                when {
                    isSelected -> Theme.GrayAlpha12.rgb
                    isHovered.value -> Theme.GrayAlpha08.rgb
                    else -> Theme.Transparent.rgb
                }
            )
            .transitionBackground()
            .borderBottom(
                width = Dimen.smallThickness,
                color = Theme.GrayCD.rgb,
                style = LineStyle.Solid,
            )
            .onMouseOver { isHovered.value=true }
            .onMouseLeave { isHovered.value=false },
        verticalAlignment = Alignment.CenterVertically
    )
    {
        HeaderTitle(
            title = extractDate(model.dateTime?:""),
            width = Dimen.headerSmallWidth,
            fontWeight = FontWeight.Normal,
        )
        HeaderTitle(
            title = extractDate(model.dateTime?:""),
            width = Dimen.headerSmallWidth,
            fontWeight = FontWeight.Normal,
        )
        HeaderTitle(
            title =model.number.toString() ,
            width = Dimen.headerSmallWidth,
            fontWeight = FontWeight.Normal,
            alignStart = false
        )
        HeaderTitle(
            title =model.cashRegister?.name?:"",
            width = Dimen.headerExtraLargeWidth,
            fontWeight = FontWeight.Normal,
        )

        Spacer()

        HeaderTitle(
            title = model.total.toString() ,
            alignStart = false
        )
    }
}