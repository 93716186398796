package org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Item(
    @SerialName("amount")
    val amount: Double?,
    @SerialName("codeMWSt")
    val codeMWSt: String?,
    @SerialName("discountPercent")
    val discountPercent: Double?,
    @SerialName("flag")
    val flag: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("inCafe")
    val inCafe: Boolean?,
    @SerialName("mul1")
    val mul1: Double?,
    @SerialName("price")
    val price: Double?,
    @SerialName("product")
    val product: Product?,
    @SerialName("total")
    val total: Double?,
    @SerialName("__typename")
    val typename: String?
)