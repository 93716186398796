package org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_surcharge_in_house

import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_surcharge.UpdateSurchargeModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_surcharge_in_house.UpdateSurchargeRepository

class UpdateSurchargeUseCase(
    private val repository: UpdateSurchargeRepository
) {
    suspend operator fun invoke(
        id: String,
        dataTitle:String,
        dataValue:List<Int?>,
    ): UpdateSurchargeModel {
       return repository.updateSurcharge(
           id=id,
           dataTitle=dataTitle,
           dataValue=dataValue,
        )
    }
}