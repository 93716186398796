package org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.update_checkout


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("__typename")
    val typename: String,
    @SerialName("updateUser")
    val updateUser: UpdateUser
)