package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.create_product_addition


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ProductAdditionModel(
    @SerialName("data")
    val `data`: Data?
)