package org.hs_soft.runmynesto.pages.home.sub_page.template.mvi


import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.closing_reports.CurrencyTypeEnum
import org.hs_soft.runmynesto.domain.model.home.options.CountriesEnum
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.model.home.template.TemplateInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.template.product_categories.TemplateCategoryModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.ListingLayoutsEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.WaiterDeviceTypeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.GetCategory
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.UpdateListOfCategoriesPositionsRepository
import org.hs_soft.runmynesto.domain.usecase.home.GetCompanyProfileIdUseCase
import org.hs_soft.runmynesto.domain.usecase.home.GetSavedCheckOutUseCase
import org.hs_soft.runmynesto.domain.usecase.product.GetImgUrlUseCase
import org.hs_soft.runmynesto.domain.usecase.product.ProductCategoriesUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.UpdateProductUseCase
import org.hs_soft.runmynesto.domain.usecase.template.AddCategoryUseCase
import org.hs_soft.runmynesto.domain.usecase.template.GetTemplateCategoriesUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_checkout.UpdateTemplateCheckoutUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.GetDishPictureUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.GetSingleTemplateCategoryUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.SetGridProductUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.UpdateProductsPositionsUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.UpdateTemplateCategoryImgUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.UpdateTemplateCategoryUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_options.GetPriceLevelsUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_surcharge_in_house.UpdatePriceLevelUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_surcharge_in_house.UpdateSurchargeUseCase
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.handleError
import org.hs_soft.runmynesto.domain.util.helper.BaseViewModel
import org.hs_soft.runmynesto.domain.util.monthModelToDateString
import org.hs_soft.runmynesto.domain.util.parseDateStringToMonthModel
import org.hs_soft.runmynesto.domain.util.withTokenRefresh

class TemplateViewModel(
    private val requestManager: RequestManager,
    private val refreshToken: () -> Unit,
    private val getTemplateCategoriesUseCase: GetTemplateCategoriesUseCase,
    private val getSingleTemplateCategoryUseCase: GetSingleTemplateCategoryUseCase,
    private val addCategoryUseCase: AddCategoryUseCase,
    private val updateProductsPositionsUseCase: UpdateProductsPositionsUseCase,
    private val updateTemplateCategoryUseCase: UpdateTemplateCategoryUseCase,
    private val updateTemplateCategoryImgUseCase: UpdateTemplateCategoryImgUseCase,
    private val updateSurchargeUseCase: UpdateSurchargeUseCase,
    private val priceLevelsUseCase: GetPriceLevelsUseCase,
    private val updatePriceLevelUseCase: UpdatePriceLevelUseCase,
    private val updateTemplateCheckoutUseCase: UpdateTemplateCheckoutUseCase,
    private val getDishPictureUseCase: GetDishPictureUseCase,
    private val updateProductUseCase: UpdateProductUseCase,
    private val productCategoriesUseCase: ProductCategoriesUseCase,
    private val getSavedCheckOutUseCase: GetSavedCheckOutUseCase,
    private val getCompanyProfileIdUseCase: GetCompanyProfileIdUseCase,
    private val imgUrlUseCase: GetImgUrlUseCase,
    private val setGridProductUseCase: SetGridProductUseCase,
    private val updateListOfCategoriesPositionsRepository: UpdateListOfCategoriesPositionsRepository,
):BaseViewModel()
{

    private val _state = MutableStateFlow(TemplateState())
    val state : StateFlow<TemplateState> = _state

    private var tempProductCategoryList:List<TemplateCategoryModel> = listOf()
    private var tempArticleList:List<ProductItem> ?= listOf()
    private var tempCheckoutList: List<Children>? = listOf()

    override fun clear() {
        super.clear()
        requestManager.cancelAll()
    }

    init {
        setCurrencyType()
    }


    fun onEvent(event: TemplateEvent) {
        when (event) {

            is TemplateEvent.SetGridProduct->{
                setGridProduct(
                    categoryId=state.value.selectedCategory?.id?:"",
                    productId=event.productId,
                    position=event.position,
                )
            }

            is TemplateEvent.GetProductListBasedOnCategoryId->{
                getProductListBasedOnCategoryId(
                    categoryId=state.value.selectedCategory?.id,
                )
            }

            is TemplateEvent.ChooseWaiterDeviceType->{
                chooseWaiterDeviceType(type = event.type)
            }

            is TemplateEvent.ChooseWaiterLayoutType->{
                _state.value=_state.value.copy(
                    selectedWaiterLayoutType = event.type
                )
            }


            is TemplateEvent.SelectWaiterDeviceType->{
                _state.value=_state.value.copy(
                    selectedWaiterDeviceType = event.type,
                )
            }

            is TemplateEvent.SetFilterItemsComponentStatus->{
                _state.value=_state.value.copy(
                    showItemsFilterComponent = event.status
                )
            }
            is TemplateEvent.SubmitFilterItems->{
                submitFilterItems(type=state.value.selectedFilterItemsType)
            }
            is TemplateEvent.SelectFilterType->{
                _state.value=_state.value.copy(selectedFilterItemsType = event.type)
            }

            is TemplateEvent.SetLocalCategoryImg->{
                _state.value=_state.value.copy(
                    imageAiGeneratorUrl=event.url,
                )
            }
            is TemplateEvent.GetTemplateCategoryList ->{
                getTemplateCategoryList( )

            }
            is TemplateEvent.OnTemplateCategoryClick->{
                onTemplateCategoryClick(
                    id=event.id,
                    callBack=event.switchToSecondPage,
                )
            }

            is TemplateEvent.AddNewProductToArticleList->{
                addNewProductToArticleList(
                    dataTitle = ApiUtils.Field.categoryId,
                    dataValue = state.value.selectedCategory?.id?:"",
                    selectedProductId = event.id
                )
            }

            is TemplateEvent.OnSubmitNewImage-> {
                updateTemplateImage(
                    id=event.id,
                    img=event.url,
                )
            }

            is TemplateEvent.OnAddToArticleIconClick->{
                onAddToArticleIconClick(query = state.value.searchedProductValue)

            }

            is TemplateEvent.OnUpdateTemplateCategoryInfo->{
                updateTemplateCategoryInfo(
                    id = event.id,
                    dataTitle = event.dataTitle,
                    dataValue = event.dataValue,
                )
            }

            is TemplateEvent.GetCheckoutData -> {
                getCheckoutData(   )
            }

            is TemplateEvent.UpdateTemplateCategoryListAfterDragDrop->{
                updateTemplateCategoryListAfterDragDrop(
                    newList = event.newList,
                    idsList=event.idList,
                    dataTitle = ApiUtils.Field.position,
                    positionList = event.positionList,
                )
            }
            is TemplateEvent.UpdateCategoryBasedProductListAfterDragDrop->{
                updateCategoryBasedProductsListAfterDragDrop(
                    newList = event.newList,
                    idsList=event.idList,
                    productIdList=event.productIdList,
                    categoryId = state.value.selectedCategory?.id?:"",
                    positionList = event.positionList,
                )
            }
            is TemplateEvent.RefreshFoodList ->{
                getTemplateCategoryList(  )
            }

            is TemplateEvent.UpdateCheckout -> {
                updateTemplateCheckout(
                    dataValue = event.dataValue,
                    id = state.value.selectedCategory?.id ?: "",
                )
            }

            is TemplateEvent.OnSearchCheckoutItems -> {
                searchCheckoutItems(value = event.value)

            }

            is TemplateEvent.GetPriceLevels->{
                getPriceLevels()
            }
            is TemplateEvent.UpdatePriceLevel->{
                updatePriceLevel(
                    index = event.index,
                    id=state.value.selectedCategory?.id?:"",
                )
            }

            is TemplateEvent.UpdateSurcharge->{
                updateSurcharge(
                    id=state.value.selectedCategory?.id?:"",
                    dataValues=event.values,
                    dataTitle=event.title,
                )
            }

            //Add Record
            is TemplateEvent.OnAddRecordIconClick->onAddRecordIconClick()

            is TemplateEvent.OnAddRecordDialogBackgroundClick->{}
            //dismissAddRecordDialog()

            is TemplateEvent.OnAddProductToArticleDialogBackgroundClick->{
                _state.value=_state.value.copy(
                    showArticleListDialog = false,
                    addNewProductToArticleErrMsg = ""
                )
            }

            is TemplateEvent.OnCancelRecordDialogClick->dismissAddRecordDialog()

            is TemplateEvent.OnChangeRecordName->onChangeRecordName(event.value)

            is TemplateEvent.OnSubmitRecordDialogClick->{
                onSubmitRecordDialogClick(
                    windowMode=event.windowMode,
                    name=state.value.newRecordName,
                )
            }

            //Searching Articles
            is TemplateEvent.OnSearchArticleInputChange ->{
                onSearchArticleInputChange(
                    value = event.value,
                )
            }

//            is TemplateEvent.OnArticleTitleSortClick ->onArticleTitleSortClick()


            is TemplateEvent.RefreshArticleList -> refreshArticleList()

            //Other
            is TemplateEvent.OnChangePageClick->changePage()


            is TemplateEvent.OnTabClick->onTabClick(event.tab)


            is TemplateEvent.SetCalendarStatus->{
                _state.value=_state.value.copy(showCalendar = event.status)
            }
            is TemplateEvent.OnSelectDate->{
                chooseDate(
                    selectedDate=event.selectedDate,
                    id=state.value.selectedCategory?.id?:""
                )
            }

            is TemplateEvent.OnPalletColorClick->{
                onPalletColorClick(
                    hexColor =event.hexColor
                )


            }

            is TemplateEvent.SetColorPalletStatus->{
                _state.value=_state.value.copy(showColorPalletDialog=event.status)
            }
            is TemplateEvent.SetGenerateAIImgDialogStatus->{
                _state.value=_state.value.copy(
                    showGenerateAIImgDialog = event.status,
                    imageAiGeneratorUrl = Res.PathIcon.defaultRectPhoto,
                )
            }

            is TemplateEvent.GenerateTemplateImg->{
                generateTemplateImg(
                    description=event.description,
                )
            }
            is TemplateEvent.OnChangeAiImageDescription->{
                _state.value=_state.value
                    .copy(imageAiGeneratorDescription = event.value)
            }


        }
    }

    private fun onAddToArticleIconClick(
        query:String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=productCategoriesUseCase(
                        searchQuery = query,
                        isActive=null
                    )
                        .data?.cashAssist?.listProducts
                    hideProgress()
                    _state.value=_state.value.copy(
                        showArticleListDialog = true,
                        searchedProducts = result?.items?: listOf()
                    )
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }
        }


    }


    private fun setGridProduct(
        categoryId: String,
        productId: String?,
        position: Int,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=setGridProductUseCase(
                        categoryId=categoryId,
                        position=position,
                        productId=productId,
                    ).data?.cashAssist?.setGridProduct

                    if (result?.id!=null){
                        getSingleTemplateCategoryData(
                            id=state.value.selectedCategory?.id?:"",
                            callBack = {}
                        )

                    }

                }
            }catch (e:Exception){
                hideProgress()
                handleError(e)
            }


        }

    }

    private fun getProductListBasedOnCategoryId(
        categoryId: String?,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=productCategoriesUseCase(
                        categoryId=categoryId,
                        isActive = null,
                    ).data?.cashAssist?.listProducts?.items
                    _state.value=_state.value.copy(
                         articleList=result
                    )
                    tempArticleList = result
                    hideProgress()
                }
            }catch (e:Exception){
                hideProgress()
                handleError(e)
            }


        }

    }

    suspend fun showImgURL(key: String): String {
        return imgUrlUseCase(key)
    }

    private fun setCurrencyType(){
        val result=getCompanyProfileIdUseCase()

        if (result== CountriesEnum.SWITZERLAND.id){
            _state.value=_state.value.copy(
                currencyType=CurrencyTypeEnum.CHF
            )
        }
        else{
            _state.value=_state.value.copy(
                currencyType=CurrencyTypeEnum.EUR
            )
        }
    }

    private fun chooseWaiterDeviceType(
        type:WaiterDeviceTypeEnum,
    )
    {
        _state.value = _state.value.copy(
            selectedWaiterDeviceType = type,
            selectedWaiterLayoutType = if (type == WaiterDeviceTypeEnum.DESKTOP_WAITER)
                ListingLayoutsEnum.TILES else state.value.selectedWaiterLayoutType
        )
    }

    private fun onPalletColorClick(hexColor:String) {
        if ( hexColor != state.value.selectedPalletColor) {
            _state.value = _state.value.copy(
                selectedPalletColor = hexColor,
                showColorPalletDialog = false
            )


            updateTemplateCategoryInfo(
                id = state.value.selectedCategory?.id ?: "",
                dataTitle = ApiUtils.Field.color,
                dataValue = hexColor,

            )

        }
    }


    private fun updateCategoryBasedProductsListAfterDragDrop(
        newList: List<ProductItem>,
        idsList: List<String>,
        productIdList: List<String>,
        categoryId: String,
        positionList: List<Int>,
    ) {
        val temp=state.value.articleList

        _state.value=_state.value.copy(
            articleList = newList
        )

        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    updateProductsPositionsUseCase(
                        categoryId=categoryId,
                        productIdList=productIdList,
                        positionsList = positionList,
                        idsList = idsList
                    )
                    hideProgress()
                }
            }catch (e:Exception){
                hideProgress()
                handleError(e)
                _state.value=_state.value.copy(articleList = temp)
            }


        }


    }


    private fun submitFilterItems(type: GridItemsFilterEnum) {
        when(type){
            GridItemsFilterEnum.ALL_ITEMS->{
                _state.value=_state.value.copy(
                    productCategoryList = tempProductCategoryList
                )
            }

            GridItemsFilterEnum.ACTIVE_ITEMS->{
                _state.value=_state.value.copy(
                    productCategoryList = tempProductCategoryList.filter {
                        it.isActive?:false
                    }
                )
            }
            GridItemsFilterEnum.INACTIVE_ITEMS->{
                _state.value=_state.value.copy(
                    productCategoryList = tempProductCategoryList.filter {
                        !(it.isActive?:false)
                    }
                )
            }
        }



    }




    private fun generateTemplateImg(
        description: String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    _state.value=_state.value.copy(isImageAiGeneratorLoading = true)
                    val result=getDishPictureUseCase(description=description)
                    val imageDataUrl = "data:image/jpeg;base64,$result"
                    _state.value = _state.value.copy(
                        imageAiGeneratorUrl =imageDataUrl,
                        isImageAiGeneratorLoading = false
                    )
                }

            }catch (e:Exception){
                _state.value=_state.value.copy(isImageAiGeneratorLoading = false)
                handleError(
                    error = e,
                )
            }

        }
    }




    private fun chooseDate(
        selectedDate: MonthModel,
        id: String,
    ) {
        _state.value = _state.value.copy(
            showCalendar = false,
            selectedDate =  selectedDate,
        )

        updateTemplateCategoryInfo(
            id=id,
            dataTitle = ApiUtils.Field.validAfter,
            dataValue = monthModelToDateString(selectedDate) ,
        )

    }

    private fun updateSurcharge(
        id: String,
        dataValues: List<Int?>,
        dataTitle: String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result= updateSurchargeUseCase(
                        id=id,
                        dataTitle=dataTitle,
                        dataValue =dataValues,
                    ).data.cashAssist.updateCategory
                    hideProgress()
                    _state.value=_state.value.copy(
                        selectedCategory = state.value.selectedCategory?.copy(
                            surchargeFrom = result?.surchargeFrom?: listOf(),
                            surchargeAmount =result?.surchargeAmount?: listOf(),
                            surchargePercent =result?.surchargePercent?: listOf() ,
                        )
                    )

                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun updatePriceLevel(
        id:String,
        index:Int,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=updatePriceLevelUseCase(
                        index = index,
                        id = id
                    ).data.cashAssist.updateCategory
                    hideProgress()
                    _state.value=_state.value.copy(selectedPriceLevelIndex = result?.priceLevel)
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun getPriceLevels() {
        if (state.value.priceLevels==null){
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()

                        val result=priceLevelsUseCase()
                        _state.value=_state.value.copy(
                            priceLevels = result.data.cashAssist.
                            getAppConfigAdditional.priceLevels,
                        )
                        hideProgress()
                    }

                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }

            }
        }


    }

    private fun updateTemplateCheckout(
        dataValue: MutableSet<String>,
        id: String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {

                    showProgress()
                    val result=updateTemplateCheckoutUseCase(
                        userId = id,
                        dataValue = dataValue,
                    )
                    _state.value=_state.value.copy(
                        selectedCategory = state.value.selectedCategory?.copy(
                            cashRegisterId = result.data.cashAssist.updateCategory?.cashRegisterId?: listOf()
                        )
                    )
                    hideProgress()
                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }
    }

    private fun searchCheckoutItems(value: String) {
        _state.value = _state.value.copy(cashList = tempCheckoutList)

        if (value == "") {
            _state.value = _state.value.copy(
                checkOutExpandAll = false,
            )
        } else {
            val searchedList = ArrayList<Children>()
            state.value.cashList?.forEach { model ->
                var matchFound = model.name?.lowercase()?.contains(value.lowercase()) ?: false
                model.children?.forEach { childX ->
                    if (childX.name?.lowercase()?.contains(value.lowercase()) == true) {
                        matchFound = true
                    }
                    childX.children?.forEach { childXX ->
                        if (childXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                            matchFound = true
                        }
                        childXX.children.forEach {childXXX->
                            if (childXXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                                matchFound = true
                            }
                        }
                    }
                }
                if (matchFound) {
                    searchedList.add(model)
                }
            }


            _state.value = _state.value.copy(
                checkOutExpandAll = true,
                cashList = searchedList
            )

        }
    }

    private fun getCheckoutData(  ) {
        launch {
            if (state.value.cashList==null){

                val result=getSavedCheckOutUseCase()

                _state.value=_state.value.copy(
                    cashList = result?.data?.cashAssist?.children
                )
                tempCheckoutList=state.value.cashList
            }

        }

    }

    private fun onTabClick(tab: TemplateInfoHeaderEnum) {
        _state.value=_state.value.copy(
            selectedTab=tab
        )

    }


    private fun updateTemplateCategoryListAfterDragDrop(
        newList: List<dynamic>,
        idsList: List<String>,
        dataTitle: String,
        positionList: List<Int>,
    ) {
        _state.value=_state.value.copy(
            productCategoryList = newList
        )

        idsList.forEachIndexed { index,item ->
            updateTemplateCategoryInfo(
                id = item,
                dataTitle = dataTitle,
                dataValue = positionList[index],
            )
        }


    }






    private fun onSubmitRecordDialogClick(
        name: String,
        windowMode: WindowSizeModeEnum,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    _state.value=_state.value.copy(addRecordLoading = true)


                    val model=addCategoryUseCase(name=name)
                    _state.value=_state.value.copy(
                        addRecordLoading = false,
                        showAddRecordDialog = false,
                        addRecordErrorMessage = "",
                        newRecordName = "",
                        selectedCategory = GetCategory(
                            id = model.data?.cashAssist?.createMutation?.id?:"",
                            name = model.data?.cashAssist?.createMutation?.name?:"",
                        ),
                        articleList = listOf(),
                        templateItemImg = Res.PathIcon.defaultRectPhoto,
                        firstPageActive = windowMode != WindowSizeModeEnum.PHONE
                    )
                    getTemplateCategoryList(   )
                }



            }catch (e:Exception){
                _state.value=_state.value.copy(addRecordLoading = false)
                _state.value=_state.value.copy(
                    addRecordErrorMessage = e.message.toString()
                )
                handleError(
                    error = e,
                )
            }

        }

    }



    private fun onChangeRecordName(value: String) {
        _state.value=_state.value.copy(
            newRecordName = value
        )
        checkAddRecordSubmitClickable()
    }

    private fun checkAddRecordSubmitClickable(){
        if (state.value.newRecordName.isNotEmpty()){
            _state.value=_state.value.copy(
                isAddNewRecordSubmitClickable = true
            )
        }else _state.value=_state.value.copy(
            isAddNewRecordSubmitClickable = false
        )
    }

    private fun dismissAddRecordDialog() {
        _state.value=_state.value.copy(
            showAddRecordDialog = false,
            addRecordErrorMessage =""
        )
    }

    private fun onAddRecordIconClick() {
        _state.value=_state.value.copy(
            showAddRecordDialog = true
        )
    }

    private fun updateTemplateImage(
        id: String,
        img: String,
    ) {
        _state.value=_state.value.copy(
            isImageAiGeneratorLoading = true,
        )
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()

                    val data=updateTemplateCategoryImgUseCase(
                        img=img,
                        id = id
                    )
                    console.log(data)
                    _state.value=_state.value.copy(
                        imageAiGeneratorUrl = Res.PathIcon.defaultRectPhoto,
                        showGenerateAIImgDialog = false,
                        isImageAiGeneratorLoading = false,
                        templateItemImg = img,
                    )

                    hideProgress()
                }

            }catch (e:Exception){
                hideProgress()
                _state.value=_state.value.copy(
                    isImageAiGeneratorLoading = false
                )
                handleError(
                    error = e,
                )
            }


        }

    }

    private fun updateTemplateCategoryInfo(
        id: String,
        dataTitle: String,
        dataValue:dynamic,
    ) {

        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()

                    val result=updateTemplateCategoryUseCase(
                        dataTitle=dataTitle,
                        dataValue = dataValue,
                        id = id
                    )
                    val model=result.data?.cashAssist?.updateCategory
                    _state.value=_state.value.copy(
                        selectedDays = model?.weekDays?:0
                    )

                    if (dataTitle== ApiUtils.Field.isActive){
                        //This is for updating filter data
                        tempProductCategoryList = tempProductCategoryList.map { category ->
                            if (category.id == id) {
                                category.copy(isActive = dataValue as Boolean)
                            }
                            else {
                                category
                            }
                        }
                        submitFilterItems(type = state.value.selectedFilterItemsType)
                    }
                    if(dataTitle== ApiUtils.Field.name){
                        _state.value=_state.value.copy(
                            selectedCategory = state.value.selectedCategory?.copy(
                                name = model?.name?:"",
                            ),
                            imageAiGeneratorDescription =model?.name?:""
                        )
                        getTemplateCategoryList(   )
                    }else hideProgress()
                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun onTemplateCategoryClick(
        id: String,
        callBack: () -> Unit={},
    ) {
        if (id!=state.value.selectedCategory?.id){

            getSingleTemplateCategoryData(
                id=id,
                callBack={
                    callBack()
                }
            )


        }else callBack()

    }

    private fun addNewProductToArticleList(
        dataTitle: String,
        dataValue:String,
        selectedProductId:String,
    ) {
        launch {

            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    _state.value=_state.value.copy(addRecordLoading = true)


                    updateProductUseCase(
                        dataTitle=dataTitle,
                        dataValue = dataValue,
                        productId = selectedProductId,
                    )
                    _state.value=_state.value.copy(
                        showArticleListDialog = false,
                        addNewProductToArticleErrMsg=""
                    )

                    getSingleTemplateCategoryData(
                        id=state.value.selectedCategory?.id?:"",
                        callBack={}
                    )
                }

            }catch (e:Exception){
                hideProgress()
                _state.value=_state.value.copy(
                    addNewProductToArticleErrMsg =e.message.toString(),
                )
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun getSingleTemplateCategoryData(
        id: String,
        callBack: () -> Unit
    ) {
        launch {
            try {

                withTokenRefresh(refreshToken) {
                    showProgress()


                    val result=getSingleTemplateCategoryUseCase(id)
                    val model=result.data?.cashAssist?.getCategory

                    _state.value = _state.value.copy(
                        selectedCategory = model,
                        templateItemImg = model?.img?.url,
                        selectedPriceLevelIndex = model?.priceLevel,
                        selectedDate = parseDateStringToMonthModel(model?.validAfter),
                        selectedPalletColor = model?.color ?: Theme.Transparent.hex,
                        selectedDays = model?.weekDays?:0,
                        imageAiGeneratorDescription = model?.name?:""

                    )

                    getProductListBasedOnCategoryId(
                        categoryId = model?.id
                    )


                    callBack()
                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }
        }

    }

    private fun getTemplateCategoryList( )
    {
        launch {
            try {
                showProgress()
                val templateCategoryData = getTemplateCategoriesUseCase()


                val sortedCategories = defaultCategorySorting(
                    templateCategoryData.data?.cashAssist?.listCategories?.items?: listOf()
                )

                _state.value=_state.value.copy(productCategoryList = sortedCategories)


                tempProductCategoryList = sortedCategories

                if (sortedCategories.isNotEmpty() && state.value.selectedCategory == null) {
                    onTemplateCategoryClick(
                        id = sortedCategories[0].id,
                    )

                } else {
                    hideProgress()
                }
            } catch (e: Exception) {
                console.log(e.toString())
                hideProgress()
                handleError(
                    error = e,
                )
            }
        }

    }




    private fun defaultCategorySorting(data: List<TemplateCategoryModel>):
            List<TemplateCategoryModel>
    {
//        val filteredData = data.filter {
//            it.position.toString().length == Constants.dataDigitLimit
//        }
        return data.sortedBy { it.position }
    }

    private fun changePage() {
        _state.value=_state.value.copy(
            firstPageActive = !_state.value.firstPageActive
        )
    }

    private fun refreshArticleList() {
        _state.value=_state.value.copy(
            articleList = tempArticleList,
            showArticleTitleSortIcon = false,
        )

    }



    private fun onSearchArticleInputChange(
        value: String,
    ) {
        _state.value=_state.value.copy(
            searchedProductValue=value,
            articleList = tempArticleList,
        )
        val filteredItems=state.value.articleList?.filter {
            it.name?.lowercase()?.contains(value.lowercase()) == true ||
                    it.code.toString().lowercase().contains(value.lowercase()) == true
        }
        _state.value=_state.value.copy(
            articleList = filteredItems
        )

    }


    private fun showProgress()
    {
        _state.value=_state.value.copy(
            dataLoadingProgressVisible = true
        )
    }
    private fun hideProgress()
    {
        _state.value=_state.value.copy(
            dataLoadingProgressVisible = false
        )
    }

}