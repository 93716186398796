package org.hs_soft.runmynesto.domain.usecase.home

import org.hs_soft.runmynesto.domain.repository.local_datasource.product.GetCompanyProfileIdRepository

class GetCompanyProfileIdUseCase(
    private val repository: GetCompanyProfileIdRepository
) {
    operator fun invoke():String{
        return repository.getCompanyProfileId()
    }


}