package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("getVirtualCardConfig")
    val getVirtualCardConfig: GetVirtualCardConfig?=null,
    @SerialName("updateVirtualCardConfig")
    val updateVirtualCardConfig:GetVirtualCardConfig?=null,
    @SerialName("__typename")
    val typename: String?
)