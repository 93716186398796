package org.hs_soft.runmynesto.data.api_datasource.statistic.tabs.bill_search


import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.init_search_bill.InitSearchBillModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.search_bill.InitSearchBillRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class InitSearchBillRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): InitSearchBillRepository {

    override suspend fun initSearchBill(
        billNumberFrom: Int?,
        billNumberTo: Int?,
        from: String,
        to: String,
        totalFrom: Int?,
        totalTo: Int?,
        cashRegisterIds: List<String>,
        products: List<String>
    ): InitSearchBillModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                query initSearchBills(${"$"}params: SearchBillsArgs!) {
                  cashAssist {
                    initSearchBills(params: ${"$"}params)
                    __typename
                  }
                }
            """.trimIndent())

            putJsonObject("variables") {
                putJsonObject("params"){
                    put("from",from)
                    put("to", to)
                    put("cashRegisters",
                        Json.encodeToJsonElement(cashRegisterIds as List<String>)
                    )
                    put("products",
                        Json.encodeToJsonElement(products as List<String>)
                    )
                    put("totalFrom", totalFrom)
                    put("billNumberFrom", billNumberFrom)
                    put("billNumberTo", billNumberTo)
                    put("totalTo", totalTo)
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }

}

