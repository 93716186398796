package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.upload_img


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Data(
    @SerialName("cashAssist")
    val cashAssist: CashAssist?
)