package org.hs_soft.runmynesto.data.api_datasource.closing_report

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.closing_reports.print_closing_reports_model.PrintClosingReportsModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.closing_report.PrintClosingReportsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class PrintClosingReportsRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): PrintClosingReportsRepository
{
    override suspend fun getPrintClosingReports(
        from: String,
        to: String,
        departments: List<String>,
        groupBy: String?,
        cacheKey:String?,
    ): PrintClosingReportsModel {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                   query getClosingsReport(${'$'}period: FullDateTimeIntervalInput!,
                    ${'$'}cashRegisterIds: [ID!],
                     ${'$'}departments: [String!],
                      ${'$'}groupBy: ClosingReportGroup, 
                      ${'$'}params: ListQueryArgs,
                       ${'$'}pagination: ClosingsReportPaginationInput) {
                     reports {
                       getClosingsReport(
                         period: ${'$'}period
                         cashRegisterIds: ${'$'}cashRegisterIds
                         departments: ${'$'}departments
                         groupBy: ${'$'}groupBy
                         params: ${'$'}params
                         pagination: ${'$'}pagination
                       ) {
                         ${ApiUtils.Field.url}
                         pagination {
                           ${ApiUtils.Field.cacheKey}
                           ${ApiUtils.Field.hasNext}
                           ${ApiUtils.Field.expectedUrl}
                       ${ApiUtils.Field.__typename}
                         }
                       ${ApiUtils.Field.__typename}
                       }
                       ${ApiUtils.Field.__typename}
                     }
                   }


            """.trimIndent())

            putJsonObject("variables") {
                put("departments",Json.encodeToJsonElement(departments))

                if (groupBy!=null){
                    put("groupBy",groupBy)
                }
                if (cacheKey!=null){
                    putJsonObject("pagination"){
                        put("cacheKey",cacheKey)
                    }
                }

                putJsonObject("period"){
                    put("from",from)
                    put("to",to)
                }

            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }
    }

}