package org.hs_soft.runmynesto.di.template

import org.hs_soft.runmynesto.domain.usecase.template.AddCategoryUseCase
import org.hs_soft.runmynesto.domain.usecase.template.GetTemplateCategoriesUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_checkout.UpdateTemplateCheckoutUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_options.GetPriceLevelsUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_surcharge_in_house.UpdatePriceLevelUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_surcharge_in_house.UpdateSurchargeUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.GetDishPictureUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.GetSingleTemplateCategoryUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.SetGridProductUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.UpdateListOfCategoriesPositionsUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.UpdateProductsPositionsUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.UpdateTemplateCategoryImgUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.UpdateTemplateCategoryUseCase
import org.koin.dsl.module

val templateUseCaseModule= module {

    single {
        GetTemplateCategoriesUseCase(get())
    }

    single {
        GetSingleTemplateCategoryUseCase(get())
    }

    single {
        AddCategoryUseCase(get())
    }

    single {
        UpdateProductsPositionsUseCase(get())
    }
    single {
        UpdateTemplateCategoryUseCase(get())
    }
    single {
        UpdateTemplateCategoryImgUseCase(get())
    }
    single {
        UpdateSurchargeUseCase(get())
    }
    single {
        GetPriceLevelsUseCase(get())
    }
    single {
        UpdatePriceLevelUseCase(get())
    }

    single {
        UpdateTemplateCheckoutUseCase(get())
    }
    single {
        GetDishPictureUseCase(get())
    }
    single {
        UpdateListOfCategoriesPositionsUseCase(get())
    }
    single {
        SetGridProductUseCase(get())
    }


}
