package org.hs_soft.runmynesto.data.local_datasource.menu_data

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.menu.MenuItemModel
import org.hs_soft.runmynesto.domain.model.menu.MenuItemsEnum
import org.hs_soft.runmynesto.domain.util.Res


object MenuItemsData{
    val data= listOf(
        MenuItemModel(
            id= MenuItemsEnum.DASHBOARD,
            title = MenuItemsEnum.DASHBOARD.title ,
            img = MenuItemsEnum.DASHBOARD.iconPath,
            subData = listOf()
        ),
        MenuItemModel(
            id = MenuItemsEnum.PRODUCT,
            title = MenuItemsEnum.PRODUCT.title ,
            img = MenuItemsEnum.PRODUCT.iconPath,
            subData = listOf()
        ),
        MenuItemModel(
            id= MenuItemsEnum.TEMPLATE,
            title = MenuItemsEnum.TEMPLATE.title ,
            img = MenuItemsEnum.TEMPLATE.iconPath ,
            subData = listOf(),
        ),
        MenuItemModel(
            id = MenuItemsEnum.USER,
            title = MenuItemsEnum.USER.title ,
            img = MenuItemsEnum.USER.iconPath ,
            subData = listOf()
        ),
        MenuItemModel(
            id = MenuItemsEnum.MEDIA_CENTER,
            title = MenuItemsEnum.MEDIA_CENTER.title,
            img = MenuItemsEnum.MEDIA_CENTER.iconPath,
            subData = listOf(),
        ),
        MenuItemModel(
            id = MenuItemsEnum.CUSTOMER_CARD,
            title = MenuItemsEnum.CUSTOMER_CARD.title,
            img = MenuItemsEnum.CUSTOMER_CARD.iconPath,
            subData = listOf(),
        ),
        MenuItemModel(
            id = MenuItemsEnum.CLOSING_REPORTS,
            title = MenuItemsEnum.CLOSING_REPORTS.title,
            img = MenuItemsEnum.CLOSING_REPORTS.iconPath,
            subData = listOf(),
        ),
        MenuItemModel(
            id = MenuItemsEnum.STATISTIC,
            title = MenuItemsEnum.STATISTIC.title,
            img = MenuItemsEnum.STATISTIC.iconPath,
            subData = listOf(),
        ),

        MenuItemModel(
            id= MenuItemsEnum.OPTIONS,
            title =MenuItemsEnum.OPTIONS.title,
            img = MenuItemsEnum.OPTIONS.iconPath,
            subData = listOf()
        ),


    )
}



