package org.hs_soft.runmynesto.data.api_datasource.product.tabs

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.add
import kotlinx.serialization.json.addJsonObject
import kotlinx.serialization.json.buildJsonArray
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductListModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.ProductCategoriesRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class ProductCategoriesRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): ProductCategoriesRepository {

    override suspend fun getProductCategories(
        searchQuery: String,
        isActive: Boolean?,
        categoryId:String?,
    ): ProductListModel {

        val abortController = requestManager.createController()


        val query =
            """
            query listProducts(${'$'}filter: ProductFilterArgs, ${'$'}params: ListQueryArgs) {
              cashAssist {
                listProducts(filter: ${'$'}filter, params: ${'$'}params) {
                  ...listProducts 
                }
                __typename
              }
            }
            
            fragment listProducts on ProductConnection {
              items {
                ${ApiUtils.Field.name}
                ${ApiUtils.Field.id}
                ${ApiUtils.Field.code}
                checkOutCommandId
                ${ApiUtils.Field.isActive}
                ${ApiUtils.Field.price}
                ${ApiUtils.Field.priceInCafe}
                FA_ProductId 
              }
              cursors {
                hasNext
                hasPrevious
                before
                after
                __typename
              }
              hasNextPage
              total
              __typename
            }
            """.trimIndent()

        val variables = buildJsonObject {
            put("params", buildJsonObject {
                put("sort", buildJsonArray {
                    addJsonObject {
                        put("field", "code")
                        put("order", "ASC")
                    }
                })
                put("cursors", buildJsonObject {


                })
                put("search", buildJsonObject {
                    put("string", searchQuery)
                    put("columns", buildJsonArray {
                        add("code")
                        add("name")
                    })
                })
                put("offset", 0)
            })
            put("filter", buildJsonObject {
                put("isActive", true)
                put("includeWawiProducts", true)
                put("includeCashassistProducts", true)
                if (categoryId!=null)
                    put("categoryId",categoryId)
            })
        }
        val graphQLQuery = buildJsonObject {
            put("query", query)
            put("variables", variables)
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController=abortController,
        )

        return if (result != null) {
            Json.decodeFromString<ProductListModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }

}
