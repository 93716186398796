package org.hs_soft.runmynesto.domain.model.menu

import org.hs_soft.kobweb_aws_cognito.domain.model.menu.MenuFooterModel
import org.hs_soft.runmynesto.data.local_datasource.menu_data.MenuFooterData
import org.hs_soft.runmynesto.data.local_datasource.menu_data.MenuHeaderData
import org.hs_soft.runmynesto.data.local_datasource.menu_data.MenuItemsData
import org.hs_soft.runmynesto.domain.config.Theme
import org.jetbrains.compose.web.css.CSSColorValue

data class MenuModel(
    val menuItemsData:List<MenuItemModel> = MenuItemsData.data,
    val footerData: MenuFooterModel = MenuFooterData.data,
    val menuHeaderData: MenuHeaderModel = MenuHeaderData.data,
    val hoverColor: CSSColorValue = Theme.GrayAlpha08.rgb,
//    val iconTint:String = Theme.Transparent.hex,
    val clickedItemBackground:CSSColorValue= Theme.GrayAlpha12.rgb,
    val footerBackground:CSSColorValue= Theme.Transparent.rgb,
    val menuBackground:CSSColorValue= Theme.Transparent.rgb,
    val headerBackground:CSSColorValue= Theme.Transparent.rgb,
)
