package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.series.reset_range_loyalty_card


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("resetRangeLoyaltyCards")
    val resetRangeLoyaltyCards: Int?,
    @SerialName("__typename")
    val typename: String?
)