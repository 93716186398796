package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.ShapeEnum
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.BoxColor
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun RestaurantTable(
    windowSizeMode: WindowSizeModeEnum,
    selectedTableColor: String,
    onRestaurantTableColorClick: () -> Unit,
    onSubmitClick: (ShapeEnum,String) -> Unit,
) {
    val number= remember { mutableStateOf("") }
    val shape= remember { mutableStateOf<ShapeEnum?>(null) }



    Column(
        modifier = Modifier
            .fillMaxWidth()
            .borderSetting()
            .margin(top = Dimen.normalPadding)
    )
    {
        SpanText(
            text = Strings.table,
            modifier = Modifier
                .minHeight(Dimen.minimumContentTitleHeight)
                .margin(left = Dimen.normalPadding)
                .fontWeight(FontWeight.Normal)
                .lineHeight(FontLineHeight.lineLarge)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Jakarta)
        )

        BoxColor(
            modifier = Modifier,
            color =selectedTableColor,
            title = Strings.color,
            onColorBoxClick = {
                onRestaurantTableColorClick()
            }
        )

        CustomEditText(
            modifier = Modifier,
            marginTop = Dimen.normalPadding,
            width = Dimen.normalEditTextWidth,
            label = Strings.number,
            value = "",
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {

            },
            onInputChange = {
                number.value=it
            },
        )

        Row()
        {
            Box(
                modifier = Modifier
                    .rippleEffect {
                        shape.value=ShapeEnum.ROUND
                    }
                    .generalDialogSetting()
                    .backgroundColor(
                        when(shape.value){
                            ShapeEnum.RECTAGNLE ->Theme.Transparent.rgb
                            ShapeEnum.ROUND ->Theme.YellowAlpha20.rgb
                            else ->Theme.Transparent.rgb
                        }
                    )
                    .margin(right = Dimen.mediumPadding)
                    .size(Dimen.restaurantTableRectSize)
            )
            {
                Box(
                    modifier = Modifier
                        .align(Alignment.Center)
                        .size(Dimen.extraLargeIconSize.px)
                        .borderRadius(50.percent)
                        .backgroundColor(Theme.GrayAlpha12.rgb)
                )
            }

            Box(
                modifier = Modifier
                    .rippleEffect {
                        shape.value=ShapeEnum.RECTAGNLE
                    }
                    .generalDialogSetting()
                    .backgroundColor(
                        when(shape.value){
                            ShapeEnum.RECTAGNLE ->Theme.YellowAlpha20.rgb
                            ShapeEnum.ROUND ->Theme.Transparent.rgb
                            else ->Theme.Transparent.rgb
                        }
                    )
                    .size(Dimen.restaurantTableRectSize)
            )
            {
                Box(
                    modifier = Modifier
                        .align(Alignment.Center)
                        .size(Dimen.extraLargeIconSize.px)
                        .backgroundColor(Theme.GrayAlpha12.rgb)
                )
            }

        }

        CustomButton(
            modifier = Modifier,
            topBottomMargin = Dimen.normalPadding,
            title = Strings.add,
            leftRightMargin = 0.px,
            isClickable = shape.value!=null,
            defaultTextColor = Theme.White.rgb,
            onButtonClick = {
                onSubmitClick(shape.value!!,number.value)
            },
        )


    }
    
}
