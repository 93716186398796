package org.hs_soft.runmynesto.data.api_datasource.user.tab_right

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_rights.UserRightModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.tab_right.UpdateUserPermissionsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateUserPermissionRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateUserPermissionsRepository {


    override suspend fun updateUserPermissions(
        dataTitle: String,
        dataValue: dynamic,
        userId: String
    ): UserRightModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            mutation updateUser_Permissions(${'$'}id: ID!, ${'$'}fields: UserFields!) {
              cashAssist {
                updateUser(id: ${'$'}id, fields: ${'$'}fields) {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.mod_ver}
                  ...permissions
                  ${ApiUtils.Field.__typename}
                }
                ${ApiUtils.Field.__typename}
              }
            }
            fragment permissions on User {
              ${ApiUtils.Field.isAdmin}
              ${ApiUtils.Field.isManager}
              ${ApiUtils.Field.isFinStatements}
              ${ApiUtils.Field.isDayFinStatements}
              ${ApiUtils.Field.isViewSales}
              ${ApiUtils.Field.isTimeTracking}
              ${ApiUtils.Field.typeRFID}
              ${ApiUtils.Field.cancellationPermissions}
              ${ApiUtils.Field.__typename}
            }
        """.trimIndent())

            putJsonObject("variables") {
                put("id", userId)
                putJsonObject("fields") {
                    when(dataValue){
                        is String -> put(dataTitle,dataValue as String)
                        is Boolean ->put(dataTitle,dataValue as Boolean)
                    }

                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<UserRightModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }



    }

}
