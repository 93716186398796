package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ClosingReportItem(
    @SerialName("cashRegister")
    val cashRegister: CashRegister?,
    @SerialName("dateTime")
    val dateTime: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("inactive")
    val inactive: Boolean?,
    @SerialName("number")
    val number: Int?,
    @SerialName("salesGroups")
    val salesGroups: List<SalesGroup>?,
    @SerialName("total")
    val total: Double?,
    @SerialName("__typename")
    val typename: String?
)