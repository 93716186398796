package org.hs_soft.runmynesto.domain.config

object Strings
{
    const val group="Group"
    const val groupByDateShop="Group by Date/Shop"
    const val groupByShop="Group by Shop"
    const val groupByDate="Group by Date"
    const val inTotal="In Total"
    const val printFinancialStatements="Print Financial Statements"
    const val actions="Actions"
    const val selectedItem="Selected Item"
    const val exportExcel="Export excel"
    const val price="Price"
    const val percentSign="%"
    const val code="Code"
    const val productDateShop="Product-Date-Shop"
    const val productDate="Product-Date"
    const val dateProduct="Date-Product"
    const val productShop="Product-Shop"
    const val shopProduct="Shop-Product"
    const val lastSale="Last Sale"
    const val weight="Weight"
    const val firstSale="First Sale"
    const val groupedBy="Grouped by"
    const val notCancelled="Not Cancelled"
    const val cancelled="Cancelled"
    const val userName="UserName"
    const val total ="Total"
    const val cancellation="Cancellation"
    const val pdf="PDF"
    const val billNumberFrom="Bill Number (from)"
    const val BillNumberTo="Bill Number (to)"
    const val totalTo="Total (to)"
    const val totalFrom="Total (from)"
    const val abcStatistic="ABC Statistic"
    const val billSearch="Bill Search"
    const val setting="Setting"
    const val statistic="Statistic"
    const val threeWeeksAgo="3 weeks ago"
    const val twoWeeksAgo="2 weeks ago"
    const val lastWeek="Last week"
    const val compare="Compare"
    const val sales="Sales"
    const val allTheRegions="All the regions"
    const val clients="Clients"
    const val workingHours="Working hours"
    const val hourlyRate="Hourly rate"
    const val averageCheck="Average check"
    const val turnOver="Turnover"
    const val euro="€"
    const val cashRegisters="Cash Registers"
    const val allRegions="All Regions"
    const val dashboard: String="Dashboard"
    const val backgroundColor="Background Color"
    const val fakeLatestPurchasesAndMore="https://shortlink.com/**********"
    const val fakeQRCode="0000-0000-0000-0000"
    const val fakeCustomerNumber="0000001"
    const val incomeAndExpanse="Income $ Expanses"
    const val cashBookAndCoupons="CashBook $ Coupons"
    const val credit="Credit"
    const val pressets="Pressets"
    const val apply="Apply"
    const val amountCHF="Amount CHF"
    const val amount="Amount"
    const val difference="Difference"
    const val cashDelivery="Cash Delivery"
    const val IsnInCHF="Isn in CHF"
    const val course="Course"
    const val lst="lst"
    const val currency="Currency"
    const val reduction="Reduction"
    const val freeVat="Free Vat"
    const val empty="Empty"
    const val reports="Reports"
    const val FROM_DATE = "From Date"
    const val GROUP = "group"
    const val BALANCE = "balance"
    const val BOOKING_MODE = "booking mode"
    const val UNTIL_DATE = "Until Date"
    const val CHECKOUT = "Checkout"
    const val CREDIT = "Credit"
    const val POINTS = "points"
    const val CHECKOUT_DATE = "checkout date"
    const val DESIGNATION = "Designation"
    const val DOCUMENT_NUMBER = "document number"
    const val BURDEN = "Burden"
    const val POINTS_BALANCE = "points balance"
    const val CASH_REGISTER_TIME = "cash register time"
    const val cashRegisterName="Cash Register Name"
    const val selectDesiredColumns="Select the desired columns"
    const val transaction="Transactions"
    const val addNewCustomerCard="Add New Customer Card"
    const val digitalCustomerCard="Digital Customer Card"
    const val rfidCustomerCard="RFID Customer Card"
    const val zeroSetting="No Setting"
    const val none="None"
    const val discountGroup1 = "Discount Group 1"
    const val discountGroup2 = "Discount Group 2"
    const val discountGroup3 = "Discount Group 3"
    const val discountGroup4 = "Discount Group 4"
    const val discountGroup5 = "Discount Group 5"
    const val discountGroup6 = "Discount Group 6"
    const val discountGroup7 = "Discount Group 7"
    const val discountGroup8 = "Discount Group 8"
    const val discountGroup9 = "Discount Group 9"
    const val discountGroup10 = "Discount Group 10"
    const val discountGroup11 = "Discount Group 11"
    const val discountGroup12 = "Discount Group 12"
    const val discountGroup13 = "Discount Group 13"
    const val discountGroup14 = "Discount Group 14"
    const val discountGroup15 = "Discount Group 15"
    const val discountGroup16 = "Discount Group 16"
    const val discountGroup17 = "Discount Group 17"
    const val discountGroup18 = "Discount Group 18"
    const val discountGroup19 = "Discount Group 19"
    const val discountGroup20 = "Discount Group 20"
    const val inHouseCard="In House Card"
    const val loyaltyCard="Loyalty Card"
    const val clientName="Client Name"
    const val salutation="Salutation"
    const val resetBooking="Reset Booking"
    const val cardHolder="Card Holder"
    const val loadLabels="Load Labels"
    const val loadMaps="Load Maps"
    const val loadingLabeling="Loading/Labeling"
    const val labelingWorkCards="Labeling Work Cards"
    const val loadingWorkCards="Loading Work Cards"
    const val createCustomerCard="Create Customer Card"
    const val until="Until"
    const val from="From"
    const val loyaltyCards="Loyalty Cards"
    const val calculation="Calculation"
    const val end="End"
    const val start="Start"
    const val period="Period"
    const val January = "January"
    const val February = "February"
    const val March = "March"
    const val April = "April"
    const val May = "May"
    const val June = "June"
    const val July = "July"
    const val August = "August"
    const val September = "September"
    const val October = "October"
    const val November = "November"
    const val December = "December"

    const val fourQuarters="Q4"
    const val threeQuarters="Q3"
    const val twoQuarters="Q2"
    const val oneQuarter="Q1"
    const val twoHalfAYear="2ST HALF"
    const val oneHalfAYear="1ST HALF"
    const val next="Next"
    const val previous="Previous"
    const val previousWeek="Previous Week"
    const val thisWeek="This Week"
    const val yesterday="Yesterday"
    const val today="Today"
    const val rfid="RFID"
    const val barcode="BarCode"
    const val collectPointsWhenCharged="Collect points only when charged"
    const val cardCanOnlyBeChargedByCash="Card can only be topped up with cash"
    const val grantDiscountOnNetItems="Also grant a discount on net items"
    const val grantDiscountWhenCharged="Grant discount only when charged"
    const val discountNumber="Discount No."
    const val discountGLWithPercent="Rabatt GL, %"
    const val discountWithPercent="Discount, %"
    const val debitCard="Debit Card"
    const val cardType="Card Type"
    const val art="Art"
    const val owner="Owner"
    const val email="Email"
    const val telephone="Telephone"
    const val firstName="First Name"
    const val address="Address"
    const val zipCity="Zip/City"
    const val cardLimit="Card Limit"
    const val dailyLimit="Daily Limit"
    const val card="Card"
    const val cardNumber="Card Number"
    const val lastUsed="Last Used"
    const val internetAccessCode="Internet Access Code"
    const val series="Series"
    const val customerCards="Customer Cards"
    const val points="Points"
    const val ipAddress="IP Address"
    const val kitchenPrinterSerialNumber="Kitchen Printer Serial Number"
    const val addCakeDisplay="Add Cake Display"
    const val serialNumber="Serial Number"
    const val logout="Log out"
    const val backToAdmin="Back to Admin mode"
    const val myProfile="My Profile"
    const val globalSearch="Global Search"
    const val noItemToDisplay="No items to display"
    const val standardInBracket="<Standard>"
    const val mwstNormal="MWST-Normal"
    const val salesVolume="Sales Volume"
    const val time="Time"
    const val date="Date"
    const val closingReports="Closing Reports"
    const val standardWithoutPrinting="Standard without printing"
    const val withCashReportAndCountProtocol="With cash report and count protocol"
    const val withoutCash="Without cash"
    const val standard="Standard"
    const val withCashReport="With cash report"
    const val vatRateSelection="VAT Rate Selection"
    const val specialVat="Special Vat"
    const val reducedVat="Reduced Vat"
    const val normalVat="Normal Vat"
    const val office="Office"
    const val priceLevel="Price Level"
    const val region="Region"
    const val priceLevelInHouseButton="Price Level In House Button"
    const val branch="Branch"
    const val mode="Mode"
    const val cashierName="Cashier Name"
    const val topEdgeCm="Oberer Rand, cm"
    const val separateCakeToppings="Separate Cake Toppings"
    const val tableShift="Table Shift"
    const val copyForWaiter="Copy for waiter"
    const val printer="Printer"
    const val printKitchenOnlyForTables="Print kitchen receipt for tables only"
    const val printReceiptAfterCompletion="Print kitchen receipt only after receipt completion"
    const val activeKitchenPrinter="Activate kitchen printer"
    const val hardware="hardware"
    const val receiptCopy="Receipt Copy"
    const val fromAmount="From Amount"
    const val variable="Variable"
    const val inTheHouse="In the House"
    const val degree: String="Degree"
    const val activateEmployeeCompletion="Activate employee completion"
    const val degreeType="Degree Type"
    const val close="Close"
    const val previewForAppleWallet: String="Preview for Apple Wallet"
    const val latestPurchasesAndMore="Latest purchases and more"
    const val phone="Phone"
    const val qrCode="QR-Code"
    const val customerName: String="Customer Name"
    const val customerNumber="Customer Number"
    const val cardName="Card Name"
    const val specialDiscountTier ="Special Discount Tier"
    const val discountTier="Discount Tier"
    const val aboutTheCard="More about the card is on the back!"
    const val cashAssistCard="Cash Assist Card"
    const val balance="Balance"
    const val customerCard="Customer Card"
    const val previewForGoogleWallet="Preview for Google Wallet"
    const val cardPreview="Card preview"
    const val cardInfoPreview="Card info preview"
    const val cashRegisterConfig="Cash Register Config"
    const val menuCardImport="Menu Card Import"
    const val advertisingText="Advertising Text"
    const val optionalTwo="Optional Two"
    const val optionalOne="Optional One"
    const val vatNumber="VAT Number"
    const val addition="Addition"
    const val mfaCode="MFA Code"
    const val incomeExpanses="Inc/Expanses"
    const val incomeExpanses1="Inc/Expanses 1"
    const val incomeExpanses2="Inc/Expanses 2"
    const val incomeExpanses3="Inc/Expanses 3"
    const val incomeExpanses4="Inc/Expanses 4"
    const val incomeExpanses5="Inc/Expanses 5"
    const val name1="Name 1"
    const val name2="Name 2"
    const val name3="Name 3"
    const val name4="Name 4"
    const val name5="Name 5"
    const val name6="Name 6"
    const val name7="Name 7"
    const val name8="Name 8"
    const val name9="Name 9"
    const val name10="Name 10"
    const val creditCard="Credit Card"
    const val cashFlow="Cash Flow"
    const val companyLogo="Company Logo"
    const val colorIsMissing="Color is missing"
    const val titleFontColor="Title font color"
    const val fontColor="Font Color"
    const val preview="Preview"
    const val wideCompanyLogo="Wide company logo"
    const val heroImage="Hero image"
    const val googleWallet="Google Wallet"
    const val appleWallet="Apple Wallet"
    const val website="Website"
    const val titleWebsite="Title Website"
    const val telephoneNumber="Telephone Number"
    const val titleContact="Title Contact"
    const val title2="Title 2"
    const val titleField2="Title Field 2"
    const val title1="Title1"
    const val titleField1="Title Field1"
    const val description="Description"
    const val emailCompany="Email Company"
    const val activeCustomersCard="Mobile customer cards are active"
    const val virtualCard="Virtual Card"
    const val addNewLocation="Add New Location"
    const val uploadFromPc="Upload From Local"
    const val prompt: String="Prompt"
    const val newPicture="New Picture"
    const val newMenuCard="New Menu Card"
    const val price2="Price 2"
    const val price1="Price 1"
    const val numberDot="No."
    const val addNewItem="Add New Item"
    const val variant1="Variant 1"
    const val variant2="Variant 2"
    const val variant3="Variant 3"
    const val variant4="Variant 4"
    const val variant5="Variant 5"
    const val variant6="Variant 6"
    const val variant7="Variant 7"
    const val variant8="Variant 8"
    const val variant9="Variant 9"
    const val variant10="Variant 10"
    const val kitchenInformation="Kitchen Information"
    const val items: String="Items"
    const val addNewSaleGroup="Add New Sale Group"
    const val submit: String="Submit"
    const val inactiveItems: String="Inactive Items"
    const val activeItems: String="Active Items"
    const val allItems: String="All Items"
    const val individualFilter: String="Individual Filter"
    const val timeoutErr="Request Timeout"
    const val addNewProduct="Add a New Product"
    const val numberOrNameIsWrong="Number or Name is wrong."
    const val products: String="Products"
    const val companyProfile: String="Company Profile"
    const val imageAiDialogWaitingMsg: String="Process may take more than 30 s"
    const val menuCardAIDialogWaitingMsg: String="Process may take more than 10 m"
    const val generate: String="Generate"
    const val hint: String="Hint"
    const val allDays="All Days"
    const val mondayFull = "Monday"
    const val tuesdayFull = "Tuesday"
    const val wednesdayFull = "Wednesday"
    const val thursdayFull = "Thursday"
    const val fridayFull = "Friday"
    const val saturdayFull = "Saturday"
    const val sundayFull = "Sunday"

    const val selectDays="Select Days"
    const val color: String="Color"
    const val changesValidFrom: String="Changes Valid From"
    const val surchargePercent: String="Surcharge %"
    const val away: String="Away"
    const val surchargeAmount: String="Surcharge Amount"
    const val priceLevels="Price Levels"
    const val surchargeInHouse: String="SurchargeInHouse"
    const val contain: String="Contain"
    const val allergens: String = "Allergens"
    const val deliveryTimeInDays="Delivery Days"
    const val minimumOrder="Minimum Order"
    const val maximumOrder="Maximum Order"
    const val vat="VAT"
    const val printKitchenReceiptIfInfoSelected
        ="Print kitchen receipt only when kitchen information is selected"
    const val printKitchenReceiptIfInHouseActive
        ="Print kitchen receipt only when In-House button is activated"

    const val kitchen: String="Kitchen"
    const val restaurant: String="Restaurant"
    const val prices: String="Prices"
    const val discount: String="Discount"
    const val product: String="Product"
    const val slash="/"
    const val noDiscountPossible="Customer net items (no discount possible)"
    const val employeeNetItems="Employee net items"
    const val employeeConsumption="Employee consumption"
    const val interimFinancialStatement: String="Interim Financial Statement"
    const val enableUsersOnlyForTimeTracking="Enable Users Only For Time Tracking"
    const val allReceipts: String="All Receipts"
    const val noAuthorization: String="No Authorization"
    const val onlyOwnReceipts="Only Own Receipts"
    const val gastronomy: String="Gastronomy"
    const val shortName:String="Short Name"
    const val addNewUser: String="Add New User"
    const val addNewCategory: String="Add New Category"
    const val img:String="Image"
    const val menuCard:String="Menu Card"
    const val remove:String="Remove"
    const val confirm: String="Confirm"
    const val cancel="Cancel"
    const val printName: String="Print Name"
    const val users: String="Users"

    const val articleList: String="Article List"
    const val operation: String="Operation"
    const val general: String="General"
    const val userPhoto:String="User Photo"
    const val title: String="Title"
    const val filter="Filter"
    const val fibuNormal: String="Fibu Normal"
    const val financialReduced: String="Reduced"
    const val accounting: String="Accounting"
    const val accountingPercent: String="Accounting 0%"
    const val article = "Article"
    const val template = "Template"
    const val options = "Options"
    const val salesGroups = "Sales Groups"
    const val kitchenLocations = "Kitchen Locations"
    const val clickAndCollect = "Click&Collect"
    const val update = "Update"

    const val DAYS_END = "Day's End"
    const val ADMINISTRATOR = "Administrator"
    const val MANAGER = "Manager (Manager can access other users)"
    const val NO_SALES_TRIGGER = "No Sales Trigger (Maison)"

    const val cancellationRight="Cancellation Rights"
    const val activate: String="Activate"
    const val receiptName: String="Receipt Name"
    const val password: String="Password"
    const val name: String="Name"
    const val waitersCastle: String="Waiter's Castle"
    const val right: String="Right"
    const val devices: String="Devices"
    const val user="User"

    const val search: String="Search"
    const val number="Number"
    const val language="Language"
    const val street="Street"
    const val label="Label"
    const val companyName="Company Name"
    const val zip="Zip"
    const val city="City"


    const val APP_NAME_PART1 = "RunMy"
    const val APP_NAME_PART2 = "Setup"

    //--------------------
    const val saturday="S"
    const val sunday="S"
    const val monday="M"
    const val tuesday="T"
    const val wednesday="W"
    const val thursday="T"
    const val friday="F"


    // User details
    const val USER_NAME = "Jens Lehman"
    const val COMPANY_NAME = "HS-Soft AG"

    //Login
    const val forgetYourPassword: String="Forget your password?"
    const val login: String="Login"
    const val enterYourPassword: String="Enter Password *"
    const val enterYourEmail: String="Enter email *"
}