package org.hs_soft.runmynesto.domain.config

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba

enum class Theme(
    val hex: String,
    val rgb: CSSColorValue,
) {
    Primary(
        hex = "#000000",
        rgb = rgba(r = 0, g = 0, b = 0, a = 1),
    ),

    Secondary(
        hex = "#000000DD",
        rgb = rgba(r = 0, g = 0, b = 0, a = 0.87),
    ),
    Black43(
        hex = "#434343",
        rgb=rgba(67, 67, 67, 1)
    ),
    Blue(
        hex = "#2196F3",
        rgb =rgba(33, 150, 243, 1)
    ),


    LightBlue(
        hex = "#64B5F6",
        rgb = rgba(100, 181, 246, 1)
    ),
    ExtraLightBlueAlpha12(
        hex = "#2196F31F",
        rgb= rgba(33, 150, 243, 0.12)
    ),
    ExtraLightBlueAlpha08(
        hex = "#2196F314",
        rgb = rgba(33, 150, 243, 0.08)
    ),
    Orange(
        hex ="#EF6C00" ,
        rgb=rgb(239, 108, 0)
    ),
    ExtraLightOrange(
        hex="#EF6C001F",
        rgb= rgba(239, 108, 0, 0.12)
    ),
    Emerald(
        hex = "#26C6DA",
        rgb=rgb(38, 198, 218),
    ),
    White(
        hex = "#FFFFFF",
        rgb = rgb(r = 255, g = 255, b = 255),
    ),
    Red(
        hex = "#FF0000",
        rgb = rgba(255, 0, 0, 1)
    ),
    Transparent(
        hex = "#00000000",
        rgb = rgba(r = 0, g = 0, b = 0, a = 0.0),
    ),
    YellowAlpha20(
        hex = "#4AFFFF00",
        rgb = rgba(255, 255, 0, 0.4)
    ),
    Yellow700(
        hex = "#FBC02D",
        rgb= rgb(251, 192, 45)
    ),

    GrayCD(
        hex="#CDCDCD",
        rgb=rgb(r=205, g=205, b=205)
    ),
    DarkBlue(
        hex = "#0D47A1",
        rgb = rgba(13, 71, 161, 1)
    ),
    Gray3E(
        hex = "#3E3E3E",
        rgb= rgb(62,62,62)
    ),
    GrayAlpha38(
        hex = "#00000061",
        rgb= rgba(0, 0, 0, 0.38)
    ),

    GrayAlpha12(
        hex = "#0000001F",
        rgb= rgba(0,0,0,0.12),
    ),

    Gray61(
        hex ="#616161E5" ,
        rgb= rgba(97,97,97,a=0.9f)
    ),

    GrayLow(
        hex = "#E0E0E0",
        rgb=rgb(224, 224, 224)
    ),
    GrayAlpha02(
      hex = "00000005",
      rgb= rgba(0,0,0,0.02)
    ),
    GrayAlpha04(
        hex = "#0000000A",
        rgb= rgba(0,0,0,0.04),
    ),
    GrayAlpha08(
        hex = "#00000014",
        rgb= rgba(0,0,0,0.08),
    ),
    WhiteFA(
        hex = "#FAFAFA",
        rgb=rgba(250, 250, 250, 1),
    ),
    GrayAlpha56(
        hex ="#0000008F" ,
        rgb=rgba(0, 0, 0, 0.60)
    ),
    GrayAlpha60(
        hex = "#00000099",
        rgb= rgba(0, 0, 0, 0.6)
    ),
    Gray9E(
        hex = "#9E9E9E",
        rgb= rgba(158, 158, 158, 1)
    ),


    Transparent10Percent(
        hex = "#00000008",
        rgb = rgba(r = 0, g = 0, b = 0, a = 0.2),
    ),
    DarkGreen(
        hex = "#279E5E",
        rgb= rgb(39, 158, 94)
    ),
    Green(
        hex = "#31C676",
        rgb= rgb(49, 198, 118)
    ),
    LightGreen(
        hex = "#8BC34A",
        rgb=rgb(139, 195, 74)
    ),
    ExtraLightGreen(
        hex = "#c9fad0",
        rgb =rgb(201, 250, 208)
    ),
    Green45Alpha10(
      hex = "#45DB5D1A",
      rgb=rgba(69, 219, 93, 0.1),
    ),
    Green45Alpha25(
        hex = "#45DB5D40",
        rgb=rgba(69, 219, 93, 0.25),
    ),
    Green45Alpha80(
        hex="#45DB5DCC",
        rgb =rgba(69, 219, 93, 0.8)
    ),
    Green45(
        hex="#45DB5D",
        rgb=rgba(69, 219, 93, 1),
    ),

    PurpuleAlpha50(
        hex ="#9C27B080",
        rgb= rgba(156, 39, 176, 0.5)
    ),
    Purpule(
        hex ="#9C27B0",
        rgb= rgba(156, 39, 176, 1)
    )


}