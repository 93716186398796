package org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.init_abc_statistic


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("initAbcStatistics")
    val initAbcStatistics: String?,
    @SerialName("__typename")
    val typename: String?
)