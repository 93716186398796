package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.InfiniteScrollGrid

@Composable
fun AddProductToArticleDialog(
    errMsg:String,
    searchedProductItems: List<ProductItem>,
    onBackgroundClick: () -> Unit,
    onItemClick:(String)->Unit,
)
{
    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                onBackgroundClick()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(Constants.ZIndex.normalZIndex)
    ){
        Column(
            modifier = Modifier
                .onClick {
                        event: SyntheticMouseEvent -> event.stopPropagation()
                }
                .align(Alignment.Center)
                .padding(topBottom = Dimen.mediumPadding)
                .width(Dimen.imageAIDialogWidth)
                .height(Dimen.imageAIDialogHeight)
                .backgroundColor(Theme.White.rgb)
                .zIndex(Constants.ZIndex.smallZIndex)
                .boxShadow(
                    color = Theme.GrayCD.rgb,
                    blurRadius = Dimen.smallBlurRadius,
                )
                .borderRadius(r= Dimen.lowBorderRadius),
            horizontalAlignment = Alignment.CenterHorizontally
        ){
            SpanText(
                text = Strings.addNewProduct,
                modifier = Modifier
                    .fontSize(FontSizes.mediumFontSize)
                    .fontWeight(FontWeight.Medium)
                    .fontFamily(FontFamily.Montserrat)
                    .color(Theme.DarkBlue.rgb)
                    .margin(bottom = Dimen.normalPadding)
            )

            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .backgroundColor(Theme.GrayCD.rgb)
                    .height(Dimen.smallThickness)
            )

            InfiniteScrollGrid(
                modifier = Modifier
                    .fillMaxHeight()
                    .fillMaxWidth()
                    .weight(1f)
                ,
                items = searchedProductItems,
                onLoadMore = {}
            ){
                val model=searchedProductItems[it]
                productItem(
                    onItemClick={
                        onItemClick(model.id)
                    },
                    model=model
                )
            }



            Spacer()


            Box(
                modifier = Modifier.fillMaxWidth()
                    .padding(Dimen.normalPadding)
            ){
                SpanText(
                    text =
                    if (
                        searchedProductItems.isEmpty() ||
                        searchedProductItems.isEmpty()
                    )
                        searchedProductItems.size.toString()+" "+ Strings.product
                    else searchedProductItems.size.toString()+" "+ Strings.products,
                    modifier = Modifier
                        .fontSize(FontSizes.normalFontSize)
                        .fontWeight(FontWeight.Medium)
                        .fontFamily(FontFamily.Montserrat)
                        .color(Theme.DarkBlue.rgb)
                        .align(Alignment.CenterEnd)
                )


                SpanText(
                    text =errMsg,
                    modifier = Modifier
                        .margin(right = Dimen.normalPadding)
                        .fontSize(FontSizes.smallFontSize)
                        .fontWeight(FontWeight.Medium)
                        .fontFamily(FontFamily.Montserrat)
                        .color(Theme.Red.rgb)
                        .align(Alignment.CenterStart)
                )


            }



        }
    }



}

@Composable
private fun productItem(
    onItemClick: (String) -> Unit,
    model: ProductItem
) {
    val isHovered = remember { mutableStateOf(false) }
//    val isSelected = remember { mutableStateOf(false) }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .height(Dimen.gridHeaderHeight)
            .cursor(Cursor.Pointer)
            .onClick {
//                isSelected.value = true
                onItemClick(model.id)

            }
            .onMouseLeave {
                isHovered.value = false
            }
            .onMouseOver {
                isHovered.value = true
            }
            .backgroundColor(
                when {
//                    isSelected.value -> Theme.GrayAlph12.rgb
                    isHovered.value -> Theme.GrayAlpha04.rgb
                    else -> Theme.Transparent.rgb
                }
            )
            ,
            verticalArrangement = Arrangement.Center
    ) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .height(Dimen.gridHeaderHeight)

        )
        {
            SpanText(
                text = model.name ?: "",
                modifier = Modifier
                    .margin(left = Dimen.normalPadding)
                    .align(Alignment.CenterStart)
                    .color(Theme.Primary.rgb)
                    .fontFamily(FontFamily.Montserrat)
                    .fontSize(FontSizes.normalFontSize)

            )

            Box(
                modifier = Modifier
                    .align(Alignment.BottomStart)
                    .fillMaxWidth()
                    .backgroundColor(Theme.GrayCD.rgb)
                    .height(Dimen.smallThickness)
            )

        }

    }
}