package org.hs_soft.runmynesto.domain.usecase.product.tab_allergens

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.checked_ingredients_list.CheckedIngredientData
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_allergens.CheckedIngredientsRepository


class CheckedIngredientsUseCase(
    private val repository: CheckedIngredientsRepository
) {
    suspend operator fun invoke(
        productId:String
    ): CheckedIngredientData? {
        return repository.getCheckedIngredients(productId=productId)
    }


}