package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.days_filter_dialog

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.color
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CircleIcon
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.RadioGroup
import org.jetbrains.compose.web.dom.RadioInput

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun ClosingReportsDaysFilterDialog(
    modifier: Modifier=Modifier,
    selectedPeriodType: PeriodDaysEnum,
    submitFilterItems:(PeriodDaysEnum)->Unit,
    onCloseFilterClick:()->Unit,
)
{
    val newSelectedPeriodType= remember{ mutableStateOf(selectedPeriodType) }

    Column(
        modifier = modifier
            .margin(top = Dimen.normalPadding)
            .width(Dimen.daysFilterDialogWidth)
            .height(Dimen.daysFilterDialogHeight)
            .generalDialogSetting()
            .zIndex(Constants.ZIndex.mediumZIndex)
            .padding(Dimen.normalPadding)
            .border(
                width= Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            ) ,
        horizontalAlignment = Alignment.CenterHorizontally

    ) {
        Row(
            modifier = Modifier
                .margin(bottom = Dimen.mediumPadding)
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            SpanText(
                text = Strings.filter,
                modifier = Modifier
                    .fontSize(FontSizes.extraLargeFontSize)
                    .color(Theme.Secondary.rgb)
                    .fontFamily(FontFamily.Jakarta)
                    .fontWeight(FontWeight.Medium)
            )

            Spacer()

            CircleIcon(
                size = Dimen.semiLargeIconSize,
                img = Res.PathIcon.largeClose,
                borderColor = Theme.Transparent.rgb,
                onIconClick = {
                    onCloseFilterClick()
                },
                modifier = Modifier.margin(right = Dimen.mediumPadding)
            )



        }


        Column(
            modifier = Modifier
                .margin(Dimen.mediumPadding)
                .padding(leftRight = Dimen.normalPadding)
                .height(Dimen.daysFilterContentHeight)
                .fillMaxWidth()
        )
        {
            RadioGroup(newSelectedPeriodType.value.title) {
                PeriodDaysEnum.entries.forEach{ filter ->
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .cursor(Cursor.Pointer)
                            .position(Position.Relative)
                            .padding(topBottom = Dimen.normalPadding)
                            .rippleEffect {
                                if (filter != newSelectedPeriodType.value)
                                    newSelectedPeriodType.value=filter
                            }
                            .margin(
                                top = Dimen.smallPadding,
                                bottom = Dimen.largePadding
                            )
                    )
                    {

                        RadioInput(
                            value = filter.title,
                            attrs = Modifier
                                .cursor(Cursor.Pointer)
                                .padding(Dimen.normalPadding)
                                .toAttrs {
                                    style {
                                        color(Theme.Secondary.hex)
                                        width(Dimen.mediumRadioButton)
                                        height(Dimen.mediumRadioButton)
                                    }
                                    checked(filter == newSelectedPeriodType.value)

                                }
                        )
                        SpanText(
                            text = filter.title,
                            modifier = Modifier
                                .cursor(Cursor.Pointer)
                                .margin(leftRight = Dimen.normalPadding)
                                .fontSize( FontSizes.mediumFontSize )
                                .color(Theme.Secondary.rgb)
                                .fontWeight(FontWeight.Medium)
                                .fontFamily(FontFamily.Jakarta)
                        )
                    }

                    if (filter==PeriodDaysEnum.YESTERDAY){
                        Spacer()
                    }


                }
            }
        }

        CustomButton(
            modifier = Modifier.fillMaxWidth(),
            leftRightMargin = Dimen.mediumPadding,
            title = Strings.apply,
            onButtonClick = {
                submitFilterItems(
                    newSelectedPeriodType.value
                )
            },
            defaultTextColor = Theme.White.rgb,
        )



    }





}