package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class GetCategory(

    @SerialName("cashRegisterId")
    val cashRegisterId: List<String> = listOf(),
    @SerialName("surchargeAmount")
    val surchargeAmount:List<Int?> = listOf(),
    @SerialName("surchargeFrom")
    val surchargeFrom:List<Int?> = listOf(),
    @SerialName("surchargePercent")
    val surchargePercent:List<Int?> = listOf(),
    @SerialName("validAfter")
    val validAfter:String ?=null,
    @SerialName("color")
    val color:String?=null,
    @SerialName("weekDays")
    val weekDays:Int=0,
    @SerialName("gridProducts")
    val gridProducts: List<ArticleModel> = listOf(),
    val id: String ="",
    val img: Img?= Img(),
    val isActive: Boolean=false,
    val name: String="",
    val priceLevel:Int?=-1,
    val position: Int=-1,



    )