package org.hs_soft.runmynesto.data.api_datasource.statistic.tabs.abc_statics


import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.init_abc_statistic.InitABCStatisticModel
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.init_search_bill.InitSearchBillModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.abc_statistic.InitABCStatisticRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.search_bill.InitSearchBillRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class InitABCStatisticRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): InitABCStatisticRepository {


    override suspend fun initABCStatistic(
        cashRegisterIds: List<String>,
        from: String,
        groupBy: String,
        productIds: List<String>,
        to: String,
    ): InitABCStatisticModel {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
               
               query initAbcStatistics(${'$'}params: StatisticsRequestParams!) {
                 cashAssist {
                   initAbcStatistics(params:${'$'}params)
                   ${ApiUtils.Field.__typename}
                 }
               }

               
            """.trimIndent())

            putJsonObject("variables") {
                putJsonObject("params"){
                    put("from",from)
                    put("to", to)
                    put("cashRegisterIds",
                        Json.encodeToJsonElement(cashRegisterIds as List<String>)
                    )
                    put("productIds",
                        Json.encodeToJsonElement(productIds as List<String>)
                    )
                    put("groupBy", groupBy)
                    put("unitedAgainstWaste", false)
                    put("goodsFlow", true)
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }

}

