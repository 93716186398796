package org.hs_soft.runmynesto.domain.usecase.closing_report

import org.hs_soft.runmynesto.domain.model.home.closing_reports.print_closing_reports_model.PrintClosingReportsModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.closing_report.PrintClosingReportsRepository

class PrintClosingReportsUseCase(
    private val repository:PrintClosingReportsRepository
)
{
    suspend operator fun invoke(
        from: String,
        to: String,
        departments:List<String>,
        groupBy:String?,
        cacheKey:String?,
    ): PrintClosingReportsModel {
        return repository.getPrintClosingReports(
            from = from,
            to=to,
            departments=departments,
            groupBy=groupBy,
            cacheKey=cacheKey,
        )
    }

}