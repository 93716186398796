package org.hs_soft.runmynesto.domain.usecase.product.tab_click_collect

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_click_collect.update_properties.UpdatePropertiesModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_click_collect.UpdateProductPropertiesRepository


class UpdateProductPropertiesUseCase(
    private val repository: UpdateProductPropertiesRepository
) {
    suspend operator fun invoke(
        productId: String,
        minOrder:Double?,
        maxOrder:Double?,
        timeLeadDays:Double?,
    ): UpdatePropertiesModel {
        return repository.updateProductProperties(
            productId=productId,
            minOrder= minOrder,
            maxOrder=maxOrder,
            timeLeadDays=timeLeadDays,
        )
    }
}