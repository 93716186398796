package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PaymentTerminal(
    @SerialName("creditPositionInfo")
    val creditPositionInfo: CreditPositionInfo?,
    @SerialName("id")
    val id: String?,
    @SerialName("ip")
    val ip: String?,
    @SerialName("port")
    val port: String?,
    @SerialName("posId")
    val posId: String?,
    @SerialName("serialNumber")
    val serialNumber: String?,
    @SerialName("sumUpEmail")
    val sumUpEmail: String?,
    @SerialName("sumUpPassword")
    val sumUpPassword: String?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("walleeApplicationKey")
    val walleeApplicationKey: String?,
    @SerialName("walleeSpaceId")
    val walleeSpaceId: String?,
    @SerialName("walleeTerminalID")
    val walleeTerminalID: String?,
    @SerialName("walleeUserId")
    val walleeUserId: String?,
    @SerialName("walleeVirtualTerminalId")
    val walleeVirtualTerminalId: String?
)