package org.hs_soft.runmynesto.domain.usecase.customer_card

import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card.LoyaltyCardModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.GetLoyaltyCardsRepository
import org.hs_soft.runmynesto.domain.util.Constants

class GetLoyaltyCardsUseCase(
    private val repository:GetLoyaltyCardsRepository
)
{
    suspend operator fun invoke(
        searchValue:String,
        offset:Int,
        isActive:Boolean?=null
    ): LoyaltyCardModel {
        return repository.getLoyaltyCards(
            searchValue = searchValue,
            limit = Constants.limitItemsForPagination,
            offset = offset,
            isActive = isActive
        )
    }

}