package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Euro(
    @SerialName("additionPercent")
    val additionPercent: Double?,
    @SerialName("changeTo")
    val changeTo: String?,
    @SerialName("exchangeDateRefresh")
    val exchangeDateRefresh: String?,
    @SerialName("exchangeRate")
    val exchangeRate: Double?,
    @SerialName("exchangeRateIsUsed")
    val exchangeRateIsUsed: Double?,
    @SerialName("is200EuroNotShown")
    val is200EuroNotShown: Boolean?,
    @SerialName("isActive")
    val isActive: Boolean?,
    @SerialName("isDefinedMannualy")
    val isDefinedMannualy: Boolean?,
    @SerialName("isEuroCoinsNotShown")
    val isEuroCoinsNotShown: Boolean?,
    @SerialName("isShowTotalEuroOnCustomerDisplay")
    val isShowTotalEuroOnCustomerDisplay: Boolean?,
    @SerialName("roundTo")
    val roundTo: String?,
    @SerialName("__typename")
    val typename: String?
)