package org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("getLoyaltyCard")
    val getLoyaltyCard: LoyaltyCardDetail?=null,
    @SerialName("updateLoyaltyCard")
    val updatedLoyaltyCard: LoyaltyCardDetail?=null,
    @SerialName("createMutation")
    val createLoyaltyCard: LoyaltyCardDetail?=null,
    @SerialName("__typename")
    val typename: String?
)