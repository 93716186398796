package org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.cache

import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.SaveCachedProductCategoriesRepository

class SaveCachedProductCategoriesUseCase(
    private val repository: SaveCachedProductCategoriesRepository
) {
    operator fun invoke(
        items: List<ProductItem?>,
    ){
        repository.saveProductCategories(items)
    }


}