package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashRegisterGlobal(
    @SerialName("printerLocations")
    val printerLocations: List<PrinterLocation?>?,
    @SerialName("__typename")
    val typename: String?
)