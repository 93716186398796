package org.hs_soft.runmynesto.domain.model.home.home

import org.hs_soft.runmynesto.domain.util.Res

enum class CompaniesInfoEnum(
    val title: String,
    val img: String,
    val url:String,
) {
    CONSOLE(
        title = "Console",
        img = Res.PathIcon.console,
        url="https://console.hssoft.com/"
    ),
    REZEPT_ASSIST(
        title = "Rezept_Assist",
        img = Res.PathIcon.rezeptAssist,
        url = "https://rezeptassist.web.hssoft.com/",
    ),
    WAWI_ASSIST(
        title = "WaWi_Assist",
        img = Res.PathIcon.wawiAssist,
        url ="https://wawiassist.hssoft.com/",
    ),
    SMART_PICKING(
        title = "Smart_Picking",
        img = Res.PathIcon.smartPicking,
        url = "https://smartpicking.hssoft.com/",
    ),
    CASH_ASSIST(
        title = "Cash_Assist",
        img = Res.PathIcon.cashAssist,
        url = "https://cashassist.hssoft.com/",
    ),
    RESTAURANT_MANAGER(
        title = "Restaurant_Manager",
        img = Res.PathIcon.restaurant,
        url ="https://restaurant-manager24.hssoft.com/"
    )
}