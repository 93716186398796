package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.restaurant_data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AdditionModel(
    @SerialName("id")
    val id:String,
    @SerialName("mod_ver")
    val mod_ver:Int,
    @SerialName("productAddionId")
    val productAdditionId:String,
    @SerialName("__typename")
    val typeName:String,

)
