package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.closing_reports_content.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model.Currency
import org.hs_soft.runmynesto.domain.model.home.options.CountriesEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.jetbrains.compose.web.css.LineStyle


@Composable
fun Currency(
    data: List<Currency>?,
    windowSizeMode: WindowSizeModeEnum,
    companyProfileId: String?,
) {


    MediumContentTitle(
       marginTop = Dimen.mediumPadding,
        title = Strings.currency
    )

    Column(
        modifier = HorizontalScrollBehavior.toModifier()
                .fillMaxWidth()
            .margin(topBottom = Dimen.mediumPadding)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb
            )
            .borderRadius(Dimen.lowBorderRadius)
    ) {

        Row(
            modifier =Modifier
                .thenIf(
                    windowSizeMode==WindowSizeModeEnum.WINDOWS,
                    Modifier.fillMaxWidth()
                )
                .height(Dimen.gridHeaderHeight)
                .borderBottom(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayAlpha12.rgb
                )

                .backgroundColor(Theme.GrayAlpha04.rgb)
                .padding(leftRight = Dimen.normalPadding),
            verticalAlignment = Alignment.CenterVertically
        ) {

            HeaderTitle(
                title = Strings.currency,
            )
            HeaderTitle(
                title = Strings.lst,
                arrangement = Arrangement.End
            )
            HeaderTitle(
                title = Strings.course,
                arrangement = Arrangement.End
            )
            HeaderTitle(
                title =
                    if (companyProfileId== CountriesEnum.SWITZERLAND.id)
                            ApiUtils.CURRENCY_TYPE.CHF
                    else ApiUtils.CURRENCY_TYPE.EUR,
                arrangement = Arrangement.End

            )
            HeaderTitle(
                title = Strings.cashDelivery,
                arrangement = Arrangement.End
            )
            HeaderTitle(
                title = Strings.difference,
                arrangement = Arrangement.End
            )
            HeaderTitle(
                title = Strings.amount,
                arrangement = Arrangement.End
            )
            HeaderTitle(
                title = Strings.amountCHF,
                arrangement = Arrangement.End
            )

        }

        Column(
            modifier = VerticalScrollBehavior
                .toModifier()
                .thenIf(
                    data.isNullOrEmpty(),
                    Modifier.fillMaxWidth()
                )
        )
        {
            if (data.isNullOrEmpty()){
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(leftRight = Dimen.normalPadding)
                        .height(Dimen.mediumTableItemHeight)
                )
            }
            else{
                data.forEach {model->
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(leftRight = Dimen.normalPadding)
                            .height(Dimen.mediumTableItemHeight)
                    ){
                        HeaderTitle(
                            title = model.name?:"",
                        )
                        HeaderTitle(
                            title = if (model.present==0.0) ""
                                else model.present?.toString()?:"",
                            arrangement = Arrangement.End
                        )
                        HeaderTitle(
                            title = formatToTwoDecimals(model.exchangeRate),
                            arrangement = Arrangement.End
                        )
                        HeaderTitle(
                            title = formatToTwoDecimals(model.presentConverted),
                            arrangement = Arrangement.End
                        )
                        HeaderTitle(
                            title = formatToTwoDecimals(model.cashInTransit),
                            arrangement = Arrangement.End
                        )
                        HeaderTitle(
                            title = formatToTwoDecimals(model.difference),
                            arrangement = Arrangement.End
                        )
                        HeaderTitle(
                            title = formatToTwoDecimals(model.amount),
                            arrangement = Arrangement.End
                        )
                        HeaderTitle(
                            title = formatToTwoDecimals(model.amountConverted),
                            arrangement = Arrangement.End
                        )
                    }
                }
            }
        }







    }


}