package org.hs_soft.runmynesto.domain.usecase.dashboard

import org.hs_soft.runmynesto.domain.model.home.dashboard.customer_frequency.CustomerFrequencyModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.CustomerFrequencyRepository

class CustomerFrequencyUseCase(
    private val repository: CustomerFrequencyRepository
) {
    suspend operator fun invoke(
        cashRegisterIds:List<String>,
        periodStart:String,
        periodEnd:String,
    ): CustomerFrequencyModel {
        return repository.getCustomerFrequency(
            cashRegisterIds=cashRegisterIds,
            periodStart=periodStart,
            periodEnd=periodEnd,
        )
    }
}