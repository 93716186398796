package org.hs_soft.runmynesto.pages.components.menu

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseDown
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.onMouseUp
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun MenuTopBar(
    applicationIcon: String,
    titlePart1: String,
    titlePart2: String,
    isExpanded: Boolean = false,
    backgroundColor: CSSColorValue,
    windowSizeMode: WindowSizeModeEnum,
    onMenuClick: () -> Unit,
)
{
    val isHovered= remember { mutableStateOf(false) }
    val isMouseDown= remember { mutableStateOf(false) }

    Column(
        modifier = Modifier.fillMaxWidth()
    ){
        Row(
            modifier = Modifier
                .margin(
                    bottom = Dimen.normalPadding,
                    top = Dimen.semiSemiNormalPadding
                )
//                .fillMaxWidth()
//            .minWidth(Dimen.menuDefaultWidth)
            .width(Dimen.menuItemRowWidthDesktopAndTablet)
//            .thenIf(
//                windowSizeMode==WindowSizeModeEnum.PHONE
//            )
                .backgroundColor(backgroundColor),//
            verticalAlignment =Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        )
        {

            Box(
                modifier = Modifier
                    .width(Dimen.menuDefaultWidth)
                    .cursor(Cursor.Pointer)
                    .onMouseOver { isHovered.value=true }
                    .onMouseLeave { isHovered.value=false }
                    .onMouseDown {isMouseDown.value=true}
                    .onMouseUp { isMouseDown.value=false }
                    .padding(Dimen.semiNormalPadding)
                    .onClick {
                        onMenuClick()
                    }
            )
            {
                Box(
                    modifier = Modifier
                        .align(Alignment.Center)
                        .borderRadius(50.percent)
                        .padding(Dimen.smallPadding)
                        .size(Dimen.menuItemParent)
                        .backgroundColor(
                            if(isMouseDown.value) Theme.GrayAlpha12.rgb
                            else if (isHovered.value) Theme.GrayAlpha04.rgb
                            else Theme.Transparent.rgb
                        )
                ){
                    VectorIcon(
                        modifier = Modifier.align(Alignment.Center),
                        pathData = Res.PathIcon.menuFilled,
                        color = Theme.GrayAlpha56.hex,
                        width = Dimen.mediumIconSize,
                        height = Dimen.mediumIconSize,
                    )
                }

            }


            Row(
                modifier = Modifier
                    .width(Dimen.menuItemInfoWidth)
                    .attrsModifier {
                        style {
                            property("opacity", if (isExpanded) "1" else "0")
                            property("overflow", "hidden")
                            property("transition",
                                " opacity ${Constants.fastExpandingTime}s ease")
                        }
                    }
                ,
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start,
            )
            {

                Image(
                    src = applicationIcon,
                    modifier = Modifier
                        .size(Dimen.largeIconSize.px)
                )

                SpanText(
                    modifier = Modifier
                        .margin(left = Dimen.smallPadding)
                        .fontFamily(FontFamily.Jakarta)
                        .fontSize(FontSizes.mediumFontSize)
                        .lineHeight(FontLineHeight.lineNormal)
                        .fontWeight(FontWeight.SemiBold)
                        .color(Theme.Primary.rgb),
                    text =titlePart1
                )
                SpanText(
                    modifier = Modifier
                        .fontFamily(FontFamily.Jakarta)
                        .fontSize(FontSizes.mediumFontSize)
                        .lineHeight(FontLineHeight.lineNormal)
                        .fontWeight(FontWeight.Normal)
                        .color(Theme.Primary.rgb),
                    text =titlePart2
                )


            }

        }
    }





}