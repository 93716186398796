package org.hs_soft.runmynesto.domain.model.home.home.hssoft_apps_list


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Data(
    @SerialName("listHSWebApps")
    val listHSWebApps: List<HSWebApps?>?
)