package org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChildrenX(
    @SerialName("children")
    val children: List<ChildrenXX>?=null,
    @SerialName("id")
    val id: String?=null,
    @SerialName("mod_ver")
    val modVer: Int?=null,
    @SerialName("name")
    val name: String?=null,
    @SerialName("__typename")
    val typename: String?=null
)