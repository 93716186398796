package org.hs_soft.runmynesto.domain.usecase.options.cash_flow

import org.hs_soft.runmynesto.domain.model.home.options.cash_flow.app_config_model.AppConfigModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.cash_flow.GetAppConfigRepository


class GetAppConfigUseCase(
    private val repository: GetAppConfigRepository
) {
    suspend operator fun invoke(): AppConfigModel {
        return repository.getAppConfig()
    }
}