package org.hs_soft.runmynesto.domain.usecase.template

import org.hs_soft.runmynesto.domain.model.home.template.add_new_category.AddNewCategory
import org.hs_soft.runmynesto.domain.repository.network_datasource.AddCategoryRepository


class AddCategoryUseCase(private val repository: AddCategoryRepository) {
    suspend operator fun invoke(name:String): AddNewCategory {
        return repository.addCategory(name)
    }
}