package org.hs_soft.runmynesto.di.dashboard

import org.hs_soft.runmynesto.data.api_datasource.dashboard.CustomerFrequencyRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.dashboard.DashboardConfigRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.dashboard.InitShopStaticsRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.dashboard.ShopStaticsRepositoryImpl
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.CustomerFrequencyRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.DashboardConfigRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.InitShopStaticsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.ShopStaticsRepository
import org.koin.dsl.module

val dashBoardRepositoryModule= module{
    single<InitShopStaticsRepository> {
        InitShopStaticsRepositoryImpl(get(),get())
    }

    single<ShopStaticsRepository> {
        ShopStaticsRepositoryImpl(get(),get())
    }
    single<DashboardConfigRepository> {
        DashboardConfigRepositoryImpl(get(),get())
    }

    single<CustomerFrequencyRepository>{
        CustomerFrequencyRepositoryImpl(get(),get())
    }
}
