package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card

import org.hs_soft.runmynesto.domain.config.Strings

enum class DiscountNoEnum(
    val title: String,
    val value: Int,
) {
    NONE(
        title = Strings.none,
        value = 0,
    ),
    DISCOUNT_GROUP_1(
        title = Strings.discountGroup1,
        value = 1,
    ),
    DISCOUNT_GROUP_2(
        title = Strings.discountGroup2,
        value = 2,
    ),
    DISCOUNT_GROUP_3(
        title = Strings.discountGroup3,
        value = 3,
    ),
    DISCOUNT_GROUP_4(
        title = Strings.discountGroup4,
        value = 4,
    ),
    DISCOUNT_GROUP_5(
        title = Strings.discountGroup5,
        value = 5,
    ),
    DISCOUNT_GROUP_6(
        title = Strings.discountGroup6,
        value = 6,
    ),
    DISCOUNT_GROUP_7(
        title = Strings.discountGroup7,
        value = 7,
    ),
    DISCOUNT_GROUP_8(
        title = Strings.discountGroup8,
        value = 8,
    ),
    DISCOUNT_GROUP_9(
        title = Strings.discountGroup9,
        value = 9,
    ),
    DISCOUNT_GROUP_10(
        title = Strings.discountGroup10,
        value =10,
    ),
    DISCOUNT_GROUP_11(
        title = Strings.discountGroup11,
        value = 11,
    ),
    DISCOUNT_GROUP_12(
        title = Strings.discountGroup12,
        value = 12,
    ),
    DISCOUNT_GROUP_13(
        title = Strings.discountGroup13,
        value =13,
    ),
    DISCOUNT_GROUP_14(
        title = Strings.discountGroup14,
        value =14,
    ),
    DISCOUNT_GROUP_15(
        title = Strings.discountGroup15,
        value = 15,
    ),
    DISCOUNT_GROUP_16(
        title = Strings.discountGroup16,
        value =16,
    ),
    DISCOUNT_GROUP_17(
        title = Strings.discountGroup17,
        value =17,
    ),
    DISCOUNT_GROUP_18(
        title = Strings.discountGroup18,
        value = 18,
    ),
    DISCOUNT_GROUP_19(
        title = Strings.discountGroup19,
        value = 19,
    ),
    DISCOUNT_GROUP_20(
        title = Strings.discountGroup20,
        value = 20,
    ),
}