package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.key
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.css.JustifyContent
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.alignItems
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.justifyContent
import com.varabyte.kobweb.compose.ui.modifiers.onScroll
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import kotlinx.coroutines.launch
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.util.Constants
import org.jetbrains.compose.web.css.DisplayStyle
import org.w3c.dom.HTMLElement
import org.w3c.dom.Text

@Composable
fun InfiniteScrollGrid(
    modifier: Modifier = Modifier,
    items: List<Any?>,
    onLoadMore: () -> Unit,
    itemContent: @Composable (Int) -> Unit
) {
    var loadedItems by remember { mutableStateOf(0) }
    var isLoading by remember { mutableStateOf(false) }
    val coroutineScope = rememberCoroutineScope()

    LaunchedEffect(items) {
        if (items.isNotEmpty() && loadedItems == 0) {
            loadedItems = minOf(Constants.fetchItemsStep, items.size)
        }
    }

    Column(
        modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .overflow(Overflow.Auto)
            .attrsModifier {
                style {
                    property("scrollbar-width", "none")
                }
            }
            .onScroll { event ->
                val target = event.target as HTMLElement
                if (target.scrollHeight - target.scrollTop <=
                    target.clientHeight * Constants.infiniteScrollRatio
                ) {
                    if (!isLoading && loadedItems < items.size) {
                        isLoading = true
                        coroutineScope.launch {
                            onLoadMore()
                            loadedItems = (loadedItems + Constants.fetchItemsStep).coerceAtMost(items.size)
                            isLoading = false
                        }
                    }
                }
            }
    ) {
        if (items.isEmpty()) {
            Box(
                Modifier.fillMaxSize()
                    .display(DisplayStyle.Flex)
                    .justifyContent(JustifyContent.Center)
                    .alignItems(AlignItems.Center)
            ) {
                Text(Strings.noItemToDisplay)
            }
        } else {
            for (i in 0 until loadedItems) {
                if (i < items.size) {
                    key(i) {
                        itemContent(i)
                    }
                }
            }
            if (isLoading) {
                // Uncomment this if you want to show a loading indicator
                // Box(Modifier.fillMaxWidth().height(50.px)) {
                //     Text("Loading...")
                // }
            }
        }
    }
}
