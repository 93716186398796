package org.hs_soft.runmynesto.data.local_datasource.local_storage.product

import org.hs_soft.runmynesto.data.local_datasource.local_storage.saveToLocalStorage
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.SaveCompanyProfileIdRepository
import org.hs_soft.runmynesto.domain.util.IdUtils

class SaveCompanyProfileIdRepositoryImpl: SaveCompanyProfileIdRepository
{
    override fun saveCompanyProfileId(id: String) {
        saveToLocalStorage(IdUtils.companyProfileId,id)
    }

}