package org.hs_soft.runmynesto.domain.usecase.product.tab_allergens

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.update_ingredient.UpdateIngredientModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_allergens.UpdateAllergenRepository


class UpdateAllergenUseCase(
    private val repository: UpdateAllergenRepository
) {
    suspend operator fun invoke(
        productId: String,
        dataValue: Boolean,
        ingredientId:String,
    ): UpdateIngredientModel {
        return repository.updateAllergen(
            productId=productId,
            dataValue=dataValue,
            ingredientId=ingredientId,
        )
    }
}