package org.hs_soft.runmynesto.pages.home.sub_page.options.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.AddItem
import org.hs_soft.runmynesto.pages.components.FilterComponent
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnitLength
import org.jetbrains.compose.web.css.LineStyle


@Composable
fun FilterRow(
    onAddRecordClick:()->Unit,
    width: CSSSizeValue<out CSSUnitLength> = Dimen.gridWidth,
    onFilterClick:()->Unit,
)
{
    Row(
        modifier = Modifier
            .width(width),
        verticalAlignment = Alignment.CenterVertically
    )
    {
        FilterComponent(
            onFilterClick={
                onFilterClick()
            }
        )

        Spacer()

        AddItem(
            onAddRecordClick={
                onAddRecordClick()
            },
        )




    }
}

