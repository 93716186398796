package org.hs_soft.runmynesto.pages.home.sub_page.options.mvi

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config.VirtualCardConfigModel
import org.hs_soft.runmynesto.domain.model.home.options.menu_card_import.menu_card_model.MenuCardModel
import org.hs_soft.runmynesto.domain.model.home.options.menu_card_import.menu_card_model.MenuProductItemModel
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.ObjectKinds
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.ShapeEnum
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.RestaurantProject
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.Room
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group.SaleItem
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.usecase.home.GetSavedCheckOutUseCase
import org.hs_soft.runmynesto.domain.usecase.options.cash_flow.GetAppConfigUseCase
import org.hs_soft.runmynesto.domain.usecase.options.cash_flow.UpdateAppConfigUseCase
import org.hs_soft.runmynesto.domain.usecase.options.cash_register_config.GetCashRegisterConfigUseCase
import org.hs_soft.runmynesto.domain.usecase.options.cash_register_config.UpdateCashRegisterConfigUseCase
import org.hs_soft.runmynesto.domain.usecase.options.kitchen_locatoin.UpdateKitchenLocationUseCase
import org.hs_soft.runmynesto.domain.usecase.options.menu_card_import.GetMenuCardUseCase
import org.hs_soft.runmynesto.domain.usecase.options.operation.OptionDataUseCase
import org.hs_soft.runmynesto.domain.usecase.options.operation.UpdateOptionUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.CreateFloorUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.CreateFurnitureUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.DeleteFloorUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.DeleteRoomUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.RestaurantProjectsUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.UpdateFloorUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.UpdateFurnitureUseCase
import org.hs_soft.runmynesto.domain.usecase.options.sales_group.AddNewSaleGroupUseCase
import org.hs_soft.runmynesto.domain.usecase.options.sales_group.SingleSaleGroupUseCase
import org.hs_soft.runmynesto.domain.usecase.options.sales_group.UpdateSaleGroupUseCase
import org.hs_soft.runmynesto.domain.usecase.options.virtual_card.GetVirtualCardInfoUseCase
import org.hs_soft.runmynesto.domain.usecase.options.virtual_card.UpdateVirtualCardInfoUseCase
import org.hs_soft.runmynesto.domain.usecase.product.SaveCompanyProfileIdUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.ProductSaleGroupUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.PrinterLocationNamesUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_options.GetPriceLevelsUseCase
import org.hs_soft.runmynesto.domain.util.*
import org.hs_soft.runmynesto.domain.util.helper.BaseViewModel
import org.w3c.files.File

class OptionsViewModel(
    private val requestManager: RequestManager,
    private val refreshToken:()->Unit,
    private val updateKitchenLocationUseCase: UpdateKitchenLocationUseCase,
    private val optionsDataUseCase: OptionDataUseCase,
    private val addNewSaleGroupUseCase: AddNewSaleGroupUseCase,
    private val singleSaleGroupUseCase: SingleSaleGroupUseCase,
    private val updateSaleGroupUseCase: UpdateSaleGroupUseCase,
    private val salesGroupUseCase: ProductSaleGroupUseCase,
    private val printerLocationNamesUseCase: PrinterLocationNamesUseCase,
    private val updateOptionUseCase: UpdateOptionUseCase,
    private val saveCompanyProfileIdUseCase: SaveCompanyProfileIdUseCase,
    private val appConfigUseCase: GetAppConfigUseCase,
    private val updateAppConfigUseCase: UpdateAppConfigUseCase,
    private val getCashRegisterConfigUseCase: GetCashRegisterConfigUseCase,
    private val updateCashRegisterConfigUseCase: UpdateCashRegisterConfigUseCase,
    private val updateVirtualCardInfoUseCase: UpdateVirtualCardInfoUseCase,
    private val getVirtualCardInfoUseCase: GetVirtualCardInfoUseCase,
    private val priceLevelsUseCase: GetPriceLevelsUseCase,
    private val getSavedCheckOutUseCase: GetSavedCheckOutUseCase,
    private val getMenuCardUseCase: GetMenuCardUseCase,
    private val restaurantProjectsUseCase: RestaurantProjectsUseCase,
    private val createFurnitureUseCase: CreateFurnitureUseCase,
    private val updateFurnitureUseCase: UpdateFurnitureUseCase,
    private val createFloorUseCase: CreateFloorUseCase,
    private val updateFloorUseCase: UpdateFloorUseCase,
    private val deleteFloorUseCase: DeleteFloorUseCase,
    private val deleteRoomUseCase: DeleteRoomUseCase,

):BaseViewModel() {

    private val _state = MutableStateFlow(OptionsState())
    val state: StateFlow<OptionsState> = _state
    private var tempSalesGroup:List<SaleItem> = listOf()
    private var tempCheckoutList:List<Children> ?= listOf()
    private var tempRestaurantProject:List<RestaurantProject?>?= listOf()

    override  fun clear() {
        super.clear()
        requestManager.cancelAll()
    }


   fun onEvent(event: OptionsEvent) {
        when(event){

            is OptionsEvent.DeleteRestaurantRoom->{
                deleteRestaurantRoom(roomId = event.roomId)
            }

            is OptionsEvent.DeleteRestaurantFloor->{
                deleteRestaurantFloor(floorId=event.floorId)
            }

            is OptionsEvent.SetDeleteFloorDialogStatus->{
                setDeleteFloorDialogStatus(
                    status=event.status,
                    selectedFloorId=event.floorId
                )
            }
            is OptionsEvent.SetDeleteRoomDialogStatus->{
                _state.value=_state.value.copy(
                    showDeleteRestaurantRoomDialog = event.status,
                    selectedRoomId=event.roomId
                )
            }


            is OptionsEvent.UpdateRestaurantFloor->{
                updateRestaurantFloor(
                    number=event.number,
                    projectId = state.value.selectedRestaurantProject?.id?:""
                )
            }

            is OptionsEvent.CreateRestaurantFloor->{
                createRestaurantFloor(
                    number=event.number,
                    projectId = state.value.selectedRestaurantProject?.id?:""
                )
            }

            is OptionsEvent.SetEditRestaurantFloorDialogStatus->{
                _state.value=_state.value.copy(
                    showEditFloorDialog=event.status
                )
            }

            is OptionsEvent.SetAddRestaurantFloorDialogStatus->{
                setAddRestaurantFloorDialogStatus(
                    status=event.status,
                )
            }

            is OptionsEvent.OnRestaurantFloorClick->{
                onRestaurantProjectFloorClick(model = event.restaurantProject)
            }

            is OptionsEvent.UpdateFurniture->{
                updateFurniture(
                    id=event.id,
                    height=event.height,
                    positionLeft=event.positionLeft,
                    positionTop=event.positionTop,
                    rotationDeg=event.rotationDeg,
                    width=event.width,
                )
            }

            is OptionsEvent.OnRestaurantRoomClick->{
                onRestaurantRoomClick(
                    room=event.room,
                    restaurantProject=event.restaurantProject,
                )
            }

            is OptionsEvent.CreateFurniture ->{
                createFurniture(
                    componentColor = state.value.selectedRestaurantDesignTableColor,
                    floorId =state.value.selectedRestaurantRoom?.floorId?:"" ,
                    roomId =state.value.selectedRestaurantRoom?.id?:"" ,
                    projectId =state.value.selectedRestaurantRoom?.projectId?:"",
                    shape=event.shape,
                    number = event.number,
                    objectKind = event.objectKind,
                )
            }

            is OptionsEvent.OnRestaurantDesignTableColorClick->{
                onRestaurantDesignTableColorClick(color = event.color)
            }

            is OptionsEvent.SetRestaurantTableColorDialogStatus->{
                showChooseRestaurantTableColorDialog(event.status)
            }

            is OptionsEvent.OnSearchInRestaurantProject->{
                searchInRestaurantProject(title=event.title)
            }

            is OptionsEvent.OnRestaurantProjectClick->{
                onRestaurantProject(model=event.model)
            }

            is OptionsEvent.GetRestaurantProjects->{
                getRestaurantProjects()
            }

            is OptionsEvent.OnSelectShop->{
                _state.value=_state.value.copy(
                    selectedShop = event.branch
                )
            }

            is OptionsEvent.OnSelectRegion -> {
                _state.value = _state.value.copy(
                    selectedRegion = event.region,
                    selectedShop = if (event.region?.children?.isNotEmpty() == true)
                        event.region.children[0] else null
                )
            }

            is OptionsEvent.OnSelectOffice -> {

                _state.value = _state.value.copy(
                    selectedOffice = event.office,
                    selectedRegion = if (event.office?.children?.isNotEmpty() == true)
                        event.office.children[0] else null,
                    selectedShop = if (event.office?.children?.isNotEmpty() == true &&
                        !event.office.children[0].children.isNullOrEmpty())
                        event.office.children[0].children?.get(0) else null
                )
            }

            is OptionsEvent.GetPriceLevels->{
                getPriceLevels()
            }



            is OptionsEvent.OnColorBoxClick->{
                _state.value=_state.value.copy(
                    showVirtualCardColorPalletDialog = true,
                    selectedColorField=event.selectedColorField,
                )
            }

            is OptionsEvent.SetColorPalletStatus->{
                _state.value=_state.value.copy(
                    showVirtualCardColorPalletDialog = event.status
                )
            }

            is OptionsEvent.OnVirtualCardPalletColorClick->{
                onPalletColorClick(
                    virtualCardHexColor = event.hexColor,
                )
            }

            is OptionsEvent.GetVirtualCardInfo->{
                getVirtualCardInfo()
            }

            is OptionsEvent.OnCashRegisterConfigTabClick->{
                _state.value=_state.value.copy(
                    selectedCashRegisterConfigTab = event.tab
                )
            }

            is OptionsEvent.OnCloseGoogleWallet->{
                _state.value=_state.value.copy(showGoogleWalletDialog = false)
            }
            is OptionsEvent.OnPreviewGoogleWalletClick->{
                _state.value=_state.value.copy(showGoogleWalletDialog=true)
            }

            is OptionsEvent.OnCloseAppleWallet->{
                _state.value=_state.value.copy(showAppleWalletDialog =false)
            }
            is OptionsEvent.OnPreviewAppleWalletClick->{
                _state.value=_state.value.copy(showAppleWalletDialog=true)
            }

            is OptionsEvent.UpdateCashRegisterConfigData->{
                updateCashRegisterConfig(
                    id=state.value.selectedCashRegisterConfigItemId ,
                    title=event.title,
                    value=event.value,
                    valueIsInParameters = event.valueIsInParameters?:true
                )
            }

            is OptionsEvent.OnCashRegisterConfigItemClick->{
                onCashRegisterConfigItemClick(
                    id=event.id,
                )
            }

            is OptionsEvent.GetCashRegisterConfigData->{
                getCashRegisterConfigData()
            }

            is OptionsEvent.GetCheckoutData->{
                getCheckoutData()
            }

            is OptionsEvent.OnSearchCheckoutItems->{
                searchCheckoutItems(value=event.value)
            }

            is OptionsEvent.UpdateAppConfig->{
                updateAppConfig(
                    title=event.title,
                    value=event.value,
                )
            }

            is OptionsEvent.GetAppConfig->{
                getAppConfig()
            }

            is OptionsEvent.UpdateVirtualCardInfo->{
                updateVirtualCardInfo(
                    dataTitle=event.dataTitle,
                    dataValue=event.dataValue,
                )
            }

            is OptionsEvent.OnLoadingOperationData->loadOperationData()

            is OptionsEvent.UpdateOptionData->updateOptionData(
                dataTitle = event.dataTitle,
                dataValue = event.dataValue,
            )


            is OptionsEvent.OnTopTabClick->{
                _state.value=_state.value.copy(selectedTab = event.tab)
            }

            is OptionsEvent.GetProductSalesGroup ->{
                if (state.value.productSalesGroup==null){
                    getProductSalesGroup()
                }

            }

            is OptionsEvent.OnSaleGroupItemClick ->onSaleGroupItemClick(
                id=event.id,
                switchTheSecondPage=event.callBack,
            )

            is OptionsEvent.OnSearchSaleGroupInputChange->{
                onSearchSaleGroupInputChange(value = event.value)
            }

            is OptionsEvent.OnSaleGroupTitleSortClick->{
                onSaleGroupTitleSortClick()
            }

            is OptionsEvent.OnSaleGroupNumberSortClick->{
                onSaleGroupNumberSortClick()
            }



            is OptionsEvent.OnChangePageClick->{
                changePage()
            }

            is OptionsEvent.UpdateSaleGroup->{
                updateSaleGroup(
                    dataTitle=event.dataTitle,
                    dataValue=event.dataValue,
                    id=state.value.selectedSaleGroup?.id?:""
                )
            }


            //Add Record
            is OptionsEvent.OnAddRecordDialogBackgroundClick->{}

            is OptionsEvent.OnAddRecordIconClick->onAddRecordIconClick()
            is OptionsEvent.OnCancelRecordDialogClick->dismissAddRecordDialog()

            is OptionsEvent.OnChangeRecordName->onChangeRecordName(
                value = event.value,
                isNumberOptional = event.isNumberOptional
            )
            is OptionsEvent.OnChangeRecordNumber->onChangeRecordNumber(event.value)

            is OptionsEvent.OnSubmitAddSaleGroupRecordDialogClick->{
                onSubmitSaleGroupRecordDialogClick(
                    windowMode = event.windowMode,
                    name=state.value.newRecordName?:"",
                    code =state.value.newRecordNumber,
                )
            }
 

            is OptionsEvent.RefreshSaleGroupList->{
                getProductSalesGroup()
            }

            is OptionsEvent.GetPrintersLocation->{
                getPrintersLocation()
            }

            is OptionsEvent.ShowAddPrinterLocationRecordDialog->{
                _state.value=_state.value.copy(
                    showKitchenLocationRecordDialog = true
                )
            }

            is OptionsEvent.OnSubmitAddKitchenLocationRecordDialogClick->{

                submitAddKitchenLocationRecordDialog(location = event.location)

            }

            is OptionsEvent.ShowUpdatePrinterLocationRecordDialog->{
                showUpdatePrinterLocationRecordDialog(
                    name = event.name,
                    dbIndex = event.dbIndex,
                )
            }

            is OptionsEvent.OnSubmitUpdateKitchenLocationRecordDialogClick->{

                submitUpdateKitchenLocationRecordDialog(location = event.location)

            }

            is OptionsEvent.SetGenerateAIMenuCardDialogStatus->{
                _state.value=_state.value.copy(
                    showGenerateAIMenuCardDialog = event.status,
                    menuCardPDFFilesUploaded = 0
                )
            }

            is OptionsEvent.ResetGenerateAIMenuCard->{
                _state.value=_state.value.copy(
                    menuCardAIGenerated = MenuCardModel(categories = mutableListOf(), items = mutableListOf()),
                )
            }

            is OptionsEvent.GenerateMenuCard->{
                generateMenuCard(
                    files=event.files,
                )
            }

            is OptionsEvent.SaveMenuCard->{
                saveMenuCard(
                    menuCard=event.menuCard,
                )
            }

            is OptionsEvent.OnMenuProductItemValueChange->{
                onMenuProductItemValueChange(
                    event.menuCard,
                    event.id,
                    event.key,
                    event.value
                )
            }

            is OptionsEvent.OnGenerateMenuCardButtonClick->onGenerateMenuCardButtonClick()

            is OptionsEvent.OnAddPDFFile->{
                _state.value=_state.value.copy(
                    menuCardPDFFilesUploaded = event.count,
                )
            }

        }



    }

    private fun deleteRestaurantRoom(roomId: String?) {
        if (roomId!=null){
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()

                        deleteRoomUseCase(
                            roomId=roomId
                        )
                        _state.value=_state.value.copy(
                            showDeleteRestaurantRoomDialog = false,
                        )
                        hideProgress()
                    }

                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }

            }
        }



    }

    private fun setDeleteFloorDialogStatus(
        status: Boolean,
        selectedFloorId: String?,
    ) {
        _state.value=_state.value.copy(
            showDeleteRestaurantFloorDialog =status,
            selectedFloorId = selectedFloorId
        )

    }

    private fun deleteRestaurantFloor(
        floorId: String?,
    ) {
        if (floorId!=null){
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()

                        deleteFloorUseCase(
                            floorId=floorId
                        )
                        _state.value=_state.value.copy(
                            showDeleteRestaurantFloorDialog = false,
                        )
                        hideProgress()
                    }

                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }

            }
        }


    }

    private fun updateRestaurantFloor(
        number: Int,
        projectId: String
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    updateFloorUseCase(
                        number=number,
                        floorId = projectId
                    )

                    getRestaurantProjects(refresh =true )
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }
    }

    private fun createRestaurantFloor(
        number: Int,
        projectId: String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    createFloorUseCase(
                        number=number,
                        projectId = projectId
                    )

                    getRestaurantProjects(refresh =true )
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }


    }

    private fun setAddRestaurantFloorDialogStatus(
        status: Boolean,
    ) {
        _state.value=_state.value.copy(
            showAddFloorDialog = status
        )
    }

    private fun updateFurniture(
        id: String?,
        height: Int,
        positionLeft: Int,
        positionTop: Int,
        rotationDeg: Int,
        width: Int
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()

                    if (id != null) {
                        updateFurnitureUseCase(
                            id=id,
                            height=height,
                            positionLeft=positionLeft,
                            positionTop=positionTop,
                            rotationDeg=rotationDeg,
                            width=width,
                        )

                        hideProgress()
                    }

                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun onRestaurantRoomClick(
        room: Room?,
        restaurantProject: RestaurantProject?)
    {
        _state.value=_state.value.copy(
            selectedRestaurantRoom =room,
            selectedRestaurantProject = restaurantProject,
        )
    }

    private fun createFurniture(
        componentColor:String?,
        number:String?,
        floorId:String,
        roomId:String,
        projectId:String,
        shape:ShapeEnum,
        objectKind:ObjectKinds,

    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()

                   createFurnitureUseCase(
                        caption = number,
                        componentColor =componentColor,
                        floorId =floorId,
                        height=objectKind.width,
                        width =objectKind.height,
                        objectKind =objectKind.value,
                        objectType = Constants.RestaurantDesign.objectType,
                        positionLeft = 100,
                        positionTop = 100,
                        projectId = projectId,
                        roomId=roomId,
                        shape=shape.value,
                    )

                    getRestaurantProjects(
                        refresh =true,
                    )
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }


    }

    private fun onRestaurantDesignTableColorClick(color:String,) {
        _state.value = _state.value.copy(
            selectedRestaurantDesignTableColor=color,
            showChooseRestaurantTableColorDialog = false
        )

    }

    private fun showChooseRestaurantTableColorDialog(
        status:Boolean,
    ) {
        _state.value=_state.value.copy(
            showChooseRestaurantTableColorDialog=status,
        )
    }

    private fun searchInRestaurantProject(
        title: String,
    ) {
        _state.value= _state.value.copy(
            restaurantProjects =tempRestaurantProject
        )
        val searchedList = ArrayList<RestaurantProject?>()
        state.value.restaurantProjects?.forEach { model ->
            var matchFound = model?.caption?.lowercase()?.contains(title.lowercase()) ?: false

            model?.floors?.forEach { floor ->
                if ("${floor?.typename} ${floor?.number}".lowercase().contains(title.lowercase())) {
                    matchFound = true
                }
                floor?.rooms?.forEach { room ->
                    if (room?.caption?.lowercase()?.contains(title.lowercase()) == true) {
                        matchFound = true
                    }
                }
            }
            if (matchFound) {
                searchedList.add(model)
            }
        }


        _state.value=_state.value.copy(
            restaurantProjects = searchedList
        )


    }

    private fun onRestaurantProject(model: RestaurantProject?) {
        if (state.value.selectedRestaurantProject?.id!=model?.id){
             _state.value=_state.value.copy(
                 selectedRestaurantProject = model,
                 selectedRestaurantRoom = null,
             )
        }

    }
    private fun onRestaurantProjectFloorClick(model: RestaurantProject?) {
        if (state.value.selectedRestaurantProject?.id!=model?.id){
            _state.value=_state.value.copy(
                selectedRestaurantProject = model,
                selectedRestaurantRoom = null,
            )
        }

    }

    private fun getRestaurantProjects(
        refresh:Boolean=false,
    ) {
        if (state.value.restaurantProjects==null || refresh){
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()
                        val result=restaurantProjectsUseCase()
                            .data?.cashAssist?.listRestaurantProject
                        _state.value=_state.value.copy(
                            restaurantProjects = result?: listOf(),
                            showAddFloorDialog = false,
                        )
                        tempRestaurantProject=state.value.restaurantProjects
                        hideProgress()
                    }

                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }

            }
        }


    }


    private fun getPriceLevels() {
        if (state.value.priceLevels==null){
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()

                        val result=priceLevelsUseCase()
                        _state.value=_state.value.copy(
                            priceLevels = result.data.cashAssist.
                            getAppConfigAdditional.priceLevels,
                        )
                        hideProgress()
                    }

                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }

            }
        }


    }

    private fun onPalletColorClick(
        virtualCardHexColor:String,
    ) {
        _state.value = _state.value.copy(
            showVirtualCardColorPalletDialog = false
        )
        updateVirtualCardInfo(
            dataTitle = state.value.selectedColorField?:"",
            dataValue = virtualCardHexColor,
        )
    }

    private fun showUpdatePrinterLocationRecordDialog(
        dbIndex:Int?,
        name:String?,
    ) {
        _state.value = _state.value.copy(
            showKitchenLocationRecordDialog = true,
            printerLocationIndex = dbIndex,
            newRecordName = name ?: ""
        )
    }

    private fun submitAddKitchenLocationRecordDialog(
        location: String?
    ) {
        val newList: ArrayList<String?> = ArrayList()
        state.value.printersLocation?.forEach {
            newList.add(it?.value)
        }
        newList.add( location)

        updateKitchenLocations(
            newList = newList,
        )
    }

    private fun submitUpdateKitchenLocationRecordDialog(location: String?) {
        val newList: ArrayList<String?> = ArrayList()
        state.value.printersLocation?.forEach {
            if (it?.dbIndex == state.value.printerLocationIndex) {
                newList.add(location)
            } else newList.add(it?.value)
        }


        updateKitchenLocations(
            newList = newList,
        )
    }

    private fun getVirtualCardInfo() {
        if (state.value.virtualCardInfo==null){
            launch {
                try {
                    withTokenRefresh(refreshToken){
                        showProgress()
                        val result=getVirtualCardInfoUseCase()
                        hideProgress()
                        _state.value=_state.value.copy(
                            virtualCardInfo = result.data?.cashAssist?.getVirtualCardConfig
                        )
                    }
                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }
            }
        }

    }

    private fun updateCashRegisterConfig(
        id: String?,
        title: String,
        value: dynamic,
        valueIsInParameters:Boolean ,
    ) {
        if (id!=null){
            launch {
                try {
                    withTokenRefresh(refreshToken){
                        showProgress()
                        val result=updateCashRegisterConfigUseCase(
                            id=id,
                            title=title,
                            value=value,
                            valueIsInParameters = valueIsInParameters
                        )
                        hideProgress()
                        _state.value=_state.value.copy(
                            cashRegisterConfigData = result.data?.cashAssist?.updateCashRegister
                        )
                    }
                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }
            }
        }

    }

    private fun onCashRegisterConfigItemClick(id:String) {
        if (id != state.value.selectedCashRegisterConfigItemId) {
            _state.value = _state.value.copy(
                selectedCashRegisterConfigItemId =  id,
                firstPageActive = !state.value.firstPageActive
            )
        }
    }

    private fun getCashRegisterConfigData(  ) {
        if (state.value.selectedCashRegisterConfigItemId!=null){
            launch {
                try {
                    withTokenRefresh(refreshToken){
                        showProgress()
                        val result=getCashRegisterConfigUseCase(
                            id=state.value.selectedCashRegisterConfigItemId?:""
                        )
                        hideProgress()
                        _state.value=_state.value.copy(
                            cashRegisterConfigData = result.data?.cashAssist?.getCashRegister
                        )
                    }
                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }
            }
        }


    }

    private fun getCheckoutData( ) {
        launch {
            if (state.value.cashList==null){

                val result=getSavedCheckOutUseCase()

                _state.value=_state.value.copy(
                    cashList = result?.data?.cashAssist?.children
                )
                tempCheckoutList=state.value.cashList
            }

        }

    }



    private fun searchCheckoutItems(value: String)
    {
        _state.value= _state.value.copy(cashList =tempCheckoutList )


        val searchedList = ArrayList<Children>()
        state.value.cashList?.forEach { model ->
            var matchFound = model.name?.lowercase()?.contains(value.lowercase()) ?: false
            model.children?.forEach { childX ->
                if (childX.name?.lowercase()?.contains(value.lowercase()) == true) {
                    matchFound = true
                }
                childX.children?.forEach { childXX ->
                    if (childXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                        matchFound = true
                    }
                    childXX.children.forEach {childXXX->
                        if (childXXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                            matchFound = true
                        }
                    }
                }
            }
            if (matchFound) {
                searchedList.add(model)
            }
        }


        _state.value=_state.value.copy(
            cashList = searchedList
        )


    }


    private fun updateAppConfig(
        title: String,
        value: List<String?>
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken){
                    showProgress()
                    val result=updateAppConfigUseCase(
                        title=title,
                        value=value
                    )

                    _state.value=_state.value.copy(
                        appConfig = result.data?.cashAssist?.updateAppConfig
                    )
                    hideProgress()
                }

            }catch (e:Exception){
                _state.value=_state.value.copy(
                    appConfig = state.value.appConfig
                )
                handleError(e)
                hideProgress()
            }
        }

    }

    private fun getAppConfig() {
        if (state.value.appConfig==null){
            launch {
                try {
                    withTokenRefresh(refreshToken){
                        showProgress()
                        val result=appConfigUseCase()

                        _state.value=_state.value.copy(
                            appConfig = result.data?.cashAssist?.getAppConfig
                        )
                        hideProgress()
                    }

                }catch (e:Exception){
                    handleError(e)
                    hideProgress()
                }
            }
        }


    }

    private fun updateVirtualCardInfo(
        dataTitle: String,
        dataValue: String
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken){
                    showProgress()
                    val result: VirtualCardConfigModel
                    val info=state.value.virtualCardInfo

                    when(dataTitle)
                    {
                        ApiUtils.VirtualCardFields.PHONE_LINK-> {
                            val json= buildJsonObject {
                                put(ApiUtils.Field.link,dataValue)
                                put(ApiUtils.Field.description,
                                    info?.linkPhoneUri?.description
                                )
                            }

                            result=updateVirtualCardInfoUseCase(
                                dataTitle=ApiUtils.Field.linkPhoneUri,
                                dataValue= json
                            )
                        }
                        ApiUtils.VirtualCardFields.PHONE_DESCRIPTION->{

                            val json= buildJsonObject {
                                put(ApiUtils.Field.description,dataValue)
                                put(ApiUtils.Field.link,info?.linkPhoneUri?.link)
                            }
                            result=updateVirtualCardInfoUseCase(
                                dataTitle=ApiUtils.Field.linkPhoneUri,
                                dataValue= json
                            )
                        }
                        ApiUtils.VirtualCardFields.HOME_PAGE_LINK->{
                            val json= buildJsonObject {
                                put(ApiUtils.Field.link,dataValue)
                                put(ApiUtils.Field.description,info?.homepageUri?.description)
                            }
                            result=updateVirtualCardInfoUseCase(
                                dataTitle=ApiUtils.Field.homepageUri,
                                dataValue= json
                            )

                        }
                        ApiUtils.VirtualCardFields.HOME_PAGE_DESCRIPTION->{
                            val json= buildJsonObject {
                                put(ApiUtils.Field.description,dataValue)
                                put(ApiUtils.Field.link,info?.homepageUri?.link)
                            }

                            result=updateVirtualCardInfoUseCase(
                                dataTitle=ApiUtils.Field.homepageUri,
                                dataValue= json
                            )
                        }

                        else->{
                            result=updateVirtualCardInfoUseCase(
                                dataTitle=dataTitle,
                                dataValue=dataValue
                            )
                        }
                    }

                    _state.value=_state.value.copy(
                        virtualCardInfo = result.data?.cashAssist?.updateVirtualCardConfig
                    )

                    hideProgress()
                }

            }catch (e:Exception){
                handleError(e)
                hideProgress()
            }
        }

    }


    private fun updateKitchenLocations(
        newList: ArrayList<String?>,
    ) {

        launch {
            try {
                withTokenRefresh(refreshToken) {
                    _state.value=_state.value.copy(addRecordLoading = true)
                    val result=updateKitchenLocationUseCase(
                        printerLocation = newList
                    )
                    _state.value=_state.value.copy(
                        printersLocation = result.data.cashAssist
                            .updateAppConfig?.cashRegisterGlobal?.printerLocations
                            ?.sortedBy { it?.dbIndex },
                        showKitchenLocationRecordDialog = false,
                        printerLocationIndex = null,
                        newRecordName = "",

                        )
                    _state.value=_state.value.copy(addRecordLoading = false)
                }

            }catch (e:Exception){
                handleError(e)
                _state.value=_state.value.copy(addRecordLoading = false)
            }

        }


    }

    private fun getPrintersLocation() {
        if (state.value.printersLocation==null)
        {
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()
                        val result=printerLocationNamesUseCase()
                            .data.cashAssist.getAppConfig?.cashRegisterGlobal
                            ?.printerLocations
                        hideProgress()
                        _state.value=_state.value.copy(
                            printersLocation =result?.sortedBy { it?.dbIndex }?.toList()
                        )
                    }


                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }


            }
        }



    }



    private fun onSubmitSaleGroupRecordDialogClick(
        name: String,
        code: String,
        windowMode: WindowSizeModeEnum,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    _state.value=_state.value.copy(addRecordLoading = true)
                    val result=addNewSaleGroupUseCase(
                        name=name,
                        accountNumber = code
                    )
                    _state.value=_state.value.copy(
                        selectedSaleGroup =result.data.cashAssist.createMutation,
                        addRecordLoading = false,
                        showAddSaleGroupRecordDialog = false,
                        newRecordName = "",
                        newRecordNumber = "",
                        firstPageActive = if(windowMode== WindowSizeModeEnum.PHONE) false
                        else true
                    )
                    getProductSalesGroup()
                }

            }catch (e:Exception){
                _state.value=_state.value.copy(addRecordLoading = false)
                _state.value=_state.value.copy(
                    addRecordErrorMessage = e.message.toString()
                )
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun onChangeRecordNumber(value: String) {
        _state.value=_state.value.copy(
            newRecordNumber = value
        )
        checkAddRecordSubmitClickable()
    }

    private fun onChangeRecordName(value: String?,isNumberOptional: Boolean=false) {
        _state.value=_state.value.copy(
            newRecordName = value
        )
        checkAddRecordSubmitClickable(isNumberOptional = isNumberOptional)
    }

    private fun checkAddRecordSubmitClickable(
        isNumberOptional:Boolean=false,
    ){

        val isClickable = if (isNumberOptional) {
            state.value.newRecordName?.isNotEmpty()
        } else {
            state.value.newRecordNumber.isNotEmpty() && state.value.newRecordName?.isNotEmpty()?:false
        }

        _state.value = _state.value.copy(isAddNewRecordSubmitClickable = isClickable?:false)


    }

    private fun dismissAddRecordDialog() {
        _state.value=_state.value.copy(
            showAddSaleGroupRecordDialog = false,
            showKitchenLocationRecordDialog=false,
            printerLocationIndex = null,
            addRecordErrorMessage = "",
            isAddNewRecordSubmitClickable = false,
            newRecordName = "",
            newRecordNumber = ""
        )
    }

    private fun onAddRecordIconClick() {
        _state.value=_state.value.copy(
            showAddSaleGroupRecordDialog = true
        )
    }


    private fun updateSaleGroup(
        dataTitle: String,
        dataValue: String,
        id: String
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=updateSaleGroupUseCase(
                        id=id,
                        dataTitle=dataTitle,
                        dataValue=dataValue
                    )
                    _state.value=_state.value.copy(
                        selectedSaleGroup = result.data.cashAssist.updateSalesGroup
                    )
                    if (
                        dataTitle== ApiUtils.Field.acountNum
                        || dataTitle== ApiUtils.Field.name
                    ) {
                        getProductSalesGroup()
                    }else hideProgress()
                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }
    }

    private fun changePage() {
        _state.value=_state.value.copy(
            firstPageActive = !_state.value.firstPageActive,
        )
    }

    private fun onSaleGroupNumberSortClick() {

        val newData:List<SaleItem>
        if (_state.value.isSaleGroupNumberSortedAsc) {
            newData=_state.value.productSalesGroup?.sortedByDescending { it.acountNum }?.toList()?: listOf()
        }else{
            newData=_state.value.productSalesGroup?.sortedBy { it.acountNum }?.toList()?: listOf()
        }
        _state.value=_state.value.copy(
            productSalesGroup = newData,
            isSaleGroupNumberSortedAsc =!_state.value.isSaleGroupNumberSortedAsc,
            showSaleGroupNumberSortIcon = true,
            showSaleGroupTitleSortIcon = false
        )
    }


    private fun onSaleGroupTitleSortClick() {
        val newData:List<SaleItem>
        if (_state.value.isSaleGroupNameSortedAsc) {
            newData= _state.value.productSalesGroup?.
                sortedByDescending { it.name.lowercase() }?.toList()?: listOf()
        }else{
            newData= _state.value.productSalesGroup?.
                sortedBy { it.name.lowercase() }?.toList()?: listOf()
        }
        _state.value=_state.value.copy(
            productSalesGroup = newData,
            isSaleGroupNameSortedAsc =!_state.value.isSaleGroupNameSortedAsc,
            showSaleGroupTitleSortIcon = true,
            showSaleGroupNumberSortIcon = false
        )
    }

    private fun onSearchSaleGroupInputChange(value: String) {
        _state.value= _state.value.copy(productSalesGroup =tempSalesGroup )
        val newData=_state.value.productSalesGroup?.filter {model ->
            model.name.contains(value, ignoreCase = true) ||
            model.acountNum?.contains(value, ignoreCase = true)==true
        }
        _state.value=_state.value.copy(
            productSalesGroup = newData
        )

    }

    private fun getProductSalesGroup( ) {
        launch {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()
                        val result=salesGroupUseCase()
                        _state.value=_state.value.copy(
                            productSalesGroup = result.data.cashAssist.listSalesGroups.items
                        )
                        tempSalesGroup=state.value.productSalesGroup?: listOf()

                        if (
                            state.value.productSalesGroup?.isNotEmpty() ?: false &&
                            state.value.selectedSaleGroup==null
                        ){

                            onSaleGroupItemClick(
                                id = state.value.productSalesGroup?.get(0)?.id?:"",
                            )
                        }else hideProgress()
                    }

                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }


        }

    }

    private fun onSaleGroupItemClick(
        id: String,
        switchTheSecondPage: () -> Unit={},
    ) {
        if (id!=state.value.selectedSaleGroup?.id){
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()
                        val result=singleSaleGroupUseCase(id=id)
                        hideProgress()
                        _state.value=_state.value.copy(
                            selectedSaleGroup = result.data.cashAssist.getSalesGroup
                        )
                        switchTheSecondPage()
                    }

                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }

            }
        }else switchTheSecondPage()

    }


    private fun saveCompanyProfileId(id: String?) {
        if (id!=null){
            try {
                saveCompanyProfileIdUseCase(id = id)
            }catch (e:Exception){
                handleError(e)
            }
        }

    }

    private fun updateOptionData(
        dataTitle:String,
        dataValue:dynamic,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    updateOptionUseCase(
                        dataTitle=dataTitle,
                        dataValue=dataValue,
                    )
                    hideProgress()
                    if(dataTitle== ApiUtils.Field.countryId)
                        saveCompanyProfileId(dataValue as String)
                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }


    }

    private fun loadOperationData(){
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=optionsDataUseCase()
                    hideProgress()
                    _state.value=_state.value.copy(
                        optionModel = result.data.cashAssist.getAppConfig
                    )
//
//                    saveCompanyProfileId(id =state.value.optionModel?.countryId)
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }


        }
    }



    private fun showProgress()
    {
        _state.value=_state.value.copy(
            dataLoadingProgressVisible = true
        )
    }
    private fun hideProgress()
    {
        _state.value=_state.value.copy(
            dataLoadingProgressVisible = false
        )
    }

    private fun generateMenuCard(
        files: List<File>,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    _state.value=_state.value.copy(isMenuCardAIGeneratorLoading = true)
                    var result=getMenuCardUseCase(files=files)

                    if (result.startsWith("\"") && result.endsWith("\"")) {
                        result = result.substring(1, result.length - 1).replace("\\", "")
                    }

                    val menuCard = Json.decodeFromString<MenuCardModel>(result)
                    _state.value=_state.value.copy(isMenuCardAIGeneratorLoading = false)
                    _state.value=_state.value.copy(showGenerateAIMenuCardDialog = false)
                    _state.value = _state.value.copy(
                        menuCardAIGenerated = menuCard,
                        menuCardPDFFilesUploaded = 0
                    )
                }

            } catch (e:Exception){
                _state.value=_state.value.copy(isMenuCardAIGeneratorLoading = false)
                handleError(
                    error = e,
                )
            }

       }
    }

    private fun saveMenuCard(
        menuCard:MenuCardModel,
    ) {
        // TO DO SAVING PROCCESS WITH GRAPHQL
//        menuCard.categories = menuCard.items
//                .mapNotNull { it.category } // Transform List<MenuItem> to List<String> of category
//                .distinct() // Remove duplicates // Convert to MutableList
//                .toMutableList()
        _state.value=_state.value.copy(
            //
            // menuCardAIGenerated = menuCard
            menuCardAIGenerated = MenuCardModel(categories = mutableListOf(), items = mutableListOf()),
        )
    }

    private fun onGenerateMenuCardButtonClick() {
        _state.value=_state.value.copy(
            showGenerateAIMenuCardDialog = true
        )
    }

    private fun onMenuProductItemValueChange(
        menuCard:MenuCardModel,
        id:Int,
        key:String,
        value:String
    ) {
        menuCard.items[id] = menuCard.items[id].let { item ->
                when (key) {
                    "Name" -> item.copy(name = value)
                    "Description" -> item.copy(description = value)
                    "Price" -> item.copy(price = value)
                    "Weight" -> item.copy(weight = value)
                    "Allergens" -> item.copy(allergens = value.split(",").map(String::trim))
                    "Category" -> item.copy(category = value)
                    "TurnoverGroup" -> item.copy(turnoverGroup = value)
                    "Template" -> item.copy(template = value)
                    "Ingredients" -> item.copy(ingredients = value)
                    "Alcoholic" -> item.copy(alcoholic = value.toBoolean())
                    "Vegan" -> item.copy(vegan = value.toBoolean())
                    "Vegetarian" -> item.copy(vegetarian = value.toBoolean())
                    "ProductNumber" -> item.copy(productNumber = value)
                    else -> item
            }
        }
        _state.value=_state.value.copy(
            menuCardAIGenerated = menuCard,
        )
    }


}




