package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.devices

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.util.ApiUtils

enum class ClosingTypeEnum(
    val title:String,
    val value:String,
) {
    WITH_CASH_REPORT (
        title = Strings.withCashReport,
        value = ApiUtils.Field.withCashReport,
    ),
    STANDARD(
        title = Strings.standard,
        value = ApiUtils.Field.standard,
    ),
    WITHOUT_CASH(
        title = Strings.withoutCash,
        value = ApiUtils.Field.withoutCash,
    ),
    WITH_CASH_REPORT_AND_COUNT_PROTOCOL(
        title = Strings.withCashReportAndCountProtocol,
        value = ApiUtils.Field.withCashReportAndCountProtocol,
    ),
    STANDARD_WITHOUT_PRINTING(
        title =Strings.standardWithoutPrinting,
        value = ApiUtils.Field.standardWithoutPrinting,
    ),

}