package org.hs_soft.runmynesto.data.api_datasource.options.operation

import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.operation.UpdateOptionRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateOptionRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateOptionRepository {
    override suspend fun updateOption(
        dataValue: dynamic,
        dataTitle: String
    ) {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
           mutation updateAppConfig(${'$'}fields: AppConfigInput!) {
             cashAssist {
               updateAppConfig(fields: ${'$'}fields) {
                 ${ApiUtils.Field.name}
               }
             }
           }
        """.trimIndent())

            console.log(dataValue)
            putJsonObject("variables") {
                putJsonObject("fields") {
                    when(dataValue){
                        is Boolean ->put(dataTitle, dataValue as Boolean)
                        is String ->put(dataTitle,dataValue as String)
                    }
                }
            }

        }

       apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
           abortController = abortController,
        ) ?: throw Exception(Strings.timeoutErr)

    }

}
