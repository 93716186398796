package org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetStatisticsData(
    @SerialName("data")
    val `data`: List<BillSearchStatisticDataItem>?,
    @SerialName("progress")
    val progress: Int?,
    @SerialName("status")
    val status: String?,
    @SerialName("__typename")
    val typename: String?
)