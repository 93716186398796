package org.hs_soft.runmynesto.di

import org.hs_soft.runmynesto.pages.home.mvi.HomeViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.mvi.DashboardViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.product.mvi.ProductViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.template.mvi.TemplateViewModel
import org.hs_soft.runmynesto.pages.home.sub_page.user.mvi.UserViewModel
import org.hs_soft.runmynesto.pages.login.LoginViewModel
import org.koin.dsl.module

val viewModelModule = module {
    factory {
        LoginViewModel(
            signInUseCase = get(),
            isLoggedInUseCase = get()
        )
    }

    factory {
        HomeViewModel(
            tokenProvider = get(),
            fetchIdTokenUseCase = get(),
            isLoggedInUseCase = get(),
            saveCheckOutUseCase = get(),
            checkOutDataUseCase = get(),
            signOutUseCase = get(),
            userProfileDataUseCase = get(),
            hsSoftAppsUseCase = get(),
        )
    }

    factory { (  refreshToken: () -> Unit) ->
        ProductViewModel(
            requestManager = get(),
            refreshToken=refreshToken,
            getDishPictureUseCase = get(),
            checkedIngredientsUseCase = get(),
            updateAllergenUseCase = get(),
            updateProductCheckoutUseCase = get(),
            updateProductPropertiesUseCase = get(),
            ingredientListUseCase = get(),
            updateProductCashBidImageUseCase = get(),
            updateProductUseCase = get(),
            productSaleGroupUseCase = get(),
            singleProductUseCase = get(),
            kitchenInfoUseCase = get(),
            productRestaurantUseCase = get(),
            printerLocationNamesUseCase = get(),
            updateProductRestaurantUseCase = get(),
            addProductUseCase = get(),
            productCategoriesUseCase = get(),
            imgUrlUseCase = get(),
            updateProductPriceUseCase = get(),
            getCompanyProfileIdUseCase = get(),
            addProductCategoryToDBUseCase = get(),
            getCachedProductCategoriesUseCase = get(),
            saveCachedProductCategoriesUseCase = get(),
            createProductAdditionUseCase = get(),
            updateProductAdditionUseCase = get(),
            getSavedCheckOutUseCase = get(),
        )
    }

    factory { ( refreshToken: () -> Unit) ->
        TemplateViewModel(
            requestManager = get(),
            refreshToken = refreshToken,
            getTemplateCategoriesUseCase = get(),
            getSingleTemplateCategoryUseCase =get(),
            addCategoryUseCase = get(),
            updateProductsPositionsUseCase = get(),
            updateTemplateCategoryUseCase = get(),
            updateTemplateCategoryImgUseCase = get(),
            updateSurchargeUseCase=get(),
            priceLevelsUseCase = get(),
            updatePriceLevelUseCase = get(),
            updateTemplateCheckoutUseCase = get(),
            getDishPictureUseCase = get(),
            updateProductUseCase = get(),
            productCategoriesUseCase = get(),
            getSavedCheckOutUseCase = get(),

        )
    }

    factory { (  refreshToken: () -> Unit) ->
        UserViewModel(
            requestManager = get(),
            refreshToken = refreshToken,
            updateUserCheckoutUseCase = get(),
            updateUserPermissionUseCase = get(),
            addUserUseCase = get(),
            singleUserUseCase = get(),
            usersDataUseCase = get(),
            updateUserWaiterUseCase = get(),
            updateUserImageUseCase = get(),
            updateUserDataUseCase = get(),
            getSavedCheckOutUseCase = get(),
        )
    }

    factory { (refreshToken: () -> Unit) ->
        ClosingReportsViewModel(
            requestManager = get(),
            refreshToken = refreshToken,
            getSavedCheckOutUseCase = get(),
            closingReportsUseCase = get(),
            closingReportDetailUseCase = get(),
            getCompanyProfileIdUseCase = get(),
        )
    }

    factory { (  refreshToken: () -> Unit) ->
        OptionsViewModel(
            requestManager = get(),
            refreshToken=refreshToken,
            updateKitchenLocationUseCase = get(),
            optionsDataUseCase = get(),
            addNewSaleGroupUseCase = get(),
            singleSaleGroupUseCase = get(),
            updateSaleGroupUseCase = get(),
            salesGroupUseCase = get(),
            printerLocationNamesUseCase = get(),
            updateOptionUseCase = get(),
            saveCompanyProfileIdUseCase = get(),
            updateVirtualCardInfoUseCase = get(),
            appConfigUseCase = get(),
            updateAppConfigUseCase = get(),
            getCashRegisterConfigUseCase = get(),
            updateCashRegisterConfigUseCase = get(),
            getVirtualCardInfoUseCase = get(),
            priceLevelsUseCase = get(),
            getSavedCheckOutUseCase = get(),
        )
    }

    factory { (refreshToken: () -> Unit) ->
        CustomerCardViewModel(
            requestManager = get(),
            refreshToken = refreshToken,
            loyaltyCardsUseCase = get(),
            loyaltyCardDetailUseCase = get(),
            creditClientUseCase = get(),
            updateLoyaltyCardUseCase = get(),
            createCustomerCardUseCase = get(),
            updateRangeLoyaltyCardUseCase = get(),
            resetRangeLoyaltyCardUseCase = get(),
            createLoyaltyCardUseCase = get(),
            filteredTransactionLoyaltyCardUseCase = get()
        )
    }
    factory {(refreshToken:()->Unit) ->
        DashboardViewModel(
            requestManager = get(),
            refreshToken=refreshToken,
            initShopStaticsUseCase = get(),
            shopStaticsUseCase = get(),
            dashboardConfigUseCase = get(),
            getCompanyProfileIdUseCase = get(),
            customerFrequencyUseCase = get(),
        )
    }

}

