package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs

import org.hs_soft.runmynesto.domain.config.Strings

enum class LoyaltyCardTypeEnum(
    val title:String,
){
    RFID(
        title = Strings.rfidCustomerCard,
    ),
    DIGITAL(
        title = Strings.digitalCustomerCard,
    )
}