package org.hs_soft.runmynesto.di.statistic

import org.hs_soft.runmynesto.domain.usecase.statistic.abs_statistic.ABCStatisticDataUseCase
import org.hs_soft.runmynesto.domain.usecase.statistic.abs_statistic.InitABCStatisticUseCase
import org.hs_soft.runmynesto.domain.usecase.statistic.search_bill.BillPdfUseCase
import org.hs_soft.runmynesto.domain.usecase.statistic.search_bill.InitSearchBillUseCase
import org.hs_soft.runmynesto.domain.usecase.statistic.search_bill.BillSearchStatisticDataUseCase
import org.koin.dsl.module

val statisticUseCaseModule= module {

    single {
        InitSearchBillUseCase(get())
    }
    single {
        BillSearchStatisticDataUseCase(get())
    }
    single {
        InitABCStatisticUseCase(get())
    }
    single {
        ABCStatisticDataUseCase(get())
    }
    single {
        BillPdfUseCase(get())
    }


}