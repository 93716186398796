package org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Shop(
    @SerialName("id")
    val id: String?,
    @SerialName("__typename")
    val typename: String?
)