package org.hs_soft.runmynesto.pages.login

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.di.GlobalKoin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.noBorder
import org.hs_soft.runmynesto.navigation.Screen
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Img
import org.koin.core.parameter.parametersOf


@Page
@Composable
fun Login()
{
    val context= rememberPageContext()
    val viewModel = remember {
        GlobalKoin.get<LoginViewModel> { parametersOf() }

    }
    val state by viewModel.state.collectAsState()

    LaunchedEffect(Unit) {
        viewModel.onEvent(LoginEvent.UpdateIsLoggedInStatus)

    }
    LaunchedEffect(state.isLoggedIn){
        if (state.isLoggedIn && !state.isCheckingLoggedInLoading){
            context.router.navigateTo(
                Screen.HomePage.route,
                updateHistoryMode = UpdateHistoryMode.REPLACE,

            )
        }
    }


    if (!state.isLoggedIn && !state.isCheckingLoggedInLoading)
    {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .backgroundColor(Theme.Blue.rgb)
        )
        {

            Column(
                modifier = Modifier
                    .align(Alignment.Center)
                    .backgroundColor(Theme.White.rgb)
                    .borderRadius(r= Dimen.loginDialogCorner)
                    .width(Dimen.loginDialogWidth)
                    .height(Dimen.loginDialogHeight),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            )
            {

                Img(src = "/logo_img.png", alt = "Logo", attrs = {
                    style {

                    }
                })

                Box(modifier = Modifier.height(Dimen.largePadding))


                if (!state.isFirstSignInStepPassed){
                    CustomEditText(
                        value = state.userName,
                        width = Dimen.loginInputWidth,
                        onInputChange={
                            viewModel.onEvent(LoginEvent.OnUserNameChange(it))
                        },
                        inputType = InputType.Email,
                        hint = Strings.enterYourEmail,
                        onFocusIn = {

                        },
                        onFocusOut = {

                        }
                    )


                    CustomEditText(
                        value = state.password,
                        width = Dimen.loginInputWidth,
                        onInputChange={
                            viewModel.onEvent(LoginEvent.OnPasswordChange(it))
                        },
                        inputType = InputType.Password,
                        hint = Strings.enterYourPassword,
                        onFocusIn = {

                        },
                        onFocusOut = {

                        }
                    )



                }else{

                    CustomEditText(
                        width = Dimen.loginInputWidth,
                        value = state.mfaCode,
                        onInputChange={
                            viewModel.onEvent(LoginEvent.OnMFAChange(it))
                        },
                        hint = Strings.mfaCode,

                        onFocusIn = {

                        },
                        onFocusOut = {

                        },
                        isDecimal = true
                    )


                }





                Button(
                    attrs = Modifier
                        .margin(bottom = Dimen.mediumPadding, top = Dimen.mediumPadding)
                        .width(Dimen.loginInputWidth)
                        .height(Dimen.loginSubmitHeight)
                        .backgroundColor(
                            if(fieldsAreFilled(
                                    state.userName,
                                    state.password,
//                                    state.mfaCode,
                                    state.isFirstSignInStepPassed
                            )){
                                if(state.isHoveredOnButton) Theme.DarkGreen.rgb
                                else Theme.Green.rgb
                            }

                            else Theme.GrayCD.rgb
                        )
                        .color(Colors.White)
                        .borderRadius(r = Dimen.lowBorderRadius)
                        .fontFamily()
                        .noBorder()
                        .fontWeight(FontWeight.Medium)
                        .fontSize(14.px)
                        .cursor(
                            Cursor.Pointer
                        )
                        .onMouseEnter {
                            viewModel.onEvent(LoginEvent.OnMouseEnterSubmit)
                        }
                        .onMouseLeave {
                            viewModel.onEvent(LoginEvent.OnMouseLeaveSubmit)
                        }
                        .onClick()
                        {
                            if(fieldsAreFilled(
                                state.userName,
                                state.password,
//                                state.mfaCode,
                                state.isFirstSignInStepPassed,
                            ))
                            {
                                if (!state.isFirstSignInStepPassed) {
                                    viewModel.onEvent(LoginEvent.PassFirstSignInStep)
                                }else
                                {
                                    viewModel.onEvent(LoginEvent.OnSignInClick(
                                        userName =state.userName,
                                        password= state.password,
                                        mfsCode=state.mfaCode,
                                        goToHomePage = {
                                            context.router.navigateTo(Screen.HomePage.route)
                                        }
                                    ))
                                }
                            }
                        }
                        .toAttrs()
                ){
                    SpanText(
                        text= Strings.login
                    )
                }




                SpanText(
                    modifier = Modifier
                        .height(Dimen.fieldErrorMsgHeight)
                        .margin(bottom = Dimen.mediumPadding)
                        .color(Theme.Red.rgb)
                        .cursor(Cursor.Pointer)
                        .fontSize(FontSizes.normalFontSize)
                    ,
                    text = state.errorMsg
                )


                SpanText(
                    modifier = Modifier
                        .color(Theme.Gray3E.rgb)
                        .cursor(Cursor.Pointer)
                        .fontSize(FontSizes.mediumFontSize)
                        .onClick {

                        }
                    ,
                    text = Strings.forgetYourPassword
                )

            }

//            if (state.isLoading){
                LoadingProgress(
                    visibility = state.isLoading,
                    modifier = Modifier
                        .align(Alignment.Center)
                        .width(Dimen.loginDialogWidth)
                        .height(Dimen.loginDialogHeight)
                        .borderRadius(r= Dimen.loginDialogCorner)
                        .backgroundColor(Theme.Transparent10Percent.rgb)
                )
//            }





        }
    }






}

fun fieldsAreFilled(
    userName: String,
    password: String,
//    mfaCode:String,
    isSingInFirstStep:Boolean,
): Boolean
{
    if (isSingInFirstStep==false)
    {
        return userName.isNotEmpty() && password.isNotEmpty()
    }else{
         return true//mfaCode.length== Constants.MFA_LENGTH

    }



}