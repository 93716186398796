package org.hs_soft.runmynesto.data.api_datasource.customer_card

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.add
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonArray
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card.LoyaltyCardModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.GetLoyaltyCardsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class GetLoyaltyCardsRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
):GetLoyaltyCardsRepository
{
    override suspend fun getLoyaltyCards(
        searchValue:String,
        limit: Int ,
        offset: Int,
        isActive:Boolean?
    ): LoyaltyCardModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                 query listLoyaltyCards(${'$'}params: ListQueryArgs, ${'$'}filter: LoyaltyCardFilterArgs) {
                   cashAssist {
                     listLoyaltyCards(params: ${'$'}params, filter: ${'$'}filter) {
                       ...listLoyaltyCards
                       ${ApiUtils.Field.__typename}
                     }
                     ${ApiUtils.Field.__typename}
                   }
                 }

                 fragment listLoyaltyCards on LoyaltyCardConnection {
                   ${ApiUtils.Field.hasNextPage}
                   items {
                     ${ApiUtils.Field.id}
                     ${ApiUtils.Field.name}
                     ${ApiUtils.Field.balance}
                     ${ApiUtils.Field.points}
                     ${ApiUtils.Field.type}
                     ${ApiUtils.Field.isActive}
                     ${ApiUtils.Field.__typename}
                   }
                   cursors {
                     ${ApiUtils.Field.hasNext}
                     ${ApiUtils.Field.hasPrevious}
                     ${ApiUtils.Field.before}
                     ${ApiUtils.Field.after}
                     ${ApiUtils.Field.__typename}
                   }
                   ${ApiUtils.Field.total}
                   ${ApiUtils.Field.__typename}
                   
                 }

                 

            """.trimIndent())

            putJsonObject("variables") {
                putJsonObject("params") {
                    put("limit", limit)
                    putJsonArray("sort") {
                        // Add sorting fields if needed
                    }
                    putJsonObject("cursors") {
                        put("limit", limit)
                    }

                    if (searchValue.isNotEmpty()){
                        putJsonObject("search") {
                            put("string", searchValue)
                            putJsonArray("columns") {
                                add(ApiUtils.Field.id)
                                add(ApiUtils.Field.name)
                                add(ApiUtils.Field.balance)
                                add(ApiUtils.Field.points)
                                add(ApiUtils.Field.barCode)
                                add(ApiUtils.Field.phoneNumber)
                            }
                        }
                    }

                    put("offset", offset)
                }
                putJsonObject("filter"){
                    if (isActive!=null)
                        put("isActive",isActive)
                }

            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }
    }

}