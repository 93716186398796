package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.ingeredient_list


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class IngredientModel(
    @SerialName("data")
    val  data : Data
)