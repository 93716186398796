package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component

import androidx.compose.runtime.Composable
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.abc_statistic.component.CheckoutDialog
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.bill_search.components.PdfPreviewDialog
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi.StatisticEvent
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi.StatisticState

@Composable
fun StaticDialogs(
    state: StatisticState,
    windowSizeMode: WindowSizeModeEnum,
    event:(StatisticEvent)->Unit,
)
{
    if (state.showPdfPreviewDialog && state.billPdfResult!=null)
    {
        PdfPreviewDialog(
            windowSizeMode=windowSizeMode,
            pdfResultUrl=state.billPdfResult,
            dismissDialog = {
                event(StatisticEvent.SetPdfPreviewDialogStatus(false))
            }
        )
    }


    if (state.showCheckoutDialog)
    {
        CheckoutDialog(
            windowSizeMode=windowSizeMode,
            cashList = state.cashList?: listOf(),
            isDevicesExpandAll = state.isDevicesExpandAll,
            cashRegisters = state.cashRegisters?: listOf(),
            onBackgroundClick = {
                event(StatisticEvent.SetCheckoutDialogStatus(false))
            },
            updateSelectedCheckout = {
                event(StatisticEvent.UpdateSelectedCheckout(cashRegisterIds=it))
            },
            onSearchCheckoutItems = {
                event(StatisticEvent.OnSearchCheckoutItems(it))
            }
        )
    }


}

