package org.hs_soft.runmynesto.domain.model.home.options

enum class LanguageEnum(val displayName: String) {
    German("German"),
    English("English"),
    French("French"),
    Italian("Italian"),
    Polish("Polish"),
    Russian("Russian"),
    Portuguese("Portuguese");

    companion object {
        fun fromDisplayName(displayName: String): LanguageEnum? =
            entries.firstOrNull { it.displayName == displayName }
    }
}