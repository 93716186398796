package org.hs_soft.runmynesto.data.api_datasource.template

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.UpdateListOfCategoriesPositionsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateListOfCategoriesPositionsRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
):UpdateListOfCategoriesPositionsRepository {

    override suspend fun updateCategoriesPositions(
        dataValue: List<Int>,
        dataTitle: String,
        idsList: List<String>
    ) {
        val abortController = requestManager.createController()
        val graphQLQuery = buildJsonObject {
            put("query", """
            mutation UpdateManyCategories(${'$'}idArr: [ID]!, ${'$'}fields: CategoryFields!) {
              cashAssist {
                updateManyCategories(idArr: ${'$'}idArr, fields: ${'$'}fields) {
                  ${ApiUtils.Field.id}
                }
              }
            }
        """.trimIndent())

            console.log(dataValue)
            putJsonObject("variables") {
                put("idArr", Json.encodeToJsonElement(idsList))
                putJsonObject("fields") {
                    put(dataTitle,Json.encodeToJsonElement(dataValue))
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }
}