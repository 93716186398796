package org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("listLoyaltyCards")
    val listLoyaltyCards: ListLoyaltyCards?,
    @SerialName("__typename")
    val typename: String?
)