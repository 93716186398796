package org.hs_soft.runmynesto.domain.usecase.options.kitchen_locatoin

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location.PrinterLocationModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.kitchen_location.UpdateKitchenLocationRepository


class UpdateKitchenLocationUseCase
    (private val repository: UpdateKitchenLocationRepository) {
    suspend operator fun invoke(
        printerLocation:List<String?>,
    ): PrinterLocationModel {
        return repository.updateKitchenLocation(printerLocation)
    }
}