package org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general

import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.set_grid_product.SetGridProductModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.TemplateCategoryDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.SetGridProductRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.SingleTemplateCategoryRepository

class SetGridProductUseCase
    (private val repository: SetGridProductRepository)
{
    suspend operator fun invoke(
        categoryId: String,
        position: Int,
        productId: String?
    ): SetGridProductModel {
        return repository.setGridProduct(
            categoryId=categoryId,
            position=position,
            productId=productId,
        )
    }
}
