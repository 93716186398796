package org.hs_soft.runmynesto.pages.components.dialog.dialog

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun ProfileBadgeDialog(
    modifier: Modifier = Modifier,
    onLogoutClick: () -> Unit
) {

    Box(
        modifier = modifier
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    ) {
        Column(
            modifier = Modifier
                .width(Dimen.mediumEditTextWidth)
                .align(Alignment.CenterEnd)
                .overflow(Overflow.Auto)
                .zIndex(Constants.ZIndex.mediumZIndex)
                .boxShadow(
                    color = Theme.GrayAlpha12.rgb,
                )
                .backgroundColor(Theme.White.rgb),
            horizontalAlignment = Alignment.End
        ) {
            BadgeItem(
                img = Res.PathIcon.user,
                title = Strings.myProfile,
                onItemClick = {

                }
            )

            BadgeItem(
                img = Res.PathIcon.backToAdmin,
                title = Strings.backToAdmin,
                onItemClick = {

                }
            )
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(Dimen.smallThickness)
                    .backgroundColor(Theme.GrayCD.rgb)
            )

            BadgeItem(
                img = Res.PathIcon.exitApp,
                title = Strings.logout,
                onItemClick = {
                    onLogoutClick()
                }
            )
        }
    }
}

@Composable
fun BadgeItem(
    img:String,
    title:String,
    onItemClick:()->Unit,
) {
    val isHovered= remember { mutableStateOf(false) }
    Row(
        modifier = Modifier
            .padding(
                topBottom = Dimen.semiNormalPadding,
                leftRight = Dimen.normalPadding
            )
            .cursor(Cursor.Pointer)
            .onClick {
                onItemClick()
            }
            .backgroundColor(
                if (isHovered.value) Theme.GrayAlpha12.rgb
                else Theme.Transparent.rgb
            )
            .transitionBackground()
            .onMouseOver { isHovered.value=true }
            .onMouseLeave { isHovered.value=false}
            .fillMaxWidth()

    )
    {
        VectorIcon(
            color = Theme.GrayAlpha56.hex,
            pathData = img,
            width = Dimen.mediumIconSize ,
            height = Dimen.mediumIconSize,
        )

        SpanText(
            text = title,
            modifier = Modifier
                .margin(leftRight = Dimen.mediumPadding)
                .fontSize(FontSizes.semiLargeFontSize)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Montserrat)
        )



    }



}
