package org.hs_soft.runmynesto.di.login

import org.hs_soft.runmynesto.domain.usecase.login.IsLoggedInUseCase
import org.hs_soft.runmynesto.domain.usecase.login.SignInUseCase
import org.koin.dsl.module


val loginUseCaseModule= module {
    single {
        SignInUseCase(get())
    }
    single {
        IsLoggedInUseCase(get())
    }



}