package org.hs_soft.runmynesto.domain.model.menu

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.util.Res

enum class MenuItemsEnum(
    val title: String,
    val iconPath: String,
    val iconPath20:String,
) {
    DASHBOARD(
        title=Strings.dashboard,
        iconPath=Res.PathIcon.dashboard,
        iconPath20 = Res.PathIcon.dashboard20,
    ),
    PRODUCT(
        title=Strings.product,
        iconPath=Res.PathIcon.production,
        iconPath20 = Res.PathIcon.product20
    ),
    TEMPLATE(
        title=Strings.template,
        iconPath=Res.PathIcon.template,
        iconPath20 = Res.PathIcon.product20,
    ),
    USER(
        title=Strings.user,
        iconPath=Res.PathIcon.user,
        iconPath20 = Res.PathIcon.user20,
    ),
    MEDIA_CENTER(
        title = Strings.mediaCenter,
        iconPath =Res.PathIcon.mediaCenter,
        iconPath20 = Res.PathIcon.mediaCenter20
    ),
    CUSTOMER_CARD(
        title=Strings.customerCard,
        iconPath=Res.PathIcon.customerCard,
        iconPath20 = Res.PathIcon.customerCard20
    ),
    CLOSING_REPORTS(
        title=Strings.closingReports,
        iconPath=Res.PathIcon.closingReport,
        iconPath20 = Res.PathIcon.closing20,
    ),
    STATISTIC(
        title = Strings.statistic,
        iconPath = Res.PathIcon.statistic,
        iconPath20 = Res.PathIcon.statistic20
    ),
    OPTIONS(
        title=Strings.options,
        iconPath=Res.PathIcon.options,
        iconPath20 = Res.PathIcon.option20,
    ),
}