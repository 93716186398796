package org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general

import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.UpdateListOfCategoriesPositionsRepository

class UpdateListOfCategoriesPositionsUseCase
    (private val repository: UpdateListOfCategoriesPositionsRepository) {
    suspend operator fun invoke(
        dataValue: List<Int>,
        dataTitle: String,
        idsList: List<String>
    ) {
        return repository.updateCategoriesPositions(
            dataValue=dataValue,
            dataTitle=dataTitle,
            idsList=idsList,
        )
    }
}
