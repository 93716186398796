package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme

@Composable
fun Separator(modifier: Modifier=Modifier) {
    Box(
        modifier = Modifier.fillMaxWidth()
            .height(Dimen.smallThickness)
            .backgroundColor(Theme.GrayCD.rgb)
    )
}