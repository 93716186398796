package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config.ListShopItem
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.pages.components.CalendarBox
import org.hs_soft.runmynesto.pages.components.DropdownComponent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun DashboardFilter(
    selectedDate: MonthModel,
    windowSizeMode: WindowSizeModeEnum,
    listShop: List<ListShopItem?>?,
    filterBasedOnRegion: (String?) -> Unit,
    setCalendarStatus: (Boolean) -> Unit,
)
{
    if (windowSizeMode==WindowSizeModeEnum.PHONE){
        Column(
            modifier = Modifier
                .margin(topBottom = Dimen.mediumPadding)
                .fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally
        )
        {
            DashboardFilterContent(
                windowSizeMode=windowSizeMode,
                listShop= listShop,
                selectedDate=selectedDate,
                setCalendarStatus={
                    setCalendarStatus(it)
                },
                filterBasedOnRegion = {
                    filterBasedOnRegion(it)
                }
            )


        }
    }else{
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            DashboardFilterContent(
                windowSizeMode=windowSizeMode,
                selectedDate=selectedDate,
                setCalendarStatus={
                    setCalendarStatus(it)
                },
                filterBasedOnRegion = {
                    filterBasedOnRegion(it)
                },
                listShop = listShop
            )


        }
    }


}

@Composable
private fun DashboardFilterContent(
    windowSizeMode: WindowSizeModeEnum,
    selectedDate: MonthModel,
    listShop: List<ListShopItem?>?,
    setCalendarStatus: (Boolean) -> Unit,
    filterBasedOnRegion: (String?) -> Unit,
) {
    DropdownComponent(
        modifier = Modifier
            .margin(
                bottom = if (windowSizeMode!=WindowSizeModeEnum.PHONE) Dimen.normalPadding
                else Dimen.mediumPadding
            ),
        options = listShop ?: listOf(),
        itemWidth =if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
            Dimen.semiLargeEditTextWidth else Dimen.dashboardReportItemTabletPhoneWidth,
        label = Strings.region,
        selectedItem = listShop?.last(),
        content = {
            if (it is ListShopItem)
                Text(it.name ?: "")
        },
        onOptionClick = {
            val option = listShop?.find { item ->
                item.toString() == it
            }
            filterBasedOnRegion(option?.id)
        }
    )

    CalendarBox(
        modifier = Modifier.margin(
            left = if (windowSizeMode != WindowSizeModeEnum.PHONE)
                Dimen.mediumPadding else 0.px,
            top = if (windowSizeMode!=WindowSizeModeEnum.PHONE) 0.px
            else Dimen.mediumPadding,
        ),
        selectedDate =selectedDate,
        label = Strings.date,
        width = if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
            Dimen.semiLargeEditTextWidth else Dimen.dashboardReportItemTabletPhoneWidth,
        onClick = {
            setCalendarStatus(true)
        }
    )

}

