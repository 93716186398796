package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.visibility
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Constants
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span

@Composable
fun LoadingProgress(
    modifier: Modifier = Modifier,
    visibility: Boolean = false,
    loadingTitle:String="",
    backgroundColor: CSSColorValue = Theme.Transparent.rgb,
) {

    Column(
        modifier = modifier
            .zIndex(Constants.ZIndex.semiLargeZIndex)
            .fillMaxSize()
            .backgroundColor(color = backgroundColor)
            .visibility(if(visibility) Visibility.Visible else Visibility.Hidden),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    )
    {

        Box(contentAlignment = Alignment.Center) {
            Div(
                attrs = Modifier
                    .classNames("spinner-border","text-primary")
                    .toAttrs()
            ) {
                Span(attrs = Modifier
                    .classNames("visually-hidden")
                    .toAttrs()
                )

            }

        }

        SpanText(
            text = loadingTitle,
            modifier=Modifier
                .margin(top = Dimen.smallPadding)
                .fontWeight(FontWeight.Medium)
                .fontFamily(FontFamily.Montserrat)
                .color(Theme.DarkBlue.rgb)
                .fontSize(FontSizes.normalFontSize)
        )

    }

}


