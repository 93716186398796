package org.hs_soft.runmynesto.domain.usecase.user.tab_right

import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_rights.UserRightModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.tab_right.UpdateUserPermissionsRepository

class UpdateUserPermissionUseCase(
    private val repository: UpdateUserPermissionsRepository
) {
    suspend operator fun invoke(
        dataTitle: String,
        dataValue: dynamic,
        userId: String
    ): UserRightModel {
       return repository.updateUserPermissions(
           dataTitle = dataTitle,
           dataValue=dataValue,
           userId=userId,
        )
    }
}