package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart.component.chart.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent

@Composable
fun CompareCharts(
    showCompareChartDialog: () -> Unit,
    windowSizeMode: WindowSizeModeEnum,
)
{
    val isHovered= remember { mutableStateOf(false) }
    Column(
        modifier = Modifier
            .height(Dimen.normalOutlinedEditTextHeight)
            .margin(left = Dimen.normalPadding),
        horizontalAlignment = Alignment.End
    )
    {
        if (windowSizeMode==WindowSizeModeEnum.PHONE){
            Box(
                modifier = Modifier
                    .padding(Dimen.normalPadding)
                    .borderRadius(50.percent)
                    .position(Position.Relative)
                    .rippleEffect {
                        showCompareChartDialog()
                    }
            )
            {
                VectorIcon(
                    modifier = Modifier.align(Alignment.Center) ,
                    pathData = Res.PathIcon.compare,
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                    color = Theme.Blue.hex,
                )
            }


        }else{
            Row(
                modifier = Modifier
                    .onMouseOver { isHovered.value=true}
                    .onMouseLeave { isHovered.value=false }
                    .rippleEffect {
                        showCompareChartDialog()
                    }
                    .cursor(Cursor.Pointer)
                    .transitionBackground()
                    .backgroundColor(
                        if (isHovered.value) Theme.GrayAlpha08.rgb
                        else Theme.Transparent.rgb
                    )
                    .height(Dimen.normalOutlinedEditTextHeight)
                    .borderRadius(Dimen.lowBorderRadius)
                    .border(
                        width = Dimen.smallThickness,
                        style = LineStyle.Solid,
                        color = Theme.Blue.rgb
                    ),
                verticalAlignment = Alignment.CenterVertically
            )
            {

                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .fillMaxHeight()
                        .padding(
                            topBottom = Dimen.smallPadding,
                            leftRight = Dimen.mediumPadding
                        )
                )
                {
                    VectorIcon(
                        pathData = Res.PathIcon.compare,
                        width = Dimen.mediumIconSize,
                        height = Dimen.mediumIconSize,
                        color = Theme.Blue.hex,
                    )

                    SpanText(
                        text = Strings.compare.uppercase(),
                        modifier = Modifier
                            .fontFamily(FontFamily.Montserrat)
                            .color(Theme.Blue.rgb)
                            .fontSize(FontSizes.mediumFontSize)
                            .fontWeight(FontWeight.Medium)
                    )

                }



                Box(
                    modifier = Modifier
                        .width(Dimen.smallThickness)
                        .fillMaxHeight()
                        .backgroundColor(Theme.Blue.rgb)
                )

                VectorIcon(
                    modifier = Modifier
                        .margin(leftRight = Dimen.smallPadding),
                    pathData = Res.PathIcon.arrowBelow,
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                    color = Theme.Blue.hex,
                )


            }

        }







    }

}
