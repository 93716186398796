package org.hs_soft.runmynesto.pages.home.sub_page.options.mvi

import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.OptionInfoHeaders
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.CashRegisterConfigHeaders
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenXX
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenXXX

sealed class OptionsEvent
{
    data class OnSelectBranch(
        val branch:ChildrenXXX?
    ):OptionsEvent()

    data class OnSelectRegion(
        val region:ChildrenXX?
    ):OptionsEvent()

    data class OnSelectOffice(
        val office: ChildrenX?
    ):OptionsEvent()

    data class OnColorBoxClick(
        val selectedColorField:String
    ):OptionsEvent()
    data class OnPalletColorClick(
        val hexColor:String,
    ):OptionsEvent()



    data object GetPriceLevels: OptionsEvent()


    data class SetColorPalletStatus(val status:Boolean):OptionsEvent()

    data object GetVirtualCardInfo:OptionsEvent()

    data class OnCashRegisterConfigTabClick(
        val tab:CashRegisterConfigHeaders):OptionsEvent()

    data object OnPreviewGoogleWalletClick:OptionsEvent()

    data object OnPreviewAppleWalletClick:OptionsEvent()

    data object OnCloseAppleWallet:OptionsEvent()

    data object OnCloseGoogleWallet:OptionsEvent()

    data class UpdateCashRegisterConfigData(
        val title:String,
        val value:dynamic,
        val valueIsInParameters:Boolean ?=null,
    ):OptionsEvent()

    data class OnCashRegisterConfigItemClick(val id:String):OptionsEvent()

    data object GetCashRegisterConfigData :OptionsEvent()

    data object GetCheckoutData: OptionsEvent()

    data class OnSearchCheckoutItems(val value:String): OptionsEvent()

    data class UpdateAppConfig(
        val title:String,
        val value:List<String?>,
    ):OptionsEvent()

    data object GetAppConfig:OptionsEvent()

    data class UpdateVirtualCardInfo(
        val dataTitle:String,
        val dataValue:dynamic,
    ):OptionsEvent()

    data object OnLoadingOperationData:OptionsEvent()

    data class UpdateOptionData(
        val dataTitle:String,
        val dataValue:dynamic
    ): OptionsEvent()

    data class SaveCompanyProfileId(val id:String):
            OptionsEvent()

    data class OnTopTabClick(val tab: OptionInfoHeaders)
        :OptionsEvent()

    data object GetProductSalesGroup:OptionsEvent()

    data class OnSearchSaleGroupInputChange(val value:String):OptionsEvent()


    data object OnSaleGroupTitleSortClick:OptionsEvent()

    data object OnSaleGroupNumberSortClick:OptionsEvent()

    data class OnSaleGroupItemClick(
        val id:String,
        val callBack:()->Unit,
    ): OptionsEvent()

    data object OnChangePageClick:OptionsEvent()

    data class UpdateSaleGroup(
        val dataTitle: String,
        val dataValue:String,
    ):OptionsEvent()


    data object OnAddRecordIconClick: OptionsEvent()
    data object OnAddRecordDialogBackgroundClick: OptionsEvent()
    data object OnCancelRecordDialogClick: OptionsEvent()
    data class OnSubmitAddSaleGroupRecordDialogClick
        (val windowMode: WindowSizeModeEnum): OptionsEvent()
    data class OnChangeRecordNumber(val value:String): OptionsEvent()
    data class OnChangeRecordName(
        val value:String?,
        val isNumberOptional:Boolean=false,
    ): OptionsEvent()


    data class OnSubmitAddKitchenLocationRecordDialogClick(
        val location:String
    ): OptionsEvent()

    data class OnSubmitUpdateKitchenLocationRecordDialogClick(
        val location:String?,
    ): OptionsEvent()



    data object RefreshSaleGroupList:OptionsEvent()

    data object GetPrintersLocation:OptionsEvent()

    data object ShowAddPrinterLocationRecordDialog:OptionsEvent()

    data class ShowUpdatePrinterLocationRecordDialog(
        val status:Boolean,
        val dbIndex:Int?,
        val name:String?
    ):OptionsEvent()



}
