package org.hs_soft.runmynesto.domain.usecase.home

import org.hs_soft.runmynesto.domain.model.home.home.user_profile_info.UserProfileDataModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.home.UserProfileDataRepository


class UserProfileDataUseCase(
    private val repository: UserProfileDataRepository
) {
    suspend operator fun invoke(): UserProfileDataModel {
        return repository.getUserProfileData()
    }
}