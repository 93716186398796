package org.hs_soft.runmynesto.domain.usecase.options.sales_group

import org.hs_soft.runmynesto.domain.model.home.options.single_sale_group.SingleSaleGroupModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.sales_group.AddNewSaleGroupRepository


class AddNewSaleGroupUseCase
    (private val repository: AddNewSaleGroupRepository) {
    suspend operator fun invoke(
        name:String,
        accountNumber:String,
    ): SingleSaleGroupModel {
        return repository.addNewSaleGroup(
            name=name,
            accountNumber= accountNumber,
        )
    }
}