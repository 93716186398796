package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.devices

import org.hs_soft.runmynesto.domain.config.Strings

enum class CafeModeEnum(
    val title:String,
    val value:Int,
) {
    REDUCED_VAT(
        title = Strings.reducedVat,
        value = 0
    ),
    VAT_RATE_SELECTION(
        title = Strings.vatRateSelection,
        value = 1
    ),
    NORMAL_VAT(
        title = Strings.normalVat,
        value = 2
    ),

}