package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.bill_search.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.BillSearchDetailTableHeadersEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data.BillSearchStatisticDataItem
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data.Item
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.hs_soft.runmynesto.pages.components.InfiniteScrollGrid
import org.hs_soft.runmynesto.pages.components.TableItemRow
import org.hs_soft.runmynesto.pages.components.grid_function.GridFooter
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun SelectedBillSearchItemDetailTable(
    windowSizeMode: WindowSizeModeEnum,
    selectedBillSearchItem: BillSearchStatisticDataItem?,
)
{
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb
            )
            .borderRadius(Dimen.lowBorderRadius)
    )
    {

        Column(
            modifier = HorizontalScrollBehavior.toModifier()
                .fillMaxWidth()
        )
        {
            BillSearchTableHeaders(
                windowSizeMode=windowSizeMode,
            )


            Column(
                modifier = VerticalScrollBehavior
                    .toModifier()
                    .fillMaxWidth()
                    .thenIf(
                windowSizeMode!= WindowSizeModeEnum.Desktop,
                        VerticalScrollBehavior
                            .toModifier().width(Dimen.billSearchPhoneTabletWidth)
                    )
                    .height(Dimen.closingReportsTableHeight)
            ){
                if (!selectedBillSearchItem?.items.isNullOrEmpty()){
                    InfiniteScrollGrid(
                        items = selectedBillSearchItem?.items?: listOf(),
                        onLoadMore = {}
                    ){
                        val model= selectedBillSearchItem?.items?.get(it)

                        if (model != null) {
                            OneOneTableItem(
                                model = model,
                                selectedBillSearchItem=selectedBillSearchItem,
                                isSelected= selectedBillSearchItem.id==model.id,
                                onItemClick = {id->

                                }
                            )
                        }
                    }
                }
            }

        }


        Spacer()

        GridFooter(
            title = Strings.items,
            width = null,
            showRefreshIcon = false,
            dataSize =selectedBillSearchItem?.items?.size?:0,
            onRefreshClick = {

            }
        )





    }

}

@Composable
private fun BillSearchTableHeaders(
    windowSizeMode: WindowSizeModeEnum
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .thenIf(
                windowSizeMode!= WindowSizeModeEnum.Desktop,
                Modifier.width(Dimen.billSearchPhoneTabletWidth)
            )
            .backgroundColor(Theme.GrayAlpha04.rgb)
            .padding(
                leftRight = Dimen.normalPadding,
                topBottom = Dimen.smallPadding,
            )
            .borderBottom(
                width = Dimen.smallThickness,
                color = Theme.GrayCD.rgb,
                style = LineStyle.Solid,
            )
    )
    {
        BillSearchDetailTableHeadersEnum.entries.forEach {
            HeaderTitle(
                title = it.title,
                fillMaxWidth = true,
                arrangement = it.arrangement
            )
        }

    }
}


@Composable
fun OneOneTableItem(
    model: Item,
    isSelected: Boolean,
    selectedBillSearchItem: BillSearchStatisticDataItem,
    onItemClick: (String) -> Unit,
)
{
    TableItemRow(
        id = model.id?:"",
        isSelected =isSelected,
        onItemClick = {
            if (selectedBillSearchItem.id!=it)
                onItemClick(it)
        }
    ){
        HeaderTitle(
            title = formatToTwoDecimals(model.amount),
            fillMaxWidth = true,
            arrangement = BillSearchDetailTableHeadersEnum.AMOUNT.arrangement
        )
        HeaderTitle(
            title = model.product?.code?:"",
            fillMaxWidth = true,
            arrangement = BillSearchDetailTableHeadersEnum.NUMBER.arrangement
        )
        HeaderTitle(
            title = model.product?.name?:"",
            fillMaxWidth = true,
            arrangement = BillSearchDetailTableHeadersEnum.NAME.arrangement
        )
        HeaderTitle(
            title = formatToTwoDecimals(model.price),
            fillMaxWidth = true,
            arrangement = BillSearchDetailTableHeadersEnum.PRICE.arrangement
        )
        HeaderTitle(
            title = formatToTwoDecimals(model.discountPercent),
            fillMaxWidth = true,
            arrangement = BillSearchDetailTableHeadersEnum.PERCENT.arrangement
        )
        HeaderTitle(
            title = model.codeMWSt,
            fillMaxWidth = true,
            arrangement = BillSearchDetailTableHeadersEnum.VAT.arrangement
        )

        HeaderTitle(
            title = if (model.mul1==0.0 || model.mul1==null)
                "" else model.mul1.toString(),
            fillMaxWidth = true,
            arrangement = BillSearchDetailTableHeadersEnum.IN_HOUSE.arrangement
        )

        HeaderTitle(
            title = formatToTwoDecimals(model.total),
            fillMaxWidth = true,
            arrangement = BillSearchDetailTableHeadersEnum.TOTAL.arrangement
        )
    }
}