package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_checkout.update_template_checkout


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateCategory(
    @SerialName("cashRegisterId")
    val cashRegisterId: List<String>?=null,
    @SerialName("id")
    val id: String?=null,
    @SerialName("mod_ver")
    val modVer: Int?=null,
    @SerialName("__typename")
    val typename: String?=null,
    @SerialName("priceLevel")
    val priceLevel:Int?=null,

)