package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component.mobile

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.overflowText
import org.hs_soft.runmynesto.domain.model.home.closing_reports.CurrencyTypeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.ArticleModel
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component.WaiterHoverBackground
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun MobileWaiterListTypeItem(
    model: ArticleModel,
    currencyType: CurrencyTypeEnum,
    showImgUrl: suspend (String) -> String,
    deleteGridProduct:()->Unit,
)
{
    val imageUrl= remember { mutableStateOf("") }

    LaunchedEffect(model.product) {
        model.product?.img?.key?.let { key ->
            imageUrl.value = showImgUrl(key)
        }
    }
    val isHovered= remember { mutableStateOf(false) }

    Box(
        modifier = Modifier
            .onMouseOver { isHovered.value=true }
            .onMouseLeave { isHovered.value=false }
            .margin(
                topBottom = Dimen.semiSemiNormalPadding,
            )

            .width(Dimen.mobileWaiterListTypeItemWidth)
            .height(Dimen.mobileWaiterListTypeItemHeight)
    )
    {
        Row(
            modifier = Modifier
                .fillMaxSize()
                .padding(leftRight = Dimen.semiMediumPadding )
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayCD.rgb
                )
                .borderRadius(Dimen.normalBorderRadius),
            verticalAlignment = Alignment.CenterVertically,
        )
        {
            if (model.product?.img!=null){
                Image(
                    src =imageUrl.value,
                    modifier = Modifier
                        .borderRadius(50.percent)
                        .objectFit(ObjectFit.Cover)
                        .size(Dimen.extraLargeIconSize.px)
                        .border(
                            width = Dimen.extraSmallThickness,
                            color = Theme.GrayCD.rgb,
                            style = LineStyle.Solid
                        )
                )
            }else{
                Box(
                    modifier = Modifier
                        .borderRadius(50.percent)
                        .size(Dimen.extraLargeIconSize.px)
                        .border(
                            width = Dimen.extraSmallThickness,
                            color = Theme.GrayCD.rgb,
                            style = LineStyle.Solid
                        )
                )
            }


            Column(
                modifier = Modifier
                    .margin(left = Dimen.mediumPadding)
            )
            {
                SpanText(
                    text =model.product?.name?:"" ,
                    modifier = Modifier
                        .fontFamily(FontFamily.Jakarta)
                        .fontSize(FontSizes.mediumFontSize)
                        .fontWeight(FontWeight.SemiBold)
                        .overflowText()
                        .color(Theme.Black43.rgb)

                )

                SpanText(
                    text =currencyType.title + " "+ formatToTwoDecimals(
                        model.product?.price?.filterNotNull()?.minOrNull() ?: 0.00
                    ) ,
                    modifier = Modifier
                        .fontFamily(FontFamily.Jakarta)
                        .fontSize(FontSizes.mediumFontSize)
                        .fontWeight(FontWeight.SemiBold)
                        .color(Theme.Green.rgb)

                )
            }


        }


        WaiterHoverBackground(
            isHovered = isHovered.value,
            onDeleteClick = {
                deleteGridProduct()
            }
        )
    }



}