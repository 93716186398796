package org.hs_soft.runmynesto.domain.usecase.home

import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.CheckoutModel
import org.hs_soft.runmynesto.domain.repository.local_datasource.home.GetSavedCheckOutRepository

class GetSavedCheckOutUseCase(
    private val repository: GetSavedCheckOutRepository
) {
    suspend operator fun invoke(): CheckoutModel? {
        return repository.getCashList()
    }
}