package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.disabled
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.dir
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onFocusIn
import com.varabyte.kobweb.compose.ui.modifiers.onFocusOut
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.transitionBorder
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.noBorder
import org.jetbrains.compose.web.attributes.DirType
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.CSSUnitLength
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.HTMLInputElement

@Composable
fun CustomEditText(
    modifier: Modifier = Modifier,
    width: CSSSizeValue<out CSSUnitLength> = Dimen.mediumEditTextWidth,
    height: CSSSizeValue<CSSUnit.px> = Dimen.mediumOutlinedEditTextHeight,
    marginBottom: CSSSizeValue<CSSUnit.px> = Dimen.mediumPadding,
    marginLeft: CSSSizeValue<CSSUnit.px> = 0.px,
    marginRight: CSSSizeValue<CSSUnit.px> = 0.px,
    marginTop: CSSSizeValue<CSSUnit.px> = 0.px,
    label: String = "",
    hasLabelSpace: Boolean = true,
    isEnabled: Boolean = true,
    textAlign: TextAlign = TextAlign.Start,
    value: String,
    isDecimal: Boolean = false,
    usingDotForDigit:Boolean=true,
    endIconPath: String? = null,
    startIconPath:String?=null,
    isRequired: Boolean = true,
    borderless: Boolean = false,
    shadowLess: Boolean = false,
    inputType: InputType<*> = InputType.Text,
    focusedBorderColor: CSSColorValue = Theme.Blue.rgb,
    defaultBorderColor: CSSColorValue = Theme.GrayCD.rgb,
    hint: String = "",
    isOutlinedEditText: Boolean = true,
    offsetX: CSSSizeValue<CSSUnit.px> = 0.px,
    offsetY: CSSSizeValue<CSSUnit.px> = 0.px,
    onFocusIn: () -> Unit,
    onFocusOut: (String) -> Unit,
    onEndIconClick: () -> Unit = {},
    onStartIconClick:()->Unit={},
    onInputChange: (String) -> Unit,
)
{
    val isTextChanged= remember { mutableStateOf(false) }
    val focused = remember { mutableStateOf(false) }
    val isValid = remember { mutableStateOf(true) }
    val inputValue = remember { mutableStateOf(value) }
    val isHovered = remember{ mutableStateOf(false) }



    LaunchedEffect(key1 = value) {
        inputValue.value = value
    }


    if(isOutlinedEditText){
        Box(modifier = modifier
            .margin(
                bottom =marginBottom,
                top = marginTop,
                left = marginLeft,
                right= marginRight,
            )
            .width(width)
            .height((height.value.toInt()+ Dimen.normalPadding.value.toInt()).px)
        )
        {
            SpanText(
                text = label,
                modifier = Modifier
                    .backgroundColor(Theme.White.rgb)
                    .zIndex(2f)
                    .align(Alignment.TopStart)
                    .color(Theme.GrayAlpha56.rgb)
                    .fontSize(FontSizes.normalFontSize)
                    .padding(leftRight = Dimen.smallPadding)
                    .margin(leftRight = Dimen.mediumPadding)
                    .fontFamily(FontFamily.Montserrat)
                    .transition(CSSTransition(property = "transform",
                        duration = Constants.editTextTransition))
                    .transition(CSSTransition(property = "opacity",
                        duration = Constants.editTextTransition))
            )

            editTextContent(
                modifier = Modifier.align(Alignment.BottomStart),
                shadowLess = shadowLess,
                focused = focused,
                focusedBorderColor = focusedBorderColor,
                offsetX = offsetX,
                usingDotForDigit=usingDotForDigit,
                offsetY =offsetY,
                width = width,
                height = height,
                borderless = borderless,
                isEnabled = isEnabled,
                isValid = isValid,
                defaultBorderColor = defaultBorderColor,
                inputType = inputType,
                onFocusIn = onFocusIn,
                isRequired = isRequired,
                inputValue = inputValue,
                isDecimal=isDecimal,
                onFocusOut = onFocusOut,
                onInputChange = onInputChange,
                hint = hint,
                endIconPath = endIconPath,
                onEndIconClick = onEndIconClick,
                startIconPath = startIconPath,
                onStartIconClick=onStartIconClick,
                isHovered = isHovered,
                textAlign=textAlign,
                isTextChanged = isTextChanged,
            )


        }


    }else{
        Column(modifier = modifier
            .margin(bottom =marginBottom)
        )
        {
            if (hasLabelSpace){
                EditTextLabel(label = label)

            }

            editTextContent(
                shadowLess = shadowLess,
                focused = focused,
                focusedBorderColor = focusedBorderColor,
                offsetX = offsetX,
                offsetY =offsetY,
                width = width,
                height = height,
                borderless = borderless,
                isEnabled = isEnabled,
                isValid = isValid,
                usingDotForDigit=usingDotForDigit,
                defaultBorderColor = defaultBorderColor,
                inputType = inputType,
                onFocusIn = onFocusIn,
                onFocusOut = onFocusOut,
                isRequired = isRequired,
                inputValue = inputValue,
                onInputChange = onInputChange,
                hint = hint,
                endIconPath = endIconPath,
                onEndIconClick = onEndIconClick,
                startIconPath = startIconPath,
                onStartIconClick=onStartIconClick,
                isHovered =isHovered,
                isTextChanged = isTextChanged,
                textAlign=textAlign,
                isDecimal = isDecimal,
            )


        }
    }




}

@Composable
private fun editTextContent(
    modifier: Modifier = Modifier,
    shadowLess: Boolean,
    focused: MutableState<Boolean>,
    focusedBorderColor: CSSColorValue,
    usingDotForDigit:Boolean,
    offsetX: CSSSizeValue<CSSUnit.px>,
    offsetY: CSSSizeValue<CSSUnit.px>,
    width: CSSSizeValue<out CSSUnitLength>,
    height: CSSSizeValue<CSSUnit.px>,
    borderless: Boolean,
    isEnabled: Boolean,
    isValid: MutableState<Boolean>,
    defaultBorderColor: CSSColorValue,
    inputType: InputType<*>,
    onFocusIn: () -> Unit,
    onFocusOut: (String) -> Unit,
    isRequired: Boolean,
    inputValue: MutableState<String>,
    onInputChange: (String) -> Unit,
    hint: String,
    endIconPath: String?,
    startIconPath:String?,
    onEndIconClick: () -> Unit,
    onStartIconClick:()->Unit,
    isHovered: MutableState<Boolean>,
    isTextChanged: MutableState<Boolean>,
    textAlign: TextAlign,
    isDecimal: Boolean,
) {
    Row(
        modifier = modifier
            .onMouseOver{ isHovered.value=true }
            .onMouseLeave { isHovered.value=false }
            .boxShadow(
                color =
                if (shadowLess) Theme.Transparent.rgb
                else if(isHovered.value) Theme.Primary.rgb
                else {
                    if (focused.value) focusedBorderColor
                    else Theme.GrayAlpha12.rgb
                },

                offsetX = offsetX,
                offsetY = offsetY,
            )
            .width(width)
            .height(height)
            .borderRadius(
                r = if (borderless) 0.px
                else Dimen.lowBorderRadius
            )
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = getRgbColorCondition(
                    isEnabled = isEnabled,
                    focused = focused.value,
                    isValid = isValid.value,
                    isHovered=isHovered.value,
                    focusedBorderColor = focusedBorderColor,
                    defaultBorderColor = defaultBorderColor
                )
            )
            .padding(left = Dimen.normalPadding, right = Dimen.normalPadding)
            .transition(CSSTransition(property = "border", duration = Constants.fastTransition)),
        verticalAlignment = Alignment.CenterVertically
    ) {

        if (startIconPath != null) {
            VectorIcon(
                modifier = Modifier .onClick {
                    onStartIconClick()
                },
                pathData = startIconPath,
                width = Dimen.mediumIconSize,
                height= Dimen.mediumIconSize,
                color = getHexColorCondition(
                    isEnabled = isEnabled,
                    focused = focused,
                    isValid = isValid
                )
            )
        }

        Input(
            type = inputType,
            attrs = Modifier
                .transitionBorder()
                .fillMaxSize()
                .color(if(isEnabled) Theme.Primary.rgb else Theme.GrayCD.rgb)
                .backgroundColor(Colors.Transparent)
                .noBorder()
                .dir(DirType.Ltr)
                .textAlign(textAlign)
                .fontWeight(FontWeight.Medium)
                .padding(
                    left = Dimen.normalPadding,
                    right = Dimen.normalPadding
                )
                .fontFamily(FontFamily.Montserrat)
                .fontSize(FontSizes.semiMediumFontSize)
                .onFocusIn {
                    focused.value = true
                    onFocusIn()
                }
                .onFocusOut {
                    focused.value = false
                    if (isRequired && inputValue.value.isEmpty())
                        isValid.value = false

                    if (isValid.value && isTextChanged.value) {
                        onFocusOut(inputValue.value)
                        isTextChanged.value=false
                    }

                }
                .toAttrs {
                    value(inputValue.value)
                    .onInput { event ->
                            val target = event.target as? HTMLInputElement
                            val decimalSeparator = if (usingDotForDigit) '.' else ','

                            val filteredInput = target?.value?.
                            filter { it.isDigit() || it == decimalSeparator }
                                ?.let {
                                    val count = it.count { ch -> ch == decimalSeparator }
                                    if (count > 1) {
                                        val lastIndex = it.lastIndexOf(decimalSeparator)
                                        it.substring(0, lastIndex) +
                                                it.substring(lastIndex + 1)
                                    } else it
                                } ?: ""

                            inputValue.value = if (isDecimal) {
                                filteredInput.ifEmpty { "" }
                            } else {
                                target?.value ?: ""
                            }

                            isTextChanged.value = true
                            onInputChange(inputValue.value)
                        }
                    //--------------------------------
                    attr("placeholder", hint)
                    if (!isEnabled) {
                        disabled(true)
                    }
                }
        )

        if (endIconPath != null) {
            VectorIcon(
                modifier = Modifier .onClick {
                    onEndIconClick()
                },
                pathData = endIconPath,
                width = Dimen.mediumIconSize,
                height= Dimen.mediumIconSize,
                color = getHexColorCondition(
                    isEnabled = isEnabled,
                    focused = focused,
                    isValid = isValid
                )
            )
        }


    }
}


@Composable
private fun EditTextLabel(label: String) {
    Box(
        modifier = Modifier
            .height(Dimen.labelHeight)
    )
    {
        SpanText(
            text = label,
            modifier = Modifier
                .color(Theme.Primary.rgb)
                .zIndex(1f)
                .align(Alignment.BottomStart)
                .fontWeight(FontWeight.Medium)
                .fontFamily(FontFamily.Montserrat)
                .fontSize(FontSizes.normalFontSize)
                .margin(bottom = Dimen.extraSmallPadding)
        )
    }
}


private fun telephoneValidation(inputValue: MutableState<String>):Boolean{
    return (inputValue.value.toIntOrNull() != null)
}


private fun NumberValidation(inputValue: MutableState<String>):Boolean{
    return (inputValue.value.toIntOrNull() != null)
}


private fun emailValidation(inputValue: MutableState<String>):Boolean{
    return inputValue.value.contains("@") &&
            inputValue.value.contains(".") &&
            inputValue.value.indexOf('@') <
            inputValue.value.indexOf('.') &&
            inputValue.value.indexOf('@') > 0 &&
            inputValue.value.indexOf('.') <
            inputValue.value.length - 1
}

private fun urlValidation(inputValue: MutableState<String>)
        = inputValue.value
    .matches(
        Regex(
            "^(http://www\\." +
                    "|https://www\\.|http://|https://)" +
                    "?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*" +
                    "\\.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?\$"
        )
    )


private fun getHexColorCondition(
    isEnabled: Boolean,
    focused: MutableState<Boolean>,
    isValid: MutableState<Boolean>
): String {

    return if (!isEnabled) Theme.GrayAlpha56.hex
    else {
        if (focused.value) Theme.Blue.hex
        else {
            if (isValid.value) Theme.GrayAlpha56.hex
            else Theme.Red.hex
        }
    }
}


private fun getRgbColorCondition(
    isEnabled: Boolean,
    focused: Boolean,
    isValid: Boolean,
    focusedBorderColor: CSSColorValue,
    defaultBorderColor: CSSColorValue,
    isHovered: Boolean,
): CSSColorValue {

    return if (!isEnabled) Theme.GrayCD.rgb
    else {
        if(isHovered) Theme.Primary.rgb
        else if (focused) focusedBorderColor
        else {
            if (isValid) defaultBorderColor
            else Theme.Red.rgb
        }
    }
}