
package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.periods

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail.LoyaltyCardDetail
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodMonthEnum
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.periods.component.PeriodDays
import org.jetbrains.compose.web.css.px

@Composable
fun Period(
    windowSizeMode: WindowSizeModeEnum,
    selectedPeriodType: PeriodDaysEnum?,
    selectedPeriodMonthType: PeriodMonthEnum?,
    onSelectDaysPeriodType: (PeriodDaysEnum) -> Unit,
    selectedLoyaltyCard: LoyaltyCardDetail?,
    filterCardItems:(PeriodDaysEnum?)->Unit,

)
{
    LaunchedEffect(selectedLoyaltyCard){
        onSelectDaysPeriodType(
            PeriodDaysEnum.TODAY
        )
    }
    Column {
        MediumContentTitle(
            title = Strings.period,
        )

        PeriodDays(
            windowSizeMode=windowSizeMode,
            selectedPeriodType=selectedPeriodType,
            onSelectPeriodType={
                onSelectDaysPeriodType(it)
            }
        )

//    PeriodsYear(
//        windowSizeMode = windowSizeMode
//    )
//
//    PeriodsMonth(
//        selectedPeriodType = selectedPeriodMonthType,
//        onSelectPeriodType = {
//
//        }
//    )
//
//    CustomDate(
//
//    )

        CustomButton(
            modifier = Modifier,
            windowSizeMode=windowSizeMode,
            topBottomMargin = Dimen.normalPadding,
            leftRightMargin = 0.px,
            title = Strings.calculation,
            shadowColor = Theme.GrayCD.rgb,
            defaultTextColor = Theme.Secondary.rgb,
            onButtonClick = {
                filterCardItems(selectedPeriodType)
            }

        )

    }


}



