package org.hs_soft.runmynesto.pages.components.top_toolbar

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.overflow
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.thenIf
import kotlinx.browser.window
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.home.hssoft_apps_list.HSWebApps
import org.hs_soft.runmynesto.domain.model.home.home.user_profile_info.GetUserProfileData
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.openInNewTab
import org.hs_soft.runmynesto.pages.components.dialog.dialog.ApplicationsDialog
import org.hs_soft.runmynesto.pages.components.dialog.dialog.ProfileBadgeDialog
import org.hs_soft.runmynesto.pages.components.top_toolbar.component.CarouselButton
import org.hs_soft.runmynesto.pages.components.top_toolbar.component.TopToolbarPageInfo
import org.hs_soft.runmynesto.pages.components.top_toolbar.component.TopToolbarUserInfo
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.overflow
import org.jetbrains.compose.web.css.px

@Composable
fun TopPageToolbar(
    modifier: Modifier = Modifier,
    profileInfo: GetUserProfileData?,
    windowSizeMode: WindowSizeModeEnum = WindowSizeModeEnum.Desktop,
    title: String,
    iconPath: String,
    showSettingDialog: Boolean,
    showSearchBarInMobileMode: Boolean,
    showApplications: Boolean,
    carouselVisibility: Boolean,
    isMenuExpandedHorizontally: Boolean,
    listOfHSSoftApps: List<HSWebApps?>?,
    onToolbarApplicationMenuIconClick: () -> Unit,
    changeShowingSearchBarInMobileStatus: () -> Unit,
    onSweepPage: () -> Unit,
    onToolbarSettingIconClick: () -> Unit,
    onLogoutClick: () -> Unit,
    closeMenu:()->Unit,
)
{
    Column(
        modifier=modifier
            .fillMaxWidth()
            .height(Dimen.topToolbarHeight)
            .zIndex(Constants.ZIndex.largeZIndex)
            .thenIf(
                windowSizeMode!=WindowSizeModeEnum.PHONE,
                Modifier.padding(
                    left =when(windowSizeMode) {
                        WindowSizeModeEnum.Desktop-> Dimen.normalPadding
                        WindowSizeModeEnum.TABLET->Dimen.extraExtraLargePadding
                        WindowSizeModeEnum.PHONE->Dimen.normalPadding
                    },
                    right = Dimen.largePadding ,
                )
            )
        ,
        horizontalAlignment = Alignment.CenterHorizontally,

    )
    {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .margin(
                    left = Dimen.extraLargePadding,
                    right = Dimen.largePadding
                )
                .height(Dimen.topToolbarHeight) ,
            verticalAlignment = Alignment.CenterVertically
        )
        {
            TopToolbarPageInfo(
                iconPath=iconPath,
                title=title,
                windowSizeMode=windowSizeMode,
                showSearchBarInMobileMode=showSearchBarInMobileMode,
            )

            Spacer()

            TopToolbarUserInfo(
                windowSizeMode =windowSizeMode,
                profileInfo = profileInfo,
                listOfHSSoftApps=listOfHSSoftApps,
                showApplications=showApplications,
                showSettingDialog=showSettingDialog,
                showSearchBarInMobileMode =showSearchBarInMobileMode,
                isMenuExpandedHorizontally = isMenuExpandedHorizontally,
                onToolbarApplicationMenuIconClick = {
                    onToolbarApplicationMenuIconClick()
                },
                onToolbarSettingIconClick ={
                    onToolbarSettingIconClick()
                },
                changeShowingSearchBarInMobileStatus = {
                    changeShowingSearchBarInMobileStatus()
                },
                closeMenu={
                    closeMenu()
                }
            )


            if(
                windowSizeMode== WindowSizeModeEnum.PHONE &&
                carouselVisibility
            ){
                CarouselButton(
                    onScrollButtonClick ={
                        onSweepPage()
                    }
                )
            }


        }


        Column(
            modifier=Modifier
                .margin(
                    top = Dimen.smallPadding
                )
                .fillMaxWidth()
            ,
            horizontalAlignment = Alignment.End
        ) {
            if (showSettingDialog){
                ProfileBadgeDialog(
                    onLogoutClick = {
                        onLogoutClick()
                    }
                )
            }

            if (showApplications && windowSizeMode==WindowSizeModeEnum.PHONE){

                ApplicationsDialog(
                    modifier = Modifier
                        .margin(
                            right = if (windowSizeMode!=WindowSizeModeEnum.PHONE)
                                Dimen.largePadding else 0.px,
                            top = if (windowSizeMode!=WindowSizeModeEnum.PHONE)
                                Dimen.normalPadding else 0.px,
                        ),
                    windowSizeMode=windowSizeMode,
                    listOfHSSoftApps=listOfHSSoftApps,
                    onItemClick = {
                        openInNewTab(it)
                    }
                )
            }

        }





    }
}






