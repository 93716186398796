package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.closing_reports.CurrencyTypeEnum
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.ListingLayoutsEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.WaiterDeviceTypeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.GetCategory
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.AddItem
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.DropDownItem
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component.ArticleItemsGridView
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component.ArticlePreviewForMWAndDW
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun ArticleList(
    modifier: Modifier = Modifier,
    windowSizeMode: WindowSizeModeEnum,
    selectedCategory: GetCategory?,
    titleDescending: Boolean,
    showTitleSortIcon: Boolean,
    currencyType: CurrencyTypeEnum,
    selectedDeviceType: WaiterDeviceTypeEnum,
    selectedLayoutType: ListingLayoutsEnum,
    onAddProductIconClick: () -> Unit,
    onArticleItemClick: (String) -> Unit,
    articleList: List<ProductItem>?,
    onRefreshClick: () -> Unit,
    onSearchValueChange: (String) -> Unit,
    onTitleSortClick: () -> Unit,
    onDeviceTypeClick: (WaiterDeviceTypeEnum) -> Unit,
    onShowLayoutTypeClick: (ListingLayoutsEnum) -> Unit,
    showImgUrl: suspend (String) -> String,
    deleteGridProduct:(Int)->Unit,
    setGridProduct:(String,Int)->Unit,
    updateListAfterDragAndDrop:
        (List<dynamic>, List<String>, List<Int>, List<String>) -> Unit,
) {

    val productId= remember { mutableStateOf("") }


    Column(
        modifier = modifier
            .fillMaxWidth()
            .padding(bottom = Dimen.mediumPadding,)
    )
    {
        MediumContentTitle(
            title = Strings.articleList,
            marginTop = Dimen.mediumPadding,
        )

        Column(
            modifier = Modifier
                .height(Dimen.TemplateArticleListHeight)
                .fillMaxWidth(),
        )
        {
            if (windowSizeMode==WindowSizeModeEnum.Desktop){
                Row(
                    modifier = Modifier
                        .fillMaxWidth() ,
                    verticalAlignment = Alignment.CenterVertically,
                )
                {

                    PreviewTypesAndSearchInProducts(
                        windowSizeMode=windowSizeMode,
                        selectedDeviceType=selectedDeviceType,
                        selectedLayoutType=selectedLayoutType,
                        onDeviceTypeClick={
                            onDeviceTypeClick(it)
                        },
                        onShowLayoutTypeClick={
                            onShowLayoutTypeClick(it)
                        },
                        onAddProductIconClick={
                            onAddProductIconClick()
                        },
                        onSearchValueChange={
                            onSearchValueChange(it)
                        }
                    )


                }
            }else{

                Column(
                    modifier = Modifier
                        .fillMaxWidth() ,
                    horizontalAlignment = Alignment.Start,
                )
                {

                    PreviewTypesAndSearchInProducts(
                        windowSizeMode=windowSizeMode,
                        selectedDeviceType=selectedDeviceType,
                        selectedLayoutType=selectedLayoutType,
                        onDeviceTypeClick={
                            onDeviceTypeClick(it)
                        },
                        onShowLayoutTypeClick={
                            onShowLayoutTypeClick(it)
                        },
                        onAddProductIconClick={
                            onAddProductIconClick()
                        },
                        onSearchValueChange={
                            onSearchValueChange(it)
                        }
                    )


                }
            }

            if (windowSizeMode==WindowSizeModeEnum.Desktop)
            {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(Dimen.TemplateArticleListHeight)
                )
                {

                    ArticlePreviewForMWAndDW(
                        windowSizeMode=windowSizeMode,
                        productId=productId,
                        currencyType=currencyType,
                        selectedCategory=selectedCategory,
                        selectedDeviceType = selectedDeviceType,
                        selectedLayoutType=selectedLayoutType,
                        showImgUrl={key:String->
                            showImgUrl(key)
                        },
                        deleteGridProduct = {position:Int->
                            deleteGridProduct(
                                position
                            )
                        },
                        setGridProduct = {productId2:String,position:Int,->
                            setGridProduct(
                                productId2,position
                            )
                        }
                    )


                    ArticleItemsGridView(
                        showTitleSortIcon=showTitleSortIcon,
                        windowSizeMode=windowSizeMode,
                        articleList = articleList,
                        selectedArticles=selectedCategory?.gridProducts,
                        titleDescending=titleDescending,
                        onRefreshClick = {
                            onRefreshClick()
                        },
                        onArticleItemClick={
                            onArticleItemClick(it)
                        },
                        updateListAfterDragAndDrop={newList, idList, positionList, productIdList ->
                            updateListAfterDragAndDrop(
                                newList,idList,positionList,productIdList
                            )
                        },
                        onTitleSortClick = {
                            onTitleSortClick()
                        },
                        onDragEndProductItem = {
                            productId.value=it.id
                        }
                    )
                }

            }else{
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                )
                {
                    ArticleItemsGridView(
                        showTitleSortIcon=showTitleSortIcon,
                        windowSizeMode=windowSizeMode,
                        articleList = articleList,
                        selectedArticles=selectedCategory?.gridProducts,
                        titleDescending=titleDescending,
                        onRefreshClick = {
                            onRefreshClick()
                        },
                        onArticleItemClick={
                            onArticleItemClick(it)
                        },
                        updateListAfterDragAndDrop={newList, idList, positionList, productIdList ->
                            updateListAfterDragAndDrop(
                                newList,idList,positionList,productIdList
                            )
                        },
                        onTitleSortClick = {
                            onTitleSortClick()
                        },
                        onDragEndProductItem = {
                            productId.value=it.id
                        }
                    )

                    Box(
                      modifier=Modifier
                          .height(Dimen.mediumPadding)
                    )

                    ArticlePreviewForMWAndDW(
                        windowSizeMode = windowSizeMode,
                        productId=productId,
                        currencyType=currencyType,
                        selectedCategory=selectedCategory,
                        selectedDeviceType = selectedDeviceType,
                        selectedLayoutType=selectedLayoutType,
                        showImgUrl={key:String->
                            showImgUrl(key)
                        },
                        deleteGridProduct = {position:Int->
                            deleteGridProduct(
                                position
                            )
                        },
                        setGridProduct = {productId2:String,position:Int,->
                            setGridProduct(
                                productId2,position
                            )
                        }
                    )



                }
            }




        }


    }

}

@Composable
private fun PreviewTypesAndSearchInProducts(
    selectedDeviceType: WaiterDeviceTypeEnum,
    windowSizeMode: WindowSizeModeEnum,
    onDeviceTypeClick: (WaiterDeviceTypeEnum) -> Unit,
    selectedLayoutType: ListingLayoutsEnum,
    onShowLayoutTypeClick: (ListingLayoutsEnum) -> Unit,
    onAddProductIconClick: () -> Unit,
    onSearchValueChange: (String) -> Unit,
) {
    if (windowSizeMode==WindowSizeModeEnum.Desktop){
        Row(
            modifier = Modifier,
            verticalAlignment = Alignment.CenterVertically,
        )
        {
            DeviceAndListingTypeDropDowns(
                windowSizeMode = windowSizeMode,
                selectedDeviceType=selectedDeviceType,
                onDeviceTypeClick={
                    onDeviceTypeClick(it)
                },
                selectedLayoutType=selectedLayoutType,
                onShowLayoutTypeClick = {
                    onShowLayoutTypeClick(it)
                },
            )
        }
    }else{
        Column(
            modifier = Modifier,
            horizontalAlignment = Alignment.CenterHorizontally,
        )
        {
            DeviceAndListingTypeDropDowns(
                windowSizeMode=windowSizeMode,
                selectedDeviceType=selectedDeviceType,
                selectedLayoutType=selectedLayoutType,
                onDeviceTypeClick={
                    onDeviceTypeClick(it)
                },
                onShowLayoutTypeClick = {
                    onShowLayoutTypeClick(it)
                }
            )
        }
    }



    if (windowSizeMode==WindowSizeModeEnum.Desktop)
        Spacer()

    Row(
        modifier = Modifier
            .width(Dimen.semiLargeEditTextWidth)
            .thenIf(
                windowSizeMode!=WindowSizeModeEnum.Desktop,
                Modifier.fillMaxWidth()
            ),
        verticalAlignment = Alignment.CenterVertically,
    )
    {
        if (windowSizeMode!=WindowSizeModeEnum.Desktop)
            Spacer()

        Row(
            modifier = Modifier
                .width(Dimen.semiLargeEditTextWidth),
            verticalAlignment = Alignment.CenterVertically,
        )
        {
            AddItem(
                onAddClick = {
                    onAddProductIconClick()
                }
            )

            Spacer()

            CustomEditText(
                modifier = Modifier,
                isOutlinedEditText = false,
                label = "",
                value = "",
                width = Dimen.mediumEditTextWidth,
                endIconPath = Res.PathIcon.searchBox,
                isRequired = false,
                hint = Strings.search,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    onSearchValueChange(it)
                },
            )
        }



    }
}

@Composable
private fun DeviceAndListingTypeDropDowns(
    selectedDeviceType: WaiterDeviceTypeEnum,
    onDeviceTypeClick: (WaiterDeviceTypeEnum) -> Unit,
    selectedLayoutType: ListingLayoutsEnum,
    onShowLayoutTypeClick: (ListingLayoutsEnum) -> Unit,
    windowSizeMode: WindowSizeModeEnum
) {
    DropDownItem(
        modifier = Modifier
            .margin(
                bottom = Dimen.largePadding,
            ),
        options = WaiterDeviceTypeEnum.entries,
        label = Strings.device,
        selectedItem = selectedDeviceType,
        content = {
            if (it is WaiterDeviceTypeEnum)
                Text(it.title)
        },
        onOptionClick = {
            val option: WaiterDeviceTypeEnum? = WaiterDeviceTypeEnum.entries.find { item ->
                item.toString() == it
            }

            if (option != null) {
                onDeviceTypeClick(option)
            }
        },
    )



    DropDownItem(
        modifier = Modifier
            .margin(
                left =if (windowSizeMode==WindowSizeModeEnum.Desktop)
                    Dimen.mediumPadding else 0.px,
                bottom = Dimen.largePadding,
            ),
        options = ListingLayoutsEnum.entries,
        label = Strings.listingLayout,
        disable = selectedDeviceType == WaiterDeviceTypeEnum.DESKTOP_WAITER,
        selectedItem = selectedLayoutType,
        content = {
            if (it is ListingLayoutsEnum)
                Text(it.title)
        },
        onOptionClick = {
            val option: ListingLayoutsEnum? = ListingLayoutsEnum.entries.find { item ->
                item.toString() == it
            }

            if (option != null) {
                onShowLayoutTypeClick(option)
            }
        },
    )
}





