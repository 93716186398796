package org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill

import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import org.hs_soft.runmynesto.domain.config.Strings

enum class BillSearchDetailTableHeadersEnum(
    val title:String,
    val arrangement: Arrangement.Horizontal,
){
    AMOUNT(
        title = Strings.amount,
        arrangement = Arrangement.End,
    ),
    NUMBER(
        title = Strings.number ,
        arrangement = Arrangement.Start,
    ),
    NAME(
        title = Strings.name,
        arrangement = Arrangement.Start,
    ),
    PRICE(
        title = Strings.price ,
        arrangement = Arrangement.End,
    ),
    PERCENT(
        title = Strings.percentSign ,
        arrangement = Arrangement.Start,
    ),
    VAT(
        title = Strings.vat ,
        arrangement = Arrangement.Start,
    ),
    IN_HOUSE(
        title = Strings.inTheHouse ,
        arrangement = Arrangement.Start,
    ),
    TOTAL(
        title = Strings.total ,
        arrangement = Arrangement.End,
    ),
}