package org.hs_soft.runmynesto.data.api_datasource.authentication

import androidx.compose.runtime.NoLiveLiterals
import org.hs_soft.runmynesto.Auth
import org.hs_soft.runmynesto.domain.repository.network_datasource.home.SignOutRepository


class SignOutRepositoryImpl: SignOutRepository {

    @NoLiveLiterals
    override fun signOut(
        onSuccess: () -> Unit,
        onError: (String) -> Unit
    ) {
        Auth.signOut().then{
            console.log("SignOut successfully")
            onSuccess()
        }.catch{error->
            console.log("Sign out error: ${error}")
            onError(error)
        }

    }


}

