package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.restaurant_data


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("getProduct")
    val getProduct: GetRestaurant,
    @SerialName("__typename")
    val typename: String?=""
)