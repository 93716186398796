package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.color_pallet

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.dom.svg.Line
import com.varabyte.kobweb.compose.dom.svg.Svg
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.styleModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.gradientStyle
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.color_pallet.ColorPaletteEnum
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

@Composable
fun ColorPallet(
    onBackgroundClick:()->Unit,
    onColorClick:(String)->Unit,
)
{
    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                onBackgroundClick()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(2f)
    ){
        Column(
            modifier = Modifier
                .align(Alignment.Center)
                .onClick {
                    event: SyntheticMouseEvent -> event.stopPropagation()
                }
                .backgroundColor(Theme.White.rgb)
                .padding(Dimen.normalPadding)
                .borderRadius(r= Dimen.lowBorderRadius)
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayCD.rgb
                ),
            horizontalAlignment = Alignment.CenterHorizontally
        ){
            RectShapeColors(
                onRectColorClick = {
                    onColorClick(it)
                }
            )



        }
    }



}

@Composable
private fun CustomColor(
    selectedColor:String?,
    onGradientColorClick:(String)->Unit,
) {
    val rectHeightStr=(Dimen.customColorBoxHeight.value.toInt()
            - Dimen.lowBorderRadius.value.toInt()/2).toString()
    val rectWidthStr=(Dimen.customColorBoxWidth.value.toInt()-
            Dimen.lowBorderRadius.value.toInt()/2).toString()

    Row(
        modifier = Modifier.margin(top = Dimen.normalPadding)
    )
    {
        Box(
            modifier = Modifier
                .margin(right = Dimen.normalPadding)
                .width(Dimen.customColorBoxWidth)
                .height(Dimen.customColorBoxHeight)
                .backgroundColor(Color(selectedColor?: Theme.Transparent.hex))
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color=if (selectedColor== Theme.Transparent.hex) Theme.Red.rgb
                        else Theme.Gray3E.rgb,
                )
        ){
            if (selectedColor== Theme.Transparent.hex){
                Div {
                    Svg(
                        attrs = {
                            style {
                                height(Dimen.customColorBoxHeight)
                                width(Dimen.customColorBoxWidth)
                            }
                        }
                    ) {

                        Line(
                            attrs = {
                                attr("x1", "0")
                                attr("y1", rectHeightStr)
                                attr("x2", rectWidthStr)
                                attr("y2", "0")
                                attr("stroke", "red")
                                attr("stroke-width", "1")
                            }
                        )
                    }
                }
            }
        }


        Spacer()

        Box(
            modifier = Modifier
                .width(Dimen.customChooseColorBoxWidth)
                .height(Dimen.customColorBoxHeight)
                .styleModifier {
                    backgroundImage(gradientStyle)
                }
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color= Theme.Gray3E.rgb
                )
                .attrsModifier {
                    attr("id", "colorPickerBox")
                }
                .onClick { event ->

                }
        )


    }
}

@Composable
private fun RectShapeColors(
    onRectColorClick: (String) -> Unit
)
{
    ColorPaletteEnum.entries
        .windowed(size = 7, step = 7)
        .forEach {
            Row{
                it.forEach {
                    CircleColor(
                        hexColor = it.hexCode,
                        onRectColorClick={
                            onRectColorClick(it)
                        }
                    )
                }
            }
        }
}

@Composable
private fun CircleColor(
    hexColor:String,
    onRectColorClick:(String)->Unit,
)
{
    val rectSizeStr=(Dimen.rectColor.value.toInt()-
            Dimen.lowBorderRadius.value.toInt()/2).toString()
    Box(
        modifier = Modifier
            .margin(Dimen.smallPadding)
            .onClick { onRectColorClick(hexColor) }
            .size(Dimen.rectColor)
            .border(
                color = if (hexColor== Theme.Transparent.hex) Theme.Red.rgb
                       else Theme.Gray3E.rgb,
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
            )
            .borderRadius(50.percent)
            .backgroundColor(Color(hexColor))
    ){
        if (hexColor== Theme.Transparent.hex){
            Div {
                Svg(
                    attrs = {
                        style {
                            height(Dimen.rectColor)
                            width(Dimen.rectColor)
                        }
                    }
                ) {

                    Line(
                        attrs = {
                            attr("x1", "1")
                            attr("y1", rectSizeStr)
                            attr("x2", rectSizeStr)
                            attr("y2", "1")
                            attr("stroke", "red")
                            attr("stroke-width", "1")
                        }
                    )
                }
            }
        }

    }




}

