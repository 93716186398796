package org.hs_soft.runmynesto.data.api_datasource.statistic.tabs.bill_search

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data.StatisticDataModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.search_bill.BillSearchStatisticDataRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class BillSearchStatisticDataRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
):BillSearchStatisticDataRepository{

    override suspend fun getStatisticData(requestId: String): StatisticDataModel {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """
                     query getStatisticsData(${'$'}${ApiUtils.Field.requestId}: ID!) {
                        cashAssist {
                            getStatisticsData(requestId: ${'$'}${ApiUtils.Field.requestId}) {
                                ${ApiUtils.Field.status}
                                ${ApiUtils.Field.progress}
                                ${ApiUtils.Field.data} {
                                    ... on StatisticBill {
                                        ${ApiUtils.Field.id}
                                        ${ApiUtils.Field.cardName}
                                        ${ApiUtils.Field.cardNumber}
                                        ${ApiUtils.Field.groupId}
                                        ${ApiUtils.Field.number}
                                        ${ApiUtils.Field.dateTime}
                                        ${ApiUtils.Field.total}
                                        ${ApiUtils.Field.isCancelled}
                                        ${ApiUtils.Field.cashRegisterName}
                                        ${ApiUtils.Field.userName}
                                        ${ApiUtils.Field.cancellationUserName}
                                        ${ApiUtils.Field.loyaltycardId}
                                        ${ApiUtils.Field.timer}
                                        ${ApiUtils.Field.cancellationUserId}
                                        ${ApiUtils.Field.cashRegisterId}
                                        ${ApiUtils.Field.totalWithoutCancellation}
                                        ${ApiUtils.Field.items} {
                                            ${ApiUtils.Field.id}
                                            ${ApiUtils.Field.price}
                                            ${ApiUtils.Field.total}
                                            ${ApiUtils.Field.amount}
                                            ${ApiUtils.Field.codeMWSt}
                                            ${ApiUtils.Field.discountPercent}
                                            ${ApiUtils.Field.flag}
                                            ${ApiUtils.Field.mul1}
                                            ${ApiUtils.Field.product} {
                                                ${ApiUtils.Field.name}
                                                ${ApiUtils.Field.code}
                                                ${ApiUtils.Field.typename}
                                            }
                                            ${ApiUtils.Field.inCafe}
                                            ${ApiUtils.Field.typename}
                                        }
                                        ${ApiUtils.Field.typename}
                                    }
                                    ${ApiUtils.Field.typename}
                                }
                                ${ApiUtils.Field.typename}
                            }
                            ${ApiUtils.Field.typename}
                        }
                    }

                """.trimIndent()
            )
            putJsonObject("variables") {
                put("requestId",requestId)

            }


        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }


}