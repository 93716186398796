package org.hs_soft.runmynesto.di

import org.hs_soft.runmynesto.di.closing_reports.closingReportsRepositoryModule
import org.hs_soft.runmynesto.di.closing_reports.closingReportsUseCaseModule
import org.hs_soft.runmynesto.di.customer_card.customerCardRepositoryModule
import org.hs_soft.runmynesto.di.customer_card.customerCardUseCaseModule
import org.hs_soft.runmynesto.di.dashboard.dashBoardRepositoryModule
import org.hs_soft.runmynesto.di.dashboard.dashBoardUseCaseModule
import org.hs_soft.runmynesto.di.home.homeRepositoryModule
import org.hs_soft.runmynesto.di.home.homeUseCaseModule
import org.hs_soft.runmynesto.di.login.loginRepositoryModule
import org.hs_soft.runmynesto.di.login.loginUseCaseModule
import org.hs_soft.runmynesto.di.options.optionRepositoryModule
import org.hs_soft.runmynesto.di.options.optionsUseCaseModule
import org.hs_soft.runmynesto.di.product.productRepositoryModule
import org.hs_soft.runmynesto.di.product.productUseCaseModule
import org.hs_soft.runmynesto.di.statistic.statisticRepositoryModule
import org.hs_soft.runmynesto.di.statistic.statisticUseCaseModule
import org.hs_soft.runmynesto.di.template.templateRepositoryModule
import org.hs_soft.runmynesto.di.template.templateUseCaseModule
import org.hs_soft.runmynesto.di.user.userRepositoryModule
import org.hs_soft.runmynesto.di.user.userUseCaseModule

val appModules= listOf(
    networkModule,
    viewModelModule,
    templateRepositoryModule,
    templateUseCaseModule,
    userRepositoryModule,
    userUseCaseModule,
    productRepositoryModule,
    productUseCaseModule,
    homeRepositoryModule,
    homeUseCaseModule,
    loginRepositoryModule,
    loginUseCaseModule,
    customerCardRepositoryModule,
    customerCardUseCaseModule,
    closingReportsUseCaseModule,
    closingReportsRepositoryModule,
    statisticRepositoryModule,
    statisticUseCaseModule,
    optionRepositoryModule,
    optionsUseCaseModule,
    dashBoardRepositoryModule,
    dashBoardUseCaseModule,

)