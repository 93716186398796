package org.hs_soft.runmynesto.pages.components.menu

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSColorValue
import org.hs_soft.runmynesto.pages.components.MenuVectorIcon
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.jetbrains.compose.web.css.px

@Composable
fun MenuTopBar(
    applicationIcon: String,
    titlePart1: String,
    titlePart2: String,
    isExpanded: Boolean = false,
    onMenuClick: () -> Unit,
    backgroundColor: CSSColorValue,
    windowSizeMode: WindowSizeModeEnum,
)
{
    Column(
        modifier = Modifier.fillMaxWidth()
    )
    {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .backgroundColor(backgroundColor)
            ,
            verticalAlignment =Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Start
        )
        {

            Box(
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .onClick {
                        onMenuClick()
                    }
                    .padding(all = Dimen.mediumPadding))
            {
                MenuVectorIcon(
                    modifier = Modifier,
                    pathData = Res.PathIcon.menuFilled,
                    color = Theme.GrayAlpha56.hex
                )
            }



            Row(
                modifier = Modifier
                    .attrsModifier {
                        style {
                            property("opacity", if (isExpanded) "1" else "0")
                            property("overflow", "hidden")
                            property("transition",
                                "width ${Constants.slowExpandingTime}s ease," +
                                        " opacity ${Constants.slowExpandingTime}s ease")
                            property("width", if (isExpanded) "100%" else "0")
                        }
                    }
                ,
                verticalAlignment = Alignment.CenterVertically
            )
            {

                Image(
                    src = applicationIcon,
                    modifier = Modifier
                        .size(Dimen.largeIconSize.px)
                )

                SpanText(
                    modifier = Modifier
                        .margin(left = Dimen.smallPadding)
                        .fontFamily(FontFamily.Montserrat)
                        .fontSize(FontSizes.mediumFontSize)
                        .lineHeight(FontLineHeight.lineNormal)
                        .fontWeight(FontWeight.SemiBold)
                        .color(Theme.Primary.rgb),
                    text =titlePart1
                )
                SpanText(
                    modifier = Modifier
                        .fontFamily(FontFamily.Montserrat)
                        .fontSize(FontSizes.mediumFontSize)
                        .lineHeight(FontLineHeight.lineNormal)
                        .fontWeight(FontWeight.Normal)
                        .color(Theme.Primary.rgb),
                    text =titlePart2
                )


            }

        }

        if (windowSizeMode!= WindowSizeModeEnum.WINDOWS){
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(Dimen.smallThickness)
                    .backgroundColor(Theme.GrayCD.rgb)
            )
        }
    }

}