package org.hs_soft.runmynesto.pages.home.sub_page.user.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onTouchEnd
import com.varabyte.kobweb.compose.ui.modifiers.onTouchMove
import com.varabyte.kobweb.compose.ui.modifiers.onTouchStart
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.TemplateInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.user.UserCancellationReasonEnum
import org.hs_soft.runmynesto.domain.model.home.user.UserInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.user.single_user.GetUser
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.TabItem
import org.hs_soft.runmynesto.pages.components.TopTabItems
import org.hs_soft.runmynesto.pages.components.checkout.CheckoutTabContent
import org.hs_soft.runmynesto.pages.home.sub_page.user.component.tabs.rights_tab.UserRightsTabContent
import org.hs_soft.runmynesto.pages.home.sub_page.user.component.tabs.general_tab.UserTabContent
import org.w3c.dom.get

@Composable
fun UserContent(
    windowSizeMode: WindowSizeModeEnum,
    checkOutData: List<Children>?,
    userInfoDetailPasswordVisibility: Boolean,
    imgUrl: String,
    selectedUser: GetUser?,
    loading: Boolean,
    selectedTab: UserInfoHeaderEnum,
    selectedCancellationReason: UserCancellationReasonEnum,
    isCheckoutExpandAll:Boolean,
    checkoutSearchValue:String,
    onCheckoutSearch:(String)->Unit,
    onSelectImage: (String) -> Unit,
    onUserInfoDetailPassEyeClick: () -> Unit,
    onTabClick: (UserInfoHeaderEnum) -> Unit,
    updateNameValue: (String) -> Unit,
    updatePrintNameValue: (String) -> Unit,
    updateShortNameValue: (String) -> Unit,
    updatePasswordValue: (String) -> Unit,
    updateDayEndCheck: (Boolean) -> Unit,
    updateActivity: (Boolean) -> Unit,
    updateTimeTracking: (Boolean) -> Unit,
    updateIsAdmin: (Boolean) -> Unit,
    updateIsViewSale: (Boolean) -> Unit,
    updateIsManager: (Boolean) -> Unit,
    updateInterimFinancialStatement: (Boolean) -> Unit,
    updateWaiterCastle: (String) -> Unit,
    onSwipeLeft: () -> Unit,
    onCancellationReasonClick: (dynamic) -> Unit,
    updateUserCheckout:(MutableSet<String>)->Unit,
) {
    var startX by remember { mutableStateOf(0.0) }
    var endX by remember { mutableStateOf(0.0) }
    var tracking by remember { mutableStateOf(false) }

    Column(
        modifier = Modifier
            .margin(left = Dimen.normalPadding)
            .fillMaxSize()
            .onTouchStart { event ->
                startX = event.touches[0]!!.clientX.toDouble()
                tracking = true
            }
            .onTouchMove { event ->
                if (tracking) {
                    endX = event.touches[0]!!.clientX.toDouble()
                }
            }
            .onTouchEnd {
                if (tracking && startX + Constants.Responsive.SwipeWidth < endX) {
//                    onSwipeLeft()//TODO(Must be fixed)
                }
                tracking = false
            }
    )
    {
        SpanText(
            text=selectedUser?.name?:"",
            modifier = Modifier
                .minHeight(Dimen.minimumContentTitleHeight)
                .fontSize(FontSizes.extraLargeFontSize)
                .fontWeight(FontWeight.Normal)
                .lineHeight(FontLineHeight.lineLarge)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Montserrat)
        )
        TopTabItems(
            windowSizeMode=windowSizeMode,
            selectedTab=selectedTab,
            tabItems = UserInfoHeaderEnum.entries,
            onTabClick={
                onTabClick(it)
            }
        )

        when(selectedTab)
        {
            UserInfoHeaderEnum.GENERAL ->{
                UserTabContent(
                    windowSizeMode = windowSizeMode,
                    selectedUser=selectedUser,
                    userInfoDetailPasswordVisibility=userInfoDetailPasswordVisibility,
                    onUserInfoDetailPassEyeClick ={onUserInfoDetailPassEyeClick()} ,
                    updateNameValue={updateNameValue(it)},
                    updatePrintNameValue={updatePrintNameValue(it)},
                    updateShortNameValue={updateShortNameValue(it)},
                    updatePasswordValue={updatePasswordValue(it)},
                    updateActivity={updateActivity(it)},
                    loading=loading,
                    imgUrl=imgUrl,
                    onSelectImage={onSelectImage(it)},
                )
            }
            UserInfoHeaderEnum.CHECKOUT->{
                CheckoutTabContent(
                    cashList =checkOutData,
                    cashRegisterIds =selectedUser?.cashRegisterId?: listOf(),
                    windowSizeMode = windowSizeMode,
                    updateCheckout = {
                        updateUserCheckout(it)
                    },
                    isExpandAll = isCheckoutExpandAll,
                    searchValue = checkoutSearchValue,
                    onSearchValueChange = {
                        onCheckoutSearch(it)
                    },

                )
            }

            UserInfoHeaderEnum.RIGHT->{
                UserRightsTabContent(
                    cancellationReason=selectedCancellationReason,
                    selectedUser=selectedUser,
                    onCancellationReasonClick={
                        onCancellationReasonClick(it)
                    },
                    updateDayEndCheck = {
                        updateDayEndCheck(it)
                    },
                    updateIsAdmin = {
                        updateIsAdmin(it)
                    },
                    updateIsManager = {
                        updateIsManager(it)
                    },
                    updateIsViewSale = {
                        updateIsViewSale(it)
                    },
                    updateTimeTracking = {
                        updateTimeTracking(it)
                    },
                    updateInterimFinancialStatement = {
                        updateInterimFinancialStatement(it)
                    },
                    windowSizeEnum = windowSizeMode,
                    waiterCastleValue = selectedUser?.waiterKey ?:"",
                    onWaiterCastleFocusOut = {
                        updateWaiterCastle(it)
                    }

                )

            }
        }



    }
}

