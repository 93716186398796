package org.hs_soft.runmynesto.pages.components.menu

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOut
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.px
import org.hs_soft.kobweb_aws_cognito.domain.model.menu.SubMenuModel
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Constants

@Composable
fun SubMenu(
    subData: List<SubMenuModel>,
    hoverColor: CSSColorValue,
    expandedVertically: Boolean
) {
    val targetHeight = if (expandedVertically) "${subData.size *
            Dimen.subMenuItemRowHeight}px" else "0px"

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(left = Dimen.normalPadding)
            .attrsModifier {
                style {
                    property("height", targetHeight)
                    property("opacity",
                        if (expandedVertically) "1" else "0")
                    property("overflow",
                        "hidden") // Prevent content from spilling out during transition
                    property("transition",
                        "height ${Constants.fastTransition} ease, " +
                                "opacity ${Constants.fastTransition} ease")
                }
            }
    ) {
        subData.forEach { model ->
            var isSumItemHovered by remember { mutableStateOf(false) }
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .then(
                        if (isSumItemHovered)
                            Modifier.backgroundColor(hoverColor) else Modifier
                    )
                    .onClick {
                        handleSubMenuAction(subMenuId=model.id)

                    }
                    .onMouseOver { isSumItemHovered = true }
                    .onMouseOut { isSumItemHovered = false }
                    .padding(left = Dimen.veryExtraExtraPadding)
                    .height(Dimen.subMenuItemRowHeight.px)
                    .margin(
                        bottom = Dimen.smallPadding,
                        top = Dimen.smallPadding
                    )
                    .cursor(Cursor.Pointer)
            ) {
                SpanText(
                    modifier = Modifier
                        .align(Alignment.CenterStart)
                        .fontFamily(FontFamily.Montserrat)
                        .color(Theme.Secondary.rgb)
                        .fontWeight(FontWeight.Normal)
                        .fontSize(FontSizes.normalFontSize)
                        .lineHeight(FontLineHeight.lineNormal)
                        .letterSpacing(LetterSpacing.small),
                    text = model.title
                )
            }
        }
    }
}

fun handleSubMenuAction(subMenuId: String)
{
    when (subMenuId) {

        else -> {
            // Handle the default case or throw an error if an unknown ID is encountered
        }
    }
}