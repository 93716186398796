package org.hs_soft.runmynesto.domain.model.home.options.cash_flow

import org.hs_soft.runmynesto.domain.config.Strings

enum class CreditCardsInfoEnum(
    val titleShown: String,
    val title: String,
) {
    NAME_1(
        titleShown = Strings.name1,
        title = ""
    ),
    NAME_2(
        titleShown = Strings.name2,
        title = ""
    ),
    NAME_3(
        titleShown = Strings.name3,
        title = ""
    ),
    NAME_4(
        titleShown = Strings.name4,
        title = ""
    ),
    NAME_5(
        titleShown = Strings.name5,
        title = ""
    ),
    NAME_6(
        titleShown = Strings.name6,
        title = ""
    ),
    NAME_7(
        titleShown = Strings.name7,
        title = ""
    ),
    NAME_8(
        titleShown = Strings.name8,
        title = ""
    ),
    NAME_9(
        titleShown = Strings.name9,
        title = ""
    ),
    NAME_10(
        titleShown = Strings.name10,
        title = ""
    ),


}