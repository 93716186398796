package org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi

import androidx.compose.runtime.NoLiveLiterals
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.TimeZone
import kotlinx.datetime.minus
import kotlinx.datetime.toLocalDateTime
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.StatisticHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.ABCStatisticGroupByEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data.BillSearchStatisticDataItem
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.usecase.home.GetSavedCheckOutUseCase
import org.hs_soft.runmynesto.domain.usecase.statistic.abs_statistic.ABCStatisticDataUseCase
import org.hs_soft.runmynesto.domain.usecase.statistic.abs_statistic.InitABCStatisticUseCase
import org.hs_soft.runmynesto.domain.usecase.statistic.search_bill.BillPdfUseCase
import org.hs_soft.runmynesto.domain.usecase.statistic.search_bill.BillSearchStatisticDataUseCase
import org.hs_soft.runmynesto.domain.usecase.statistic.search_bill.InitSearchBillUseCase
import org.hs_soft.runmynesto.domain.util.downloadFile
import org.hs_soft.runmynesto.domain.util.extractAllCashRegisters
import org.hs_soft.runmynesto.domain.util.extractDate
import org.hs_soft.runmynesto.domain.util.extractTime
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.domain.util.getFromDate
import org.hs_soft.runmynesto.domain.util.getToDate
import org.hs_soft.runmynesto.domain.util.getUpdatedSelectedYear
import org.hs_soft.runmynesto.domain.util.handleError
import org.hs_soft.runmynesto.domain.util.helper.BaseViewModel
import org.hs_soft.runmynesto.domain.util.jsObject
import org.hs_soft.runmynesto.domain.util.withTokenRefresh
import org.hs_soft.runmynesto.modules.ExcelJS
import org.hs_soft.runmynesto.modules.FileSaver
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag

class StatisticViewModel(
    private val requestManager: RequestManager,
    private val refreshToken: () -> Unit,
    private val initSearchBillUseCase: InitSearchBillUseCase,
    private val getSavedCheckOutUseCase: GetSavedCheckOutUseCase,
    private val billSearchStatisticDataUseCase: BillSearchStatisticDataUseCase,
    private val initABCStatisticUseCase: InitABCStatisticUseCase,
    private val abcStatisticDataUseCase: ABCStatisticDataUseCase,
    private val billPdfUseCase: BillPdfUseCase,
): BaseViewModel()
{
    private val _state = MutableStateFlow(StatisticState())
    val state: StateFlow<StatisticState> = _state
    private var tempCheckoutList: List<Children>? = listOf()
    private  var tempBillSearchStatisticData:List<BillSearchStatisticDataItem> ?=null
    private var tempBillSearchStatisticItemDetail:BillSearchStatisticDataItem?=null

    override fun clear() {
        requestManager.cancelAll()
    }

    init {
        initCalendar()
    }

    private fun initCalendar()
    {
        val today = Clock.System.now().toLocalDateTime(TimeZone.UTC).date

        _state.value = _state.value.copy(
            selectedDate = MonthModel(
                dayNumber = today.dayOfMonth,
                monthName = today.month.name,
                yearNumber = today.year,
                monthNumber = today.monthNumber
            ),

        )
        _state.value=_state.value.copy(
            selectedFromDate = state.value.selectedDate?.let { getFromDate(it) },
            selectedToDate = state.value.selectedDate?.let { getFromDate(it) }
        )
    }

    fun onEvent(event: StatisticEvent){
        when(event){

            is StatisticEvent.CloseAllDialogs->{
                closeAllDialogs()
            }

            is StatisticEvent.SetPdfPreviewDialogStatus->{
                _state.value=_state.value.copy(
                    showPdfPreviewDialog = event.status
                )
                if (!event.status)
                    _state.value=_state.value.copy(
                        billPdfResult = null
                    )
            }

            is StatisticEvent.ExportExcelFile->{
                exportToExcel(data=event.data)
            }

            is StatisticEvent.BillPdf->{
                billPdf(
                    billDate=event.billDate,
                    billNumber=event.billNumber,
                    cashRegisterId=event.cashRegisterId,
                )
            }

            is StatisticEvent.OnSearchInSelectedBillSearchItem->{
                searchInSelectedBillSearchItem(searchValue = event.searchValue)
            }

            is StatisticEvent.OnSelectBillSearchStatisticItem->{
                onSelectBillSearchStatisticItem(item=event.item)
            }

            is StatisticEvent.OnSearchInBillSearchItems->{
                searchInBillSearchItems(searchValue=event.searchValue)
            }

            is StatisticEvent.SetCheckoutDialogStatus->{
                setCheckoutDialogStatus(status=event.status)
            }

            is StatisticEvent.OnSearchCheckoutItems->{
                searchCheckoutItems(value = event.value)
            }

            is StatisticEvent.UpdateSelectedCheckout->{
                updateSelectedCheckout(
                    selectedCashRegisterIds=event.cashRegisterIds,
                    cashList = state.value.cashList?: listOf()
                )
            }

            is StatisticEvent.InitABCStatistic->{
                initABCStatistic(
                    to=state.value.selectedToDate?:"",
                    from=state.value.selectedFromDate?:"",
                    groupBy=state.value.selectedGroupBy.value,
                    cashRegisterIds=state.value.cashRegisters?.mapNotNull { it.id }?: listOf() ,
                    productIds= listOf()
                )

            }

            is StatisticEvent.OnTabHeaderClick->{
                onTabHeaderClick(tab=event.tab)
            }

            is StatisticEvent.OnSelectFromDate->{
                onSelectFromDate(
                    selectedDate = event.selectedFromDate,
                )
            }
            is StatisticEvent.OnSelectToDate->{
                onSelectToDate(
                    selectedDate = event.selectedToDate,
                )
            }


            is StatisticEvent.SetFromCalendarFilterDialogStatus->{
                setCalendarFromFilterDialogStatus(status=event.status)
            }

            is StatisticEvent.SetToCalendarFilterDialogStatus->{
                setCalendarToFilterDialogStatus(status=event.status)
            }

            is StatisticEvent.SetNewCalendarHeaderYear->{

                _state.value=_state.value.copy(
                    selectedCalendarHeaderYear = event.year
                )
            }

            is StatisticEvent.SubmitStatisticCalendarFilter->{
                submitStatisticCalendarFilter(
                    from=event.from,
                    to=event.to,
                )
            }

            is StatisticEvent.SubmitStatisticDaysFilter->{
                submitStatisticDaysFilter(
                    from=event.from,
                    to=event.to,
                    selectedPeriodType=event.periodDaysType,
                )
            }

            is StatisticEvent.SetDaysFilterDialogStatus->{
                setDaysFilterDialogStatus(status=event.show)
            }

            is StatisticEvent.GetCashRegisterIds -> {
                getCashRegisters()
            }

            is StatisticEvent.InitBillSearch->{
                initSearchBill(
                    from = state.value.selectedFromDate?:"",
                    to=state.value.selectedToDate?:"",
                    cashRegisterIds = state.value.cashRegisters
                        ?.mapNotNull { it.id }?: listOf(),
                    billNumberFrom = event.billNumberFrom,
                    billNumberTo = event.billNumberTo,
                    totalFrom = event.totalFrom,
                    totalTo = event.totalTo,
                )
            }

            is StatisticEvent.UpdateGroupBy->{
                updateGroupBy(groupBy=event.groupBy)
            }


        }

        

    }

    private fun closeAllDialogs() {
        _state.value=_state.value.copy(
            showFromCalendarFilterDialog = false,
            showToCalendarFilterDialog = false,
            showPdfPreviewDialog = false,
            showCheckoutDialog = false,
            showDaysFilterDialog = false,
            showReportsTableAndDevices = false,
        )



    }


//    private fun exportToExcel(data: List<BillSearchStatisticDataItem>) {
//        val workbook = ExcelJS.Workbook()
//        val worksheet = workbook.addWorksheet("Bill Search Data")
//
//        // Add header row
//        worksheet.addRow(arrayOf(
//            "Date",
//            "Time",
//            "Cash Register Name",
//            "Number",
//            "User Name",
//            "Cancellation",
//            "Total"
//        ))
//
//        // Add data rows
//        data.forEach { item ->
//            worksheet.addRow(arrayOf(
//                extractDate(item.dateTime ?: ""),
//                extractTime(item.dateTime ?: ""),
//                item.cashRegisterName ?: "",
//                item.number.toString(),
//                item.userName ?: "",
//                if (item.isCancelled == true) Strings.cancelled else Strings.notCancelled,
//                formatToTwoDecimals(item.total)
//            ))
//        }
//
//        // Write to buffer and save
//        workbook.xlsx.writeBuffer().then { buffer ->
//            val blob = Blob(arrayOf(buffer), BlobPropertyBag(type = "application/octet-stream"))
//            FileSaver.saveAs(blob, "BillSearchData.xlsx")
//        }
//    }

    @NoLiveLiterals
    private fun exportToExcel(data: List<BillSearchStatisticDataItem>) {
        val workbook = ExcelJS.Workbook()
        val worksheet = workbook.addWorksheet("Bill Search Data")


        val table = jsObject<ExcelJS.Table> {
            name = "BillSearchTable"
            ref = "A1"
            headerRow = true
            totalsRow = false
            columns = arrayOf(
                jsObject<Any> { name = Strings.date },
                jsObject<Any> { name = Strings.time },
                jsObject<Any> { name = Strings.cashRegisterName },
                jsObject<Any> { name = Strings.number },
                jsObject<Any> { name = Strings.userName },
                jsObject<Any> { name = Strings.cancellation },
                jsObject<Any> { name = Strings.total }
            )
            rows = data.map { item ->
                arrayOf(
                    extractDate(item.dateTime ?: ""),
                    extractTime(item.dateTime ?: ""),
                    item.cashRegisterName ?: "",
                    item.number.toString(),
                    item.userName ?: "",
                    if (item.isCancelled == true) Strings.cancelled else Strings.notCancelled,
                    formatToTwoDecimals(item.total)
                )
            }.toTypedArray()
        }

        // Add the table to the worksheet
        worksheet.addTable(table)

        // Write to buffer and save
        workbook.xlsx.writeBuffer().then { buffer ->
            val blob = Blob(arrayOf(buffer), BlobPropertyBag(type = "application/octet-stream"))
            FileSaver.saveAs(blob, "BillSearchData.xlsx")
        }
    }

    private fun billPdf(
        billDate: String,
        billNumber: Int,
        cashRegisterId: String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {

                    showProgress()
                    val result=billPdfUseCase(
                        billDate=billDate,
                        billNumber=billNumber,
                        cashRegisterId=cashRegisterId,
                    ).data?.cashAssist?.getBillPdf?.url

                    if (result != null) {
                        val fileName = "bill_$billNumber.pdf"

                        _state.value=_state.value.copy(
                            billPdfResult=result,
                        )

                        if (state.value.billPdfResult!=null){
                            _state.value=_state.value.copy(
                                showPdfPreviewDialog=true,
                            )
                            downloadFile(
                                url = result,
                                fileName= fileName,
                            )
                        }


                    }
                    hideProgress()

                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }



    }



    private fun searchInBillSearchItems(searchValue: String) {
        val searchTitle = searchValue.lowercase().removeSuffix("enter")

        val result = tempBillSearchStatisticData?.filter { item ->
            listOf(
                item.dateTime,
                item.cashRegisterName,
                item.number,
                item.userName,
                item.total.toString()
            ).any { fieldValue ->
                fieldValue?.lowercase()?.contains(searchTitle) ?:false
            }
        } ?: listOf()

        _state.value = _state.value.copy(
            billSearchStatisticData = result
        )

    }

    private fun searchInSelectedBillSearchItem(searchValue: String) {
        val searchTitle = searchValue.lowercase().removeSuffix("enter")

        val result = tempBillSearchStatisticItemDetail?.items?.filter { item ->
            listOf(
                item?.amount?.toString(),
                item?.product?.code,
                item?.product?.name,
                item?.price?.toString(),
                item?.discountPercent?.toString(),
                item?.codeMWSt,
                item?.mul1?.toString(),
                item?.total?.toString()
            ).any { fieldValue ->
                fieldValue?.lowercase()?.contains(searchTitle) ?: false
            }
        } ?: listOf()

        _state.value = _state.value.copy(
            selectedBillSearchStatisticItem = state.value.selectedBillSearchStatisticItem?.copy(
                items = result
            )
        )
    }


    private fun onSelectBillSearchStatisticItem(item: BillSearchStatisticDataItem) {

        _state.value=_state.value.copy(
            selectedBillSearchStatisticItem = item
        )
        tempBillSearchStatisticItemDetail=item
    }

    private fun setCheckoutDialogStatus(status: Boolean) {
        _state.value=_state.value.copy(
            showCheckoutDialog = status
        )
    }

    private fun searchCheckoutItems(value: String)
    {
        _state.value= _state.value.copy(cashList =tempCheckoutList )

        if (value == "") {
            _state.value = _state.value.copy(isDevicesExpandAll = false,)
        } else {
            val searchedList = ArrayList<Children>()
            state.value.cashList?.forEach { model ->
                var matchFound = model.name?.lowercase()?.contains(value.lowercase()) ?: false
                model.children?.forEach { childX ->
                    if (childX.name?.lowercase()?.contains(value.lowercase()) == true) {
                        matchFound = true
                    }
                    childX.children?.forEach { childXX ->
                        if (childXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                            matchFound = true
                        }
                        childXX.children.forEach {childXXX->
                            if (childXXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                                matchFound = true
                            }
                        }
                    }
                }
                if (matchFound) {
                    searchedList.add(model)
                }
            }


            _state.value=_state.value.copy(
                isDevicesExpandAll = true,
                cashList = searchedList
            )

        }
    }

    private fun updateSelectedCheckout(
        selectedCashRegisterIds: List<String>,
        cashList:List<Children>,
    ) {
        val allCashRegisters = extractAllCashRegisters(cashList)
        val filteredCashRegisters = allCashRegisters.filter { it.id in selectedCashRegisterIds }

        _state.value = _state.value.copy(
            selectedCashRegisterIds = selectedCashRegisterIds,
            cashRegisters = filteredCashRegisters
        )




    }

    private fun updateGroupBy(groupBy: ABCStatisticGroupByEnum) {
        _state.value=_state.value.copy(
            selectedGroupBy=groupBy
        )
    }

    private fun initABCStatistic(
        to: String,
        from: String,
        groupBy: String,
        cashRegisterIds: List<String>,
        productIds: List<String>
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {

                    showProgress()
                    val result=initABCStatisticUseCase(
                        from = from,
                        to = to,
                        groupBy = groupBy,
                        cashRegisterIds = cashRegisterIds,
                        productIds = productIds,
                    ).data?.cashAssist?.initAbcStatistics

                    getABCStatisticData(requestId =result?:"")
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }




    }

    private fun getABCStatisticData(requestId: String) {

        launch {
            try {
                withTokenRefresh(refreshToken) {

                    showProgress()
                    val result=abcStatisticDataUseCase(
                        requestId=requestId
                    ).data?.cashAssist?.getStatisticsData?.data

                    if (result!=null){
                        _state.value=_state.value.copy(
                            abcStatisticData =result
                        )

                        hideProgress()
                    }else {
                        getABCStatisticData(requestId=requestId)
                    }


                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun initSearchBill(
        from: String,
        to: String,
        cashRegisterIds:List<String>,
        billNumberFrom:Int?,
        billNumberTo:Int?,
        totalFrom:Int?,
        totalTo:Int?,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {

                    showProgress()
                    val result=initSearchBillUseCase(
                        billNumberFrom = billNumberFrom,
                        billNumberTo = billNumberTo,
                        from = from,
                        to = to,
                        totalFrom = totalFrom,
                        totalTo = totalTo,
                        cashRegisterIds = cashRegisterIds,
                        products = listOf()
                    ).data?.cashAssist?.initSearchBills


                    getBillSearchStatisticData(requestId =result?:"")
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun getBillSearchStatisticData(
        requestId:String,
    )
    {
        launch {
            try {
                withTokenRefresh(refreshToken) {

                    showProgress()
                    val result=billSearchStatisticDataUseCase(
                        requestId=requestId
                    ).data?.cashAssist?.getStatisticsData?.data

                    if (result!=null){
                        _state.value=_state.value.copy(
                            billSearchStatisticData=result,
                        )

                        tempBillSearchStatisticData=result

                        hideProgress()
                    }else getBillSearchStatisticData(
                        requestId=requestId
                    )


                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }

    }
    private fun getCashRegisters() {
        launch {
            if (state.value.cashRegisters==null){

                val result=getSavedCheckOutUseCase()?.data?.cashAssist
                    ?.children

                _state.value=_state.value.copy(
                    cashRegisters = extractAllCashRegisters(cashList = result),
                    cashList = result
                )

                tempCheckoutList=state.value.cashList

            }

        }

    }

    private fun setDaysFilterDialogStatus(status: Boolean) {
        _state.value = _state.value.copy(
            showDaysFilterDialog =status,
            showFromCalendarFilterDialog = false,
            showToCalendarFilterDialog = false,

        )

    }

    private fun submitStatisticDaysFilter(
        from: String,
        to: String,
        selectedPeriodType: PeriodDaysEnum,
    ) {
        _state.value=_state.value.copy(
            showDaysFilterDialog = false,
            selectedFromDate = from,
            selectedToDate = to,
            selectedPeriodType = selectedPeriodType,
        )
        val today = Clock.System.now().toLocalDateTime(TimeZone.UTC).date

        if (selectedPeriodType == PeriodDaysEnum.TODAY) {
            _state.value = _state.value.copy(
                selectedDate = MonthModel(
                    dayNumber = today.dayOfMonth,
                    monthName = today.month.name,
                    yearNumber = today.year,
                    monthNumber = today.monthNumber
                )
            )
        } else if (selectedPeriodType == PeriodDaysEnum.YESTERDAY) {
            val yesterday = today.minus(1, DateTimeUnit.DAY)
            _state.value = _state.value.copy(
                selectedDate = MonthModel(
                    dayNumber = yesterday.dayOfMonth,
                    monthName = yesterday.month.name,
                    yearNumber = yesterday.year,
                    monthNumber = yesterday.monthNumber
                )
            )
        }else{
            _state.value=_state.value.copy(
                selectedDate = null
            )
        }



    }
    private fun submitStatisticCalendarFilter(
        from: String, to: String
    ) {
        _state.value=_state.value.copy(
            selectedToDate =  to.getUpdatedSelectedYear(state.value.selectedCalendarHeaderYear),
            selectedFromDate = from.getUpdatedSelectedYear(state.value.selectedCalendarHeaderYear),
        )



    }

    private fun onSelectFromDate(
        selectedDate: MonthModel,
    ) {
        _state.value=_state.value.copy(
//            selectedDate = selectedDate,
            selectedFromDate = getFromDate( selectedDate),
//            selectedToDate = getToDate( selectedDate)
        )


    }

    private fun onSelectToDate(
        selectedDate: MonthModel,
    ) {
        _state.value=_state.value.copy(
//            selectedDate = selectedDate,
//            selectedFromDate = getFromDate( selectedDate),
            selectedToDate = getToDate( selectedDate)
        )


    }

    private fun setCalendarFromFilterDialogStatus(status: Boolean) {
        _state.value = _state.value.copy(
            showFromCalendarFilterDialog = status,
            showDaysFilterDialog = false,
            showToCalendarFilterDialog = false
        )
    }
    private fun setCalendarToFilterDialogStatus(status: Boolean) {
        _state.value = _state.value.copy(
            showToCalendarFilterDialog = status,
            showDaysFilterDialog = false,
            showFromCalendarFilterDialog = false,
        )
    }



    private fun onTabHeaderClick(tab: StatisticHeaderEnum) {
        _state.value=_state.value.copy(
            selectedTab = tab
        )

    }

    private fun showProgress()
    {
        _state.value=_state.value.copy(
            loadingProgress = true
        )
    }
    private fun hideProgress()
    {
        _state.value=_state.value.copy(
            loadingProgress = false
        )
    }

}