package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.RestaurantProject
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.Room
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CircleIcon
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.Separator
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.jetbrains.compose.web.css.px


@Composable
fun RestaurantProjectContent(
    windowSizeMode: WindowSizeModeEnum,
    selectedRestaurantProject: RestaurantProject?,
    selectedRoom:Room?,
    onMoveFurniture:(String,Int,Int,Int,Int,Int)->Unit,
    showRestaurantFloorDialog:()->Unit,
    showDeleteFloorDialog:(String?)->Unit,
    showDeleteRoomDialog:(String?)->Unit,
    showEditFloorDialog:()->Unit,
) {
    Column(
        modifier= Modifier
            .margin(leftRight = Dimen.mediumPadding)
            .fillMaxSize()
            .borderSetting()
    )
    {
        Column(
            modifier = Modifier.fillMaxWidth()
        )
        {
            SpanText(
                text=selectedRestaurantProject?.caption?:"",
                modifier = Modifier
                    .minHeight(Dimen.minimumContentTitleHeight)
                    .margin(left = Dimen.normalPadding)
                    .fontWeight(FontWeight.Normal)
                    .lineHeight(FontLineHeight.lineLarge)
                    .color(Theme.Secondary.rgb)
                    .fontFamily(FontFamily.Jakarta)
            )


            CustomEditText(
                modifier = Modifier ,
                label = Strings.projectName,
                value = selectedRestaurantProject?.caption?:"",
                marginBottom = Dimen.smallPadding,
                isRequired = false,
                hint = "",
                onFocusIn = {   },
                onFocusOut = {

                },
                onInputChange = {},
            )

            FloorItems(
                selectedRestaurantProject=selectedRestaurantProject,
                showRestaurantFloorDialog = {
                    showRestaurantFloorDialog()
                },
                showDeleteRoomDialog = {
                    showDeleteRoomDialog(it)
                },
                showDeleteFloorDialog = {
                    showDeleteFloorDialog(it)
                },
                onEditIconClick = {
                    showEditFloorDialog()
                }
            )

            Separator()
        }


        RestaurantDesign(
            selectedRestaurantProject=selectedRestaurantProject,
            selectedRoom = selectedRoom,
            onMoveFurniture = {id:String,offsetX:Int,offsetY:Int,rotationDeg:Int,width:Int,height:Int->
                onMoveFurniture(id,offsetX,offsetY,rotationDeg,width,height)
            }
        )





    }


}



@Composable
private fun FloorItems(
    selectedRestaurantProject: RestaurantProject?,
    showRestaurantFloorDialog:()->Unit,
    showDeleteFloorDialog:(String?)->Unit,
    showDeleteRoomDialog:(String?)->Unit,
    onEditIconClick:()->Unit,
) {
    Column(
        modifier = Modifier.fillMaxWidth()
    )
    {
        selectedRestaurantProject?.floors?.forEach { floor ->

            if (!floor?.rooms.isNullOrEmpty()){

                Separator()

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                )
                {
                    SpanText(
                        text ="${floor?.typename} ${floor?.number}",
                        modifier = Modifier
                            .minHeight(Dimen.minimumContentTitleHeight)
                            .fontSize(FontSizes.mediumFontSize)
                            .margin(leftRight = Dimen.normalPadding)
                            .fontWeight(FontWeight.Normal)
                            .lineHeight(FontLineHeight.lineLarge)
                            .color(Theme.Secondary.rgb)
                            .fontFamily(FontFamily.Jakarta)
                    )

                    CircleIcon(
                        img = Res.PathIcon.close,
                        onIconClick = {
                            onEditIconClick()
                        }
                    )


                    Spacer()

                    CircleIcon(
                        borderColor = Theme.Transparent.rgb,
                        img = Res.PathIcon.close,
                        onIconClick = {
                            showDeleteFloorDialog(floor?.id)
                        }
                    )


                }

                floor?.rooms?.forEach { room ->

                    CustomEditText(
                        modifier = Modifier,
                        label = Strings.roomName,
                        value = room?.caption ?: "",
                        marginBottom = Dimen.smallPadding,
                        isRequired = false,
                        endIconPath =  Res.PathIcon.mediumClose,
                        onEndIconClick = {
                            showDeleteRoomDialog(room?.id)
                        },
                        hint = "",
                        onFocusIn = { },
                        onFocusOut = {

                        },
                        onInputChange = {},
                    )
                }

                CustomEditText(
                    modifier = Modifier,
                    label = Strings.newRoom,
                    value ="",
                    marginBottom = Dimen.smallPadding,
                    isRequired = false,
                    hint = "",
                    onFocusIn = {},
                    onFocusOut = {

                    },
                    onInputChange = {},
                )
            }


        }

        CustomButton(
            modifier = Modifier,
            topBottomMargin = Dimen.normalPadding,
            title = Strings.addFloor,
            leftRightMargin = 0.px,
            defaultTextColor = Theme.White.rgb,
            onButtonClick = {
                showRestaurantFloorDialog()
            },
        )
    }

}
