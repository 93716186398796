package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_flow

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_flow.component.CreditCards
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_flow.component.IncomeExpanses
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsState
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun CashFlow(
    state: OptionsState,
    event: (OptionsEvent) -> Unit,
    windowSizeMode: WindowSizeModeEnum
)
{

    Column(
        modifier = Modifier
            .fillMaxSize()
            .margin(
                bottom = Dimen.normalPadding
            )
            .padding(Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            ),
        horizontalAlignment =
            if (windowSizeMode==WindowSizeModeEnum.PHONE)
                Alignment.CenterHorizontally
            else Alignment.Start
    ){

        CreditCards(
           creditCards = state.appConfig?.creditCards,
           windowSizeMode=windowSizeMode,
           updateCreditCards = {
               event(OptionsEvent.UpdateAppConfig(
                   title=ApiUtils.Field.creditCards,
                   value=it
               ))
           }
        )

        IncomeExpanses(
            specialProductExpenses=state.appConfig?.specialProductExpenses,
            windowSizeMode=windowSizeMode,
            updateIncomeExpanses = {
                event(OptionsEvent.UpdateAppConfig(
                    title=ApiUtils.Field.specialProductExpenses,
                    value=it
                ))
            }
        )








    }
}