package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class OrderItem(
    @SerialName("id")
    val id: String?,
    @SerialName("price")
    val price: Float?,
    @SerialName("amount")
    val amount: Float?,
    @SerialName("total")
    val total: Float?,
    @SerialName("product")
    val product: Product?,
    @SerialName("__typename")
    val typename: String?
)