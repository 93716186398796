package org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Data(
    @SerialName("cashAssist")
    val cashAssist: CashAssist?
)