package org.hs_soft.runmynesto.domain.usecase.options.operation

import org.hs_soft.runmynesto.domain.model.home.options.operation.option_model.OptionData
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.operation.OptionDataRepository


class OptionDataUseCase
    (private val repository: OptionDataRepository) {
    suspend operator fun invoke( ): OptionData {
        return repository.optionData()
    }
}