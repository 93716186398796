package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.abc_statistic

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.ABCStatisticGroupByEnum
import org.hs_soft.runmynesto.pages.components.DropdownComponent
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.abc_statistic.component.ABCStatisticTable
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.abc_statistic.component.FilterABCStatistic
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi.StatisticEvent
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi.StatisticState
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun ABCStatistic(
    windowSizeMode: WindowSizeModeEnum,
    state: StatisticState,
    event:(StatisticEvent)->Unit,
)
{
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .borderSetting()
    ){
        MediumContentTitle(
            title = Strings.setting
        )

        DropdownComponent(
            modifier = Modifier,
            options = ABCStatisticGroupByEnum.entries,
            label = Strings.groupedBy,
            selectedItem = state.selectedGroupBy,
            content = {
                if (it is ABCStatisticGroupByEnum)
                Text(it.title)
            },
            onOptionClick = {

                val option: ABCStatisticGroupByEnum? =
                    ABCStatisticGroupByEnum.entries.find { item ->
                    item.toString() == it
                }
                if (option!=null){
                    event(StatisticEvent.UpdateGroupBy(option))
                }
            },
        )

        FilterABCStatistic(
            event = event,
            cashRegisters=state.cashRegisters,
            selectedPeriodType = state.selectedPeriodType,
            showDaysFilterDialog = state.showDaysFilterDialog,
            showCalendarFilterDialog = state.showCalendarFilterDialog,
            selectedDate = state.selectedDate,
            selectedFrom = state.selectedFromDate ?: "",
            selectedTo = state.selectedToDate ?: "",
            selectedCalendarHeaderYear = state.selectedCalendarHeaderYear,
        )

        CustomButton(
            modifier = Modifier,
            windowSizeMode=windowSizeMode,
            topBottomMargin = Dimen.mediumPadding,
            leftRightMargin = 0.px,
            title = Strings.calculation,
            onButtonClick = {
                event(StatisticEvent.InitABCStatistic)
            }

        )




        ABCStatisticTable(
            windowSizeMode =windowSizeMode,
            items = state.abcStatisticData
        )


    }




}

