package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SalesGroupX(
    @SerialName("acountNum")
    val acountNum: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("name")
    val name: String?,
    @SerialName("__typename")
    val typename: String?
)