package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.AppleWallet
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.DigitalCustomerCardInfo
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.GoogleWallet
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsState
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.overflowY
import org.jetbrains.compose.web.css.vh

@Composable
fun VirtualCard(
    state: OptionsState,
    event: (OptionsEvent) -> Unit,
    windowSizeMode: WindowSizeModeEnum
)
{
    LaunchedEffect(Unit){
        event(OptionsEvent.GetVirtualCardInfo)
    }

    Column(
        modifier = Modifier.fillMaxSize()
    )
    {

        if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
        {
            Row(
                modifier = Modifier
                    .fillMaxSize()
                    .margin(
                        leftRight = Dimen.mediumPadding,
                        bottom = Dimen.normalPadding
                    )
                    .borderSetting()
            ){
                content(
                    state=state,
                    event=event,
                    windowSizeMode=windowSizeMode
                )
            }
        }
        else{
            Column(
                modifier =VerticalScrollBehavior
                    .toModifier()
                    .weight(1f)
                    .fillMaxSize()
                    .styleModifier {
                        flexGrow(1)
                        overflowY("auto")
                    }
                    .height(Constants.Responsive.templateGridContentHeight.vh)
                    .margin(
                        bottom = Dimen.normalPadding
                    )
                    .borderSetting()
                ,
                horizontalAlignment =if (windowSizeMode==WindowSizeModeEnum.PHONE)
                    Alignment.CenterHorizontally else Alignment.Start
            ){

                content(
                    state=state,
                    event=event,
                    windowSizeMode=windowSizeMode
                )

            }
        }

    }



}

@Composable
private fun content(
    state: OptionsState,
    event: (OptionsEvent) -> Unit,
    windowSizeMode: WindowSizeModeEnum
) {
    DigitalCustomerCardInfo(
        info=state.virtualCardInfo,
        event = event,
        windowSizeMode = windowSizeMode,
    )

    if (windowSizeMode==WindowSizeModeEnum.PHONE){
        Column()
        {
            GoogleWallet(
                info=state.virtualCardInfo,
                event = event,
                windowSizeMode = windowSizeMode,
            )

            AppleWallet(
                info=state.virtualCardInfo,
                event = event,
                windowSizeMode = windowSizeMode,
            )
        }

    }else{
        Row()
        {
            GoogleWallet(
                info=state.virtualCardInfo,
                event = event,
                windowSizeMode = windowSizeMode,
            )

            AppleWallet(
                info=state.virtualCardInfo,
                event = event,
                windowSizeMode = windowSizeMode,
            )
        }
    }


}
