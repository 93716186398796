package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.menu_card_import


import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.*
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.menu_card_import.menu_card_model.MenuCardModel
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.UploadMenuCard
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsState
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px


@Composable
fun MenuCardImportTab(
    state: OptionsState,
    event: (OptionsEvent) -> Unit,
    windowSizeMode: WindowSizeModeEnum
) {

    Column(
        modifier = Modifier
            .fillMaxHeight()
            .fillMaxWidth()
            .padding(Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    )
    {
        if (windowSizeMode == WindowSizeModeEnum.WINDOWS) {
            Row()
            {
                TopContent(
                    windowSizeMode = windowSizeMode,
                    onGenerateMenuCardButtonClick = {
                        event(OptionsEvent.OnGenerateMenuCardButtonClick)
                    },
                    menuCardAIGenerated = state.menuCardAIGenerated,
                    onMenuProductItemValueChange = { menuCard, id, key, value ->
                        event(OptionsEvent.OnMenuProductItemValueChange(menuCard, id, key, value))
                    },
                    onCancelImportMenuCardClick = {
                        event(OptionsEvent.ResetGenerateAIMenuCard)
                    },
                    onImportClick = { menuCard ->
                        event(OptionsEvent.SaveMenuCard(menuCard))
                    },
                    showGenerateAIMenuCardDialog = {
                        event(OptionsEvent.SetGenerateAIMenuCardDialogStatus(true))
                    },
                )
            }
        } else {
            Column {
                TopContent(
                    windowSizeMode = windowSizeMode,
                    onGenerateMenuCardButtonClick = {
                        event(OptionsEvent.OnGenerateMenuCardButtonClick)
                    },
                    menuCardAIGenerated = state.menuCardAIGenerated,
                    onMenuProductItemValueChange = { menuCard, id, key, value ->
                        event(OptionsEvent.OnMenuProductItemValueChange(menuCard, id, key, value))
                    },
                    onImportClick = { menuCard ->
                        event(OptionsEvent.SaveMenuCard(menuCard))
                    },
                    onCancelImportMenuCardClick = {
                        event(OptionsEvent.ResetGenerateAIMenuCard)
                    },
                    showGenerateAIMenuCardDialog = {
                        event(OptionsEvent.SetGenerateAIMenuCardDialogStatus(true))
                    }
                )
            }
        }
    }
}


@Composable
private fun TopContent(
    windowSizeMode: WindowSizeModeEnum,
    menuCardAIGenerated: MenuCardModel,
    onGenerateMenuCardButtonClick:() -> Unit,
    onMenuProductItemValueChange:(MenuCardModel, Int, String, String) -> Unit,
    onCancelImportMenuCardClick: () -> Unit,
    onImportClick: (MenuCardModel) -> Unit,
    showGenerateAIMenuCardDialog:()->Unit,
) {
    Column(
        modifier = Modifier.margin(
            leftRight = if(windowSizeMode== WindowSizeModeEnum.WINDOWS)
                Dimen.mediumPadding
            else 0.px
        ),
        horizontalAlignment = Alignment.Start
        ,
    )
    {
        MediumContentTitle(
            title = Strings.menuCard,
            marginBottom = 0.px,
            marginTop =if (windowSizeMode==WindowSizeModeEnum.PHONE)
                Dimen.mediumPadding else 0.px
        )

        Box(
            modifier = Modifier
                .margin(top = Dimen.normalPadding, bottom = Dimen.extraLargePadding )
                .fillMaxWidth()
        ) {
            Row(
                modifier = Modifier
                    .margin(right = Dimen.mediumPadding,)
                    .align(Alignment.CenterStart)
                    .onClick {
                        onGenerateMenuCardButtonClick()
                    }
                    .borderRadius(
                        Dimen.lowBorderRadius
                    )
                    .height(Dimen.submitButtonHeight)
                    .width(Dimen.generateButtonWidth)
                    .backgroundColor(Theme.Blue.rgb),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            )
            {
                VectorIcon(
                    pathData = Res.PathIcon.star,
                    width = Dimen.normalIconSize,
                    height = Dimen.normalIconSize,
                    color = Theme.White.hex
                )

                SpanText(
                    text = Strings.generate,
                    modifier = Modifier
                        .margin(left = Dimen.normalPadding)
                        .cursor(Cursor.Pointer)
                        .textAlign(TextAlign.Start)
                        .fontSize(FontSizes.mediumFontSize)
                        .fontFamily(FontFamily.Montserrat)
                        .fontWeight(FontWeight.Medium)
                        .color(Theme.White.rgb)
                )

            }
        }

        UploadMenuCard(
            menuCard = menuCardAIGenerated,
            windowSizeMode = windowSizeMode,
            onMenuProductItemValueChange = onMenuProductItemValueChange,
            onCancelImportMenuCardClick = onCancelImportMenuCardClick,
            onImportClick = onImportClick
        )
    }
}


















