package org.hs_soft.runmynesto.pages.home.sub_page.template

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.GetCategory
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.GridTemplateBar
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.TemplateDialogs
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.TemplateContent
import org.hs_soft.runmynesto.pages.home.sub_page.template.mvi.TemplateEvent
import org.hs_soft.runmynesto.pages.home.sub_page.template.mvi.TemplateState
import org.hs_soft.runmynesto.pages.home.sub_page.template.mvi.TemplateViewModel

@Composable
fun Template(
    windowSizeMode: WindowSizeModeEnum,
    viewModel: TemplateViewModel,
)
{
    val state by viewModel.state.collectAsState()

    LaunchedEffect(viewModel){
        viewModel.onEvent(TemplateEvent.GetTemplateCategoryList)
    }


    Column(
        modifier = Modifier
            .padding(
                topBottom = Dimen.normalPadding,
                leftRight = Dimen.largePadding,
            )
            .scrollBehavior(ScrollBehavior.Smooth)
            .fillMaxSize()
    ) {

        Row(
            modifier = Modifier
                .fillMaxSize()
                .margin(top = Dimen.smallPadding),
            horizontalArrangement = Arrangement.Center
        )
        {

            if(state.firstPageActive){
                GridTemplateBar(
                    windowSizeMode=windowSizeMode,
                    selectedItem=state.selectedCategory,
                    infoList=state.productCategoryList,
                    showFilterComponent = state.showItemsFilterComponent,
                    selectedFilterOption = state.selectedFilterItemsType,
                    onTitleSortClick = {

                    },
                    onRefreshUserList = {
                        viewModel.onEvent(TemplateEvent.RefreshFoodList)
                    },
                    onCategoryClick = {id->
                        viewModel.onEvent(TemplateEvent.OnTemplateCategoryClick(
                                id=id,
                                switchToSecondPage = {
                                    if(windowSizeMode== WindowSizeModeEnum.PHONE)
                                        viewModel.onEvent(TemplateEvent.OnChangePageClick)
                                }
                            )
                        )
                    },
                    updateListAfterDragAndDrop ={newList,idList,positionList->
                        viewModel.onEvent(TemplateEvent.
                            UpdateTemplateCategoryListAfterDragDrop(
                                newList = newList,
                                idList=idList,
                                positionList=positionList
                            ))
                    },
                    onAddRecord = {
                        viewModel.onEvent(TemplateEvent.OnAddRecordIconClick)
                    },
                    titleDescending = false,
                    showTitleSortIcon =false,
                    onSwipeRight = {
                        viewModel.onEvent(TemplateEvent.OnChangePageClick)
                    },
                    onFilterComponentClick = {
                        viewModel.onEvent(
                            TemplateEvent.SetFilterItemsComponentStatus(
                                !state.showItemsFilterComponent
                            )
                        )
                    },
                    onCloseFilterClick = {
                        viewModel.onEvent(TemplateEvent.SetFilterItemsComponentStatus(false))
                    },
                    selectFilterType = {
                        viewModel.onEvent(TemplateEvent.SelectFilterType(it))
                    },
                    submitFilterItems = {
                        viewModel.onEvent(TemplateEvent.SubmitFilterItems)
                    }

                )

                if(windowSizeMode!= WindowSizeModeEnum.PHONE){
                    content(
                        state=state,
                        event=viewModel::onEvent,
                        windowSizeMode=windowSizeMode,
                        onSwipeLeft = {}
                    )
                }
            }
            else{
                content(
                    state=state,
                    event=viewModel::onEvent,
                    windowSizeMode=windowSizeMode,
                    onSwipeLeft = {
                        viewModel.onEvent(TemplateEvent.OnChangePageClick)
                    }
                )
            }
        }


    }

    LoadingProgress(
        visibility = state.dataLoadingProgressVisible
    )


    TemplateDialogs(
        state=state,
        windowSizeMode=windowSizeMode,
        event=viewModel::onEvent
    )



}



@Composable
private fun content(
    state: TemplateState,
    event: (TemplateEvent) -> Unit,
    windowSizeMode: WindowSizeModeEnum,
    onSwipeLeft: () -> Unit={}
) {
    TemplateContent(
        modifier = Modifier,
        selectedTab =state.selectedTab,
        selectedDate=state.selectedDate,
        selectedCategory = state.selectedCategory ?: GetCategory(),
        articleList = state.articleList.sortedBy { it.position }.toList(),
        windowSizeMode = windowSizeMode,
        selectedDays = state.selectedDays,
        showTitleSortIcon = state.showArticleTitleSortIcon,
        imgUrl = state.templateItemImg,
        checkOutData = state.cashList,
        isCheckoutExpandAll =  state.checkOutExpandAll,
        checkoutSearchValue =state.checkOutSearchValue ,
        priceLevelList = state.priceLevels?: listOf(),
        selectedPriceLevelIndex = state.selectedPriceLevelIndex,
        selectedColor = state.selectedPalletColor?: Theme.Transparent.hex,
        openColorPanel = {
             event(TemplateEvent.SetColorPalletStatus(true))
        },
        onRefreshClick = {
            event(TemplateEvent.RefreshArticleList)
        },
        onSearchValueChange = {
            event(TemplateEvent.OnSearchArticleInputChange(it))
        },
        onTitleSortClick = {
//            event(TemplateEvent.OnArticleTitleSortClick)
        },
        onChangeActivity = { id, isActive ->
            event(
                TemplateEvent.OnUpdateTemplateCategoryInfo(
                    id = id,
                    dataTitle = ApiUtils.Field.isActive,
                    dataValue = isActive
                )
            )
        },
        onChangeReceiptName = { id, value ->
            event(
                TemplateEvent.OnUpdateTemplateCategoryInfo(
                    id = id,
                    dataTitle = ApiUtils.Field.name,
                    dataValue = value
                )
            )
        },
        onSwipeLeft = {
            onSwipeLeft()
        },
        onTabClick = {
            event(TemplateEvent.OnTabClick(it))
        },
        onCheckoutSearch = {
            event(TemplateEvent.OnSearchCheckoutItems(it))
        },
        getCheckoutData = {
            event(TemplateEvent.GetCheckoutData)
        },
        updateTemplateCheckout = {
            event(TemplateEvent.UpdateCheckout(it))
        },
        getPriceLevels = {
            event(TemplateEvent.GetPriceLevels)
        },
        updatePriceLevel = {
            event(TemplateEvent.UpdatePriceLevel(it))
        },
        updateSurcharge = {fieldTitle,newList->
            event(TemplateEvent.UpdateSurcharge(
                title=fieldTitle,
                values=newList
            ))
        },
        setCalendarStatus = {
            event(TemplateEvent.SetCalendarStatus(it))
        },
        onChangeDayCheckStatus = {
            event(TemplateEvent.OnUpdateTemplateCategoryInfo(
                id = state.selectedCategory?.id?:"",
                dataTitle = ApiUtils.Field.weekDays,
                dataValue = it
            ))
        },
        showImgAIGeneratorDialog = {
            event(TemplateEvent.SetGenerateAIImgDialogStatus(true))
        },
        updateProductListAfterDragAndDrop = { newList, idList, positionList,productIdList ->
            event(TemplateEvent.
                UpdateCategoryBasedProductListAfterDragDrop(
                    newList = newList,
                    idList=idList,
                    positionList=positionList,
                    productIdList=productIdList,
                )
            )
        },
        onAddProductIconClick = {
            event(TemplateEvent.OnAddToArticleIconClick)
        }



    )
}

