package org.hs_soft.runmynesto.domain.model.home.options

enum class CountriesEnum(val id: String, val title: String) {
    SWITZERLAND("1", "SWITZERLAND"),
    GERMANY("2", "GERMANY"),
    AUSTRIA("3", "AUSTRIA"),
    GREAT_BRITAIN("4", "GREAT BRITAIN"),
    MALDIVES("5", "MALDIVES"),
    RUSSIAN_FEDERATION("6", "RUSSIAN FEDERATION"),
    UNITED_ARAB_EMIRATES("7", "UNITED ARAB EMIRATES"),
    AMERICAN_SAMOA("8", "AMERICAN SAMOA"),
    ANDORRA("9", "ANDORRA"),
    ANLA("10", "ANLA"),
    ANGUILLA("11", "ANGUILLA"),
    ANTARCTICA("12", "ANTARCTICA"),
    ANTIGUA_AND_BARBUDA("13", "ANTIGUA AND BARBUDA"),
    ARGENTINA("14", "ARGENTINA"),
    ARMENIA("15", "ARMENIA"),
    ARUBA("16", "ARUBA"),
    AUSTRALIA("17", "AUSTRALIA"),
    AZERBAIJAN("18", "AZERBAIJAN"),
    BAHAMAS("19", "BAHAMAS"),
    BAHRAIN("20", "BAHRAIN"),
    BANGLADESH("21", "BANGLADESH"),
    BARBADOS("22", "BARBADOS"),
    BELARUS("23", "BELARUS"),
    BELGIUM("24", "BELGIUM"),
    BELIZE("25", "BELIZE"),
    BENIN("26", "BENIN"),
    BERMUDA("27", "BERMUDA"),
    BHUTAN("28", "BHUTAN"),
    BOLIVIA("29", "BOLIVIA, PLURINATIONAL STATE OF"),
    BONAIRE_SAINT_EUSTATIUS_AND_SABA("30", "BONAIRE, SAINT EUSTATIUS AND SABA"),
    BOSNIA_AND_HERZEVINA("31", "BOSNIA AND HERZEVINA"),
    BOTSWANA("32", "BOTSWANA"),
    BOUVET_ISLAND("33", "BOUVET ISLAND"),
    BRAZIL("34", "BRAZIL"),
    BRITISH_INDIAN_OCEAN_TERRITORY("35", "BRITISH INDIAN OCEAN TERRITORY"),
    BRUNEI_DARUSSALAM("36", "BRUNEI DARUSSALAM"),
    BULGARIA("37", "BULGARIA"),
    BURKINA_FASO("38", "BURKINA FASO"),
    BURUNDI("39", "BURUNDI"),
    CAMBODIA("40", "CAMBODIA"),
    CAMEROON("41", "CAMEROON"),
    CANADA("42", "CANADA"),
    CAPE_VERDE("43", "CAPE VERDE"),
    CAYMAN_ISLANDS("44", "CAYMAN ISLANDS"),
    CENTRAL_AFRICAN_REPUBLIC("45", "CENTRAL AFRICAN REPUBLIC"),
    CHAD("46", "CHAD"),
    CHILE("47", "CHILE"),
    CHINA("48", "CHINA"),
    CHRISTMAS_ISLAND("49", "CHRISTMAS ISLAND"),
    COCOS_ISLANDS("50", "COCOS (KEELING) ISLANDS"),
    COLOMBIA("51", "COLOMBIA"),
    COMOROS("52", "COMOROS"),
    CONGO("53", "CON"),
    CONGO_DR("54", "CON, THE DEMOCRATIC REPUBLIC OF THE"),
    COOK_ISLANDS("55", "COOK ISLANDS"),
    COSTA_RICA("56", "COSTA RICA"),
    COTE_DIVOIRE("57", "COTE D'IVOIRE"),
    CROATIA("58", "CROATIA"),
    CUBA("59", "CUBA"),
    CURACAO("60", "CURACAO"),
    CYPRUS("61", "CYPRUS"),
    CZECH_REPUBLIC("62", "CZECH REPUBLIC"),
    DENMARK("63", "DENMARK"),
    DJIBOUTI("64", "DJIBOUTI"),
    DOMINICA("65", "DOMINICA"),
    DOMINICAN_REPUBLIC("66", "DOMINICAN REPUBLIC"),
    ECUADOR("67", "ECUADOR"),
    EGYPT("68", "EGYPT"),
    EL_SALVADOR("69", "EL SALVADOR"),
    EQUATORIAL_GUINEA("70", "EQUATORIAL GUINEA"),
    ERITREA("71", "ERITREA"),
    ESTONIA("72", "ESTONIA"),
    ETHIOPIA("73", "ETHIOPIA"),
    FALKLAND_ISLANDS("74", "FALKLAND ISLANDS (MALVINAS)"),
    FAROE_ISLANDS("75", "FAROE ISLANDS"),
    FIJI("76", "FIJI"),
    FINLAND("77", "FINLAND"),
    FRANCE("78", "FRANCE"),
    FRENCH_GUIANA("79", "FRENCH GUIANA"),
    FRENCH_POLYNESIA("80", "FRENCH POLYNESIA"),
    FRENCH_SOUTHERN_TERRITORIES("81", "FRENCH SOUTHERN TERRITORIES"),
    GABON("82", "GABON"),
    GAMBIA("83", "GAMBIA"),
    GEORGIA("84", "GEORGIA"),
    GHANA("85", "GHANA"),
    GIBRALTAR("86", "GIBRALTAR"),
    GREECE("87", "GREECE"),
    GREENLAND("88", "GREENLAND"),
    GRENADA("89", "GRENADA"),
    GUADELOUPE("90", "GUADELOUPE"),
    GUAM("91", "GUAM"),
    GUATEMALA("92", "GUATEMALA"),
    GUERNSEY("93", "GUERNSEY"),
    GUINEA("94", "GUINEA"),
    GUINEA_BISSAU("95", "GUINEA-BISSAU"),
    GUYANA("96", "GUYANA"),
    HAITI("97", "HAITI"),
    HEARD_ISLAND("98", "HEARD ISLAND AND MCDONALD ISLANDS"),
    HOLY_SEE("99", "HOLY SEE (VATICAN CITY STATE)"),
    HONDURAS("100", "HONDURAS"),
    HONG_KONG("101", "HONG KONG"),
    HUNGARY("102", "HUNGARY"),
    ICELAND("103", "ICELAND"),
    INDIA("104", "INDIA"),
    INDONESIA("105", "INDONESIA"),
    IRAN("106", "IRAN (ISLAMIC REPUBLIC OF)"),
    IRAQ("107", "IRAQ"),
    IRELAND("108", "IRELAND"),
    ISLE_OF_MAN("109", "ISLE OF MAN"),
    ISRAEL("110", "ISRAEL"),
    ITALY("111", "ITALY"),
    JAMAICA("112", "JAMAICA"),
    JAPAN("113", "JAPAN"),
    JERSEY("114", "JERSEY"),
    JORDAN("115", "JORDAN"),
    KAZAKHSTAN("116", "KAZAKHSTAN"),
    KENYA("117", "KENYA"),
    KIRIBATI("118", "KIRIBATI"),
    KOREA_DP("119", "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF"),
    KOREA("120", "KOREA, REPUBLIC OF"),
    KUWAIT("121", "KUWAIT"),
    KYRGYZSTAN("122", "KYRGYZSTAN"),
    LAOS("123", "LAO PEOPLE'S DEMOCRATIC REPUBLIC"),
    LATVIA("124", "LATVIA"),
    LEBANON("125", "LEBANON"),
    LESOTHO("126", "LESOTHO"),
    LIBERIA("127", "LIBERIA"),
    LIBYA("128", "LIBYAN ARAB JAMAHIRIYA"),
    LIECHTENSTEIN("129", "LIECHTENSTEIN"),
    LITHUANIA("130", "LITHUANIA"),
    LUXEMBOURG("131", "LUXEMBOURG"),
    MACAO("132", "MACAO"),
    MACEDONIA("133", "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF"),
    MADAGASCAR("134", "MADAGASCAR"),
    MALAWI("135", "MALAWI"),
    MALAYSIA("136", "MALAYSIA"),
    ALAND_ISLANDS("137", "ALAND ISLANDS"),
    MALI("138", "MALI"),
    MALTA("139", "MALTA"),
    MARSHALL_ISLANDS("140", "MARSHALL ISLANDS"),
    MARTINIQUE("141", "MARTINIQUE"),
    MAURITANIA("142", "MAURITANIA"),
    MAURITIUS("143", "MAURITIUS"),
    MAYOTTE("144", "MAYOTTE"),
    MEXICO("145", "MEXICO"),
    MICRONESIA("146", "MICRONESIA, FEDERATED STATES OF"),
    MOLDOVA("147", "MOLDOVA, REPUBLIC OF"),
    MONACO("148", "MONACO"),
    MONGOLIA("149", "MONGOLIA"),
    MONTENEGRO("150", "MONTENEGRO"),
    MONTSERRAT("151", "MONTSERRAT"),
    MOROCCO("152", "MOROCCO"),
    MOZAMBIQUE("153", "MOZAMBIQUE"),
    MYANMAR("154", "MYANMAR"),
    NAMIBIA("155", "NAMIBIA"),
    NAURU("156", "NAURU"),
    NEPAL("157", "NEPAL"),
    NETHERLANDS("158", "NETHERLANDS"),
    NEW_CALEDONIA("159", "NEW CALEDONIA"),
    NEW_ZEALAND("160", "NEW ZEALAND"),
    NICARAGUA("161", "NICARAGUA"),
    NIGER("162", "NIGER"),
    NIGERIA("163", "NIGERIA"),
    NIUE("164", "NIUE"),
    NORFOLK_ISLAND("165", "NORFOLK ISLAND"),
    NORTHERN_MARIANA_ISLANDS("166", "NORTHERN MARIANA ISLANDS"),
    NORWAY("167", "NORWAY"),
    OMAN("168", "OMAN"),
    PAKISTAN("169", "PAKISTAN"),
    PALAU("170", "PALAU"),
    PALESTINE("171", "PALESTINIAN TERRITORY, OCCUPIED"),
    PANAMA("172", "PANAMA"),
    PAPUA_NEW_GUINEA("173", "PAPUA NEW GUINEA"),
    PARAGUAY("174", "PARAGUAY"),
    PERU("175", "PERU"),
    PHILIPPINES("176", "PHILIPPINES"),
    PITCAIRN("177", "PITCAIRN"),
    POLAND("178", "POLAND"),
    PORTUGAL("179", "PORTUGAL"),
    PUERTO_RICO("180", "PUERTO RICO"),
    QATAR("181", "QATAR"),
    REUNION("182", "REUNION"),
    ROMANIA("183", "ROMANIA"),
    RWANDA("184", "RWANDA"),
    SAINT_BARTHELEMY("185", "SAINT BARTHELEMY"),
    SAINT_HELENA("186", "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA"),
    SAINT_KITTS_AND_NEVIS("187", "SAINT KITTS AND NEVIS"),
    SAINT_LUCIA("188", "SAINT LUCIA"),
    SAINT_MARTIN("189", "SAINT MARTIN (FRENCH PART)"),
    SAINT_PIERRE_AND_MIQUELON("190", "SAINT PIERRE AND MIQUELON"),
    SAINT_VINCENT_AND_THE_GRENADINES("191", "SAINT VINCENT AND THE GRENADINES"),
    SAMOA("192", "SAMOA"),
    SAN_MARINO("193", "SAN MARINO"),
    SAO_TOME_AND_PRINCIPE("194", "SAO TOME AND PRINCIPE"),
    SAUDI_ARABIA("195", "SAUDI ARABIA"),
    SENEGAL("196", "SENEGAL"),
    SERBIA("197", "SERBIA"),
    SEYCHELLES("198", "SEYCHELLES"),
    SIERRA_LEONE("199", "SIERRA LEONE"),
    SINGAPORE("200", "SINGAPORE"),
    SINT_MAARTEN("201", "SINT MAARTEN (DUTCH PART)"),
    SLOVAKIA("202", "SLOVAKIA"),
    SLOVENIA("203", "SLOVENIA"),
    SOLOMON_ISLANDS("204", "SOLOMON ISLANDS"),
    SOMALIA("205", "SOMALIA"),
    SOUTH_AFRICA("206", "SOUTH AFRICA"),
    SOUTH_GEORGIA("207", "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"),
    SPAIN("208", "SPAIN"),
    SRI_LANKA("209", "SRI LANKA"),
    SUDAN("210", "SUDAN"),
    SURINAME("211", "SURINAME"),
    SVALBARD_AND_JAN_MAYEN("212", "SVALBARD AND JAN MAYEN"),
    SWAZILAND("213", "SWAZILAND"),
    SWEDEN("214", "SWEDEN"),
    SYRIA("215", "SYRIAN ARAB REPUBLIC"),
    TAIWAN("216", "TAIWAN, PROVINCE OF CHINA"),
    TAJIKISTAN("217", "TAJIKISTAN"),
    TANZANIA("218", "TANZANIA, UNITED REPUBLIC OF"),
    THAILAND("219", "THAILAND"),
    TIMOR_LESTE("220", "TIMOR-LESTE"),
    TOGO("221", "TOGO"),
    TOKELAU("222", "TOKELAU"),
    TONGA("223", "TONGA"),
    TRINIDAD_AND_TOBAGO("224", "TRINIDAD AND TOBAGO"),
    TUNISIA("225", "TUNISIA"),
    TURKEY("226", "TURKEY"),
    TURKMENISTAN("227", "TURKMENISTAN"),
    TURKS_AND_CAICOS("228", "TURKS AND CAICOS ISLANDS"),
    TUVALU("229", "TUVALU"),
    UGANDA("230", "UGANDA"),
    UKRAINE("231", "UKRAINE"),
    UNITED_STATES("232", "UNITED STATES"),
    UNITED_STATES_MINOR_OUTLYING_ISLANDS("233", "UNITED STATES MINOR OUTLYING ISLANDS"),
    URUGUAY("234", "URUGUAY"),
    UZBEKISTAN("235", "UZBEKISTAN"),
    VANUATU("236", "VANUATU"),
    VENEZUELA("237", "VENEZUELA, BOLIVARIAN REPUBLIC OF"),
    VIETNAM("238", "VIET NAM"),
    VIRGIN_ISLANDS_BRITISH("239", "VIRGIN ISLANDS (BRITISH)"),
    VIRGIN_ISLANDS_US("240", "VIRGIN ISLANDs (U.S.)"),
    WALLIS_AND_FUTUNA("241", "WALLIS AND FUTUNA"),
    WESTERN_SAHARA("242", "WESTERN SAHARA"),
    YEMEN("243", "YEMEN"),
    ZAMBIA("244", "ZAMBIA"),
    ZIMBABWE("245", "ZIMBABWE");

}