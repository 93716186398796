package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SpecialSale(
    @SerialName("name")
    val name: String?,
    @SerialName("amount")
    val amount: Float?,
    @SerialName("__typename")
    val typename: String?
)