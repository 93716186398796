package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.TitleEnum

enum class CashRegisterConfigHeaders(override val title: String): TitleEnum
{
    OPERATION(Strings.operation.uppercase()),
    DEVICES(Strings.devices.uppercase()),
    HARDWARE(Strings.hardware.uppercase()),
    GENERAL(Strings.general.uppercase())


}