package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.apple

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.graphics.Image
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config.GetVirtualCardConfig
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.CardHeader
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.CardInfo
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.WalletBarCode
import org.jetbrains.compose.web.css.Color

@Composable
fun AppleCardPreview(info: GetVirtualCardConfig?)
{
    Column(
        modifier = Modifier
            .fillMaxWidth(),
        horizontalAlignment = Alignment.Start
    )
    {
        Row(
            modifier = Modifier
                .padding(Dimen.mediumPadding)
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            Image(
                modifier = Modifier
                    .borderRadius(
                        Dimen.lowBorderRadius
                    ),
                src = info?.wideLogoImg?:Res.PathIcon.gallerySmallRect,
                width = Dimen.galleryRectSmallWidth,
                height = Dimen.galleryRectSmallHeight
            )
            Spacer()
            CardInfo(
                title = Strings.customerNumber,
                value = Strings.fakeCustomerNumber,
                alignStart = false,
                valueFontSize = FontSizes.mediumFontSize,
                titleColor = Color(
                    info?.labelColor?:Theme.GrayAlpha56.hex
                ),
                valueColor = Color(
                    info?.foregroundColor?:Theme.GrayAlpha56.hex
                ),
            )
        }

        Box(
            modifier = Modifier
                .fillMaxWidth()
                .height(Dimen.smallThickness)
                .backgroundColor(Theme.GrayAlpha12.rgb)
        )
    }


    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(Dimen.mediumPadding),

    ){
        CardHeader(
            modifier = Modifier
                .margin(bottom = Dimen.mediumPadding),
            title=Strings.customerName,
        )

        Row(
            modifier = Modifier
                .fillMaxWidth()
        )
        {
            CardInfo(
                title = Strings.balance,
                value = "0000.0",
                valueFontSize = FontSizes.mediumFontSize,
                titleColor = Color(
                    info?.labelColor?:Theme.GrayAlpha56.hex
                ),
                valueColor = Color(
                    info?.foregroundColor?:Theme.GrayAlpha56.hex
                ),
            )

            Spacer()

            CardInfo(
                title = Strings.cashAssistCard,
                value = "0",
                alignStart = false,
                valueFontSize = FontSizes.mediumFontSize,
                titleColor = Color(
                    info?.labelColor?:Theme.GrayAlpha56.hex
                ),
                valueColor = Color(
                    info?.foregroundColor?:Theme.GrayAlpha56.hex
                ),
            )

        }



    }

    Spacer()

    WalletBarCode(
        modifier = Modifier
            .margin(Dimen.mediumPadding),
        textColor = info?.foregroundColor?:
            Theme.GrayAlpha56.hex
    )

}



