package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.product_general_tab

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.GetProduct
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group.SaleItem
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.product_general_tab.component.*
import org.jetbrains.compose.web.css.LineStyle


@Composable
fun ProductTabContent(
    windowSizeMode: WindowSizeModeEnum,
    selectedProduct: GetProduct?,
    selectedSaleGroupId: String?,
    profileCountryId: String,
    salesGroup: List<SaleItem>?,
    selectedVat: String?,
    selectedVat2: String?,
    updateCodeValue: (String) -> Unit,
    updateActivation: (Boolean) -> Unit,
    updateReceiptName: (String) -> Unit,
    updateNoDiscountPossible: (Boolean) -> Unit,
    updateEmployeeNetItems: (Boolean) -> Unit,
    updateEmployeeConsumption: (Boolean) -> Unit,
    getSalesGroupData: () -> Unit,
    getVirtualCardInfo: () -> Unit,
    updateSaleGroup: (String) -> Unit,
    updateVatCode: (String) -> Unit,
    updateVatCode2: (String) -> Unit,
    updatePrice: (List<Double?>) -> Unit,
    updatePriceInCafe: (List<Double?>) -> Unit,
    showImgAIGeneratorDialog: () -> Unit,
    showDataAssistantDialog: () -> Unit,
    getImgUrl:(MutableState<String>, String)->Unit
) {
    LaunchedEffect(Unit){
        getSalesGroupData()
    }

    LaunchedEffect(Unit){
        getVirtualCardInfo()
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .padding(leftRight = Dimen.mediumPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    )
    {
        if (windowSizeMode== WindowSizeModeEnum.Desktop){
            Row(
                modifier = Modifier
                    .margin(bottom = Dimen.largePadding)
            )
            {
                Column() {

                    MediumContentTitle(
                        fontSize = FontSizes.extraExtraLargeFontSize,
                        title = Strings.generalInfo,
                        marginTop = Dimen.mediumPadding,
                        marginBottom = Dimen.mediumPadding,
                    )

                    ProductTabGeneralInfo(
                        salesGroup=salesGroup,
                        selectedProduct=selectedProduct,
                        updateCodeValue={
                            updateCodeValue(it)
                        },
                        updateActivation={
                            updateActivation(it)
                        },
                        updateReceiptName={
                            updateReceiptName(it)
                        },
                        selectedSaleGroupId=selectedSaleGroupId,
                        updateSaleGroup={
                            updateSaleGroup(it)
                        }
                    )

                    ProductTabDiscountInfo(
                        selectedProduct=selectedProduct,
                        updateNoDiscountPossible={
                            updateNoDiscountPossible(it)
                        },
                        updateEmployeeNetItems={
                            updateEmployeeNetItems(it)
                        },
                        updateEmployeeConsumption={
                            updateEmployeeConsumption(it)
                        },
                        windowSizeMode = windowSizeMode

                    )



                    ProductPriceContent(
                        windowSizeMode = windowSizeMode,
                        selectedProduct=selectedProduct,
                        profileCountryId=profileCountryId,
                        updatePrice={
                            updatePrice(it)
                        },
                        updatePriceInCafe = {
                            updatePriceInCafe(it)
                        },
                        selectedVat = selectedVat,
                        selectedVat2 = selectedVat2,
                        updateVatCode = {
                            updateVatCode(it)
                        },
                        updateVatCode2 = {
                            updateVatCode2(it)
                        }
                    )
                }


                CashBidUploadImgContent(
                    cashBidImg =selectedProduct?.img,
                    showImgAIGeneratorDialog={
                        showImgAIGeneratorDialog()
                    },
                    getImageUrl = {imageUrl:MutableState<String>,key:String->
                        getImgUrl(
                            imageUrl,
                            key
                        )
                    }
                )
            }

            ProductTabDataAssistantContent(
                onChatButtonClick = {
                    showDataAssistantDialog()
                }
            )
        }else{
            Column(
                modifier = Modifier
                    .margin(bottom = Dimen.largePadding)
            )
            {
                ProductTabGeneralInfo(
                    salesGroup=salesGroup,
                    selectedSaleGroupId=selectedSaleGroupId,
                    updateCodeValue={
                        updateCodeValue(it)
                    },
                    selectedProduct=selectedProduct,
                    updateActivation={
                        updateActivation(it)
                    },
                    updateReceiptName={
                        updateReceiptName(it)
                    },
                    updateSaleGroup={
                        updateSaleGroup(it)
                    }
                )

                ProductTabDiscountInfo(
                    windowSizeMode=windowSizeMode,
                    selectedProduct=selectedProduct,
                    updateNoDiscountPossible={
                        updateNoDiscountPossible(it)
                    },
                    updateEmployeeNetItems={
                        updateEmployeeNetItems(it)
                    },
                    updateEmployeeConsumption={
                        updateEmployeeConsumption(it)
                    }

                )

                CashBidUploadImgContent(
                    cashBidImg =selectedProduct?.img,
                    showImgAIGeneratorDialog={
                        showImgAIGeneratorDialog()
                    },
                    getImageUrl = {imageUrl:MutableState<String>,key:String->
                        getImgUrl(
                            imageUrl,
                            key
                        )
                    }
                )


                ProductPriceContent(
                    windowSizeMode = windowSizeMode,
                    selectedProduct=selectedProduct,
                    profileCountryId=profileCountryId,
                    updatePrice={
                        updatePrice(it)
                    },
                    updatePriceInCafe = {
                        updatePriceInCafe(it)
                    },
                    selectedVat = selectedVat,
                    selectedVat2 = selectedVat2,
                    updateVatCode = {
                        updateVatCode(it)
                    },
                    updateVatCode2 = {
                        updateVatCode2(it)
                    }
                )


            }
        }



    }
}








