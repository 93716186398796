package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.hardware

enum class KitchenLocationPrintersEnum(
    val title:String,
    val value:Int,
)
{
    EPSON_M30(
        title ="EPSON_M30" ,
        value =0,//TODO("Must Be Fixed)
    ),
    SUNMI_CLOUD_BY_IP(
        title ="SUNMI_CLOUD by IP" ,
        value =0 ,//TODO("Must Be Fixed)
    ),
    SUNMI_CLOUD_BY_SERVER(
        title = "SUNMI_CLOUD by Server",
        value =0 ,//TODO("Must Be Fixed)
    ),
    KITCHEN_DISPLAY_KDS(
        title ="Kitchen Display (KDS)" ,
        value =0 ,//TODO("Must Be Fixed)
    ),

}
