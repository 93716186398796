package org.hs_soft.runmynesto.domain.usecase.options.operation

import org.hs_soft.runmynesto.domain.repository.network_datasource.options.operation.UpdateOptionRepository


class UpdateOptionUseCase
    (private val repository: UpdateOptionRepository) {
    suspend operator fun invoke(
        dataTitle:String,
        dataValue:String,
    ) {
        return repository.updateOption(
            dataTitle = dataTitle,
            dataValue = dataValue
        )
    }
}