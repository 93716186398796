package org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general

import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.update_products_list_position.UpdateProductsPositionModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.UpdateProductsPositionsRepository

class UpdateProductsPositionsUseCase(
    private val repository: UpdateProductsPositionsRepository
) {
    suspend operator fun invoke(
        categoryId: String,
        idsList: List<String>,
        positionsList: List<Int>,
        productIdList: List<String>
    ): UpdateProductsPositionModel {
        return repository.updateProductsPosition(
            categoryId = categoryId,
            idsList=idsList,
            positionsList=positionsList,
            productIdList=productIdList
        )
    }
}