package org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.create_product_addition.ProductAdditionModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.UpdateProductAdditionRepository


class UpdateProductAdditionUseCase(
    private val repository: UpdateProductAdditionRepository
) {
    suspend operator fun invoke(
        id:String,
        name:String,
        variants:List<String?>,
    ): ProductAdditionModel {
        return repository.updateProductAddition(
            id=id,
            name=name,
            variants=variants
        )
    }


}