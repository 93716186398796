package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.product_general_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.GetProduct
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group.SaleItem
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.DropDownItem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun ProductTabGeneralInfo(
    salesGroup: List<SaleItem>?,
    selectedSaleGroupId: String?,
    updateCodeValue: (String) -> Unit,
    selectedProduct: GetProduct?,
    updateActivation: (Boolean) -> Unit,
    updateReceiptName: (String) -> Unit,
    updateSaleGroup: (String) -> Unit
) {
    Column(
        modifier = Modifier.margin(right = Dimen.mediumPadding)
    )
    {
        Row(
            modifier = Modifier
                .width(Dimen.extraLargeEditTextWidth),
            verticalAlignment = Alignment.CenterVertically,
        )
        {
            CustomEditText(
                modifier = Modifier,
                marginRight = Dimen.smallPadding,
                width = Dimen.semiLargeEditTextWidth,
                label = Strings.number,
                onFocusIn = {},
                onFocusOut = {
                    updateCodeValue(it)
                },
                hint = "",
                onInputChange = {

                },
                value = selectedProduct?.code?:"",
                isRequired = false,
                isOutlinedEditText = true,
            )

            Spacer()

            CheckboxComponent(
                modifier = Modifier,
                checkStatus = selectedProduct?.isActive ?: false,
                title = Strings.activate,
                onChecked = {
                    updateActivation(true)
                },
                onUnChecked = {
                    updateActivation(false)
                },
                fontWeight = FontWeight.Medium,

                )
        }

        CustomEditText(
            modifier = Modifier,
            width = Dimen.extraLargeEditTextWidth,
            label = Strings.name,
            value = selectedProduct?.name ?: "",
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {
                updateReceiptName(it)
            },
            onInputChange = {

            },
        )

        DropDownItem(
            modifier = Modifier,
            options = salesGroup ?: listOf(),
            label = Strings.salesGroups,
            itemWidth = Dimen.semiLargeEditTextWidth,
            selectedItem = salesGroup?.find {
                it.id == selectedSaleGroupId
            },
            content = {
                if (it is SaleItem)
                    Text(it.name)
            },
            onOptionClick = {
                val option: SaleItem? = salesGroup?.find { saleItem ->
                    saleItem.toString() == it
                }
                if (option != null)
                    updateSaleGroup(option.id)
            },
        )


    }
}