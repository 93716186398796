package org.hs_soft.runmynesto.data.local_datasource.local_storage

import kotlinx.browser.window


fun removeFromLocalStorage(key: String) {
    window.localStorage.removeItem(key)
}

fun saveToLocalStorage(key: String, value: String) {
    window.localStorage.setItem(key, value)
}

 fun getFromLocalStorage(key: String): String? {
    return window.localStorage.getItem(key)
}