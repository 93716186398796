package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.filtered_transaction


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Data(
    @SerialName("cashAssist")
    val cashAssist: CashAssist?
)