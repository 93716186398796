package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.set_grid_product


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("setGridProduct")
    val setGridProduct: SetGridProduct?,
    @SerialName("__typename")
    val typename: String?
)