package org.hs_soft.runmynesto.pages.home.sub_page.user.mvi

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.model.home.user.UserCancellationReasonEnum
import org.hs_soft.runmynesto.domain.model.home.user.UserInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.user.single_user.GetUser
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.model.home.user.users_info.UserModel
import org.hs_soft.runmynesto.domain.usecase.home.GetSavedCheckOutUseCase
import org.hs_soft.runmynesto.domain.usecase.user.AddUserUseCase
import org.hs_soft.runmynesto.domain.usecase.user.SingleUserUseCase
import org.hs_soft.runmynesto.domain.usecase.user.UpdateUserDataUseCase
import org.hs_soft.runmynesto.domain.usecase.user.UsersDataUseCase
import org.hs_soft.runmynesto.domain.usecase.user.tab_checkout.UpdateUserCheckoutUseCase
import org.hs_soft.runmynesto.domain.usecase.user.tab_general.UpdateUserImageUseCase
import org.hs_soft.runmynesto.domain.usecase.user.tab_right.UpdateUserPermissionUseCase
import org.hs_soft.runmynesto.domain.usecase.user.tab_right.UpdateUserWaiterUseCase
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.handleError
import org.hs_soft.runmynesto.domain.util.helper.BaseViewModel
import org.hs_soft.runmynesto.domain.util.withTokenRefresh

class UserViewModel(
    private val requestManager: RequestManager,
    private val refreshToken:()->Unit,
    private val updateUserCheckoutUseCase: UpdateUserCheckoutUseCase,
    private val updateUserPermissionUseCase: UpdateUserPermissionUseCase,
    private val addUserUseCase: AddUserUseCase,
    private val singleUserUseCase: SingleUserUseCase,
    private val usersDataUseCase: UsersDataUseCase,
    private val updateUserWaiterUseCase: UpdateUserWaiterUseCase,
    private val updateUserImageUseCase: UpdateUserImageUseCase,
    private val updateUserDataUseCase: UpdateUserDataUseCase,
    private val getSavedCheckOutUseCase: GetSavedCheckOutUseCase,
): BaseViewModel()  {

    private val _state = MutableStateFlow(UserState())
    val state: StateFlow<UserState> = _state


    private var tempUsers:List<UserModel> = listOf()
    private var tempCheckoutList:List<Children> ?= listOf()

    override fun clear() {
        super.clear()
        requestManager.cancelAll()
    }


     fun onEvent(event: UserEvent) {
        when (event) {

            //Fetching Data
            is UserEvent.GetUsersData->getUsersData( )

            is UserEvent.OnUserClick ->onUserClick(
                id=event.id,
                switchTheSecondPage=event.callBack,
            )

            is UserEvent.SetFilterItemsComponentStatus->{
                _state.value=_state.value.copy(
                    showItemsFilterComponent = event.status
                )
            }
            is UserEvent.SubmitFilterItems->{
                submitFilterItems(type=state.value.selectedFilterItemsType)
            }
            is UserEvent.SelectFilterType->{
                _state.value=_state.value.copy(selectedFilterItemsType = event.type)
            }


            is UserEvent.UpdateCheckout->{
                updateCheckout(
                    dataValue = event.dataValue,
                    id=state.value.selectedUser?.id?:"",
                )
            }
            is UserEvent.GetCheckoutData->{
                getCheckoutData( )
            }
            is UserEvent.UpdateUserInfo ->updateUserInfo(
                dataTitle = event.dataTitle,
                dataValue = event.dataValue,
                id = state.value.selectedUser?.id?:"",
            )
            is UserEvent.UpdateUserPermissionInfo ->updateUserPermissionInfo(
                dataTitle = event.dataTitle,
                dataValue = event.dataValue,
                id = state.value.selectedUser?.id?:"",
            )
            is UserEvent.UpdateUserWaiterKey ->updateUserWaiterKey(
                dataValue = event.dataValue,
                id = state.value.selectedUser?.id?:"",
            )
            is UserEvent.OnSelectImageClick->updateUserImage(
                url = event.url,
                id=state.value.selectedUser?.id?:"",
            )
            is UserEvent.RefreshUserList ->{
                getUsersData(   )
            }



            is UserEvent.OnSearchCheckoutItems->{
                searchCheckoutItems(value=event.value)
            }

            //Add Record
            is UserEvent.OnAddRecordIconClick->onAddRecordIconClick()
            is UserEvent.OnAddRecordDialogBackgroundClick->{}//dismissAddRecordDialog()
            is UserEvent.OnCancelRecordDialogClick->dismissAddRecordDialog()

            is UserEvent.OnChangeRecordName->onChangeRecordName(event.value)
            is UserEvent.OnChangeRecordNumber->onChangeRecordNumber(event.value)

            is UserEvent.OnSubmitRecordDialogClick->{
                onSubmitRecordDialogClick(
                    windowMode = event.windowMode,
                    name=state.value.newRecordName,
                    code =state.value.newRecordNumber,
                )
            }

            //Cancellation
            is UserEvent.OnCancellationItemClick ->{
                onCancellationItemClick(
                    cancelReason = event.cancelReason,
                    userId = state.value.selectedUser?.id?:"",
                )
            }


            //Header Events
            is UserEvent.OnTabClick->onTabClick(event.tab)

            //Searching User
            is UserEvent.OnSearchUserInputChange ->onSearchUserInputChange(event.value)
            is UserEvent.OnNumberSortClick ->onNumberSortClick()
            is UserEvent.OnTitleSortClick ->onTitleSortClick()


            //Other
            is UserEvent.OnUserInfoDetailPassEyeClick ->onUserInfoDetailPassEyeClick()
            is UserEvent.OnChangePageClick->changePage()


        }
    }

    private fun submitFilterItems(type: GridItemsFilterEnum) {
        when(type){
            GridItemsFilterEnum.ALL_ITEMS->{
                _state.value=_state.value.copy(
                    usersInfo = tempUsers
                )
            }
            GridItemsFilterEnum.ACTIVE_ITEMS->{
                _state.value=_state.value.copy(
                    usersInfo = tempUsers.filter {
                        it.isActive==true
                    }
                )
            }
            GridItemsFilterEnum.INACTIVE_ITEMS->{
                _state.value=_state.value.copy(
                    usersInfo = tempUsers.filter {
                        it.isActive==false
                    }
                )
            }
        }



    }

    private fun updateUserPermissionInfo(
        dataTitle: String,
        dataValue: dynamic,
        id: String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken){
                    showProgress()
                    val result=updateUserPermissionUseCase(
                        dataTitle=dataTitle,
                        dataValue=dataValue,
                        userId = id
                    )
                    hideProgress()
                    val model=result.data.cashAssist.updateUser
                    _state.value=_state.value.copy(
                        selectedUser = state.value.selectedUser
                            ?.copy(
                                cancelationPermissions = model.cancelationPermissions,
                                isDayFinStatements=model.isDayFinStatements,
                                isTimeTracking=model.isTimeTracking,
                                isViewSales=model.isViewSales,
                                isManager=model.isManager,
                                isAdmin=model.isAdmin,
                                isFinStatements =model.isFinStatements

                            )
                    )
                }



            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }


        }

    }

    private fun searchCheckoutItems(value: String)
    {
        _state.value= _state.value.copy(cashList =tempCheckoutList )

        if (value == "") {
            _state.value = _state.value.copy(checkOutExpandAll = false,)
        } else {
            val searchedList = ArrayList<Children>()
            state.value.cashList?.forEach { model ->
                var matchFound = model.name?.lowercase()?.contains(value.lowercase()) ?: false
                model.children?.forEach { childX ->
                    if (childX.name?.lowercase()?.contains(value.lowercase()) == true) {
                        matchFound = true
                    }
                    childX.children?.forEach { childXX ->
                        if (childXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                            matchFound = true
                        }
                        childXX.children.forEach {childXXX->
                            if (childXXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                                matchFound = true
                            }
                        }
                    }
                }
                if (matchFound) {
                    searchedList.add(model)
                }
            }


            _state.value=_state.value.copy(
                checkOutExpandAll = true,
                cashList = searchedList
            )

        }
    }

    private fun updateUserWaiterKey(
        dataValue: String,
        id: String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken){
                    showProgress()
                    updateUserWaiterUseCase(
                        dataValue=dataValue,
                        userId = id
                    )
                    hideProgress()
                    _state.value=_state.value.copy(
                        selectedUser = state.value.selectedUser
                            ?.copy(waiterKey = dataValue)
                    )
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun onTabClick(tab: UserInfoHeaderEnum) {
        _state.value=_state.value.copy(selectedTab = tab)
    }


    private fun onSubmitRecordDialogClick(
        name: String,
        code: String,
        windowMode: WindowSizeModeEnum,
    ) {
        launch {

            try {
                withTokenRefresh(refreshToken){
                    _state.value=_state.value.copy(addRecordLoading = true)
                    val result=addUserUseCase(
                        name = name,
                        code=code
                    )

                    _state.value=_state.value.copy(
                        addRecordLoading = false,
                        showAddRecordDialog = false,
                        addRecordErrorMessage = "",
                        newRecordName = "",
                        newRecordNumber = "",
                        selectedUser = GetUser(
                            id=result.data.cashAssist.createMutation.id,
                            name = result.data.cashAssist.createMutation.name,
                            password = "",
                            isActive = true,
                        ),
                        imgUrl = Res.PathIcon.defaultCirclePhoto,
                        firstPageActive = if(windowMode== WindowSizeModeEnum.PHONE) false
                        else true

                    )
                    getUsersData(   )
                }


            }catch (e:Exception){
                _state.value=_state.value.copy(addRecordLoading = false)
                _state.value=_state.value.copy(
                    addRecordErrorMessage = e.message.toString()
                )
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun onChangeRecordNumber(value: String) {
        _state.value=_state.value.copy(
            newRecordNumber = value
        )
        checkAddRecordSubmitClickable()
    }

    private fun onChangeRecordName(value: String) {
        _state.value=_state.value.copy(
            newRecordName = value
        )
        checkAddRecordSubmitClickable()
    }

    private fun checkAddRecordSubmitClickable(){
        if (
            state.value.newRecordNumber.isNotEmpty()
            && state.value.newRecordName.isNotEmpty()
        ){
            _state.value=_state.value.copy(
                isAddNewRecordSubmitClickable = true
            )
        }else _state.value=_state.value.copy(
            isAddNewRecordSubmitClickable = false
        )
    }

    private fun dismissAddRecordDialog() {
        _state.value=_state.value.copy(
            showAddRecordDialog = false,
            addRecordErrorMessage = ""
        )
    }

    private fun onAddRecordIconClick() {
        _state.value=_state.value.copy(
            showAddRecordDialog = true
        )
    }

    private fun updateUserInfo(
        dataTitle:String,
        dataValue:String,
        id:String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken){
                    showProgress()
                    updateUserDataUseCase(
                        dataValue=dataValue,
                        dataTitle=dataTitle,
                        userId = id
                    )
                    if (dataTitle== ApiUtils.Field.isActive){

                        tempUsers = tempUsers.map { user ->
                            if (user.id == id) {
                                user.copy(isActive = dataValue as Boolean)
                            }
                            else {
                                user
                            }
                        }
                        submitFilterItems(type = state.value.selectedFilterItemsType)
                    }
                    if(dataTitle== ApiUtils.Field.name){
                        getUsersData(  )
                    }else hideProgress()

                    console.log("$dataTitle Successfully changed to $dataValue")
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }
    }


    private fun updateCheckout(
        dataValue:MutableSet<String>,
        id:String,
    ) {
        launch {
            showProgress()
            try {
                withTokenRefresh(refreshToken){
                    val result=updateUserCheckoutUseCase(
                        dataValue = dataValue,
                        userId = id
                    )
                    _state.value=_state.value.copy(
                        selectedUser = state.value.selectedUser?.copy(
                            cashRegisterId = result.data.cashAssist.updateUser.cashRegisterId
                        )
                    )
                    hideProgress()
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }
    }

    private fun updateUserImage(
        url:String,
        id:String,
    ){
        _state.value=_state.value.copy(
            imgUrl = url
        )
        launch {
            try {
                withTokenRefresh(refreshToken){
                    showProgress()
                    updateUserImageUseCase(
                        image =url,
                        id=id,
                        type = ApiUtils.Type.user
                    )

                    hideProgress()
                    console.log("Image Successfully changed")
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }
    }

    private fun getCheckoutData( ) {
        launch {
            if (state.value.cashList==null){

                val result=getSavedCheckOutUseCase()

                _state.value=_state.value.copy(
                    cashList = result?.data?.cashAssist?.children
                )
                tempCheckoutList=state.value.cashList
            }

        }

    }

    private fun onUserClick(
        id: String,
        switchTheSecondPage: () -> Unit={},
    ) {
        if (id!=state.value.selectedUser?.id){
            launch {
                try {
                    withTokenRefresh(refreshToken){
                        showProgress()
                        val selectedUser=singleUserUseCase(id=id).data.cashAssist.getUser

                        val chosenReason= UserCancellationReasonEnum.entries.find {
                            selectedUser.cancelationPermissions==it.id
                        }
                        hideProgress()
                        _state.value=_state.value.copy(
                            selectedUser = selectedUser,
                            imgUrl = selectedUser.img?.url ?: Res.PathIcon.defaultCirclePhoto,
                            cancellationReason = chosenReason?:
                            UserCancellationReasonEnum.NO_AUTHORIZATION,
                        )

                        switchTheSecondPage()

                    }

                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }

            }
        }else switchTheSecondPage()

    }

    private fun getUsersData() {
        launch {
            try {
                withTokenRefresh(refreshToken){
                    showProgress()
                    val result=usersDataUseCase()
                    _state.value=_state.value.copy(
                        usersInfo = result.data.cashAssist.listUsers.items,
                        showNumberSortIcon = false
                    )
                    tempUsers=state.value.usersInfo
                    if(
                        state.value.usersInfo.isNotEmpty()
                        && state.value.selectedUser==null
                    ){
                        onUserClick(
                            id = state.value.usersInfo[0].id,
                        )
                    }else{
                        hideProgress()
                    }
                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }



        }
    }


    private fun changePage() {
        _state.value=_state.value.copy(
            firstPageActive = !_state.value.firstPageActive
        )
    }



    private fun showProgress()
    {
        _state.value=_state.value.copy(
            dataLoadingProgressVisible = true
        )
    }
    private fun hideProgress()
    {
        _state.value=_state.value.copy(
            dataLoadingProgressVisible = false
        )
    }

    private fun onNumberSortClick() {


        val newData:List<UserModel>
        if (_state.value.isNumberSortedAsc) {
            newData=_state.value.usersInfo.sortedByDescending { it.code }
        }else{
            newData=_state.value.usersInfo.sortedBy { it.code }.toList()
        }
        _state.value=_state.value.copy(
            usersInfo = newData,
            isNumberSortedAsc =!_state.value.isNumberSortedAsc,
            showNumberSortIcon = true,
            showTitleSortIcon = false
        )
    }

    private fun onTitleSortClick() {
        val newData:List<UserModel>
        if (_state.value.isNameSortedAsc) {
            newData=_state.value.usersInfo.sortedByDescending { it.name?.lowercase() }
        }else{
            newData=_state.value.usersInfo.sortedBy { it.name?.lowercase() }.toList()
        }
        _state.value=_state.value.copy(
            usersInfo = newData,
            isNameSortedAsc =!_state.value.isNameSortedAsc,
            showTitleSortIcon = true,
            showNumberSortIcon = false
        )
    }

    private fun onSearchUserInputChange(value: String) {
        submitFilterItems(state.value.selectedFilterItemsType)



        val newData=_state.value.usersInfo.filter {model ->
            (
                    model.name?.contains(value, ignoreCase = true) == true ||
                    model.code?.contains(value, ignoreCase = true) == true
            )
        }
        _state.value=_state.value.copy(
            usersInfo = newData
        )

    }
//    private fun defaultUserSorting(data: List<UserModel>): List<UserModel> {
//        val filteredData = data.filter { it.code.toString().length == Constants.dataDigitLimit }
//
//        val newData: List<UserModel>
//        if (state.value.showTitleSortIcon && !state.value.showNumberSortIcon) {
//            newData = if (!state.value.isNameSortedAsc) {
//                filteredData.sortedByDescending { it.name?.lowercase() }
//            } else {
//                filteredData.sortedBy { it.name?.lowercase() }
//            }
//        } else if (state.value.showNumberSortIcon && !state.value.showTitleSortIcon) {
//            newData = if (!state.value.isNumberSortedAsc) {
//                filteredData.sortedByDescending { it.code }
//            } else {
//                filteredData.sortedBy { it.code }
//            }
//        } else {
//            newData = filteredData
//        }
//        return newData
//    }

    private fun onUserInfoDetailPassEyeClick() {
        _state.value=_state.value.copy(
            userInfoDetailPasswordVisibility =
                !_state.value.userInfoDetailPasswordVisibility
        )
    }

    private fun onCancellationItemClick(
        cancelReason: dynamic,
        userId:String,
    ) {

        console.log(cancelReason)
        val chosenReason= UserCancellationReasonEnum.entries.find {
            cancelReason==it.name
        }
        _state.value=_state.value.copy(
            cancellationReason = chosenReason ?:
                UserCancellationReasonEnum.NO_AUTHORIZATION
        )

        updateUserPermissionInfo(
            id = userId,
            dataTitle = ApiUtils.Field.cancellationPermissions,
            dataValue =chosenReason?.id?:"",
        )
    }



}