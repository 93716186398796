package org.hs_soft.runmynesto.data.api_datasource.dashboard

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.dashboard.customer_frequency.CustomerFrequencyModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.CustomerFrequencyRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils


class CustomerFrequencyRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): CustomerFrequencyRepository {

    override suspend fun getCustomerFrequency(
        cashRegisterIds:List<String>,
        periodStart:String,
        periodEnd:String,
    ): CustomerFrequencyModel
    {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """
                   query getCustomerFrequencies(
                    ${'$'}${ApiUtils.Field.periodStart}: Date!, 
                    ${'$'}${ApiUtils.Field.periodEnd}: Date!,
                    ${'$'}${ApiUtils.Field.cashRegisterIds}: [ID!], 
                    ${'$'}${ApiUtils.Field.timeCheck}: Boolean = false, 
                    ${'$'}${ApiUtils.Field.interval}: Int = 60
                ) {
                    ${ApiUtils.Field.cashAssist} {
                        ${ApiUtils.Field.getCustomerFrequencies}(
                            params: {
                                ${ApiUtils.Field.periodStart}: ${'$'}${ApiUtils.Field.periodStart},
                                ${ApiUtils.Field.periodEnd}: ${'$'}${ApiUtils.Field.periodEnd}, 
                                ${ApiUtils.Field.cashRegisterIds}: ${'$'}${ApiUtils.Field.cashRegisterIds}, 
                                ${ApiUtils.Field.timeCheck}: ${'$'}${ApiUtils.Field.timeCheck}, 
                                ${ApiUtils.Field.timeStart}: "00:00:00Z",
                                ${ApiUtils.Field.timeEnd}: "21:00:00Z", 
                                ${ApiUtils.Field.interval}: ${'$'}${ApiUtils.Field.interval}
                            }
                        ) {
                            ...${ApiUtils.Field.CustomerFrequenciesFragment}
                            ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.__typename}
                    }
                }
            
                fragment ${ApiUtils.Field.CustomerFrequenciesFragment} on 
                    ${ApiUtils.Field.CustomerFrequencies} {
                    ${ApiUtils.Field.total}
                    ${ApiUtils.Field.billCount}
                    ${ApiUtils.Field.totalK}
                    ${ApiUtils.Field.creditClientCount}
                    ${ApiUtils.Field.creditClientTotal}
                    ${ApiUtils.Field.creditClient}
                    ${ApiUtils.Field.dateTime}
                    ${ApiUtils.Field.interval}
                    ${ApiUtils.Field.shopId}
                    ${ApiUtils.Field.__typename}
                }

                """.trimIndent()
            )

            putJsonObject("variables") {
                put("cashRegisterIds",
                    Json.encodeToJsonElement(cashRegisterIds as List<String>)
                )
                put("periodStart",periodStart)
                put("periodEnd",periodEnd)
                put("timeCheck",true)

            }


        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }


}

