package org.hs_soft.runmynesto.domain.usecase.statistic.abs_statistic

import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.abc_statistic_data.ABCStatisticDataModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.abc_statistic.ABCStatisticDataRepository

class ABCStatisticDataUseCase(
    private val repository: ABCStatisticDataRepository
) {
    suspend operator fun invoke(
        requestId:String
    ): ABCStatisticDataModel {

        return repository.getABCStatisticData(
            requestId=requestId
        )
    }


}