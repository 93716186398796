package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.color
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.LoyaltyCardTypeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.TelephoneCodeEnum
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.DropdownComponent
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.RadioGroup
import org.jetbrains.compose.web.dom.RadioInput
import org.jetbrains.compose.web.dom.Text

@Composable
fun AddNewCustomerCardDialog(
    onBackgroundClick:()->Unit,
    onCancelClick:()->Unit,
    onSubmitClick:(cardType:LoyaltyCardTypeEnum, String, String)->Unit,
    loading:Boolean,
)
{
    val selectedLoyaltyCardType= remember { mutableStateOf(LoyaltyCardTypeEnum.RFID) }
    val selectedCountryCode = remember { mutableStateOf(TelephoneCodeEnum.SWITZERLAND) }
    val phoneNumber= remember { mutableStateOf("") }
    val cardName= remember { mutableStateOf("") }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                onBackgroundClick()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(2f)
    ){

        Column(
            modifier = Modifier
                .onClick {
                        event: SyntheticMouseEvent -> event.stopPropagation()
                }
                .align(Alignment.Center)
                .width(Dimen.addNewCustomerWidth)
                .height(Dimen.addRecordDialogHeight)
                .backgroundColor(Theme.White.rgb)
                .zIndex(1)
                .boxShadow(
                    color = Theme.GrayCD.rgb,
                    blurRadius = Dimen.smallBlurRadius,
                )
                .borderRadius(r= Dimen.lowBorderRadius),
            horizontalAlignment = Alignment.CenterHorizontally
        ){

            DialogHeader()

            Column(
                horizontalAlignment = Alignment.Start,
            ){
                CustomEditText(
                    modifier = Modifier ,
                    label =Strings.cardName,
                    value ="",
                    hasLabelSpace = false,
                    marginBottom = Dimen.smallPadding,
                    isRequired = selectedLoyaltyCardType.value==LoyaltyCardTypeEnum.DIGITAL,
                    hint = "",
                    onFocusIn = {},
                    onFocusOut = {

                    },
                    onInputChange = {
                        cardName.value=it
                    },
                )


                TelephoneInfo(
                    selectedCountryCode=selectedCountryCode,
                    selectedCustomerCardType=selectedLoyaltyCardType,
                    phoneNumber=phoneNumber
                )


                ChooseCustomerCardType(
                    selectedCustomerCardType=selectedLoyaltyCardType
                )

                Row(
                    modifier = Modifier
                        .margin(top = Dimen.normalPadding)
                )
                {
                    CustomButton(
                        modifier = Modifier,
                        title = Strings.cancel,
                        width = Dimen.normalCustomerButtonWidth,
                        hoveredBackgroundColor = Theme.Red.rgb,
                        backgroundColor = Theme.White.rgb,
                        shadowColor = Theme.GrayCD.rgb,
                        borderColor = Theme.Red.rgb,
                        defaultTextColor = Theme.Secondary.rgb,
                        clickableTextColor = Theme.Secondary.rgb,
                        onButtonClick = {
                            onCancelClick()
                        }

                    )


                    CustomButton(
                        modifier = Modifier,
                        title = Strings.submit,
                        width = Dimen.normalCustomerButtonWidth,
                        isClickable = isButtonClickable(
                            selectedLoyaltyCardType=selectedLoyaltyCardType,
                            phoneNumber=phoneNumber,
                            cardName=cardName
                        )
                        ,
                        hoveredBackgroundColor = Theme.DarkBlue.rgb,
                        shadowColor = Theme.GrayCD.rgb,
                        defaultTextColor = Theme.Secondary.rgb,
                        onButtonClick = {
                            onSubmitClick(
                                selectedLoyaltyCardType.value,
                                cardName.value,
                                "${selectedCountryCode.value.code}${phoneNumber.value}",
                            )
                        }

                    )
                }
            }




        }


        if (loading){
            LoadingProgress(
                visibility = loading,
                modifier = Modifier
                    .zIndex(2)
                    .borderRadius(
                        Dimen.lowBorderRadius
                    )
                , backgroundColor = Theme.Transparent.rgb
            )
        }




    }




}

private fun isButtonClickable(
    selectedLoyaltyCardType: MutableState<LoyaltyCardTypeEnum>,
    phoneNumber: MutableState<String>,
    cardName: MutableState<String>
) = if (selectedLoyaltyCardType.value == LoyaltyCardTypeEnum.RFID) {
    (phoneNumber.value.isNotEmpty())
} else {
    (phoneNumber.value.isNotEmpty() && cardName.value.isNotEmpty())
}

@Composable
private fun DialogHeader() {

    Column(
        modifier = Modifier
            .fillMaxWidth()
    )
    {
        SpanText(
            text =Strings.addNewCustomerCard ,
            modifier = Modifier
                .margin(
                    top = Dimen.normalPadding,
                    left = Dimen.mediumPadding
                )
                .fontWeight(FontWeight.Medium)
                .fontSize(FontSizes.mediumFontSize)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Montserrat)
        )

        Box(
            modifier = Modifier
                .fillMaxWidth()
                .margin(topBottom = Dimen.normalPadding)
                .backgroundColor(Theme.GrayCD.rgb)
                .height(Dimen.smallThickness)
        )

    }

}

@Composable
private fun TelephoneInfo(
    selectedCountryCode: MutableState<TelephoneCodeEnum>,
    selectedCustomerCardType: MutableState<LoyaltyCardTypeEnum>,
    phoneNumber: MutableState<String>
) {
    Row(
        verticalAlignment = Alignment.CenterVertically
    ) {
        DropdownComponent(
            modifier = Modifier.margin(
                bottom = Dimen.normalPadding,
                right = Dimen.normalPadding
            ),
            itemWidth = Dimen.extraSmallEditTextWidth,
            options = TelephoneCodeEnum.entries,
            label = Strings.telephone,
            selectedItem =
                TelephoneCodeEnum.entries.find {
                    it.code == selectedCountryCode.value.code
                }?:TelephoneCodeEnum.SWITZERLAND,
            content = {
                if (it is TelephoneCodeEnum)
                    Text(it.code)
            },
            onOptionClick = {
                val option: TelephoneCodeEnum? = TelephoneCodeEnum.entries.find { item ->
                    item.toString() == it
                }
                if (option != null) {
                    selectedCountryCode.value = option
                }

            }
        )
        CustomEditText(
            modifier = Modifier,
            label = "",
            width = Dimen.highNormalEditTextWidth,
            value = "",
            isDecimal = true,
            marginBottom = Dimen.smallPadding,
            isRequired = selectedCustomerCardType.value == LoyaltyCardTypeEnum.DIGITAL,
            hint = "",
            onFocusIn = {

            },
            onFocusOut = {},
            onInputChange = {
               phoneNumber.value=it
            },
        )
    }
}



@OptIn(ExperimentalComposeWebApi::class)
@Composable
private fun ChooseCustomerCardType(
    selectedCustomerCardType: MutableState<LoyaltyCardTypeEnum>
) {
    Column()
    {
        RadioGroup(selectedCustomerCardType.value.title) {
            LoyaltyCardTypeEnum.entries.forEach { filter ->
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.margin(topBottom = Dimen.smallPadding)
                )
                {

                    RadioInput(
                        value = filter.title,
                        attrs = Modifier
                            .padding(Dimen.normalPadding)
                            .toAttrs {
                                style {
                                    color(Theme.Secondary.hex)
                                    width(Dimen.mediumRadioButton)
                                    height(Dimen.mediumRadioButton)
                                }
                                onClick {
                                    if (filter != selectedCustomerCardType.value)
                                        selectedCustomerCardType.value = filter
                                }
                                checked(filter == selectedCustomerCardType.value)

                            }
                    )
                    SpanText(
                        text = filter.title,
                        modifier = Modifier
                            .margin(leftRight = Dimen.normalPadding)
                            .fontSize(FontSizes.semiMediumFontSize)
                            .color(Theme.Secondary.rgb)
                            .fontWeight(FontWeight.Medium)
                            .fontFamily(FontFamily.Montserrat)
                    )
                }


            }
        }
    }
}