package org.hs_soft.runmynesto.domain.usecase.home

import org.hs_soft.runmynesto.domain.model.home.home.hssoft_apps_list.HSSoftAppsModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.home.HSSoftAppsRepository

class HSSoftAppsUseCase(
    private val repository: HSSoftAppsRepository
) {
    suspend operator fun invoke():HSSoftAppsModel
    {
        return repository.getHSSoftApps()
    }
}