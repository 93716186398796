package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class User(
    @SerialName("averageBill")
    val averageBill: Double?,
    @SerialName("buttonBackCount")
    val buttonBackCount: Double?,
    @SerialName("buttonDelAmount")
    val buttonDelAmount: Double?,
    @SerialName("buttonDelCount")
    val buttonDelCount: Double?,
    @SerialName("buttonOpenCount")
    val buttonOpenCount: Double?,
    @SerialName("cancellationsAmount")
    val cancellationsAmount: Double?,
    @SerialName("cancellationsCount")
    val cancellationsCount: Double?,
    @SerialName("clientCount")
    val clientCount: Double?,
    @SerialName("id")
    val id: String?,
    @SerialName("inCafeAmount")
    val inCafeAmount: Double?,
    @SerialName("inCafeCount")
    val inCafeCount: Double?,
    @SerialName("inCafePercent")
    val inCafePercent: Double?,
    @SerialName("total")
    val total: Double?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("user")
    val user: UserX?,
    @SerialName("userEfficiency")
    val userEfficiency: Double?
)