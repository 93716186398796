package org.hs_soft.runmynesto.data.local_datasource.local_storage.product

import kotlinx.browser.window
import kotlinx.serialization.json.Json
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.GetCachedProductCategoriesRepository
import org.hs_soft.runmynesto.domain.util.IdUtils

class GetCachedProductCategoriesRepositoryImpl: GetCachedProductCategoriesRepository
{
    override fun getProductCategories(callback: (List<ProductItem>?) -> Unit) {
        val jsonString = window.localStorage.getItem(IdUtils.productsDB)
        callback(jsonString?.let { Json.decodeFromString(it) })
        
    }

}