package org.hs_soft.runmynesto.pages.components.top_toolbar.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.overflowText
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.home.hssoft_apps_list.HSWebApps
import org.hs_soft.runmynesto.domain.model.home.home.user_profile_info.GetUserProfileData
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.openInNewTab
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.hs_soft.runmynesto.pages.components.dialog.dialog.ApplicationsDialog
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun TopToolbarUserInfo(
    windowSizeMode: WindowSizeModeEnum,
    profileInfo: GetUserProfileData?,
    showApplications: Boolean,
    showSearchBarInMobileMode: Boolean,
    showSettingDialog: Boolean,
    listOfHSSoftApps: List<HSWebApps?>?,
    isMenuExpandedHorizontally: Boolean,
    changeShowingSearchBarInMobileStatus: () -> Unit,
    onToolbarSettingIconClick: () -> Unit,
    onToolbarApplicationMenuIconClick: () -> Unit,
    closeMenu:()->Unit,
)
{
    val isSettingHovered= remember { mutableStateOf(false) }
    val isApplicationButtonHovered= remember { mutableStateOf(false) }
    val searchValue= remember { mutableStateOf("") }


    Column(
        modifier = Modifier
            .fillMaxHeight(),
            horizontalAlignment = Alignment.Start,
    )
    {
        Row(
            modifier = Modifier
                .fillMaxHeight()
                .onClick {
                    closeMenu()
                }
                .minHeight(Dimen.topToolbarHeight)
                .zIndex(
                    if(isMenuExpandedHorizontally)
                        Constants.ZIndex.largeZIndex
                    else Constants.ZIndex.extraLargeZIndex
                )
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.End
        )
        {
            when(windowSizeMode)
            {
                WindowSizeModeEnum.Desktop->{

                    WindowToolbarUser(
                        searchValue=searchValue,
                        showApplications = showApplications,
                        profileInfo = profileInfo,
                        isApplicationButtonHovered = isApplicationButtonHovered,
                        showSettingDialog = showSettingDialog,
                        isSettingHovered = isSettingHovered,
                        onToolbarApplicationMenuIconClick ={
                            onToolbarApplicationMenuIconClick()
                        } ,
                        onCloseSearchBarIconClick ={
                            searchValue.value=""
                        } ,
                        onToolbarSettingIconClick ={
                            onToolbarSettingIconClick()
                        }
                    )


                }

                WindowSizeModeEnum.TABLET->{

                    TabletToolbarUser(
                        searchValue = searchValue,
                        showSearchBarInMobileMode=showSearchBarInMobileMode,
                        showApplications = showApplications,
                        profileInfo = profileInfo,
                        isApplicationButtonHovered = isApplicationButtonHovered,
                        onToolbarApplicationMenuIconClick ={
                            onToolbarApplicationMenuIconClick()
                        } ,
                        changeShowingSearchBarInMobileStatus ={
                            searchValue.value=""
                            changeShowingSearchBarInMobileStatus()
                        } ,
                    )


                }
                WindowSizeModeEnum.PHONE->{


                    PhoneToolbarUser(
                        showSearchBarInMobileMode=showSearchBarInMobileMode,
                        searchValue = searchValue,
                        profileInfo=profileInfo,
                        showApplications=showApplications,
                        isMenuExpandedHorizontally=isMenuExpandedHorizontally,
                        isApplicationButtonHovered=isApplicationButtonHovered,
                        onToolbarApplicationMenuIconClick={
                            onToolbarApplicationMenuIconClick()
                        },
                        changeShowingSearchBarInMobileStatus ={
                            searchValue.value=""
                            changeShowingSearchBarInMobileStatus()
                        } ,
                    )


                }


            }


        }



        if (showApplications && windowSizeMode!=WindowSizeModeEnum.PHONE){

            ApplicationsDialog(
                modifier = Modifier
                    .margin(
                        right =when(windowSizeMode){
                            WindowSizeModeEnum.Desktop->0.px
                            WindowSizeModeEnum.TABLET ->Dimen.applicationsDialogMarginRightInTablet
                            WindowSizeModeEnum.PHONE ->Dimen.largePadding
                        },
                        top = Dimen.smallPadding,
                    ),
                windowSizeMode=windowSizeMode,
                listOfHSSoftApps=listOfHSSoftApps,
                onItemClick = {
                    openInNewTab(it)
                }
            )


        }


    }


}

@Composable
private fun PhoneToolbarUser(
    showSearchBarInMobileMode: Boolean,
    searchValue: MutableState<String>,
    profileInfo: GetUserProfileData?,
    showApplications: Boolean,
    isMenuExpandedHorizontally: Boolean,
    isApplicationButtonHovered: MutableState<Boolean>,
    changeShowingSearchBarInMobileStatus: () -> Unit,
    onToolbarApplicationMenuIconClick: () -> Unit,
) {
    val searchHasFocus= remember { mutableStateOf(true) }
    LaunchedEffect(showSearchBarInMobileMode){
        searchHasFocus.value=true
    }


    Box(
        modifier = Modifier
            .fillMaxWidth()
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .zIndex(Constants.ZIndex.extraLargeZIndex)
                .attrsModifier {
                    style {
                        property("transition",
                            "all ${Constants.fastExpandingTime}s ease-in-out")
                        property("max-width",
                            if (showSearchBarInMobileMode && !isMenuExpandedHorizontally) "100%" else "0%")
                        property("overflow", "hidden")
                    }
                },
            verticalAlignment = Alignment.CenterVertically

        ) {
            CustomEditText(
                modifier = Modifier,
                value = searchValue.value,
                height = Dimen.normalOutlinedEditTextHeight,
                marginRight = Dimen.mediumPadding,
                marginTop = Dimen.smallPadding,
                hasLabelSpace = false,
                label = "",
                isRequired = false,
                defaultBorderColor = Theme.GrayCD.rgb,
                hint = Strings.globalSearch,
                initialFocus = true,
                onFocusIn = {
                    searchHasFocus.value = true
                },
                onFocusOut = {
                    searchHasFocus.value = false
                },
                onInputChange = {
                    searchValue.value = it
                },
                endContent = {
                    SearchBarEndContent(
                        isValueEmpty = searchValue.value.isEmpty(),
                        showCloseInEmptyMode = true,
                        showArrowInFillMode = false,
                        onCloseSearchBarIconClick = {
                            changeShowingSearchBarInMobileStatus()
                        }
                    )
                }
            )
        }

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight()
                .attrsModifier {
                    style {
                        property("transition",
                            "all ${Constants.fastExpandingTime}s ease-in-out")
                        property("opacity",
                            if (!showSearchBarInMobileMode) "1" else "0")
                        property("visibility",
                            if (!showSearchBarInMobileMode) "visible" else "hidden")
                    }
                },
            horizontalArrangement = Arrangement.End,
            verticalAlignment = Alignment.CenterVertically
        ) {
            // Search Icon
            Box(
                modifier = Modifier
                    .padding(Dimen.smallPadding)
                    .onClick {
                        changeShowingSearchBarInMobileStatus()
                    }
            ) {
                VectorIcon(
                    modifier = Modifier,
                    color = Theme.GrayAlpha56.hex,
                    pathData = Res.PathIcon.searchBox,
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                )
            }

            // Apps Menu Icon
            Box(
                modifier = Modifier
                    .transitionBackground()
                    .borderRadius(50.percent)
                    .backgroundColor(
                        if (showApplications)
                            Theme.GrayAlpha12.rgb
                        else if (isApplicationButtonHovered.value)
                            Theme.GrayAlpha04.rgb
                        else Theme.Transparent.rgb
                    )
                    .margin(leftRight = Dimen.largePadding)
                    .padding(Dimen.normalPadding)
                    .onMouseOver {
                        isApplicationButtonHovered.value = true
                    }
                    .onMouseLeave {
                        isApplicationButtonHovered.value = false
                    }
                    .onClick {
                        onToolbarApplicationMenuIconClick()
                    }
            ) {
                VectorIcon(
                    modifier = Modifier,
                    color = Theme.GrayAlpha56.hex,
                    pathData = Res.PathIcon.appsMenu,
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                )
            }

            // Profile Avatar
            if (profileInfo?.avatarUrl == null) {
                Box(
                    modifier = Modifier
                        .borderRadius(50.percent)
                        .size(Dimen.semiLargeIconSize2.px)
                        .backgroundColor(Theme.GrayAlpha04.rgb)
                ) {
                    VectorIcon(
                        modifier = Modifier
                            .align(Alignment.Center),
                        color = Theme.GrayAlpha56.hex,
                        pathData = Res.PathIcon.user,
                        width = Dimen.mediumIconSize,
                        height = Dimen.mediumIconSize,
                    )
                }
            } else {
                VectorIcon(
                    modifier = Modifier,
                    pathData = profileInfo.avatarUrl,
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                )
            }
        }
    }
}


@Composable
private fun TabletToolbarUser(
    searchValue: MutableState<String>,
    showApplications: Boolean,
    profileInfo: GetUserProfileData?,
    isApplicationButtonHovered: MutableState<Boolean>,
    showSearchBarInMobileMode:Boolean,
    onToolbarApplicationMenuIconClick: () -> Unit,
    changeShowingSearchBarInMobileStatus:()->Unit,
) {

    val searchHasFocus= remember { mutableStateOf(true) }
    LaunchedEffect(showSearchBarInMobileMode){
        searchHasFocus.value=true
    }

    if (showSearchBarInMobileMode) {

        CustomEditText(
            modifier = Modifier
                .zIndex(Constants.ZIndex.extraLargeZIndex),
            value = searchValue.value,
            height = Dimen.normalOutlinedEditTextHeight,
            marginTop = Dimen.smallPadding,
            hasLabelSpace = false,
            label = "",
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = Strings.globalSearch,
            initialFocus =true,
            onFocusIn = {
                searchHasFocus.value=true
            },
            onFocusOut = {
                searchHasFocus.value=false
            },
            onInputChange = {
                searchValue.value = it
            },
            endContent = {
                SearchBarEndContent(
                    isValueEmpty=searchValue.value.isEmpty(),
                    onCloseSearchBarIconClick={
                        changeShowingSearchBarInMobileStatus()
                    }
                )
            }
        )
    }

    if (!showSearchBarInMobileMode) {
        Box(
            modifier = Modifier
                .zIndex(Constants.ZIndex.extraLargeZIndex)
                .padding(Dimen.smallPadding)
                .margin(right = Dimen.smallPadding)
                .onClick {
                    changeShowingSearchBarInMobileStatus()
                }
        )
        {
            VectorIcon(
                modifier = Modifier,
                color = Theme.GrayAlpha56.hex,
                pathData = Res.PathIcon.searchBox,
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
            )
        }

    }


    Box(
        modifier = Modifier
            .transitionBackground()
            .borderRadius(50.percent)
            .backgroundColor(
                if (showApplications)
                    Theme.GrayAlpha12.rgb
                else if (isApplicationButtonHovered.value)
                    Theme.GrayAlpha04.rgb
                else Theme.Transparent.rgb
            )
            .margin(leftRight = Dimen.largePadding)
            .padding(Dimen.normalPadding)
            .onMouseOver {
                isApplicationButtonHovered.value = true
            }
            .onMouseLeave {
                isApplicationButtonHovered.value = false
            }
            .onClick {
                onToolbarApplicationMenuIconClick()
            }
    )
    {
        VectorIcon(
            modifier = Modifier,
            color = Theme.GrayAlpha56.hex,
            pathData = Res.PathIcon.appsMenu,
            width = Dimen.mediumIconSize,
            height = Dimen.mediumIconSize,
        )
    }


    if (profileInfo?.avatarUrl == null) {
        Box(
            modifier = Modifier
                .borderRadius(50.percent)
                .size(Dimen.semiLargeIconSize2.px)
                .backgroundColor(Theme.GrayAlpha04.rgb)
        )
        {
            VectorIcon(
                modifier = Modifier
                    .align(Alignment.Center),
                color = Theme.GrayAlpha56.hex,
                pathData = Res.PathIcon.user,
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
            )
        }


    } else {
        VectorIcon(
            modifier = Modifier,
            pathData = profileInfo.avatarUrl,
            width = Dimen.mediumIconSize,
            height = Dimen.mediumIconSize,
        )

    }
}

@Composable
private fun WindowToolbarUser(
    showSettingDialog: Boolean,
    showApplications: Boolean,
    profileInfo: GetUserProfileData?,
    searchValue: MutableState<String>,
    isApplicationButtonHovered: MutableState<Boolean>,
    isSettingHovered: MutableState<Boolean>,
    onCloseSearchBarIconClick: () -> Unit,
    onToolbarApplicationMenuIconClick: () -> Unit,
    onToolbarSettingIconClick: () -> Unit,
) {
    val isFocused= remember { mutableStateOf(false) }
    Box(
        modifier = Modifier
            .width(Dimen.paddingOfApplicationDialog)
    )

    CustomEditText(
        modifier = Modifier
            .zIndex(Constants.ZIndex.extraLargeZIndex),
        value = searchValue.value,
        height = Dimen.normalEditTextHeight,
        marginRight = Dimen.mediumPadding,
        marginTop = Dimen.smallPadding,
        hasLabelSpace = false,
        label = "",
        isRequired = false,
        defaultBorderColor = Theme.GrayCD.rgb,
        hint = Strings.globalSearch,
        onFocusIn = {
            isFocused.value=true
        },
        onFocusOut = {
             isFocused.value=false
        },
        onInputChange = {
            searchValue.value = it
        },
        endContent = {
            SearchBarEndContent(
                isValueEmpty=searchValue.value.isEmpty(),
                onCloseSearchBarIconClick={
                    onCloseSearchBarIconClick()
                }
            )

        }
    )


    Row(
        modifier = Modifier
//            .minWidth(Dimen.topToolbarUserInfoWidth)
            .margin(left = Dimen.mediumPadding),
        verticalAlignment = Alignment.CenterVertically
    )
    {
        Box(
            modifier = Modifier
                .transitionBackground()
                .borderRadius(50.percent)
                .backgroundColor(
                    if (showApplications)
                        Theme.GrayAlpha12.rgb
                    else if (isApplicationButtonHovered.value)
                        Theme.GrayAlpha04.rgb
                    else Theme.Transparent.rgb
                )
                .margin(right = Dimen.mediumPadding)
                .padding(Dimen.smallPadding)
                .onMouseOver {
                    isApplicationButtonHovered.value = true
                }
                .onMouseLeave {
                    isApplicationButtonHovered.value = false
                }
                .onClick {
                    onToolbarApplicationMenuIconClick()
                }
        )
        {
            VectorIcon(
                modifier = Modifier,
                color = Theme.GrayAlpha56.hex,
                pathData = Res.PathIcon.appsMenu,
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
            )
        }


        if (profileInfo?.avatarUrl == null) {
            Box(
                modifier = Modifier
                    .borderRadius(50.percent)
                    .size(Dimen.semiLargeIconSize2.px)
                    .backgroundColor(Theme.GrayAlpha04.rgb)
            )
            {
                VectorIcon(
                    modifier = Modifier
                        .align(Alignment.Center),
                    color = Theme.GrayAlpha56.hex,
                    pathData = Res.PathIcon.user,
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                )
            }


        } else {
            VectorIcon(
                modifier = Modifier,
                pathData = profileInfo.avatarUrl,
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
            )

        }


        Column(
            modifier = Modifier
//                .minWidth(Dimen.topToolbarUserNameWidth)
                .margin(leftRight = Dimen.largePadding)
//                .padding(right = Dimen.mediumPadding)
        ) {
            SpanText(
                text = profileInfo?.name ?: "",
                modifier = Modifier
                    .fontSize(FontSizes.mediumFontSize)
                    .overflowText()
                    .color(Theme.Primary.rgb)
                    .fontWeight(FontWeight.Medium)
                    .fontFamily(FontFamily.Jakarta)

            )
            SpanText(
                text =profileInfo?.company ?: "",
                modifier = Modifier
                    .fontSize(FontSizes.semiMediumFontSize)
                    .overflowText()
                    .color(Theme.GrayAlpha56.rgb)
                    .fontWeight(FontWeight.Normal)
                    .fontFamily(FontFamily.Jakarta)
            )
        }

        Spacer()

        Box(
            modifier = Modifier
                .transitionBackground()
                .borderRadius(50.percent)
                .backgroundColor(
                    if (showSettingDialog)
                        Theme.GrayAlpha12.rgb
                    else if (isSettingHovered.value)
                        Theme.GrayAlpha04.rgb
                    else Theme.Transparent.rgb
                )
                .onMouseOver {
                    isSettingHovered.value = true
                }
                .onMouseLeave {
                    isSettingHovered.value = false
                }
                .onClick {
                    onToolbarSettingIconClick()
                }
                .padding(Dimen.smallPadding)
        ) {
            VectorIcon(
                modifier = Modifier
                    .align(Alignment.Center),
                color = Theme.GrayAlpha56.hex,
                pathData = Res.PathIcon.exit2,
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
            )
        }
    }




}


@Composable
private fun SearchBarEndContent(
    isValueEmpty: Boolean,
    showCloseInEmptyMode:Boolean=false,
    showArrowInFillMode:Boolean=true,
    onCloseSearchBarIconClick: () -> Unit,
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
    )
    {
        if (isValueEmpty){

            if (showCloseInEmptyMode){
                VectorIcon(
                    modifier = Modifier
                        .onClick {
                            onCloseSearchBarIconClick()
                        },
                    pathData = Res.PathIcon.mediumClose,
                    width = Dimen.normalIconSize,
                    height = Dimen.normalIconSize,
                    color = Theme.GrayAlpha56.hex
                )

            }

            VectorIcon(
                modifier = Modifier
                    .onClick {

                    },
                pathData = Res.PathIcon.searchBox,
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
                color = Theme.GrayAlpha56.hex
            )



        }

        if (!isValueEmpty)
        {

            VectorIcon(
                modifier = Modifier
                    .onClick {
                        onCloseSearchBarIconClick()
                    },
                pathData = Res.PathIcon.mediumClose,
                width = Dimen.normalIconSize,
                height = Dimen.normalIconSize,
                color = Theme.GrayAlpha56.hex
            )


            if (showArrowInFillMode){
                VectorIcon(
                    modifier = Modifier
                        .onClick {

                        },
                    pathData = Res.PathIcon.arrowBelow,
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                    color = Theme.GrayAlpha56.hex
                )
            }



        }



    }
}