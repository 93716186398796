package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.closing_reports.CurrencyTypeEnum
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.ListingLayoutsEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.WaiterDeviceTypeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.ArticleModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.GetCategory
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.UploadImage
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.ArticleList
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.TemplateInfoDetail
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun TabTemplateGeneralContent(
    selectedCategory: GetCategory?,
    selectedWaiterDeviceType: WaiterDeviceTypeEnum,
    selectedWaiterLayoutType: ListingLayoutsEnum,
    modifier: Modifier,
    showTitleSortIcon: Boolean,
    titleSorting: Boolean,
    windowSizeMode: WindowSizeModeEnum,
    selectedDate: MonthModel,
    imgUrl: String?,
    selectedDays: Int,
    currencyType: CurrencyTypeEnum,
    selectedColor: String,
    openColorPanel: () -> Unit,
    onChangeActivity: (String, Boolean) -> Unit,
    onChangeReceiptName: (String, String) -> Unit,
    articleList: List<ProductItem>?,
    onRefreshClick: () -> Unit,
    onSearchValueChange: (String) -> Unit,
    onTitleSortClick: () -> Unit,
    setCalendarStatus: (Boolean) -> Unit,
    onChangeDayCheckStatus: (Int) -> Unit,
    showImgAIGeneratorDialog: () -> Unit,
    onAddProductIconClick: () -> Unit,
    onDeviceTypeClick: (WaiterDeviceTypeEnum) -> Unit,
    onShowLayoutTypeClick: (ListingLayoutsEnum) -> Unit,
    updateListAfterDragAndDrop:
        (List<dynamic>, List<String>, List<Int>, List<String>) -> Unit,
    deleteGridProduct:(Int)->Unit,
    showImgUrl: suspend (String) -> String,
    setGridProduct:(String,Int)->Unit,
) {

    Column(
        modifier = modifier
            .fillMaxHeight()
            .fillMaxWidth()
            .padding(
                leftRight = Dimen.normalPadding,
                bottom = Dimen.normalPadding,
                top = Dimen.smallPadding,
            )
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    )
    {
        if (windowSizeMode == WindowSizeModeEnum.Desktop) {
            Row()
            {
                TopContent(
                    imgUrl = imgUrl,
                    selectedDate=selectedDate,
                    windowSizeMode = windowSizeMode,
                    receiptName = selectedCategory?.name,
                    selectedDays = selectedDays,
                    activityStatus = selectedCategory?.isActive,
                    selectedColor =selectedColor ,
                    openColorPanel = {
                         openColorPanel()
                    },
                    setCalendarStatus={
                        setCalendarStatus(it)
                    },
                    onChangeActivity = {
                        selectedCategory?.id?.let { it1 -> onChangeActivity(it1, it) }
                    },
                    onChangeReceiptName = {
                        selectedCategory?.id?.let { it1 -> onChangeReceiptName(it1, it) }
                    },
                    onChangeDayCheckStatus = {
                        onChangeDayCheckStatus(it)
                    },
                    showImgAIGeneratorDialog = {
                        showImgAIGeneratorDialog()
                    }
                )
            }
        } else {
            Column {
                TopContent(
                    imgUrl = imgUrl,
                    windowSizeMode = windowSizeMode,
                    receiptName = selectedCategory?.name,
                    activityStatus = selectedCategory?.isActive,
                    selectedDays = selectedDays,
                    selectedColor =selectedColor ,
                    openColorPanel = {
                        openColorPanel()
                    },
                    onChangeActivity = {
                        onChangeActivity(selectedCategory?.id?:"", it)
                    },
                    onChangeReceiptName = {
                        onChangeReceiptName(selectedCategory?.id?:"", it)
                    },
                    setCalendarStatus = {
                        setCalendarStatus(it)
                    },
                    selectedDate = selectedDate,
                    onChangeDayCheckStatus = {
                        onChangeDayCheckStatus(it)
                    },
                    showImgAIGeneratorDialog = {
                        showImgAIGeneratorDialog()
                    }
                )
            }
        }



        ArticleList(
            modifier=Modifier,
            currencyType=currencyType,
            selectedCategory=selectedCategory,
            articleList = articleList,
            selectedDeviceType = selectedWaiterDeviceType,
            selectedLayoutType = selectedWaiterLayoutType,
            windowSizeMode = windowSizeMode,
            showTitleSortIcon = showTitleSortIcon,
            titleDescending = titleSorting,
            onRefreshClick = {
                onRefreshClick()
            },
            onSearchValueChange = {
                onSearchValueChange(it)
            },
            onTitleSortClick = {
                onTitleSortClick()
            },
            onArticleItemClick = {

            },
            updateListAfterDragAndDrop = { newList,idList,positionList,productIdList ->
                updateListAfterDragAndDrop(
                    newList,idList,positionList,productIdList
                )
            },
            onAddProductIconClick = {
                onAddProductIconClick()
            },
            onDeviceTypeClick = {
                onDeviceTypeClick(it)
            },
            onShowLayoutTypeClick = {
                onShowLayoutTypeClick(it)
            },
            showImgUrl={key:String->
                showImgUrl(key)
            },
            deleteGridProduct = {position:Int->
                deleteGridProduct(position)
            },
            setGridProduct = {productId:String,position:Int->
                setGridProduct(
                    productId,position
                )
            }
        )


    }
}


@Composable
private fun TopContent(
    imgUrl: String?,
    windowSizeMode: WindowSizeModeEnum,
    receiptName: String?,
    activityStatus: Boolean?,
    selectedDays:Int,
    selectedDate: MonthModel,
    selectedColor:String,
    openColorPanel:()->Unit,
    onChangeActivity: (Boolean) -> Unit,
    onChangeReceiptName: (String) -> Unit,
    setCalendarStatus: (Boolean) -> Unit,
    onChangeDayCheckStatus:(Int)->Unit,
    showImgAIGeneratorDialog:()->Unit,

) {
    Column(
        modifier = Modifier
    )
    {

        MediumContentTitle(
            fontSize = FontSizes.extraExtraLargeFontSize,
            title = Strings.general,
            marginTop = Dimen.normalPadding,
            marginBottom = Dimen.normalPadding,
        )

        TemplateInfoDetail(
            windowSizeMode=windowSizeMode,
            receiptName=receiptName?:"",
            activityStatus=activityStatus?:false,
            selectedDate=selectedDate,
            selectedDays = selectedDays,
            selectedColor = selectedColor,
            openColorPanel = {
                openColorPanel()
            },
            setCalendarStatus={
                setCalendarStatus(it)
            },
            onChangeActivity = {
                onChangeActivity(it)
            },
            onChangeReceiptName = {
                onChangeReceiptName(it)
            },
            onChangeDayCheckStatus = {
                onChangeDayCheckStatus(it)
            }
        )
    }


    Column(
        modifier = Modifier.margin(
            leftRight = if(windowSizeMode== WindowSizeModeEnum.Desktop)
                Dimen.mediumPadding
            else 0.px,
        ),
        horizontalAlignment = Alignment.Start
        ,
    )
    {
        MediumContentTitle(
            title = Strings.img,
            marginBottom =Dimen.normalPadding,
            fontSize = FontSizes.extraExtraLargeFontSize,
            marginTop =if (windowSizeMode==WindowSizeModeEnum.PHONE)
                Dimen.mediumPadding else Dimen.normalPadding
        )

        UploadImage(
            imgUrl = imgUrl ?: Res.PathIcon.defaultRectPhoto,
            onImgContentClick = {
                showImgAIGeneratorDialog()
            },
        )





    }
}

