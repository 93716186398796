package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DeviceIdentify(
    @SerialName("clientType")
    val clientType: String?,
    @SerialName("eloviewId")
    val eloviewId: String?,
    @SerialName("hardwareId")
    val hardwareId: String?,
    @SerialName("__typename")
    val typename: String?
)