package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import org.hs_soft.runmynesto.domain.config.*
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.menu_card_import.menu_card_model.MenuCardModel
import org.hs_soft.runmynesto.domain.model.home.options.menu_card_import.menu_card_model.MenuProductItemModel
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.pages.components.dialog.component.SubmitButtons
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLTextAreaElement

@Composable
fun UploadMenuCard(
    windowSizeMode: WindowSizeModeEnum,
    onCancelImportMenuCardClick: () -> Unit,
    onImportClick: (MenuCardModel) -> Unit,
    menuCard: MenuCardModel,
    onMenuProductItemValueChange:(MenuCardModel, Int, String, String) -> Unit,
) {
    if(menuCard != MenuCardModel(categories = mutableListOf(), items = mutableListOf()) ) {
        Style(MenuCardStyle)
        Table(attrs = {
            classes("menu-card-table")
            style {
                borderCollapse(BorderCollapse.Collapse)
            }
        }) {
            // Table Head
            Tr {
                ApiUtils.MenuCardTable.menuProductItemProperties.forEach { property ->
                    Th { Text(property) }
                }
            }
            // Table Body
            menuCard.items.forEachIndexed {index, item ->
                MenuCardProductItem(
                    menuCard=menuCard,
                    id=index,
                    productItem = item,
                    onFocusOut ={

                    },
                    onMenuProductItemValueChange = onMenuProductItemValueChange
                )
            }
        }
        SubmitButtons(
            windowSize=windowSizeMode,

            onCancelClick = {
                onCancelImportMenuCardClick()
            },
            onSubmitClick = {
                onImportClick(
                    menuCard
                )
            },
            isSubmitClickable = true,
            arrangement=Arrangement.End
        )
    }
}

@Composable
fun MenuCardProductItem(
    menuCard: MenuCardModel,
    id:Int,
    productItem: MenuProductItemModel,
    onFocusOut: (String) -> Unit,
    onMenuProductItemValueChange:(MenuCardModel, Int, String, String) -> Unit,
) {
    val inputNameValue = remember { mutableStateOf(productItem.name) }
    val isNameTextChanged = remember { mutableStateOf(false) }
    val isNameFocused = remember { mutableStateOf(false) }
    val isNameHovered = remember { mutableStateOf(false) }

    val inputDescriptionValue = remember { mutableStateOf(productItem.description) }
    val isDescriptionTextChanged = remember { mutableStateOf(false) }
    val isDescriptionFocused = remember { mutableStateOf(false) }
    val isDescriptionHovered = remember { mutableStateOf(false) }

    val inputPriceValue = remember { mutableStateOf(productItem.price) }
    val isPriceTextChanged = remember { mutableStateOf(false) }
    val isPriceFocused= remember { mutableStateOf(false) }
    val isPriceHovered= remember { mutableStateOf(false) }

    val inputWeightValue = remember { mutableStateOf(productItem.weight) }
    val isWeightTextChanged = remember { mutableStateOf(false) }
    val isWeightFocused = remember { mutableStateOf(false) }
    val isWeightHovered= remember { mutableStateOf(false) }

    val inputAllergensValue = remember { mutableStateOf(productItem.allergens?.joinToString(", ")) }
    val isAllergensTextChanged = remember { mutableStateOf(false) }
    val isAllergensFocused= remember { mutableStateOf(false) }
    val isAllergensHovered= remember { mutableStateOf(false) }

    val inputCategoryValue = remember { mutableStateOf(productItem.category) }
    val isCategoryTextChanged = remember { mutableStateOf(false) }
    val isCategoryFocused= remember { mutableStateOf(false) }
    val isCategoryHovered= remember { mutableStateOf(false) }

    val inputTurnoverGroupValue = remember { mutableStateOf(productItem.turnoverGroup) }
    val isTurnoverGroupTextChanged = remember { mutableStateOf(false) }
    val isTurnoverGroupFocused= remember { mutableStateOf(false) }
    val isTurnoverGroupHovered= remember { mutableStateOf(false) }

    val inputTemplateValue = remember { mutableStateOf(productItem.template) }
    val isTemplateTextChanged = remember { mutableStateOf(false) }
    val isTemplateFocused= remember { mutableStateOf(false) }
    val isTemplateHovered= remember { mutableStateOf(false) }

    val inputIngredientsValue = remember { mutableStateOf(productItem.ingredients) }
    val isIngredientsTextChanged = remember { mutableStateOf(false) }
    val isIngredientsFocused= remember { mutableStateOf(false) }
    val isIngredientsHovered= remember { mutableStateOf(false) }

    val inputAlcoholicValue = remember { mutableStateOf(productItem.alcoholic.toString()) }
    val isAlcoholicTextChanged = remember { mutableStateOf(false) }
    val isAlcoholicFocused= remember { mutableStateOf(false) }
    val isAlcoholicHovered= remember { mutableStateOf(false) }

    val inputVeganValue = remember { mutableStateOf(productItem.vegan.toString()) }
    val isVeganTextChanged = remember { mutableStateOf(false) }
    val isVeganFocused= remember { mutableStateOf(false) }
    val isVeganHovered= remember { mutableStateOf(false) }

    val inputVegetarianValue = remember { mutableStateOf(productItem.vegetarian.toString()) }
    val isVegetarianTextChanged = remember { mutableStateOf(false) }
    val isVegetarianFocused= remember { mutableStateOf(false) }
    val isVegetarianHovered= remember { mutableStateOf(false) }

    val inputProductNumberValue = remember { mutableStateOf(productItem.productNumber) }
    val isProductNumberTextChanged = remember { mutableStateOf(false) }
    val isProductNumberFocused= remember { mutableStateOf(false) }
    val isProductNumberHovered= remember { mutableStateOf(false) }

    LaunchedEffect(productItem.name) {
        inputNameValue.value = productItem.name
        inputDescriptionValue.value = productItem.description
        inputPriceValue.value = productItem.price
        inputWeightValue.value = productItem.weight
        inputAllergensValue.value = productItem.allergens?.joinToString(", ")
        inputCategoryValue.value = productItem.category
        inputTurnoverGroupValue.value = productItem.turnoverGroup
        inputTemplateValue.value = productItem.template
        inputIngredientsValue.value = productItem.ingredients
        inputAlcoholicValue.value = productItem.alcoholic.toString()
        inputVeganValue.value = productItem.vegan.toString()
        inputVegetarianValue.value = productItem.vegetarian.toString()
        inputProductNumberValue.value = productItem.productNumber
    }

    Tr(attrs = { classes("menu-product-item") }) {
        Td(attrs = {classes("medium-input")}) {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isNameHovered.value = true }
                    .onMouseLeave { isNameHovered.value = false }
                    .onFocusIn {
                        isNameFocused.value = true
                    }
                    .onFocusOut {
                        isNameFocused.value = false
                        isNameFocused.value = false
                        if (isNameTextChanged.value) {
                            onFocusOut(inputNameValue.value ?: "")
                            isNameTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputNameValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputNameValue.value = target?.value ?: ""
                            isNameTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id,  ApiUtils.MenuCardTable.menuProductItemProperties[0], inputNameValue.value ?: "")
                        }
                    }
            )
        }

        Td(attrs = {classes("large-input")}) {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isDescriptionHovered.value = true }
                    .onMouseLeave { isDescriptionHovered.value = false }
                    .onFocusIn {
                        isDescriptionFocused.value = true
                    }
                    .onFocusOut {
                        isDescriptionFocused.value = false
                        isDescriptionFocused.value = false
                        if (isDescriptionTextChanged.value) {
                            onFocusOut(inputDescriptionValue.value ?: "")
                            isDescriptionTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputDescriptionValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputDescriptionValue.value = target?.value ?: ""
                            isDescriptionTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id, ApiUtils.MenuCardTable.menuProductItemProperties[1], inputDescriptionValue.value ?: "")
                        }
                    }
            )
        }

        Td(attrs = {classes("extra-small-input")})  {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isPriceHovered.value = true }
                    .onMouseLeave { isPriceHovered.value = false }
                    .onFocusIn {
                        isPriceFocused.value = true
                    }
                    .onFocusOut {
                        isPriceFocused.value = false
                        isPriceFocused.value = false
                        if (isPriceTextChanged.value) {
                            onFocusOut(inputPriceValue.value ?: "")
                            isPriceTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputPriceValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputPriceValue.value = target?.value ?: ""
                            isPriceTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id, ApiUtils.MenuCardTable.menuProductItemProperties[2], inputPriceValue.value ?: "")
                        }
                    }
            )
        }

        Td(attrs = {classes("extra-small-input")})  {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isWeightHovered.value = true }
                    .onMouseLeave { isWeightHovered.value = false }
                    .onFocusIn {
                        isWeightFocused.value = true
                    }
                    .onFocusOut {
                        isWeightFocused.value = false
                        isWeightFocused.value = false
                        if (isWeightTextChanged.value) {
                            onFocusOut(inputWeightValue.value ?: "")
                            isWeightTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputWeightValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputWeightValue.value = target?.value ?: ""
                            isWeightTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id, ApiUtils.MenuCardTable.menuProductItemProperties[3], inputWeightValue.value ?: "")
                        }
                    }
            )
        }

        Td(attrs = {classes("small-medium-input")}) {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isAllergensHovered.value = true }
                    .onMouseLeave { isAllergensHovered.value = false }
                    .onFocusIn {
                        isAllergensFocused.value = true
                    }
                    .onFocusOut {
                        isAllergensFocused.value = false
                        isAllergensFocused.value = false
                        if (isAllergensTextChanged.value) {
                            onFocusOut(inputAllergensValue.value ?: "")
                            isAllergensTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputAllergensValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputAllergensValue.value = target?.value ?: ""
                            isAllergensTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id, ApiUtils.MenuCardTable.menuProductItemProperties[4], inputAllergensValue.value ?: "")
                        }
                    }
            )
        }

        Td(attrs = {classes("small-input")}) {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isCategoryHovered.value = true }
                    .onMouseLeave { isCategoryHovered.value = false }
                    .onFocusIn {
                        isCategoryFocused.value = true
                    }
                    .onFocusOut {
                        isCategoryFocused.value = false
                        isCategoryFocused.value = false
                        if (isCategoryTextChanged.value) {
                            onFocusOut(inputCategoryValue.value ?: "")
                            isCategoryTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputCategoryValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputCategoryValue.value = target?.value ?: ""
                            isCategoryTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id, ApiUtils.MenuCardTable.menuProductItemProperties[5], inputCategoryValue.value ?: "")
                        }
                    }
            )
        }

        Td(attrs = {classes("extra-small-input")})  {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isTurnoverGroupHovered.value = true }
                    .onMouseLeave { isTurnoverGroupHovered.value = false }
                    .onFocusIn {
                        isTurnoverGroupFocused.value = true
                    }
                    .onFocusOut {
                        isTurnoverGroupFocused.value = false
                        isTurnoverGroupFocused.value = false
                        if (isTurnoverGroupTextChanged.value) {
                            onFocusOut(inputTurnoverGroupValue.value ?: "")
                            isTurnoverGroupTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputTurnoverGroupValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputTurnoverGroupValue.value = target?.value ?: ""
                            isTurnoverGroupTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id, ApiUtils.MenuCardTable.menuProductItemProperties[6], inputTurnoverGroupValue.value ?: "")
                        }
                    }
            )
        }

        Td(attrs = {classes("extra-small-input")})  {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isTemplateHovered.value = true }
                    .onMouseLeave { isTemplateHovered.value = false }
                    .onFocusIn {
                        isTemplateFocused.value = true
                    }
                    .onFocusOut {
                        isTemplateFocused.value = false
                        isTemplateFocused.value = false
                        if (isTemplateTextChanged.value) {
                            onFocusOut(inputTemplateValue.value ?: "")
                            isTemplateTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputTemplateValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputTemplateValue.value = target?.value ?: ""
                            isTemplateTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id, ApiUtils.MenuCardTable.menuProductItemProperties[7], inputTemplateValue.value ?: "")
                        }
                    }
            )
        }

        Td(attrs = {classes("large-input")}) {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isIngredientsHovered.value = true }
                    .onMouseLeave { isIngredientsHovered.value = false }
                    .onFocusIn {
                        isIngredientsFocused.value = true
                    }
                    .onFocusOut {
                        isIngredientsFocused.value = false
                        isIngredientsFocused.value = false
                        if (isIngredientsTextChanged.value) {
                            onFocusOut(inputIngredientsValue.value ?: "")
                            isIngredientsTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputIngredientsValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputIngredientsValue.value = target?.value ?: ""
                            isIngredientsTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id, ApiUtils.MenuCardTable.menuProductItemProperties[8], inputIngredientsValue.value ?: "")
                        }
                    }
            )
        }

        Td(attrs = {classes("extra-small-input")})  {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isAlcoholicHovered.value = true }
                    .onMouseLeave { isAlcoholicHovered.value = false }
                    .onFocusIn {
                        isAlcoholicFocused.value = true
                    }
                    .onFocusOut {
                        isAlcoholicFocused.value = false
                        isAlcoholicFocused.value = false
                        if (isAlcoholicTextChanged.value) {
                            onFocusOut(inputAlcoholicValue.value ?: "")
                            isAlcoholicTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputAlcoholicValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputAlcoholicValue.value = target?.value ?: ""
                            isAlcoholicTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id, ApiUtils.MenuCardTable.menuProductItemProperties[9], inputAlcoholicValue.value ?: "")
                        }
                    }
            )
        }

            Td(attrs = {classes("extra-small-input")}) {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isVeganHovered.value = true }
                    .onMouseLeave { isVeganHovered.value = false }
                    .onFocusIn {
                        isVeganFocused.value = true
                    }
                    .onFocusOut {
                        isVeganFocused.value = false
                        isVeganFocused.value = false
                        if (isVeganTextChanged.value) {
                            onFocusOut(inputVeganValue.value ?: "")
                            isVeganTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputVeganValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputVeganValue.value = target?.value ?: ""
                            isVeganTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id, ApiUtils.MenuCardTable.menuProductItemProperties[10], inputVeganValue.value ?: "")
                        }
                    }
            )
        }

            Td(attrs = {classes("extra-small-input")})  {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isVegetarianHovered.value = true }
                    .onMouseLeave { isVegetarianHovered.value = false }
                    .onFocusIn {
                        isVegetarianFocused.value = true
                    }
                    .onFocusOut {
                        isVegetarianFocused.value = false
                        isVegetarianFocused.value = false
                        if (isVegetarianTextChanged.value) {
                            onFocusOut(inputVegetarianValue.value ?: "")
                            isVegetarianTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputVegetarianValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputVegetarianValue.value = target?.value ?: ""
                            isVegetarianTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id, ApiUtils.MenuCardTable.menuProductItemProperties[11], inputVegetarianValue.value ?: "")
                        }
                    }
            )
        }

        Td(attrs = {classes("extra-small-input")}) {
            TextArea(
                attrs = Modifier
                    .classNames("input")
                    .onMouseOver { isProductNumberHovered.value = true }
                    .onMouseLeave { isProductNumberHovered.value = false }
                    .onFocusIn {
                        isProductNumberFocused.value = true
                    }
                    .onFocusOut {
                        isProductNumberFocused.value = false
                        isProductNumberFocused.value = false
                        if (isProductNumberTextChanged.value) {
                            onFocusOut(inputProductNumberValue.value ?: "")
                            isProductNumberTextChanged.value = false
                        }
                    }
                    .toAttrs {
                        value(inputProductNumberValue.value ?: "")
                        onInput { event ->
                            val target = event.target as? HTMLTextAreaElement
                            inputProductNumberValue.value = target?.value ?: ""
                            isProductNumberTextChanged.value = true

                            onMenuProductItemValueChange(menuCard, id, ApiUtils.MenuCardTable.menuProductItemProperties[12], inputProductNumberValue.value ?: "")
                        }
                    }
            )
        }

    }
}

object MenuCardStyle : StyleSheet() {
    init {
        "menu-card-table" style {
            border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
            borderRadius(Dimen.lowBorderRadius)
            padding(Dimen.normalPadding)
            width(50.percent)
            property("table-layout", "auto")
        }

        "menu-product-item" style {
            border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
        }

        "td, th" style {
            padding(Dimen.smallPadding)
            textAlign("center")
            border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
            fontSize(9.px)
        }

        ".input" style {
            boxSizing("border-box")
            padding(Dimen.smallPadding)
            textAlign("center") // Center text in input
            width(100.percent)
        }

        ".extra-small-input" style {
            width(2.percent)
        }

        ".small-input" style {
            width(4.percent)
        }

        ".small-medium-input" style {
            width(6.percent)
        }

        ".medium-input" style {
            width(8.percent)
        }

        ".large-input" style {
            width(10.percent)
        }

        ".extra-large-input" style {
            width(13.percent)
        }
    }
}
