package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail.LoyaltyCardDetail
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.CustomerCardTypeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.DiscountNoEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.credit_client.CreditClientModel
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.credit_client.Item
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.DropDownItem
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.jetbrains.compose.web.dom.Text

@Composable
fun Art(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    creditClient: CreditClientModel?,
    updateLoyaltyCardInfo: (String, dynamic) -> Unit,
    windowSizeMode: WindowSizeModeEnum,
)
{
    val editableType=CustomerCardTypeEnum.LOYALTY_CARDS.value

    Column()
    {
        MediumContentTitle(
            title = Strings.art,
        )

        if (windowSizeMode==WindowSizeModeEnum.Desktop)
        {
            Row(){
                ArtLeftContent(
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    editableType=editableType,
                    creditClient=creditClient,
                    updateLoyaltyCardInfo = {title:String,value:dynamic->
                        updateLoyaltyCardInfo(title, value)
                    }
                )

                ArtRightContent(
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    updateLoyaltyCardInfo = {title:String,value:dynamic->
                        updateLoyaltyCardInfo(title, value)
                    }
                )
            }
        }else{
            Column {
                ArtLeftContent(
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    editableType=editableType,
                    creditClient=creditClient,
                    updateLoyaltyCardInfo = {title:String,value:dynamic->
                        updateLoyaltyCardInfo(title, value)
                    }
                )

                ArtRightContent(
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    updateLoyaltyCardInfo = {title:String,value:dynamic->
                        updateLoyaltyCardInfo(title, value)
                    }
                )
            }
        }



    }

}

@Composable
private fun ArtLeftContent(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    editableType: Int,
    creditClient: CreditClientModel?,
    updateLoyaltyCardInfo: (String, dynamic) -> Unit,
) {
    Column(
        modifier = Modifier.margin(right = Dimen.normalPadding)
    )
    {
        DropDownItem(
            modifier = Modifier.margin(
                bottom = Dimen.normalPadding,
                right = Dimen.normalPadding
            ),
            options = CustomerCardTypeEnum.entries,
            label = Strings.cardType,
            selectedItem = CustomerCardTypeEnum.entries.find {
                it.value == selectedLoyaltyCard?.type
            },
            content = {
                if (it is CustomerCardTypeEnum)
                    Text(it.title)
            },
            onOptionClick = {
                val option: CustomerCardTypeEnum? = CustomerCardTypeEnum.entries.find { item ->
                    item.toString() == it
                }
                updateLoyaltyCardInfo(
                    ApiUtils.Field.type,
                    option?.value
                )

            }
        )

        DropDownItem(
            modifier = Modifier.margin(
                bottom = Dimen.mediumPadding,
                right = Dimen.normalPadding
            ),
            options = creditClient?.data?.cashAssist?.listCreditClients?.items?: listOf(),
            disable = selectedLoyaltyCard?.type==editableType,
            label = Strings.debitCard,
            selectedItem = creditClient?.data?.cashAssist
                ?.listCreditClients?.items?.find {
                    it?.id == selectedLoyaltyCard?.creditClientId
                },
            content = {
                if (it is Item)
                    Text(it.name?:"")
            },
            onOptionClick = {
                val option = creditClient?.data?.cashAssist?.
                    listCreditClients?.items?.find { item ->
                    item.toString() == it
                }

                if (option != null) {
                    updateLoyaltyCardInfo(
                        ApiUtils.Field.creditClientId,
                        option.id
                    )
                }

            }
        )

        Row()
        {
            CustomEditText(
                modifier = Modifier,
                label = Strings.discountWithPercent,
                marginRight = Dimen.mediumPadding,
                value =selectedLoyaltyCard?.discount?.freePercent?.toString()?:"",
                width = Dimen.semiNormalEditTextWidth,
                isRequired = false,
                hint = "",
                isDecimal = true,
                marginBottom = Dimen.smallPadding,
                onFocusIn = { },
                onFocusOut = {

                    val json= buildJsonObject {
                        put(ApiUtils.Field.freePercent,it.toInt())

                    }
                    updateLoyaltyCardInfo(
                        ApiUtils.Field.discount,
                        json
                    )
                },
                onInputChange = {},
            )

            CustomEditText(
                modifier = Modifier,
                label = Strings.discountGLWithPercent,
                value =selectedLoyaltyCard?.discount?.masterPercent?.toString()?:"",
                width = Dimen.semiNormalEditTextWidth,
                isRequired = false,
                isDecimal = true,
                hint = "",
                marginBottom = Dimen.smallPadding,
                onFocusIn = { },
                onFocusOut = {
                   val json= buildJsonObject {
                       put(ApiUtils.Field.masterPercent,it.toInt())

                   }
                    updateLoyaltyCardInfo(
                        ApiUtils.Field.discount,
                        json
                    )
                },
                onInputChange = {},
            )
        }

        DropDownItem(
            modifier = Modifier.margin(
                bottom = Dimen.normalPadding,
                right = Dimen.normalPadding
            ),
            options = DiscountNoEnum.entries,
            label = Strings.discountNumber,
            selectedItem = DiscountNoEnum.entries.find {
                    it.value == selectedLoyaltyCard?.discount?.numberOfPercent
            }?:DiscountNoEnum.NONE,
            content = {
                if (it is DiscountNoEnum)
                    Text(it.title)
            },
            onOptionClick = {
                val option: DiscountNoEnum? = DiscountNoEnum.entries.find { item ->
                    item.toString() == it
                }

                if (option != null) {
                    val json= buildJsonObject {
                        put(ApiUtils.Field.numberOfPercent,option.value)

                    }
                    updateLoyaltyCardInfo(
                        ApiUtils.Field.discount,
                        json
                    )
                }

            }
        )


    }
}

@Composable
private fun ArtRightContent(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    updateLoyaltyCardInfo: (String, dynamic) -> Unit,
) {
    Column(
        modifier = Modifier.margin(top = Dimen.extraSmallPadding)
    )
    {
        CheckboxComponent(
            modifier = Modifier ,
            checkStatus = selectedLoyaltyCard?.balanceDiscount?:false,
            title = Strings.grantDiscountWhenCharged,
            onChecked = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.balanceDiscount,
                    true
                )
            },
            onUnChecked = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.balanceDiscount,
                    false
                )
            },
        )

        CheckboxComponent(
            modifier = Modifier ,
            checkStatus = selectedLoyaltyCard?.alwaysDiscount?:false,
            title = Strings.grantDiscountOnNetItems,
            onChecked = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.alwaysDiscount,
                    true
                )
            },
            onUnChecked = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.balancePoints,
                    false
                )
            },
        )

        CheckboxComponent(
            modifier = Modifier ,
            checkStatus = selectedLoyaltyCard?.cashLoad?:false,
            title = Strings.cardCanOnlyBeChargedByCash,
            onChecked = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.cashLoad,
                    true
                )
            },
            onUnChecked = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.cashLoad,
                    false
                )
            },
        )

        CheckboxComponent(
            modifier = Modifier
                .margin(bottom = Dimen.mediumPadding),
            checkStatus = selectedLoyaltyCard?.balancePoints?:false,
            title = Strings.collectPointsWhenCharged,
            onChecked = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.balancePoints,
                    true
                )
            },
            onUnChecked = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.balancePoints,
                    false
                )
            },
        )

        Row()
        {
            CustomEditText(
                modifier = Modifier,
                label = Strings.rfid,
                isEnabled = false,
                marginRight = Dimen.mediumPadding,
                value =selectedLoyaltyCard?.rfid?:"",
                width = Dimen.normalEditTextWidth,
                isRequired = false,
                hint = "",
                marginBottom = Dimen.smallPadding,
                onFocusIn = { },
                onFocusOut = {
                    updateLoyaltyCardInfo(
                        ApiUtils.Field.rfid,
                        it
                    )
                },
                onInputChange = {},
            )

            CustomEditText(
                modifier = Modifier,
                label = Strings.barcode,
                value =selectedLoyaltyCard?.barCode?:"",
                width = Dimen.normalEditTextWidth,
                isRequired = false,
                hint = "",
                marginBottom = Dimen.smallPadding,
                onFocusIn = { },
                onFocusOut = {
                    updateLoyaltyCardInfo(
                        ApiUtils.Field.barCode,
                        it
                    )
                },
                onInputChange = {},
            )
        }

    }
}