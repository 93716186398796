package org.hs_soft.runmynesto.domain.usecase.options.cash_register_config

import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.GetCashRegisterConfigModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.cash_register_config.UpdateCashRegisterConfigRepository


class UpdateCashRegisterConfigUseCase(
    private val repository: UpdateCashRegisterConfigRepository
) {
    suspend operator fun invoke(
        id:String,
        title:String,
        value:dynamic,
        valueIsInParameters:Boolean,
    ): GetCashRegisterConfigModel {
        return repository.updateCashRegisterConfig(
            id=id,
            title=title,
            value=value,
            valueIsInParameters=valueIsInParameters,
        )
    }
}