package org.hs_soft.runmynesto.data.local_datasource.menu_data

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.menu.MenuHeaderModel
import org.hs_soft.runmynesto.domain.util.Res

object MenuHeaderData
{
    val data= MenuHeaderModel(
        appIcon = Res.PathIcon.logo,
        title1 = Strings.APP_NAME_PART1 ,
        title2 = Strings.APP_NAME_PART2,
    )
}