package org.hs_soft.runmynesto.domain.usecase.product.tab_product_general

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.ingeredient_list.IngredientModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.IngredientListRepository


class IngredientListUseCase(
    private val repository: IngredientListRepository
) {
    suspend operator fun invoke(): IngredientModel {
        return repository.ingredientList()
    }


}