package org.hs_soft.runmynesto.pages.components.top_toolbar.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.util.animatePageTransition
import org.jetbrains.compose.web.css.percent


@Composable
fun CarouselButton(
    onScrollButtonClick: () -> Unit
) {
    val pages = listOf("", "",) // Example pages
    var activePage by remember { mutableStateOf(0) }

    Row(
        modifier = Modifier
            .padding(Dimen.normalPadding)
            .borderRadius(50.percent)
            .onClick {
                onScrollButtonClick()
                activePage = (activePage + 1) % pages.size
            },
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
    ) {
        pages.forEachIndexed { index, page ->
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(Dimen.extraExtraSmallPadding)
                    .backgroundColor(if (index == activePage) Colors.Gray else Colors.LightGray)
                    .animatePageTransition(activePage == index)
            )
        }
    }
}