package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.calendar_filter_dialog.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.YearModel
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.component.CalendarMonthRow

@Composable
fun TopCalendarHeaderSelectMonth(
    currentMonth: MutableState<MonthModel>,
    currentIndex: MutableState<Int>,
    date: YearModel,
    closeCalendarDialog:()->Unit,
) {
    CalendarMonthRow(
        selectedItem = currentMonth.value,
        onPreviousMonthClick = {
            currentIndex.value = date.monthsOfYear.indexOfFirst {
                it.yearNumber == currentMonth.value.yearNumber
                        &&
                        it.monthNumber == currentMonth.value.monthNumber
            }
            if (currentIndex.value > 0) {
                val previousMonth = date.monthsOfYear[currentIndex.value - 1]

                currentMonth.value = previousMonth
                currentMonth.value = currentMonth.value.copy(
                    days = previousMonth.days,
                    dayNumber = previousMonth.dayNumber,
                    monthName = previousMonth.monthName,
                    yearNumber = previousMonth.yearNumber
                )
            }


        },
        onNextMonthClick = {
            val allMonths = date.monthsOfYear
            currentIndex.value = date.monthsOfYear.indexOfFirst {
                it.yearNumber == currentMonth.value.yearNumber
                        &&
                        it.monthNumber == currentMonth.value.monthNumber
            }

            if (currentIndex.value < allMonths.size - 1) {

                val nextMonth = allMonths[currentIndex.value + 1]
                currentMonth.value = nextMonth

                currentMonth.value = currentMonth.value.copy(
                    days = nextMonth.days,
                    dayNumber = nextMonth.dayNumber,
                    monthNumber = nextMonth.monthNumber,
                    monthName = nextMonth.monthName
                )

            }

        },
        onCloseFilterClick = {
            closeCalendarDialog()
        }
    )
}