package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general

import org.hs_soft.runmynesto.domain.config.Strings

enum class ListingLayoutsEnum(
    val title:String,
)
{
    LIST(
        title = Strings.list
    ),
    TILES(
        title = Strings.tiles,
    )

}