package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.bill_search.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data.BillSearchStatisticDataItem
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.getCheckoutWidth
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.jetbrains.compose.web.css.px


@Composable
fun BillSearchTableContent(
    modifier: Modifier=Modifier,
    windowSizeMode:WindowSizeModeEnum,
    selectedBillSearchStatisticItem:BillSearchStatisticDataItem?,
    statisticData:List<BillSearchStatisticDataItem>?,
    onSelectStatisticItem:(BillSearchStatisticDataItem)->Unit,
    searchBillSearchItems:(String)->Unit,
    getBillPdf:(String,String,String)->Unit,
    exportStatisticReports:(List<BillSearchStatisticDataItem>?)->Unit,
)
{
    Column(
        modifier = modifier
            .fillMaxWidth(),
    )
    {
        MediumContentTitle(
            title = Strings.billSearch
        )

        Row(
            modifier=Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            CustomEditText(
                modifier = modifier,
                label = "",
                value = "",
                width =when(windowSizeMode){
                    WindowSizeModeEnum.Desktop -> getCheckoutWidth(windowSizeMode)
                    WindowSizeModeEnum.TABLET -> Constants.Responsive.ClosingReportsTableTablet.px
                    WindowSizeModeEnum.PHONE -> Constants.Responsive.ClosingReportsTablePhone.px
                },
                endIconPath = Res.PathIcon.searchBox,
                fillMaxWidth = windowSizeMode==WindowSizeModeEnum.PHONE,
                isRequired = false,
                hasLabelSpace = false,
                marginBottom =Dimen.normalPadding,
                defaultBorderColor = Theme.GrayCD.rgb,
                hint = Strings.search,
                marginRight = Dimen.normalPadding,
                onFocusIn = {},
                onFocusOut = {},
                onInputChange = {
                    searchBillSearchItems(it)
                },
            )


            Spacer()

            CustomButton(
                modifier = Modifier,
                height =Dimen.mediumOutlinedEditTextHeight ,
                windowSizeMode=windowSizeMode,
                title = Strings.exportExcel,
                isClickable = !statisticData.isNullOrEmpty(),
                leftRightMargin = 0.px,
                topBottomMargin = 0.px,
                onButtonClick = {
                    exportStatisticReports(statisticData)
                }

            )

        }


        BillSearchTable(
            windowSizeMode=windowSizeMode,
            statisticData=statisticData ,
            selectedBillSearchItem =selectedBillSearchStatisticItem ,
            onItemClick = {
                onSelectStatisticItem(it)
            },
            getBillPdf = {number:String,dateTime:String,cashRegisterId:String->
                getBillPdf(
                    number,
                    dateTime,
                    cashRegisterId
                )
            }
        )



    }


}