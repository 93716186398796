package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component.desktop

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.borderTop
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.Job
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.overflowText
import org.hs_soft.runmynesto.domain.model.home.closing_reports.CurrencyTypeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.ArticleModel
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component.WaiterHoverBackground
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun DesktopWaiterListTypeItem(
    model: ArticleModel,
    showImgUrl: suspend (String) -> String,
    deleteGridProduct:()->Unit,
)
{
    val imageUrl= remember { mutableStateOf("") }

    LaunchedEffect(model.product) {
        model.product?.img?.key?.let { key ->
            imageUrl.value = showImgUrl(key)
        }
    }

    val isHovered= remember { mutableStateOf(false) }

    Box(
        modifier = Modifier
            .onMouseOver { isHovered.value=true }
            .onMouseLeave { isHovered.value=false }
            .margin(Dimen.semiSemiNormalPadding)
            .width(Dimen.desktopWaiterTilesTypeItemWidth)
            .height(Dimen.desktopWaiterTilesTypeItemHeight)
            .borderRadius(Dimen.normalBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    )
    {

        Column(
            modifier = Modifier.fillMaxSize()
        )
        {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(Dimen.desktopWaiterListTypeImgHeight)
            )
            {
                if (model.product?.img!=null){
                    Image(
                        src =imageUrl.value,
                        modifier = Modifier
                            .borderTop(Dimen.normalBorderRadius)
                            .borderBottom(
                                width = Dimen.smallThickness,
                                style = LineStyle.Solid,
                                color = Theme.GrayCD.rgb
                            )
                            .fillMaxWidth()
                            .fillMaxHeight()
                    )

                }else{
                    Box(
                        modifier = Modifier
                            .borderTop(Dimen.normalBorderRadius)
                            .borderBottom(
                                width = Dimen.smallThickness,
                                style = LineStyle.Solid,
                                color = Theme.GrayCD.rgb
                            )
                            .backgroundColor(Theme.GrayAlpha02.rgb)
                            .fillMaxWidth()
                            .fillMaxHeight()
                    )
                }

                Box(
                    modifier = Modifier
                        .align(Alignment.TopStart)
                        .backgroundColor(Theme.Black43.rgb)
                        .borderRadius(
                            topRight = Dimen.semiExtraLargeBorderRadius,
                            bottomRight =Dimen.semiExtraLargeBorderRadius,
                        )
                        .margin(top = Dimen.mediumPadding)
                        .width(Dimen.desktopWaiterListTypeLabelWidth)
                        .height(Dimen.desktopWaiterListTypeLabelHeight)
                ){
                    SpanText(
                        text = formatToTwoDecimals(
                            model.product?.price?.filterNotNull()?.minOrNull() ?: 0.00
                        ),
                        modifier = Modifier
                            .align(Alignment.Center)
                            .fontWeight(FontWeight.SemiBold)
                            .color(Theme.White.rgb)
                            .fontSize(FontSizes.mediumFontSize)
                            .fontFamily(FontFamily.Jakarta)
                    )
                }
            }


            Box(
                modifier = Modifier
                    .height(Dimen.desktopWaiterListTypeTitleHeight)
                    .fillMaxWidth()
            )
            {
                SpanText(
                    text = model.product?.name?:"",
                    modifier = Modifier
                        .align(Alignment.Center)
                        .fillMaxWidth()
                        .fontFamily(FontFamily.Jakarta)
                        .textAlign(TextAlign.Center)
                        .padding(leftRight = Dimen.mediumPadding)
                        .margin(leftRight = Dimen.mediumPadding)
                        .fontSize(FontSizes.semiMediumFontSize)
                        .fontWeight(FontWeight.Medium)
                        .color(Theme.Black43.rgb)

                )
            }


        }


        WaiterHoverBackground(
            isHovered = isHovered.value,
            onDeleteClick = {
                deleteGridProduct()
            }
        )

    }

}