package org.hs_soft.runmynesto.data.api_datasource.authentication

import androidx.compose.runtime.NoLiveLiterals
import kotlinx.coroutines.await
import org.hs_soft.runmynesto.Auth
import org.hs_soft.runmynesto.domain.repository.network_datasource.login.IsLoggedInRepository
import kotlin.js.Promise

class IsLoggedInRepositoryImpl: IsLoggedInRepository {
    @NoLiveLiterals
    override suspend fun isLoggedIn(): Boolean {
        return Promise { resolve, reject ->
            Auth.getCurrentUser().then { user ->
                console.log("User is logged in")
                resolve(true)
            }.catch { error ->
                console.log("Error checking auth status: $error")
                resolve(false)
            }
        }.await()

    }

}

