package org.hs_soft.runmynesto.data.api_datasource.options.sale_group

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.single_sale_group.SingleSaleGroupModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.sales_group.UpdateSaleGroupRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateSaleGroupRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateSaleGroupRepository {

    override suspend fun updateSaleGroup(
        id: String,
        dataTitle: String,
        dataValue: String
    ): SingleSaleGroupModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            mutation updateSalesGroup(${'$'}id: ID!,${'$'}fields: SalesGroupFields) {
              cashAssist {
                updateSalesGroup(id: ${'$'}id,fields:${'$'}fields) {
                  ...salesGroupMain
                  ${ApiUtils.Field.__typename}
                }
                ${ApiUtils.Field.__typename}
              }
            }
            
            fragment salesGroupMain on SalesGroup {
              ${ApiUtils.Field.id}
              ${ApiUtils.Field.name}
              ${ApiUtils.Field.mod_ver}
              ${ApiUtils.Field.acountNum}
              ${ApiUtils.Field.discount}
              ${ApiUtils.Field.accountingReduced}
              ${ApiUtils.Field.accountingNormal}
              ${ApiUtils.Field.accountingZeroPercent}
              clientDiscount {
                ${ApiUtils.Field.number}
                ${ApiUtils.Field.percent} 
                ${ApiUtils.Field.__typename} 
              }
              ${ApiUtils.Field.__typename} 
            }
    """.trimIndent())

            putJsonObject("variables") {
                put("id", id)
                putJsonObject("fields") {
                    put(dataTitle,dataValue)
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<SingleSaleGroupModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }
    }

}
