package org.hs_soft.runmynesto.pages.components.menu

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.browser.dom.ElementTarget
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.alignItems
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseDown
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.onMouseUp
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.overlay.AdvancedTooltip
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.delay
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.circleHoverAroundItem
import org.hs_soft.runmynesto.domain.model.menu.SubMenuModel
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.MenuVectorIcon
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun MenuItemRow(
    itemTitle: String,
    imgSrc: String,
    hoverColor: CSSColorValue,
    subData: List<SubMenuModel> = listOf(),
    isMenuExpandedHorizontally: Boolean = false,
    backgroundColor: CSSColorValue,
    clickedIconColor: CSSColorValue,
    isSelected: Boolean,
    onRowClick: () -> Unit,
)
{
    var showTooltip by remember { mutableStateOf(false) }
    val isMouseDown= remember { mutableStateOf(false) }
    LaunchedEffect(Unit) {
        delay(100)
        showTooltip = true
    }


    var isItemHovered by remember { mutableStateOf(false) }
    var expandedVertically by remember { mutableStateOf(false) }
    val tooltipId = remember { "tooltip_${itemTitle}" }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .minHeight(Dimen.menuItemRowHeight)
        ,
        horizontalAlignment = Alignment.Start
    )
    {
        Row(
            modifier =Modifier
                .width(Dimen.menuItemRowWidthDesktopAndTablet)
                .minHeight(Dimen.menuItemRowHeight)
                .thenIf(
                    isMenuExpandedHorizontally,
                    when {
                        isSelected ->Modifier.backgroundColor(backgroundColor)
                        isItemHovered -> Modifier.backgroundColor(hoverColor)
                        else -> Modifier
                    }
                )
                .borderRadius(
                    if(isMenuExpandedHorizontally) 0.px else 50.percent
                )
                .onMouseOver { isItemHovered = true }
                .onMouseLeave { isItemHovered=false }
                .onClick {
                    expandedVertically = !expandedVertically
                    onRowClick()
                }
                .cursor(Cursor.Pointer)

            ,
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Start,
        )
        {
            Box(
                modifier = Modifier
                .width(Dimen.menuDefaultWidth)
            )
            {
                Box(
                    modifier = Modifier
                        .id(tooltipId)
                        .align(Alignment.Center)
                        .position(Position.Relative)
                        .circleHoverAroundItem(
                            showHover =false,
                            showMouseDownHover = !isMenuExpandedHorizontally,
                            onClick = {
                                expandedVertically = !expandedVertically
                                onRowClick()
                            })

                        .onMouseDown {isMouseDown.value=true}
                        .onMouseUp { isMouseDown.value=false }
                        .size(Dimen.menuItemParentSize)
                        .thenIf(
                            !isMenuExpandedHorizontally,
                            when {
                                isSelected ->Modifier.backgroundColor(clickedIconColor)
                                isMouseDown.value ->Modifier.backgroundColor(clickedIconColor)
                                isItemHovered-> Modifier.backgroundColor(hoverColor)
                                else -> Modifier
                            }
                        )
                ) 
                {
                        

                    MenuVectorIcon(
                        modifier = Modifier
                            .align(Alignment.Center),
                        pathData = imgSrc,
                        color = Theme.Gray61.hex
                    )

                    if (showTooltip && !isMenuExpandedHorizontally) {

                        Tooltip(
                            target = ElementTarget.withId(tooltipId),
                            text = itemTitle,
                            placement = PopupPlacement.Right,
                            hasArrow = false,
                            showDelayMs =100,
                            modifier = Modifier
                                .padding(
                                    leftRight = Dimen.normalPadding,
                                    topBottom = Dimen.smallPadding,
                                )
                                .height(Dimen.menuTooltipHeight)
                                .fontWeight(FontWeight.Medium)
                                .borderRadius(Dimen.lowBorderRadius)
                                .fontFamily(FontFamily.Jakarta)
                                .zIndex(Constants.ZIndex.largeZIndex)
                                .fontSize(FontSizes.normalFontSize)
                                .backgroundColor(Theme.Gray61.rgb)
                                .color(Theme.White.rgb)
                                .lineHeight(FontLineHeight.lineNormal)
                                .textAlign(TextAlign.Center)
                                .display(DisplayStyle.Flex)
                                .alignItems(AlignItems.Center)
                        )

                    }




                }
            }


            Row(
                modifier = Modifier
                    .width(Dimen.menuItemInfoWidth)
                    .attrsModifier {
                        style {
                            property("opacity",
                                if (isMenuExpandedHorizontally) "1" else "0")
                            property("overflow", "hidden")
                            property("transition",
                                " opacity ${Constants.fastExpandingTime}s ease")

                        }
                    },
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start,
            )
            {
                MenuItemTitle(
                    title=itemTitle
                )

                Spacer()

                if(subData.isNotEmpty()) {
                    expandingIcon(
                        expandedVertically = expandedVertically,
                        color = Theme.Primary.hex
                    )
                }
            }

        }

        SubMenu(
            subData=subData,
            hoverColor=hoverColor,
            expandedVertically=expandedVertically,
        )


    }


}

@Composable
private fun MenuItemTitle(title: String)
{
    SpanText(
        modifier = Modifier
            .fontFamily(FontFamily.Jakarta)
            .fontWeight(FontWeight.Normal)
            .color(Theme.Secondary.rgb)
            .fontSize(FontSizes.mediumFontSize)
            .height(Dimen.menuItemHeight)
            .textAlign(TextAlign.Center)
            .lineHeight(FontLineHeight.lineSemiLarge)
            .letterSpacing(LetterSpacing.small),
        text = title
    )
}

@Composable
private fun expandingIcon(
    expandedVertically: Boolean,
    color:String,
)
{
    MenuVectorIcon(
        modifier = Modifier
            .margin(right = Dimen.mediumPadding)
            .attrsModifier {
                if (!expandedVertically) {
                    style {
                        property(
                            "transform",
                            "rotate(180deg)"
                        )
                        property(
                            "transition",
                            "transform ${Constants.fastTransition} ease"
                        )
                    }
                } else {
                    style {
                        property(
                            "transform",
                            "rotate(0deg)"
                        )
                        property(
                            "transition",
                            "transform ${Constants.fastTransition} ease"
                        )
                    }
                }
            },
        pathData = Res.PathIcon.expandlessFilled,
        color =color
    )
    Image(
        src = Res.PathIcon.expandlessFilled,
    )
}




