package org.hs_soft.runmynesto.di.customer_card

import org.hs_soft.runmynesto.domain.usecase.customer_card.CreateLoyaltyCardUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.GetLoyaltyCardsUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.LoyaltyCardDetailUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.customer_card_tab.CreditClientUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.customer_card_tab.FilteredTransactionLoyaltyCardUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.customer_card_tab.UpdateLoyaltyCardUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.series.CreateCustomerCardUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.series.ResetRangeLoyaltyCardUseCase
import org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.series.UpdateRangeLoyaltyCardUseCase
import org.hs_soft.runmynesto.domain.usecase.home.FetchIdTokenUseCase
import org.koin.dsl.module

val customerCardUseCaseModule= module {

    single {
        FetchIdTokenUseCase(get())
    }

    single {
        GetLoyaltyCardsUseCase(get())
    }
    single {
        LoyaltyCardDetailUseCase(get())
    }
    single {
        CreditClientUseCase(get())
    }
    single {
        UpdateLoyaltyCardUseCase(get())
    }
    single {
        CreateCustomerCardUseCase(get())
    }
    single {
        UpdateRangeLoyaltyCardUseCase(get())
    }
    single {
        ResetRangeLoyaltyCardUseCase(get())
    }
    single {
        CreateLoyaltyCardUseCase(get())
    }
    single {
        FilteredTransactionLoyaltyCardUseCase(get())
    }


}
