package org.hs_soft.runmynesto.domain.model.home.product.products_list_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("listProducts")
    val listProducts: ListProducts,
    @SerialName("__typename")
    val typename: String?
)