package org.hs_soft.runmynesto.domain.usecase.options.virtual_card

import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config.VirtualCardConfigModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.virtual_card.UpdateVirtualCardInfoRepository


class UpdateVirtualCardInfoUseCase(
    private val repository: UpdateVirtualCardInfoRepository
) {
    suspend operator fun invoke(
        dataTitle:String,
        dataValue:dynamic,
    ):VirtualCardConfigModel {
        return repository.updateVirtualCardInfo(
            dataTitle=dataTitle,
            dataValue=dataValue,
        )
    }
}