package org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DataX(
    @SerialName("closingData")
    val closingData: ClosingData?,
    @SerialName("customerCount")
    val customerCount: Int?,
    @SerialName("realHours")
    val realHours: String?,
    @SerialName("shopId")
    val shopId: String?,
    @SerialName("total")
    val total: Double?,
    @SerialName("totalPerBill")
    val totalPerBill: Double?,
    @SerialName("totalPerRealTimeHours")
    val totalPerRealTimeHours: Double?,
    @SerialName("__typename")
    val typename: String?
)