package org.hs_soft.runmynesto.pages.home.sub_page.product.mvi

import androidx.compose.runtime.MutableState
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.Img
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.Properties
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.model.home.product.tabs.ProductInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.ingeredient_list.Ingredients
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.AppConstantsEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.kitchen_info.ProductAdditions
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.usecase.home.GetCompanyProfileIdUseCase
import org.hs_soft.runmynesto.domain.usecase.home.GetSavedCheckOutUseCase
import org.hs_soft.runmynesto.domain.usecase.product.AddProductUseCase
import org.hs_soft.runmynesto.domain.usecase.product.GetImgUrlUseCase
import org.hs_soft.runmynesto.domain.usecase.product.ProductCategoriesUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_allergens.CheckedIngredientsUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_allergens.UpdateAllergenUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_checkout.UpdateProductCheckoutUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_click_collect.UpdateProductPropertiesUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.GetSingleProductUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.IngredientListUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.ProductSaleGroupUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.UpdateProductCashBidImageUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.UpdateProductPriceUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.UpdateProductUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.cache.AddProductCategoryToDBUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.cache.GetCachedProductCategoriesUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.cache.SaveCachedProductCategoriesUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.CreateProductAdditionUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.KitchenInfoUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.PrinterLocationNamesUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.ProductRestaurantUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.UpdateProductAdditionUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.UpdateProductRestaurantUseCase
import org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general.GetDishPictureUseCase
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.handleError
import org.hs_soft.runmynesto.domain.util.helper.BaseViewModel
import org.hs_soft.runmynesto.domain.util.withTokenRefresh

class ProductViewModel(
    private val requestManager: RequestManager,
    private val refreshToken: () -> Unit,
    private val getDishPictureUseCase: GetDishPictureUseCase,
    private val checkedIngredientsUseCase: CheckedIngredientsUseCase,
    private val updateAllergenUseCase: UpdateAllergenUseCase,
    private val updateProductCheckoutUseCase: UpdateProductCheckoutUseCase,
    private val updateProductPropertiesUseCase: UpdateProductPropertiesUseCase,
    private val ingredientListUseCase: IngredientListUseCase,
    private val updateProductCashBidImageUseCase: UpdateProductCashBidImageUseCase,
    private val updateProductUseCase: UpdateProductUseCase,
    private val productSaleGroupUseCase: ProductSaleGroupUseCase,
    private val singleProductUseCase: GetSingleProductUseCase,
    private val kitchenInfoUseCase: KitchenInfoUseCase,
    private val productRestaurantUseCase: ProductRestaurantUseCase,
    private val printerLocationNamesUseCase: PrinterLocationNamesUseCase,
    private val updateProductRestaurantUseCase: UpdateProductRestaurantUseCase,
    private val addProductUseCase: AddProductUseCase,
    private val productCategoriesUseCase: ProductCategoriesUseCase,
    private val imgUrlUseCase: GetImgUrlUseCase,
    private val updateProductPriceUseCase: UpdateProductPriceUseCase,
    private val getCompanyProfileIdUseCase: GetCompanyProfileIdUseCase,
    private val addProductCategoryToDBUseCase: AddProductCategoryToDBUseCase,
    private val getCachedProductCategoriesUseCase: GetCachedProductCategoriesUseCase,
    private val saveCachedProductCategoriesUseCase: SaveCachedProductCategoriesUseCase,
    private val createProductAdditionUseCase: CreateProductAdditionUseCase,
    private val updateProductAdditionUseCase: UpdateProductAdditionUseCase,
    private val getSavedCheckOutUseCase: GetSavedCheckOutUseCase,
):BaseViewModel()
{

    private val _state = MutableStateFlow(ProductState())
    val state: StateFlow<ProductState> = _state
    private var tempCheckoutList: List<ChildrenX>? = listOf()
    private var tempIngredients: List<Ingredients>? = listOf()
    private var tempKitchenInfo:List<ProductAdditions?>? = listOf()




    override fun clear() {
//        super.clear()
        requestManager.cancelAll()
    }


    fun onEvent(event: ProductEvent) {
        when (event) {
            //Data

            is ProductEvent.OnSubmitKitchenInfoDialog->{
                submitKitchenInfoDialog(
                    id = event.id,
                    name=event.name,
                    variants=event.variants,
                )
            }

            is ProductEvent.OnAddKitchenItemIconClick->{
                onAddKitchenItemIconClick()
            }

            is ProductEvent.OnCancelKitchenItemDialog->{
                onCancelKitchenItemDialog()
            }

            is ProductEvent.OnRefreshKitchenInfoClick->{
                onRefreshKitchenInfoClick()
            }
            is ProductEvent.OnKitchenInfoItemClick->{
                onKitchenInfoItemClick(info=event.info)
            }

            is ProductEvent.OnSearchKitchenInfo->{
                searchInKitchenInfo(value=event.value)
            }

            is ProductEvent.GetKitchenInfo->{
                getKitchenInfo()
            }

            is ProductEvent.SetFilterItemsComponentStatus->{
                _state.value=_state.value.copy(
                    showItemsFilterComponent = event.status
                )
            }

            is ProductEvent.SubmitFilterItems->{
                submitFilterItems(type=state.value.selectedFilterItemsType)
            }
            is ProductEvent.SelectFilterType->{
                _state.value=_state.value.copy(selectedFilterItemsType = event.type)
            }

            is ProductEvent.RefreshProductsCategoryList->{
                refreshProductCategoryList()
            }
            is ProductEvent.GetProductsCategoryList->{
                getProductCategoryList( )

            }

            is ProductEvent.GetCachedBidImg->{
                getCashBidImg(
                    imageUrl = event.imageUrl,
                    key = event.key
                )

            }

            is ProductEvent.GetCheckedIngredients->{
                getCheckedIngredients(
                    productId=state.value.selectedProduct?.id?:"",
                )
            }
            is ProductEvent.UpdateAllergenContent->{
                updateAllergenContent(
                    ingredientId = event.ingredientId,
                    dataValue=event.content,
                    productId=state.value.selectedProduct?.id?:""
                )
            }


            is ProductEvent.GetCompanyProfileId->{
                try {
                    val result=getCompanyProfileIdUseCase()
                    _state.value=_state.value.copy(
                        companyProfileId =result
                    )
                }catch (e:Exception){
                    handleError(e)
                }

            }

            is ProductEvent.OnProductItemClick -> {
                onProductClick(
                    productId = event.id,
                    switchTheSecondPage =event.switchTheSecondPage
                )
            }

            is ProductEvent.UpdateProductInfo -> {
                updateProductInfo(
                    dataValue = event.value,
                    dataTitle = event.title,
                    id = state.value.selectedProduct?.id?:"",
                )
            }
            is ProductEvent.UpdateProductPrice -> {
                updateProductPrice(
                    dataValue = event.value,
                    dataTitle = event.title,
                    id = state.value.selectedProduct?.id?:"",
                )
            }

            is ProductEvent.UpdateProductProperties -> {
                updateProductProperties(
                    id = state.value.selectedProduct?.id?:"",
                    minOrder = event.minOrder,
                    maxOrder=event.maxOrder,
                    timeLeadDays=event.timeLeadDays,
                )
            }

            is ProductEvent.OnKitchenInfoTitleClick->{
                onKitchenInfoTitleClick()

            }

            is ProductEvent.UpdateRestaurantData -> {
                updateRestaurantData(
                    dataValue = event.value,
                    dataTitle = event.title,
                    id = state.value.selectedProduct?.id?:"",
                )
            }
            is ProductEvent.SetLocalCashBidImg->
            {
                _state.value=_state.value.copy(
                    cashBidImgAIGeneratorUrl = event.url,
                )
            }

            is ProductEvent.OnSelectCashBidImg-> {
                selectCashBidImg(
                    id=event.id,
                    img=event.url,
                )
            }

            is ProductEvent.OnChangeAiImageDescription->{
                _state.value=_state.value
                    .copy(imageAiGeneratorDescription = event.value)
            }

            is ProductEvent.UpdateCheckout -> {
                updateProductCheckout(
                    dataValue = event.dataValue,
                    id = state.value.selectedProduct?.id ?: ""
                )
            }

            is ProductEvent.GetCheckoutData -> {

                getCheckoutData( )
            }

            is ProductEvent.GetRestaurantData -> {
                getRestaurantData(
                    id = state.value.selectedProduct?.id?:"",
                )
            }

            is ProductEvent.GetPrinterLocationNames->{
                getPrintersLocation( )
            }

            is ProductEvent.GetProductSalesGroup ->{
                getProductSalesGroup()
            }
            is ProductEvent.GetIngredients->{
                getIngredients( )
            }

//            is ProductEvent.LoadAppConstants->{
//                loadAppConstants(
//                    scope=scope,
//                    idToken=idToken,
//                )
//            }

            //Search

            is ProductEvent.OnSearchCheckoutItems -> {
                searchCheckoutItems(value = event.value)

            }
            is ProductEvent.OnSearchIngredientItems->{
                searchIngredientItems(value=event.value)
            }

            is ProductEvent.OnSearchProductInputChange ->{
                onMainGridSearchProductInputChange(event.value)
            }




            is ProductEvent.OnMainGridNumberSortClick ->onMainGridNumberSortClick()
            is ProductEvent.OnMainGridTitleSortClick ->onMainGridTitleSortClick()




            //Other
            is ProductEvent.OnTabClick -> {
                onTabClick(selectedTab = event.selectedTab)
            }
            is ProductEvent.OnIngredientsNameHeaderClick->{
                onIngredientsNameHeaderClick()

            }

            is ProductEvent.OnIngredientsNumberHeaderClick->{
                onIngredientsNumberHeaderClick()
            }

            is ProductEvent.OnChangePageClick->changePage()

            //Image Processing
            is ProductEvent.SetGenerateCashBidAIImgDialogStatus->{
                _state.value=_state.value.copy(
                    showGenerateAIImgDialog = event.status,
                    cashBidImgAIGeneratorUrl = Res.PathIcon.defaultRectPhoto,
                )
            }
            is ProductEvent.GenerateProductCashBidImg->{
                generateProductCashBidImg(
                    description=event.description,
                )
            }


            //Add Record
            is ProductEvent.OnAddRecordIconClick->{
                onAddRecordIconClick()
            }
            is ProductEvent.OnAddRecordDialogBackgroundClick->{}//dismissAddRecordDialog()
            is ProductEvent.OnCancelRecordDialogClick->dismissAddRecordDialog()

            is ProductEvent.OnChangeRecordName->onChangeRecordName(event.value)
            is ProductEvent.OnChangeRecordNumber->onChangeRecordNumber(event.value)

            is ProductEvent.OnSubmitAddRecordDialogClick->{
                onSubmitRecordDialogClick(
                    windowMode = event.windowMode,
                    name=state.value.newRecordName,
                    code =state.value.newRecordNumber,
                )

            }

        }


    }



    private fun submitKitchenInfoDialog(
        id:String,
        name: String,
        variants: List<String?>
    ) {
        if (state.value.selectedKitchenInfoItem==null){
            addKitchenInfoItem(
                name=name,
                variants=variants
            )

        }else{
            updateKitchenInfoItem(
                id = id,
                name=name,
                variants=variants,
            )
        }

    }

    private fun addKitchenInfoItem(name: String, variants: List<String?>) {

        launch {

            try {
                withTokenRefresh(refreshToken) {
                    _state.value = _state.value.copy(kitchenInfoDialogLoading = true)
                    createProductAdditionUseCase(
                        name = name,
                        variants = variants,
                    )
                    _state.value = _state.value.copy(
                        kitchenInfoDialogLoading = false,
                        showKitchenAddItemDialog = false
                    )
                    getKitchenInfo()
                }

            } catch (e: Exception) {
                _state.value = _state.value.copy(kitchenInfoDialogLoading = false)
                handleError(e)
            }
        }
    }

    private fun updateKitchenInfoItem(
        id:String,
        name: String,
        variants: List<String?>,
    ) {
        launch {

            try {
                withTokenRefresh(refreshToken) {
                    _state.value = _state.value.copy(kitchenInfoDialogLoading = true)
                    updateProductAdditionUseCase(
                        id=id,
                        name = name,
                        variants = variants,
                    )
                    _state.value = _state.value.copy(
                        kitchenInfoDialogLoading = false,
                        showKitchenAddItemDialog = false,
                    )
                    getKitchenInfo()
                }

            } catch (e: Exception) {
                _state.value = _state.value.copy(kitchenInfoDialogLoading = false)
                handleError(e)
            }
        }
    }


    private fun onRefreshKitchenInfoClick() {
        _state.value = _state.value.copy(kitchenInfo = tempKitchenInfo)
    }

    private fun onCancelKitchenItemDialog() {
        _state.value = _state.value.copy(
            showKitchenAddItemDialog = false,
            selectedKitchenInfoItem = null
        )
    }

    private fun onAddKitchenItemIconClick() {
        _state.value = _state.value.copy(
            showKitchenAddItemDialog = true,
            selectedKitchenInfoItem = null
        )
    }

    private fun onKitchenInfoItemClick(info: ProductAdditions?) {
        _state.value=_state.value.copy(
            selectedKitchenInfoItem = info,
            showKitchenAddItemDialog = true
        ) 
    }

    private fun searchInKitchenInfo(value:String) {
        _state.value = _state.value.copy(kitchenInfo = tempKitchenInfo)
        val newData = _state.value.kitchenInfo?.filter { model ->
            model?.name?.contains(value, ignoreCase = true) ?: false
        }
        _state.value = _state.value.copy(kitchenInfo = newData)
    }

    private fun onKitchenInfoTitleClick()
    {
        when (state.value.restaurantTempItemsDescendingSort) {
            false -> {
                _state.value = _state.value.copy(
                    kitchenInfo = state.value.kitchenInfo?.sortedByDescending { it?.name?.lowercase() },
                    restaurantTempItemsDescendingSort = true
                )
            }

            true -> {
                _state.value = _state.value.copy(
                    kitchenInfo = state.value.kitchenInfo?.sortedBy { it?.name?.lowercase() },
                    restaurantTempItemsDescendingSort = false
                )
            }

            null -> {
                _state.value = _state.value.copy(
                    kitchenInfo = state.value.kitchenInfo?.sortedBy { it?.name?.lowercase() },
                    restaurantTempItemsDescendingSort = false
                )
            }
        }
    }


    private fun getCashBidImg(
        imageUrl:MutableState<String>,
        key:String
    ) {
        try {
            launch {
                val result = imgUrlUseCase( key)
                imageUrl.value = result
            }

        } catch (e: Exception) {
            handleError(e)
        }
    }


    private fun getKitchenInfo( )
    {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=kitchenInfoUseCase()
                    hideProgress()
                    _state.value=_state.value.copy(
                        kitchenInfo = result.data?.cashAssist?.listProductAdditions
                    )
                    tempKitchenInfo=state.value.kitchenInfo
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }



        }

    }

    private fun submitFilterItems(type: GridItemsFilterEnum) {

        getCachedProductCategoriesUseCase {
            when(type){
                GridItemsFilterEnum.ALL_ITEMS->{
                    _state.value=_state.value.copy(
                        productsInfo = it?: listOf()
                    )


                }
                GridItemsFilterEnum.ACTIVE_ITEMS->{
                    _state.value=_state.value.copy(
                        productsInfo = it?.filter {model->
                            model.isActive?:false
                        }?: listOf()
                    )


                }
                GridItemsFilterEnum.INACTIVE_ITEMS->{
                    _state.value=_state.value.copy(
                        productsInfo = it?.filter {model->
                            model.isActive==false || model.isActive==null
                        }?: listOf()
                    )


                }
            }
        }




    }


    private fun onSubmitRecordDialogClick(
        name: String,
        code: String,
        windowMode: WindowSizeModeEnum,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    _state.value=_state.value.copy(addRecordLoading = true)

                    val result=addProductUseCase(
                        name=name,
                        code=code,
                    )
                    val model=result.data?.cashAssist?.createMutation


                    val newItem= ProductItem(
                        checkOutCommandId ="" ,
                        code=code,
                        fAProductId ="",
                        id =model?.id?:"" ,
                        isActive =model?.isActive?:false ,
                        name=model?.name,
                        price =model?.price ,
                        priceInCafe =model?.priceInCafe ,
                    )


                    addProductCategoryToDBUseCase(newItem)

                    _state.value=_state.value.copy(
                        addRecordLoading = false,
                        showAddRecordDialog = false,
                        checkedIngredients = listOf(),
                        addRecordErrorMessage = "",
                        productsInfo = state.value.productsInfo+newItem,
                        productSalesGroup= listOf(),
                        selectedProduct = state.value.selectedProduct?.copy(
                            id = model?.id?:"",
                            cashRegisterId = model?.cashRegisterId?: listOf(),
                            isActive = model?.isActive?:false,
                            name = model?.name?:"",
                            code = code,
                            codeMWSt = AppConstantsEnum.VAT_NORMAL.value,
                            codeMWSt2 = AppConstantsEnum.VAT_NORMAL.value,
                            img = null,
                            isNetto = false,
                            isEmployeeArticle = false,
                            isNettoForUser = false,
                            price =model?.price,
                            priceInCafe = model?.priceInCafe,
                            properties = Properties(
                                clickCollectLeadDaysCount = null,
                                clickCollectOrderMax = null,
                                clickCollectOrderMin = null,
                            ),
                            salesGroupId = null

                        ),
                        newRecordName = "",
                        newRecordNumber = "",

                        firstPageActive = if(windowMode== WindowSizeModeEnum.PHONE) false
                        else true

                    )
                }

            }catch (e:Exception){
                _state.value=_state.value.copy(addRecordLoading = false)
                _state.value=_state.value.copy(
                    addRecordErrorMessage = Strings.numberOrNameIsWrong
                )

                handleError(
                    error = e,
                )
            }


        }

    }

    private fun onChangeRecordNumber(value: String) {
        _state.value=_state.value.copy(
            newRecordNumber = value,
            addRecordErrorMessage = ""
        )
        checkAddRecordSubmitClickable()
    }

    private fun onChangeRecordName(value: String) {
        _state.value=_state.value.copy(
            newRecordName = value,
            addRecordErrorMessage = ""
        )
        checkAddRecordSubmitClickable()
    }

    private fun checkAddRecordSubmitClickable(){
        if (
            state.value.newRecordNumber.isNotEmpty()
            && state.value.newRecordName.isNotEmpty()
        ){
            _state.value=_state.value.copy(
                isAddNewRecordSubmitClickable = true
            )
        }else _state.value=_state.value.copy(
            isAddNewRecordSubmitClickable = false
        )
    }

    private fun dismissAddRecordDialog() {
        _state.value=_state.value.copy(
            showAddRecordDialog = false,
            addRecordErrorMessage = ""
        )
    }

    private fun onAddRecordIconClick() {
        _state.value=_state.value.copy(
            showAddRecordDialog = true
        )
    }


    private fun getCheckedIngredients(
        productId: String
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=checkedIngredientsUseCase(productId=productId)
                    hideProgress()
                    _state.value=_state.value.copy(
                        checkedIngredients = if (result==null) listOf()
                        else result.data?.cashAssist?.getProduct?.ingredients
                    )
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }


    }

    private fun updateAllergenContent(
        dataValue: Boolean,
        ingredientId:String,
        productId: String
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=updateAllergenUseCase(
                        productId=productId,
                        dataValue=dataValue,
                        ingredientId=ingredientId
                    )
                    _state.value=_state.value.copy(
                        checkedIngredients =
                        result.data?.cashAssist?.updateProduct?.ingredients
                    )
                    hideProgress()
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }



    }

    private fun generateProductCashBidImg(
        description: String
    ) {
       launch {

            try {
                withTokenRefresh(refreshToken) {
                    _state.value=_state.value.copy(isImageAiGeneratorLoading = true)
                    val result=getDishPictureUseCase(description=description)

                    val imageDataUrl = "data:image/jpeg;base64,$result"
                    _state.value=_state.value.copy(isImageAiGeneratorLoading = false)
                    _state.value = _state.value.copy(
                        cashBidImgAIGeneratorUrl =imageDataUrl
                    )
                }

            }catch (e:Exception){
                _state.value=_state.value.copy(isImageAiGeneratorLoading = false)
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun selectCashBidImg(
        id: String,
        img: String,
    )
    {

        launch {
            try {
                withTokenRefresh(refreshToken) {
                    _state.value=_state.value.copy(isImageAiGeneratorLoading = true,)
                    showProgress()
                    val model=updateProductCashBidImageUseCase(
                        id=id,
                        image = img
                    ).data?.cashAssist?.img
                    hideProgress()
                    _state.value=_state.value.copy(
                        cashBidImgAIGeneratorUrl = Res.PathIcon.defaultRectPhoto,
                        showGenerateAIImgDialog = false,
                        isImageAiGeneratorLoading = false,
                        selectedProduct = state.value.selectedProduct
                            ?.copy(
                                img= Img(
                                    key =model?.key?:"",
                                    uid = model?.uid?:""
                                )
                            )

                    )
                }

            }catch (e:Exception){
                hideProgress()
                _state.value=_state.value.copy(
                    isImageAiGeneratorLoading = false
                )
                handleError(
                    error = e,
                )
            }

        }


    }

    private fun changePage() {
        _state.value=_state.value.copy(
            firstPageActive = !_state.value.firstPageActive,
        )
    }



    private fun onMainGridNumberSortClick() {
        val newData:List<ProductItem>
        if (_state.value.isMainGridNumberSortedAsc) {
            newData=_state.value.productsInfo.sortedByDescending { it.code }
        }else{
            newData=_state.value.productsInfo.sortedBy { it.code }.toList()
        }
        _state.value=_state.value.copy(
            productsInfo = newData,
            isMainGridNumberSortedAsc =!_state.value.isMainGridNumberSortedAsc,
            showMainGridNumberSortIcon = true,
            showMainGridNameSortIcon = false
        )
    }

    private fun onMainGridTitleSortClick() {
        val newData:List<ProductItem>
        if (_state.value.isMainGridNameSortedAsc) {
            newData=_state.value.productsInfo.sortedByDescending { it.name?.lowercase() }
        }else{
            newData=_state.value.productsInfo.sortedBy { it.name?.lowercase() }.toList()
        }
        _state.value=_state.value.copy(
            productsInfo = newData,
            isMainGridNameSortedAsc =!_state.value.isMainGridNameSortedAsc,
            showMainGridNameSortIcon = true,
            showMainGridNumberSortIcon = false
        )
    }

    private fun onMainGridSearchProductInputChange(value: String) {
        getCachedProductCategoriesUseCase {
            if (value.isNotEmpty()){
                val newData=it?.filter {model ->
                    model.name?.contains(value, ignoreCase = true) ?: false
                }
                _state.value=_state.value.copy(
                    productsInfo = newData?: listOf()
                )
            }else {
                _state.value=_state.value.copy(
                    productsInfo = it?: listOf()
                )
            }

        }

    }

    private fun refreshProductCategoryList(
        selectedProductId: String? = null
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=productCategoriesUseCase().data?.cashAssist?.listProducts
                    hideProgress()
                    saveCachedProductCategoriesUseCase(result?.items?: listOf() )

                    _state.value=_state.value.copy(
                        productsInfo = result?.items?: listOf()
                    )

                    if (state.value.productsInfo.isNotEmpty() && selectedProductId == null) {
                        onProductClick(
                            productId = selectedProductId ?: state.value.productsInfo[0].id,
                        )
                    }

                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }
    }



    private fun getProductCategoryList(  )
    {
        showProgress()
        getCachedProductCategoriesUseCase{result->
            if (result==null || result.isEmpty()){
                console.log()
                refreshProductCategoryList( )
            }
            else {
                launch {
                    _state.value=_state.value.copy(productsInfo = result )
                    if (
                        state.value.productsInfo.isNotEmpty()
                        && state.value.selectedProduct?.id == null
                    ) {
                        onProductClick(
                            productId = state.value.productsInfo[0].id,
                        )
                    }


                }
            }
        }


    }

    private fun onIngredientsNumberHeaderClick() {
        _state.value = _state.value.copy(
            showAllergensNumberSortIcon =true,
            showAllergensNameSortIcon = false,
            isAllergensNumberSortedAsc = !state.value.isAllergensNumberSortedAsc
        )
        sortIngredientsList()
    }

    private fun onIngredientsNameHeaderClick() {
        _state.value = _state.value.copy(
            showAllergensNameSortIcon =true,
            showAllergensNumberSortIcon = false,
            isAllergensNameSortedAsc = !state.value.isAllergensNameSortedAsc
        )
        sortIngredientsList()
    }

    private fun sortIngredientsList()
    {
        val newData: List<Ingredients>? = if (state.value.showAllergensNameSortIcon
            && !state.value.showAllergensNumberSortIcon) {
            if (!state.value.isAllergensNameSortedAsc) {
                state.value.ingredients?.sortedByDescending { it.name?.lowercase() } ?: listOf()
            } else {
                state.value.ingredients?.sortedBy { it.name?.lowercase() } ?: listOf()
            }
        } else if (state.value.showAllergensNumberSortIcon && !state.value.showAllergensNameSortIcon) {
            if (!state.value.isAllergensNumberSortedAsc) {
                state.value.ingredients?.sortedByDescending { it.code } ?: listOf()
            } else {
                state.value.ingredients?.sortedBy { it.code } ?: listOf()
            }
        } else tempIngredients


        _state.value = _state.value.copy(ingredients = newData)
    }

    private fun searchIngredientItems(value: String) {
        _state.value= _state.value.copy(ingredients =tempIngredients )

        val newData=_state.value.ingredients?.filter {model ->
            model.name?.lowercase()?.contains(value, ignoreCase = true) ?: false
        }
        _state.value=_state.value.copy(
            ingredients = newData
        )

    }

    private fun getIngredients(   ) {
        launch {
            if (state.value.ingredients==null)
            {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()
                        val result=ingredientListUseCase()
                        hideProgress()
                        _state.value=_state.value.copy(
                            ingredients = result.data.cashAssist.listIngredients
                        )

                        tempIngredients=state.value.ingredients
                    }

                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }

            }


        }

    }


    private fun getProductSalesGroup( ) {
        if (state.value.productSalesGroup==null)
        {
            launch {
                try {
                    showProgress()
                    val result=productSaleGroupUseCase()
                    _state.value=_state.value.copy(
                        productSalesGroup =
                        result.data.cashAssist.listSalesGroups.
                        items.sortedBy { it.name }.toList()
                    )
                    hideProgress()//TODO(If loading had a problem , you must check this)
                }catch (e:Exception){
                    hideProgress()//TODO(If loading had a problem , you must check this)
                    handleError(
                        error = e,
                    )
                }

            }

        }

    }

    private fun getPrintersLocation(   ) {
//        if (state.value.printersLocation==null)
//        {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=printerLocationNamesUseCase()
                        .data.cashAssist.getAppConfig?.cashRegisterGlobal
                        ?.printerLocations
                    hideProgress()
                    _state.value=_state.value.copy(printersLocation =result)
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }


        }
//        }



    }

    private fun updateRestaurantData(
        dataValue: dynamic,
        dataTitle: String,
        id: String
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val model=updateProductRestaurantUseCase(
                        productId = id,
                        dataTitle=dataTitle,
                        dataValue=dataValue
                    ).data.cashAssist.updateProduct

                    hideProgress()
                    _state.value=_state.value.copy(
                        restaurantData = state.value.restaurantData?.copy(
                            isAdditionRequired = model.isAdditionRequired,
                            isPrintOnlyInCafe =model.isPrintOnlyInCafe ,
                            printerLocations = model.printerLocations,
                            additions = model.additions?: listOf()
                        )
                    )
                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }
    }

    private fun getRestaurantData(
        id: String
    ) {
//        if(state.value.restaurantDataModel==null){
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=productRestaurantUseCase(id=id)
                    hideProgress()
                    _state.value = _state.value.copy(
                        restaurantData =result.data.cashAssist.getProduct
                    )
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }


    }

    private fun searchCheckoutItems(value: String) {
        _state.value = _state.value.copy(cashList = tempCheckoutList)

        if (value == "") {
            _state.value = _state.value.copy(
                checkOutExpandAll = false,
            )
        } else {
            val searchedList = ArrayList<ChildrenX>()
            state.value.cashList?.forEach { model ->
                var matchFound = model.name?.lowercase()?.contains(value.lowercase()) ?: false
                model.children?.forEach { childXX ->
                    if (childXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                        matchFound = true
                    }
                    childXX.children.forEach { childXXX ->
                        if (childXXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                            matchFound = true
                        }
                    }
                }
                if (matchFound) {
                    searchedList.add(model)
                }
            }


            _state.value = _state.value.copy(
                checkOutExpandAll = true,
                cashList = searchedList
            )

        }
    }

    private fun getCheckoutData(  ) {
        launch {
            if (state.value.cashList==null){

                val result=getSavedCheckOutUseCase()

                _state.value=_state.value.copy(
                    cashList = result?.data?.cashAssist
                        ?.children?.get(0)?.children
                )
                tempCheckoutList=state.value.cashList
            }

        }

    }

    private fun updateProductCheckout(
        dataValue: MutableSet<String>,
        id: String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=updateProductCheckoutUseCase(
                        userId = id,
                        dataValue=dataValue,
                    )
                    _state.value=_state.value.copy(
                        selectedProduct = state.value.selectedProduct?.copy(
                            cashRegisterId = result.data.cashAssist.updateProduct?.cashRegisterId?: listOf()
                        )
                    )
                    hideProgress()
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }
    }

    private fun updateProductInfo(
        dataTitle: String,
        dataValue: dynamic,
        id: String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=updateProductUseCase(
                        productId = id,
                        dataTitle=dataTitle,
                        dataValue=dataValue
                    )
                    val item=result.data.cashAssist.updateProduct

                    _state.value=_state.value.copy(
                        selectedSaleGroupId = item.salesGroupId,
                        selectedVat = item.codeMWSt,
                        selectedVat2 = item.codeMWSt2,
                        selectedProduct =state.value.selectedProduct?.copy(
                            code = item.code,
                            isActive = item.isActive?:false,
                            name = item.name?:"",
                            isNetto = item.isNetto,
                            isNettoForUser = item.isNettoForUser,
                            isEmployeeArticle = item.isEmployeeArticle,
                        )

                    )
                    if (
                        dataTitle== ApiUtils.Field.code
                        || dataTitle== ApiUtils.Field.name
                        || dataTitle== ApiUtils.Field.isActive
                    )
                    {
                        _state.value=_state.value.copy(imageAiGeneratorDescription =item.name?:"")
                        refreshProductCategoryList( )
                    }else hideProgress()
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }
        }
    }

    private fun updateProductPrice(
        dataTitle: String,
        dataValue: List<Double?>,
        id: String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    updateProductPriceUseCase(
                        dataTitle=dataTitle,
                        dataValue=dataValue,
                        productId = id,
                    )

                    hideProgress()
                    console.log("$dataTitle Successfully changed to $dataValue")

                    when(dataTitle){
                        ApiUtils.Field.price->{
                            _state.value=_state.value.copy(
                                selectedProduct = state.value.selectedProduct
                                    ?.copy(price = dataValue)
                            )
                        }
                        ApiUtils.Field.priceInCafe->{
                            _state.value=_state.value.copy(
                                selectedProduct = state.value.selectedProduct
                                    ?.copy(priceInCafe = dataValue)
                            )
                        }



                    }
                }


            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }


        }
    }

    private fun updateProductProperties(
        id: String,
        minOrder: Double?,
        maxOrder: Double?,
        timeLeadDays: Double?,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=updateProductPropertiesUseCase(
                        productId = id,
                        minOrder=minOrder,
                        maxOrder=maxOrder,
                        timeLeadDays=timeLeadDays
                    ).data.cashAssist.updateProductProperties.properties
                    hideProgress()
                    console.log("Successfully changed to $minOrder,$maxOrder,$timeLeadDays")
                    _state.value = _state.value.copy(
                        properties = Properties(
                            clickCollectLeadDaysCount = result.clickCollectLeadDaysCount,
                            clickCollectOrderMax = result.clickCollectOrderMax,
                            clickCollectOrderMin = result.clickCollectOrderMin,
                        )
                    )
                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }
    }


    private fun onProductClick(
        productId: String,
        switchTheSecondPage:() -> Unit ={},
    ) {
        if (productId!=state.value.selectedProduct?.id){
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()
                        val result=singleProductUseCase(id = productId)
                            .data.cashAssist.getProduct

                        hideProgress()
                        _state.value = _state.value.copy(
                            selectedProduct = result,
                            properties = result.properties,
                            selectedSaleGroupId = result.salesGroupId,
                            selectedVat = result.codeMWSt,
                            selectedVat2 = result.codeMWSt2,
                            imageAiGeneratorDescription=result.name
                        )
                        switchTheSecondPage()
                    }

                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }

            }
        }else  switchTheSecondPage()



    }

    private fun onTabClick(selectedTab: ProductInfoHeaderEnum) {
        _state.value = _state.value.copy(
            selectedTab = selectedTab,
        )

    }

    private fun showProgress() {
        _state.value = _state.value.copy(
            dataLoadingProgressVisible = true
        )
    }

    private fun hideProgress() {
        _state.value = _state.value.copy(
            dataLoadingProgressVisible = false
        )
    }



}
