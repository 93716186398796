package org.hs_soft.runmynesto.di.customer_card

import org.hs_soft.runmynesto.data.api_datasource.authentication.FetchIdTokenRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.customer_card.CreateLoyaltyCardRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.customer_card.tabs.customer_card_tab.CreditClientRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.customer_card.LoyaltyCardDetailRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.customer_card.GetLoyaltyCardsRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.customer_card.tabs.customer_card_tab.FilteredLoyaltyCardTransactionRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.customer_card.tabs.customer_card_tab.UpdateLoyaltyCardRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.customer_card.tabs.series.CreateCustomerCardRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.customer_card.tabs.series.ResetRangeLoyaltyCardRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.customer_card.tabs.series.UpdateRangeLoyaltyCardRepositoryImpl
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.CreateLoyaltyCardRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.customer_card_tab.CreditClientRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.GetLoyaltyCardsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.LoyaltyCardDetailRepostiory
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.customer_card_tab.FilteredTransactionLoyaltyCardRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.customer_card_tab.UpdateLoyaltyCardRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.series.CreateCustomerCardRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.series.ResetRangeLoyaltyCardRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.series.UpdateRangeLoyaltyCardRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.home.FetchIdTokenRepository
import org.koin.dsl.module


val customerCardRepositoryModule= module {

    single<FetchIdTokenRepository> {
        FetchIdTokenRepositoryImpl()
    }
    single<GetLoyaltyCardsRepository> {
        GetLoyaltyCardsRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<LoyaltyCardDetailRepostiory> {
        LoyaltyCardDetailRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }


    single<CreditClientRepository> {
        CreditClientRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }
    single<UpdateLoyaltyCardRepository> {
        UpdateLoyaltyCardRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<CreateCustomerCardRepository> {
        CreateCustomerCardRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<UpdateRangeLoyaltyCardRepository> {
        UpdateRangeLoyaltyCardRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }
    single<ResetRangeLoyaltyCardRepository> {
        ResetRangeLoyaltyCardRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<CreateLoyaltyCardRepository> {
        CreateLoyaltyCardRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<FilteredTransactionLoyaltyCardRepository> {
        FilteredLoyaltyCardTransactionRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }



}