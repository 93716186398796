package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.update_template_category


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateCategory(
    @SerialName("id")
    val id: String?,
    @SerialName("name")
    val name:String?,
    @SerialName("validAfter")
    val validAfter: String?,
    @SerialName("weekDays")
    val weekDays: Int?
)