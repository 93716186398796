package org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.update_restaurant_data.UpdateRestaurantModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.UpdateProductRestaurantRepository


class UpdateProductRestaurantUseCase(
    private val repository: UpdateProductRestaurantRepository
) {
    suspend operator fun invoke(
        productId: String,
        dataTitle:String,
        dataValue:dynamic,
    ): UpdateRestaurantModel {
        return repository.updateRestaurant(
            productId=productId,
            dataTitle=dataTitle,
            dataValue=dataValue
        )
    }


}