package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Location(
    @SerialName("copyForWaiter")
    val copyForWaiter: Boolean?,
    @SerialName("ip")
    val ip: String?,
    @SerialName("ipDeviceLabel")
    val ipDeviceLabel: String?,
    @SerialName("isActive")
    val isActive: Boolean?,
    @SerialName("isDirectConnected")
    val isDirectConnected: Boolean?,
    @SerialName("position")
    val position: Int?,
    @SerialName("tableShiftReceipt")
    val tableShiftReceipt: Boolean?,
    @SerialName("__typename")
    val typename: String?
)