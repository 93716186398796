package org.hs_soft.runmynesto.domain.usecase.dashboard

import org.hs_soft.runmynesto.domain.model.home.dashboard.init_shop_statics.InitShopStaticsModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.InitShopStaticsRepository

class InitShopStaticsUseCase(
    private val repository: InitShopStaticsRepository
) {
    suspend operator fun invoke(
        cashRegisterIds:List<String>,
        from:String,
        to:String,
    ): InitShopStaticsModel {
        return repository.initShopStatics(
            cashRegisterIds=cashRegisterIds,
            from =from ,
            to=to,
        )
    }
}