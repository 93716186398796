package org.hs_soft.runmynesto.domain.util


object Res{
    object PathIcon{


        const val avatar="/avatar.png"
        const val pickImgCircleSmall="/pick_img_circle_small.png"
        const val barcode="/barcode.png"
        const val defaultCirclePhoto: String="/pick_img.png"
        const val defaultRectPhoto:String="/pick_img_rect.png"
        const val walletRectPhoto="/pick_img_rect_wallet.png"
        const val gallerySmallRect="/pick_img_rect_small.png"
        const val galleryBackground="url('/gallery.svg')"
        const val logo:String="/logo_img.png"
        const val restaurant="/restaurant.png"
        const val cashAssist="cash_assist.png"
        const val smartPicking="smart_picking.png"
        const val wawiAssist="wawi_assist.png"
        const val console="console.png"
        const val rezeptAssist="rezept_assist.png"

        const val creditCard="M18.53 11.768c.622-.42 1.432-.42 2.048 0 .835.566 1.058 1.704.492 2.54-.566.835-1.704 1.058-2.54.492-.304.208-.658.314-1.022.314-1.012 0-1.827-.825-1.827-1.831 0-1.007.815-1.823 1.827-1.823.364 0 .718.106 1.022.314zm.496 2.144c.344.288.855.242 1.144-.1.288-.344.242-.855-.1-1.144-.344-.288-.855-.242-1.144.1-.025.03-.05.056-.075.075-.217.178-.537.142-.715-.075-.152-.188-.384-.299-.627-.299-.456 0-.82.365-.815.821 0 .45.37.814.821.814.243.005.475-.107.627-.294.096-.116.238-.182.389-.182.146-.005.293.061.389.178.001.001.002.003.004.004.034.038.063.072.101.101zM23.403 6.279c0-.023.005-.046.01-.07.005-.025.01-.051.01-.076 0-.025-.005-.051-.01-.075-.005-.023-.01-.046-.01-.07V4.972c0-1.188-.976-2.14-2.166-2.14h-.617V1.944c0-1.188-.946-2.17-2.134-2.17H2.144C.954-.226 0 .726 0 1.944v8.893c-.005 1.178.951 2.14 2.13 2.144h.016h.587v.612c.005 1.194.966 2.165 2.16 2.17h14.492c1.194-.01 2.165-.976 2.17-2.17V8.675c0-.023.005-.047.01-.071.005-.024.01-.048.01-.071 0-.023-.005-.047-.01-.071-.005-.024-.01-.047-.01-.071V6.279zm-18.818-1.346v7.27h-.587c-.617.005-1.128-.491-1.133-1.108v-.016V1.944c0-.628.501-1.158 1.133-1.158h14.492c.632 0 1.123.53 1.123 1.158v.612H4.891c-1.188 0-2.165.952-2.165 2.14zm16.652 10.051c.637-.005 1.148-.52 1.153-1.158V9.278H3.743v4.548c0 .637.516 1.153 1.153 1.158h14.492zm1.153-6.982H3.743V6.687h17.647v1.315zm0-2.327h.005v-.744c0-.632-.52-1.123-1.153-1.123H4.897h-.005c-.632-.005-1.104.501-1.108 1.123v.744h17.647z"
        const val statistic="  M13.4942 4.67755V10.7598C14.4215 11.3951 14.9744 12.446 14.9744 13.5725C14.9744 15.4511 13.4496 16.9759 11.5675 16.9759C9.68895 16.9759 8.1641 15.4511 8.1641 13.5691C8.16067 12.8616 8.38047 12.1713 8.78915 11.5978V6.47028C8.78915 6.27795 8.94713 6.14401 9.13602 6.14401H10.4651C10.654 6.14401 10.8154 6.28139 10.8154 6.47028V10.2515C11.0283 10.2 11.2481 10.1725 11.4679 10.1656V4.67755C11.4679 4.48866 11.6328 4.35815 11.8217 4.35815H13.1508C13.3397 4.35815 13.4942 4.48866 13.4942 4.67755ZM12.8073 5.04502H12.1548V10.2171C12.378 10.2515 12.5978 10.3133 12.8073 10.3957V5.04502ZM9.47602 6.83088H10.1285V10.4885C9.89845 10.5949 9.67865 10.7254 9.47602 10.88V6.83088ZM11.571 16.2856C12.5292 16.2856 13.4186 15.7808 13.9098 14.9531C14.6756 13.6618 14.2497 11.9961 12.9584 11.2303C12.6047 11.0173 12.2029 10.8903 11.7908 10.8594H11.7873C11.7152 10.8525 11.6465 10.8491 11.5744 10.8491C11.2584 10.8491 10.9425 10.904 10.6471 11.0139C10.6128 11.0345 10.575 11.0517 10.5338 11.0586C9.51723 11.4775 8.85441 12.4701 8.85441 13.5691C8.85441 15.0664 10.0702 16.2856 11.571 16.2856Z\n" +
                "    M12.3438 14.7504C11.9214 14.9084 11.4474 14.8363 11.0937 14.5581H12.3438C12.5327 14.5581 12.6872 14.4035 12.6872 14.2146C12.6872 14.0258 12.5327 13.8712 12.3438 13.8712H10.6541C10.6369 13.8025 10.6266 13.7338 10.6197 13.6652H12.5224C12.7147 13.6652 12.8692 13.5106 12.8692 13.3217C12.8692 13.1328 12.7147 12.9783 12.5258 12.9783H10.7674C10.8292 12.8684 10.9014 12.7654 10.9906 12.6726C11.3444 12.3189 11.8733 12.2056 12.3438 12.3841C12.5224 12.4528 12.7216 12.3635 12.7868 12.1849C12.8521 12.0064 12.7662 11.8072 12.5876 11.7419C11.8664 11.4672 11.049 11.6389 10.503 12.1884C10.2866 12.4116 10.1218 12.6829 10.0256 12.9783H10.0187C9.82984 12.9783 9.67529 13.1294 9.67529 13.3183C9.67529 13.4763 9.78176 13.6136 9.93287 13.6514C9.9363 13.727 9.94317 13.8025 9.95691 13.8746C9.77145 13.909 9.64782 14.0876 9.68216 14.2765C9.71307 14.4379 9.85388 14.5581 10.0187 14.5581H10.2007C10.2866 14.6989 10.3862 14.8328 10.503 14.9496C11.049 15.4957 11.8664 15.6674 12.5876 15.3926C12.7662 15.3239 12.8521 15.1282 12.7868 14.9496C12.7182 14.771 12.5224 14.6852 12.3438 14.7504Z\n" +
                "    M3.7409 8.8916H5.08029C5.26918 8.8916 5.42372 9.04615 5.42372 9.23504V14.627C5.42372 14.8158 5.26918 14.9704 5.08029 14.9704H3.7409C3.55201 14.9704 3.39746 14.8158 3.39746 14.627V9.23504C3.39746 9.04615 3.55201 8.8916 3.7409 8.8916ZM4.08433 14.3179H4.73685V9.57847H4.08433V14.3179Z\n" +
                "    M6.45379 8.10156H7.79318C7.98207 8.10156 8.13661 8.25611 8.13661 8.445V14.6612C8.13661 14.85 7.98207 15.0046 7.79318 15.0046H6.45379C6.2649 15.0046 6.11035 14.85 6.11035 14.6612V8.445C6.11035 8.25611 6.2649 8.10156 6.45379 8.10156ZM6.79722 14.3177H7.44975V8.78843H6.79722V14.3177Z\n" +
                "    M1.02752 11.845H2.36691C2.5558 11.845 2.71034 11.9995 2.71034 12.1884V14.6268C2.71034 14.8157 2.5558 14.9702 2.36691 14.9702H1.02752C0.838627 14.9702 0.684082 14.8157 0.684082 14.6268V12.1884C0.684082 11.9995 0.838627 11.845 1.02752 11.845ZM1.37095 14.3177H2.02348V12.5318H1.37095V14.3177Z\n" +
                "    M13.4429 0L11.0938 0.00343434C10.9049 0 10.7504 0.154545 10.7469 0.343434C10.7435 0.532323 10.8981 0.686869 11.0869 0.690303C11.0904 0.690303 11.0904 0.690303 11.0938 0.690303L12.6152 0.686869L8.88896 4.39939L7.58734 3.10808C7.52209 3.04626 7.4328 3.01535 7.34351 3.01879C7.25421 3.01535 7.16492 3.04626 7.09967 3.10808L0.505728 9.70202C0.371789 9.83596 0.371789 10.0523 0.505728 10.1828C0.570981 10.2481 0.656839 10.2824 0.749566 10.2824C0.842294 10.2824 0.928152 10.2481 0.993405 10.1828L7.34694 3.82929L8.64856 5.14121C8.71037 5.2099 8.79967 5.24768 8.8924 5.25111C8.98512 5.24768 9.07098 5.20646 9.13623 5.14121L13.0995 1.17455L13.096 2.70283C13.096 2.89515 13.2471 3.0497 13.4395 3.05657C13.6318 3.05313 13.7829 2.89515 13.7829 2.70283L13.7863 0.346869C13.7863 0.15798 13.6318 0 13.4429 0Z\n" +
                " "

        const val compare="M10.8334 6.52508C11.5417 6.27508 12.1084 5.70841 12.3584 5.00008H15.0001L12.5001 10.8334C12.5001 12.2167 13.8084 13.3334 15.4167 13.3334C17.0251 13.3334 18.3334 12.2167 18.3334 10.8334L15.8334 5.00008H17.5001V3.33341H12.3584C12.0167 2.35841 11.0917 1.66675 10.0001 1.66675C8.90842 1.66675 7.98342 2.35841 7.64175 3.33341H2.50008V5.00008H4.16675L1.66675 10.8334C1.66675 12.2167 2.97508 13.3334 4.58341 13.3334C6.19175 13.3334 7.50008 12.2167 7.50008 10.8334L5.00008 5.00008H7.64175C7.89175 5.70841 8.45841 6.27508 9.16675 6.52508V15.8334H1.66675V17.5001H18.3334V15.8334H10.8334V6.52508ZM16.9751 10.8334H13.8584L15.4167 7.20008L16.9751 10.8334ZM6.14175 10.8334H3.02508L4.58341 7.20008L6.14175 10.8334ZM10.0001 5.00008C9.54175 5.00008 9.16675 4.62508 9.16675 4.16675C9.16675 3.70841 9.54175 3.33341 10.0001 3.33341C10.4584 3.33341 10.8334 3.70841 10.8334 4.16675C10.8334 4.62508 10.4584 5.00008 10.0001 5.00008Z"
        const val cashRegister = "M20 3H4C2.9 3 2 3.9 2 5V16C2 17.1 2.9 18 4 18H7L6 19V21H18V19L17 18H20C21.1 18 22 17.1 22 16V5C22 3.9 21.1 3 20 3ZM20 16H4V5H20V16Z " +
                "M15.5636 9.60024L16.7345 7.58483C16.8364 7.38836 16.76 7.14753 16.5691 7.04612C16.3845 6.95106 16.1555 7.0081 16.0409 7.18555L14.8445 9.23899C13.0245 8.47212 10.9755 8.47212 9.15545 9.23899L7.95909 7.18555C7.83818 7.00176 7.59 6.94472 7.40545 7.0588C7.22727 7.17288 7.17 7.40104 7.26545 7.58483L8.43636 9.60024C6.46364 10.722 5.17818 12.7438 5 15H19C18.8218 12.7438 17.5364 10.722 15.5636 9.60024ZM8.81818 13.2571C8.37909 13.2571 8.02273 12.9022 8.02273 12.4649C8.02273 12.0276 8.37909 11.6727 8.81818 11.6727C9.25727 11.6727 9.61364 12.0276 9.61364 12.4649C9.61364 12.9022 9.25727 13.2571 8.81818 13.2571ZM15.1818 13.2571C14.7427 13.2571 14.3864 12.9022 14.3864 12.4649C14.3864 12.0276 14.7427 11.6727 15.1818 11.6727C15.6209 11.6727 15.9773 12.0276 15.9773 12.4649C15.9773 12.9022 15.6209 13.2571 15.1818 13.2571Z"
        const val leftArrow="M24 15H11.83L17.42 9.41L16 8L8 16L16 24L17.41 22.59L11.83 17H24V15Z"
        const val workingHours = "M12.5 7.5H11V13.5L16.25 16.65L17 15.42L12.5 12.75V7.5Z " +
                "M11.99 2.5C6.47 2.5 2 6.98 2 12.5C2 18.02 6.47 22.5 11.99 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 11.99 2.5ZM12 20.5C7.58 20.5 4 16.92 4 12.5C4 8.08 7.58 4.5 12 4.5C16.42 4.5 20 8.08 20 12.5C20 16.92 16.42 20.5 12 20.5Z"

        const val hourlyRate = "M21 4.40886C20.9851 4.78202 20.3896 5.27 19.3325 5.64317C18.2754 6.03068 16.7717 6.31773 14.9553 6.31773C11.3375 6.31773 8.92556 5.16954 8.92556 4.40886C8.92556 4.0357 9.52109 3.54772 10.5782 3.17456C11.6352 2.78705 13.139 2.5 14.9553 2.5C18.5881 2.5 21 3.64819 21 4.40886Z " +
                "M21 8.68586V6.4756C19.5558 7.32239 17.2481 7.75296 14.9553 7.75296C12.6625 7.75296 10.3697 7.32239 8.92556 6.4756V8.68586C8.92556 9.08773 9.71464 9.70488 11.2184 10.1211C13.4069 10.7382 16.5186 10.7239 18.6923 10.1211C20.2109 9.70488 21 9.08773 21 8.68586Z " +
                "M21 10.7382V12.3045C20.3646 11.9368 19.6555 11.682 18.9002 11.5678C18.9706 11.5501 19.0402 11.5319 19.1092 11.5133C19.8536 11.298 20.4789 11.0396 21 10.7382Z " +
                "M13.7328 21.7195C13.0849 21.064 12.587 20.2597 12.2946 19.3627C11.2765 19.5634 10.1606 19.6654 9.04466 19.6654C6.75186 19.6654 4.44417 19.2348 3 18.3881V20.5983C3 21.0002 3.78908 21.6173 5.30769 22.0479C7.49628 22.6507 10.5931 22.6507 12.7816 22.0479C13.1435 21.9459 13.4599 21.8348 13.7328 21.7195Z " +
                "M12 17.5C12 16.5429 12.224 15.6379 12.6225 14.8348C11.6568 14.5824 10.4422 14.4125 9.04466 14.4125C5.41191 14.4125 3 15.5606 3 16.3213C3 17.082 5.41191 18.2302 9.04466 18.2302C10.1639 18.2302 11.1658 18.1212 12.0165 17.9481C12.0056 17.8002 12 17.6507 12 17.5Z " +
                "M8.92556 13.0346C10.5169 13.0265 12.1179 13.2132 13.4412 13.5973C14.015 12.9272 14.7339 12.3851 15.5494 12.0201C15.3524 12.0266 15.1543 12.03 14.9553 12.03C13.4665 12.03 12.0223 11.8434 10.8015 11.5133C10.0571 11.298 9.43176 11.0396 8.92556 10.7382V13.0346Z " +
                "M18.45 14.8008V17.5119L20.7761 18.8921L20.1924 19.8494L17.3 18.114V14.8008H18.45Z " +
                "M12.8 17.5008C12.8 14.6306 15.1243 12.3008 17.995 12.3008C20.8703 12.3008 23.2 14.6302 23.2 17.5008C23.2 20.3714 20.8703 22.7008 17.995 22.7008C15.1243 22.7008 12.8 20.371 12.8 17.5008ZM14.2 17.5008C14.2 19.6003 15.9004 21.3008 18 21.3008C20.0995 21.3008 21.8 19.6003 21.8 17.5008C21.8 15.4012 20.0995 13.7008 18 13.7008C15.9004 13.7008 14.2 15.4012 14.2 17.5008Z"

        const val clients = "M16 11.5C17.66 11.5 18.99 10.16 18.99 8.5C18.99 6.84 17.66 5.5 16 5.5C14.34 5.5 13 6.84 13 8.5C13 10.16 14.34 11.5 16 11.5ZM8 11.5C9.66 11.5 10.99 10.16 10.99 8.5C10.99 6.84 9.66 5.5 8 5.5C6.34 5.5 5 6.84 5 8.5C5 10.16 6.34 11.5 8 11.5ZM8 13.5C5.67 13.5 1 14.67 1 17V19.5H15V17C15 14.67 10.33 13.5 8 13.5ZM16 13.5C15.71 13.5 15.38 13.52 15.03 13.55C16.19 14.39 17 15.52 17 17V19.5H23V17C23 14.67 18.33 13.5 16 13.5Z"
        const val averageCheck = "M18 10.5H16V12.5H18V10.5Z " +
                "M15 10.5H9V12.5H15V10.5Z " +
                "M18 7.5H16V9.5H18V7.5Z " +
                "M15 7.5H9V9.5H15V7.5Z " +
                "M19.5 4L18 2.5L16.5 4L15 2.5L13.5 4L12 2.5L10.5 4L9 2.5L7.5 4L6 2.5V16.5H3V19.5C3 21.16 4.34 22.5 6 22.5H18C19.66 22.5 21 21.16 21 19.5V2.5L19.5 4ZM19 19.5C19 20.05 18.55 20.5 18 20.5C17.45 20.5 17 20.05 17 19.5V16.5H8V5.5H19V19.5Z"
        const val difference="M9.78 11.66L8.36 13.08C7.68 12.39 7.02 11.5 6.57 10.14L8.51 9.65C8.83 10.54 9.28 11.15 9.78 11.66ZM11 6.5L7 2.5L3 6.5H6.02C6.04 7.31 6.1 8.04 6.21 8.67L8.15 8.18C8.08 7.7 8.03 7.13 8.02 6.5H11ZM21 6.5L17 2.5L13 6.5H15.99C15.89 10.18 14.71 11.25 13.45 12.38C12.95 12.82 12.44 13.3 12 13.93C11.66 13.44 11.27 13.05 10.87 12.69L9.46 14.1C10.39 14.95 11 15.64 11 17.5V22.5H13V17.5C13 15.48 13.71 14.84 14.79 13.87C16.17 12.63 17.87 11.09 17.99 6.5H21Z"
        const val turnOver = "M15.0744 18.4024V20.5983C15.0744 20.9858 14.3598 21.603 12.7816 22.0479C10.5931 22.6507 7.49628 22.6507 5.30769 22.0479C3.78908 21.6173 3 21.0002 3 20.5983V18.3881C4.44417 19.2348 6.75186 19.6654 9.04466 19.6654C11.3375 19.6654 13.6303 19.2348 15.0744 18.4024Z " +
                "M15.0744 16.3213C15.0744 17.082 12.6774 18.2302 9.04466 18.2302C5.41191 18.2302 3 17.082 3 16.3213C3 15.5606 5.41191 14.4125 9.04466 14.4125C12.6774 14.4125 15.0744 15.5606 15.0744 16.3213Z " +
                "M21 15.0153V17.2255C21 17.613 20.2705 18.2445 18.6923 18.6751C18.0372 18.8617 17.3077 18.9621 16.5633 19.0339V16.3213C16.5633 16.3213 16.5633 16.2352 16.5484 16.1921C17.4566 16.1204 18.335 15.9912 19.0943 15.7759C19.8536 15.575 20.4789 15.3023 21 15.0153Z " +
                "M21 10.7382V12.9485C21 13.336 20.2705 13.9532 18.6774 14.3981C17.8437 14.6421 16.861 14.7713 15.8486 14.8143C14.5533 13.6087 11.7246 13.0203 8.92556 13.0346V10.7382C9.43176 11.0396 10.0571 11.298 10.8015 11.5133C12.0223 11.8434 13.4665 12.03 14.9553 12.03C16.4442 12.03 17.8883 11.8434 19.1092 11.5133C19.8536 11.298 20.4789 11.0396 21 10.7382Z " +
                "M21 6.4756V8.68586C21 9.08773 20.2109 9.70488 18.6923 10.1211C16.5186 10.7239 13.4069 10.7382 11.2184 10.1211C9.71464 9.70488 8.92556 9.08773 8.92556 8.68586V6.4756C10.3697 7.32239 12.6625 7.75296 14.9553 7.75296C17.2481 7.75296 19.5558 7.32239 21 6.4756Z " +
                "M21 4.40886C20.9851 4.78202 20.3896 5.27 19.3325 5.64317C18.2754 6.03068 16.7717 6.31773 14.9553 6.31773C11.3375 6.31773 8.92556 5.16954 8.92556 4.40886C8.92556 4.0357 9.52109 3.54772 10.5782 3.17456C11.6352 2.78705 13.139 2.5 14.9553 2.5C18.5881 2.5 21 3.64819 21 4.40886Z"

        const val dashboard="M6.25002 17.5H1.66669V7.5H6.25002V17.5ZM12.2917 2.5H7.70835V17.5H12.2917V2.5ZM18.3334 9.16667H13.75V17.5H18.3334V9.16667Z"
        const val verticalExpandedSwift = "M16.59 11.59L13 15.17V1H11V15.17L7.41 11.58L6 13L12 19L18 13L16.59 11.59ZM18 20H6V22H18V20Z"
        const val verticalUnexpandedSwift="M7.41 12.41L11 8.83L11 23H13L13 8.83L16.59 12.42L18 11L12 5L6 11L7.41 12.41ZM6 4H18V1.99999H6V4Z"
        const val horizontalUnexpandedSwift="M11.59 7.41L15.17 11H1L1 13H15.17L11.58 16.59L13 18L19 12L13 6L11.59 7.41ZM20 6V18H22V6H20Z"
        const val horizontalExpandSwift="M12.41 16.59L8.83 13L23 13V11L8.83 11L12.42 7.41L11 6L5 12L11 18L12.41 16.59ZM4 18L4 6H2L2 18H4Z"
        const val pressets="M0.25 10.75V12.25H4.75V10.75H0.25ZM0.25 1.75V3.25H7.75V1.75H0.25ZM7.75 13.75V12.25H13.75V10.75H7.75V9.25H6.25V13.75H7.75ZM3.25 4.75V6.25H0.25V7.75H3.25V9.25H4.75V4.75H3.25ZM13.75 7.75V6.25H6.25V7.75H13.75ZM9.25 4.75H10.75V3.25H13.75V1.75H10.75V0.25H9.25V4.75Z"
        const val emptyList="M26.6667 0H3.31667C1.46667 0 0.0166667 1.48333 0.0166667 3.33333L0 26.6667C0 28.5 1.46667 30 3.31667 30H26.6667C28.5 30 30 28.5 30 26.6667V3.33333C30 1.48333 28.5 0 26.6667 0ZM26.6667 20H20C20 22.7667 17.75 25 15 25C12.25 25 10 22.7667 10 20H3.31667V3.33333H26.6667V20Z"
        const val closingReport="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11.41 16.09V18H8.74V16.07C7.03 15.71 5.58 14.61 5.47 12.67H7.43C7.53 13.72 8.25 14.54 10.08 14.54C12.04 14.54 12.48 13.56 12.48 12.95C12.48 12.12 12.04 11.34 9.81 10.81C7.33 10.21 5.63 9.19 5.63 7.14C5.63 5.42 7.02 4.3 8.74 3.93V2H11.41V3.95C13.27 4.4 14.2 5.81 14.26 7.34H12.3C12.25 6.23 11.66 5.47 10.08 5.47C8.58 5.47 7.68 6.15 7.68 7.11C7.68 7.95 8.33 8.5 10.35 9.02C12.37 9.54 14.53 10.41 14.53 12.93C14.52 14.76 13.15 15.76 11.41 16.09Z"
        const val calendar2="M14.1667 10H10V14.1667H14.1667V10ZM13.3333 0.833344V2.50001H6.66667V0.833344H5V2.50001H4.16667C3.24167 2.50001 2.50833 3.25001 2.50833 4.16668L2.5 15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16668C17.5 3.25001 16.75 2.50001 15.8333 2.50001H15V0.833344H13.3333ZM15.8333 15.8333H4.16667V6.66668H15.8333V15.8333Z"
        const val backToAdmin="M19 9L20.25 6.25L23 5L20.25 3.75L19 1L17.75 3.75L15 5L17.75 6.25L19 9ZM11.5 9.5L9 4L6.5 9.5L1 12L6.5 14.5L9 20L11.5 14.5L17 12L11.5 9.5ZM19 15L17.75 17.75L15 19L17.75 20.25L19 23L20.25 20.25L23 19L20.25 17.75L19 15Z"
        const val appsMenu="M4 8H8V4H4V8ZM10 20H14V16H10V20ZM4 20H8V16H4V20ZM4 14H8V10H4V14ZM10 14H14V10H10V14ZM16 4V8H20V4H16ZM10 8H14V4H10V8ZM16 14H20V10H16V14ZM16 20H20V16H16V20Z"
        const val net= "M9.99175 2.16667C5.39175 2.16667 1.66675 5.90001 1.66675 10.5C1.66675 15.1 5.39175 18.8333 9.99175 18.8333C14.6001 18.8333 18.3334 15.1 18.3334 10.5C18.3334 5.90001 14.6001 2.16667 9.99175 2.16667ZM15.7667 7.16667H13.3084C13.0417 6.12501 12.6584 5.12501 12.1584 4.20001C13.6917 4.72501 14.9667 5.79167 15.7667 7.16667ZM10.0001 3.86667C10.6917 4.86667 11.2334 5.97501 11.5917 7.16667H8.40842C8.76675 5.97501 9.30841 4.86667 10.0001 3.86667ZM3.55008 12.1667C3.41675 11.6333 3.33341 11.075 3.33341 10.5C3.33341 9.92501 3.41675 9.36667 3.55008 8.83334H6.36675C6.30008 9.38334 6.25008 9.93334 6.25008 10.5C6.25008 11.0667 6.30008 11.6167 6.36675 12.1667H3.55008ZM4.23341 13.8333H6.69175C6.95842 14.875 7.34175 15.875 7.84175 16.8C6.30841 16.275 5.03341 15.2167 4.23341 13.8333ZM6.69175 7.16667H4.23341C5.03341 5.78334 6.30841 4.72501 7.84175 4.20001C7.34175 5.12501 6.95842 6.12501 6.69175 7.16667ZM10.0001 17.1333C9.30841 16.1333 8.76675 15.025 8.40842 13.8333H11.5917C11.2334 15.025 10.6917 16.1333 10.0001 17.1333ZM11.9501 12.1667H8.05008C7.97508 11.6167 7.91675 11.0667 7.91675 10.5C7.91675 9.93334 7.97508 9.37501 8.05008 8.83334H11.9501C12.0251 9.37501 12.0834 9.93334 12.0834 10.5C12.0834 11.0667 12.0251 11.6167 11.9501 12.1667ZM12.1584 16.8C12.6584 15.875 13.0417 14.875 13.3084 13.8333H15.7667C14.9667 15.2083 13.6917 16.275 12.1584 16.8ZM13.6334 12.1667C13.7001 11.6167 13.7501 11.0667 13.7501 10.5C13.7501 9.93334 13.7001 9.38334 13.6334 8.83334H16.4501C16.5834 9.36667 16.6667 9.92501 16.6667 10.5C16.6667 11.075 16.5834 11.6333 16.4501 12.1667H13.6334Z"
        const val phone= "M16.675 13.7452C15.65 13.7452 14.6583 13.5786 13.7333 13.2786C13.4417 13.1786 13.1167 13.2536 12.8917 13.4786L11.5833 15.1202C9.225 13.9952 7.01667 11.8702 5.84167 9.42856L7.46667 8.04522C7.69167 7.81189 7.75833 7.48689 7.66667 7.19523C7.35833 6.27022 7.2 5.27856 7.2 4.25356C7.2 3.80356 6.825 3.42856 6.375 3.42856H3.49167C3.04167 3.42856 2.5 3.62856 2.5 4.25356C2.5 11.9952 8.94167 18.4286 16.675 18.4286C17.2667 18.4286 17.5 17.9036 17.5 17.4452V14.5702C17.5 14.1202 17.125 13.7452 16.675 13.7452Z"
        const val largeClose="M23 10.41L21.59 9L16 14.59L10.41 9L9 10.41L14.59 16L9 21.59L10.41 23L16 17.41L21.59 23L23 21.59L17.41 16L23 10.41Z"
        const val fromLocal="M16.5 6.5V18C16.5 20.21 14.71 22 12.5 22C10.29 22 8.5 20.21 8.5 18V5.5C8.5 4.12 9.62 3 11 3C12.38 3 13.5 4.12 13.5 5.5V16C13.5 16.55 13.05 17 12.5 17C11.95 17 11.5 16.55 11.5 16V6.5H10V16C10 17.38 11.12 18.5 12.5 18.5C13.88 18.5 15 17.38 15 16V5.5C15 3.29 13.21 1.5 11 1.5C8.79 1.5 7 3.29 7 5.5V18C7 21.04 9.46 23.5 12.5 23.5C15.54 23.5 18 21.04 18 18V6.5H16.5Z"
        const val star="M15.8335 8.00004L16.8752 5.70837L19.1668 4.66671L16.8752 3.62504L15.8335 1.33337L14.7918 3.62504L12.5002 4.66671L14.7918 5.70837L15.8335 8.00004ZM9.5835 8.41671L7.50016 3.83337L5.41683 8.41671L0.833496 10.5L5.41683 12.5834L7.50016 17.1667L9.5835 12.5834L14.1668 10.5L9.5835 8.41671ZM15.8335 13L14.7918 15.2917L12.5002 16.3334L14.7918 17.375L15.8335 19.6667L16.8752 17.375L19.1668 16.3334L16.8752 15.2917L15.8335 13Z"

        const val mediumClose="M15.8333 5.34175L14.6583 4.16675L10 8.82508L5.34166 4.16675L4.16666 5.34175L8.825 10.0001L4.16666 14.6584L5.34166 15.8334L10 11.1751L14.6583 15.8334L15.8333 14.6584L11.175 10.0001L15.8333 5.34175Z"
        const val smallClose="M7.00001 0.333328C3.31334 0.333328 0.333344 3.31333 0.333344 7C0.333344 10.6867 3.31334 13.6667 7.00001 13.6667C10.6867 13.6667 13.6667 10.6867 13.6667 7C13.6667 3.31333 10.6867 0.333328 7.00001 0.333328ZM10.3333 9.39333L9.39334 10.3333L7.00001 7.93999L4.60668 10.3333L3.66668 9.39333L6.06001 7L3.66668 4.60666L4.60668 3.66666L7.00001 6.06L9.39334 3.66666L10.3333 4.60666L7.94001 7L10.3333 9.39333Z"
        const val paint="M16.56 8.94L7.62 0L6.21 1.41L8.59 3.79L3.44 8.94C2.85 9.53 2.85 10.48 3.44 11.06L8.94 16.56C9.23 16.85 9.62 17 10 17C10.38 17 10.77 16.85 11.06 16.56L16.56 11.06C17.15 10.48 17.15 9.53 16.56 8.94ZM5.21 10L10 5.21L14.79 10H5.21ZM19 11.5C19 11.5 17 13.67 17 15C17 16.1 17.9 17 19 17C20.1 17 21 16.1 21 15C21 13.67 19 11.5 19 11.5ZM2 20H22V24H2V20Z"

        const val calendar = "M9 11H7V13H9V11ZM13 11H11V13H13V11ZM17 11H15V13H17V11ZM19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20Z"
        //        const val menuFilled="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z"
        const val menuFilled="M2.5 15H17.5V13.3333H2.5V15ZM2.5 10.8333H17.5V9.16667H2.5V10.8333ZM2.5 5V6.66667H17.5V5H2.5Z"


        const val expandlessFilled="M14 10.9125L9 15.9125L10.175 17.0875L14 13.2708L17.825 17.0875L19 15.9125L14 10.9125Z"
        const val options="M15.95 10.7837C15.9833 10.5337 16 10.2753 16 10.0003C16 9.73367 15.9833 9.467 15.9417 9.217L17.6333 7.90034C17.7833 7.78367 17.825 7.55867 17.7333 7.392L16.1333 4.62534C16.0333 4.442 15.825 4.38367 15.6417 4.442L13.65 5.242C13.2333 4.92534 12.7917 4.65867 12.3 4.45867L12 2.342C11.9667 2.142 11.8 2.00034 11.6 2.00034H8.39999C8.19999 2.00034 8.04166 2.142 8.00832 2.342L7.70832 4.45867C7.21666 4.65867 6.76666 4.93367 6.35832 5.242L4.36666 4.442C4.18332 4.37534 3.97499 4.442 3.87499 4.62534L2.28332 7.392C2.18332 7.567 2.21666 7.78367 2.38332 7.90034L4.07499 9.217C4.03332 9.467 3.99999 9.742 3.99999 10.0003C3.99999 10.2587 4.01666 10.5337 4.05832 10.7837L2.36666 12.1003C2.21666 12.217 2.17499 12.442 2.26666 12.6087L3.86666 15.3753C3.96666 15.5587 4.17499 15.617 4.35832 15.5587L6.34999 14.7587C6.76666 15.0753 7.20832 15.342 7.69999 15.542L7.99999 17.6587C8.04166 17.8587 8.19999 18.0003 8.39999 18.0003H11.6C11.8 18.0003 11.9667 17.8587 11.9917 17.6587L12.2917 15.542C12.7833 15.342 13.2333 15.0753 13.6417 14.7587L15.6333 15.5587C15.8167 15.6253 16.025 15.5587 16.125 15.3753L17.725 12.6087C17.825 12.4253 17.7833 12.217 17.625 12.1003L15.95 10.7837ZM9.99999 13.0003C8.34999 13.0003 6.99999 11.6503 6.99999 10.0003C6.99999 8.35034 8.34999 7.00034 9.99999 7.00034C11.65 7.00034 13 8.35034 13 10.0003C13 11.6503 11.65 13.0003 9.99999 13.0003Z"
        const val exitApp="M10.09 15.59L11.5 17L16.5 12L11.5 7L10.09 8.41L12.67 11H3V13H12.67L10.09 15.59ZM19 3H5C3.89 3 3 3.9 3 5V9H5V5H19V19H5V15H3V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"

        const val user="M9.99998 10C11.8416 10 13.3333 8.50834 13.3333 6.66668C13.3333 4.82501 11.8416 3.33334 9.99998 3.33334C8.15831 3.33334 6.66665 4.82501 6.66665 6.66668C6.66665 8.50834 8.15831 10 9.99998 10ZM9.99998 11.6667C7.77498 11.6667 3.33331 12.7833 3.33331 15V16.6667H16.6666V15C16.6666 12.7833 12.225 11.6667 9.99998 11.6667Z"
        const val production="M15.2084 6.33332L10.625 3.68332C10.2417 3.45832 9.75835 3.45832 9.37502 3.68332L4.79169 6.33332C4.40835 6.55832 4.16669 6.96666 4.16669 7.41666V12.7083C4.16669 13.1583 4.40835 13.5667 4.79169 13.7917L9.37502 16.4417C9.75835 16.6667 10.2417 16.6667 10.625 16.4417L15.2084 13.7917C15.5917 13.5667 15.8334 13.1583 15.8334 12.7083V7.41666C15.8334 6.96666 15.5917 6.55832 15.2084 6.33332ZM5.83335 12.4667V8.61666L9.16669 10.55V14.3917L5.83335 12.4667ZM10 9.10832L6.66669 7.17499L10 5.24999L13.3334 7.17499L10 9.10832ZM10.8334 14.3917V10.55L14.1667 8.61666V12.4667L10.8334 14.3917ZM5.83335 1.66666H2.91669C2.22502 1.66666 1.66669 2.22499 1.66669 2.91666V5.83332H3.33335V3.33332H5.83335V1.66666ZM14.1667 1.66666H17.0834C17.775 1.66666 18.3334 2.22499 18.3334 2.91666V5.83332H16.6667V3.33332H14.1667V1.66666ZM5.83335 18.3333H2.91669C2.22502 18.3333 1.66669 17.775 1.66669 17.0833V14.1667H3.33335V16.6667H5.83335V18.3333ZM14.1667 18.3333H17.0834C17.775 18.3333 18.3334 17.775 18.3334 17.0833V14.1667H16.6667V16.6667H14.1667V18.3333Z"
        const val showEye="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z"
        const val hideEye="M12 7C14.76 7 17 9.24 17 12C17 12.65 16.87 13.26 16.64 13.83L19.56 16.75C21.07 15.49 22.26 13.86 22.99 12C21.26 7.61 16.99 4.5 11.99 4.5C10.59 4.5 9.25 4.75 8.01 5.2L10.17 7.36C10.74 7.13 11.35 7 12 7ZM2 4.27L4.74 7.01C3.08 8.3 1.78 10.02 1 12C2.73 16.39 7 19.5 12 19.5C13.55 19.5 15.03 19.2 16.38 18.66L16.8 19.08L19.73 22L21 20.73L3.27 3L2 4.27ZM7.53 9.8L9.08 11.35C9.03 11.56 9 11.78 9 12C9 13.66 10.34 15 12 15C12.22 15 12.44 14.97 12.65 14.92L14.2 16.47C13.53 16.8 12.79 17 12 17C9.24 17 7 14.76 7 12C7 11.21 7.2 10.47 7.53 9.8ZM11.84 9.02L14.99 12.17L15.01 12.01C15.01 10.35 13.67 9.01 12.01 9.01L11.84 9.02Z"
        const val arrowBelow="M7 10L12 15L17 10H7Z"
        const val arrowTop="M7 15L12 10L17 15H7Z"
        const val template="M9.99167 15.45L3.85 10.675L2.5 11.725L10 17.5583L17.5 11.725L16.1417 10.6667L9.99167 15.45ZM10 13.3333L16.1333 8.55832L17.5 7.49999L10 1.66666L2.5 7.49999L3.85833 8.55832L10 13.3333Z"
        const val filter="M4.25006 5.61C6.27006 8.2 10.0001 13 10.0001 13V19C10.0001 19.55 10.4501 20 11.0001 20H13.0001C13.5501 20 14.0001 19.55 14.0001 19V13C14.0001 13 17.7201 8.2 19.7401 5.61C20.2501 4.95 19.7801 4 18.9501 4H5.04006C4.21006 4 3.74006 4.95 4.25006 5.61Z"
        const val add="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
        const val searchBox="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
        const val refresh= "M17.65 6.35C16.2 4.9 14.21 4 12 4C7.58001 4 4.01001 7.58 4.01001 12C4.01001 16.42 7.58001 20 12 20C15.73 20 18.84 17.45 19.73 14H17.65C16.83 16.33 14.61 18 12 18C8.69001 18 6.00001 15.31 6.00001 12C6.00001 8.69 8.69001 6 12 6C13.66 6 15.14 6.69 16.22 7.78L13 11H20V4L17.65 6.35Z"
        const val sortDesc="M20 12L18.59 10.59L13 16.17V4H11V16.17L5.42 10.58L4 12L12 20L20 12Z"
        const val sortAsc = "M4 12L5.41 13.41L11 7.83V20H13V7.83L18.58 13.42L20 12L12 4L4 12Z"
        const val dots6="M9.16666 15C9.16666 15.9166 8.41666 16.6666 7.49999 16.6666C6.58333 16.6666 5.83333 15.9166 5.83333 15C5.83333 14.0833 6.58333 13.3333 7.49999 13.3333C8.41666 13.3333 9.16666 14.0833 9.16666 15ZM7.49999 8.33331C6.58333 8.33331 5.83333 9.08331 5.83333 9.99998C5.83333 10.9166 6.58333 11.6666 7.49999 11.6666C8.41666 11.6666 9.16666 10.9166 9.16666 9.99998C9.16666 9.08331 8.41666 8.33331 7.49999 8.33331ZM7.49999 3.33331C6.58333 3.33331 5.83333 4.08331 5.83333 4.99998C5.83333 5.91665 6.58333 6.66665 7.49999 6.66665C8.41666 6.66665 9.16666 5.91665 9.16666 4.99998C9.16666 4.08331 8.41666 3.33331 7.49999 3.33331ZM12.5 6.66665C13.4167 6.66665 14.1667 5.91665 14.1667 4.99998C14.1667 4.08331 13.4167 3.33331 12.5 3.33331C11.5833 3.33331 10.8333 4.08331 10.8333 4.99998C10.8333 5.91665 11.5833 6.66665 12.5 6.66665ZM12.5 8.33331C11.5833 8.33331 10.8333 9.08331 10.8333 9.99998C10.8333 10.9166 11.5833 11.6666 12.5 11.6666C13.4167 11.6666 14.1667 10.9166 14.1667 9.99998C14.1667 9.08331 13.4167 8.33331 12.5 8.33331ZM12.5 13.3333C11.5833 13.3333 10.8333 14.0833 10.8333 15C10.8333 15.9166 11.5833 16.6666 12.5 16.6666C13.4167 16.6666 14.1667 15.9166 14.1667 15C14.1667 14.0833 13.4167 13.3333 12.5 13.3333Z"
        const val previous="M15.705 7.41L14.295 6L8.29504 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z"
        const val next="M9.70504 6L8.29504 7.41L12.875 12L8.29504 16.59L9.70504 18L15.705 12L9.70504 6Z"
        const val close = "M12.67,4.27 L11.73,3.33 L8,7.06 L4.27,3.33 L3.33,4.27 L7.06,8 L3.33,11.73 L4.27,12.67 L8,8.94 L11.73,12.67 L12.67,11.73 L8.94,8 L12.67,4.27 Z"
        const val location="M9.99999 1.66663C6.77499 1.66663 4.16666 4.27496 4.16666 7.49996C4.16666 11.875 9.99999 18.3333 9.99999 18.3333C9.99999 18.3333 15.8333 11.875 15.8333 7.49996C15.8333 4.27496 13.225 1.66663 9.99999 1.66663ZM9.99999 9.58329C8.84999 9.58329 7.91666 8.64996 7.91666 7.49996C7.91666 6.34996 8.84999 5.41663 9.99999 5.41663C11.15 5.41663 12.0833 6.34996 12.0833 7.49996C12.0833 8.64996 11.15 9.58329 9.99999 9.58329Z"
        const val store="M16.6667 3.33337H3.33333V5.00004H16.6667V3.33337ZM17.5 11.6667V10L16.6667 5.83337H3.33333L2.5 10V11.6667H3.33333V16.6667H11.6667V11.6667H15V16.6667H16.6667V11.6667H17.5ZM10 15H5V11.6667H10V15Z"
        const val android="M14.6667 7.89994L16.2 5.24994C16.3333 4.9916 16.2333 4.67494 15.9833 4.5416C15.7417 4.4166 15.4417 4.4916 15.2917 4.72494L13.725 7.42494C11.3417 6.4166 8.65833 6.4166 6.27499 7.42494L4.70833 4.72494C4.54999 4.48327 4.22499 4.40827 3.98333 4.55827C3.74999 4.70827 3.67499 5.00827 3.79999 5.24994L5.33333 7.89994C2.74999 9.37494 1.06666 12.0333 0.833328 14.9999H19.1667C18.9333 12.0333 17.25 9.37494 14.6667 7.89994ZM5.83333 12.7083C5.25833 12.7083 4.79166 12.2416 4.79166 11.6666C4.79166 11.0916 5.25833 10.6249 5.83333 10.6249C6.40833 10.6249 6.87499 11.0916 6.87499 11.6666C6.87499 12.2416 6.40833 12.7083 5.83333 12.7083ZM14.1667 12.7083C13.5917 12.7083 13.125 12.2416 13.125 11.6666C13.125 11.0916 13.5917 10.6249 14.1667 10.6249C14.7417 10.6249 15.2083 11.0916 15.2083 11.6666C15.2083 12.2416 14.7417 12.7083 14.1667 12.7083Z"






    }
}