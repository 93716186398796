package org.hs_soft.runmynesto.domain.model.home.media_center

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.TitleEnum

enum class MediaCenterInfoHeaderEnum(
    override val title: String
) : TitleEnum
{
    MEDIA(
        title = Strings.media ,
    ),
    DEVICES(
        title = Strings.devices
    )

}