package org.hs_soft.runmynesto.data.local_datasource.menu_data

import org.hs_soft.kobweb_aws_cognito.domain.model.menu.MenuFooterModel
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.util.Res

object MenuFooterData
{
    val data= MenuFooterModel(
        id = "",//TODO(Must be fixed)
        settingIcon = Res.PathIcon.options ,
        exitlloIcon = Res.PathIcon.exitApp,
        settingTitle = Strings.options,
        userName = Strings.USER_NAME,
        companyName = Strings.COMPANY_NAME,
    )
}