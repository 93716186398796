package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.grid_customer_card_bar

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onTouchEnd
import com.varabyte.kobweb.compose.ui.modifiers.onTouchMove
import com.varabyte.kobweb.compose.ui.modifiers.onTouchStart
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card.LoyaltyCardItem
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.grid_function.FilterGridContent
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.grid_customer_card_bar.component.CustomerCardGridFunction
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.FilterRow
import org.w3c.dom.get

@Composable
fun GridCustomerCardBar(
    selectedItemId:String,
    loyaltyCardItems: List<LoyaltyCardItem?>?,
    showFilterComponent: Boolean,
    selectedFilterOption: GridItemsFilterEnum,
    windowSizeMode: WindowSizeModeEnum,
    setFilterComponentVisibility:(Boolean)->Unit,
    onSearchValueChange: (String) -> Unit,
    onAddRecord: () -> Unit,
    selectFilterType: (GridItemsFilterEnum) -> Unit,
    submitFilterItems: () -> Unit,
    onItemClick:(String)->Unit,
    loadMoreData:()->Unit,
    onSwipeRight: () -> Unit,
) {
    var startX by remember { mutableStateOf(0.0) }
    var endX by remember { mutableStateOf(0.0) }
    var tracking by remember { mutableStateOf(false) }
    Column(
        modifier = Modifier
            .fillMaxHeight()
            .then(
                if (windowSizeMode== WindowSizeModeEnum.PHONE){
                    Modifier.onTouchStart { event ->
                        startX = event.touches[0]!!.clientX.toDouble()
                        endX = event.touches[0]!!.clientX.toDouble()
                        tracking = true
                    }
                        .onTouchMove { event ->
                            if (tracking) {
                                endX = event.touches[0]!!.clientX.toDouble()
                            }
                        }
                        .onTouchEnd {
                            if (tracking && endX + Constants.Responsive.SwipeWidth < startX) {
//                                onSwipeRight()
                            }
                            tracking = false
                        }
                }
                else Modifier
            )
    )
    {
        FilterRow(
            width =Dimen.gridCustomerCardWidth,
            onAddRecordClick = {
                onAddRecord()
            },
            onFilterClick = {
                setFilterComponentVisibility(!showFilterComponent)
            }
        )

        Box(modifier = Modifier
            .weight(1f)
        )
        {
            if (showFilterComponent){
                FilterGridContent(
                    selectFilterType = {
                        selectFilterType(it)
                    },
                    selectedFilterType=selectedFilterOption,
                    onCloseFilterClick ={
                        setFilterComponentVisibility(false)
                    },
                    submitFilterItems = {
                        submitFilterItems()
                    }

                )
            }

            Column(
                modifier = Modifier.fillMaxHeight()
            ) {
                CustomEditText(
                    width =Dimen.gridCustomerCardWidth,
                    label = "",
                    value = "",
                    endIconPath = Res.PathIcon.searchBox,
                    isRequired = false,
                    hint = Strings.search,
                    onFocusIn = {},
                    onFocusOut = {},
                    onInputChange = {
                        onSearchValueChange(it)
                    },
                )

                CustomerCardGridFunction(
                    items=loyaltyCardItems?: listOf(),
                    id =selectedItemId ,
                    onItemClick = {
                        onItemClick(it)
                    },
                    loadMoreData = {
                        loadMoreData()
                    }

                )



            }
        }




    }
}