package org.hs_soft.runmynesto.data.local_datasource.local_storage.home

import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.serialization.json.Json
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.CheckoutModel
import org.hs_soft.runmynesto.domain.repository.local_datasource.home.GetSavedCheckOutRepository
import org.hs_soft.runmynesto.domain.util.IdUtils

class GetSavedCheckOutRepositoryImpl:GetSavedCheckOutRepository
{
    override suspend fun getCashList(): CheckoutModel? {
        return withContext(Dispatchers.Default) {
            val jsonData = window.sessionStorage.getItem(IdUtils.checkOutList)

            jsonData?.let {
                Json.decodeFromString<CheckoutModel>(it)
            }
        }
    }
}