package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme

@Composable
fun DateWeekNameItem(
    title:String,
)
{
    Box(
        modifier = Modifier
            .margin(Dimen.extraSmallPadding)
            .size(Dimen.DateItemSize)
    )
    {
        SpanText(
            text =title,
            modifier = Modifier
                .align(Alignment.Center)
                .color(Theme.GrayAlpha38.rgb)
                .fontSize(FontSizes.normalFontSize)
                .fontFamily(FontFamily.Montserrat)
        )
    }


}