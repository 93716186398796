package org.hs_soft.runmynesto.domain.model.home.options.cash_flow.app_config_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class AppConfigModel(
    @SerialName("data")
    val `data`: Data?
)