package org.hs_soft.runmynesto.pages.home.mvi


import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.WindowSizeModel
import org.hs_soft.runmynesto.domain.model.menu.MenuItemsEnum
import org.hs_soft.runmynesto.domain.usecase.home.FetchIdTokenUseCase
import org.hs_soft.runmynesto.domain.usecase.home.HSSoftAppsUseCase
import org.hs_soft.runmynesto.domain.usecase.home.SaveCheckOutUseCase
import org.hs_soft.runmynesto.domain.usecase.home.SignOutUseCase
import org.hs_soft.runmynesto.domain.usecase.home.UserProfileDataUseCase
import org.hs_soft.runmynesto.domain.usecase.login.IsLoggedInUseCase
import org.hs_soft.runmynesto.domain.usecase.user.CheckOutDataUseCase
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.TokenProvider
import org.hs_soft.runmynesto.domain.util.handleError

class HomeViewModel(
    private val tokenProvider: TokenProvider,
    private val fetchIdTokenUseCase: FetchIdTokenUseCase,
    private val isLoggedInUseCase: IsLoggedInUseCase,
    private val checkOutDataUseCase: CheckOutDataUseCase,
    private val saveCheckOutUseCase: SaveCheckOutUseCase,
    private val signOutUseCase:SignOutUseCase,
    private val userProfileDataUseCase: UserProfileDataUseCase,
    private val hsSoftAppsUseCase: HSSoftAppsUseCase,
)  {
    private val _state = MutableStateFlow(HomeState())
    val state: StateFlow<HomeState> = _state
    private val scope = CoroutineScope(SupervisorJob() + Dispatchers.Default)


     fun onEvent(event: HomeEvent) {
        when (event) {

            is HomeEvent.GetHSSoftApps->{
                getHSSoftApps()
            }

            is HomeEvent.SignOut->{
                signOut()
            }
            is HomeEvent.OnToolbarCompaniesMenuIconClick->{
                onToolbarCompaniesMenuIconClick()
            }
            is HomeEvent.UserProfileInfo->{
                getUserProfileInfo()
            }
            is HomeEvent.OnToolbarSettingIconClick->onToolbarSettingIconClick()
            is HomeEvent.ChangeShowingSearchBarInMobileStatus->changeShwoingSearchBarInMobileStatus()
            is HomeEvent.GetCheckoutData->getAndSaveCheckoutData(scope)
            is HomeEvent.SetIsUserLoggedIn->setIsUserLoggedIn()
            is HomeEvent.FetchIdToken->fetchUserIdToken( )
            is HomeEvent.ChangeMenuStatus ->changeMenuStatus()
            is HomeEvent.CloseMenu ->closeMenu()
            is HomeEvent.InitWindowsSize ->initWindowSize(event.windowsSize)
            is HomeEvent.OnChangeName -> changeName(event.value)
            is HomeEvent.OnMenuItemClick->onMenuItemClick(menuItem = event.menuItem)

        }
    }

    private fun onMenuItemClick(
        menuItem:MenuItemsEnum
    ) {
        if (state.value.activePage==menuItem)
            _state.value=_state.value.copy(isMenuExpandedHorizontally = false)
        else{
            _state.value=_state.value.copy(
                activePage =menuItem,
                isMenuExpandedHorizontally = false
            )
        }

    }

    private fun getHSSoftApps() {
        scope.launch {
            _state.value = _state.value.copy(
                isLoading = true
            )
            try {
                val result= hsSoftAppsUseCase()
                _state.value=_state.value.copy(
                    listOfHSSortsApps=result.data?.listHSWebApps
                )
            } catch (e: Exception) {
                _state.value = _state.value.copy(
                    isLoading = false,
                )
            }
        }



    }

    private fun getUserProfileInfo() {
        scope.launch {
            _state.value = _state.value.copy(
                isLoading = true
            )
            try {
               val result= userProfileDataUseCase()
               _state.value=_state.value.copy(
                   profileInfo = result.data?.getUserProfileData
               )
            } catch (e: Exception) {
                _state.value = _state.value.copy(
                    isLoading = false,
                )
            }
        }


    }


    private fun signOut() {
        scope.launch {
            _state.value = _state.value.copy(
                isLoading = true
            )
            try {
                signOutUseCase(
                    onSuccess = {
                       _state.value=_state.value.copy(
                           isLoggedIn = false,
                           isLoading = false,
                           showSettingDialog = false
                       )
                    },
                    onError = {
                        _state.value = _state.value.copy(
                            isLoading = false,
                        )
                    }
                )
            } catch (e: Exception) {
                _state.value = _state.value.copy(
                    isLoading = false,
                )
            }
        }


    }

    private fun onToolbarCompaniesMenuIconClick() {
        _state.value=_state.value.copy(
            showCompaniesDialog = !state.value.showCompaniesDialog,
            showSettingDialog = false
        )
    }

    private fun onToolbarSettingIconClick() {

        _state.value=_state.value.copy(
            showSettingDialog=!state.value.showSettingDialog,
            showCompaniesDialog = false
        )
    }

    private fun changeShwoingSearchBarInMobileStatus() {
        _state.value=_state.value.copy(
            showSearchBarInMobileMode = !state.value.showSearchBarInMobileMode
        )
    }

    private fun getAndSaveCheckoutData(scope: CoroutineScope) {
        scope.launch {
            try {
                val result=checkOutDataUseCase()
                saveCheckOutUseCase(
                    model = result
                )

            }catch (e:Exception){
                handleError(
                    error = e,
                )
            }

        }





    }



    private fun updateToken(newToken: String) {
        tokenProvider.updateToken(newToken)
    }

    private fun setIsUserLoggedIn() {
        scope.launch {
            _state.value=_state.value.copy( isLoggedIn = true)
            try {
                 val result=isLoggedInUseCase()
                _state.value=_state.value.copy( isLoggedIn = result)
            }catch (e:Exception){
                _state.value=_state.value.copy(isLoggedIn = false)
            }
        }

    }




    private fun fetchUserIdToken( ) {
//        _state.value=_state.value.copy(isLoading = true)
        scope.launch {
            try {
                val idToken=fetchIdTokenUseCase()
                _state.value=_state.value.copy(
                    idToken = idToken,
                    isLoading = false,
                )
                updateToken(idToken)

            }catch (e:Exception){
                console.log(e)
                _state.value=_state.value.copy(isLoading = false)
            }
        }



    }

    private fun closeMenu() {
        _state.value=_state.value.copy(isMenuExpandedHorizontally = false)
    }

    private fun changeMenuStatus() {
        _state.value=_state.value.copy(
            isMenuExpandedHorizontally =
            !state.value.isMenuExpandedHorizontally)
    }



    private fun initWindowSize(windowsSize: WindowSizeModel) {
        if(windowsSize.width> Constants.Responsive.MaxTabletWidth){
            _state.value=_state.value.copy(
                windowSizeMode = WindowSizeModeEnum.WINDOWS
            )
        }else if(windowsSize.width< Constants.Responsive.MaxTabletWidth
            && windowsSize.width> Constants.Responsive.MaxPhoneWidth){
            _state.value=_state.value.copy(
                windowSizeMode = WindowSizeModeEnum.TABLET
            )
        }else{
            _state.value=_state.value.copy(
                windowSizeMode = WindowSizeModeEnum.PHONE
            )
        }

    }


    private fun changeName(value:String){
        _state.value=_state.value.copy(username = value)
    }





}