package org.hs_soft.runmynesto.domain.usecase.user.tab_general

import org.hs_soft.runmynesto.domain.repository.network_datasource.user.tab_general.UpdateUserImageRepository

class UpdateUserImageUseCase(
    private val repository: UpdateUserImageRepository
) {
    suspend operator fun invoke(
        image: String,
        id: String,
        type: String
    ){
        repository.updateUserImage(
           image=image,
            id=id,
            type=type
        )
    }
}