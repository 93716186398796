package org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_general

import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.TemplateCategoryDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.SingleTemplateCategoryRepository

class GetSingleTemplateCategoryUseCase
    (private val repository: SingleTemplateCategoryRepository) {
    suspend operator fun invoke(id:String): TemplateCategoryDetailModel {
        return repository.fetchSingleTemplateCategoryData(id)
    }
}
