package org.hs_soft.runmynesto.di.home

import org.hs_soft.runmynesto.domain.usecase.home.FetchIdTokenUseCase
import org.hs_soft.runmynesto.domain.usecase.home.GetSavedCheckOutUseCase
import org.hs_soft.runmynesto.domain.usecase.home.HSSoftAppsUseCase
import org.hs_soft.runmynesto.domain.usecase.home.SaveCheckOutUseCase
import org.hs_soft.runmynesto.domain.usecase.home.SignOutUseCase
import org.hs_soft.runmynesto.domain.usecase.home.UserProfileDataUseCase
import org.koin.dsl.module


val homeUseCaseModule= module {
    single {
        FetchIdTokenUseCase(get())
    }
    single {
        GetSavedCheckOutUseCase(get())
    }
    single {
        SaveCheckOutUseCase(get())
    }
    single {
        SignOutUseCase(get())
    }
    single {
        UserProfileDataUseCase(get())
    }
    single {
        HSSoftAppsUseCase(get())
    }



}