package org.hs_soft.runmynesto.domain.usecase.product

import org.hs_soft.runmynesto.domain.repository.local_datasource.product.SaveCompanyProfileIdRepository

class SaveCompanyProfileIdUseCase(
    private val repository: SaveCompanyProfileIdRepository
) {
    operator fun invoke(
        id: String,
    ){
        repository.saveCompanyProfileId(id)
    }


}