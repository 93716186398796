package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PriceDifference(
    @SerialName("id")
    val id: String?,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("product")
    val product: Product?,
    @SerialName("type")
    val type: String?,
    @SerialName("quantity")
    val quantity: Float?,
    @SerialName("reduction")
    val reduction: Float?,
    @SerialName("__typename")
    val typename: String?
)