package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.bill_search.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.key
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CircleIcon
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.alignContent
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Iframe

@Composable
fun PdfPreviewDialog(
    windowSizeMode: WindowSizeModeEnum,
    pdfResultUrl: String?,
    dismissDialog: () -> Unit,
) {

    val  iframeKey = remember { mutableStateOf(0) }

    LaunchedEffect(pdfResultUrl) {
        iframeKey.value=iframeKey.value+1
    }


    Box(
        modifier = Modifier
            .zIndex(Constants.ZIndex.largeZIndex)
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .onClick {
                dismissDialog()
            }
            .fillMaxSize()
    ) {
        Column(
            modifier = Modifier
                .align(Alignment.Center)
                .generalDialogSetting()
                .backgroundColor(Theme.White.rgb)
                .margin(Dimen.largePadding)
                .onClick { event ->
                    event.stopPropagation()
                }
        ) {
            Row(
                modifier = Modifier
                    .borderBottom(
                        width = Dimen.smallThickness,
                        style = LineStyle.Solid,
                        color = Theme.GrayAlpha12.rgb
                    )
                    .backgroundColor(Theme.White.rgb)
                    .padding(
                        leftRight = Dimen.largePadding,
                        topBottom = Dimen.normalPadding,
                    )
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
            ){


                Spacer()

                CircleIcon(
                    img= Res.PathIcon.close,
                    borderColor = Theme.Transparent.rgb,
                    onIconClick = {
                        dismissDialog()
                    }
                )
            }


            pdfResultUrl?.let { url ->
                val viewerUrl = "https://docs.google.com/viewer?url=${encodeURIComponent(url)}&embedded=true"

                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .weight(1f)
                )
                {
                    key(iframeKey) {
                        Iframe(
                            attrs = {
                                attr("src", viewerUrl)
                                style {
                                    width(
                                        if (windowSizeMode==WindowSizeModeEnum.PHONE)
                                            Dimen.previewPdfMobileWidth
                                        else Dimen.previewPdfWidth
                                    )
                                    height(
                                        if (windowSizeMode==WindowSizeModeEnum.PHONE)
                                            Dimen.previewPdfMobileHeight
                                        else Dimen.previewPdfHeight
                                    )
                                    alignContent(AlignContent.Center)
                                    border(Dimen.lowBorderRadius)
                                    backgroundColor(Theme.GrayCD.rgb)
                                }
                            }
                        )
                    }

                }

            }
        }
    }
}

// Helper function to encode URLs
private fun encodeURIComponent(uri: String): String {
    return js("encodeURIComponent")(uri) as String
}