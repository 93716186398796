import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import org.hs_soft.runmynesto.domain.util.Constants
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.get

@Composable
fun Carousel(
    items: List<@Composable () -> Unit>,
    height: CSSSizeValue<CSSUnit.px>,
    currentIndex: MutableState<Int>,
) {
    var startX by remember { mutableStateOf(0f) }

    Column(
        modifier = Modifier
            .fillMaxWidth()
    ) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .height(height)
                .position(Position.Relative)
                .overflow(Overflow.Hidden)
                .attrsModifier {
                    onTouchStart { event ->
                        startX = (event.touches[0]?.clientX ?: 0f).toFloat()
                    }
                    onTouchEnd { event ->
                        val endX = event.changedTouches[0]?.clientX ?: 0f
                        val diff = endX.toFloat() - startX
                        if (diff > 50) {
                            currentIndex.value = (currentIndex.value - 1 + items.size) % items.size
                        } else if (diff < -50) {
                            currentIndex.value = (currentIndex.value + 1) % items.size
                        }
                    }
                }
        ) {
            items.forEachIndexed { index, item ->
                Box(
                    modifier = Modifier
                        .fillMaxSize()
                        .position(Position.Absolute)
                        .top(0.px)
                        .attrsModifier {
                            style {
                                left(if (index == currentIndex.value) 0.px else 100.percent)
                                property("transition",
                                    "left ${Constants.fastTransition} ease")
                            }
                        }
                ) {
                    item()
                }
            }
        }


    }
}