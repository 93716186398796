package org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetStatisticsData(
    @SerialName("data")
    val `data`: List<DataX>?,
    @SerialName("progress")
    val progress: Int?,
    @SerialName("status")
    val status: String?,
    @SerialName("__typename")
    val typename: String?
)