package org.hs_soft.runmynesto.di.statistic

import org.hs_soft.runmynesto.data.api_datasource.statistic.tabs.abc_statics.ABCStatisticDataRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.statistic.tabs.abc_statics.InitABCStatisticRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.statistic.tabs.bill_search.BillPdfRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.statistic.tabs.bill_search.InitSearchBillRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.statistic.tabs.bill_search.BillSearchStatisticDataRepositoryImpl
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.abc_statistic.ABCStatisticDataRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.abc_statistic.InitABCStatisticRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.search_bill.BillPdfRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.search_bill.InitSearchBillRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.search_bill.BillSearchStatisticDataRepository
import org.koin.dsl.module

val statisticRepositoryModule = module {

    single<InitSearchBillRepository> {
        InitSearchBillRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }


    single<BillSearchStatisticDataRepository> {
        BillSearchStatisticDataRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }


    single<InitABCStatisticRepository> {
        InitABCStatisticRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }


    single<ABCStatisticDataRepository> {
        ABCStatisticDataRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<BillPdfRepository> {
        BillPdfRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }



}