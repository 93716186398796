package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.bill_search.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.ReportsTableFilterOptions
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi.StatisticEvent
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px


@Composable
fun BillSearchSetting(
    windowSizeMode: WindowSizeModeEnum,
    selectedDate: MonthModel?,
    selectedFrom: String,
    selectedTo: String,
    selectedCalendarHeaderYear:Int,
    selectedPeriodType: PeriodDaysEnum?,
    showCalendarFilterDialog: Boolean,
    showDaysFilterDialog: Boolean,
    event:(StatisticEvent)->Unit,
) {
    val billNumberFrom= remember { mutableStateOf<Int?>(null) }
    val billNumberTo= remember { mutableStateOf<Int?>(null) }
    val totalFrom= remember { mutableStateOf<Int?>(null) }
    val totalTo= remember { mutableStateOf<Int?>(null) }


    Column()
    {
        MediumContentTitle(
            title = Strings.setting
        )

        ReportsTableFilterOptions(
            selectedPeriodType = selectedPeriodType,
            showDaysFilterDialog=showDaysFilterDialog,
            selectedDate = selectedDate,
            showCalendarFilterDialog = showCalendarFilterDialog,
            selectedTo = selectedTo,
            selectedFrom = selectedFrom,
            selectedCalendarHeaderYear =selectedCalendarHeaderYear,
            setDaysFilterDialogStatus = {
                event(StatisticEvent.SetDaysFilterDialogStatus(it))
            },
            submitFilterItemsBasedOnDays = { from:String, to:String, type: PeriodDaysEnum ->
                event(
                    StatisticEvent.SubmitStatisticDaysFilter(
                        from=from,
                        to=to,
                        periodDaysType=type,
                    )
                )
            },
            setCalendarFilterDialogStatus = {
                event(StatisticEvent.SetCalendarFilterDialogStatus(status = it))
            },
            onSelectDate = {
                event(
                    StatisticEvent.OnSelectDate(
                        selectedDate = it,
                    )
                )
            },
            filterDate = {from:String,to:String->
                event(
                    StatisticEvent.SubmitStatisticCalendarFilter(
                        from,
                        to,
                    )
                )
            },
            closeCalendarDialog = {
                event(
                    StatisticEvent.SetCalendarFilterDialogStatus(false)
                )
            },
            setNewCalendarHeaderYear = {
                event(
                    StatisticEvent.SetNewCalendarHeaderYear(it)
                )
            }
        )

        Column()
        {

            Row(
                modifier = Modifier
            ) {
                CustomEditText(
                    modifier = Modifier,
                    marginRight = Dimen.normalPadding,
                    width = Dimen.highNormalEditTextWidth,
                    height = Dimen.mediumOutlinedEditTextHeight,
                    label = Strings.billNumberFrom,
                    inputType = InputType.Number,
                    isOutlinedEditText = true,
                    textAlign = TextAlign.End,
                    value = "",
                    isDecimal = true,
                    isRequired = false,
                    defaultBorderColor = Theme.GrayCD.rgb,
                    onFocusIn = {},
                    onFocusOut = {

                    },
                    onInputChange = {
                        if (it.isNotEmpty())
                            billNumberFrom.value=it.toInt()
                        else billNumberFrom.value=null
                    },

                )

                CustomEditText(
                    modifier = Modifier,
                    width = Dimen.highNormalEditTextWidth,
                    height = Dimen.mediumOutlinedEditTextHeight,
                    label =Strings.BillNumberTo,
                    isOutlinedEditText = true,
                    inputType = InputType.Number,
                    textAlign = TextAlign.End,
                    value = "",
                    isDecimal = true,
                    isRequired = false,
                    defaultBorderColor = Theme.GrayCD.rgb,
                    onFocusIn = {},
                    onFocusOut = {

                    },
                    onInputChange = {
                        if (it.isNotEmpty())
                            billNumberTo.value=it.toInt()
                        else billNumberTo.value=null
                    },

                )
            }

            Row(
                modifier = Modifier
            ) {

                CustomEditText(
                    modifier = Modifier,
                    inputType = InputType.Number,
                    marginRight = Dimen.normalPadding,
                    width = Dimen.highNormalEditTextWidth,
                    height = Dimen.mediumOutlinedEditTextHeight,
                    label = Strings.totalFrom,
                    isOutlinedEditText = true,
                    textAlign = TextAlign.End,
                    value = "",
                    isDecimal = true,
                    isRequired = false,
                    defaultBorderColor = Theme.GrayCD.rgb,
                    onFocusIn = {},
                    onFocusOut = {

                    },
                    onInputChange = {
                        if (it.isNotEmpty())
                            totalFrom.value=it.toInt()
                        else totalFrom.value=null
                    },

                )

                CustomEditText(
                    modifier = Modifier,
                    width = Dimen.highNormalEditTextWidth,
                    height = Dimen.mediumOutlinedEditTextHeight,
                    label =Strings.totalTo,
                    inputType = InputType.Number,
                    isOutlinedEditText = true,
                    textAlign = TextAlign.End,
                    value = "",
                    isDecimal = true,
                    isRequired = false,
                    defaultBorderColor = Theme.GrayCD.rgb,
                    onFocusIn = {},
                    onFocusOut = {

                    },
                    onInputChange = {
                        if (it.isNotEmpty())
                            totalTo.value=it.toInt()
                        else totalTo.value=null
                    },

                )
            }


        }

        CustomButton(
            modifier = Modifier,
            windowSizeMode=windowSizeMode,
            topBottomMargin = Dimen.normalPadding,
            leftRightMargin = 0.px,
            title = Strings.calculation,
            onButtonClick = {
                event(
                    StatisticEvent.InitBillSearch(
                        billNumberFrom=billNumberFrom.value,
                        billNumberTo =billNumberTo.value ,
                        totalFrom=totalFrom.value,
                        totalTo=totalTo.value
                    )
                )
            }

        )
    }

}
