package org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.series

import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.series.reset_range_loyalty_card.ResetRangeLoyaltyCardModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.series.ResetRangeLoyaltyCardRepository

class ResetRangeLoyaltyCardUseCase(
    private val repository:ResetRangeLoyaltyCardRepository
) {
    suspend operator fun invoke(
        from:Int,
        to:Int,
        description:String ,
    ):ResetRangeLoyaltyCardModel
    {
        return repository.resetRangeLoyaltyCard(
            from=from,
            to=to,
            description=description,
        )
    }
}