package org.hs_soft.runmynesto.data.api_datasource.template.tab_surcharge_in_house

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_surcharge.UpdateSurchargeModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_surcharge_in_house.UpdateSurchargeRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils


class UpdateSurchargeRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateSurchargeRepository {

    override suspend fun updateSurcharge(
        id: String,
        dataTitle: String,
        dataValue: List<Int?>
    ): UpdateSurchargeModel {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            mutation updateCategory_Surcharge(${'$'}id: ID!, ${'$'}fields: CategoryFields) {
              cashAssist {
                updateCategory(id: ${'$'}id, fields: ${'$'}fields) {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.surchargeFrom}
                  ${ApiUtils.Field.surchargeAmount}
                  ${ApiUtils.Field.surchargePercent}
                   
                }
              }
            }
            
            
            
        """.trimIndent())

            putJsonObject("variables")
            {
                put("id", id)
                putJsonObject("fields") {
                    put(dataTitle, Json.encodeToJsonElement(dataValue))
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<UpdateSurchargeModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }

}
