package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.select_days

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CircleIcon
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun SelectedItem(
    name: String,
    onDeleteClick: () -> Unit,
)
{

    Row(
        modifier = Modifier
            .margin(Dimen.extraSmallPadding)
            .padding(
                leftRight = Dimen.smallPadding,
                topBottom = Dimen.extraSmallPadding
            )
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
            .backgroundColor(Theme.GrayAlpha08.rgb)
            .borderRadius(Dimen.largeBorderRadius),
        verticalAlignment = Alignment.CenterVertically
    )
    {
        SpanText(
            text = name,
            modifier = Modifier
                .fontSize(FontSizes.normalFontSize)
                .color(Theme.Primary.rgb)
                .fontWeight(FontWeight.Normal)
                .fontFamily(FontFamily.Jakarta)
        )

        CircleIcon(
            modifier = Modifier
                .margin(left= Dimen.smallPadding),
            img = Res.PathIcon.smallClose,
            size =Dimen.extraSmallIcon ,
            borderColor = Theme.Transparent.rgb,
            tintColor = Theme.GrayAlpha38.hex,
            onIconClick ={
                onDeleteClick()
            },
        )



    }
}

