package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.getCheckoutWidth
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh

@Composable
fun ReportsTableSearchBar(
    windowSizeMode: WindowSizeModeEnum,
    onSearchInfo: (String) -> Unit,
)
{
    CustomEditText(
        modifier = Modifier,
        label = "",
        value = "",
        width =when(windowSizeMode){
            WindowSizeModeEnum.WINDOWS ->getCheckoutWidth(windowSizeMode)
            WindowSizeModeEnum.TABLET -> Constants.Responsive.ClosingReportsTableTablet.px
            WindowSizeModeEnum.PHONE -> Constants.Responsive.ClosingReportsTablePhone.px
        }

        ,
        endIconPath = Res.PathIcon.searchBox,
        isRequired = false,
        defaultBorderColor = Theme.GrayCD.rgb,
        hint = Strings.search,
        onFocusIn = {},
        onFocusOut = {},
        onInputChange = {
            onSearchInfo(it)
        },
    )
}