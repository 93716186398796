package org.hs_soft.runmynesto.domain.usecase.product

import org.hs_soft.runmynesto.domain.model.home.product.add_new_product.AddNewProductModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.AddProductRepository

class AddProductUseCase(
    private val repository: AddProductRepository
) {
    suspend operator fun invoke(
        name: String,
        code: String,
        categoryId:String?=null
    ): AddNewProductModel {
        return repository.addProduct(
            name=name,
            code=code,
            categoryId=categoryId,
        )
    }


}