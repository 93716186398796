package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SalesGroup(
    @SerialName("amount")
    val amount: Double?,
    @SerialName("discount")
    val discount: Double?,
    @SerialName("id")
    val id: String?,
    @SerialName("mWStFree")
    val mWStFree: Double?,
    @SerialName("mWStNormal")
    val mWStNormal: Double?,
    @SerialName("mWStReduced")
    val mWStReduced: Double?,
    @SerialName("reduction")
    val reduction: Double?,
    @SerialName("salesGroup")
    val salesGroup: SalesGroupX?,
    @SerialName("total")
    val total: Double?,
    @SerialName("__typename")
    val typename: String?
)