package org.hs_soft.runmynesto.pages.components.dialog.dialog

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.delay
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config.GetVirtualCardConfig
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.chat_model.ChatMessagesModel
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.chat_model.RoleMessageEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CustomTextArea
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*


@Composable
fun DataAssistantAIDialog(
    windowSizeMode: WindowSizeModeEnum,
    onBackgroundClick: () -> Unit,
    onCancelDataAssistantButtonClick: () -> Unit,
    onQuestionInputChange: (String) -> Unit,
    loading:Boolean,
    onGenerateAnswerClick: (String, String, ChatMessagesModel) -> Unit,
    question: String,
    messages: ChatMessagesModel,
    sendIsClickable: Boolean,
    info: GetVirtualCardConfig?,
    getDataAssistantMessageHistory: (String) -> Unit,
)
{
    LaunchedEffect(Unit){
        getDataAssistantMessageHistory(info!!.issuerEmail ?: "")
    }

    Box(
        modifier = Modifier
           .fillMaxSize()
            .onClick {
                onBackgroundClick()
            }
            .zIndex(2f)
    ){
        Column(
            modifier = Modifier
                .onClick {
                    event: SyntheticMouseEvent -> event.stopPropagation()
                }
                .align(Alignment.BottomEnd)
                .margin(right = Dimen.largePadding, bottom = Dimen.normalPadding)
                .padding(Dimen.mediumPadding)
                .width(Dimen.dataAssistantAIDialogWidth)
                .height(Dimen.dataAssistantAIDialogHeight)
                .backgroundColor(Theme.White.rgb)
                .zIndex(1)
                .boxShadow(
                    color = Theme.GrayCD.rgb,
                   blurRadius = Dimen.smallBlurRadius,
                )
                .borderRadius(r= Dimen.lowBorderRadius),
            horizontalAlignment = Alignment.CenterHorizontally
        ){

            Header(
                onCancelDataAssistantButtonClick=onCancelDataAssistantButtonClick
            )

            ChatMessages(
                messages = messages
            )

            if (loading){
                LoadingComponent(loading=loading)
            }

            Spacer()

            GenerateAnswer(
                onQuestionInputChange=onQuestionInputChange,
                onGenerateAnswerClick=onGenerateAnswerClick,
                question=question,
                messages = messages,
                sendIsClickable = sendIsClickable,
                info = info
            )
        }

    }
}


@Composable
private fun LoadingComponent(loading: Boolean) {
    var currentStage by remember { mutableStateOf(0) }
    val stageMessages = listOf(
        Strings.analizeQuestionProcess,
        Strings.generateSQLProcess,
        Strings.formatAnswerProcess,
        Strings.createFilesProcess,
        Strings.prepareResultProcess
    )
    val stageDelays = listOf(
        Constants.delayDefaultProcess * 3,
        Constants.delayGenerateSQL,
        Constants.delayDefaultProcess * 2,
        Constants.delayDefaultProcess,
        Constants.timeout * 10
    )

    LaunchedEffect(currentStage, loading) {
        while (loading && currentStage < stageMessages.size) {
            delay(stageDelays[currentStage])
            currentStage = (currentStage + 1) % (stageMessages.size + 1)
        }
    }

    if (loading && currentStage < stageMessages.size) {
        val progressMessage = "${currentStage}/${stageMessages.size} ${Strings.menuCardProgressMessage}"
        Column(
            modifier = Modifier.margin(top = Dimen.normalPadding),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SpanText(
                text = stageMessages[currentStage],
                modifier = Modifier
                    .fontSize(FontSizes.mediumFontSize)
                    .fontFamily(FontFamily.Jakarta)
                    .fontWeight(FontWeight.Medium)
                    .color(Theme.DarkBlue.rgb)
                    .padding(leftRight = Dimen.smallPadding)
            )
            SpanText(
                text = progressMessage,
                modifier = Modifier
                    .fontSize(FontSizes.mediumFontSize)
                    .fontFamily(FontFamily.Jakarta)
                    .fontWeight(FontWeight.Medium)
                    .color(Theme.DarkBlue.rgb)
                    .padding(top = Dimen.smallPadding)
            )
        }
    }
}


@Composable
private fun GenerateAnswer(
    onQuestionInputChange: (String) -> Unit,
    onGenerateAnswerClick: (String, String, ChatMessagesModel) -> Unit,
    question: String,
    messages: ChatMessagesModel,
    sendIsClickable: Boolean,
    info: GetVirtualCardConfig?,
) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .margin(bottom=Dimen.normalPadding)
            .height(Dimen.smallThickness)
            .backgroundColor(Theme.GrayCD.rgb)
    )

    Row(
        modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        CustomTextArea(
            value = question,
            label = Strings.question,
            width = Dimen.extraMediumEditTextWidth,
            height = Dimen.extraSmallTextAreaHeight,
            maxHeight = Dimen.smallTextAreaHeight,
            onFocusOut = {

            },
            onValueChange = {
                onQuestionInputChange(it)
            }
        )

        Row(
            modifier = Modifier
                .onClick {
                    if (question.isNotEmpty() && sendIsClickable)
                        onGenerateAnswerClick(info!!.issuerEmail ?: "", question, messages)
                 },
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        )
        {
            VectorIcon(
                modifier = Modifier
                    .cursor(Cursor.Pointer),
                pathData = Res.PathIcon.sendQuestion,
                width = Dimen.normalIconSize,
                height = Dimen.normalIconSize,
                color = if (question.isNotEmpty() && sendIsClickable) Theme.Blue.hex
                else Theme.Gray3E.hex
            )
        }
    }
}


@Composable
private fun ChatMessages(messages: ChatMessagesModel) {
    Div(
        attrs = {
            style {
                width(100.percent)
                property("max-height", 100.percent)
                overflowY("auto")  // Enables vertical scrolling
            }
        }
    ) {
        messages.messages.forEach { message ->
            Box(
                modifier = Modifier
                    .width(80.percent)
                    .maxWidth(Dimen.dataAssistantAIDialogWidth)
                    .padding(Dimen.normalPadding)
                    .borderRadius(Dimen.mediumBorderRadius)
                    .boxShadow(
                        offsetX = 0.px,
                        offsetY =1.px,
                        blurRadius = 2.px,
                        spreadRadius = 0.px,
                        color = Colors.Black.copyf(alpha = 0.15f)
                    )
                    .margin(topBottom = Dimen.semiNormalPadding)
                    .backgroundColor(if (message.role == RoleMessageEnum.USER.role)
                        Theme.ExtraLightBlueAlpha12.rgb else Theme.White.rgb),
                contentAlignment = Alignment.CenterStart
            ) {
                Column {
                    SpanText(
                        text = message.role,
                        modifier = Modifier
                            .fontSize(FontSizes.mediumFontSize)
                            .color(Theme.Blue.rgb)
                    )
                    SpanText(
                        text = message.content.text,
                        modifier = Modifier
                            .fontSize(FontSizes.semiLargeFontSize)
                            .overflowWrap(OverflowWrap.BreakWord)
                            .wordBreak(WordBreak.BreakAll)
                    )
                    message.content.files.forEach { file ->
                        Link(
                            path = file.path,
                            text = "${Strings.download} ${file.fileFormat}",
                            modifier = Modifier.textDecorationLine(TextDecorationLine.None)
                        )
                    }
                }
            }
        }
    }
}


@Composable
private fun Header(
    onCancelDataAssistantButtonClick: () -> Unit,
) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                src = Res.PathIcon.logo,
                modifier = Modifier
                    .size(Dimen.mediumIconSize.px)
                    .margin(right=Dimen.normalPadding)
            )

            SpanText(
                text = Strings.dataAssistant,
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .fontSize(FontSizes.mediumFontSize)
                    .fontFamily(FontFamily.Jakarta)
                    .fontWeight(FontWeight.Medium)
                    .color(Theme.Primary.rgb)
            )
        }

        Row(
        ) {
            VectorIcon(
                modifier = Modifier
                    .align(Alignment.CenterVertically)
                    .onClick {
                        onCancelDataAssistantButtonClick()
                             }
                    .cursor(Cursor.Pointer),
                pathData = Res.PathIcon.close,
                width= Dimen.mediumIconSize,
                height= Dimen.mediumIconSize,
            )
        }
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .margin(top=Dimen.normalPadding)
            .height(Dimen.smallThickness)
            .backgroundColor(Theme.GrayCD.rgb)
    )
}


