package org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.update_checkout


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateUser(
    @SerialName("cashRegisterId")
    val cashRegisterId: List<String>,
    @SerialName("id")
    val id: String,
    @SerialName("mod_ver")
    val modVer: Int,
    @SerialName("__typename")
    val typename: String
)