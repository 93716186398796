package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_checkout

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_checkout.update_checkout.UpdateProductCheckoutModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_checkout.UpdateProductCheckoutRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateProductCheckoutRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateProductCheckoutRepository {


    override suspend fun updateProductCheckout(
        userId: String,
        dataValue: MutableSet<String>
    ): UpdateProductCheckoutModel {

        val abortController = requestManager.createController()


        val graphQLQuery = buildJsonObject {
            put("query", """
        mutation updateProduct_cashRegisters(${'$'}id: ID!, ${'$'}fields: ProductFields!) {
          cashAssist {
            updateProduct(id: ${'$'}id, fields: ${'$'}fields) {
              ${ApiUtils.Field.id}
              ${ApiUtils.Field.mod_ver}
              ${ApiUtils.Field.cashRegisterId}
              ${ApiUtils.Field.__typename}
            }
            ${ApiUtils.Field.__typename}
          }
        }
    """.trimIndent())


            putJsonObject("variables") {
                put("id", userId)
                putJsonObject("fields") {
                    put(ApiUtils.Field.cashRegisterId,Json.encodeToJsonElement(dataValue))
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<UpdateProductCheckoutModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }

    }

}
