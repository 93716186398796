package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ImgX(
    @SerialName("key")
    val key: String?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("uid")
    val uid: String?
)