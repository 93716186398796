package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetCashRegister(
    @SerialName("clientNumber")
    val clientNumber: Int?,
    @SerialName("device")
    val device: Device?,
    @SerialName("deviceIdentity")
    val deviceIdentity: DeviceIdentify?,
    @SerialName("deviceType")
    val deviceType: String?,
    @SerialName("externToolBox")
    val externToolBox: String?,
    @SerialName("filial")
    val filial: String?,
    @SerialName("hardwareId")
    val hardwareId: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("installVersion")
    val installVersion: String?,
    @SerialName("isSynced")
    val isSynced: Boolean?,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("mode")
    val mode: Int?,
    @SerialName("name")
    val name: String?,
    @SerialName("parameters")
    val parameters: Parameters?,
    @SerialName("priceLevelIn")
    val priceLevelIn: Int?,
    @SerialName("priceLevelOut")
    val priceLevelOut: Int?,
    @SerialName("shopId")
    val shopId: String?,
    @SerialName("status")
    val status: String?,
    @SerialName("tseInfo")
    val tseInfo: TseInfo?,
    @SerialName("tseRemoteCashRegisterId")
    val tseRemoteCashRegisterId: String?,
    @SerialName("__typename")
    val typename: String?
)