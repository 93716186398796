package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.CashRegisterConfigHeaders
import org.hs_soft.runmynesto.pages.components.TabItem
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.devices.DeviceTabCashRegisterConfig
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.general.GeneralTabCashRegisterConfig
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.hardware.HardwareTabCashRegisterConfig
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.operation.OperationTabCashRegisterConfig
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsState

@Composable
fun CashRegisterConfigContent(
    state:OptionsState,
    event: (OptionsEvent) -> Unit,
    windowSizeMode: WindowSizeModeEnum,
){
    if (state.selectedCashRegisterConfigItemId!=null){

        Column(
            modifier= Modifier
                .fillMaxSize()
                .thenIf(
                    windowSizeMode!=WindowSizeModeEnum.PHONE,
                    Modifier
                        .padding(leftRight = Dimen.normalPadding)
                        .margin(left = Dimen.normalPadding)
                )

        )
        {
            Column {
                Row(modifier = HorizontalScrollBehavior
                    .toModifier()
                    .thenIf(
                        windowSizeMode!=WindowSizeModeEnum.PHONE,
                        Modifier.margin(leftRight = Dimen.mediumPadding)
                    )
                    .margin(
                        bottom = Dimen.normalPadding
                    )
                    .weight(1f)
                    .then(
                        if (windowSizeMode==WindowSizeModeEnum.PHONE)
                            Modifier.width(
                                Dimen.mobileTabsWidth
                            )
                        else if (windowSizeMode==WindowSizeModeEnum.TABLET){
                            Modifier.width(
                                Dimen.tabletTabsSmallWidth
                            )
                        }
                        else Modifier
                    ),
                    verticalAlignment = Alignment.CenterVertically
                )
                {
                    CashRegisterConfigHeaders.entries.forEach { tab->
                        TabItem(
                            title = tab.title,
                            isActive = tab==state.selectedCashRegisterConfigTab,
                            onTabClick = {
                                event(OptionsEvent.OnCashRegisterConfigTabClick(tab))
                            },
                            windowSizeMode = windowSizeMode,
                        )
                    }

                }
            }



            when(state.selectedCashRegisterConfigTab)
            {
                CashRegisterConfigHeaders.OPERATION->{

                    OperationTabCashRegisterConfig(
                        data =state.cashRegisterConfigData?.parameters,
                        windowSizeMode=windowSizeMode,
                        updateData = { title:String, value:dynamic->
                            event(OptionsEvent.UpdateCashRegisterConfigData(
                                title=title,
                                value=value
                            ))
                        }
                    )

                }
                CashRegisterConfigHeaders.DEVICES->{

                    DeviceTabCashRegisterConfig(
                        windowSizeMode=windowSizeMode,
                        data =state.cashRegisterConfigData?.parameters,
                        updateData = {title:String,value:dynamic->
                            event(OptionsEvent.UpdateCashRegisterConfigData(
                                title = title,
                                value=value
                            ))
                        }
                    )

                }
                CashRegisterConfigHeaders.HARDWARE->{
                    HardwareTabCashRegisterConfig(
                        modifier = Modifier.weight(1f),
                        windowSizeMode = windowSizeMode,
                        printerLocations=state.appConfig?.cashRegisterGlobal?.printerLocations,
                        parameters=state.cashRegisterConfigData?.parameters,
                        updateData = {title: String, value: dynamic ->
                            event(
                                OptionsEvent.UpdateCashRegisterConfigData(
                                    title = title,
                                    value = value
                                )
                            )

                        }
                    )

                }

                CashRegisterConfigHeaders.GENERAL->{

                    GeneralTabCashRegisterConfig(
                        windowSizeMode=windowSizeMode,
                        cashList=state.cashList,
                        selectedOffice=state.selectedOffice,
                        selectedRegion=state.selectedRegion,
                        selectedBranch = state.selectedBranch,
                        cashRegisterConfigData=state.cashRegisterConfigData,
                        event =event,
                    )

                }



            }
        }
    }




}