package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_surcharge


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateCategory(
    @SerialName("id")
    val id: String,
    @SerialName("surchargeAmount")
    val surchargeAmount: List<Int?>,
    @SerialName("surchargeFrom")
    val surchargeFrom: List<Int?>,
    @SerialName("surchargePercent")
    val surchargePercent: List<Int?>
)