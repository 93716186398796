package org.hs_soft.runmynesto.domain.usecase.statistic.abs_statistic

import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.init_abc_statistic.InitABCStatisticModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.abc_statistic.InitABCStatisticRepository

class InitABCStatisticUseCase(
    private val repository: InitABCStatisticRepository
) {
    suspend operator fun invoke(
        cashRegisterIds: List<String>,
        from: String,
        groupBy: String,
        productIds: List<String>,
        to: String,
    ): InitABCStatisticModel {

        return repository.initABCStatistic(
            cashRegisterIds = cashRegisterIds,
            productIds = productIds,
            from = from,
            to = to,
            groupBy = groupBy,
        )
    }


}