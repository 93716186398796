package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TseInfo(
    @SerialName("expirationDate")
    val expirationDate: String?,
    @SerialName("info")
    val info: String?,
    @SerialName("__typename")
    val typename: String?
)