package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("getAppConfig")
    val getAppConfig: GetAppConfig?=null,
    @SerialName("updateAppConfig")
    val updateAppConfig: GetAppConfig?=null,
    @SerialName("__typename")
    val typename: String
)