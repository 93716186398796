package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Res
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color

@Composable
fun WalletBarCode(
    modifier:Modifier=Modifier,
    textColor: String =Theme.GrayAlpha56.hex,
) {
    Column(
        modifier = modifier
            ,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Image(
            modifier = Modifier
                .borderRadius(Dimen.largeBorderRadius),
            src = Res.PathIcon.barcode,
            width = Dimen.barcodeSize,
            height = Dimen.barcodeSize
        )

        SpanText(
            text = Strings.aboutTheCard,
            modifier = Modifier
                .margin(top = Dimen.normalPadding)
                .fontFamily(FontFamily.Montserrat)
                .color(Color(textColor))
                .fontWeight(FontWeight.Normal)
                .fontSize(FontSizes.normalFontSize)
        )
    }

}
