package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.VectorIcon

@Composable
fun TopDashboardChartToolbar(
    onBackClick: () -> Unit,
    name: String?,
)
{

    Row(
        verticalAlignment = Alignment.CenterVertically
    )
    {
        VectorIcon(
            modifier = Modifier
            .onClick {
                 onBackClick()
            },
            pathData = Res.PathIcon.leftArrow,
            color = Theme.Gray3E.hex,
            width = Dimen.semiLargeIconSize,
            height = Dimen.semiLargeIconSize
        )

        MediumContentTitle(
            title = name?:""
        )

    }


}