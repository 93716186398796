package org.hs_soft.runmynesto.domain.model.home.options.cash_flow.app_config_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("getAppConfig")
    val getAppConfig: GetAppConfig?=null,
    @SerialName("updateAppConfig")
    val updateAppConfig: GetAppConfig?=null
)