package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.util.getEndOfMonth
import org.hs_soft.runmynesto.domain.util.getStartOfMonth

enum class PeriodMonthEnum(
    val title: String,
    val from: String,
    val to: String,
) {
    January(
        title = Strings.January,
        from = getStartOfMonth(1),
        to = getEndOfMonth(1)
    ),
    February(
        title = Strings.February,
        from = getStartOfMonth(2),
        to = getEndOfMonth(2)
    ),
    March(
        title = Strings.March,
        from = getStartOfMonth(3),
        to = getEndOfMonth(3)
    ),
    April(
        title = Strings.April,
        from = getStartOfMonth(4),
        to = getEndOfMonth(4)
    ),
    May(
        title = Strings.May,
        from = getStartOfMonth(5),
        to = getEndOfMonth(5)
    ),
    June(
        title = Strings.June,
        from = getStartOfMonth(6),
        to = getEndOfMonth(6)
    ),
    July(
        title = Strings.July,
        from = getStartOfMonth(7),
        to = getEndOfMonth(7)
    ),
    August(
        title = Strings.August,
        from = getStartOfMonth(8),
        to = getEndOfMonth(8)
    ),
    September(
        title = Strings.September,
        from = getStartOfMonth(9),
        to = getEndOfMonth(9)
    ),
    October(
        title = Strings.October,
        from = getStartOfMonth(10),
        to = getEndOfMonth(10)
    ),
    November(
        title = Strings.November,
        from = getStartOfMonth(11),
        to = getEndOfMonth(11)
    ),
    December(
        title = Strings.December,
        from = getStartOfMonth(12),
        to = getEndOfMonth(12)
    )
}