package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.util.Res
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun FilterComponent(
    title:String,
    onFilterClick: () -> Unit
) {
    val isHovered= remember { mutableStateOf(false) }

    Row(
        modifier = Modifier
            .minHeight(Dimen.filterHeight)
            .width(Dimen.filterWidth)
            .onMouseLeave {
                isHovered.value=false
            }
            .onMouseOver {
                isHovered.value=true
            }
            .padding(
                leftRight = Dimen.mediumPadding,
                topBottom = Dimen.smallPadding
            )
            .transitionBackground()
            .backgroundColor(
                if (isHovered.value) Theme.GrayAlpha04.rgb
                else Theme.Transparent.rgb
            )
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color =Theme.GrayCD.rgb
            )
            .rippleEffect {
                onFilterClick()
            }
            .borderRadius(r = Dimen.lowBorderRadius),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
    )
    {
        VectorIcon(
            modifier = Modifier,
            pathData = Res.PathIcon.filter,
            width = Dimen.mediumIconSize,
            height = Dimen.mediumIconSize,
            color = Theme.GrayAlpha56.hex,
        )

        SpanText(
            text = title,
            modifier = Modifier
                .margin(Dimen.smallPadding)
                .fontSize(FontSizes.highMediumFontSize)
                .color(Theme.Secondary.rgb)
                .cursor(Cursor.Pointer)
                .fontFamily(FontFamily.Jakarta)
                .fontWeight(FontWeight.Medium)
        )

    }
}