package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class WeightTara(
    @SerialName("isAutoSelect")
    val isAutoSelect: List<IsAutoSelect?>?,
    @SerialName("names")
    val names: List<Name?>?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("values")
    val values: List<Value?>?
)