package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
//@SerialName("gridProducts")
data class ArticleModel(
    @SerialName("categoryId")
    val categoryId: String?,
    @SerialName("id")
    val id: String,
    @SerialName("position")
    val position: Int,
    @SerialName("product")
    val product: Product?,
    @SerialName("productId")
    val productId: String,
    @SerialName("__typename")
    val typename: String?
)