package org.hs_soft.runmynesto.data.api_datasource.options.menu_card_import

import kotlinx.browser.window
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.menu_card_import.GetMenuCardRepository

import kotlinx.coroutines.*
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.util.*
import org.w3c.fetch.Headers
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.time.Duration.Companion.seconds

class GetMenuCardRepositoryImpl(
    requestManager: RequestManager
) : GetMenuCardRepository {

    private val abortController = requestManager.createController()

    override suspend fun getMenuCard(files: List<File>): String {

        return withContext(Dispatchers.Default) {
            retry(times = 3, initialDelay = 2.seconds) {
                withTimeout(Constants.timeout.seconds) {
                    fetchMenuCard(files)
                }
            }
        }
    }

    data class Base64File(val filename: String, val mime: String, val data: String)

    suspend fun convertFileToBase64(file: File): Base64File = suspendCancellableCoroutine { continuation ->
        val reader = FileReader()
        reader.onload = { event ->
            val result = event.target.asDynamic().result as String
            val base64 = result.substring(result.indexOf(",") + 1)
            continuation.resume(Base64File(file.name, file.type, base64))
        }
        reader.onerror = { event ->
            continuation.resumeWithException(RuntimeException("Error reading file"))
        }
        reader.readAsDataURL(file)
    }

    suspend fun fetchMenuCard(files: List<File>): String = coroutineScope {
        val url = ApiUtils.Domain.baseDomain + ApiPath.getMenuCard
        val headers = Headers().apply {
            append("x-api-key", ApiUtils.API_KEY.RESTAURANTS_API_KEY_VALUE)
            append("Content-Type", "application/json")
        }

        val base64Files = files.map {
            async { convertFileToBase64(it) }
        }.awaitAll()

        val body = JSON.stringify(jsObject<Any> {
            this["files"] = base64Files.map { mapOf("filename" to it.filename, "mime" to it.mime, "data" to it.data) }
        })
        val requestInit: dynamic = js("({})")
        requestInit.method = "POST"
        requestInit.headers = headers
        requestInit.body = body

        val response = window.fetch(url, requestInit).await()

        if (!response.ok) {
            console.error("HTTP Error Response: ${response.status} ${response.statusText}")
        }
        response.text().await()
    }


    private suspend fun <T> retry(
        times: Int,
        initialDelay: kotlin.time.Duration,
        factor: Double = 2.0,
        block: suspend () -> T
    ): T {
        var currentDelay = initialDelay
        repeat(times - 1) {
            try {
                return block()
            } catch (e: Exception) {
                // You can add logging here
            }
            delay(currentDelay)
            currentDelay *= factor
        }
        return block() // last attempt
    }
}


