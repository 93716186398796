package org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ClientTree(
    @SerialName("regions")
    val regions: List<Region?>?,
    @SerialName("__typename")
    val typename: String?
)