package org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location.PrinterLocationModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.PrinterLocationNamesRepository


class PrinterLocationNamesUseCase(
    private val repository: PrinterLocationNamesRepository
) {
    suspend operator fun invoke(): PrinterLocationModel {
        return repository.getPrinterLocationsName()
    }


}