package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CircleIcon

@Composable
fun WaiterHoverBackground(
    isHovered:Boolean,
    onDeleteClick:()->Unit,
)
{
    Box(
        modifier = Modifier
            .fillMaxSize()
            .transitionBackground()
            .backgroundColor(
                if (isHovered) Theme.GrayAlpha60.rgb
                else Theme.Transparent.rgb
            )
            .borderRadius(Dimen.normalBorderRadius)

    )
    {

        if (isHovered){
            CircleIcon(
                modifier = Modifier.align(Alignment.Center),
                img = Res.PathIcon.delete,
                hoveredBackground = Theme.Transparent.rgb,
                borderColor = Theme.Transparent.rgb,
                tintColor = Theme.White.hex,
                size = Dimen.mediumIconSize,
                onIconClick = {
                    onDeleteClick()
                }
            )
//            VectorIcon(
//                modifier = Modifier
//                    .align(Alignment.Center),
//                pathData = Res.PathIcon.delete,
//                width =Dimen.mediumIconSize ,
//                height =Dimen.mediumIconSize,
//                color = Theme.White.hex,
//            )
        }


    }
}