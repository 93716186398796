package org.hs_soft.runmynesto.domain.usecase.statistic.search_bill

import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data.StatisticDataModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.search_bill.BillSearchStatisticDataRepository

class BillSearchStatisticDataUseCase(
    private val repository: BillSearchStatisticDataRepository
) {
    suspend operator fun invoke(
        requestId:String
    ): StatisticDataModel {

        return repository.getStatisticData(
            requestId=requestId
        )
    }


}