package org.hs_soft.runmynesto.pages.components.dialog.dialog

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.components.dialog.component.SubmitButtons
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun AddRecordDialog(
    windowSizeMode: WindowSizeModeEnum,
    dialogTitle:String,
    nameFieldTitle:String,
    nameValue:String,
    numberFieldTitle:String="",
    showNumberField:Boolean,
    numberValue:String="",
    isSubmitClickable:Boolean,
    errorMessage:String,
    loading:Boolean,
    isNameRequired:Boolean=true,
    isNumberRequired:Boolean=true,
    onNumberValueChange:(String)->Unit={},
    onNameValueChange:(String)->Unit,
    onCancelClick: () -> Unit,
    onSubmitClick:()->Unit,
    onBackgroundClick: () -> Unit,

)
{
    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick { onBackgroundClick()}
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(2f)
    )
    {
        Box(
            modifier = Modifier
                .align(Alignment.Center)
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.Transparent.rgb
                )
                .width(
                    if(windowSizeMode!= WindowSizeModeEnum.PHONE)
                        Dimen.addRecordDialogWidth
                    else Dimen.addRecordDialogPhoneWidth
                )
                .height(
                    if (showNumberField)
                        Dimen.addRecordDialogHeight
                    else (Dimen.addRecordDialogHeight.value.toInt()*0.75).px
                )
        ){
            Column(
                modifier = Modifier
                    .align(Alignment.Center)
                    .boxShadow(
                        blurRadius = Dimen.smallBlurRadius,
                        color = Theme.Gray3E.rgb,
                    )
                    .onClick {
                        event: SyntheticMouseEvent -> event.stopPropagation()
                    }
                    .fillMaxSize()
                    .backgroundColor(Theme.White.rgb)
                    .border(
                        width = Dimen.smallThickness,
                        style = LineStyle.Solid,
                        color = Theme.GrayCD.rgb
                    )
                    .borderRadius(Dimen.lowBorderRadius)
            )
            {
                SpanText(
                    text = dialogTitle ,
                    modifier = Modifier
                        .padding(
                            topBottom = Dimen.mediumPadding,
                            leftRight = Dimen.largePadding
                        )
                        .fontSize(FontSizes.largeFontSize)
                        .fontWeight(FontWeight.Medium)
                        .color(Theme.Secondary.rgb)
                        .fontFamily(FontFamily.Montserrat)
                        .lineHeight(FontLineHeight.lineLarge)
                        .letterSpacing(LetterSpacing.small)
                )

                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(Dimen.smallThickness)
                        .backgroundColor(Theme.GrayAlpha12.rgb)
                )

                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .padding(
                            leftRight = Dimen.largePadding,
                            topBottom = Dimen.mediumPadding,
                        )
                )
                {
                    if (showNumberField){
                        CustomEditText(
                            width = if (windowSizeMode!= WindowSizeModeEnum.PHONE)
                                Dimen.extraLargeEditTextWidth
                            else Dimen.semiLargeEditTextWidth
                            ,
                            isRequired = isNumberRequired,
                            label = numberFieldTitle,
                            value =numberValue,
                            defaultBorderColor = Theme.GrayCD.rgb,
                            isOutlinedEditText = true,
                            onFocusIn = {},
                            onFocusOut = {},
                            onInputChange = {
                                onNumberValueChange(it)
                            },

                        )

                    }

                    CustomEditText(
                        width =if (windowSizeMode!= WindowSizeModeEnum.PHONE)
                            Dimen.extraLargeEditTextWidth
                        else Dimen.semiLargeEditTextWidth,
                        label = nameFieldTitle,
                        value =nameValue,
                        isRequired = isNameRequired,
                        inputType = InputType.Text,
                        defaultBorderColor = Theme.GrayCD.rgb,
                        isOutlinedEditText = true,
                        onFocusIn = {},
                        onFocusOut = {},
                        onInputChange = {
                            onNameValueChange(it)
                        },
                    )

                    SpanText(
                        text = errorMessage,
                        modifier = Modifier
                            .fontSize(FontSizes.normalFontSize)
                            .fontFamily(FontFamily.Montserrat)
                            .fontWeight(FontWeight.Normal)
                            .margin(Dimen.smallPadding)
                            .color(Theme.Red.rgb)
                    )

                    Spacer()



                    SubmitButtons(
                        windowSize=windowSizeMode,
                        onCancelClick = {
                            onCancelClick()
                        },
                        onSubmitClick = {
                            onSubmitClick()
                        },
                        isSubmitClickable = isSubmitClickable,
                        arrangement= Arrangement.Center
                    )


                }



            }

            LoadingProgress(
                visibility = loading,
                modifier = Modifier
                    .borderRadius(
                        Dimen.lowBorderRadius
                    )
                , backgroundColor = Theme.Transparent.rgb
            )
        }

    }


}



