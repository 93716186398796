package org.hs_soft.runmynesto.data.local_datasource.local_storage.home

import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.CheckoutModel
import org.hs_soft.runmynesto.domain.repository.local_datasource.home.SaveCheckOutRepository
import org.hs_soft.runmynesto.domain.util.IdUtils

class SaveCheckOutRepositoryImpl:SaveCheckOutRepository
{
    override suspend fun saveCashList(model: CheckoutModel) {
        withContext(Dispatchers.Default) {
            val jsonData = Json.encodeToString(model)
            window.sessionStorage.setItem(IdUtils.checkOutList, jsonData)
        }


    }
}