package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.IdUtils
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.DashboardDialogs
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart.component.DashboardGeneralInfo
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart.component.chart.DashboardChart
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.mvi.DashboardEvent
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.mvi.DashboardState

@Composable
fun DashboardCharts(
    state: DashboardState,
    event:(DashboardEvent)->Unit,
    windowSizeMode: WindowSizeModeEnum,
)
{

    Column(
        modifier = Modifier
            .scrollBehavior(ScrollBehavior.Smooth)
            .fillMaxSize()
            .padding(
                topBottom = Dimen.normalPadding,
                leftRight = Dimen.largePadding,
            )
    ){


        DashboardGeneralInfo(
            windowSizeMode = windowSizeMode,
            cashRegistersCount=state.listShop?.filter {
                    it?.id == state.selectedDashboardItem?.shopId
                }?.flatMap { it?.cashRegisters.orEmpty()}?.size,
            currencyType=state.currencyType?:"",
            dashboardItem=state.selectedDashboardItem,
            selectedDate = state.selectedDate,
            dashboardItemName =state.dashboardItemName?:"",
            onBackClick={
                event(DashboardEvent.OnDetailBackClick)
            },
            setCalendarStatus = {
                event(DashboardEvent.SetCalendarStatus(it))
            }
        )

        DashboardChart(
            windowSizeMode=windowSizeMode,
            mainData=state.customerFrequencies?.map { it?.billCount },
            comparedData = state.comparedCustomerFrequencies?.map { it?.billCount },
            interval=state.customerFrequencies?.map { it?.interval },
            selectedDate = state.selectedDate,
            comparedDate = state.comparedDate,
            title = Strings.clients,
            id =IdUtils.clientChartId ,
            firstStrokeColor = Theme.Blue.hex ,
            firstFillColor =Theme.ExtraLightBlueAlpha12.hex ,
            secondStrokeColor = Theme.Orange.hex,
            secondFillColor =Theme.ExtraLightOrange.hex ,
            showCompareChartDialogStatus = state.showCompareChartDialog,
            todayCircleColor =Theme.Blue.rgb ,
            comparedCircleColor = Theme.Orange.rgb,
            showCompareChartDialog = {
                event(DashboardEvent.SetCompareChartDialogStatus(
                    status = !state.showCompareChartDialog
                ))
            },
            onCompareDayClick = {
                event(DashboardEvent.GetOtherDaysCustomerFrequency(it))
            }
        )


        DashboardChart(
            mainData = state.customerFrequencies?.map { it?.total },
            comparedData = state.comparedCustomerFrequencies?.map { it?.total },
            title = Strings.sales,
            id =IdUtils.salesChartId,
            firstFillColor =Theme.ExtraLightGreen.hex,
            firstStrokeColor = Theme.DarkGreen.hex,
            secondFillColor =Theme.ExtraLightOrange.hex,
            secondStrokeColor = Theme.Orange.hex,
            interval=state.customerFrequencies?.map { it?.interval },
            selectedDate = state.selectedDate,
            comparedDate = state.comparedDate,
            showCompareButton=false,
            showCompareChartDialogStatus = false,
            todayCircleColor = Theme.Green.rgb,
            comparedCircleColor =Theme.Orange.rgb,
            windowSizeMode = windowSizeMode,
            onCompareDayClick = {
                event(DashboardEvent.GetOtherDaysCustomerFrequency(it))
            },
        )


    }

    DashboardDialogs(
        windowSizeMode=windowSizeMode,
        showCalendarDialog = state.showCalendarDialog,
        selectedDate=state.selectedDate,
        calendarData = state.calendarData,
        onSelectDay = {
            event(DashboardEvent.GetCustomerFrequency(it))
        },
        onBackgroundClick = {
            event(DashboardEvent.SetCalendarStatus(false))
        },
        closeCalendarDialog = {
            event(DashboardEvent.SetCalendarStatus(false))
        }
    )


}