package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general


import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonArray
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group.SaleGroupModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.ProductSaleGroupRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class ProductSalesGroupRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): ProductSaleGroupRepository {

    override suspend fun getProductSaleGroup(): SaleGroupModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            query listSalesGroups(${'$'}params: ListQueryArgs) {
              cashAssist {
                listSalesGroups(params: ${'$'}params) {
                  items {
                        ${ApiUtils.Field.id}
                        ${ApiUtils.Field.name}
                        ${ApiUtils.Field.mod_ver}
                        ${ApiUtils.Field.acountNum}
                        ${ApiUtils.Field.discount}
                        clientDiscount {
                          ${ApiUtils.Field.number}
                          ${ApiUtils.Field.percent}
                          ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.__typename}
                      }
                      ${ApiUtils.Field.hasNextPage}
                      ${ApiUtils.Field.__typename}
                    }
                ${ApiUtils.Field.__typename}
              }
            }
        """.trimIndent())

            put("variables", buildJsonObject {
                put("params", buildJsonObject {
                    put("sort", buildJsonArray {
                        add(buildJsonObject {
                            put("field", "acountNum")
                            put("order", "ASC")
                        })
                    })
                })
                put("filter", buildJsonObject { })
            })
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<SaleGroupModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }

}

