package org.hs_soft.runmynesto.domain.usecase.product

import org.hs_soft.runmynesto.domain.repository.network_datasource.product.ImgUrlRepository


class GetImgUrlUseCase(
    private val repository: ImgUrlRepository
) {
    suspend operator fun invoke(
        key: String,
    ): String {
        return repository.getImgUrl(key)
    }


}