package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_options

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.pages.components.DropdownComponent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.dom.Text

@Composable
fun TemplateOptionContent(
    selectedIndex:Int?,
    getPriceLevels:()->Unit,
    priceLevelList:List<String>?,
    updatePriceLevel:(Int)->Unit,
)
{
    LaunchedEffect(Unit){
        getPriceLevels()
    }

    Column(
        Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .padding(Dimen.normalPadding)
//            .margin(leftRight = Dimen.mediumPadding,)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    )
    {
        DropdownComponent(
            modifier = Modifier
                .margin(top = Dimen.normalPadding),
            options = priceLevelList?: listOf(),
            label = Strings.priceLevels,
            selectedItem =priceLevelList?.find {
                it== priceLevelList[selectedIndex?:0]//TODO(the logic is a little skeptic
            },
            content = {
                Text(it)
            },
            onOptionClick = { selectedItem ->

                val index = priceLevelList?.indexOf(selectedItem)

                updatePriceLevel(index?:0)

            },
        )


    }


}