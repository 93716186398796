package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.grid_customer_card_bar.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card.LoyaltyCardItem
import org.hs_soft.runmynesto.pages.components.grid_function.GridFooter
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection


@Composable
fun CustomerCardGridFunction(
    modifier: Modifier = Modifier,
    items: List<LoyaltyCardItem?>,
    id:String,
    onItemClick:(String)->Unit,
    loadMoreData:()->Unit,

)
{
    Column(
        modifier = modifier
            .styleModifier {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Column)
            }
            .fillMaxHeight()
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width= Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
            .width(Dimen.gridCustomerCardWidth)
    )
    {
        GridCustomerCardHeader(
            numberDescending = null,
            onNumberSortClick = {

            }
        )

        CustomerCardGridItemList(
            id = id,
            items =items,
            onItemClick = {
                onItemClick(it)
            },
            loadMoreData = {
                loadMoreData()
            }
        )

        Spacer()

        GridFooter(
            title = Strings.customerCards,
            width = null,
            dataSize = items.size,
            onRefreshClick = {

            }
        )


    }
}