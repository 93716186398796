package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Resize
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.resize
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun OutlinedBox(
    modifier: Modifier=Modifier,
    label:String,
    height:CSSSizeValue<CSSUnit.px> = Dimen.outlinedBoxHeight,
    width:CSSSizeValue<CSSUnit.px> = Dimen.semiLargeEditTextWidth,
    boxContent:@Composable ()->Unit,
    expandableList:@Composable ()->Unit ={},
) {
    Box(
        modifier = modifier
            .width(width)
            .minHeight(height)
    )
    {
        SpanText(
            text = label,
            modifier = Modifier
                .zIndex(1)
                .align(Alignment.TopStart)
                .padding(leftRight = Dimen.smallPadding)
                .margin(left = Dimen.normalPadding)
                .backgroundColor(Theme.White.rgb)
                .fontWeight(FontWeight.Medium)
                .fontFamily(FontFamily.Montserrat)
                .color(Theme.Primary.rgb)
                .fontSize(FontSizes.normalFontSize)
        )

        Box(
            modifier = Modifier
                .fillMaxWidth()
                .borderRadius(Dimen.lowBorderRadius)
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayCD.rgb,
                )
                .margin(top = Dimen.normalPadding)
        ) {

            Column(
                modifier = Modifier
                    .align(Alignment.TopStart)
                    .borderRadius(Dimen.lowBorderRadius)
            ) {
                Box(
                    modifier = Modifier
                        .resize(Resize.Vertical)
                        .minHeight(
                            Dimen.mediumOutlinedEditTextHeight
                        )
                ){
                    boxContent()
                }

                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .zIndex(2f)
                        .backgroundColor(Theme.White.rgb)
                ){
                    expandableList()
                }


            }
        }
    }

}
