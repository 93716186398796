package org.hs_soft.runmynesto.domain.usecase.options.sales_group

import org.hs_soft.runmynesto.domain.model.home.options.single_sale_group.SingleSaleGroupModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.sales_group.SingleSaleGroupRepository


class SingleSaleGroupUseCase
    (private val repository: SingleSaleGroupRepository) {
    suspend operator fun invoke(
        id:String
    ): SingleSaleGroupModel {
        return repository.getSingleSaleGroup(
            id=id
        )
    }
}