

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_checkout.update_template_checkout.UpdateCategory

@Serializable
data class CashAssist(
    @SerialName("__typename")
    val typename: String?=null,
    @SerialName("updateCategory")
    val updateCategory: UpdateCategory?=null,
    @SerialName("updateProduct")
    val updateProduct: UpdateCategory?=null,

    )