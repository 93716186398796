package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.hardware

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.window
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_flow.app_config_model.PrinterLocation
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.Parameters
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.hardware.component.HardwareKitchenLocation
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh

@Composable
fun HardwareTabCashRegisterConfig(
    windowSizeMode:WindowSizeModeEnum,
    modifier: Modifier=Modifier,
    parameters: Parameters?,
    updateData: (String, dynamic) -> Unit,
    printerLocations: List<PrinterLocation?>?,
)
{
    Column(
        modifier = modifier
            .fillMaxSize()
            .padding(Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    ){
        CheckboxComponent(
            checkStatus =parameters?.printer?.isKitchenPrinterActive?:false,
            title = Strings.activeKitchenPrinter,
            onChecked = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrintJson(
                        title = ApiUtils.Field.isKitchenPrinterActive,
                        value = true
                    )
                )
            },
            onUnChecked = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrintJson(
                        title = ApiUtils.Field.isKitchenPrinterActive,
                        value = false
                    )
                )
            }
        )
        CheckboxComponent(
            checkStatus = parameters?.printer?.isPrintKitchenBillAfterCompletionOnly?:false,
            title = Strings.printReceiptAfterCompletion,
            onChecked = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrintJson(
                        title = ApiUtils.Field.isPrintKitchenBillAfterCompletionOnly,
                        value =true
                    )
                )
            },
            onUnChecked = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrintJson(
                        title = ApiUtils.Field.isPrintKitchenBillAfterCompletionOnly,
                        value =false
                    )
                )
            }
        )
        CheckboxComponent(
            checkStatus = parameters?.printer?.isPrintKitchenBillForTableOnly?:false,
            title = Strings.printKitchenOnlyForTables,
            onChecked = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrintJson(
                        title = ApiUtils.Field.isPrintKitchenBillForTableOnly,
                        value =true
                    )
                )
            },
            onUnChecked = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrintJson(
                        title = ApiUtils.Field.isPrintKitchenBillForTableOnly,
                        value =false
                    )
                )
            }
        )


        MediumContentTitle(
            title = Strings.kitchenLocations,
            marginBottom = 0.px,
            marginTop = Dimen.mediumPadding,
        )

       Column(
           modifier = VerticalScrollBehavior.toModifier()
               .weight(1f)
               .height(Constants.Responsive.CashConfigPrinterLocationHeight.vh)
               .fillMaxWidth()
       ) {
           printerLocations?.sortedBy { it?.dbIndex }?.forEach { printerLocation->
               HardwareKitchenLocation(
                   windowSizeMode = windowSizeMode,
                   location = parameters?.printer?.locations?.find {
                       it?.position==printerLocation?.dbIndex
                   },
                   printerLocation =printerLocation,
                   ipDeviceLabel =parameters?.printer
                    ?.locations?.get(printerLocation?.dbIndex?:0)?.ipDeviceLabel,
                   ip =parameters?.printer?.locations?.get(printerLocation?.dbIndex?:0)?.ip,
                   position=printerLocation?.dbIndex?:0,
                   updateData = {title:String,value:dynamic->
                       updateData(
                           title,value
                       )
                   }

               )
           }
       }

        Spacer()


        Row(
            modifier = Modifier.margin(top = Dimen.normalPadding),
            verticalAlignment = Alignment.CenterVertically
        )
        {

            CustomEditText(
                modifier = Modifier,
                label = Strings.topEdgeCm,
                value = (parameters?.printer?.topMargin?:0).toString(),
                marginTop = Dimen.normalPadding,
                width = Dimen.normalEditTextWidth,
                isOutlinedEditText = true,
                isRequired = false,
                inputType = InputType.Number,
                defaultBorderColor = Theme.GrayCD.rgb,
                hint = "",
                onFocusIn = {},
                onFocusOut = {

                    updateData(
                        ApiUtils.Field.printer,
                        getPrintJson(
                            title = ApiUtils.Field.topMargin,
                            value =it.toInt()
                        )
                    )
                },
                onInputChange = {

                },
            )
            CheckboxComponent(
                modifier = Modifier
                    .margin(left = Dimen.mediumPadding),

                fontSize = if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                    FontSizes.mediumFontSize
                else FontSizes.semiMediumFontSize,
                title = Strings.separateCakeToppings,
                checkStatus = parameters?.printer?.separateKitchenBill?:false,
                onUnChecked = {
                     updateData(
                        ApiUtils.Field.printer,
                        getPrintJson(
                            title = ApiUtils.Field.separateKitchenBill,
                            value =false
                        )
                    )
                },
                onChecked = {
                    updateData(
                        ApiUtils.Field.printer,
                        getPrintJson(
                            title = ApiUtils.Field.separateKitchenBill,
                            value =true
                        )
                    )
                }
            )
        }





    }
}

fun getPrintJson(
    title:String,
    value:dynamic,
): JsonObject {
    val printerJson = buildJsonObject {
        when(value){
            is Boolean -> put(title, value as Boolean)
            is String -> put(title, value as String)
            is Int -> put(title, value as Int)
        }
    }
    return printerJson
}