package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.sales_group.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onTouchEnd
import com.varabyte.kobweb.compose.ui.modifiers.onTouchMove
import com.varabyte.kobweb.compose.ui.modifiers.onTouchStart
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.editTextBoxBackground
import org.hs_soft.runmynesto.domain.util.editTextLabelBackground
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsState
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.LineStyle
import org.w3c.dom.get

@Composable
fun OptionSaleGroupTabContent(
    windowSizeMode: WindowSizeModeEnum,
    state: OptionsState,
    event: (OptionsEvent) -> Unit,
    onSwipeLeft:()->Unit={},
)
{
    var startX by remember { mutableStateOf(0.0) }
    var endX by remember { mutableStateOf(0.0) }
    var tracking by remember { mutableStateOf(false) }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .margin(leftRight = Dimen.mediumPadding,)
            .padding(Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
            .onTouchStart {
                startX = it.touches[0]!!.clientX.toDouble()
                tracking = true
            }
            .onTouchMove {
                if (tracking) {
                    endX = it.touches[0]!!.clientX.toDouble()
                }
            }
            .onTouchEnd {
                if (tracking && startX + Constants.Responsive.SwipeWidth < endX) {
                    onSwipeLeft()
                }
                tracking = false
            }
    )
    {
        if (windowSizeMode== WindowSizeModeEnum.WINDOWS){
            Row()
            {
                generalContent(
                    state=state,
                    event=event
                )
            }
        }else{
            Column()
            {
                generalContent(
                    state=state,
                    event=event
                )
            }
        }


        SpanText(
            text = Strings.accountingPercent,
            modifier = Modifier
                .margin(
                    top = Dimen.mediumPadding,
                    bottom = Dimen.normalPadding
                )
                .fontWeight(FontWeight.Medium)
                .fontSize(FontSizes.mediumFontSize)
                .color(Theme.Primary.rgb)
                .fontFamily(FontFamily.Montserrat)
        )

        if (windowSizeMode== WindowSizeModeEnum.WINDOWS){
            Row() {
                accountingContent(
                    state=state,
                    event=event
                )
            }
        }else{
            Column()
            {
                accountingContent(
                    state=state,
                    event=event
                )
            }
        }




    }

}

@Composable
private fun accountingContent(
    state: OptionsState,
    event: (OptionsEvent) -> Unit
) {
    CustomEditText(
        modifier = Modifier,
        width = Dimen.normalEditTextWidth,
        label = Strings.financialReduced,
        isRequired = false,
        value = state.selectedSaleGroup?.accountingReduced ?: "",
        defaultBorderColor = Theme.GrayCD.rgb,
        hint = "",
        isOutlinedEditText = true,
        onFocusIn = {},
        onFocusOut = {
            event(
                OptionsEvent.UpdateSaleGroup(
                    dataTitle = ApiUtils.Field.accountingReduced,
                    dataValue = it
                )
            )
        },
        onInputChange = {

        },
    )
    Box(modifier = Modifier.padding(Dimen.smallPadding))

    CustomEditText(
        modifier = Modifier,
        width = Dimen.normalEditTextWidth,
        isRequired = false,
        label = Strings.fibuNormal,
        value = state.selectedSaleGroup?.accountingNormal ?: "",
        defaultBorderColor = Theme.GrayCD.rgb,
        hint = "",
        isOutlinedEditText = true,
        onFocusIn = {},
        onFocusOut = {
            event(
                OptionsEvent.UpdateSaleGroup(
                    dataTitle = ApiUtils.Field.accountingNormal,
                    dataValue = it
                )
            )
        },
        onInputChange = {

        },
    )
    Box(modifier = Modifier.padding(Dimen.smallPadding))

    CustomEditText(
        modifier = Modifier,
        inputType = InputType.Number,
        isRequired = false,
        width = Dimen.normalEditTextWidth,
        label = Strings.accounting,
        value = state.selectedSaleGroup?.accountingZeroPercent ?: "",
        defaultBorderColor = Theme.GrayCD.rgb,
        hint = "",
        isOutlinedEditText = true,
        onFocusIn = {},
        onFocusOut = {
            event(
                OptionsEvent.UpdateSaleGroup(
                    dataTitle = ApiUtils.Field.accountingZeroPercent,
                    dataValue = it
                )
            )
        },
        onInputChange = {

        },
    )
}

@Composable
private fun generalContent(
    state: OptionsState,
    event: (OptionsEvent) -> Unit
) {
    CustomEditText(
        modifier = Modifier,
        label = Strings.number,
        value = state.selectedSaleGroup?.acountNum ?: "",
        defaultBorderColor = Theme.GrayCD.rgb,
        isRequired = false,
        hint = "",
        isOutlinedEditText = true,
        onFocusIn = {},
        onFocusOut = {
            event(
                OptionsEvent.UpdateSaleGroup(
                    dataTitle = ApiUtils.Field.acountNum,
                    dataValue = it
                )
            )
        },
        onInputChange = {

        },
    )
    Box(modifier = Modifier.padding(Dimen.smallPadding))

    CustomEditText(
        modifier = Modifier,
        label = Strings.receiptName,
        value = state.selectedSaleGroup?.name ?: "",
        defaultBorderColor = Theme.GrayCD.rgb,
        isRequired = false,
        hint = "",
        isOutlinedEditText = true,
        onFocusIn = {},
        onFocusOut = {
            event(
                OptionsEvent.UpdateSaleGroup(
                    dataTitle = ApiUtils.Field.name,
                    dataValue = it
                )
            )
        },
        onInputChange = {

        },
    )
}