package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.grid_customer_card_bar.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.hs_soft.runmynesto.pages.home.sub_page.user.component.ItemSeparator
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun GridCustomerCardHeader(
    numberDescending:Boolean?,
    onNumberSortClick:()->Unit,
)
{
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .borderBottom(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
            .backgroundColor(Theme.GrayAlpha04.rgb)
            .padding(
                leftRight = Dimen.normalPadding,
                topBottom = Dimen.smallPadding,
            ),
        verticalAlignment = Alignment.CenterVertically
    )
    {
        HeaderItem(
            title =Strings.numberDot,
            numberDescending =numberDescending,
            width =Dimen.headerExtraSmallWidth,
            onHeaderItemClick = {

            }
        )


        HeaderItem(
            title =Strings.cardName,
            numberDescending =numberDescending,
            width = Dimen.headerMediumWidth,
            onHeaderItemClick = {

            }
        )

        HeaderItem(
            title =Strings.balance,
            numberDescending =numberDescending,
            width = Dimen.gridCustomerCardNumberWidth,
            onHeaderItemClick = {

            }
        )

        HeaderItem(
            title =Strings.points,
            showSeparator = false,
            numberDescending =numberDescending,
            width = Dimen.gridCustomerCardSmallHeaderWdith,
            onHeaderItemClick = {

            }
        )


    }



}

@Composable
private fun HeaderItem(
    title: String,
    numberDescending: Boolean?,
    showSeparator:Boolean=true,
    width: CSSSizeValue<CSSUnit.px>,
    onHeaderItemClick: () -> Unit,
) {
    Row(
        modifier = Modifier
            .onClick {
                onHeaderItemClick()
            },
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically
    )
    {
        SpanText(
            text = title,
            modifier = Modifier
                .cursor(Cursor.Pointer)
                .width(width)
                .fontSize(FontSizes.semiMediumFontSize)
                .lineHeight(FontLineHeight.lineMedium)
                .letterSpacing(LetterSpacing.semiMedium)
                .fontFamily(FontFamily.Montserrat)
                .color(Theme.Primary.rgb)
        )

        VectorIcon(
            modifier = Modifier.padding(Dimen.smallPadding),
            pathData = if (numberDescending == true)
                Res.PathIcon.sortDesc
            else Res.PathIcon.sortAsc,
            height = Dimen.mediumIconSize,
            width = Dimen.mediumIconSize,
            color = Theme.GrayAlpha56.hex,
            visibilityStatus = numberDescending != null
        )

    }

    if (showSeparator){
        ItemSeparator(color = Theme.GrayCD.rgb)
    }

}