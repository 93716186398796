package org.hs_soft.runmynesto.data.api_datasource.customer_card.tabs.series

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.series.create_customer_card.CreateCustomerCardModel
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.series.update_range_customer_card.UpdateRangeLoyaltyCardModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.series.UpdateRangeLoyaltyCardRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateRangeLoyaltyCardRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateRangeLoyaltyCardRepository
{
    override suspend fun updateRangeLoyaltyCard(
        balance: Int?,
        from: Int,
        to: Int,
        name: String?,
        description: String?
    ): UpdateRangeLoyaltyCardModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation updateRangeLoyaltyCards(
                    ${'$'}ids: RangeIds!, 
                    ${'$'}fields: LoyaltyCardFields, 
                    ${'$'}transactionFields: RangeLoyaltyCardFields
                ) {
                  cashAssist {
                    updateRangeLoyaltyCards(
                      ids: ${'$'}ids
                      fields: ${'$'}fields
                      transactionFields: ${'$'}transactionFields
                    )
                    ${ApiUtils.Field.__typename}
                  }
                }


               
            """.trimIndent())

            putJsonObject("variables") {
                putJsonObject("fields"){
                   if (balance!=null) {
                       put("balance",balance)
                   }
                   if (name!=null) {
                       put("name",name)
                   }

                }
                putJsonObject("ids"){
                    put("from",from)
                    put("to",to)
                }

                if (description!=null){
                    putJsonObject("transactionFields"){
                        put("description",description)
                    }
                }

            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }
    }

}