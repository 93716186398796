package org.hs_soft.runmynesto.domain.usecase.statistic.search_bill

import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.billPdfModel.BillPdfModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.search_bill.BillPdfRepository


class BillPdfUseCase(
    private val repository: BillPdfRepository
) {
    suspend operator fun invoke(
        billDate:String,
        billNumber:Int,
        cashRegisterId:String,
    ): BillPdfModel {

        return repository.getBillPdf(
            billDate =billDate,
            billNumber =billNumber,
            cashRegisterId =cashRegisterId,
        )
    }


}