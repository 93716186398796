package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.calendar_filter_dialog

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.getYearsData
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.calendar_filter_dialog.component.ClosingReportDatePicker
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.calendar_filter_dialog.component.Pressets
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun ClosingReportsCalendarDialog(
    modifier: Modifier=Modifier,
    selectedCalendarHeaderYear:Int,
    selectedDate:MonthModel?,
    selectedFrom:String,
    selectedTo:String,
    onSelectDate:(MonthModel)->Unit,
    filterDate:(String,String)->Unit,
    closeCalendarDialog:()->Unit,
    setNewCalendarHeaderYear:(Int)->Unit,
)
{
    val showFirstCalendarType= remember { mutableStateOf(true) }

    Column(
        modifier = modifier
            .margin(top = Dimen.normalPadding)
            .zIndex(Constants.ZIndex.mediumZIndex)
            .backgroundColor(Theme.White.rgb)
            .boxShadow(color = Theme.GrayAlpha12.rgb)
            .padding(Dimen.normalPadding)
            .border(
                width= Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
            .borderRadius(Dimen.lowBorderRadius),
        horizontalAlignment = Alignment.CenterHorizontally

    ) {
        ClosingReportDatePicker(
            modifier = Modifier,
            showFirstCalendarType=showFirstCalendarType.value,
            selectedDay = selectedDate,
            selectedTo = selectedTo,
            selectedFrom =selectedFrom ,
            selectedCalendarHeaderYear = selectedCalendarHeaderYear,
            date = getYearsData(
                startYear = Constants.startYear,
                endYear = Constants.endYear
            ),
            onSelectDay = {
                onSelectDate(it)
            },
            filterDate = { from:String,to:String->
                filterDate(from,to)
            },
            closeCalendarDialog = {
                closeCalendarDialog()
            },
            setNewCalendarHeaderYear = {
                setNewCalendarHeaderYear(it)
            }
        )

        Pressets(
            onClick={
                showFirstCalendarType.value = !showFirstCalendarType.value
            }
        )

    }





}










