package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_restaurant

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.addJsonObject
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonArray
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.update_restaurant_data.UpdateRestaurantModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.UpdateProductRestaurantRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateProductRestaurantRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateProductRestaurantRepository {

    override suspend fun updateRestaurant(
        productId: String,
        dataTitle: String,
        dataValue: dynamic
    ): UpdateRestaurantModel {

        val abortController = requestManager.createController()


        val graphQLQuery = buildJsonObject {
            put("query", """
            mutation updateProduct_restaurant(${'$'}id: ID!, ${'$'}fields: ProductFields!) {
              cashAssist {
                updateProduct(id: ${'$'}id, fields: ${'$'}fields) {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.mod_ver}
                  ...restaurant
                  ${ApiUtils.Field.__typename}
                }
                ${ApiUtils.Field.__typename}
              }
            }
        
            fragment restaurant on Product {
              ${ApiUtils.Field.isPrintOnlyInCafe}
              ${ApiUtils.Field.isAdditionRequired}
              ${ApiUtils.Field.isServiceCall}
              ${ApiUtils.Field.printerLocations}
              additions {
                ${ApiUtils.Field.id}
                ${ApiUtils.Field.mod_ver}
                ${ApiUtils.Field.productAddionId}
                ${ApiUtils.Field.__typename}
              }
              ${ApiUtils.Field.__typename}
            }
        """.trimIndent())



            putJsonObject("variables") {
                put("id", productId)
                putJsonObject("fields") {
                    when(dataTitle){
                        ApiUtils.Field.additions -> {
                            putJsonArray(dataTitle) {
                                (dataValue as? List<Map<String, String>>)?.forEach { addition ->
                                    addJsonObject {
                                        addition.forEach { (key, value) ->
                                            put(key, value)
                                        }
                                    }
                                }
                            }
                        }else->{
                            when(dataValue){
                                is Boolean ->put(dataTitle, dataValue as Boolean)
                                is String ->put(dataTitle,dataValue as String)
                                is Int ->put(dataTitle,dataValue as Int)
                                is Double ->put(dataTitle,dataValue as Double)
                                is List<Boolean?>? ->  put(dataTitle,Json
                                    .encodeToJsonElement(dataValue as List<Boolean?>?))
                            }
                        }
                    }

                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<UpdateRestaurantModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }

}
