package org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LoyaltyCardDetail(
    @SerialName("accessCode")
    val accessCode: String?,
    @SerialName("additive")
    val additive: String?,
    @SerialName("alwaysDiscount")
    val alwaysDiscount: Boolean?,
    @SerialName("balance")
    val balance: Double?,
    @SerialName("balanceDiscount")
    val balanceDiscount: Boolean?,
    @SerialName("balancePoints")
    val balancePoints: Boolean?,
    @SerialName("barCode")
    val barCode: String?,
    @SerialName("cashLoad")
    val cashLoad: Boolean?,
    @SerialName("city")
    val city: String?,
    @SerialName("clientName")
    val clientName: String?,
    @SerialName("clientSurname")
    val clientSurname: String?,
    @SerialName("creditClient")
    val creditClient: CreditClient?,
    @SerialName("creditClientId")
    val creditClientId: String?,
    @SerialName("dayLimit")
    val dayLimit: Int?,
    @SerialName("discount")
    val discount: Discount?,
    @SerialName("email")
    val email: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("isActive")
    val isActive: Boolean?,
    @SerialName("isPointsActive")
    val isPointsActive: Boolean?,
    @SerialName("isUser")
    val isUser: Boolean?,
    @SerialName("lastUpdatedAt")
    val lastUpdatedAt: String?,
    @SerialName("limit")
    val limit: Int?,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("name")
    val name: String?,
    @SerialName("points")
    val points: Int?,
    @SerialName("postcode")
    val postcode: String?,
    @SerialName("rfid")
    val rfid: String?,
    @SerialName("salutation")
    val salutation: String?,
    @SerialName("statusVirtual")
    val statusVirtual: StatusVirtual?,
    @SerialName("street")
    val street: String?,
    @SerialName("type")
    val type: Int?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("user")
    val user: User?,
    @SerialName("userId")
    val userId: String?,
    @SerialName("validPhoneNumber")
    val validPhoneNumber: String?,
    @SerialName("virtualCardLink")
    val virtualCardLink: String?
)