package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi

import org.hs_soft.runmynesto.domain.model.home.customer_card.CustomerCardHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodMonthEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card.LoyaltyCardItem
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail.LoyaltyCardDetail
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.TransactionColumnsEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.credit_client.CreditClientModel
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.filtered_transaction.FilteredLoyaltyCardItem
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel


data class CustomerCardState(
    val firstPageActive:Boolean=true,
    val selectedCustomerCardTab:CustomerCardHeaderEnum =
        CustomerCardHeaderEnum.CUSTOMER_CARD,
    val loyaltyCards:List<LoyaltyCardItem?>? =null,
    val dataLoadingProgressVisible:Boolean=false,
    val showAddNewCustomerCardDialog:Boolean=false,
    val loyaltyCardSearchValue:String="",
    val selectedLoyaltyCard:LoyaltyCardDetail ?=null,
    val selectedDate: MonthModel = MonthModel(),
    val selectedDaysPeriod: PeriodDaysEnum?=PeriodDaysEnum.TODAY,
    val selectedMonthPeriod: PeriodMonthEnum?=null,
    val creditClient:CreditClientModel?=null,
    val filteredLoyaltyCardItems:List<FilteredLoyaltyCardItem?> ?=null,
    val showTransactionColumnsDialog:Boolean=false,
    val selectedTransactionColumns: Set<TransactionColumnsEnum> =
        TransactionColumnsEnum.entries.filter { it.value }.toSet(),
    val showGridCustomerFilter:Boolean=false,
    val gridCustomerCardFilterType: GridItemsFilterEnum=GridItemsFilterEnum.ALL_ITEMS

)
