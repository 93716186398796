package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.ingeredient_list


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Ingredients(
    @SerialName("code")
    val code: String?,
    @SerialName("group")
    val group: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("name")
    val name: String?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("units")
    val units: String?,
    @SerialName("uuid")
    val uuid: String?
)