package org.hs_soft.runmynesto.domain.model.home.template

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.TitleEnum



enum class TemplateInfoHeaderEnum(override val title: String) : TitleEnum {
    GENERAL(Strings.general ),
    CHECKOUT(Strings.devices ),
    SURCHARGE_IN_HOUSE(Strings.surchargeInHouse ),
    OPTIONS(Strings.options )
}