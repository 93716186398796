package org.hs_soft.runmynesto.domain.model.home.options.operation.option_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class OptionModel(
    @SerialName("name")
    val name: String ?,
    @SerialName("street")
    val street: String ?,
    @SerialName("postCode")
    val postCode: String?,
    @SerialName("city")
    val city: String?,
    @SerialName("countryId")
    val countryId: String ?,
    @SerialName("language")
    val language: String?,
    @SerialName("mainCurrency")
    val mainCurrency: String ?,
    @SerialName("isShopFieldActive")
    val isShopFieldActive: Boolean ?,
)
