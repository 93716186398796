package org.hs_soft.runmynesto.pages.home.sub_page.user.mvi

import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.model.home.user.UserInfoHeaderEnum


sealed class UserEvent {

    //Getting Data
    data class UpdateUserInfo(
        val dataTitle:String,
        val dataValue:dynamic
    ):UserEvent()
    data class UpdateUserPermissionInfo(
        val dataTitle: String,
        val dataValue: dynamic
    ):UserEvent()

    data class UpdateUserWaiterKey(
        val dataValue:String
    ):UserEvent()

    data class UpdateCheckout(
        val dataValue:MutableSet<String>
    ):UserEvent()



    data object GetCheckoutData:UserEvent()

    data object GetUsersData:UserEvent()
    data class OnUserClick(
        val id:String,
        val callBack:()->Unit,
    ):UserEvent()

    data class OnSelectImageClick(
        val url:String
    ):UserEvent()



    //Searching Event
    data class OnSearchUserInputChange(val value:String): UserEvent()
    data object OnNumberSortClick: UserEvent()
    data object OnTitleSortClick: UserEvent()
    data object RefreshUserList:UserEvent()

    data class OnSearchCheckoutItems(val value:String):UserEvent()


    //Cancellation Event
    data class OnCancellationItemClick(val cancelReason:dynamic): UserEvent()



    //Header click event
    data class OnTabClick(val tab: UserInfoHeaderEnum): UserEvent()



    //Other
    data object OnUserInfoDetailPassEyeClick: UserEvent()

    data object OnChangePageClick: UserEvent()

    data object OnAddRecordIconClick:UserEvent()
    data object OnAddRecordDialogBackgroundClick:UserEvent()
    data object OnCancelRecordDialogClick:UserEvent()
    data class OnSubmitRecordDialogClick(val windowMode: WindowSizeModeEnum):UserEvent()
    data class OnChangeRecordNumber(val value:String):UserEvent()
    data class OnChangeRecordName(val value:String):UserEvent()


    //Filter
    data class SetFilterItemsComponentStatus(val status:Boolean):UserEvent()

    data object SubmitFilterItems:UserEvent()

    data class SelectFilterType(val type: GridItemsFilterEnum):UserEvent()


}