package org.hs_soft.runmynesto.pages.home.sub_page.user

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.components.dialog.dialog.AddRecordDialog
import org.hs_soft.runmynesto.pages.components.top_toolbar.component.CarouselButton
import org.hs_soft.runmynesto.pages.home.sub_page.user.component.GridUserBar
import org.hs_soft.runmynesto.pages.home.sub_page.user.component.UserContent
import org.hs_soft.runmynesto.pages.home.sub_page.user.mvi.UserEvent
import org.hs_soft.runmynesto.pages.home.sub_page.user.mvi.UserState
import org.hs_soft.runmynesto.pages.home.sub_page.user.mvi.UserViewModel

@Composable
fun User(
    windowSizeMode: WindowSizeModeEnum,
    viewModel: UserViewModel,
) {
    val state by viewModel.state.collectAsState()



    LaunchedEffect(viewModel) {
        viewModel.onEvent(UserEvent.GetUsersData)
    }

    Column(
        modifier = Modifier
            .padding(
                topBottom = Dimen.normalPadding,
                leftRight = Dimen.normalPadding,
            )
            .fillMaxSize()
            .backgroundColor(Theme.White.rgb)
    ) {
        Row(
            modifier = Modifier.fillMaxSize()
                .margin(top = Dimen.smallPadding)
        )
        {
            if (state.firstPageActive) {
                GridUserBar(
                    windowSizeMode=windowSizeMode,
                    selectedItem = state.selectedUser,
                    searchValue = state.searchValue,
                    infoList = state.usersInfo,
                    titleDescending = state.isNameSortedAsc,
                    numberDescending = state.isNumberSortedAsc,
                    showNumberSortIcon = state.showNumberSortIcon,
                    showTitleSortIcon = state.showTitleSortIcon,
                    showFilterComponent = state.showItemsFilterComponent,
                    selectedFilterOption = state.selectedFilterItemsType,
                    onSearchValueChange = {
                        viewModel.onEvent(UserEvent.OnSearchUserInputChange(it))
                    },
                    onAddRecord = {
                        viewModel.onEvent(UserEvent.OnAddRecordIconClick)
                    },
                    onTitleSortClick = {
                        viewModel.onEvent(UserEvent.OnTitleSortClick)
                    },
                    onNumberSortClick = {
                        viewModel.onEvent(UserEvent.OnNumberSortClick)
                    },
                    refreshList = {
                        viewModel.onEvent(UserEvent.RefreshUserList)
                    },
                    onUserClick = { id ->
                        viewModel.onEvent(UserEvent.OnUserClick(
                            id = id,
                            callBack = {
                                if (windowSizeMode == WindowSizeModeEnum.PHONE)
                                    viewModel.onEvent(UserEvent.OnChangePageClick)
                            }
                        ))
                    },
                    onSwipeRight = {
                        viewModel.onEvent(UserEvent.OnChangePageClick)
                    },
                    onFilterComponentClick = {
                        viewModel.onEvent(UserEvent.SetFilterItemsComponentStatus(
                            !state.showItemsFilterComponent)
                        )
                    },
                    onCloseFilterClick = {
                        viewModel.onEvent(UserEvent.SetFilterItemsComponentStatus(false))
                    },
                    selectFilterType = {
                        viewModel.onEvent(UserEvent.SelectFilterType(it))
                    },
                    submitFilterItems = {
                        viewModel.onEvent(UserEvent.SubmitFilterItems)
                    }
                )

                if (windowSizeMode != WindowSizeModeEnum.PHONE) {
                    content(
                        windowSizeMode=windowSizeMode,
                        state=state,
                        event=viewModel::onEvent
                    )

                }

            } else {

                content(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=viewModel::onEvent,
                    onSwipeLeft = {
                        viewModel.onEvent(UserEvent.OnChangePageClick)
                    }

                )


            }

        }


    }

    LoadingProgress(
        visibility = state.dataLoadingProgressVisible
    )

    if (state.showAddRecordDialog) {
        AddRecordDialog(
            windowSizeMode = windowSizeMode,
            dialogTitle = Strings.addNewUser,
            nameFieldTitle = Strings.name,
            numberFieldTitle = Strings.number,
            nameValue = state.newRecordName,
            showNumberField=true,
            numberValue = state.newRecordNumber,
            errorMessage = state.addRecordErrorMessage,
            isSubmitClickable = state.isAddNewRecordSubmitClickable,
            loading = state.addRecordLoading,
            onNumberValueChange = {
                viewModel.onEvent(UserEvent.OnChangeRecordNumber(it))
            },
            onNameValueChange = {
                viewModel.onEvent(UserEvent.OnChangeRecordName(it))
            },
            onCancelClick = {
                viewModel.onEvent(UserEvent.OnCancelRecordDialogClick)
            },
            onSubmitClick = {
                viewModel.onEvent(UserEvent.OnSubmitRecordDialogClick(windowSizeMode))
            },
            onBackgroundClick = {
                viewModel.onEvent(UserEvent.OnAddRecordDialogBackgroundClick)
            }
        )
    }



}

@Composable
private fun content(
    windowSizeMode: WindowSizeModeEnum,
    state: UserState,
    event: (UserEvent) -> Unit,
    onSwipeLeft:()->Unit={}
) {
    UserContent(
        windowSizeMode = windowSizeMode,
        isCheckoutExpandAll=state.checkOutExpandAll,
        checkoutSearchValue=state.checkOutSearchValue,
        userInfoDetailPasswordVisibility = state.userInfoDetailPasswordVisibility,
        imgUrl = state.imgUrl,
        checkOutData=state.cashList,
        selectedTab = state.selectedTab,
        selectedCancellationReason = state.cancellationReason,
        loading = state.dataLoadingProgressVisible,
        selectedUser = state.selectedUser ,
        updateNameValue = {
            event(
                UserEvent.UpdateUserInfo(
                    dataTitle = ApiUtils.Field.name,
                    dataValue = it
                )
            )
        },
        onSelectImage = {
            event(UserEvent.OnSelectImageClick(it))
        },
        onUserInfoDetailPassEyeClick = {
            event(UserEvent.OnUserInfoDetailPassEyeClick)
        },
        getCheckoutData={
            event(UserEvent.GetCheckoutData)
        },
        updatePrintNameValue = {
            event(
                UserEvent.UpdateUserInfo(
                    dataTitle = ApiUtils.Field.printName,
                    dataValue = it
                )
            )
        },
        updateShortNameValue = {
            event(
                UserEvent.UpdateUserInfo(
                    dataTitle = ApiUtils.Field.shortName,
                    dataValue = it
                )
            )
        },
        updatePasswordValue = {
            event(
                UserEvent.UpdateUserInfo(
                    dataTitle = ApiUtils.Field.password,
                    dataValue = it
                )
            )
        },
        updateActivity = {
            event(
                UserEvent.UpdateUserInfo(
                    dataTitle = ApiUtils.Field.isActive,
                    dataValue = it
                )
            )
        },
        onSwipeLeft = {
            onSwipeLeft()
        },
        onTabClick = {
            event(UserEvent.OnTabClick(it))
        },
        onCancellationReasonClick = {
            event(UserEvent.OnCancellationItemClick(it))
        },

        updateDayEndCheck = {
            event(
                UserEvent.UpdateUserPermissionInfo(
                    dataTitle = ApiUtils.Field.isDayFinStatements,
                    dataValue = it
                )
            )
        },
        updateTimeTracking = {
            event(
                UserEvent.UpdateUserPermissionInfo(
                    dataTitle = ApiUtils.Field.isTimeTracking,
                    dataValue = it
                )
            )
        },
        updateIsViewSale = {
            event(
                UserEvent.UpdateUserPermissionInfo(
                    dataTitle = ApiUtils.Field.isViewSales,
                    dataValue = it
                )
            )
        },
        updateIsManager = {
            event(
                UserEvent.UpdateUserPermissionInfo(
                    dataTitle = ApiUtils.Field.isManager,
                    dataValue = it
                )
            )
        },
        updateIsAdmin = {
            event(
                UserEvent.UpdateUserPermissionInfo(
                    dataTitle = ApiUtils.Field.isAdmin,
                    dataValue = it
                )
            )
        },
        updateInterimFinancialStatement = {
            event(
                UserEvent.UpdateUserPermissionInfo(
                    dataTitle = ApiUtils.Field.isFinStatements,
                    dataValue = it
                )
            )
        },

        updateWaiterCastle = {
            event(
                UserEvent.UpdateUserWaiterKey(
                    dataValue = it
                )
            )
        },
        updateUserCheckout = {
            event(UserEvent.UpdateCheckout(it))
        },
        onCheckoutSearch = {
            event(UserEvent.OnSearchCheckoutItems(it))
        }

    )
}





