package org.hs_soft.runmynesto.domain.model.home.options.cash_flow.app_config_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetAppConfig(
    @SerialName("cashRegisterGlobal")
    val cashRegisterGlobal: CashRegisterGlobal?,
    @SerialName("creditCards")
    val creditCards: List<String?>?,
    @SerialName("specialProductExpenses")
    val specialProductExpenses: List<String?>?,
    @SerialName("__typename")
    val typename: String?
)