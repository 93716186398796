package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components.dialogs

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CircleIcon
import org.hs_soft.runmynesto.pages.components.dialog.component.SubmitButtons

@Composable
fun DeleteDialog(
    title:String,
    description:String,
    windowSizeMode: WindowSizeModeEnum,
    onDismissDialog: () -> Unit,
    onSubmitClick:()->Unit,
)
{

    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                onDismissDialog()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(Constants.ZIndex.normalZIndex)
    )
    {

        Column(
            modifier = Modifier
                .align(Alignment.Center)
                .boxShadow(
                    blurRadius = Dimen.smallBlurRadius,
                    color = Theme.Gray3E.rgb,
                )
                .generalDialogSetting()
        )
        {
            Row(
                modifier = Modifier
                    .padding(
                        leftRight = Dimen.normalPadding,
                        topBottom = Dimen.smallPadding,
                    )
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            )
            {
                SpanText(
                    text =title ,
                    modifier = Modifier
                        .fontSize(FontSizes.largeFontSize)
                        .fontWeight(FontWeight.Medium)
                        .color(Theme.Secondary.rgb)
                        .fontFamily(FontFamily.Jakarta)
                        .lineHeight(FontLineHeight.lineLarge)
                        .letterSpacing(LetterSpacing.small)
                )

                Spacer()

                CircleIcon(
                    borderColor = Theme.Transparent.rgb,
                    img = Res.PathIcon.close,
                    onIconClick = {
                        onDismissDialog()
                    }
                )


            }


            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(Dimen.smallThickness)
                    .backgroundColor(Theme.GrayAlpha12.rgb)
            )

            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(
                        topBottom = Dimen.mediumPadding,
                    ),
                horizontalAlignment = Alignment.CenterHorizontally,
            )
            {
                SpanText(
                    text = description,
                    modifier = Modifier
                        .fontSize(FontSizes.largeFontSize)
                        .fontWeight(FontWeight.Medium)
                        .color(Theme.Secondary.rgb)
                        .fontFamily(FontFamily.Jakarta)
                        .lineHeight(FontLineHeight.lineLarge)
                        .letterSpacing(LetterSpacing.small)
                )


                SubmitButtons(
                    windowSize=windowSizeMode,
                    isSubmitClickable =true,
                    arrangement= Arrangement.Center,
                    onCancelClick = {
                        onDismissDialog()
                    },
                    onSubmitClick = {
                        onSubmitClick()
                    },
                )


            }



        }

    }


}