package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.update_ingredient


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.checked_ingredients_list.CheckedIngredient

@Serializable
data class UpdateProduct(
    @SerialName("ingredients")
    val ingredients: List<CheckedIngredient?>?
)