package org.hs_soft.runmynesto.pages.home.sub_page.template.mvi

import kotlinx.coroutines.Job
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.model.home.template.TemplateInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.template.product_categories.TemplateCategoryModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.YearModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.product_based_on_category.Item
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.GetCategory
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.ArticleModel
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.getYearsData

data class TemplateState(
    val selectedTab: TemplateInfoHeaderEnum =
        TemplateInfoHeaderEnum.GENERAL,
    val dataLoadingProgressVisible:Boolean=false,
    val addRecordLoading:Boolean=false,
    val templateItemImg:String ?= null,
    val searchedProductValue:String="",

    val searchJob:Job ?= null,
    val searchedProducts:List<ProductItem> =  listOf(),

    val isFoodPositionSortedAsc:Boolean=false,

    val isArticleTitleSortedAsc:Boolean=true,
    val showArticleTitleSortIcon:Boolean=false,

    val productCategoryList:List<TemplateCategoryModel> = emptyList(),
    val selectedCategory: GetCategory?=null,
    val articleList:List<ArticleModel> = listOf(),
    val firstPageActive:Boolean=true,

    val showAddRecordDialog:Boolean=false,
    val newRecordName:String="",
    val isAddNewRecordSubmitClickable:Boolean=false,
    val addRecordErrorMessage:String="",


    val checkOutExpandAll:Boolean=false,
    val cashList: List<ChildrenX> ?= null,
    val checkOutSearchValue:String="",
    val priceLevels:List<String> ?=null,
    val selectedPriceLevelIndex:Int?=null,

    val calendarData: YearModel = getYearsData(
        startYear = Constants.startYear,
        endYear = Constants.endYear
    ),
    val showCalendar:Boolean=false,
    val selectedDate: MonthModel = MonthModel(),
    val selectedPalletColor:String ?= Theme.Transparent.hex,
    val showColorPalletDialog:Boolean=false,

    val selectedDays:Int=0,
    val showGenerateAIImgDialog:Boolean=false,
    val imageAiGeneratorDescription: String="",
    val imageAiGeneratorUrl:String = Res.PathIcon.defaultRectPhoto,
    val isImageAiGeneratorLoading:Boolean=false,

    val catBasedProductList:List<Item?> ?=null,
    val showArticleListDialog:Boolean=false,
    val addNewProductToArticleErrMsg:String="",


    val showItemsFilterComponent:Boolean=false,
    val selectedFilterItemsType: GridItemsFilterEnum = GridItemsFilterEnum.ALL_ITEMS,


    )
