package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general

import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.ProductItemModel


import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.SingleProductRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class GetSingleProductRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): SingleProductRepository {

    override suspend fun getSingleProduct(id:String): ProductItemModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
        query getProduct {
          cashAssist {
            getProduct(id: $id) {
              ${ApiUtils.Field.id}
              ${ApiUtils.Field.isActive}
              ${ApiUtils.Field.name}
              ${ApiUtils.Field.cashRegisterId}
              ${ApiUtils.Field.code}
              ${ApiUtils.Field.salesGroupId}
              ${ApiUtils.Field.isNetto}
              ${ApiUtils.Field.isNettoForUser}
              ${ApiUtils.Field.isEmployeeArticle}
              ${ApiUtils.Field.price}
              ${ApiUtils.Field.priceInCafe}
              ${ApiUtils.Field.codeMWSt}
              ${ApiUtils.Field.codeMWSt2}
              
              ${ApiUtils.Field.properties}{
                   ${ApiUtils.Field.clickCollectLeadDaysCount}
                   ${ApiUtils.Field.clickCollectOrderMax}
                   ${ApiUtils.Field.clickCollectOrderMin}
              }
              
              ${ApiUtils.Field.img} {
                ${ApiUtils.Field.uid}
                ${ApiUtils.Field.key} 
              }
              
                
            }
          }
        }
    """.trimIndent())
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<ProductItemModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }

}
