package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components
    .tabs.customer_card_tab.component.periods.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.color
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.RadioGroup
import org.jetbrains.compose.web.dom.RadioInput

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun PeriodDays(
    selectedPeriodType: PeriodDaysEnum?,
    onSelectPeriodType: (PeriodDaysEnum) -> Unit,
    windowSizeMode: WindowSizeModeEnum
) {

    Row(
        modifier = Modifier
    )
    {
        RadioGroup(selectedPeriodType?.title) {
            PeriodDaysEnum.entries.forEach { filter ->
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.margin(topBottom = Dimen.smallPadding)
                )
                {

                    RadioInput(
                        value = filter.title,
                        attrs = Modifier
                            .padding(Dimen.normalPadding)
                            .toAttrs {
                                style {
                                    color(Theme.Secondary.hex)
                                    width(Dimen.mediumRadioButton)
                                    height(Dimen.mediumRadioButton)
                                }
                                onClick {
                                    if (filter != selectedPeriodType)
                                        onSelectPeriodType(filter)
                                }
                                checked(filter == selectedPeriodType)

                            }
                    )
                    SpanText(
                        text = filter.title,
                        modifier = Modifier
                            .margin(leftRight = Dimen.normalPadding)
                            .fontSize(
                                if (windowSizeMode==WindowSizeModeEnum.PHONE)
                                    FontSizes.normalFontSize
                                else FontSizes.mediumFontSize
                            )
                            .color(Theme.Secondary.rgb)
                            .fontWeight(FontWeight.Medium)
                            .fontFamily(FontFamily.Jakarta)
                    )
                }


            }
        }


    }
}