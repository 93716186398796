package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.calendar_filter_dialog.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodMonthEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodYearEnum
import org.hs_soft.runmynesto.domain.util.getUpdatedSelectedYear
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.periods.component.PeriodsMonth

@Composable
fun CalendarYearAndMonthFilter(
    selectedFrom:String,
    selectedTo:String,
    selectedYear:Int,
    filterDate:(String, String)->Unit,
) {

    Column(
        modifier = Modifier
            .fillMaxWidth(),
    )
    {
        Row(
            modifier = Modifier.fillMaxWidth()
        )
        {
            YearPart(
                modifier = Modifier,
                isClicked =  PeriodYearEnum.ONE_HALF_A_YEAR.to
                    .getUpdatedSelectedYear(selectedYear)==selectedTo
                        && PeriodYearEnum.ONE_HALF_A_YEAR.from
                            .getUpdatedSelectedYear(selectedYear)==selectedFrom ,
                title = PeriodYearEnum.ONE_HALF_A_YEAR.title ,
                onItemClick = {
                    filterDate(
                        PeriodYearEnum.ONE_HALF_A_YEAR.from,
                        PeriodYearEnum.ONE_HALF_A_YEAR.to,
                    )
                }
            )
            YearPart(
                title = PeriodYearEnum.TWO_HALF_A_YEAR.title ,
                modifier = Modifier,
                isClicked =  PeriodYearEnum.TWO_HALF_A_YEAR.to
                    .getUpdatedSelectedYear(selectedYear)==selectedTo
                        && PeriodYearEnum.TWO_HALF_A_YEAR.from
                    .getUpdatedSelectedYear(selectedYear)==selectedFrom ,
                onItemClick = {
                    filterDate(
                        PeriodYearEnum.TWO_HALF_A_YEAR.from,
                        PeriodYearEnum.TWO_HALF_A_YEAR.to,
                    )
                }
            )

        }

        Row(
            modifier = Modifier
                .fillMaxWidth()
        )
        {
            YearPart(
                title = PeriodYearEnum.ONE_QUARTER.title,
                modifier = Modifier,
                isClicked =  PeriodYearEnum.ONE_QUARTER.to
                    .getUpdatedSelectedYear(selectedYear)==selectedTo
                        && PeriodYearEnum.ONE_QUARTER.from
                    .getUpdatedSelectedYear(selectedYear)==selectedFrom ,
                onItemClick = {
                    filterDate(
                        PeriodYearEnum.ONE_QUARTER.from,
                        PeriodYearEnum.ONE_QUARTER.to,
                    )
                }
            )

            YearPart(
                title = PeriodYearEnum.TWO_QUARTERS.title,
                modifier = Modifier,
                isClicked =  PeriodYearEnum.TWO_QUARTERS.to
                    .getUpdatedSelectedYear(selectedYear)==selectedTo
                        && PeriodYearEnum.TWO_QUARTERS.from
                    .getUpdatedSelectedYear(selectedYear)==selectedFrom ,
                onItemClick = {
                    filterDate(
                        PeriodYearEnum.TWO_QUARTERS.from,
                        PeriodYearEnum.TWO_QUARTERS.to,
                    )
                }
            )

            YearPart(
                title = PeriodYearEnum.THREE_QUARTERS.title ,
                modifier = Modifier,
                isClicked =  PeriodYearEnum.THREE_QUARTERS.to
                    .getUpdatedSelectedYear(selectedYear)==selectedTo
                        && PeriodYearEnum.THREE_QUARTERS.from
                    .getUpdatedSelectedYear(selectedYear)==selectedFrom ,
                onItemClick = {
                    filterDate(
                        PeriodYearEnum.THREE_QUARTERS.from,
                        PeriodYearEnum.THREE_QUARTERS.to,
                    )
                }
            )

            YearPart(
                title =  PeriodYearEnum.FOUR_QUARTERS.title,
                modifier = Modifier,
                isClicked =  PeriodYearEnum.FOUR_QUARTERS.to
                    .getUpdatedSelectedYear(selectedYear)==selectedTo
                        && PeriodYearEnum.FOUR_QUARTERS.from
                    .getUpdatedSelectedYear(selectedYear)==selectedFrom ,
                onItemClick = {
                    filterDate(
                        PeriodYearEnum.FOUR_QUARTERS.from,
                        PeriodYearEnum.FOUR_QUARTERS.to,
                    )
                }
            )

        }

        PeriodsMonth(
            selectedPeriodType = PeriodMonthEnum.entries.find {
                it.to.getUpdatedSelectedYear(selectedYear)==selectedTo
                        && it.from.getUpdatedSelectedYear(selectedYear)==selectedFrom
            },
            onSelectPeriodType = {
                filterDate(
                    it.from,
                    it.to,
                )
            }
        )
    }

}