package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail.LoyaltyCardDetail
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.TelephoneCodeEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.DropdownComponent
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.jetbrains.compose.web.dom.Text

@Composable
fun Owner(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    updateLoyaltyCardInfo: (String, dynamic) -> Unit,
    windowSizeMode: WindowSizeModeEnum,
)
{
    val isUser=selectedLoyaltyCard?.isUser?:false

    Column()
    {
        MediumContentTitle(
            title = Strings.owner,
        )

        if (windowSizeMode==WindowSizeModeEnum.WINDOWS){
            Row()
            {
                Content(
                    isUser=isUser,
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    updateLoyaltyCardInfo={title:String,value:dynamic->
                        updateLoyaltyCardInfo(title,value)
                    }
                )

            }
        }else{
            Column()
            {
                Content(
                    isUser=isUser,
                    selectedLoyaltyCard=selectedLoyaltyCard,
                    updateLoyaltyCardInfo={title:String,value:dynamic->
                        updateLoyaltyCardInfo(title,value)
                    }
                )

            }
        }



    }



}

@Composable
private fun Content(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    isUser: Boolean,
    updateLoyaltyCardInfo: (String, dynamic) -> Unit
) {
    OwnerLeftContent(
        selectedLoyaltyCard = selectedLoyaltyCard,
        isUser = isUser,
        updateLoyaltyCardInfo = { title: String, value: dynamic ->
            updateLoyaltyCardInfo(title, value)
        }
    )

    Box(
      modifier = Modifier.width(Dimen.normalPadding)
    )

    OwnerRightContent(
        selectedLoyaltyCard = selectedLoyaltyCard,
        isUser = isUser,
        updateLoyaltyCardInfo = { title: String, value: dynamic ->
            updateLoyaltyCardInfo(title, value)
        }
    )
}

@Composable
private fun OwnerLeftContent(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    isUser: Boolean,
    updateLoyaltyCardInfo:(String, dynamic)->Unit,
) {
    Column()
    {
        CustomEditText(
            modifier = Modifier ,
            label = Strings.cardName,
            value = selectedLoyaltyCard?.name?:"",
            marginBottom = Dimen.smallPadding,
            isRequired = false,
            isEnabled = !isUser,
            hint = "",
            onFocusIn = {  },
            onFocusOut = {
                 updateLoyaltyCardInfo(
                     ApiUtils.Field.name,
                     it
                 )
            },
            onInputChange = {},
        )

        CustomEditText(
            modifier = Modifier ,
            label = Strings.salutation,
            isEnabled = !isUser,
            value =selectedLoyaltyCard?.salutation?:"",
            marginBottom = Dimen.smallPadding,
            isRequired = false,
            hint = "",
            onFocusIn = { },
            onFocusOut = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.salutation,
                    it
                )
            },
            onInputChange = {},
        )

        CustomEditText(
            modifier = Modifier ,
            label = Strings.clientName,
            isEnabled = !isUser,
            value = selectedLoyaltyCard?.clientName?:"",
            marginBottom = Dimen.smallPadding,
            isRequired = false,
            hint = "",
            onFocusIn = {   },
            onFocusOut = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.clientName,
                    it
                )
            },
            onInputChange = {},
        )

        CustomEditText(
            modifier = Modifier ,
            isEnabled = !isUser,
            label = Strings.firstName,
            value = selectedLoyaltyCard?.clientSurname?:"",
            marginBottom = Dimen.smallPadding,
            isRequired = false,
            hint = "",
            onFocusIn = { },
            onFocusOut = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.clientSurname,
                    it
                )
            },
            onInputChange = {},
        )

        CustomEditText(
            modifier = Modifier ,
            isEnabled = !isUser,
            label = Strings.addition,
            value = selectedLoyaltyCard?.additive?:"",
            marginBottom = Dimen.smallPadding,
            isRequired = false,
            hint = "",
            onFocusIn = {},
            onFocusOut = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.additive,
                    it
                )
            },
            onInputChange = {},
        )



    }
}

@Composable
private fun OwnerRightContent(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    isUser: Boolean,
    updateLoyaltyCardInfo:(String, dynamic)->Unit,
) {
    val selectedCountryCode = remember { mutableStateOf<TelephoneCodeEnum?>(null) }

    LaunchedEffect(selectedLoyaltyCard?.validPhoneNumber) {
        selectedCountryCode.value = selectedLoyaltyCard?.validPhoneNumber?.let { phoneNumber ->
            val firstThreeDigits = phoneNumber.take(3)
            TelephoneCodeEnum.entries.find { firstThreeDigits.startsWith(it.code) }
        } ?: TelephoneCodeEnum.OTHER
    }

    Column(
        modifier = Modifier
    )
    {
        CustomEditText(
            modifier = Modifier ,
            label = Strings.street,
            isEnabled = !isUser,
            value = selectedLoyaltyCard?.street?:"",
            marginBottom = Dimen.smallPadding,
            isRequired = false,
            hint = "",
            onFocusIn = {},
            onFocusOut = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.street,
                    it
                )
            },
            onInputChange = {},
        )

        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            CustomEditText(
                modifier = Modifier ,
                isEnabled = !isUser,
                marginRight = Dimen.normalPadding,
                label = Strings.zipCity,
                width = Dimen.extraSmallEditTextWidth,
                value =selectedLoyaltyCard?.postcode?:"",
                marginBottom = Dimen.smallPadding,
                isRequired = false,
                hint = "",
                onFocusIn = {},
                onFocusOut = {
                    updateLoyaltyCardInfo(
                        ApiUtils.Field.postcode,
                        it
                    )
                },
                onInputChange = {},
            )

            CustomEditText(
                modifier = Modifier ,
                label ="",
                isEnabled = !isUser,
                value = selectedLoyaltyCard?.city?:"",
                hasLabelSpace = false,
                width = Dimen.highNormalEditTextWidth,
                marginBottom = Dimen.smallPadding,
                isRequired = false,
                hint = "",
                onFocusIn = {},
                onFocusOut = {
                    updateLoyaltyCardInfo(
                        ApiUtils.Field.city,
                        it
                    )
                },
                onInputChange = {},
            )
        }





        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            DropdownComponent(
                modifier = Modifier.margin(
                    bottom = Dimen.normalPadding,
                    right = Dimen.normalPadding
                ),
                disable =isUser,
                itemWidth = Dimen.extraSmallEditTextWidth,
                options = TelephoneCodeEnum.entries,
                label = Strings.telephone,
                selectedItem =
                    TelephoneCodeEnum.entries.find {
                        it.code==selectedCountryCode.value?.code
                    }

                ,
                content = {
                    if (it is TelephoneCodeEnum)
                        Text(it.code)
                },
                onOptionClick = {
//                    val option: TelephoneCodeEnum? = TelephoneCodeEnum.entries.find { item ->
//                        item.toString() == it
//                    }
//                    console.log(option?.code)
//                    if (option != null) {
//                        selectedCountryCode.value=option
//                    }
                    TODO("Must be fixed.")

                }
            )
            CustomEditText(
                modifier = Modifier ,
                label ="",
                isEnabled = !isUser,
                width = Dimen.highNormalEditTextWidth,
                value = selectedLoyaltyCard?.validPhoneNumber?.removePrefix(
                    selectedCountryCode.value?.code?:""
                )?:"",
                hasLabelSpace = true,
                isDecimal = true,
                marginBottom = Dimen.smallPadding,
                isRequired = false,
                hint = "",
                onFocusIn = {

                },
                onFocusOut = {},
                onInputChange = {},
            )
        }


        CustomEditText(
            modifier = Modifier ,
            label = Strings.email,
            isEnabled = !isUser,
            value = selectedLoyaltyCard?.email?:"",
            marginBottom = Dimen.smallPadding,
            isRequired = false,
            hint = "",
            onFocusIn = {},
            onFocusOut = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.email,
                    it
                )
            },
            onInputChange = {},
        )

        CheckboxComponent(
            modifier = Modifier ,
            checkStatus = selectedLoyaltyCard?.isUser?:false,
            title = Strings.activate,
            onChecked = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.isUser,
                    true
                )
            },
            onUnChecked = {
                updateLoyaltyCardInfo(
                    ApiUtils.Field.isUser,
                    false
                )
            },
        )

    }
}