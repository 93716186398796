package org.hs_soft.runmynesto.domain.util

object IdUtils
{
    const val logoImg="logoImg"
    const val heroGoogleImg="heroGoogleImg"
    const val wideCompanyLogo="wideCompanyLogo"
    const val wideLogoImg="wideLogoImg"
    const val productsDB="products_data4"
    const val productCategories="productCategories40"
    const val companyProfileId="companyProfileId20"
    const val authToken="authToken"
    const val checkOutList="checkOutList2"
    const val salesChartId="salesChartId"
    const val clientChartId="clientChartId"

}