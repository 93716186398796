package org.hs_soft.runmynesto.domain.usecase.user

import org.hs_soft.runmynesto.domain.model.home.user.users_info.UsersData
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.UsersDataRepository

class UsersDataUseCase(
    private val repository: UsersDataRepository
) {
    suspend operator fun invoke(): UsersData {
       return repository.getUsersData()
    }
}