package org.hs_soft.runmynesto.domain.model.home.home.hssoft_apps_list


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class HSWebApps(
    @SerialName("appType")
    val appType: String?,
    @SerialName("isAvailable")
    val isAvailable: Boolean?
)