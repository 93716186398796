package org.hs_soft.runmynesto.domain.usecase.product.tab_product_general

import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.ProductItemModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.SingleProductRepository


class GetSingleProductUseCase(
    private val repository: SingleProductRepository
) {
    suspend operator fun invoke(id:String): ProductItemModel {
        return repository.getSingleProduct(id=id)
    }


}