package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.accentColor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.dom.Input
import org.hs_soft.runmynesto.domain.config.Theme
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.w3c.dom.HTMLInputElement

@Composable
fun CheckboxComponent(
    modifier:Modifier=Modifier,
    checkStatus:Boolean,
    title:String,
    onChecked:()->Unit,
    fontSize:CSSSizeValue<CSSUnit.px> = FontSizes.mediumFontSize,
    fontWeight: FontWeight=FontWeight.Normal,
    onUnChecked:()->Unit,
    enabled:Boolean=true,
    accentColor:CSSColorValue= Theme.Blue.rgb
)
{
    val isChecked= remember { mutableStateOf(checkStatus) }

    LaunchedEffect(key1 = checkStatus) {
        isChecked.value = checkStatus
    }
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically){
        Box(modifier = Modifier
            .onClick {  event: SyntheticMouseEvent -> event.stopPropagation() }
        )
        {
            Input(type = InputType.Checkbox,
                attrs = {
                    if (!enabled)
                        disabled()
                    checked(isChecked.value)
                    style {
                        accentColor(accentColor)

                    }
                    onInput { event ->
                        isChecked.value = (event.target as HTMLInputElement).checked
                        if (isChecked.value){
                            onChecked()
                        }else onUnChecked()
                    }
                })


        }

        ComponentLabel(
            title=title,
            fontWeight = fontWeight,
            fontSize=fontSize,
        )
    }





}

