package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.series_tab.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail.LoyaltyCardDetail
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.series_tab.getCustomerCardSeriesBorderColor
import org.jetbrains.compose.web.css.px

@Composable
fun ResetBooking(
    selectedLoyaltyCard: LoyaltyCardDetail?,
    resetRangeLoyaltyCard: (Int, Int) -> Unit,
)
{
    val from= remember { mutableStateOf("") }
    val until= remember { mutableStateOf("") }

    LaunchedEffect(selectedLoyaltyCard){
        until.value=""
        from.value=""
    }

    Column(
        modifier = Modifier
            .margin(top = Dimen.mediumPadding)
    )
    {
        MediumContentTitle(
            title = Strings.resetBooking,
        )

        Row(
            verticalAlignment = Alignment.CenterVertically
        )
        {
            CustomEditText(
                modifier = Modifier ,
                label = Strings.from,
                value = from.value,
                defaultBorderColor = getCustomerCardSeriesBorderColor(
                    from=from.value,
                    until=until.value
                ),
                marginRight = Dimen.normalPadding,
                isDecimal = true,
                width = Dimen.semiNormalEditTextWidth,
                marginBottom = Dimen.smallPadding,
                isRequired = false,
                hint = "",
                onFocusIn = {

                },
                onFocusOut = {},
                onInputChange = {
                    from.value=it
                },
            )


            CustomEditText(
                modifier = Modifier,
                label = Strings.until,
                value =until.value,
                defaultBorderColor = getCustomerCardSeriesBorderColor(
                    from=from.value,
                    until=until.value
                ),
                width = Dimen.semiNormalEditTextWidth,
                marginRight = Dimen.normalPadding,
                isRequired = false,
                hint = "",
                marginBottom = Dimen.smallPadding,
                onFocusIn = {

                },
                onFocusOut = {},
                onInputChange = {
                    until.value=it
                },
            )
        }

        CustomButton(
            modifier = Modifier,
            topBottomMargin = Dimen.mediumPadding,
            leftRightMargin = 0.px,
            isClickable = (from.value.isNotEmpty()
                    && until.value.isNotEmpty()
                    && from.value.toInt()<=until.value.toInt()
            ),
            title = Strings.createCustomerCard,
            shadowColor = Theme.GrayCD.rgb,
            defaultTextColor = Theme.Secondary.rgb,
            onButtonClick = {
                resetRangeLoyaltyCard(
                    from.value.toInt(),
                    until.value.toInt(),
                )
            }

        )

    }



}