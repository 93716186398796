package org.hs_soft.runmynesto.domain.model.home.dashboard.customer_frequency


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetCustomerFrequency(
    @SerialName("billCount")
    val billCount: Double?,
    @SerialName("creditClient")
    val creditClient: Double?,
    @SerialName("creditClientCount")
    val creditClientCount: Double?,
    @SerialName("creditClientTotal")
    val creditClientTotal: Double?,
    @SerialName("dateTime")
    val dateTime: String?,
    @SerialName("interval")
    val interval: String?,
    @SerialName("shopId")
    val shopId: String?,
    @SerialName("total")
    val total: Double?,
    @SerialName("totalK")
    val totalK: Double?,
    @SerialName("__typename")
    val typename: String?
)