package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.filtered_transaction


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FilteredLoyaltyCardItem(
    @SerialName("balance")
    val balance: Double?,
    @SerialName("billNumber")
    val billNumber: Int?,
    @SerialName("cashRegister")
    val cashRegister: CashRegister?,
    @SerialName("dateTime")
    val dateTime: String?,
    @SerialName("decrease")
    val decrease: Double?,
    @SerialName("group")
    val group: Group?,
    @SerialName("groupId")
    val groupId: String?,
    @SerialName("increase")
    val increase: Double?,
    @SerialName("isCancelled")
    val isCancelled: Boolean?,
    @SerialName("mode")
    val mode: String?,
    @SerialName("name")
    val name: String?,
    @SerialName("points")
    val points: Int?,
    @SerialName("pointsTotal")
    val pointsTotal: Int?,
    @SerialName("transactionDate")
    val transactionDate: String?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("userName")
    val userName: String?
)