package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.openLocalForUniqueSelectingFile
import org.hs_soft.runmynesto.pages.components.UploadImage
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit


@Composable
fun WalletImgContent(
    modifier: Modifier=Modifier,
    title:String,
    img:String,
    imgId:String,
    width: CSSSizeValue<CSSUnit.px>,
    height:CSSSizeValue<CSSUnit.px>,
    updateImg:(String,)->Unit,
)
{
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.Start
    ){
        SpanText(
            text = title ,
            modifier = Modifier
                .fontFamily(FontFamily.Montserrat)
                .fontSize(FontSizes.mediumFontSize)
                .color(Theme.Primary.rgb)
                .fontWeight(FontWeight.Normal)
        )


        UploadImage(
            imgUrl =img,
            setImageDirectlyStatus = true,
            width = width,
            height =height,
            imgId=imgId,
            onImgContentClick = {
                openLocalForUniqueSelectingFile(imgId)
            },
            setImageDirectly = {
                updateImg(it)
            }
        )



    }

}