package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart.component.chart

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart.component.chart.component.AreaChart
import org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart.component.chart.component.TopChartToolbar
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun DashboardChart(
    mainData: List<Double?>?,
    comparedData: List<Double?>?,
    title: String,
    id: String,
    firstFillColor: String,
    firstStrokeColor: String,
    secondFillColor: String,
    secondStrokeColor: String,
    interval: List<String?>?,
    selectedDate: MonthModel,
    todayCircleColor: CSSColorValue,
    comparedCircleColor: CSSColorValue,
    showCompareButton: Boolean = true,
    windowSizeMode: WindowSizeModeEnum,
    showCompareChartDialogStatus: Boolean,
    showCompareChartDialog: () -> Unit = {},
    onCompareDayClick: (MonthModel) -> Unit,
) {

    Column(
        modifier = Modifier
            .thenIf(
                windowSizeMode!=WindowSizeModeEnum.PHONE,
                Modifier.fillMaxWidth()
            )
            .thenIf(
                windowSizeMode==WindowSizeModeEnum.PHONE,
                Modifier.width(Dimen.chartWidth.px)
            )
            .fillMaxWidth()
            .margin(topBottom = Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    )
    {
        TopChartToolbar(
            windowSizeMode=windowSizeMode,
            title = title,
            showCompareButton=showCompareButton,
            selectedDate=selectedDate,
            showCompareChartDialogStatus = showCompareChartDialogStatus,
            todayCircleColor = todayCircleColor,
            comparedCircleColor = comparedCircleColor,
            showCompareChartDialog = {
                showCompareChartDialog()
            },
            onDayClick = {
                onCompareDayClick(it)
            }
        )

        AreaChart(
            windowSizeMode=windowSizeMode,
            mainData=mainData,
            comparedData=comparedData,
            interval=interval,
            id=id,
            firstFillColor=firstFillColor,
            firstStrokeColor=firstStrokeColor,
            secondFillColor = secondFillColor,
            secondStrokeColor =secondStrokeColor ,
        )

    }


}


