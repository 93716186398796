package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.DayModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel

@Composable
fun CalendarDayNumbers(
    currentMonth: MonthModel,
    onDayClick: (MonthModel) -> Unit,
    selectedDay: MonthModel?,
) {
    Column(
        modifier = Modifier
            .width(Dimen.DateDaysWidth),
    ) {

        val adjustedDays = adjustDaysToStartFromMonday(currentMonth.days)

        adjustedDays.chunked(7).forEachIndexed { index, week ->
            Row {
                week.forEach { day ->
                    DateNumberItem(
                        number = day.dayNumber,
                        onSelectNumber = {
                            onDayClick(it)
                        },
                        selectedDate = selectedDay,
                        currentMonth = currentMonth.monthName,
                        currentYear = currentMonth.yearNumber,
                        monthNumber = currentMonth.monthNumber,
                    )
                }
            }
        }
    }
}

private fun adjustDaysToStartFromMonday(days: List<DayModel>): List<DayModel> {
    // Find the first day of the month
    val firstDay = days.first()

    // Calculate how many blank days we need before the 1st
    val dayOfWeek = when (firstDay.dayName) {
        "Monday" -> 0
        "Tuesday" -> 1
        "Wednesday" -> 2
        "Thursday" -> 3
        "Friday" -> 4
        "Saturday" -> 5
        "Sunday" -> 6
        else -> 0
    }

    // Create blank days for padding
    val blankDays = List(dayOfWeek) {
        DayModel(
            dayNumber = 0,
            dayName = "",
            isCurrentMonth = false
        )
    }

    return blankDays + days
}