package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.kitchen_info


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("listProductAdditions")
    val listProductAdditions: List<ProductAdditions?>

)