package org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class StatusVirtual(
    @SerialName("type")
    val type: List<String?>?,
    @SerialName("__typename")
    val typename: String?
)