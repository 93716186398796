package org.hs_soft.runmynesto.data.api_datasource.home

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.home.user_profile_info.UserProfileDataModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.home.UserProfileDataRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils


class UserProfileDataRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UserProfileDataRepository {

    override suspend fun getUserProfileData(): UserProfileDataModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """
                     query getUserProfileData {
                       getUserProfileData { 
                        company
                        name
                        email
                        avatarUrl
                       }
                     }
                """.trimIndent()
            )


        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }


}

