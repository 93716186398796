package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.Art
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.Balance
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.CustomerCardTabCard
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.Owner
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.customer_card_transactions.CustomerCardTransaction
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.periods.Period
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardEvent
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardState
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.overflowY
import org.jetbrains.compose.web.css.vh

@Composable
fun CustomerCardTab(
    modifier:Modifier=Modifier,
    windowSizeMode:WindowSizeModeEnum,
    state:CustomerCardState,
    event:(CustomerCardEvent)->Unit,
)
{

    Column(
        modifier = modifier
            .fillMaxWidth()
            .height(Constants.Responsive.templateGridContentHeight.vh)
            .styleModifier {
                flexGrow(1)
                overflowY("auto")
            }
            .margin(top = Dimen.normalPadding)
            .padding(Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    )
    {
        CustomerCardTabCard(
            windowSizeMode=windowSizeMode,
            selectedLoyaltyCard=state.selectedLoyaltyCard,
            setIsActiveStatus = {
                event(CustomerCardEvent.UpdateLoyalCards(
                    dataTitle = ApiUtils.Field.isActive,
                    dataValue = it
                ))
            }

        )
        Balance(
            windowSizeMode=windowSizeMode,
            selectedLoyaltyCard=state.selectedLoyaltyCard,
            setIsPointsActiveStatus = {
                event(CustomerCardEvent.UpdateLoyalCards(
                    dataTitle = ApiUtils.Field.isPointsActive,
                    dataValue = it
                ))
            },
            setCardLimit = {
                    event(CustomerCardEvent.UpdateLoyalCards(
                        dataTitle = ApiUtils.Field.limit,
                        dataValue = it
                    ))
                }
            ,
            setDailyLimit = {
                event(CustomerCardEvent.UpdateLoyalCards(
                    dataTitle = ApiUtils.Field.dayLimit,
                    dataValue = it
                ))
            },
        )

        if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
        {
            Row {
                OwnerAndArt(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=event
                )
            }
        }else{
            Column{
                OwnerAndArt(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=event
                )
            }
        }


        Period(
            windowSizeMode=windowSizeMode,
            selectedLoyaltyCard=state.selectedLoyaltyCard,
            selectedPeriodType = state.selectedDaysPeriod,
            selectedPeriodMonthType = state.selectedMonthPeriod,
            onSelectDaysPeriodType = {period:PeriodDaysEnum->
                event(CustomerCardEvent.OnSelectPeriodDays(
                    period = period,
                ))
            },
            filterCardItems = {
//                event(CustomerCardEvent.FilterLoyaltyCards(
//                    cardId = state.selectedLoyaltyCard?.id,
//                    from=it?.from,
//                    to=it?.to,
//                ))
            }
        )

        Spacer()

        CustomerCardTransaction(
            selectedTransactionColumns=state.selectedTransactionColumns,
            filteredLoyaltyCards=state.filteredLoyaltyCardItems,
            showTransactionColumnsDialog = {
                event(
                    CustomerCardEvent.ShowTransactionColumnsDialog
                )
            }
        )


    }


}

@Composable
private fun OwnerAndArt(
    windowSizeMode: WindowSizeModeEnum,
    state: CustomerCardState,
    event: (CustomerCardEvent) -> Unit
) {
    Owner(
        windowSizeMode=windowSizeMode,
        selectedLoyaltyCard = state.selectedLoyaltyCard,
        updateLoyaltyCardInfo = { title: String, value: dynamic ->
            event(
                CustomerCardEvent.UpdateLoyalCards(
                    dataTitle = title,
                    dataValue = value
                )
            )
        }
    )

    Box(
        modifier = Modifier
            .width(Dimen.mediumPadding)
    )


    Art(
        windowSizeMode=windowSizeMode,
        selectedLoyaltyCard = state.selectedLoyaltyCard,
        creditClient = state.creditClient,
        updateLoyaltyCardInfo = { title: String, value: dynamic ->
            event(
                CustomerCardEvent.UpdateLoyalCards(
                    dataTitle = title,
                    dataValue = value
                )
            )
        }
    )
}