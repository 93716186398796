package org.hs_soft.runmynesto.data.local_datasource.local_storage.product

import org.hs_soft.runmynesto.data.local_datasource.local_storage.getFromLocalStorage
import org.hs_soft.runmynesto.domain.model.home.options.CountriesEnum
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.GetCompanyProfileIdRepository
import org.hs_soft.runmynesto.domain.util.IdUtils

class GetCompanyProfileIdRepositoryImpl: GetCompanyProfileIdRepository
{
    override fun getCompanyProfileId():String {
        return getFromLocalStorage(IdUtils.companyProfileId)
            ?: CountriesEnum.SWITZERLAND.id
    }

}