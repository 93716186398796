package org.hs_soft.runmynesto.domain.model.home.user.tabs.user_rights


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UserRightModel(
    @SerialName("data")
    val data: Data
)
