package org.hs_soft.runmynesto.domain.usecase.login

import org.hs_soft.runmynesto.domain.repository.network_datasource.login.SignInRepository


class SignInUseCase(
    private val repository: SignInRepository
) {
    operator fun invoke(
        userName:String,
        password:String,
        mfaCode:String,
        onSuccess:()->Unit,
        onError:(String)->Unit,
    ) {
        repository.signIn(
            userName = userName,
            password=password,
            mfaCode=mfaCode,
            onSuccess = {
                onSuccess()
            },
            onError={
                onError(it)
            }

        )
    }
}