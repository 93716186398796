package org.hs_soft.runmynesto.pages.home.sub_page.product.mvi

import org.hs_soft.runmynesto.domain.model.home.product.tabs.ProductInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.GetProduct
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.Properties
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.checked_ingredients_list.CheckedIngredient
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.ingeredient_list.Ingredients
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group.SaleItem
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.kitchen_info.ProductAdditions
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location.PrinterLocation
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.restaurant_data.GetRestaurant
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.util.Res

data class ProductState(
    val kitchenInfo:List<ProductAdditions?>?=null,
    val selectedTab: ProductInfoHeaderEnum = ProductInfoHeaderEnum.GENERAL,
    val ingredients:List<Ingredients> ?=null,
    val checkedIngredients:List<CheckedIngredient?> ?= listOf(),
    val restaurantData: GetRestaurant?=null,
    val selectedProduct: GetProduct?=null,
    val selectedSaleGroupId:String?=null,
    val companyProfileId:String="",
    val properties: Properties?=null,
    val cashList: List<ChildrenX> ?= null,
    val productSalesGroup:List<SaleItem> ?=null,
    val checkOutExpandAll:Boolean=false,
    val checkOutSearchValue:String="",
    val collectMinOrder:String="",
    val collectMaxOrder:String="",
    val collectTimeDaysLead:String="",
    val printersLocation:List<PrinterLocation?> ? =null,
    val selectedVat:String ?=null,
    val selectedVat2:String ?=null,
    val isAllergensNameSortedAsc:Boolean=false,
    val isAllergensNumberSortedAsc:Boolean=false,
    val showAllergensNumberSortIcon:Boolean=false,
    val restaurantTempItemsDescendingSort:Boolean ?=null,
    val showAllergensNameSortIcon:Boolean=false,
    val firstPageActive:Boolean=true,
    val mainGridSearchValue:String="",
    val dataLoadingProgressVisible:Boolean=false,
    val productsInfo:List<ProductItem> = listOf(),
    val isMainGridNameSortedAsc:Boolean=false,
    val isMainGridNumberSortedAsc:Boolean=false,
    val showMainGridNumberSortIcon:Boolean=false,
    val showMainGridNameSortIcon:Boolean=false,
    val newRecordName:String="",
    val newRecordNumber:String="",
    val addRecordErrorMessage:String="",
    val showGenerateAIImgDialog:Boolean=false,
    val imageAiGeneratorDescription: String="",
    val cashBidImgAIGeneratorUrl:String = Res.PathIcon.defaultRectPhoto,
    val isImageAiGeneratorLoading:Boolean=false,
    val isAddNewRecordSubmitClickable:Boolean=false,
    val addRecordLoading:Boolean=false,
    val showAddRecordDialog:Boolean=false,
    val showItemsFilterComponent:Boolean=false,
    val selectedFilterItemsType: GridItemsFilterEnum = GridItemsFilterEnum.ACTIVE_ITEMS,
    val showKitchenAddItemDialog:Boolean=false,
    val selectedKitchenInfoItem:ProductAdditions?=null,
    val kitchenInfoDialogLoading:Boolean=false,

)