package org.hs_soft.runmynesto.pages.home.sub_page.options.component

import androidx.compose.runtime.Composable
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.menu_card_import.menu_card_model.MenuCardModel
import org.hs_soft.runmynesto.pages.components.dialog.dialog.AddRecordDialog
import org.hs_soft.runmynesto.pages.components.dialog.dialog.MenuCardAIDialog
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.apple.AppleWalletDialog
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.google.GoogleWalletDialog
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsState
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.color_pallet.ColorPallet

@Composable
fun OptionsDialogs(
    state: OptionsState,
    windowSizeMode: WindowSizeModeEnum,
    event: (OptionsEvent) -> Unit
){
    if (state.showColorPalletDialog){
        ColorPallet(
            onColorClick = {
                event(OptionsEvent.OnPalletColorClick(
                    it
                ))
            },
            onBackgroundClick = {
                event(OptionsEvent.SetColorPalletStatus(false))
            }
        )
    }

    if (state.showAddSaleGroupRecordDialog) {
        AddRecordDialog(
            windowSizeMode = windowSizeMode,
            dialogTitle = Strings.addNewSaleGroup,
            nameFieldTitle = Strings.name,
            numberFieldTitle = Strings.number,
            nameValue = state.newRecordName?:"",
            showNumberField=true,
            numberValue = state.newRecordNumber,
            errorMessage = state.addRecordErrorMessage,
            isSubmitClickable = state.isAddNewRecordSubmitClickable,
            loading = state.addRecordLoading,
            onNumberValueChange = {
                event(OptionsEvent.OnChangeRecordNumber(it))
            },
            onNameValueChange = {
                event(OptionsEvent.OnChangeRecordName(it))
            },
            onCancelClick = {
                event(OptionsEvent.OnCancelRecordDialogClick)
            },
            onSubmitClick = {
                event(OptionsEvent.OnSubmitAddSaleGroupRecordDialogClick(windowSizeMode))
            },
            onBackgroundClick = {
                event(OptionsEvent.OnAddRecordDialogBackgroundClick)
            }
        )
    }

    if (state.showKitchenLocationRecordDialog) {
        AddRecordDialog(
            windowSizeMode = windowSizeMode,
            dialogTitle = Strings.addNewLocation,
            nameFieldTitle = Strings.name,
            isNameRequired = state.printerLocationIndex==null,
            nameValue = state.newRecordName?:"",
            showNumberField=false,
            errorMessage = state.addRecordErrorMessage,
            isSubmitClickable = if (state.printerLocationIndex==null) state.isAddNewRecordSubmitClickable
                else true,
            loading = state.addRecordLoading,
            onNameValueChange = {
                event(OptionsEvent.OnChangeRecordName(
                    value = it.ifEmpty { null },
                    isNumberOptional = true
                ))
            },
            onCancelClick = {
                event(OptionsEvent.OnCancelRecordDialogClick)
            },
            onSubmitClick = {
                if (state.printerLocationIndex==null && state.newRecordName?.isNotEmpty() == true){
                    event(OptionsEvent.OnSubmitAddKitchenLocationRecordDialogClick(
                        location = state.newRecordName
                    ))
                }else if (state.printerLocationIndex!=null){
                    event(OptionsEvent.OnSubmitUpdateKitchenLocationRecordDialogClick(
                        location = state.newRecordName
                    ))
                }

            },
            onBackgroundClick = {
                event(OptionsEvent.OnAddRecordDialogBackgroundClick)
            }
        )
    }

    if (state.showGoogleWalletDialog){
        GoogleWalletDialog(
            windowSizeMode=windowSizeMode,
            info=state.virtualCardInfo,
            closeDialog={
                event(OptionsEvent.OnCloseGoogleWallet)
            }
        )

    }
    if (state.showAppleWalletDialog){
        AppleWalletDialog(
            windowSizeMode=windowSizeMode,
            info = state.virtualCardInfo,
            closeDialog = {
                event(OptionsEvent.OnCloseAppleWallet)
            }
        )
    }

    if (state.showGenerateAIMenuCardDialog){
        MenuCardAIDialog(
            windowSizeMode=windowSizeMode,
            menuCardAIGenerated = state.menuCardAIGenerated,
            loading = state.isMenuCardAIGeneratorLoading,
            onBackgroundClick = {},
            onCancelGenerateMenuCardClick = {
                event(OptionsEvent.SetGenerateAIMenuCardDialogStatus(false))
            },
            onGenerateMenuCardClick={files->
                event(OptionsEvent.GenerateMenuCard(
                    files=files,
                ))
            },
        )
    }



}