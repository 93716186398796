package org.hs_soft.runmynesto.data.api_datasource.statistic.tabs.abc_statics

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.abc_statistic_data.ABCStatisticDataModel
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data.StatisticDataModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.abc_statistic.ABCStatisticDataRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.statistic.search_bill.BillSearchStatisticDataRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class ABCStatisticDataRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
):ABCStatisticDataRepository{



    override suspend fun getABCStatisticData(requestId: String): ABCStatisticDataModel {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """ 
                    query getStatisticsData(${'$'}${ApiUtils.Field.requestId}: ID!) {
                    cashAssist {
                        getStatisticsData(requestId: ${'$'}${ApiUtils.Field.requestId}) {
                            ${ApiUtils.Field.status}
                            ${ApiUtils.Field.progress}
                            ${ApiUtils.Field.data} {
                                ... on StatisticsABC {
                                    ${ApiUtils.Field.shopName}
                                    ${ApiUtils.Field.dateTime}
                                    ${ApiUtils.Field.code}
                                    ${ApiUtils.Field.name}
                                    ${ApiUtils.Field.salesGroup}
                                    ${ApiUtils.Field.group1}
                                    ${ApiUtils.Field.group2}
                                    ${ApiUtils.Field.group3}
                                    ${ApiUtils.Field.total}
                                    ${ApiUtils.Field.number}
                                    ${ApiUtils.Field.weight}
                                    ${ApiUtils.Field.creditCustomerTotal}
                                    ${ApiUtils.Field.creditCustomer}
                                    ${ApiUtils.Field.creditCustomerWeight}
                                    ${ApiUtils.Field.order1}
                                    ${ApiUtils.Field.order1Amount}
                                    ${ApiUtils.Field.unsold}
                                    ${ApiUtils.Field.unsoldAmount}
                                    ${ApiUtils.Field.unsoldPercent}
                                    ${ApiUtils.Field.deliver}
                                    ${ApiUtils.Field.stock}
                                    ${ApiUtils.Field.stockAmount}
                                    ${ApiUtils.Field.oldStock}
                                    ${ApiUtils.Field.difference}
                                    ${ApiUtils.Field.differenceAmount}
                                    ${ApiUtils.Field.productCount}
                                    ${ApiUtils.Field.billAmount1}
                                    ${ApiUtils.Field.billAmount2}
                                    ${ApiUtils.Field.billAmount3}
                                    ${ApiUtils.Field.billAmount4}
                                    ${ApiUtils.Field.onlyThis}
                                    ${ApiUtils.Field.firstSale}
                                    ${ApiUtils.Field.lastSale}
                                    ${ApiUtils.Field.inCafeNumber}
                                    ${ApiUtils.Field.inCafeTotal}
                                    ${ApiUtils.Field.promotionNumber}
                                    ${ApiUtils.Field.promotionTotal}
                                    ${ApiUtils.Field.breakfastNumber}
                                    ${ApiUtils.Field.breakfastTotal}
                                    ${ApiUtils.Field.discountNumber}
                                    ${ApiUtils.Field.discountTotal}
                                    ${ApiUtils.Field.g1Percent}
                                    ${ApiUtils.Field.g1PercentT}
                                    ${ApiUtils.Field.g1Abc}
                                    ${ApiUtils.Field.productId}
                                    ${ApiUtils.Field.gNumber}
                                    ${ApiUtils.Field.grossProfit}
                                    ${ApiUtils.Field.contributionMargin}
                                    ${ApiUtils.Field.typename}
                                }
                                ${ApiUtils.Field.typename}
                            }
                            ${ApiUtils.Field.typename}
                        }
                        ${ApiUtils.Field.typename}
                    }
                }
            """.trimIndent()
            )
            putJsonObject("variables") {
                put("requestId",requestId)

            }


        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }


}