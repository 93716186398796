package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.ArticleModel
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.getArticleListWidth
import org.hs_soft.runmynesto.pages.components.grid_function.GridFunction
import org.hs_soft.runmynesto.pages.components.grid_function.GridItemsList
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.SearchBarWithAddIcon
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh

@Composable
fun ArticleList(
    onAddProductIconClick:()->Unit,
    onArticleItemClick:(String)->Unit,
    infoList: List<ArticleModel>,
    onRefreshClick: () -> Unit,
    onSearchValueChange: (String) -> Unit,
    onTitleSortClick: () -> Unit,
    windowSizeMode: WindowSizeModeEnum,
    titleDescending:Boolean,
    showTitleSortIcon:Boolean,
    updateListAfterDragAndDrop: (
        List<dynamic>,
        List<String>,
        List<Int>,
        List<String>
    ) -> Unit,
) {

    Column(
        modifier = Modifier
//            .height(articleContentRatio.vh)
    )
    {
        MediumContentTitle(
            title = Strings.articleList,
            marginTop = Dimen.mediumPadding,
        )

        SearchBarWithAddIcon(
            onSearchInfo = {
                onSearchValueChange(it)
            },
            onAddClick = {
                onAddProductIconClick()
            }
        )

        GridFunction(
            modifier = Modifier ,
            title = Strings.name,
            contentHeight = Constants.Responsive.templateArticleListHeight,
            infoTitle = "",
            footerTitle = Strings.article,
            width = getArticleListWidth(windowSizeMode),
            infoList =infoList,
            refreshList = {
                onRefreshClick()
            },
            content = {
                GridItemsList(
                    items = infoList,
                    id="",
                    onItemClick = {
                        onArticleItemClick(it)
                    },
                    onReorder = {newList,idList,positionList,productIdList->
                        updateListAfterDragAndDrop(
                            newList,
                            idList,
                            positionList,
                            productIdList,
                        )
                    }
                )

            },
            showHeaderSeparator = false,
            titleDescending = titleDescending,
            numberDescending = false,
            onTitleSortClick = {
                onTitleSortClick()
            },
            onNumberSortClick = {},
            showTitleSortIcon = showTitleSortIcon,
            showNumberSortIcon = false
        )


    }

}