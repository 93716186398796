package org.hs_soft.runmynesto.data.api_datasource.template.tab_general

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.update_template_category.UpdateTemplateCategoryModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.UpdateTemplateCategoryRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateTemplateCategoryRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateTemplateCategoryRepository {

    override suspend fun updateTemplateCategory(
        dataValue: dynamic,
        dataTitle: String,
        id: String
    ): UpdateTemplateCategoryModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation CpdateCategory_Main(${'$'}id: ID!, ${'$'}fields: CategoryFields!) {
                  cashAssist {
                    updateCategory(id: ${'$'}id, fields: ${'$'}fields) {
                      ${ApiUtils.Field.id}
                      ${ApiUtils.Field.validAfter}
                      ${ApiUtils.Field.weekDays}
                      ${ApiUtils.Field.name}
                    }
                  }
                }
            """.trimIndent())
            putJsonObject("variables") {
                put("id", id)
                putJsonObject("fields") {
                    when(dataValue){
                        is Boolean ->put(dataTitle, dataValue as Boolean)
                        is String ->put(dataTitle,dataValue as String)
                        is Int -> put(dataTitle,dataValue as Int)
                    }
                }
            }

        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController
        )

        return if (result != null) {
            Json.decodeFromString<UpdateTemplateCategoryModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }






}