package org.hs_soft.runmynesto.data.api_datasource.customer_card.tabs.series

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.series.reset_range_loyalty_card.ResetRangeLoyaltyCardModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.series.ResetRangeLoyaltyCardRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class ResetRangeLoyaltyCardRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): ResetRangeLoyaltyCardRepository
{
    override suspend fun resetRangeLoyaltyCard(
        from: Int,
        to: Int,
        description: String
    ): ResetRangeLoyaltyCardModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation resetRangeLoyaltyCards(${'$'}ids: RangeIds!,
                 ${'$'}fields: LoyaltyCardFields,
                 ${'$'}transactionFields: RangeLoyaltyCardFields) {
                  cashAssist {
                    resetRangeLoyaltyCards(
                      ids: ${'$'}ids
                      fields: ${'$'}fields
                      transactionFields: ${'$'}transactionFields
                    )
                    ${ApiUtils.Field.__typename}
                  }
                }



               
            """.trimIndent())

            putJsonObject("variables") {

                putJsonObject("ids"){
                    put("from",from)
                    put("to",to)
                }

                putJsonObject("transactionFields"){
                    put("description",description)
                }


            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }
    }

}