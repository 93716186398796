package org.hs_soft.runmynesto.data.api_datasource.closing_report

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model.ClosingReportDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.closing_report.ClosingReportDetailRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class ClosingReportDetailRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): ClosingReportDetailRepository
{
    override suspend fun getClosingReportDetail(
        id:String,
    ): ClosingReportDetailModel {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                query getClosing(${'$'}id: ID!) {
                    cashAssist {
                        getClosing(id: ${'$'}id) {
                            ...getClosingFragment
                            ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.__typename}
                    }
                }
            
                fragment getClosingFragment on Closing {
                    ${ApiUtils.Field.id}
                    ${ApiUtils.Field.number}
                    ${ApiUtils.Field.dateTime}
                    ${ApiUtils.Field.total}
                    ${ApiUtils.Field.cashRegisterId}
                    ${ApiUtils.Field.clientCount}
                    ${ApiUtils.Field.productCount}
                    ${ApiUtils.Field.cancellationsCount}
                    ${ApiUtils.Field.clientCardValue}
                    ${ApiUtils.Field.billNumStart}
                    ${ApiUtils.Field.billNumEnd}
                    ${ApiUtils.Field.moneyCountDateTime}
                    ${ApiUtils.Field.doorCounter}
                    ${ApiUtils.Field.guestsCount}
                    ${ApiUtils.Field.inactive}
                    ${ApiUtils.Field.inactiveDate}
                    ${ApiUtils.Field.inactiveReason}
                    ${ApiUtils.Field.inactiveUserId}
                    dateInterval {
                        ${ApiUtils.Field.from}
                        ${ApiUtils.Field.to}
                        ${ApiUtils.Field.__typename}
                    }
                    salesGroups {
                        ${ApiUtils.Field.id}
                        ${ApiUtils.Field.amount}
                        ${ApiUtils.Field.total}
                        ${ApiUtils.Field.mWStNormal}
                        ${ApiUtils.Field.mWStReduced}
                        ${ApiUtils.Field.mWStFree}
                        ${ApiUtils.Field.mWStSpecial}
                        ${ApiUtils.Field.discount}
                        ${ApiUtils.Field.reduction}
                        salesGroup {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.name}
                            ${ApiUtils.Field.acountNum}
                            ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.__typename}
                    }
                    currency {
                        ${ApiUtils.Field.name}
                        ${ApiUtils.Field.present}
                        ${ApiUtils.Field.exchangeRate}
                        ${ApiUtils.Field.presentConverted}
                        ${ApiUtils.Field.difference}
                        ${ApiUtils.Field.cashInTransit}
                        ${ApiUtils.Field.amount}
                        ${ApiUtils.Field.amountConverted}
                        ${ApiUtils.Field.__typename}
                    }
                    taxes {
                        ${ApiUtils.Field.MWSt}
                        ${ApiUtils.Field.sales}
                        ${ApiUtils.Field.total}
                        ${ApiUtils.Field.percent}
                        ${ApiUtils.Field.__typename}
                    }
                    paymentMethods {
                        ${ApiUtils.Field.name}
                        ${ApiUtils.Field.count}
                        ${ApiUtils.Field.amount}
                        ${ApiUtils.Field.__typename}
                    }
                    coupons {
                        ${ApiUtils.Field.name}
                        ${ApiUtils.Field.amount}
                        ${ApiUtils.Field.__typename}
                    }
                    cashbook(groupCashbooksBy: DESCRIPTION) {
                        ${ApiUtils.Field.description}
                        ${ApiUtils.Field.have}
                        ${ApiUtils.Field.should}
                        ${ApiUtils.Field.__typename}
                    }
                    specialSales {
                        ${ApiUtils.Field.name}
                        ${ApiUtils.Field.amount}
                        ${ApiUtils.Field.__typename}
                    }
                    users {
                        ${ApiUtils.Field.id}
                        user {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.name}
                            ${ApiUtils.Field.code}
                            ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.total}
                        ${ApiUtils.Field.clientCount}
                        ${ApiUtils.Field.averageBill}
                        ${ApiUtils.Field.userEfficiency}
                        ${ApiUtils.Field.cancellationsCount}
                        ${ApiUtils.Field.cancellationsAmount}
                        ${ApiUtils.Field.buttonOpenCount}
                        ${ApiUtils.Field.buttonBackCount}
                        ${ApiUtils.Field.buttonDelCount}
                        ${ApiUtils.Field.buttonDelAmount}
                        ${ApiUtils.Field.inCafeCount}
                        ${ApiUtils.Field.inCafeAmount}
                        ${ApiUtils.Field.inCafePercent}
                        ${ApiUtils.Field.__typename}
                    }
                    priceDifference {
                        ${ApiUtils.Field.id}
                        ${ApiUtils.Field.mod_ver}
                        product {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.name}
                            ${ApiUtils.Field.code}
                            ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.type}
                        ${ApiUtils.Field.quantity}
                        ${ApiUtils.Field.reduction}
                        ${ApiUtils.Field.__typename}
                    }
                    creditClientOrders {
                        ${ApiUtils.Field.id}
                        ${ApiUtils.Field.invoiceId}
                        ${ApiUtils.Field.total}
                        creditClient {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.clientNumber}
                            ${ApiUtils.Field.name}
                            ${ApiUtils.Field.__typename}
                        }
                        cashRegister {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.name}
                            ${ApiUtils.Field.__typename}
                        }
                        items {
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.price}
                            ${ApiUtils.Field.amount}
                            ${ApiUtils.Field.total}
                            product {
                                ${ApiUtils.Field.id}
                                ${ApiUtils.Field.name}
                                ${ApiUtils.Field.code}
                                ${ApiUtils.Field.__typename}
                            }
                            ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                }
            """.trimIndent())

            putJsonObject("variables") {
                put("id",id)
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }

    }



}