package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ClosingReportDetailModel(
    @SerialName("data")
    val `data`: Data?
)