package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ListSalesGroups(
    @SerialName("hasNextPage")
    val hasNextPage: Boolean?,
    @SerialName("items")
    val items: List<SaleItem>,
    @SerialName("__typename")
    val typename: String?
)