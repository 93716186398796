package org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Region(
    @SerialName("id")
    val id: String?,
    @SerialName("name")
    val name: String?,
    @SerialName("shops")
    val shops: List<Shop?>?,
    @SerialName("__typename")
    val typename: String?
)