package org.hs_soft.runmynesto.data.api_datasource

import com.varabyte.kobweb.browser.http.AbortController

class RequestManager {
    private val controllers = mutableListOf<AbortController>()

    fun createController(): AbortController {
        val controller = AbortController()
        controllers.add(controller)
        return controller
    }

    fun cancelAll() {
        controllers.forEach { it.abort() }
        controllers.clear()
    }
}