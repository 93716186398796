package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.restaurant_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.restaurant_data.GetRestaurant
import org.hs_soft.runmynesto.domain.util.getGeneralItemRatio
import org.hs_soft.runmynesto.pages.components.CheckboxComponent

@Composable
 fun KitchenGeneral(
    restaurantData: GetRestaurant?,
    updatePrintOnlyInCafe: (Boolean) -> Unit,
    windowSizeMode: WindowSizeModeEnum,
    updateIsAdditionRequired: (Boolean) -> Unit
) {
    SpanText(
        text = Strings.kitchen,
        modifier = Modifier
            .color(Theme.Secondary.rgb)
            .fontFamily(FontFamily.Jakarta)
            .fontWeight(FontWeight.Normal)
            .fontSize(FontSizes.extraExtraLargeFontSize)
    )

    Column(
        modifier = Modifier
            .margin(left = Dimen.normalPadding)
    )
    {
        CheckboxComponent(
            modifier = Modifier
                .margin(top = Dimen.normalPadding),
            checkStatus = restaurantData?.isPrintOnlyInCafe ?: false,
            title = Strings.printKitchenReceiptIfInHouseActive,
            onChecked = {
                updatePrintOnlyInCafe(true)
            },
            onUnChecked = {
                updatePrintOnlyInCafe(false)
            },
            fontSize = getGeneralItemRatio(
                windowSizeMode = windowSizeMode,
                size = FontSizes.mediumFontSize,
            )
        )

        CheckboxComponent(
            modifier = Modifier.margin(top = Dimen.normalPadding),
            checkStatus = restaurantData?.isAdditionRequired ?: false,
            title = Strings.printKitchenReceiptIfInfoSelected,
            onChecked = {
                updateIsAdditionRequired(true)
            },
            onUnChecked = {
                updateIsAdditionRequired(false)
            },
            fontSize = getGeneralItemRatio(
                windowSizeMode = windowSizeMode,
                size = FontSizes.mediumFontSize,
            )
        )
    }

}