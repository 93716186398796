package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.TitleEnum
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum

@Composable
fun <T> TopTabItems(
    windowSizeMode: WindowSizeModeEnum,
    selectedTab: T,
    onTabClick: (T) -> Unit,
    tabItems: List<T>
) where T : Enum<T>, T : TitleEnum {
    Column(
        modifier = Modifier
            .fillMaxWidth()
    ) {
        Row(
            modifier = HorizontalScrollBehavior
                .toModifier()
                .weight(1f)
                .then(
                    if (windowSizeMode == WindowSizeModeEnum.PHONE)
                        HorizontalScrollBehavior
                            .toModifier().width(Dimen.mobileTabsWidth)
                    else if (windowSizeMode == WindowSizeModeEnum.TABLET) {
                        HorizontalScrollBehavior
                            .toModifier().width(Dimen.tabletTabsWidth)
                    } else HorizontalScrollBehavior
                        .toModifier()
                ),
            verticalAlignment = Alignment.CenterVertically
        ) {
            tabItems.forEach { userTab ->
                TabItem(
                    windowSizeMode = windowSizeMode,
                    title = userTab.title,
                    isActive = userTab == selectedTab,
                    onTabClick = {
                        onTabClick(userTab)
                    },
                )
            }
        }

        Box(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = Dimen.normalPadding)
                .height(Dimen.smallThickness)
                .backgroundColor(Theme.GrayAlpha12.rgb)
        )
    }
}
