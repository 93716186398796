package org.hs_soft.runmynesto.data.api_datasource.options.cash_register_config

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.GetCashRegisterConfigModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.cash_register_config.GetCashRegisterConfigRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class GetCashRegisterConfigRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): GetCashRegisterConfigRepository {
    override suspend fun getCashRegisterConfig(
        id: String,
    ): GetCashRegisterConfigModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """
                    query getCashRegister(${'$'}id: ID!) {
                      cashAssist {
                        getCashRegister(id:${'$'}id) {
                          id
                          mod_ver
                          deviceType
                          hardwareId
                          isSynced
                          parameters {
                            isTSEStillLive
                            installedPrinters
                            foreignCurrency {
                              euro {
                                exchangeRate
                                exchangeRateIsUsed
                                exchangeDateRefresh
                                __typename
                              }
                              __typename
                            }
                            __typename
                          }
                          device {
                            appManagerVersion
                            deviceStatus
                            __typename
                          }
                          ...cashRegisterMain
                          ...cashRegisterKasse
                          ...cashRegisterHardware
                          ...cashRegisterGeneral
                          ...cashRegisterExternal
                          __typename
                        }
                        __typename
                      }
                    }

                    fragment cashRegisterMain on CashRegister {
                      parameters {
                        phone
                        street
                        city
                        postCode
                        companyName
                        additionName
                        vatNumber
                        optional1
                        optional2
                        autoCloseBill
                        advertisingText
                        country
                        language
                        printer {
                          autoPrintBills
                          notPrintRFIDBills
                          printBillFromAmount
                          __typename
                        }
                        loginMode
                        loyaltyProdItemId
                        loyaltyProdItemIdOp2
                        loyaltyProdItemIdOp3
                        loyaltyProdItemIdOp4
                        loyaltyProdItemIdOp5
                        employeeLoyaltyProductId
                        smsNotificationPhoneNumbers
                        __typename
                      }
                      __typename
                    }

                    fragment cashRegisterKasse on CashRegister {
                      parameters {
                        reducedVat
                        normalVat
                        specialVat
                        isInCafeModeInt
                        isInCafeMode
                        isInCafeModeDefault
                        isActiveTimeAssist
                        loginOnlyAfterTimeRecording
                        logoutOnlyAfterTimeRecording
                        apaleoPmsProperty
                        pmsType
                        pmsProtelIP
                        pmsProtelPort
                        nonGuestTransfer
                        pmsAutoPrintBills
                        creditCards {
                          clientButtonName {
                            value
                            position
                            __typename
                          }
                          startPiecemeal {
                            value
                            position
                            __typename
                          }
                          isPiecemeal {
                            value
                            position
                            __typename
                          }
                          receiptCopies {
                            value
                            position
                            __typename
                          }
                          __typename
                        }
                      
                        isDiscountFormDisabled
                        drawerCloseCheck
                        hideOpenDrawerBtn
                        isDrawerOpenDuringClosing
                        isReturnButtonDeleted
                        designProject
                        restaurantIsMainMode
                        restaurantDefaultRoom
                        restaurantBirdViewActive
                        isHospitalityReceiptEnabled
                        isInCafeConfirmationBeforePayment
                        serviceCallMode
                        retrieveNumberOfGuests
                        closingType
                        isEmployeeClosingActive
                        priceLevelForm {
                          timeInterval {
                            from
                            to
                            __typename
                          }
                          priceLevel {
                            normal
                            inCafe
                            __typename
                          }
                          weekDays
                          __typename
                        }
                        isSellProductInWorkingTime
                        foreignCurrency {
                          euro {
                            roundTo
                            changeTo
                            exchangeRateIsUsed
                            additionPercent
                            isEuroCoinsNotShown
                            is200EuroNotShown
                            isShowTotalEuroOnCustomerDisplay
                            isActive
                            isDefinedMannualy
                            __typename
                          }
                          __typename
                        }
                        itemLoyaltyCard
                        activateTipFunction
                        egumaCreditId
                        isEgumaActive
                        guradoCreditId
                        isGuradoActive
                        preoderPrepaidProduct
                        preoderPickupProduct
                        __typename
                      }
                      __typename
                    }

                    fragment cashRegisterHardware on CashRegister {
                      installVersion
                      parameters {
                        printer {
                          name
                          ip
                          labelTitle
                          isPrintEFTReceipts
                          connectionType
                          printCartQR
                          separateKitchenBill
                          topMargin
                          defaultLabelID
                          automaticLabelPrint
                          isKitchenPrinterActive
                          isPrintKitchenBillAfterCompletionOnly
                          isPrintKitchenBillForTableOnly
                          locations {
                            position
                            isActive
                            ip
                            isDirectConnected
                            ipDeviceLabel
                            copyForWaiter
                            tableShiftReceipt
                            __typename
                          }
                          __typename
                        }
                        builtInFonts
                        localIPAdress
                        hasBarcodeByReceiptTotal
                        paymentTerminal {
                          id
                          serialNumber
                          posId
                          ip
                          port
                          sumUpEmail
                          sumUpPassword
                          creditPositionInfo {
                            position
                            __typename
                          }
                          walleeApplicationKey
                          walleeVirtualTerminalId
                          walleeUserId
                          walleeSpaceId
                          walleeTerminalID
                          __typename
                        }
                        gloryTerminalConnected
                        gloryTerminalAddress
                        gloryTerminalUser
                        gloryTerminalPassword
                        categoryFont {
                          fontName
                          size
                          __typename
                        }
                        productFont {
                          fontName
                          size
                          __typename
                        }
                        autoCloseBill
                        autoSelectFirstCategory
                        categoryGridX
                        categoryGridY
                        specialDesignType
                        cardTextColor
                        isMainScreenWithVideo
                        accentColor
                        textColor
                        backgroundColor
                        hasFastLine
                        multiLanguage
                        mainScreenLogo
                        paymentBackgroundImg
                        loyaltyCardImg
                        loyaltyCardIcon
                        takeOutImg
                        inCafeImg
                        settingsPassword
                        showOrderViewKiosk
                        useOldDesign
                        logo {
                          img {
                            uid
                            key
                            __typename
                          }
                          __typename
                        }
                        disallowLoyaltyCards
                        closingTime
                        displayEitherCategoriesOrProducts
                        idleMinutesBeforeLock
                        displayArticle
                        scale
                        scaleDisplayAlwaysVisible
                        isQRCodeBillEnabled
                        weightTara {
                          names {
                            value
                            position
                            __typename
                          }
                          values {
                            value
                            position
                            __typename
                          }
                          isAutoSelect {
                            value
                            position
                            __typename
                          }
                          __typename
                        }
                        tseIsActive
                        tseType
                        tseAdminPin
                        tseAdminPuk
                        tseTimeAdminPin
                        tseTimeAdminPuk
                        tseEpsonIp
                        tsePrintQRCode
                        isCashierHasManyDrawers
                        __typename
                      }
                      isSynced
                      deviceIdentity {
                        hardwareId
                        clientType
                        eloviewId
                        __typename
                      }
                      status
                      tseRemoteCashRegisterId
                      tseInfo {
                        info
                        expirationDate
                        __typename
                      }
                      __typename
                    }

                    fragment cashRegisterGeneral on CashRegister {
                      shopId
                      name
                      priceLevelIn
                      priceLevelOut
                      mode
                      __typename
                    }

                    fragment cashRegisterExternal on CashRegister {
                      clientNumber
                      externToolBox
                      filial
                      __typename
                    }

                    
                    
                """.trimIndent()
            )
//            Line 133
//            discountForm {
//                button1
//                buttonName1
//                button2
//                buttonName2
//                button3
//                buttonName3
//                button4
//                buttonName4
//                button5
//                buttonName5
//                __typename
//            }


            putJsonObject("variables") {
                put("id", id)
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )



        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception("Timeout or other error occurred")
        }
    }
}
