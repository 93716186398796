package org.hs_soft.runmynesto.domain.model.home.dashboard.customer_frequency


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("getCustomerFrequencies")
    val getCustomerFrequencies: List<GetCustomerFrequency?>?,
    @SerialName("__typename")
    val typename: String?
)