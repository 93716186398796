package org.hs_soft.runmynesto.data.api_datasource.customer_card.tabs.series

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.series.create_customer_card.CreateCustomerCardModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.series.CreateCustomerCardRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class CreateCustomerCardRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): CreateCustomerCardRepository
{
    override suspend fun createCustomerCard(
        type:Int,
        from:Int,
        to:Int
    ): CreateCustomerCardModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
               mutation createRangeLoyaltyCards(${'$'}ids: RangeIds, ${'$'}fields: LoyaltyCardFields, 
               ${'$'}importItems: [LoyaltyCardFields]) {
                 cashAssist {
                   createRangeLoyaltyCards(ids: ${'$'}ids, fields: ${'$'}fields
                   ,importItems: ${'$'}importItems)
                   
                        ${ApiUtils.Field.__typename}
                 }
               }

               
            """.trimIndent())

            putJsonObject("variables") {
                putJsonObject("fields"){
                   put("type",type)
                }
                putJsonObject("ids"){
                    put("from",from)
                    put("to",to)
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }
    }

}