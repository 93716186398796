package org.hs_soft.runmynesto.domain.util



object ApiUtils
{
    object Domain{
//        const val domain="https://3nfkxoeu91.execute-api.eu-central-1.amazonaws.com/dev/graphql"
        const val baseDomain="https://idl353labhivox2tpkxcjjwd7y0ityfy.lambda-url.us-east-1.on.aws/"
    }
    object API_KEY{
      const val GET_DISH_IMG_API_KEY_VALUE = "734e4c9f9fefcc645259a6d54a014680"
    }

    object Type{
        const val user="User"
    }
    object CURRENCY_TYPE{
        const val CHF="CHF"
        const val EUR="EUR"
    }
    object Status{
        const val ERR_401="401"
    }
    object Cognito{
        //Dev
//        const val USER_NAME="testhssoft@hssoft.com"
//        const val PASSWORD="bakery.2020"
//        const val API_URL="https://devapi.hssoft.com/graphql"
//        const val USER_POOL_ID="eu-west-1_HB2RoWYWY"
//        const val USER_POOL_CLIENT_ID="v5ev0902vd0m0gcdb551hu37h"
//        const val IDENTITY_POOL_ID="eu-west-1:d901e32d-9604-4851-9907-acd611a558c8"
//        const val REGION="eu-west-1"
//        const val BUCKET="cashassist-api-dev-uploads"


//        Production
        const val USER_NAME=""
        const val PASSWORD=""
        const val API_URL="https://api.hssoft.com/graphql"
        const val USER_POOL_ID="eu-west-1_ZQAKufUVZ"
        const val USER_POOL_CLIENT_ID="1t2j35i84uj8ekvr29np02n9k7"
        const val IDENTITY_POOL_ID="eu-west-1:46ef19b7-823a-4c10-a0a6-fa23d303688e"
        const val REGION="eu-west-1"
        const val BUCKET="upload.cashassist.hssoft.com"


    }


    object VirtualCardFields {
        const val ISSUER_NAME = "issuerName"
        const val ISSUER_EMAIL = "issuerEmail"
        const val PROGRAM_NAME = "programName"
        const val ACCOUNT_ID_LABEL = "accountIdLabel"
        const val REWARDS_TIER_LABEL = "rewardsTierLabel"
        const val REWARDS_TIER = "rewardsTier"
        const val SECONDARY_REWARDS_TIER_LABEL = "secondaryRewardsTierLabel"
        const val SECONDARY_REWARDS_TIER = "secondaryRewardsTier"

        const val PHONE_DESCRIPTION="linkPhoneUri_description"
        const val PHONE_LINK="linkPhoneUri_link"
        const val HOME_PAGE_DESCRIPTION="homepageUri_description"
        const val HOME_PAGE_LINK="homepageUri_link"




    }

    object Field {

        const val periodStart = "periodStart"
        const val periodEnd = "periodEnd"
        const val cashRegisterIds = "cashRegisterIds"
        const val timeCheck = "timeCheck"
        const val interval = "interval"
        const val getCustomerFrequencies = "getCustomerFrequencies"
        const val timeStart = "timeStart"
        const val timeEnd = "timeEnd"
        const val CustomerFrequenciesFragment = "CustomerFrequenciesFragment"
        const val CustomerFrequencies = "CustomerFrequencies"
        const val billCount = "billCount"
        const val totalK = "totalK"
        const val creditClientCount = "creditClientCount"
        const val creditClientTotal = "creditClientTotal"

        const val clientTree = "clientTree"
        const val regions = "regions"
        const val shops = "shops"
        const val typename = "__typename"
        const val listShops = "listShops"
        const val items = "items"
        const val regionId = "regionId"
        const val cashRegisters = "cashRegisters"
        const val isAvailableInWebApp = "isAvailableInWebApp"
        const val getAppConfig = "getAppConfig"
        const val getDashboardDSGeo = "getDashboardDSGeo"
        const val coordinates = "coordinates"
        const val googleMapUrl = "google_map_url"

        const val requestId = "requestId"
        const val cashAssist = "cashAssist"
        const val getStatisticsData = "getStatisticsData"
        const val status = "status"
        const val progress = "progress"
        const val data = "data"
        const val CustomerStatisticShopFragment = "CustomerStatisticShopFragment"
        const val StatisticShop = "StatisticShop"
        const val closingData = "closingData"
        const val cashDifference = "cashDifference"
        const val totalPerBill = "totalPerBill"
        const val customerCount = "customerCount"
        const val realHours = "realHours"
        const val totalPerRealTimeHours = "totalPerRealTimeHours"
        const val clientCount = "clientCount"
        const val productCount = "productCount"
        const val cancellationsCount = "cancellationsCount"
        const val clientCardValue = "clientCardValue"
        const val billNumStart = "billNumStart"
        const val billNumEnd = "billNumEnd"
        const val moneyCountDateTime = "moneyCountDateTime"
        const val doorCounter = "doorCounter"
        const val guestsCount = "guestsCount"
        const val inactive = "inactive"
        const val inactiveDate = "inactiveDate"
        const val inactiveReason = "inactiveReason"
        const val inactiveUserId = "inactiveUserId"
        const val mWStNormal = "mWStNormal"
        const val mWStReduced = "mWStReduced"
        const val mWStFree = "mWStFree"
        const val mWStSpecial = "mWStSpecial"
        const val reduction = "reduction"
        const val present = "present"
        const val exchangeRate = "exchangeRate"
        const val presentConverted = "presentConverted"
        const val difference = "difference"
        const val cashInTransit = "cashInTransit"
        const val amountConverted = "amountConverted"
        const val MWSt = "MWSt"
        const val sales = "sales"
        const val count = "count"
        const val have = "have"
        const val should = "should"
        const val averageBill = "averageBill"
        const val userEfficiency = "userEfficiency"
        const val cancellationsAmount = "cancellationsAmount"
        const val buttonOpenCount = "buttonOpenCount"
        const val buttonBackCount = "buttonBackCount"
        const val buttonDelCount = "buttonDelCount"
        const val buttonDelAmount = "buttonDelAmount"
        const val inCafeCount = "inCafeCount"
        const val inCafeAmount = "inCafeAmount"
        const val inCafePercent = "inCafePercent"
        const val quantity = "quantity"
        const val invoiceId = "invoiceId"

        const val totalAmount = "totalAmount"
        const val clientNumber="clientNumber"
        const val lastUpdatedAt = "lastUpdatedAt"
        const val isPointsActive = "isPointsActive"
        const val limit = "limit"
        const val dayLimit = "dayLimit"
        const val clientName = "clientName"
        const val clientSurname = "clientSurname"
        const val salutation = "salutation"
        const val additive = "additive"
        const val isUser = "isUser"
        const val userId = "userId"
        const val user = "user"
        const val postcode = "postcode"
        const val validPhoneNumber = "validPhoneNumber"
        const val email = "email"
        const val creditClientId = "creditClientId"
        const val creditClient = "creditClient"
        const val freePercent = "freePercent"
        const val masterPercent = "masterPercent"
        const val numberOfPercent = "numberOfPercent"
        const val balanceDiscount = "balanceDiscount"
        const val alwaysDiscount = "alwaysDiscount"
        const val cashLoad = "cashLoad"
        const val balancePoints = "balancePoints"
        const val statusVirtual = "statusVirtual"
        const val accessCode = "accessCode"
        const val rfid = "rfid"
        const val virtualCardLink = "virtualCardLink"
        const val phoneNumber="phoneNumber"
        const val barCode="barCode"
        const val total="total"
        const val type="type"
        const val points="points"
        const val balance="balance"
        const val topMargin="topMargin"
        const val ip="ip"
        const val ipDeviceLabel="ipDeviceLabel"
        const val tableShiftReceipt="tableShiftReceipt"
        const val copyForWaiter="copyForWaiter"
        const val isPrintKitchenBillForTableOnly="isPrintKitchenBillForTableOnly"
        const val isPrintKitchenBillAfterCompletionOnly="isPrintKitchenBillAfterCompletionOnly"
        const val printer="printer"
        const val isKitchenPrinterActive="isKitchenPrinterActive"
        const val mode="mode"
        const val isPiecemeal="isPiecemeal"
        const val priceLevelIn="priceLevelIn"
        const val priceLevelOut="priceLevelOut"



        const val separateKitchenBill = "separateKitchenBill"
        const val ID = "id"


        const val dateTime = "dateTime"
        const val groupId = "groupId"
        const val billNumber = "billNumber"
        const val userName = "userName"
        const val increase = "increase"
        const val decrease = "decrease"
        const val pointsTotal = "pointsTotal"
        const val transactionDate = "transactionDate"
        const val isCancelled = "isCancelled"
        const val isActivePointsSystem="isActivePointsSystem"
        const val pointsSystemName="pointsSystemName"
        const val TYPENAME = "__typename"
        const val standardWithoutPrinting="standardWithoutPrinting"
        const val withCashReportAndCountProtocol="withCashReportAndCountProtocol"
        const val withoutCash="withoutCash"
        const val standard="standard"
        const val withCashReport="withCashReport"
        const val receiptCopies="receiptCopies"
        const val startPiecemeal="startPiecemeal"
        const val clientButtonName="clientButtonName"
        const val button1 = "button1"
        const val buttonName1 = "buttonName1"
        const val button2 = "button2"
        const val buttonName2 = "buttonName2"
        const val button3 = "button3"
        const val buttonName3 = "buttonName3"
        const val button4 = "button4"
        const val buttonName4 = "buttonName4"
        const val button5 = "button5"
        const val buttonName5 = "buttonName5"
        const val to = "to"
        const val normal = "normal"
        const val inCafe = "inCafe"
        const val roundTo = "roundTo"
        const val changeTo = "changeTo"
        const val exchangeRateIsUsed = "exchangeRateIsUsed"
        const val additionPercent = "additionPercent"
        const val isEuroCoinsNotShown = "isEuroCoinsNotShown"
        const val is200EuroNotShown = "is200EuroNotShown"
        const val isShowTotalEuroOnCustomerDisplay = "isShowTotalEuroOnCustomerDisplay"
        const val isDefinedMannualy = "isDefinedMannualy"
        const val foreignCurrency="foreignCurrency"
        const val priceLevelForm="priceLevelForm"
        const val discountForm="discountForm"
        const val reducedVat = "reducedVat"
        const val normalVat = "normalVat"
        const val specialVat = "specialVat"
        const val isInCafeModeInt = "isInCafeModeInt"
        const val isInCafeMode = "isInCafeMode"
        const val isInCafeModeDefault = "isInCafeModeDefault"
        const val isActiveTimeAssist = "isActiveTimeAssist"
        const val loginOnlyAfterTimeRecording = "loginOnlyAfterTimeRecording"
        const val logoutOnlyAfterTimeRecording = "logoutOnlyAfterTimeRecording"
        const val apaleoPmsProperty = "apaleoPmsProperty"
        const val pmsType = "pmsType"
        const val pmsProtelIP = "pmsProtelIP"
        const val pmsProtelPort = "pmsProtelPort"
        const val nonGuestTransfer = "nonGuestTransfer"
        const val pmsAutoPrintBills = "pmsAutoPrintBills"
        const val isDiscountFormDisabled = "isDiscountFormDisabled"
        const val drawerCloseCheck = "drawerCloseCheck"
        const val hideOpenDrawerBtn = "hideOpenDrawerBtn"
        const val isDrawerOpenDuringClosing = "isDrawerOpenDuringClosing"
        const val isReturnButtonDeleted = "isReturnButtonDeleted"
        const val designProject = "designProject"
        const val restaurantIsMainMode = "restaurantIsMainMode"
        const val restaurantDefaultRoom = "restaurantDefaultRoom"
        const val restaurantBirdViewActive = "restaurantBirdViewActive"
        const val isHospitalityReceiptEnabled = "isHospitalityReceiptEnabled"
        const val isInCafeConfirmationBeforePayment = "isInCafeConfirmationBeforePayment"
        const val serviceCallMode = "serviceCallMode"
        const val retrieveNumberOfGuests = "retrieveNumberOfGuests"
        const val closingType = "closingType"
        const val isEmployeeClosingActive = "isEmployeeClosingActive"
        const val isSellProductInWorkingTime = "isSellProductInWorkingTime"
        const val itemLoyaltyCard = "itemLoyaltyCard"
        const val activateTipFunction = "activateTipFunction"
        const val egumaCreditId = "egumaCreditId"
        const val isEgumaActive = "isEgumaActive"
        const val guradoCreditId = "guradoCreditId"
        const val isGuradoActive = "isGuradoActive"
        const val preoderPrepaidProduct = "preoderPrepaidProduct"
        const val preoderPickupProduct = "preoderPickupProduct"
        const val revolutPaymentDiscount="revolutPaymentDiscount"
        const val isRevolutPaymentActive="isRevolutPaymentActive"
        const val e2nIntegrationActive="e2nIntegrationActive"
        const val e2nReportingActive="e2nReportingActive"
        const val portosIntegrationKey="portosIntegrationKey"
        const val isShopFieldActive="isShopFieldActive"
        const val mainCurrency="mainCurrency"
        const val specialProductExpenses="specialProductExpenses"
        const val creditCards="creditCards"
        const val classSuffix = "classSuffix"
        const val issuerName = "issuerName"
        const val issuerEmail = "issuerEmail"
        const val programName = "programName"
        const val accountIdLabel = "accountIdLabel"
        const val rewardsTierLabel = "rewardsTierLabel"
        const val rewardsTier = "rewardsTier"
        const val secondaryRewardsTierLabel = "secondaryRewardsTierLabel"
        const val secondaryRewardsTier = "secondaryRewardsTier"
        const val logoImg = "logoImg"
        const val wideLogoImg = "wideLogoImg"
        const val wideLogoGoogleImg = "wideLogoGoogleImg"
        const val heroGoogleImg = "heroGoogleImg"
        const val backgroundColor = "backgroundColor"
        const val foregroundColor = "foregroundColor"
        const val labelColor = "labelColor"
        const val homepageUri = "homepageUri"
        const val linkPhoneUri = "linkPhoneUri"
        const val isDefault = "isDefault"
        const val link = "link"

        const val additions="additions"
        const val accountingZeroPercent="accountingZeroPercent"
        const val accountingNormal="accountingNormal"
        const val accountingReduced="accountingReduced"
        const val discount="discount"
        const val percent="percent"
        const val variants="variants"
        const val productId="productId"
        const val after: String="after"
        const val before: String="before"
        const val hasPrevious: String="hasPrevious"
        const val hasNext: String="hasNext"
        const val amount="amount"
        const val keyword: String="keyword"
        const val ingredientId="ingredientId"
        const val contains="contains"
        const val img="img"
        const val countryId: String="countryId"
        const val description="description"
        const val weekDays="weekDays"
        const val color="color"
        const val validAfter="validAfter"
        const val surchargePercent:String="surchargePercent"
        const val surchargeFrom: String="surchargeFrom"
        const val surchargeAmount: String="surchargeAmount"
        const val priceLevels: String="priceLevels"
        const val units: String="units"
        const val group: String="group"
        const val uuid: String="uuid"
        const val hasNextPage="hasNextPage"
        const val acountNum="acountNum"
        const val value="value"
        const val dbIndex="dbIndex"
        const val isPrintOnlyInCafe="isPrintOnlyInCafe"
        const val isAdditionRequired="isAdditionRequired"
        const val isServiceCall="isServiceCall"
        const val printerLocations="printerLocations"
        const val productAddionId="productAddionId"
        const val checkOutCommandId="checkOutCommandId"
        const val clickCollectLeadDaysCount="ClickCollectLeadDaysCount"
        const val clickCollectOrderMax="ClickCollectOrderMax"
        const val clickCollectOrderMin="ClickCollectOrderMin"
        const val properties="properties"
        const val codeMWSt2="codeMWSt2"
        const val codeMWSt="codeMWSt"
        const val unitSellCode: String="unitSellCode"
        const val salesGroupId: String="salesGroupId"
        const val altName="altName"
        const val categoryId="categoryId"
        const val nestedCategoryId="nestedCategoryId"
        const val isEmployeeArticle="isEmployeeArticle"
        const val isNettoForUser="isNettoForUser"
        const val isNetto="isNetto"
        const val price="price"
        const val priceInCafe="priceInCafe"
        const val cashRegisterId: String ="cashRegisterId"
        const val priceLevel="priceLevel"
        const val tseRemoteCashRegisterId: String="tseRemoteCashRegisterId"
        const val deviceType: String="deviceType"
        const val hardwareId="hardwareId"
        const val typeRFID = "typeRFID"
        const val __typename = "__typename"
        const val cancellationPermissions = "cancelationPermissions"
        const val isTimeTracking = "isTimeTracking"
        const val isViewSales = "isViewSales"
        const val isAdmin = "isAdmin"
        const val isDayFinStatements = "isDayFinStatements"
        const val isManager = "isManager"
        const val mod_ver = "mod_ver"
        const val isFinStatements = "isFinStatements"
        const val shortName = "shortName"
        const val id = "id"
        const val name = "name"
        const val code = "code"
        const val number = "number"
        const val position = "position"
        const val password = "password"
        const val printName = "printName"
        const val isActive = "isActive"
        const val url = "url"
        const val street = "street"
        const val postCode = "postCode"
        const val city = "city"
        const val language = "language"
        const val waiterKey="waiterKey"
        const val codeExtern = "codeExtern"
        const val performance = "performance"
        const val timeTrackingId = "timeTrackingId"
        const val isPasswordOnlyForLogin = "isPasswordOnlyForLogin"
        const val shopId = "shopId"
        const val uid = "uid"
        const val key = "key"
        const val to_ = "to"
        const val from = "from"
        const val phone = "phone"
        const val companyName = "companyName"
        const val additionName = "additionName"
        const val vatNumber = "vatNumber"
        const val optional1 = "optional1"
        const val optional2 = "optional2"
        const val autoCloseBill = "autoCloseBill"
        const val advertisingText = "advertisingText"
        const val country = "country"
        const val autoPrintBills = "autoPrintBills"
        const val notPrintRFIDBills = "notPrintRFIDBills"
        const val printBillFromAmount = "printBillFromAmount"
        const val loginMode = "loginMode"
        const val loyaltyProdItemId = "loyaltyProdItemId"
        const val loyaltyProdItemIdOp2 = "loyaltyProdItemIdOp2"
        const val loyaltyProdItemIdOp3 = "loyaltyProdItemIdOp3"
        const val loyaltyProdItemIdOp4 = "loyaltyProdItemIdOp4"
        const val loyaltyProdItemIdOp5 = "loyaltyProdItemIdOp5"
        const val employeeLoyaltyProductId = "employeeLoyaltyProductId"
        const val smsNotificationPhoneNumbers = "smsNotificationPhoneNumbers"
    }

    object Response {

        const val done:String="DONE"
        const val needsMFA:String="CONFIRM_SIGN_IN_WITH_TOTP_CODE"
    }


}