package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.util.Res
import org.jetbrains.compose.web.css.percent

@Composable
fun SearchBarWithAddIcon(
    onSearchInfo: (String) -> Unit,
    onAddClick: () -> Unit,
) {
    val isAddIconHovered= remember { mutableStateOf(false) }
    Row(
        modifier = Modifier,
        verticalAlignment = Alignment.CenterVertically,
    )
    {
        CustomEditText(
            modifier = Modifier,
            label = "",
            value = "",
            endIconPath = Res.PathIcon.searchBox,
            isRequired = false,
            hint = Strings.search,
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {
                onSearchInfo(it)
            },
        )

        Box(
            modifier = Modifier
                .margin(leftRight = Dimen.normalPadding)
                .padding(Dimen.normalPadding)
                .onClick {
                    onAddClick()
                }
                .borderRadius(50.percent)
                .transitionBackground()
                .backgroundColor(
                    color = if (isAddIconHovered.value) Theme.GrayAlpha12.rgb
                    else Theme.Transparent.rgb
                )
                .onClick {

                }
                .onMouseOver { isAddIconHovered.value = true }
                .onMouseLeave { isAddIconHovered.value = false }
        ) {
            VectorIcon(
                modifier = Modifier,
                pathData = Res.PathIcon.add,
                color = Theme.Blue.hex,
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
            )

        }

    }
}