package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.filtered_transaction


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashRegister(
    @SerialName("id")
    val id: String?,
    @SerialName("name")
    val name: String?,
    @SerialName("__typename")
    val typename: String?
)