package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.general

import org.hs_soft.runmynesto.domain.config.Strings

enum class ModeEnum(
    val title:String,
    val value:Int,
) {
    STANDARD(
        title = Strings.standardInBracket,
        value = 0
    ),
    MWST_NORMAL(
        title = Strings.mwstNormal,
        value = 100
    )
}