package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_reports.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config.ListShopItem
import org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics.ShopStaticItem
import org.hs_soft.runmynesto.domain.util.Constants.Responsive.dashboardReportsHeight
import org.jetbrains.compose.web.css.vh

@Composable
fun DashboardReports(
    windowSizeMode: WindowSizeModeEnum,
    shopStaticsData: List<ShopStaticItem>?,
    listShop: List<ListShopItem?>?,
    currencyType: String,
    timeType: String,
    onItemClick: (ShopStaticItem, String) -> Unit,
)
{
    val chunk=when(windowSizeMode){
        WindowSizeModeEnum.Desktop -> 5
        WindowSizeModeEnum.TABLET -> 2
        WindowSizeModeEnum.PHONE -> 1
    }

    Column(
        modifier = VerticalScrollBehavior
            .toModifier()
            .fillMaxWidth()
            .height(dashboardReportsHeight.vh),
        horizontalAlignment = if (windowSizeMode==WindowSizeModeEnum.PHONE)
            Alignment.CenterHorizontally else Alignment.Start
    )
    {
        InfoDetail(
            shopStaticsData=shopStaticsData,
            chunk=chunk,
            windowSizeMode=windowSizeMode,
            listShop=listShop,
            currencyType=currencyType,
            timeType=timeType,
            onItemClick = { item:ShopStaticItem,name:String->
                onItemClick(
                    item,name
                )
            }
        )
    }




}


@Composable
private fun InfoDetail(
    shopStaticsData: List<ShopStaticItem>?,
    chunk: Int,
    windowSizeMode: WindowSizeModeEnum,
    listShop: List<ListShopItem?>?,
    currencyType: String,
    timeType: String,
    onItemClick: (ShopStaticItem, String) -> Unit
) {

    shopStaticsData?.chunked(chunk)?.forEach { rowItems ->

        Row(
            modifier = Modifier
                .thenIf(
                    windowSizeMode == WindowSizeModeEnum.PHONE,
                    Modifier.fillMaxWidth()
                )
        ) {

            rowItems.forEach { model ->

                DashboardReportItem(
                    name = listShop?.find {
                        it?.id == model.shopId
                    }?.name,
                    registerIdsCount = listShop?.filter { it?.id == model.shopId }
                        ?.flatMap { it?.cashRegisters.orEmpty() }
                        ?.size,
                    windowSizeMode = windowSizeMode,
                    model = model,
                    currencyType = currencyType,
                    timeType = timeType,
                    onItemClick = {
                        onItemClick(
                            model,
                            listShop?.find {
                                it?.id == model.shopId
                            }?.name ?: ""
                        )
                    }
                )
            }
        }
    }
}

