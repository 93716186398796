package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.util.rotateDayNames

@Composable
fun CalendarWeekRow() {
    val dayMap = mapOf(
        "Monday" to Strings.monday,
        "Tuesday" to Strings.tuesday,
        "Wednesday" to Strings.wednesday,
        "Thursday" to Strings.thursday,
        "Friday" to Strings.friday,
        "Saturday" to Strings.saturday,
        "Sunday" to Strings.sunday,
    )

    Row(
        modifier = Modifier
            .margin(bottom = Dimen.normalPadding)
            .width(Dimen.DateDaysWidth),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
    ) {
        // Simply iterate through the fixed order of days
        dayMap.values.forEach { dayChar ->
            DateWeekNameItem(title = dayChar)
        }
    }
}
