package org.hs_soft.runmynesto.data.api_datasource.dashboard

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics.ShopStaticModel
import org.hs_soft.runmynesto.domain.model.home.dashboard.init_shop_statics.InitShopStaticsModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.InitShopStaticsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.dashboard.ShopStaticsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils


class ShopStaticsRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): ShopStaticsRepository {

    override suspend fun getShopStatics(
        requestId:String,
    ):ShopStaticModel
    {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """
                    query getShopStatisticsData(${'$'}requestId: ID!) {
                      ${ApiUtils.Field.cashAssist} {
                        ${ApiUtils.Field.getStatisticsData}(requestId: ${'$'}requestId) {
                          ${ApiUtils.Field.status}
                          ${ApiUtils.Field.progress}
                          ${ApiUtils.Field.data} {
                            ...${ApiUtils.Field.CustomerStatisticShopFragment}
                            ${ApiUtils.Field.__typename}
                          }
                          ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.__typename}
                      }
                    }
                    
                    fragment CustomerStatisticShopFragment on StatisticShop {
                      ${ApiUtils.Field.shopId}
                      ${ApiUtils.Field.total}
                      ${ApiUtils.Field.closingData} {
                        ${ApiUtils.Field.cashDifference}
                        ${ApiUtils.Field.__typename}
                      }
                      ${ApiUtils.Field.totalPerBill}
                      ${ApiUtils.Field.customerCount}: ${ApiUtils.Field.number}
                      ${ApiUtils.Field.realHours}
                      ${ApiUtils.Field.totalPerRealTimeHours}
                      ${ApiUtils.Field.__typename}
                    }
                """.trimIndent()
            )


            putJsonObject("variables") {
                put("requestId",requestId)
            }

        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }

    }



}

