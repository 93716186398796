package org.hs_soft.runmynesto.di.product

import org.hs_soft.runmynesto.data.api_datasource.product.tabs.AddProductRepositoryImpl
import org.hs_soft.runmynesto .data.api_datasource.product.tabs.GetImageUrlRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.ProductCategoriesRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_allergens.CheckedIngredientsRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_allergens.UpdateAllergenRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_checkout.UpdateProductCheckoutRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_click_collect.UpdateProductPropertiesRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general.GetSingleProductRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general.IngredientListRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general.ProductSalesGroupRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general.UpdateProductCashBidImageRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general.UpdateProductPriceRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general.UpdateProductRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_restaurant.CreateProductAdditionRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_restaurant.KitchenInfoRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_restaurant.PrinterLocationNamesRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_restaurant.ProductRestaurantRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_restaurant.UpdateProductAdditionRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_restaurant.UpdateProductRestaurantRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.user.CheckoutDataRepositoryImpl
import org.hs_soft.runmynesto.data.local_datasource.local_storage.product.AddProductCategoryToDBRepositoryImpl
import org.hs_soft.runmynesto.data.local_datasource.local_storage.product.GetCachedProductCategoriesRepositoryImpl
import org.hs_soft.runmynesto.data.local_datasource.local_storage.product.GetCompanyProfileIdRepositoryImpl
import org.hs_soft.runmynesto.data.local_datasource.local_storage.product.SaveCachedProductCategoriesRepositoryImpl
import org.hs_soft.runmynesto.data.local_datasource.local_storage.product.SaveCompanyProfileIdRepositoryImpl
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.AddProductCategoryToDBRepository
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.GetCachedProductCategoriesRepository
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.GetCompanyProfileIdRepository
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.SaveCachedProductCategoriesRepository
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.SaveCompanyProfileIdRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.AddProductRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.ImgUrlRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.ProductCategoriesRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_allergens.CheckedIngredientsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_allergens.UpdateAllergenRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_checkout.UpdateProductCheckoutRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_click_collect.UpdateProductPropertiesRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.IngredientListRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.ProductSaleGroupRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.SingleProductRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.UpdateProductCashBidImageRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.UpdateProductPriceRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.UpdateProductRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.CreateProductAdditionRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.KitchenInfoRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.PrinterLocationNamesRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.ProductRestaurantRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.UpdateProductAdditionRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.UpdateProductRestaurantRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.CheckoutDataRepository
import org.koin.dsl.module

val productRepositoryModule= module {

    single<CheckedIngredientsRepository> {
        CheckedIngredientsRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<UpdateAllergenRepository> {
        UpdateAllergenRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<CheckoutDataRepository> {
        CheckoutDataRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<UpdateProductCheckoutRepository> {
        UpdateProductCheckoutRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<UpdateProductPropertiesRepository> {
        UpdateProductPropertiesRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }


    single<IngredientListRepository> {
        IngredientListRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<UpdateProductCashBidImageRepository> {
        UpdateProductCashBidImageRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }


    single<UpdateProductRepository> {
        UpdateProductRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<ProductSaleGroupRepository>{
        ProductSalesGroupRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<SingleProductRepository> {
        GetSingleProductRepositoryImpl(
            apiClient =get(),
            requestManager = get(),
        )
    }


    single<KitchenInfoRepository> {
        KitchenInfoRepositoryImpl(
            apiClient =get(),
            requestManager = get(),
        )
    }

    single<ProductRestaurantRepository> {
        ProductRestaurantRepositoryImpl(
            apiClient =get(),
            requestManager = get(),
        )
    }


    single<PrinterLocationNamesRepository> {
        PrinterLocationNamesRepositoryImpl(
            apiClient =get(),
            requestManager = get(),
        )
    }

    single<UpdateProductRestaurantRepository> {
        UpdateProductRestaurantRepositoryImpl(
            apiClient =get(),
            requestManager = get(),
        )
    }


    single<AddProductRepository> {
        AddProductRepositoryImpl(
            apiClient =get(),
            requestManager = get(),
        )
    }


    single<ProductCategoriesRepository> {
        ProductCategoriesRepositoryImpl(
            apiClient =get(),
            requestManager = get(),
        )
    }

    single<ImgUrlRepository> {
        GetImageUrlRepositoryImpl()
    }

    single<UpdateProductPriceRepository> {
        UpdateProductPriceRepositoryImpl(
            apiClient=get(),
            requestManager = get(),
        )
    }

    single<GetCompanyProfileIdRepository>{
        GetCompanyProfileIdRepositoryImpl()
    }


    single<SaveCompanyProfileIdRepository>{
        SaveCompanyProfileIdRepositoryImpl()
    }



    single<AddProductCategoryToDBRepository>{
        AddProductCategoryToDBRepositoryImpl()
    }

    single<GetCachedProductCategoriesRepository>{
        GetCachedProductCategoriesRepositoryImpl()
    }

    single<SaveCachedProductCategoriesRepository>{
        SaveCachedProductCategoriesRepositoryImpl()
    }


    single<CreateProductAdditionRepository>{
        CreateProductAdditionRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<UpdateProductAdditionRepository>{
        UpdateProductAdditionRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }
}