package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.operation

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.CountriesEnum
import org.hs_soft.runmynesto.domain.model.home.options.LanguageEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.Parameters
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.editTextBoxBackground
import org.hs_soft.runmynesto.domain.util.editTextLabelBackground
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.CustomTextArea
import org.hs_soft.runmynesto.pages.components.DropdownComponent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun OperationTabCashRegisterConfig(
    data: Parameters?,
    windowSizeMode: WindowSizeModeEnum,
    updateData: (String, dynamic) -> Unit,
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    ) {

        if (windowSizeMode== WindowSizeModeEnum.WINDOWS){
            Row()
            {
                content(
                    data=data,
                    windowSizeMode =windowSizeMode,
                    updateData = { title:String, value:dynamic->
                        updateData(title,value)
                    }
                )

            }

        }else{
            Column(
                modifier = VerticalScrollBehavior
                    .toModifier()
                    .fillMaxHeight()
            )
            {
                content(
                    data=data,
                    windowSizeMode =windowSizeMode,
                    updateData = { title:String, value:dynamic->
                        updateData(title,value)
                    }
                )


            }
        }


    }

}


@Composable
private fun content(
    windowSizeMode: WindowSizeModeEnum,
    data: Parameters?,
    updateData: (String, dynamic) -> Unit
) {
    Column(
        modifier = Modifier.margin(right = Dimen.normalPadding)
    )
    {
        CustomEditText(
            modifier = Modifier,
            label = Strings.companyName,
            value = data?.companyName ?: "",
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {
                updateData(ApiUtils.Field.companyName, it)
            },
            onInputChange = {

            },
        )


        CustomEditText(
            modifier = Modifier,
            label = Strings.street,
            value = data?.street ?: "",
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            isOutlinedEditText = true,

            onFocusIn = {

            },
            onFocusOut = {
                updateData(ApiUtils.Field.street, it)
            },
            onInputChange = {

            },
        )

        Row()
        {
            CustomEditText(
                modifier = Modifier,
                width = Dimen.smallEditTextWidth,
                label = Strings.zip,
                value = data?.postCode ?: "",
                defaultBorderColor = Theme.GrayCD.rgb,
                hint = "",
                isOutlinedEditText = true,
                onFocusIn = {

                },
                onFocusOut = {
                    updateData(ApiUtils.Field.postCode, it)
                },
                onInputChange = {

                },
            )

            Box(modifier = Modifier.padding(Dimen.smallPadding))

            CustomEditText(
                modifier = Modifier,
                width = (Dimen.mediumEditTextWidth.value.toInt()
                        - Dimen.normalPadding.value.toInt()
                        - Dimen.smallEditTextWidth.value.toInt()).px,
                label = Strings.city,
                value = data?.city ?: "",
                defaultBorderColor = Theme.GrayCD.rgb,
                hint = "",
                isOutlinedEditText = true,
                onFocusIn = {

                },
                onFocusOut = {
                    updateData(ApiUtils.Field.city, it)
                },
                onInputChange = {

                },
            )
        }

        CustomEditText(
            modifier = Modifier,
            label = Strings.vatNumber,
            value = data?.vatNumber ?: "",
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            isOutlinedEditText = true,

            onFocusIn = {

            },
            onFocusOut = {
                updateData(ApiUtils.Field.vatNumber, it)
            },
            onInputChange = {

            },
        )
        CustomEditText(
            modifier = Modifier,
            label = Strings.telephoneNumber,
            value = data?.phone ?: "",
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            isOutlinedEditText = true,
            onFocusIn = {

            },
            onFocusOut = {
                updateData(ApiUtils.Field.phone, it)
            },
            onInputChange = {

            },
        )


        DropdownComponent(
            modifier = Modifier,
            options = LanguageEnum.entries,
            label = Strings.language,
            selectedItem = LanguageEnum.fromDisplayName(data?.language ?: "")
                ?: LanguageEnum.English,
            content = {
                Text(it)
            },
            onOptionClick = {
                updateData(ApiUtils.Field.language, it)
            },
        )


        DropdownComponent(
            modifier = Modifier.margin(top = Dimen.mediumPadding),
            options = CountriesEnum.entries,
            label = Strings.companyProfile,
            selectedItem = CountriesEnum.entries.find {
                it.id == data?.country.toString()
            },
            content = {
                Text(it)
            },
            onOptionClick = {

                val option: CountriesEnum? = CountriesEnum.entries.find { item ->
                    item.toString() == it
                }

                if (option != null) {
                    updateData(ApiUtils.Field.countryId, option.id)
                }
            },
        )
    }

    Column(
        modifier = Modifier.
        margin(top = if (windowSizeMode!=WindowSizeModeEnum.WINDOWS)
            Dimen.mediumPadding else  0.px
        )
    ) {

        CustomTextArea(
            modifier = Modifier.margin(top = Dimen.normalPadding),
            value = data?.advertisingText ?: "",
            label = Strings.advertisingText,
            width = Dimen.selectRectPhoneWidth,
            height = Dimen.normalTextAreaHeight,
            maxHeight = Dimen.normalTextAreaHeight,
            onFocusOut = {
                updateData(ApiUtils.Field.advertisingText, it)
            },
            onValueChange = {

            }
        )



        CustomTextArea(
            modifier = Modifier.margin(top = Dimen.normalPadding),
            value = data?.additionName ?: "",
            label = Strings.addition,
            width = Dimen.selectRectPhoneWidth,
            height = Dimen.normalTextAreaHeight,
            maxHeight = Dimen.normalTextAreaHeight,
            onFocusOut = {
                updateData(ApiUtils.Field.additionName, it)
            },
            onValueChange = {

            }
        )

        CustomTextArea(
            modifier = Modifier.margin(top = Dimen.normalPadding),
            value = data?.optional1 ?: "",
            label = Strings.optionalOne,
            width = Dimen.selectRectPhoneWidth,
            height = Dimen.normalTextAreaHeight,
            maxHeight = Dimen.normalTextAreaHeight,
            onFocusOut = {
                updateData(ApiUtils.Field.optional1, it)
            },
            onValueChange = {

            }
        )

        CustomTextArea(
            modifier = Modifier.margin(top = Dimen.normalPadding),
            value = data?.optional2 ?: "",
            label = Strings.optionalTwo,
            width = Dimen.selectRectPhoneWidth,
            height = Dimen.normalTextAreaHeight,
            maxHeight = Dimen.normalTextAreaHeight,
            onFocusOut = {
                updateData(ApiUtils.Field.optional2, it)
            },
            onValueChange = {

            }
        )

    }
}