package org.hs_soft.runmynesto.domain.usecase.options.cash_register_config

import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.GetCashRegisterConfigModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.cash_register_config.GetCashRegisterConfigRepository


class GetCashRegisterConfigUseCase(
    private val repository: GetCashRegisterConfigRepository
) {
    suspend operator fun invoke(id:String): GetCashRegisterConfigModel {
        return repository.getCashRegisterConfig(id)
    }
}