package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.restaurant_data


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RestaurantDataModel(
    @SerialName("data")
    val data: Data
)