package org.hs_soft.runmynesto.domain.model.home.options

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.TitleEnum

enum class OptionInfoHeaders(override val title: String): TitleEnum
{
    OPERATION(Strings.operation),
    SALES_GROUP(Strings.salesGroups ),
    KITCHEN_LOCATIONS(Strings.kitchenLocations ),
    CASH_FLOW(Strings.cashFlow ),
    VIRTUAL_CARD(Strings.virtualCard ),
    CASH_REGISTER_CONFIG(Strings.cashRegisterConfig),
    MENU_CARD_IMPORT(Strings.menuCardImport ),
    RESTAURANT_MANAGER(Strings.restaurantManager )

}