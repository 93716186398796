package org.hs_soft.runmynesto.di.user

import org.hs_soft.runmynesto.data.api_datasource.user.AddUserRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.user.CheckoutDataRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.user.GetUsersDataRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.user.SingleUserRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.user.UpdateUserDataRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.user.tab_checkout.UpdateUserCheckoutRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.user.tab_right.UpdateUserPermissionRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.user.tab_right.UpdateUserWaiterKeyRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.user.tab_general.UpdateUserImageRepositoryImpl
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.AddUserRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.CheckoutDataRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.SingleUserRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.UpdateUserDataRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.UsersDataRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.tab_checkout.UpdateUserCheckoutRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.tab_right.UpdateUserPermissionsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.tab_right.UpdateUserWaiterKeyRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.tab_general.UpdateUserImageRepository
import org.koin.dsl.module

val userRepositoryModule= module {

    single<UpdateUserCheckoutRepository> {
        UpdateUserCheckoutRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<UpdateUserPermissionsRepository> {
        UpdateUserPermissionRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<AddUserRepository> {
        AddUserRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }


    single<CheckoutDataRepository> {
        CheckoutDataRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<SingleUserRepository> {
        SingleUserRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }
    single<UsersDataRepository> {
        GetUsersDataRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<UpdateUserWaiterKeyRepository>{
        UpdateUserWaiterKeyRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }


    single<UpdateUserImageRepository>{
        UpdateUserImageRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<UpdateUserDataRepository>{
        UpdateUserDataRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }


}