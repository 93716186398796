package org.hs_soft.runmynesto.domain.model.home.product.product_item_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetProduct(
    @SerialName("cashRegisterId")
    val cashRegisterId: List<String>,
    @SerialName("id")
    val id: String,
    @SerialName("isActive")
    val isActive: Boolean,
    @SerialName("name")
    val name: String,
    @SerialName("code")
    val code:String?="",
    @SerialName("isNetto")
    val isNetto: Boolean?=false,
    @SerialName("isNettoForUser")
    val isNettoForUser: Boolean?=false,
    @SerialName("isEmployeeArticle")
    val isEmployeeArticle:Boolean?=false,
    @SerialName("price")
    val price: List<Double?> ?,
    @SerialName("priceInCafe")
    val priceInCafe: List<Double?> ?,

    @SerialName("properties")
    val properties: Properties,

    @SerialName("salesGroupId")
    val salesGroupId:String?,


    @SerialName("codeMWSt")
    val codeMWSt: String?,
    @SerialName("codeMWSt2")
    val codeMWSt2: String?,


    @SerialName("img")
    val img: Img?,



//    @SerialName("altName")
//    val altName: Any,
//    @SerialName("bakeryReturn")
//    val bakeryReturn: List<Any>,
//    @SerialName("bakeryReturnEndOfDay")
//    val bakeryReturnEndOfDay: Any,
//    @SerialName("bakeryStock")
//    val bakeryStock: Any,
//    @SerialName("canCopy")
//    val canCopy: Any,
//    @SerialName("cardChargeAmount")
//    val cardChargeAmount: Any,
//    @SerialName("cardSurchargeFrom")
//    val cardSurchargeFrom: List<Any>,
//    @SerialName("cardSurchargePercent")
//    val cardSurchargePercent: List<Any>,




//    @SerialName("category")
//    val category: Category,
//    @SerialName("categoryId")
//    val categoryId: String,
//    @SerialName("checkOutCommandId")
//    val checkOutCommandId: String,
//    @SerialName("code")
//    val code: Any,
//    @SerialName("codeMWSt")
//    val codeMWSt: String,
//    @SerialName("codeMWSt2")
//    val codeMWSt2: Any,
//    @SerialName("discount")
//    val discount: List<Any>,
//    @SerialName("EANCode")
//    val eANCode: List<Any>,
//    @SerialName("FA_ProductId")
//    val fAProductId: Any,
//    @SerialName("group1Id")
//    val group1Id: Any,
//    @SerialName("group2Id")
//    val group2Id: Any,
//    @SerialName("group3Id")
//    val group3Id: Any,
//    @SerialName("halfNotAllowed")
//    val halfNotAllowed: Boolean,

//    @SerialName("imgKiosk")
//    val imgKiosk: Any,
//    @SerialName("intervals")
//    val intervals: List<Any>,
//    @SerialName("inventoryUnit")
//    val inventoryUnit: String,
//    @SerialName("isEmployeeArticle")
//    val isEmployeeArticle: Boolean,
//    @SerialName("isInventory")
//    val isInventory: Boolean,
//    @SerialName("isMenuName")
//    val isMenuName: Boolean,
//    @SerialName("isTareInLabelMode")
//    val isTareInLabelMode: Boolean,
//    @SerialName("isWarehouse")
//    val isWarehouse: Any,
//    @SerialName("isWeighted")
//    val isWeighted: Any,
//    @SerialName("linkExpense")
//    val linkExpense: Int,
//    @SerialName("lists")
//    val lists: List<Any>,
//    @SerialName("minOrder")
//    val minOrder: Any,
//    @SerialName("mod_ver")
//    val modVer: Int,
//    @SerialName("nameInOrder")
//    val nameInOrder: Any,
//    @SerialName("nestedCategoryId")
//    val nestedCategoryId: Any,
//    @SerialName("numberPerUnit")
//    val numberPerUnit: Int,
//    @SerialName("orderUnit")
//    val orderUnit: Int,
//    @SerialName("points")
//    val points: Points,
//    @SerialName("pointsCost")
//    val pointsCost: Any,
//    @SerialName("pointsPriceLevel")
//    val pointsPriceLevel: Any,


//    @SerialName("price")
//    val price: List<Double?> ?,
//    @SerialName("priceInCafe")
//    val priceInCafe: List<Double?>,



//    @SerialName("salesGroupId")
//    val salesGroupId: String,
//    @SerialName("shopId")
//    val shopId: Any,
//    @SerialName("specialPrices")
//    val specialPrices: List<Any>,
//    @SerialName("tare")
//    val tare: List<Any>,
//    @SerialName("__typename")
//    val typename: String,
//    @SerialName("unitSellCode")
//    val unitSellCode: String,
//    @SerialName("visibleStatus")
//    val visibleStatus: Any,
//    @SerialName("waste")
//    val waste: Boolean
)