package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.calendar_filter_dialog.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun YearPart(
    title:String,
    isClicked:Boolean,
    modifier: Modifier = Modifier,
    onItemClick:()->Unit,
)
{
    val isHovered= remember{ mutableStateOf(false) }
    SpanText(
        text = title,
        modifier = modifier
            .cursor(Cursor.Pointer)
            .backgroundColor(
                if (isClicked) Theme.Blue.rgb
                else if (isHovered.value) Theme.ExtraLightBlue.rgb
                else Theme.Transparent.rgb
            )
            .margin(Dimen.smallPadding)
            .rippleEffect {
                onItemClick()
            }
            .color(
                if (isClicked) Theme.White.rgb
                else Theme.Blue.rgb
            )
            .onMouseOver { isHovered.value=true }
            .onMouseLeave { isHovered.value=false }
            .fillMaxWidth()
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.Blue.rgb
            )
            .textAlign(TextAlign.Center)
            .borderRadius(Dimen.lowBorderRadius)
            .fontSize(FontSizes.semiMediumFontSize)
            .fontWeight(FontWeight.Medium)
            .fontFamily(FontFamily.Montserrat)
            .padding(
                topBottom = Dimen.normalPadding,
                leftRight = Dimen.mediumPadding
            )

    )
}