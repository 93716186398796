package org.hs_soft.runmynesto.domain.model.home.options.cash_flow.app_config_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashRegisterGlobal(
    @SerialName("isActivePointsSystem")
    val isActivePointsSystem: Boolean?,
    @SerialName("pointsSystemName")
    val pointsSystemName: String?,
    @SerialName("printerLocations")
    val printerLocations: List<PrinterLocation?>?,
    @SerialName("__typename")
    val typename: String?
)