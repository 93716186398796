package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_click_collect

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_click_collect.update_properties.UpdatePropertiesModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_click_collect.UpdateProductPropertiesRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateProductPropertiesRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateProductPropertiesRepository {


    override suspend fun updateProductProperties(
        productId: String,
        minOrder: Double?,
        maxOrder: Double?,
        timeLeadDays: Double?
    ): UpdatePropertiesModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
           mutation updateProductProperties(${'$'}productId: ID!,
            ${'$'}fields: ProductPropertiesInput!) {
              cashAssist {
                updateProductProperties(productId: ${'$'}productId, fields: ${'$'}fields) {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.mod_ver}
                  ...properties
                  ${ApiUtils.Field.__typename}
                }
                 ${ApiUtils.Field.__typename}
              }
            }
            
            fragment properties on Product {
              properties { 
                ${ApiUtils.Field.clickCollectOrderMin}
                ${ApiUtils.Field.clickCollectOrderMax}
                ${ApiUtils.Field.clickCollectLeadDaysCount}
                ${ApiUtils.Field.__typename}
              }
              ${ApiUtils.Field.__typename}
            }
            
        """.trimIndent())



            putJsonObject("variables") {
                put("productId", productId)
                putJsonObject("fields") {
                    put(ApiUtils.Field.clickCollectOrderMin,minOrder)
                    put(ApiUtils.Field.clickCollectOrderMax, maxOrder)
                    put(ApiUtils.Field.clickCollectLeadDaysCount,timeLeadDays)
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<UpdatePropertiesModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }

    }

}