package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.set_grid_product


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SetGridProduct(
    @SerialName("categoryId")
    val categoryId: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("position")
    val position: Int?,
    @SerialName("product")
    val product: Product?,
    @SerialName("productId")
    val productId: String?,
    @SerialName("__typename")
    val typename: String?
)