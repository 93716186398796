package org.hs_soft.runmynesto.pages.components.dialog.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum

@Composable
fun SubmitButtons(
    arrangement: Arrangement.Horizontal,
    onCancelClick: () -> Unit,
    onSubmitClick: () -> Unit,
    isSubmitClickable: Boolean,
    windowSize: WindowSizeModeEnum,
)
{
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .margin(top = Dimen.mediumPadding),
        horizontalArrangement = arrangement
    )
    {
        CustomButton(
            windowSizeMode =windowSize,
            onButtonClick ={
                onCancelClick()
            },
            borderColor = Theme.Blue.rgb,
            defaultTextColor = Theme.Blue.rgb,
            hoveredTextColor = Theme.Blue.rgb,
            clickableTextColor = Theme.Blue.rgb,
            backgroundColor = Theme.White.rgb,
            hoveredBackgroundColor = Theme.GrayAlpha12.rgb,
            title = Strings.cancel,

        )

        CustomButton(
            windowSizeMode =windowSize,
            isClickable =isSubmitClickable,
            title = Strings.confirm,
            onButtonClick ={
                onSubmitClick()
            },
            backgroundColor = Theme.Blue.rgb,
            shadowColor =  Theme.DarkBlue.rgb,
            defaultTextColor = Theme.White.rgb,
            hoveredBackgroundColor = Theme.DarkBlue.rgb,
        )



    }
}