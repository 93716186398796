package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Resize
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onFocusIn
import com.varabyte.kobweb.compose.ui.modifiers.onFocusOut
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.resize
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.TextArea
import org.w3c.dom.HTMLTextAreaElement

@Composable
fun CustomTextArea(
    modifier: Modifier=Modifier,
    value: String,
    label:String,
    width: CSSSizeValue<CSSUnit.px> = Dimen.mediumTextAreaWidth,
    height: CSSSizeValue<CSSUnit.px> = Dimen.largeTextAreaHeight,
    maxHeight: CSSSizeValue<CSSUnit.px> = (3 * (height.value.toInt())).px,
    onFocusOut: (String) -> Unit,
    onValueChange: (String) -> Unit
) {
    val inputValue = remember { mutableStateOf(value) }
    val isTextChanged = remember { mutableStateOf(false) }
    val isFocused= remember { mutableStateOf(false) }
    val isHovered= remember { mutableStateOf(false) }


    LaunchedEffect(value) {
        inputValue.value = value
    }
    Box(
        modifier = modifier
            .width(width)
            .height(
                (height.value.toInt()+Dimen.normalPadding.value.toInt()).px
            )
    ) {
        SpanText(
            text = label,
            modifier = Modifier
                .align(Alignment.TopStart)
                .zIndex(1)
                .padding(leftRight = Dimen.smallPadding)
                .backgroundColor(Theme.White.rgb)
                .margin(
                    left = Dimen.mediumPadding
                )
                .color(Theme.Gray3E.rgb)
                .fontFamily(FontFamily.Montserrat)
                .fontSize(FontSizes.normalFontSize)
        )

        TextArea(
            attrs = Modifier
                .margin(topBottom = Dimen.normalPadding)
                .width(width)
                .height(height)
                .resize(Resize.Vertical)
                .onMouseOver { isHovered.value = true }
                .onMouseLeave { isHovered.value = false }
                .onFocusIn {
                    isFocused.value = true
                }
                .onFocusOut {
                    isFocused.value = false
                    isFocused.value = false
                    if (isTextChanged.value) {
                        onFocusOut(inputValue.value)
                        isTextChanged.value = false
                    }
                }
                .maxHeight(maxHeight)
                .border(
                    width = Dimen.smallThickness,
                    color = if (isFocused.value) Theme.Blue.rgb
                    else if (isHovered.value && !isFocused.value) Theme.Primary.rgb
                    else Theme.GrayCD.rgb,
                    style = LineStyle.Solid,
                )
                .boxShadow(color = Theme.GrayAlpha12.rgb)
                .padding(all = Dimen.mediumPadding)
                .fontFamily(FontFamily.Montserrat)
                .fontSize(FontSizes.mediumFontSize)
                .borderRadius(r = Dimen.lowBorderRadius)
                .toAttrs {
                    value(inputValue.value)
                    onInput { event ->
                        val target = event.target as? HTMLTextAreaElement
                        inputValue.value = target?.value ?: ""
                        isTextChanged.value = true

                        onValueChange(inputValue.value)
                    }
                }
        )
    }


}