package org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetAppConfig(
    @SerialName("language")
    val language: String?,
    @SerialName("mainCurrency")
    val mainCurrency: String?,
    @SerialName("__typename")
    val typename: String?
)