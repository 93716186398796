package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.util.getEndOfHalfYear
import org.hs_soft.runmynesto.domain.util.getEndOfQuarter
import org.hs_soft.runmynesto.domain.util.getEndOfYear
import org.hs_soft.runmynesto.domain.util.getStartOfHalfYear
import org.hs_soft.runmynesto.domain.util.getStartOfQuarter
import org.hs_soft.runmynesto.domain.util.getStartOfYear

enum class PeriodYearEnum(
    val title: String,
    val from: String,
    val to: String,
) {
    ONE_HALF_A_YEAR(
        title = Strings.oneHalfAYear,
        from = getStartOfYear(0),
        to = getEndOfHalfYear(1)
    ),
    TWO_HALF_A_YEAR(
        title = Strings.twoHalfAYear,
        from = getStartOfHalfYear(1),
        to = getEndOfYear(0)
    ),
    ONE_QUARTER(
        title = Strings.oneQuarter,
        from = getStartOfQuarter(1),
        to = getEndOfQuarter(1)
    ),
    TWO_QUARTERS(
        title = Strings.twoQuarters,
        from = getStartOfQuarter(2),
        to = getEndOfQuarter(2)
    ),
    THREE_QUARTERS(
        title = Strings.threeQuarters,
        from = getStartOfQuarter(3),
        to = getEndOfQuarter(3)
    ),
    FOUR_QUARTERS(
        title = Strings.fourQuarters,
        from = getStartOfQuarter(4),
        to = getEndOfQuarter(4)
    ),
}