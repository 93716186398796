package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_click_collect.update_properties


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateProductProperties(
    @SerialName("id")
    val id: String,
    @SerialName("mod_ver")
    val modVer: Int,
    @SerialName("properties")
    val properties: UpdatedProperties,
    @SerialName("__typename")
    val typename: String
)