package org.hs_soft.runmynesto.domain.usecase.customer_card

import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail.LoyaltyCardDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.LoyaltyCardDetailRepostiory

class LoyaltyCardDetailUseCase(
    private val repository: LoyaltyCardDetailRepostiory
)
{
    suspend operator fun invoke(id:String): LoyaltyCardDetailModel {
        return repository.getLoyaltyCardDetail(
            id=id
        )
    }

}