package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Res
import org.jetbrains.compose.web.css.percent


@Composable
fun AddItem(
    onAddRecordClick: () -> Unit,
) {

    val isHovered= remember { mutableStateOf(false) }
    Box(
        modifier = Modifier
            .onClick {
                event: SyntheticMouseEvent -> event.stopPropagation()
                onAddRecordClick()
            }
            .padding(Dimen.normalPadding)
            .borderRadius(50.percent)
            .onMouseOver {
                isHovered.value = true
            }
            .onMouseLeave {
                isHovered.value = false
            }
            .backgroundColor(
                if (isHovered.value) {
                    Theme.GrayAlpha04.rgb
                } else {
                    Theme.Transparent.rgb
                }
            )
    ) {
        VectorIcon(
            modifier = Modifier,
            pathData = Res.PathIcon.add,
            width = Dimen.mediumIconSize,
            height = Dimen.mediumIconSize,
            color = Theme.GrayAlpha56.hex,
        )
    }
}