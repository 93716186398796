package org.hs_soft.runmynesto.domain.usecase.product.tab_product_general

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group.SaleGroupModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.ProductSaleGroupRepository


class ProductSaleGroupUseCase(
    private val repository: ProductSaleGroupRepository
) {
    suspend operator fun invoke(): SaleGroupModel {
        return repository.getProductSaleGroup()
    }


}