package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.create_product_addition


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.kitchen_info.ProductAdditions

@Serializable
data class CashAssist(
    @SerialName("createProductAddition")
    val createProductAddition: ProductAdditions?=null,
    @SerialName("updateProductAddition")
    val updateProductAddition: ProductAdditions?=null,

)