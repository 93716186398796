package org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("children")
    val children: List<Children>?,
    @SerialName("__typename")
    val typename: String?
)