package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.series.update_range_customer_card


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateRangeLoyaltyCardModel(
    @SerialName("data")
    val `data`: Data?
)