package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.overflowText
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.jetbrains.compose.web.css.percent

@Composable
fun TabItem(
    title: String,
    isActive: Boolean,
    onTabClick: () -> Unit,
    windowSizeMode: WindowSizeModeEnum,
)
{
    val isHovered= remember { mutableStateOf(false) }
    Box(
        modifier = Modifier
            .thenIf(
                windowSizeMode!=WindowSizeModeEnum.Desktop,
                       Modifier.minWidth(Dimen.tabItemWidth)
            )
            .rippleEffect {
                onTabClick()
            }
            .height(Dimen.userTabHeight)
            .cursor(Cursor.Pointer)
            .onMouseEnter { isHovered.value=true}
            .onMouseLeave { isHovered.value=false }
    )
    {
        SpanText(
            text = title,
            modifier = Modifier
                .textAlign(TextAlign.Center)
                .fillMaxWidth()
                .overflowText()
                .align(Alignment.Center)
                .padding(
                    leftRight = Dimen.mediumPadding,
                )
                .fontSize(FontSizes.mediumFontSize)
                .fontWeight(FontWeight.Medium)
                .color(
                    when {
                        isActive -> Theme.Blue.rgb
                        isHovered.value
                        -> Theme.LightBlue.rgb
                        else -> Theme.GrayAlpha56.rgb
                    }
                )
                .fontFamily(FontFamily.Jakarta)
        )

        Box(
            modifier = Modifier
                .height(Dimen.mediumThickness)
                .transitionBackground()
                .width(100.percent)
                .align(Alignment.BottomCenter)
                .backgroundColor(
                    if (isActive) Theme.Blue.rgb
                    else if (isHovered.value) Theme.LightBlue.rgb
                    else Theme.Transparent.rgb
                )

        )

    }


}