package org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.cache

import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.AddProductCategoryToDBRepository

class AddProductCategoryToDBUseCase(
    private val repository: AddProductCategoryToDBRepository
) {
    operator fun invoke(
        item:ProductItem,
    ){
        repository.addItem(item)
    }


}