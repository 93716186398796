package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config.GetVirtualCardConfig
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.IdUtils
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.jetbrains.compose.web.css.px

@Composable
fun GoogleWallet(
    info: GetVirtualCardConfig?,
    event: (OptionsEvent) -> Unit,
    windowSizeMode: WindowSizeModeEnum
){
    Column(
        modifier = Modifier
            .margin(leftRight = Dimen.normalPadding)
    ) {
        MediumContentTitle(
            title = Strings.googleWallet,
            marginTop = Dimen.mediumPadding,
            marginBottom = Dimen.mediumPadding,
        )

        WalletImgContent(
            title = Strings.wideCompanyLogo,
            img = info?.wideLogoGoogleImg?:Res.PathIcon.defaultRectPhoto,
            width = Dimen.selectRectPhoneWidth,
            height =Dimen.selectRectPhoneHeight,
            imgId = IdUtils.wideCompanyLogo,
            updateImg = {
                event(OptionsEvent.UpdateVirtualCardInfo(
                    dataTitle = ApiUtils.Field.wideLogoGoogleImg,
                    dataValue = it
                ))
            }
        )


        WalletImgContent(
            modifier = Modifier
                .margin(top=Dimen.mediumPadding),
            title = Strings.heroImage,
            img = info?.heroGoogleImg?:Res.PathIcon.defaultRectPhoto,
            width = Dimen.selectRectPhoneWidth,
            height =Dimen.selectRectPhoneHeight,
            imgId = IdUtils.heroGoogleImg,
            updateImg = {
                event(OptionsEvent.UpdateVirtualCardInfo(
                    dataTitle = ApiUtils.Field.heroGoogleImg,
                    dataValue = it
                ))
            }
        )

        CustomButton(
            title = Strings.preview,
            shadowColor = Theme.DarkBlue.rgb,
            leftRightMargin = 0.px,
            topBottomMargin = Dimen.mediumPadding,
            defaultTextColor = Theme.Secondary.rgb,
            windowSizeMode = windowSizeMode,
            onButtonClick = {
                event(OptionsEvent.OnPreviewGoogleWalletClick)
            },
        )

    }


}