package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.grid_customer_card_bar.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.hoverBackgroundStyle
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card.LoyaltyCardItem
import org.hs_soft.runmynesto.domain.util.Constants
import org.jetbrains.compose.web.css.Position

@Composable
fun CustomerCardGridItem(
    model: LoyaltyCardItem?,
    isSelected: Boolean,
    onItemClick: (String) -> Unit,
)
{
    val isHovered=  remember { mutableStateOf(false) }

    Column(
        modifier = hoverBackgroundStyle
            .toModifier()
            .position(Position.Relative)
            .fillMaxWidth()
            .transitionBackground()
            .zIndex(Constants.ZIndex.normalZIndex)
            .scrollBehavior(ScrollBehavior.Smooth)
            .backgroundColor(
                when {
                    isSelected -> Theme.GrayAlpha12.rgb
                    isHovered.value -> Theme.GrayAlpha08.rgb
                    else -> Theme.Transparent.rgb
                }
            )
            .thenIf(
                isSelected,
                Modifier.onClick {
                    onItemClick(model?.id?:"")
                }
            )
            .thenIf(
                !isSelected,
                Modifier.onClick {
                    onItemClick(model?.id?:"")
                }
            )
            .onMouseOver { isHovered.value=true }
            .onMouseLeave { isHovered.value=false }


    ) {
        Row(
            modifier = Modifier
                .padding(
                    leftRight = Dimen.normalPadding,
                    topBottom = Dimen.smallPadding
                )
                .fillMaxWidth()
                .cursor(Cursor.Pointer)
                .height(Dimen.gridHeaderHeight),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            SpanText(
                text = model?.id?:"",
                modifier = Modifier
                    .padding(right = Dimen.mediumPadding)
                    .width(Dimen.gridCustomerCardNumberWidth)
                    .cursor(Cursor.Pointer)
                    .fontSize(FontSizes.semiMediumFontSize)
                    .lineHeight(FontLineHeight.lineMedium)
                    .letterSpacing(LetterSpacing.semiMedium)
                    .fontFamily(FontFamily.Montserrat)
                    .color(Theme.Primary.rgb)
            )

            SpanText(
                text = model?.name?:"",
                modifier = Modifier
                    .padding(right = Dimen.mediumPadding)
                    .width(Dimen.headerLargeWidth)
                    .cursor(Cursor.Pointer)
                    .fontSize(FontSizes.semiMediumFontSize)
                    .lineHeight(FontLineHeight.lineMedium)
                    .letterSpacing(LetterSpacing.semiMedium)
                    .fontFamily(FontFamily.Montserrat)
                    .color(Theme.Primary.rgb)
            )


            SpanText(
                text = model?.balance?.toString()?:"",
                modifier = Modifier
                    .margin(left = Dimen.mediumPadding)
                    .padding(leftRight = Dimen.mediumPadding)
                    .width(Dimen.headerSmallWidth)
                    .cursor(Cursor.Pointer)
                    .fontSize(FontSizes.semiMediumFontSize)
                    .lineHeight(FontLineHeight.lineMedium)
                    .letterSpacing(LetterSpacing.semiMedium)
                    .fontFamily(FontFamily.Montserrat)
                    .color(Theme.Primary.rgb)
            )


            SpanText(
                text = model?.points?.toString()?:"",
                modifier = Modifier
                    .margin(left = Dimen.mediumPadding)
                    .width( Dimen.gridCustomerCardNumberWidth)
                    .cursor(Cursor.Pointer)
                    .fontSize(FontSizes.semiMediumFontSize)
                    .lineHeight(FontLineHeight.lineMedium)
                    .letterSpacing(LetterSpacing.semiMedium)
                    .fontFamily(FontFamily.Montserrat)
                    .color(Theme.Primary.rgb)
            )


        }

        Box(
            modifier = Modifier
                .fillMaxWidth()
                .height(Dimen.smallThickness)
                .backgroundColor(Theme.GrayCD.rgb)
        )

    }

}

