package org.hs_soft.runmynesto.data.api_datasource.template.tab_general

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.upload_img.UploadTemplateCategoryImgModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.UpdateTemplateCategoryImgRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateTemplateCategoryImgRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateTemplateCategoryImgRepository {

    override suspend fun updateTemplateCategoryImg(img: String, id: String):
            UploadTemplateCategoryImgModel
    {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """
            mutation setCategoryImg {
              cashAssist {
                uploadImgBase64(file: "$img", type: Category, ${ApiUtils.Field.id}: $id) {
                  ${ApiUtils.Field.url}
                }
              }
            }
        """.trimIndent()
            )
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )


        return if (result != null) {
            Json.decodeFromString<UploadTemplateCategoryImgModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }

}