package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class HomepageUri(
    @SerialName("description")
    val description: String?,
    @SerialName("link")
    val link: String?,
    @SerialName("__typename")
    val typename: String?
)