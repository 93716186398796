package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.allergens_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.ingeredient_list.Ingredients
import org.hs_soft.runmynesto.domain.util.getAllergensWidth
import org.hs_soft.runmynesto.domain.util.getGeneralItemRatio
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.home.sub_page.user.component.ItemSeparator
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun AllergensItem(
    onItemClick: () -> Unit,
    onCheckAllergenItem:(Boolean,String)->Unit,
    checkedStatus:Boolean,
    index: Int,
    ingredientModel: Ingredients,
    windowSizeMode: WindowSizeModeEnum,
)
{
    Row(
        modifier = Modifier
            .onClick { onItemClick() }
            .width(
                getAllergensWidth(
                    windowSizeMode=windowSizeMode,
                )
            )
            .height(Dimen.gridHeaderHeight)
            .minHeight(Dimen.gridHeaderHeight)
            .borderBottom(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
            .backgroundColor(
                if (index%2==0)
                    Theme.White.rgb
                else Theme.GrayAlpha04.rgb
            )
            .padding(leftRight = Dimen.normalPadding),
        verticalAlignment = Alignment.CenterVertically
    )
    {
        SpanText(
            text = ingredientModel.code?:"",
            modifier = Modifier
                .cursor(Cursor.Pointer)
                .width(
                    getGeneralItemRatio(
                        windowSizeMode=windowSizeMode,
                        (Dimen.allergensHeaderItemWidth.value.toInt()/2).px
                    )
                )
                .fontSize(
                    getGeneralItemRatio(
                        windowSizeMode=windowSizeMode,
                        size = FontSizes.semiMediumFontSize
                    )
                )
                .lineHeight(FontLineHeight.lineMedium)
                .letterSpacing(LetterSpacing.semiMedium)
                .fontFamily(FontFamily.Montserrat)
                .color(Theme.Primary.rgb)
        )

        ItemSeparator(color = Theme.GrayCD.rgb)


        Box(
            modifier = Modifier
                .width(
                    getGeneralItemRatio(
                        windowSizeMode=windowSizeMode,
                        (Dimen.allergensHeaderItemWidth.value.toInt()/2).px
                    )
                )
        )
        {
            CheckboxComponent(
                modifier = Modifier.align(Alignment.Center)
                    ,
                checkStatus = checkedStatus,
                onUnChecked = {
                    onCheckAllergenItem(false,ingredientModel.id?:"")
                },
                onChecked = {
                    onCheckAllergenItem(true,ingredientModel.id?:"")
                },
                title = ""

            )
        }


        ItemSeparator(color = Theme.GrayCD.rgb)


        SpanText(
            text = ingredientModel.name?:"",
            modifier = Modifier
                .cursor(Cursor.Pointer)
                .fontSize(
                    getGeneralItemRatio(
                        windowSizeMode=windowSizeMode,
                        FontSizes.semiMediumFontSize,
                    )

                )
                .lineHeight(FontLineHeight.lineMedium)
                .letterSpacing(LetterSpacing.semiMedium)
                .fontFamily(FontFamily.Montserrat)
                .color(Theme.Primary.rgb)
        )





    }
}