package org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Data(
    @SerialName("cashAssist")
    val cashAssist: CashAssist?
)