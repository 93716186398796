package org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.customer_card_tab

import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card_detail.LoyaltyCardDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.customer_card_tab.UpdateLoyaltyCardRepository

class UpdateLoyaltyCardUseCase(
    private val repository: UpdateLoyaltyCardRepository
)
{
    suspend operator fun invoke(
        id:String,
        dataTitle:String,
        dataValue:dynamic,
    ): LoyaltyCardDetailModel {
        return repository.updateLoyaltyCard(
            id=id,
            dataTitle=dataTitle,
            dataValue=dataValue,
        )
    }

}