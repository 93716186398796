package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_reports.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.compositionLocalOf
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics.ShopStaticItem
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.attributes.InputFormEncType
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun DashboardReportItem(
    windowSizeMode: WindowSizeModeEnum,
    model: ShopStaticItem,
    currencyType: String,
    timeType: String,
    onItemClick: () -> Unit,
    name: String?,
    registerIdsCount: Int?,
)
{

    Column(
        modifier = Modifier
            .cursor(Cursor.Pointer)
            .margin(
                right = if (windowSizeMode==WindowSizeModeEnum.PHONE) 0.px
                        else Dimen.mediumPadding,
                bottom = Dimen.normalPadding,
                top = Dimen.normalPadding,
            )
            .rippleEffect {
                onItemClick()
            }
            .width(
                when(windowSizeMode){
                    WindowSizeModeEnum.Desktop->Dimen.dashboardReportItemWidth
                    WindowSizeModeEnum.TABLET->Dimen.dashboardReportItemTabletPhoneWidth
                    WindowSizeModeEnum.PHONE->Dimen.dashboardReportItemTabletPhoneWidth
                }
            )
            .thenIf(
                windowSizeMode==WindowSizeModeEnum.PHONE,
                Modifier
                    .fillMaxWidth()
                    .height(Dimen.dashboardReportItemMobileHeight)
            )
            .thenIf(
                windowSizeMode!=WindowSizeModeEnum.PHONE,
                Modifier
                    .height(Dimen.dashboardReportItemHeight)
            )
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb,
            ),
    )
    {
        TopHeader(
            name=name?:"",
        )

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(Dimen.largePadding)
        )
        {
            GeneralInfo(
                currencyType=currencyType
            )

            if (windowSizeMode==WindowSizeModeEnum.PHONE)
            {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.Center,
                    verticalAlignment = Alignment.CenterVertically,
                ){
                    InfoDetail(currencyType=currencyType)
                }
            }else{
                Column(){
                    InfoDetail(currencyType=currencyType)
                }
            }



        }


    }


}

@Composable
private fun InfoDetail(currencyType: String) {

    Column()
    {
        DashboardReportInfo(
            title = Strings.cash,
            value = "1234.56 " +//"${formatToTwoDecimals(model.total?:0.0)}" +
                    " $currencyType",
            color = Theme.DarkGreen.hex,
            icon = Res.PathIcon.cash
        )

        DashboardReportInfo(
            title = Strings.eft,
            value = "1234.56 " +//"${formatToTwoDecimals(model.closingData?.cashDifference?:0.00)} " +
                    "$currencyType",
            color = Theme.LightGreen.hex,
            icon = Res.PathIcon.eft,
        )

        DashboardReportInfo(
            title = Strings.credit,
            value = "1234.56 " +//
                    // "${formatToTwoDecimals( model.totalPerBill?:0.00)}
                    "$currencyType",
            color = Theme.Yellow700.hex,
            icon = Res.PathIcon.credit,
        )
    }

    Column()
    {
        DashboardReportInfo(
            title = Strings.tips,
            value = "1234.56 " + currencyType,
            //model.customerCount?.toString()?:"",
            color = Theme.Blue.hex,
            icon = Res.PathIcon.tips,
        )

        DashboardReportInfo(
            title = Strings.cancelled,
            value = "1234.56 " + currencyType,
            //model.realHours?:"",
            color = Theme.Orange.hex,
            icon = Res.PathIcon.cancel,
        )

        DashboardReportInfo(
            title = Strings.revenueGuest,
            value = "1234.56 " + currencyType,//
//                "${formatToTwoDecimals(model.totalPerRealTimeHours?:0.00)} " +
//                        "${currencyType}/${timeType}",
            color = Theme.Emerald.hex,
            icon = Res.PathIcon.revenueGuest,
        )
    }
}


@Composable
private fun GeneralInfo(
    currencyType: String,
) {

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .borderRadius(Dimen.normalBorderRadius)
            .backgroundColor(Theme.Green45Alpha10.rgb)
            .height(Dimen.dashboardItemGeneralInfoHeight),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally,
    )
    {
        SpanText(
            text = Strings.totalRevenue ,
            modifier = Modifier
                .color(Theme.Green45Alpha80.rgb)
                .fontSize(FontSizes.semiMediumFontSize)
                .fontFamily(FontFamily.Jakarta)
        )

        SpanText(
            text = formatToTwoDecimals(1234.56)+" "+currencyType,
            modifier = Modifier
                .color(Theme.Green45.rgb)
                .fontWeight(FontWeight.SemiBold)
                .fontSize(FontSizes.largeFontSize)
                .fontFamily(FontFamily.Jakarta)
        )

        Row(
            modifier = Modifier
                .margin(top=Dimen.smallPadding)
                .padding(leftRight = Dimen.smallPadding)
                .width(Dimen.dashboardItemPercentageWidth)
                .height(Dimen.dashboardItemPercentageHeight)
                .borderRadius(Dimen.dashboardItemPercentageBorder)
                .backgroundColor(Theme.Green45Alpha25.rgb),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center,
        )
        {
            VectorIcon(
                modifier = Modifier,
                pathData =Res.PathIcon.increase,
                color = Theme.Green45.hex,
                width = Dimen.extraSmallIcon,
                height = Dimen.extraSmallIcon,
            )

            Spacer()

            SpanText(
                text ="+6.5%" ,
                modifier = Modifier
                    .fontFamily(FontFamily.Jakarta)
                    .fontWeight(FontWeight.Medium)
                    .fontSize(FontSizes.normalFontSize)
                    .color(Theme.Green45.rgb)

            )


        }


    }



}


@Composable
private fun TopHeader(
    name: String,
)
{
    Column(
        modifier =Modifier
            .backgroundColor(Theme.GrayAlpha04.rgb)
            .fillMaxWidth()
            .padding(
                leftRight = Dimen.mediumPadding,
            )
            .height(Dimen.dashboardReportItemHeaderHeight),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.Start,
    )
    {
        SpanText(
            text=name,
            modifier = Modifier
                .fontFamily(FontFamily.Jakarta)
                .fontSize(FontSizes.largeFontSize)
                .color(Theme.GrayAlpha56.rgb)
                .fontWeight(FontWeight.SemiBold)
        )



    }
}

@Composable
fun DashboardReportInfo(
    modifier: Modifier=Modifier,
    title: String,
    value: String,
    color: String,
    icon: String,
    hasBorder:Boolean=false,

)
{
    Row(
        modifier = modifier
            .padding(
                leftRight = Dimen.largePadding,
            )
            .margin(
                topBottom = Dimen.smallPadding,
            )
            .thenIf(
                hasBorder,
                modifier.borderSetting()
            )
            .minWidth(Dimen.dashboardReportItemInfoWidth)
            .height(Dimen.dashboardReportItemInfoHeight),
        verticalAlignment = Alignment.CenterVertically,
    )
    {
        VectorIcon(
            modifier = Modifier,
            pathData = icon,
            color = color,
            height = Dimen.mediumIconSize,
            width = Dimen.mediumIconSize,
        )

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .margin(left=Dimen.normalPadding,)
        )
        {
            SpanText(
                text= title,
                modifier = Modifier
                    .fontFamily(FontFamily.Jakarta)
                    .fontSize(FontSizes.semiMediumFontSize)
                    .color(Theme.GrayAlpha56.rgb)
            )

            SpanText(
                text= value,
                modifier = Modifier
                    .fontFamily(FontFamily.Jakarta)
                    .fontSize(FontSizes.largeFontSize)
                    .color(Color(color))
                    .fontWeight(FontWeight.SemiBold)
            )
        }


    }


}