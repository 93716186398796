package org.hs_soft.runmynesto.domain.model.home.product.product_item_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Properties(
    @SerialName("ClickCollectLeadDaysCount")
    val clickCollectLeadDaysCount: Double?=null,
    @SerialName("ClickCollectOrderMax")
    val clickCollectOrderMax: Double?=null,
    @SerialName("ClickCollectOrderMin")
    val clickCollectOrderMin: Double?=null,
//    @SerialName("Color")
//    val color: Any,
//    @SerialName("ColorFont")
//    val colorFont: Any,
//    @SerialName("Font")
//    val font: Any,
//    @SerialName("FontSize")
//    val fontSize: Any,
//    @SerialName("FontStyle")
//    val fontStyle: Any,
//    @SerialName("MDEBatchSize")
//    val mDEBatchSize: Any,
//    @SerialName("RetoureMehrereTage")
//    val retoureMehrereTage: Any,
//    @SerialName("SCOFoodTray")
//    val sCOFoodTray: Any,
//    @SerialName("SCOKitchenComponent")
//    val sCOKitchenComponent: Any,
//    @SerialName("SCOProductTimeSlot")
//    val sCOProductTimeSlot: Any,
//    @SerialName("__typename")
//    val typename: String,
//    @SerialName("YouthProtection")
//    val youthProtection: Any
)