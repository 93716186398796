package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.customer_card_transactions

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.TransactionColumnsEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.filtered_transaction.FilteredLoyaltyCardItem
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent

@Composable
fun CustomerCardTransaction(
    filteredLoyaltyCards: List<FilteredLoyaltyCardItem?>?,
    showTransactionColumnsDialog: () -> Unit,
    selectedTransactionColumns: Set<TransactionColumnsEnum>,
)
{
    val isTransactionColumnFilterIconHovered= remember { mutableStateOf(false) }


    Column(
        modifier = Modifier
            .fillMaxWidth()
            .borderRadius(Dimen.lowBorderRadius)
    )
    {
        Row(
            modifier = Modifier
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            MediumContentTitle(
                title = Strings.transaction
            )

            Spacer()

            Box(
                modifier = Modifier
                    .borderRadius(50.percent)
                    .backgroundColor(
                        if (isTransactionColumnFilterIconHovered.value)
                            Theme.GrayAlpha12.rgb
                        else Theme.Transparent.rgb
                    )
                    .padding(Dimen.normalPadding)
                    .onMouseOver {
                        isTransactionColumnFilterIconHovered.value=true
                    }
                    .onMouseLeave {
                        isTransactionColumnFilterIconHovered.value=false
                    }
                    .onClick {
                        showTransactionColumnsDialog()
                    }
            )
            {
                VectorIcon(
                    modifier = Modifier ,
                    color = Theme.GrayAlpha56.hex,
                    pathData = Res.PathIcon.appsMenu,
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                )
            }
        }


        Column(
            modifier = HorizontalScrollBehavior.toModifier()
                .fillMaxWidth()
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayAlpha12.rgb
                )
                .borderRadius(Dimen.lowBorderRadius)
        ) {

            Row(
                modifier = HorizontalScrollBehavior.toModifier()
                    .fillMaxWidth()
                    .height(Dimen.gridHeaderHeight)
                    .borderBottom(
                        width = Dimen.smallThickness,
                        style = LineStyle.Solid,
                        color = Theme.GrayAlpha12.rgb
                    )
                    .backgroundColor(Theme.GrayAlpha04.rgb)
                    .padding(leftRight = Dimen.normalPadding),
                verticalAlignment = Alignment.CenterVertically
            ) {
                selectedTransactionColumns.forEach {
                    HeaderTitle(
                        title = it.title,
                    )
                }

            }

            Column(
                modifier = VerticalScrollBehavior.toModifier()
                    .height(Dimen.kitchenInfoItemsHeight)
                    .fillMaxWidth()
            )
            {
                filteredLoyaltyCards?.forEach {

                }
            }


        }




    }
}