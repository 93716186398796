package org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics

import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit

enum class ABCStatisticTableHeadersEnum(
    val title:String,
    val arrangement: Arrangement.Horizontal,

)
{
    CODE(
        title = Strings.code,
        arrangement = Arrangement.Start,
    ),
    NAME(
        title =Strings.name ,
        arrangement = Arrangement.Start,
    ),
    SALES_GROUP(
        title =Strings.salesGroups,
        arrangement = Arrangement.Start,
    ),
    ANZAHL(
        title =Strings.number ,
        arrangement = Arrangement.End,
    ),
    AMOUNT(
        title =Strings.amount ,
        arrangement = Arrangement.End,
    ),
    WEIGTH(
        title =Strings.weight ,
//        width =Dimen.headerSmallWidth,
        arrangement = Arrangement.End,
    ),
    FIRST_SALE(
        title =Strings.firstSale ,
//        width =Dimen.headerExtraLargeWidth ,
        arrangement = Arrangement.Start,
    ),
    LAST_SALE(
        title =Strings.lastSale ,
//        width =Dimen.headerExtraLargeWidth ,
        arrangement = Arrangement.Start,
    )

}