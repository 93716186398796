package org.hs_soft.runmynesto.domain.usecase.home

import org.hs_soft.runmynesto.domain.repository.network_datasource.home.FetchIdTokenRepository


class FetchIdTokenUseCase(
    private val repository: FetchIdTokenRepository
) {
    suspend operator fun invoke(): String {
        return repository.getIdToken()
    }
}