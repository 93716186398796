package org.hs_soft.runmynesto.di.product

import org.hs_soft.runmynesto.domain.usecase.home.GetCompanyProfileIdUseCase
import org.hs_soft.runmynesto.domain.usecase.product.AddProductUseCase
import org.hs_soft.runmynesto.domain.usecase.product.GetImgUrlUseCase
import org.hs_soft.runmynesto.domain.usecase.product.ProductCategoriesUseCase
import org.hs_soft.runmynesto.domain.usecase.product.SaveCompanyProfileIdUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_allergens.CheckedIngredientsUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_allergens.UpdateAllergenUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_checkout.UpdateProductCheckoutUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_click_collect.UpdateProductPropertiesUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.GetSingleProductUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.IngredientListUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.ProductSaleGroupUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.UpdateProductCashBidImageUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.UpdateProductPriceUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.UpdateProductUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.cache.AddProductCategoryToDBUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.cache.GetCachedProductCategoriesUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.cache.SaveCachedProductCategoriesUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.CreateProductAdditionUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.KitchenInfoUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.PrinterLocationNamesUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.ProductRestaurantUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.UpdateProductAdditionUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant.UpdateProductRestaurantUseCase
import org.hs_soft.runmynesto.domain.usecase.user.CheckOutDataUseCase
import org.koin.dsl.module

val productUseCaseModule= module {
    single {
        CheckedIngredientsUseCase(get())
    }
    single {
        UpdateAllergenUseCase(get())
    }

    single {
        CheckOutDataUseCase(get())
    }

    single {
        UpdateProductCheckoutUseCase(get())
    }
    single {
        UpdateProductPropertiesUseCase(get())
    }

    single {
        IngredientListUseCase(get())
    }

    single {
        UpdateProductCashBidImageUseCase(get())
    }

    single {
        UpdateProductUseCase(get())
    }
    single {
        ProductSaleGroupUseCase(get())
    }
    single {
        GetSingleProductUseCase(get())
    }
    single {
        KitchenInfoUseCase(get())
    }

    single {
        ProductRestaurantUseCase(get())
    }
    single {
        PrinterLocationNamesUseCase(get())
    }
    single {
        UpdateProductRestaurantUseCase(get())
    }
    single {
        AddProductUseCase(get())
    }

    single {
        ProductCategoriesUseCase(get())
    }
    single {
        GetImgUrlUseCase(get())
    }

    single {
        UpdateProductPriceUseCase(get())
    }


    single {
        GetCompanyProfileIdUseCase(get())
    }


    single {
        SaveCompanyProfileIdUseCase(get())
    }

    single {
        AddProductCategoryToDBUseCase(get())
    }
    single {
        GetCachedProductCategoriesUseCase(get())
    }
    single {
        SaveCachedProductCategoriesUseCase(get())
    }
    single {
        CreateProductAdditionUseCase(get())
    }
    single {
        UpdateProductAdditionUseCase(get())
    }


}