package org.hs_soft.runmynesto.data.api_datasource.options.menu_card_import

import com.varabyte.kobweb.browser.http.http
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.menu_card_import.GetMenuCardRepository
import org.hs_soft.runmynesto.domain.util.*
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.time.Duration.Companion.seconds
import kotlinx.serialization.json.Json

class GetMenuCardRepositoryImpl(
    private val requestManager: RequestManager
) : GetMenuCardRepository {

    override suspend fun getMenuCard(files: List<File>): String {
        return withContext(Dispatchers.Default) {
            retry(times = 3, initialDelay = 2.seconds) {
                withTimeout(Constants.timeout.seconds) {
                    fetchMenuCard(files)
                }
            }
        }
    }

    @Serializable
    data class Base64File(val filename: String, val data: String)

    suspend fun convertFileToBase64(file: File): Base64File = suspendCancellableCoroutine { continuation ->
        val reader = FileReader()
        reader.onload = { event ->
            val result = event.target.asDynamic().result as String
            console.log(result)
            val base64 = result.substring(result.indexOf(",") + 1)
            continuation.resume(Base64File(file.name, base64))
        }
        reader.onerror = { event ->
            continuation.resumeWithException(RuntimeException("Error reading file"))
        }
        reader.readAsDataURL(file)
    }

    private suspend fun fetchMenuCard(files: List<File>): String {
        val abortController = requestManager.createController()

        val headers = mapOf(
            "Content-Type" to "application/json",
            "x-api-key" to ApiUtils.API_KEY.RESTAURANTS_API_KEY_VALUE
        )

        val base64Files = files.map { convertFileToBase64(it) }

        val requestBody = mapOf("files" to base64Files)

        val jsonBody = Json.encodeToString(MapSerializer(String.serializer(), ListSerializer(Base64File.serializer())), requestBody)
        val bodyByteArray = jsonBody.encodeToByteArray()

        val result = window.http.post(
            resource = ApiUtils.Domain.baseDomain + ApiPath.getMenuCard,
            headers = headers,
            abortController = abortController,
            body = bodyByteArray
        ).decodeToString()

        return result.parseData<String>()
    }


    private suspend fun <T> retry(
        times: Int,
        initialDelay: kotlin.time.Duration,
        factor: Double = 2.0,
        block: suspend () -> T
    ): T {
        var currentDelay = initialDelay
        repeat(times - 1) {
            try {
                return block()
            } catch (e: Exception) {
                // You can add logging here
            }
            delay(currentDelay)
            currentDelay *= factor
        }
        return block() // last attempt
    }
}