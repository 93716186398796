package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.devices

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.Parameters
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.devices.component.ClosingTypeConfigContent
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.devices.component.CreditConfigCards
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.devices.component.VatConfigContent
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun DeviceTabCashRegisterConfig(
    windowSizeMode:WindowSizeModeEnum,
    data: Parameters?,
    updateData:(String,dynamic)->Unit,
)
{
    Row(
        modifier = HorizontalScrollBehavior.toModifier()
            .then(
                when(windowSizeMode)
                {
                    WindowSizeModeEnum.WINDOWS -> Modifier.fillMaxSize()
                    WindowSizeModeEnum.TABLET -> HorizontalScrollBehavior.toModifier()
                        .width(Dimen.tabletTabsSmallWidth)
                    WindowSizeModeEnum.PHONE -> HorizontalScrollBehavior
                        .toModifier().width(Dimen.mobileTabsWidth)
                }
            )
            .fillMaxHeight()
            .padding(Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    ){
        CreditConfigCards(
            creditCard=data?.creditCards,
            updateData= {title:String,value:dynamic->
                updateData(title,value)
            }
        )

        Column(
            modifier = Modifier
                .weight(1f)
                .margin(
                    left = Dimen.mediumPadding
                )
        ) {
            ClosingTypeConfigContent(
                isActive=data?.isEmployeeClosingActive?:false,
                closingType = data?.closingType?:"",
                updateData={title: String, value: dynamic ->
                    updateData(title, value)
                }
            )

            VatConfigContent(
                normalVat=data?.normalVat,
                reducedVat=data?.reducedVat,
                specialVat=data?.specialVat,
                isItCafeMode=data?.isInCafeModeInt,
                updateData = {title:String,value:dynamic->
                    updateData(title,value)
                }
            )
        }





    }

}
