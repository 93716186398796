package org.hs_soft.runmynesto.data.api_datasource.customer_card.tabs.customer_card_tab

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.credit_client.CreditClientModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.customer_card_tab.CreditClientRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class CreditClientRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): CreditClientRepository
{
    override suspend fun getCreditClient(): CreditClientModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            query listCreditClients(${'$'}params: ListQueryArgs, ${'$'}filter: CreditClientsFilter) {
                 cashAssist {
                   listCreditClients(params:${'$'}params, filter: ${'$'}filter) {
                     ...listCreditClients
                     ${ApiUtils.Field.__typename}
                   }
                   ${ApiUtils.Field.__typename}
                 }
               }

               fragment listCreditClients on CreditClientConnection {
                 items {
                   ${ApiUtils.Field.id}
                   ${ApiUtils.Field.clientNumber}
                   ${ApiUtils.Field.name}
                   ${ApiUtils.Field.__typename}
                 }
                 ${ApiUtils.Field.total}
                 ${ApiUtils.Field.hasNextPage}
                 ${ApiUtils.Field.__typename}
               }
                
                """.trimIndent())

            putJsonObject("variables") {

            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }
    }

}