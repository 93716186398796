package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ListClosings(
    @SerialName("hasNextPage")
    val hasNextPage: Boolean?,
    @SerialName("items")
    val items: List<ClosingReportItem>?,
    @SerialName("total")
    val total: Int?,
    @SerialName("totalAmount")
    val totalAmount: Double?,
    @SerialName("__typename")
    val typename: String?
)