package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_click_collect.update_properties


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("__typename")
    val typename: String,
    @SerialName("updateProductProperties")
    val updateProductProperties: UpdateProductProperties
)