package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card

import org.hs_soft.runmynesto.domain.config.Strings

enum class CustomerCardTypeEnum (
    val title:String,
    val value:Int,
){
    IN_HOUSE(
        title = Strings.inHouseCard,
        value = 1,
    ),
    LOYALTY_CARDS(
        title = Strings.loyaltyCard,
        value = 2,
    ),
    DEBIT_CARDS(
        title = Strings.debitCard,
        value = 3,
    ),



}