package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.px

@Composable
fun MediumContentTitle(
    title:String,
    marginBottom:CSSSizeValue<CSSUnit.px> = 0.px,
    marginTop:CSSSizeValue<CSSUnit.px> = 0.px
) {
    SpanText(
        text = title,
        modifier = Modifier
            .margin(
                bottom = marginBottom,
                top = marginTop,
            )
            .fontFamily(FontFamily.Montserrat)
            .fontSize(FontSizes.largeFontSize)
            .fontWeight(FontWeight.Normal)
            .lineHeight(FontLineHeight.lineLarge)
            .color(Theme.Secondary.rgb)
    )
}