package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_reports.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics.DataX
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun DashboardReportItem(
    windowSizeMode: WindowSizeModeEnum,
    model: DataX,
    currencyType: String,
    timeType: String,
    onItemClick: () -> Unit,
    name: String?,
    registerIdsCount: Int?,
)
{


    Column(
        modifier = Modifier
            .cursor(Cursor.Pointer)
            .margin(
                right = if (windowSizeMode==WindowSizeModeEnum.PHONE) 0.px
                        else Dimen.normalPadding,
                bottom = Dimen.normalPadding,
                top = Dimen.normalPadding,
            )
            .rippleEffect {
                onItemClick()
            }
            .width(
                if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                    Dimen.dashboardReportItemWidth
                else Dimen.dashboardReportItemTabletPhoneWidth
            )
            .height(Dimen.dashboardReportItemHeight)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb,
            ),
    )
    {
        TopHeader(
            name=name?:"",
            registerIdsCount=registerIdsCount,
        )

        DashboardReportInfo(
            title = Strings.turnOver,
            value = "${formatToTwoDecimals(model.total?:0.0)} $currencyType",
            color = Theme.DarkGreen.hex,
            icon = Res.PathIcon.turnOver
        )

        DashboardReportInfo(
            title =Strings.difference,
            value ="${model.closingData?.cashDifference?:"0.00"} $currencyType" ,
            color = Theme.LightGreen.hex,
            icon = Res.PathIcon.difference,
        )

        DashboardReportInfo(
            title =Strings.averageCheck,
            value ="${model.totalPerBill} $currencyType",
            color = Theme.Yellow700.hex,
            icon = Res.PathIcon.averageCheck,
        )

        DashboardReportInfo(
            title =Strings.clients,
            value =model.customerCount?.toString()?:"",
            color = Theme.Blue.hex,
            icon = Res.PathIcon.clients,
        )

        DashboardReportInfo(
            title =Strings.workingHours,
            value =model.realHours?:"",
            color = Theme.Orange.hex,
            icon = Res.PathIcon.workingHours,
        )

        DashboardReportInfo(
            title =Strings.hourlyRate,
            value = "${model.totalPerRealTimeHours?.toString()?:""} " +
                    "${currencyType}/${timeType}",
            color = Theme.Emerald.hex,
            icon = Res.PathIcon.hourlyRate,
        )

    }


}

@Composable
private fun TopHeader(
    name: String,
    registerIdsCount: Int?,
)
{
    Column(
        modifier =Modifier
            .backgroundColor(Theme.GrayAlpha04.rgb)
            .fillMaxWidth()
            .padding(
                leftRight = Dimen.mediumPadding,
                topBottom = Dimen.normalPadding
            )
            .height(Dimen.dashboardReportItemHeaderHeight),
        verticalArrangement = Arrangement.Center
    )
    {
        SpanText(
            text=name,
            modifier = Modifier
                .fontFamily(FontFamily.Montserrat)
                .fontSize(FontSizes.largeFontSize)
                .color(Theme.GrayAlpha56.rgb)
                .fontWeight(FontWeight.SemiBold)
        )
        Row(
            verticalAlignment = Alignment.CenterVertically
        )
        {
            SpanText(
                text= Strings.cashRegisters,
                modifier = Modifier
                    .margin(right = Dimen.normalPadding)
                    .fontFamily(FontFamily.Montserrat)
                    .fontSize(FontSizes.semiMediumFontSize)
                    .color(Theme.GrayAlpha56.rgb)
            )

            SpanText(
                text= "${registerIdsCount?:0}/${registerIdsCount?:0}",
                modifier = Modifier
                    .fontFamily(FontFamily.Montserrat)
                    .fontSize(FontSizes.semiMediumFontSize)
                    .color(Theme.GrayAlpha56.rgb)
                    .fontWeight(FontWeight.SemiBold)
            )


        }


    }
}

@Composable
fun DashboardReportInfo(
    modifier: Modifier=Modifier,
    title: String,
    value: String,
    color: String,
    icon: String,
    hasBorder:Boolean=false,

)
{
    Row(
        modifier = modifier
            .thenIf(
                hasBorder,
                modifier.borderSetting()
            )
            .minWidth(Dimen.dashboardReportItemInfoWidth)
            .height(Dimen.dashboardReportItemInfoHeight)
            .padding(leftRight = Dimen.mediumPadding),
        verticalAlignment = Alignment.CenterVertically,
    )
    {
        VectorIcon(
            modifier = Modifier,
            pathData = icon,
            color = color,
            height = Dimen.mediumIconSize,
            width = Dimen.mediumIconSize,
        )

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .margin(left=Dimen.normalPadding,)
        )
        {
            SpanText(
                text= title,
                modifier = Modifier
                    .fontFamily(FontFamily.Montserrat)
                    .fontSize(FontSizes.semiMediumFontSize)
                    .color(Theme.GrayAlpha56.rgb)
            )

            SpanText(
                text= value,
                modifier = Modifier
                    .fontFamily(FontFamily.Montserrat)
                    .fontSize(FontSizes.largeFontSize)
                    .color(Color(color))
                    .fontWeight(FontWeight.SemiBold)
            )
        }


    }


}