package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.ingeredient_list.IngredientModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.IngredientListRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class IngredientListRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): IngredientListRepository {

    override suspend fun ingredientList(): IngredientModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            query listIngredients(${'$'}type: ingredientTypes!) {
              cashAssist {
                listIngredients(type: ${'$'}type) {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.uuid}
                  ${ApiUtils.Field.group}
                  ${ApiUtils.Field.code}
                  ${ApiUtils.Field.name}
                  ${ApiUtils.Field.units}
                  ${ApiUtils.Field.__typename}
                }
                ${ApiUtils.Field.__typename}
              }
            }
        """.trimIndent())

            put("variables", buildJsonObject {
                put("type", "allergens_EU")
                put("params", buildJsonObject {
//                    put("limit", 100)
                })
                put("filter", buildJsonObject { })
            })
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<IngredientModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }

}
