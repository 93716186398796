package org.hs_soft.runmynesto.data.api_datasource.authentication

import androidx.compose.runtime.NoLiveLiterals
import org.hs_soft.runmynesto.Auth
import org.hs_soft.runmynesto.domain.repository.network_datasource.login.SignInRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.jsObject


class SignInRepositoryImpl:SignInRepository{

    @NoLiveLiterals
    override fun signIn(
        userName: String,
        password: String,
        mfaCode: String,
        onSuccess: () -> Unit,
        onError:(String)->Unit,
    ) {

        val options = jsObject {
            this["username"] = userName
            this["password"] = password
        }
        Auth.signIn(options).then { result ->
            if (result.nextStep?.signInStep == ApiUtils.Response.needsMFA) {
                confirmTOTP(
                    user = result,
                    totpCode = mfaCode,
                    onSuccess = {
                       onSuccess()
                    },
                    onError={
                        onError(it)
                    }
                )

            }else if(result.nextStep?.signInStep==ApiUtils.Response.done)
                onSuccess()
            console.log(result)
        }.catch { error: dynamic ->
            onError(error.toString())
            console.log("Sign in error: ${error}")
        }
    }


}

@NoLiveLiterals
private fun confirmTOTP(
    user: dynamic,
    totpCode: String,
    onSuccess: () -> Unit,
    onError: (String) -> Unit,
) {
    console.log(Auth.confirmSignIn)

    val input = jsObject {
        this["challengeResponse"] = totpCode
        this["options"] = jsObject {
            this["username"] = user.username
            this["challengeName"] = user.challengeName
            this["signInSession"] = user.signInSession
            this["signInDetails"] = user.signInDetails
        }
    }
    Auth.confirmSignIn(input).then { result ->
        onSuccess()
        console.log(result)
    }.catch { error: dynamic ->
        onError(error.toString())
        console.log("TOTP confirmation error: ${error}")
    }
}
