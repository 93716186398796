package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.color_pallet

enum class ColorPaletteEnum(val colorName: String, val hexCode: String) {
    BLACK("Black", "#000000"),
    RED("Red", "#FF0000"),
    ORANGE("Orange", "#FFA500"),
    YELLOW("Yellow", "#FFFF00"),
    GREEN("Green", "#008000"),
    PURPLE("Purple", "#800080"),
    WHITE("White", "#FFFFFF"),
    LIGHT_PINK("Light Pink", "#FFB6C1"),
    LIGHT_ORANGE("Light Orange", "#FFDAB9"),
    LIGHT_YELLOW("Light Yellow", "#FFFFE0"),
    LIGHT_GREEN("Light Green", "#90EE90"),
    LIGHT_BLUE("Light Blue", "#ADD8E6"),
    LIGHT_PURPLE("Light Purple", "#DDA0DD"),
    GRAY("Gray", "#808080"),
    DARK_RED("Dark Red", "#8B0000"),
    DARK_ORANGE("Dark Orange", "#FF8C00"),
    DARK_YELLOW("Dark Yellow", "#FFD700"),
    DARK_GREEN("Dark Green", "#006400"),
    DARK_BLUE("Dark Blue", "#00008B"),
    DARK_PURPLE("Dark Purple", "#4B0082"),
    DARK_GRAY("Dark Gray", "#A9A9A9"),
    BROWN("Brown", "#A52A2A"),
    DARK_BROWN("Dark Brown", "#8B4513"),
    OLIVE("Olive", "#808000"),
    DARK_OLIVE("Dark Olive", "#556B2F"),
    NAVY("Navy", "#000080"),
    DARK_NAVY("Dark Navy", "#000033"),
    MAROON("Maroon", "#800000"),
    DARK_MAROON("Dark Maroon", "#660000"),
    GOLD("Gold", "#FFD700"),
    DARK_GOLD("Dark Gold", "#B8860B"),
    DARK_GREEN2("Dark Green 2", "#013220"),
    DARK_BLUE2("Dark Blue 2", "#000033"),
    DARK_PURPLE2("Dark Purple 2", "#4B0082")
}