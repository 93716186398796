package org.hs_soft.runmynesto.data.local_datasource.local_storage.product

import kotlinx.browser.window
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.repository.local_datasource.product.SaveCachedProductCategoriesRepository
import org.hs_soft.runmynesto.domain.util.IdUtils

class SaveCachedProductCategoriesRepositoryImpl:
    SaveCachedProductCategoriesRepository
{
    override fun saveProductCategories(items: List<ProductItem?>) {
        val jsonString = Json.encodeToString(items)
        window.localStorage.setItem(IdUtils.productsDB, jsonString)

    }

}