package org.hs_soft.runmynesto.domain.model.home.dashboard


import kotlinx.datetime.*
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.util.showSelectedDate

enum class CompareChartDaysEnum(
    val title: String,
    val date:MonthModel,
)
{
    SUNDAY(
        title = getTitleForDay(DayOfWeek.SUNDAY),
        date = getDateForDay(DayOfWeek.SUNDAY),
    ),
    MONDAY(
        title = getTitleForDay(DayOfWeek.MONDAY),
        date = getDateForDay(DayOfWeek.MONDAY) ,
    ),
    TUESDAY(
        title = getTitleForDay(DayOfWeek.TUESDAY),
        date = getDateForDay(DayOfWeek.TUESDAY) ,
    ),
    WEDNESDAY(
        title = getTitleForDay(DayOfWeek.WEDNESDAY),
        date =  getDateForDay(DayOfWeek.WEDNESDAY) ,
    ),
    THURSDAY(
        title = getTitleForDay(DayOfWeek.THURSDAY),
        date = getDateForDay(DayOfWeek.THURSDAY) ,
    ),
    FRIDAY(
        title = getTitleForDay(DayOfWeek.FRIDAY),
        date = getDateForDay(DayOfWeek.FRIDAY) ,
    ),
    SATURDAY(
        title = getTitleForDay(DayOfWeek.SATURDAY),
        date = getDateForDay(DayOfWeek.SATURDAY) ,
    ),
    LAST_WEEK(
        title = Strings.lastWeek,
        date = getDateWeeksAgo(1) ,
    ),
    TWO_WEEKS_AGO(
        title = Strings.twoWeeksAgo,
        date = getDateWeeksAgo(2),
    ),
    THREE_WEEKS_AGO(
        title = Strings.threeWeeksAgo,
        date = getDateWeeksAgo(3) ,
    );

}

private fun getDateForDay(dayOfWeek: DayOfWeek): MonthModel {
    val today = Clock.System.now().toLocalDateTime(TimeZone.UTC).date
    val daysToSubtract = (today.dayOfWeek.ordinal - dayOfWeek.ordinal + 7) % 7
    val targetDate = today.minus(daysToSubtract, DateTimeUnit.DAY)
    return MonthModel(
        dayNumber = targetDate.dayOfMonth,
        monthNumber = targetDate.monthNumber,
        yearNumber = targetDate.year,
        monthName = targetDate.month.name
    )
}

private fun getDateWeeksAgo(weeksAgo: Int): MonthModel {
    val today = Clock.System.now().toLocalDateTime(TimeZone.UTC).date
    val targetDate = today.minus(weeksAgo * 7, DateTimeUnit.DAY)
    return MonthModel(
        dayNumber = targetDate.dayOfMonth,
        monthNumber = targetDate.monthNumber,
        yearNumber = targetDate.year
    )
}

private fun getTitleForDay(dayOfWeek: DayOfWeek): String {
    val today = Clock.System.now().toLocalDateTime(TimeZone.UTC).date
    return if (today.dayOfWeek == dayOfWeek) {
        "${dayOfWeek.name.lowercase().replaceFirstChar { it.uppercase() }} (Today)"
    } else {
        dayOfWeek.name.lowercase().replaceFirstChar { it.uppercase() }
    }
}