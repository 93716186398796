package org.hs_soft.runmynesto.domain.model.home.product.add_new_product


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("createMutation")
    val createMutation: CreateMutation
)