package org.hs_soft.runmynesto.data.api_datasource.user

import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.UpdateUserDataRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateUserDataRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateUserDataRepository {
    override suspend fun updateUserData(
        dataValue: dynamic,
        dataTitle: String,
        userId: String
    ) {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            mutation updateUser_Main(${'$'}id: ID!, ${'$'}fields: UserFields!) {
              cashAssist {
                updateUser(id: ${'$'}id, fields: ${'$'}fields) {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.isActive}
                  ${ApiUtils.Field.name}
                  ${ApiUtils.Field.printName}
                  ${ApiUtils.Field.password}
                }
              }
            }
        """.trimIndent())


            console.log(dataValue)
            putJsonObject("variables") {
                put("id", userId)
                putJsonObject("fields") {
                    when(dataValue){
                        is Boolean ->put(dataTitle, dataValue as Boolean)
                        is String ->put(dataTitle,dataValue as String)
                        is Int ->put(dataTitle,dataValue as Int)
                    }
                }
            }
        }

        apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        ) ?: throw Exception(Strings.timeoutErr)

    }

}


