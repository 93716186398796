package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Product(
    @SerialName("category")
    val category: Category?,
    @SerialName("code")
    val code: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("img")
    val img: ImgX?,
    @SerialName("name")
    val name: String?,
    @SerialName("price")
    val price: List<Double?>?,
    @SerialName("__typename")
    val typename: String?
)