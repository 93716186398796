package org.hs_soft.runmynesto.domain.model.home.user.single_user


import kotlinx.serialization.Serializable

@Serializable
data class SingleUserModel(
    val data: Data
)

@Serializable
data class Data(
    val cashAssist: CashAssist
)

@Serializable
data class CashAssist(
    val getUser: GetUser
)

@Serializable
data class GetUser(
    val id: String,
    val isActive: Boolean?=null,
    val name: String?=null,
    val printName: String?=null,
    val shortName:String?=null,
    val password: String ?=null,
    val isTimeTracking:Boolean?=null,
    val isDayFinStatements:Boolean?=null,
    val isAdmin:Boolean?=null,
    val isManager:Boolean?=null,
    val isViewSales:Boolean?=null,
    val isFinStatements:Boolean?=null,
    val waiterKey:String?=null,
    val cancelationPermissions:String?=null,
    val img: Img?=null,
    val cashRegisterId:List<String> ?=null,
)

@Serializable
data class Img(
    val url: String ?=null
)