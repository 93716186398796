package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs
    .cash_register_configuration.tabs.hardware.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.buildJsonArray
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_flow.app_config_model.PrinterLocation
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.hardware.KitchenLocationPrintersEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.hardware.SunmiPartEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.Location
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.DropdownComponent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun HardwareKitchenLocation(
    windowSizeMode: WindowSizeModeEnum,
    location: Location?,
    printerLocation: PrinterLocation?,
    ipDeviceLabel: String?,
    ip: String?,
    updateData: (String, dynamic) -> Unit,
    position: Int,
)
{
    val showSerialNumberField= remember { mutableStateOf(false) }
    val serialOrIPNumber= remember { mutableStateOf(ip?:"") }

    val selectedPrinterStatus = remember { mutableStateOf(getSelectedPrinterStatus(
        ip=ip,
        ipDeviceLabel=ipDeviceLabel))
    }

    Column(
        modifier = Modifier
            .margin(topBottom = Dimen.smallPadding)
            .fillMaxWidth()
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
            .borderRadius(Dimen.lowBorderRadius)
            .padding(Dimen.normalPadding)
    ) {

        Row(
            verticalAlignment = Alignment.CenterVertically
        )
        {
            if (windowSizeMode!=WindowSizeModeEnum.WINDOWS)
            {
                Column(verticalArrangement = Arrangement.Center)
                {
                    LeftContent(
                        windowSizeMode=windowSizeMode,
                        printerLocation=printerLocation,
                        selectedPrinterStatus=selectedPrinterStatus,
                        serialOrIPNumber=serialOrIPNumber,
                        location=location,
                        position=position,
                        updateData = {title:String,value:dynamic->
                            updateData(title,value)
                        },
                    )
                }

            }else{
                Row(
                    verticalAlignment = Alignment.CenterVertically
                )
                {
                    LeftContent(
                        windowSizeMode=windowSizeMode,
                        printerLocation=printerLocation,
                        selectedPrinterStatus=selectedPrinterStatus,
                        serialOrIPNumber=serialOrIPNumber,
                        location=location,
                        position=position,
                        updateData = {title:String,value:dynamic->
                            updateData(title,value)
                        },
                    )
                }
            }



            Column(
                verticalArrangement = Arrangement.Center
            )
            {
                if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                {
                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    )
                    {
                        PrinterContent(
                            windowSizeMode=windowSizeMode,
                            ip=ip,
                            ipDeviceLabel=ipDeviceLabel,
                            selectedPrinterStatus=selectedPrinterStatus,
                            position=position,
                            serialOrIPNumber = serialOrIPNumber,
                            updateData = {title:String,value:dynamic->
                                updateData(title,value)
                            },
                        )
                    }
                }else{
                    Column(
                        verticalArrangement = Arrangement.Center
                    )
                    {
                        PrinterContent(
                            windowSizeMode=windowSizeMode,
                            ip=ip,
                            ipDeviceLabel=ipDeviceLabel,
                            selectedPrinterStatus=selectedPrinterStatus,
                            position=position,
                            serialOrIPNumber = serialOrIPNumber,
                            updateData = {title:String,value:dynamic->
                                updateData(title,value)
                            },
                        )
                    }
                }



                if (
                    selectedPrinterStatus.value != KitchenLocationPrintersEnum.KITCHEN_DISPLAY_KDS
                    && selectedPrinterStatus.value != null
                ){
                    val parts = ipDeviceLabel?.split(";")
                    val secondPart = parts?.getOrNull(1)
                    Box()
                    {

                        if (secondPart?.length==Constants.sunmiTypeLength)
                        {
                            CustomEditText(
                                modifier = Modifier,
                                label = Strings.kitchenPrinterSerialNumber,
                                value = secondPart,
                                width = if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                                    Dimen.mediumEditTextWidth else Dimen.highNormalEditTextWidth,
                                isOutlinedEditText = true,
                                isRequired = false,
                                defaultBorderColor = Theme.GrayCD.rgb,
                                hint = "",
                                onFocusIn = {},
                                endIconPath = Res.PathIcon.mediumClose,
                                onEndIconClick = {
                                    updateData(
                                        ApiUtils.Field.printer,
                                        getPrinterJson(
                                            title = ApiUtils.Field.ipDeviceLabel,
                                            value = "",
                                            position = position
                                        )
                                    )
                                },
                                onFocusOut = {
                                    updateData(
                                        ApiUtils.Field.printer,
                                        getPrinterJson(
                                            title = ApiUtils.Field.ipDeviceLabel,
                                            value = ";$it",
                                            position = position
                                        )
                                    )
                                },
                                onInputChange = {

                                },
                            )


                        }else{
                            if (!showSerialNumberField.value)
                            {
                                SpanText(
                                    text = Strings.addCakeDisplay,
                                    modifier = Modifier
                                        .cursor(Cursor.Pointer)
                                        .margin(top = Dimen.normalPadding)
                                        .onClick {
                                            showSerialNumberField.value=true
                                        }
                                        .padding(
                                            leftRight = Dimen.normalPadding,
                                            topBottom = Dimen.smallPadding
                                        )
                                        .fontSize(FontSizes.normalFontSize)
                                        .fontFamily(FontFamily.Montserrat)
                                        .borderRadius(Dimen.mediumBorderRadius)
                                        .border(
                                            width = Dimen.smallThickness,
                                            color = Theme.GrayCD.rgb,
                                            style = LineStyle.Solid,
                                        )
                                )
                            }else {
                                CustomEditText(
                                    modifier = Modifier,
                                    width =when(windowSizeMode){
                                        WindowSizeModeEnum.WINDOWS->Dimen.mediumEditTextWidth
                                        WindowSizeModeEnum.TABLET ->Dimen.semiMediumEditTextWidth
                                        WindowSizeModeEnum.PHONE ->Dimen.highNormalEditTextWidth
                                    },
                                    label = Strings.kitchenPrinterSerialNumber,
                                    value ="",
                                    marginBottom = 0.px,
                                    endIconPath = Res.PathIcon.mediumClose,
                                    onEndIconClick = {
                                        showSerialNumberField.value=false
                                        updateData(
                                            ApiUtils.Field.printer,
                                            getPrinterJson(
                                                title = ApiUtils.Field.ipDeviceLabel,
                                                value = "",
                                                position = position
                                            )
                                        )
                                    },
                                    isOutlinedEditText = true,
                                    isRequired = false,
                                    defaultBorderColor = Theme.GrayCD.rgb,
                                    hint = "",
                                    onFocusIn = {},
                                    onFocusOut = {
                                        updateData(
                                            ApiUtils.Field.printer,
                                            getPrinterJson(
                                                title = ApiUtils.Field.ipDeviceLabel,
                                                value = ";$it",
                                                position = position
                                            )
                                        )
                                    },
                                    onInputChange = {

                                    },
                                )
                            }

                        }

                    }

                }


            }




        }


    }


}

@Composable
private fun PrinterContent(
    windowSizeMode: WindowSizeModeEnum,
    ip: String?,
    ipDeviceLabel: String?,
    selectedPrinterStatus: MutableState<KitchenLocationPrintersEnum?>,
    updateData: (String, dynamic) -> Unit,
    position: Int,
    serialOrIPNumber: MutableState<String>
) {
    DropdownComponent(
        modifier = Modifier
            .margin(
                bottom = Dimen.normalPadding,
                right = Dimen.normalPadding,
            ),
        itemWidth =when(windowSizeMode){
            WindowSizeModeEnum.WINDOWS->Dimen.mediumEditTextWidth
            WindowSizeModeEnum.TABLET ->Dimen.semiMediumEditTextWidth
            WindowSizeModeEnum.PHONE ->Dimen.highNormalEditTextWidth
        },
        options = KitchenLocationPrintersEnum.entries,
        label = Strings.printer,
        selectedItem = getSelectedPrinterStatus(
            ip = ip,
            ipDeviceLabel = ipDeviceLabel,
        ),
        content = {
            if (it is KitchenLocationPrintersEnum)
                Text(it.title)
        },
        onOptionClick = {
            val option = KitchenLocationPrintersEnum.entries.find { item ->
                item.toString() == it
            }
            selectedPrinterStatus.value = option
        }
    )


    if (selectedPrinterStatus.value != null || !ip.isNullOrEmpty()) {
        CustomEditText(
            modifier = Modifier,
            label = if (selectedPrinterStatus.value ==
                KitchenLocationPrintersEnum.SUNMI_CLOUD_BY_IP
            ) Strings.ipAddress else Strings.serialNumber,
            value =ip?:"",
            width =when(windowSizeMode){
                WindowSizeModeEnum.WINDOWS->Dimen.mediumEditTextWidth
                WindowSizeModeEnum.TABLET ->Dimen.semiMediumEditTextWidth
                WindowSizeModeEnum.PHONE ->Dimen.highNormalEditTextWidth
            },
            isOutlinedEditText = true,
            isRequired = true,
            marginBottom = 0.px,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            onFocusIn = {},
            onFocusOut = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrinterJson(
                        title = ApiUtils.Field.ip,
                        value = it,
                        position = position
                    )
                )
            },
            onInputChange = {
                serialOrIPNumber.value = it
            },
        )
    }
}

@Composable
private fun LeftContent(
    windowSizeMode: WindowSizeModeEnum,
    printerLocation: PrinterLocation?,
    selectedPrinterStatus: MutableState<KitchenLocationPrintersEnum?>,
    serialOrIPNumber: MutableState<String>,
    location: Location?,
    updateData: (String, dynamic) -> Unit,
    position: Int
) {
    SpanText(
        text = printerLocation?.value ?: "",
        modifier = Modifier
            .width(
                if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                    Dimen.printLocationNameWidth
                else (Dimen.printLocationNameWidth.value.toInt()*0.75).px
            )
            .fontFamily(FontFamily.Montserrat)
            .fontSize(
                if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                    FontSizes.mediumFontSize
                else FontSizes.semiMediumFontSize
            )
            .color(Theme.Secondary.rgb)
            .fontWeight(FontWeight.Bold)
    )

    Column(
        modifier = Modifier
            .margin(
                leftRight =if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                    Dimen.largePadding else 0.px
            )
    ) {
        CheckboxComponent(
            title = Strings.activate,
            fontSize = if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                    FontSizes.mediumFontSize
                else FontSizes.normalFontSize,
            enabled = (selectedPrinterStatus.value != null &&
                    serialOrIPNumber.value.isNotEmpty()),
            checkStatus = location?.isActive == true,
//                            &&  location.isDirectConnected == true,//TODO(Must Ask Sergey)
            onUnChecked = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrinterJson(
                        title = ApiUtils.Field.isActive,
                        value = false,
                        position = position
                    )
                )
            },
            onChecked = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrinterJson(
                        title = ApiUtils.Field.isActive,
                        value = true,
                        position = position
                    )
                )
            }
        )

        CheckboxComponent(
            enabled = (selectedPrinterStatus.value != null && serialOrIPNumber.value.isNotEmpty()),
            title = Strings.copyForWaiter,
            fontSize = if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                FontSizes.mediumFontSize
            else FontSizes.normalFontSize,
            checkStatus = (
                location?.copyForWaiter ?: false
            ),
            onUnChecked = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrinterJson(
                        title = ApiUtils.Field.copyForWaiter,
                        value = false,
                        position = position
                    )
                )
            },
            onChecked = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrinterJson(
                        title = ApiUtils.Field.copyForWaiter,
                        value = true,
                        position = position
                    )
                )
            }
        )

        CheckboxComponent(
            enabled = (selectedPrinterStatus.value != null && serialOrIPNumber.value.isNotEmpty()),
            title = Strings.tableShift,
            fontSize = if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                FontSizes.mediumFontSize
            else FontSizes.normalFontSize,
            checkStatus = location?.tableShiftReceipt ?: false,
            onUnChecked = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrinterJson(
                        title = ApiUtils.Field.tableShiftReceipt,
                        value = false,
                        position = position
                    )
                )
            },
            onChecked = {
                updateData(
                    ApiUtils.Field.printer,
                    getPrinterJson(
                        title = ApiUtils.Field.tableShiftReceipt,
                        value = true,
                        position = position
                    )
                )
            }
        )


    }
}

private fun getPrinterJson(
    title:String,
    value:dynamic,
    position:Int
): JsonObject {
    val printerJson = buildJsonObject {
        put("locations", buildJsonArray {
            add(buildJsonObject {
                put("value", buildJsonObject {
                    when(value){
                        is Boolean->put(title,value as Boolean)
                        is String ->put(title,value as String)
                        is Int -> put(title,value as Int)
                    }
                })
                put(ApiUtils.Field.position, position)
            })
        })
    }
    return printerJson


}

fun getSelectedPrinterStatus(
    ip: String?,
    ipDeviceLabel: String?
): KitchenLocationPrintersEnum? {
    return when {
        ipDeviceLabel.isNullOrEmpty() && ip.isNullOrEmpty() -> null
        ipDeviceLabel.isNullOrEmpty() && !ip.isNullOrEmpty() -> KitchenLocationPrintersEnum.EPSON_M30
        else -> {
            val parts = ipDeviceLabel?.split(";")
            val type = parts?.getOrNull(0)
            when {
                type == SunmiPartEnum.SUNMI.title ->
                    KitchenLocationPrintersEnum.SUNMI_CLOUD_BY_IP
                type ==SunmiPartEnum.SUNMI_CLOUD.title ->
                    KitchenLocationPrintersEnum.SUNMI_CLOUD_BY_SERVER
                type != null && type.length == Constants.sunmiTypeLength ->
                    KitchenLocationPrintersEnum.KITCHEN_DISPLAY_KDS
                else -> KitchenLocationPrintersEnum.EPSON_M30
            }
        }
    }
}