package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.Theme
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit

@Composable
fun ComponentLabel(
    modifier: Modifier=Modifier,
    title:String,
    leftMargin: CSSSizeValue<CSSUnit.px> ,
    fontSize:CSSSizeValue<CSSUnit.px>,
    fontWeight: FontWeight =FontWeight.Normal
) {
    SpanText(
        text = title,
        modifier = modifier
            .margin(
                left = leftMargin
            )
            .fontSize(fontSize)
            .fontWeight(fontWeight)
            .fontFamily(FontFamily.Jakarta)
            .color(Theme.Primary.rgb)
    )
}