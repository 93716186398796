package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit

@Composable
fun CardInfo(
    title:String,
    value:String,
    valueFontSize: CSSSizeValue<CSSUnit.px> =
        FontSizes.semiMediumFontSize,
    alignStart:Boolean=true,
    titleColor:CSSColorValue=Theme.GrayAlpha56.rgb,
    valueColor:CSSColorValue=Theme.Secondary.rgb,
) {
    Column(
        modifier = Modifier.margin(topBottom = Dimen.smallPadding),
        horizontalAlignment = if (alignStart)
            Alignment.Start
        else Alignment.End
    ) {
        SpanText(
            text = title,
            modifier = Modifier
                .fontFamily(FontFamily.Montserrat)
                .color(titleColor)
                .fontWeight(FontWeight.Normal)
                .fontSize(FontSizes.normalFontSize)
        )

        SpanText(
            text = value,
            modifier = Modifier
                .fontFamily(FontFamily.Montserrat)
                .color(valueColor)
                .fontWeight(FontWeight.Normal)
                .fontSize(valueFontSize)
        )


    }
}
