package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class PriceLevel(
    @SerialName("inCafe")
    val inCafe: Int?,
    @SerialName("normal")
    val normal: Int?,
    @SerialName("__typename")
    val typename: String?
)