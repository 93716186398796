package org.hs_soft.runmynesto.domain.model.home.user.users_info

import kotlinx.serialization.Serializable

@Serializable
data class UsersData(
    val data: Data
)

@Serializable
data class Data(
    val cashAssist: CashAssist
)

@Serializable
data class CashAssist(
    val listUsers: ListUsers
)
@Serializable
data class ListUsers(
    val items: List<UserModel>
)

@Serializable
data class UserModel(
    val id: String,
    val code: String ?,
    val name: String ?,
    val isActive:Boolean?,
)