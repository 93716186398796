package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Res

@Composable
fun ShowWhenListIsEmpty(
    modifier: Modifier = Modifier,
) {
    Column(
        modifier = modifier.fillMaxSize() ,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {

        VectorIcon(
            pathData = Res.PathIcon.emptyList,
            color = Theme.GrayAlpha38.hex,
            width = Dimen.highMediumIconSize,
            height = Dimen.highMediumIconSize
        )
        SpanText(
            text = Strings.empty,
            modifier = Modifier
                .fontSize(FontSizes.mediumFontSize)
                .fontFamily(FontFamily.Montserrat)
                .color(Theme.GrayAlpha56.rgb)

        )
    }
}