package org.hs_soft.runmynesto.domain.usecase.template.tabs.tab_surcharge_in_house

import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_checkout.update_template_checkout.UpdateTemplateCheckoutModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_options.UpdatePriceLevelRepository

class UpdatePriceLevelUseCase(
    private val repository: UpdatePriceLevelRepository
) {
    suspend operator fun invoke(
        id: String,
        index:Int,
    ): UpdateTemplateCheckoutModel {
       return repository.updatePriceLevel(
           id=id,
           index=index,
        )
    }
}