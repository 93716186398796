package org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card

enum class TelephoneCodeEnum(
    val code:String,
) {

    GERMANY(
        code = "+49"
    ),
    SWITZERLAND(
        code = "+41"
    ),
    AUSTRIA(
        code = "+43"
    ),
    FRANCE(
        code = "+33"
    ),
    OTHER(
        code = "+"
    )


}