package org.hs_soft.runmynesto.domain.usecase.product.tab_restaurant

import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.restaurant_data.RestaurantDataModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.ProductRestaurantRepository


class ProductRestaurantUseCase(
    private val repository: ProductRestaurantRepository
) {
    suspend operator fun invoke(id:String): RestaurantDataModel {
        return repository.productRestaurant(id)
    }


}