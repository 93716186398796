package org.hs_soft.runmynesto.pages.home.mvi

import org.hs_soft.runmynesto.domain.model.WindowSizeModel
import org.hs_soft.runmynesto.domain.model.menu.MenuItemsEnum

sealed class HomeEvent {

    data object  GetHSSoftApps:HomeEvent()

    data object UserProfileInfo:HomeEvent()

    data object SignOut:HomeEvent()

    data object GetCheckoutData: HomeEvent()

    data object CloseMenu:HomeEvent()

    data object SetIsUserLoggedIn:HomeEvent()

    data object ChangeMenuStatus:HomeEvent()

    data object FetchIdToken :HomeEvent()

    data class OnChangeName(val value:String): HomeEvent()

    data class OnMenuItemClick(val menuItem:MenuItemsEnum):HomeEvent()

    data object ChangeShowingSearchBarInMobileStatus:HomeEvent()

    data object OnToolbarSettingIconClick:HomeEvent()

    data object OnToolbarCompaniesMenuIconClick :HomeEvent()

    data class InitWindowsSize(val windowsSize: WindowSizeModel) :HomeEvent()






}