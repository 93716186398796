package org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetClosing(
    @SerialName("billNumEnd")
    val billNumEnd: Double?,
    @SerialName("billNumStart")
    val billNumStart: Double?,
    @SerialName("cancellationsCount")
    val cancellationsCount: Double?,
    @SerialName("cashRegisterId")
    val cashRegisterId: String?,
    @SerialName("cashbook")
    val cashbook: List<Cashbook>?,
    @SerialName("clientCardValue")
    val clientCardValue: Double?,
    @SerialName("clientCount")
    val clientCount: Double?,
    @SerialName("coupons")
    val coupons: List<Coupon>?,
    @SerialName("creditClientOrders")
    val creditClientOrders: List<CreditClientOrder>?,
    @SerialName("currency")
    val currency: List<Currency>?,
    @SerialName("dateInterval")
    val dateInterval: DateInterval?,
    @SerialName("dateTime")
    val dateTime: String?,
    @SerialName("doorCounter")
    val doorCounter: Double?,
    @SerialName("guestsCount")
    val guestsCount: Double?,
    @SerialName("id")
    val id: String?,
    @SerialName("inactive")
    val inactive: Boolean?,
    @SerialName("inactiveDate")
    val inactiveDate: String?,
    @SerialName("inactiveReason")
    val inactiveReason: String?,
    @SerialName("inactiveUserId")
    val inactiveUserId: String?,
    @SerialName("moneyCountDateTime")
    val moneyCountDateTime: String?,
    @SerialName("number")
    val number: Double?,
    @SerialName("paymentMethods")
    val paymentMethods: List<PaymentMethod>?,
    @SerialName("priceDifference")
    val priceDifference: List<PriceDifference>?,
    @SerialName("productCount")
    val productCount: Double?,
    @SerialName("salesGroups")
    val salesGroups: List<SalesGroup>?,
    @SerialName("specialSales")
    val specialSales: List<SpecialSale>?,
    @SerialName("taxes")
    val taxes: List<Taxe>?,
    @SerialName("total")
    val total: Double?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("users")
    val users: List<User>?
)