package org.hs_soft.runmynesto.domain.usecase.options.cash_flow

import org.hs_soft.runmynesto.domain.model.home.options.cash_flow.app_config_model.AppConfigModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.cash_flow.UpdateAppConfigRepository


class UpdateAppConfigUseCase(
    private val repository: UpdateAppConfigRepository
) {
    suspend operator fun invoke(
        title:String,
        value:List<String?>,
    ): AppConfigModel {
        return repository.updateAppConfig(
            title = title,
            value = value,
        )
    }
}