package org.hs_soft.runmynesto.pages.home.mvi


import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.WindowSizeModel
import org.hs_soft.runmynesto.domain.model.menu.MenuItemsEnum
import org.hs_soft.runmynesto.domain.usecase.home.FetchIdTokenUseCase
import org.hs_soft.runmynesto.domain.usecase.home.HSSoftAppsUseCase
import org.hs_soft.runmynesto.domain.usecase.home.SaveCheckOutUseCase
import org.hs_soft.runmynesto.domain.usecase.home.ShopListUseCase
import org.hs_soft.runmynesto.domain.usecase.home.SignOutUseCase
import org.hs_soft.runmynesto.domain.usecase.home.UserProfileDataUseCase
import org.hs_soft.runmynesto.domain.usecase.login.IsLoggedInUseCase
import org.hs_soft.runmynesto.domain.usecase.options.operation.OptionDataUseCase
import org.hs_soft.runmynesto.domain.usecase.product.SaveCompanyProfileIdUseCase
import org.hs_soft.runmynesto.domain.usecase.user.CheckOutDataUseCase
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.TokenProvider
import org.hs_soft.runmynesto.domain.util.handleError
import org.hs_soft.runmynesto.domain.util.helper.BaseViewModel

class HomeViewModel(
    private val tokenProvider: TokenProvider,
    private val fetchIdTokenUseCase: FetchIdTokenUseCase,
    private val isLoggedInUseCase: IsLoggedInUseCase,
    private val checkOutDataUseCase: CheckOutDataUseCase,
    private val saveCheckOutUseCase: SaveCheckOutUseCase,
    private val signOutUseCase:SignOutUseCase,
    private val userProfileDataUseCase: UserProfileDataUseCase,
    private val hsSoftAppsUseCase: HSSoftAppsUseCase,
    private val shopListUseCase: ShopListUseCase,
    private val optionsDataUseCase:OptionDataUseCase,
    private val saveCompanyProfileIdUseCase:SaveCompanyProfileIdUseCase,
):BaseViewModel(){

    private val _state = MutableStateFlow(HomeState())
    val state: StateFlow<HomeState> = _state


     fun onEvent(event: HomeEvent) {
        when (event) {

            is HomeEvent.SaveCountryId ->{
                saveCountryId()
            }

            is HomeEvent.DismissAllDialogs->{
                dismissAllDialogs()
            }


            is HomeEvent.GetHSSoftApps->{
                getHSSoftApps()
            }

            is HomeEvent.SignOut->{
                signOut()
            }
            is HomeEvent.OnToolbarApplicationsMenuIconClick->{
                onToolbarApplicationsMenuIconClick()
            }
            is HomeEvent.UserProfileInfo->{
                getUserProfileInfo()
            }
            is HomeEvent.OnToolbarSettingIconClick->onToolbarSettingIconClick()
            is HomeEvent.ChangeShowingSearchBarInMobileStatus->changeShowingSearchBarInMobileStatus()
            is HomeEvent.GetCheckoutData->getAndSaveCheckoutData()
            is HomeEvent.SetIsUserLoggedIn->setIsUserLoggedIn()
            is HomeEvent.FetchIdToken->fetchUserIdToken( )
            is HomeEvent.ChangeMenuStatus ->changeMenuStatus()
            is HomeEvent.CloseMenu ->closeMenu()
            is HomeEvent.InitWindowsSize ->initWindowSize(event.windowsSize)
            is HomeEvent.OnChangeName -> changeName(event.value)
            is HomeEvent.OnMenuItemClick->onMenuItemClick(menuItem = event.menuItem)
            is HomeEvent.GetShopList->getShopList()

        }
    }

    private fun saveCountryId(){
       launch {
            try {
                val result=optionsDataUseCase()
                    .data.cashAssist.getAppConfig?.countryId

                if (result!=null){
                    try {
                        saveCompanyProfileIdUseCase(id = result)
                    }catch (e:Exception){
                        handleError(e)
                    }
                }


            }catch (e:Exception){

                handleError(
                    error = e,
                )
            }


        }
    }

    private fun dismissAllDialogs() {
        _state.value=_state.value.copy(
            showSettingDialog = false,
            showApplications = false,
        )

    }


    private fun getShopList() {
       launch {

            try {
                _state.value = _state.value.copy(isLoading = true)
                shopListUseCase()
                _state.value = _state.value.copy(isLoading = false,)
            } catch (e: Exception) {
                _state.value = _state.value.copy(
                    isLoading = false,
                )
            }
        }


    }

    private fun onMenuItemClick(
        menuItem:MenuItemsEnum
    ) {
        if (state.value.activePage==menuItem)
            _state.value=_state.value.copy(isMenuExpandedHorizontally = false)
        else{
            _state.value=_state.value.copy(
                activePage =menuItem,
                isMenuExpandedHorizontally = false
            )
        }

    }

    private fun getHSSoftApps() {
        launch {
            _state.value = _state.value.copy(
                isLoading = true
            )
            try {
                val result= hsSoftAppsUseCase()
                _state.value=_state.value.copy(
                    listOfHSSortsApps=result.data?.listHSWebApps
                )
            } catch (e: Exception) {
                _state.value = _state.value.copy(
                    isLoading = false,
                )
            }
        }



    }

    private fun getUserProfileInfo() {
        launch {
            _state.value = _state.value.copy(
                isLoading = true
            )
            try {
               val result= userProfileDataUseCase()
               _state.value=_state.value.copy(
                   profileInfo = result.data?.getUserProfileData
               )
            } catch (e: Exception) {
                _state.value = _state.value.copy(
                    isLoading = false,
                )
            }
        }


    }


    private fun signOut() {
        launch {
            _state.value = _state.value.copy(
                isLoading = true
            )
            try {
                signOutUseCase(
                    onSuccess = {
                       _state.value=_state.value.copy(
                           isLoggedIn = false,
                           isLoading = false,
                           showSettingDialog = false
                       )
                    },
                    onError = {
                        _state.value = _state.value.copy(
                            isLoading = false,
                        )
                    }
                )
            } catch (e: Exception) {
                _state.value = _state.value.copy(
                    isLoading = false,
                )
            }
        }


    }

    private fun onToolbarApplicationsMenuIconClick() {
        _state.value=_state.value.copy(
            showApplications = !state.value.showApplications,
            showSettingDialog = false
        )
    }

    private fun onToolbarSettingIconClick() {

        _state.value=_state.value.copy(
            showSettingDialog=!state.value.showSettingDialog,
            showApplications = false
        )
    }

    private fun changeShowingSearchBarInMobileStatus() {
        _state.value=_state.value.copy(
            showSearchBarInMobileMode = !state.value.showSearchBarInMobileMode
        )
    }

    private fun getAndSaveCheckoutData() {
         launch {
            try {
                val result=checkOutDataUseCase()
                saveCheckOutUseCase(
                    model = result
                )

            }catch (e:Exception){
                handleError(
                    error = e,
                )
            }

        }

    }



    private fun updateToken(newToken: String) {
        tokenProvider.updateToken(newToken)
    }

    private fun setIsUserLoggedIn() {
       launch {
            _state.value=_state.value.copy( isLoggedIn = true)
            try {
                 val result=isLoggedInUseCase()
                _state.value=_state.value.copy( isLoggedIn = result)
            }catch (e:Exception){
                _state.value=_state.value.copy(isLoggedIn = false)
            }
        }
    }



    private fun fetchUserIdToken( ) {
       launch {
            try {
                val idToken=fetchIdTokenUseCase()
                _state.value=_state.value.copy(
                    idToken = idToken,
                    isLoading = false,

                )
                updateToken(idToken)
            }catch (e:Exception){
                console.log(e)
                _state.value=_state.value.copy(isLoading = false)
            }
        }



    }

    private fun closeMenu() {
        _state.value=_state.value.copy(isMenuExpandedHorizontally = false)
    }

    private fun changeMenuStatus() {
        _state.value=_state.value.copy(
            isMenuExpandedHorizontally =
            !state.value.isMenuExpandedHorizontally)
    }



    private fun initWindowSize(windowsSize: WindowSizeModel) {
        if(windowsSize.width> Constants.Responsive.MaxTabletWidth){
            _state.value=_state.value.copy(
                windowSizeMode = WindowSizeModeEnum.Desktop
            )
        }else if(windowsSize.width< Constants.Responsive.MaxTabletWidth
            && windowsSize.width> Constants.Responsive.MaxPhoneWidth){
            _state.value=_state.value.copy(
                windowSizeMode = WindowSizeModeEnum.TABLET
            )
        }else{
            _state.value=_state.value.copy(
                windowSizeMode = WindowSizeModeEnum.PHONE
            )
        }

    }


    private fun changeName(value:String){
        _state.value=_state.value.copy(username = value)
    }





}