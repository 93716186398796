package org.hs_soft.runmynesto.di.login

import org.hs_soft.runmynesto.data.api_datasource.authentication.IsLoggedInRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.authentication.SignInRepositoryImpl
import org.hs_soft.runmynesto.domain.repository.network_datasource.login.IsLoggedInRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.login.SignInRepository
import org.koin.dsl.module

val loginRepositoryModule= module {

    single<SignInRepository> {
        SignInRepositoryImpl()
    }

    single<IsLoggedInRepository> {
        IsLoggedInRepositoryImpl()
    }


}