package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.devices

import org.hs_soft.runmynesto.domain.config.Strings

enum class CreditCardsEnum(
    val cardNameValue:String,
    val cardNameTitle:String,
    val fromAmount:String,
    val variable:String,
    val receiptCopy:String,
)
{
    CARD_1(
        cardNameValue = "",
        cardNameTitle = Strings.name1,
        fromAmount = "",
        variable = "",
        receiptCopy = ""
    ),
    CARD_2(
        cardNameValue = "",
        cardNameTitle =Strings.name2,
        fromAmount = "",
        variable = "",
        receiptCopy = ""
    ),
    CARD_3(
        cardNameValue = "",
        cardNameTitle = Strings.name3,
        fromAmount = "",
        variable = "",
        receiptCopy = ""
    ),
    CARD_4(
        cardNameValue = "",
        cardNameTitle = Strings.name4,
        fromAmount = "",
        variable = "",
        receiptCopy = ""
    ),
    CARD_5(
        cardNameValue = "",
        cardNameTitle =Strings.name5,
        fromAmount = "",
        variable = "",
        receiptCopy = ""
    ),
    CARD_6(
        cardNameValue = "",
        cardNameTitle =Strings.name6,
        fromAmount = "",
        variable = "",
        receiptCopy = ""
    ),
    CARD_7(
        cardNameValue = "",
        cardNameTitle = Strings.name7,
        fromAmount = "",
        variable = "",
        receiptCopy = ""
    ),
    CARD_8(
        cardNameValue = "",
        cardNameTitle =Strings.name8,
        fromAmount = "",
        variable = "",
        receiptCopy = ""
    ),
    CARD_9(
        cardNameValue = "",
        cardNameTitle =Strings.name9,
        fromAmount = "",
        variable = "",
        receiptCopy = ""
    ),
    CARD_10(
        cardNameValue = "",
        cardNameTitle =Strings.name10,
        fromAmount = "",
        variable = "",
        receiptCopy = ""
    ),

}