package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.overflowText

import org.hs_soft.runmynesto.domain.util.Res
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit

@Composable
fun HeaderTitle(
    modifier: Modifier=Modifier,
    title:String?,
    fontWeight: FontWeight=FontWeight.Medium,
    arrangement:Arrangement.Horizontal=Arrangement.Start,
    fillMaxWidth:Boolean=false,
    descending:Boolean?=null,
    width: CSSSizeValue<CSSUnit.px> = Dimen.headerMediumWidth,
    textColor:CSSColorValue=Theme.Secondary.rgb,
    onTitleClick:(SyntheticMouseEvent)->Unit={},
)
{
    Row(
        modifier = modifier
            .width(width)
            .thenIf(
                fillMaxWidth,
                modifier.fillMaxWidth()
            )

            .height(Dimen.kitchenInfoTitleHeight)
            .padding(topBottom = Dimen.smallPadding)
            .onClick() {
                onTitleClick(it)
            }
        , verticalAlignment = Alignment.CenterVertically
        , horizontalArrangement =arrangement
    )
    {
        SpanText(
            text = title?:"",
            modifier = Modifier
                .cursor(Cursor.Pointer)
                .fontWeight(fontWeight)
                .fontSize(FontSizes.mediumFontSize)
                .lineHeight(FontLineHeight.lineMedium)
                .letterSpacing(LetterSpacing.semiMedium)
                .fontFamily(FontFamily.Jakarta)
                .overflowText()
                .color(textColor)
        )

        VectorIcon(
            modifier = Modifier.padding(Dimen.smallPadding),
            pathData = if(descending==true)
                Res.PathIcon.sortDesc
            else Res.PathIcon.sortAsc
            ,
            height = Dimen.mediumIconSize,
            width = Dimen.mediumIconSize,
            color = Theme.GrayAlpha56.hex,
            visibilityStatus = descending!=null
        )




    }
}

