package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.devices.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.devices.CreditCardsEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.CreditCards
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.jetbrains.compose.web.css.px

@Composable
fun CreditConfigCards(
    creditCard: CreditCards?,
    updateData:(String,dynamic)->Unit,
)
{
    Column {
        MediumContentTitle(
            title = Strings.creditCard,
            marginTop = 0.px,
            marginBottom = 0.px
        )

        CreditCardsEnum.entries.forEachIndexed{index,item->
            CreditCardItem(
                name = item.cardNameTitle,
                index=index,
                clientButtonName= creditCard?.clientButtonName?.get(index),
                isPieceMeal=creditCard?.isPiecemeal?.get(index),
                receiptCopies=creditCard?.receiptCopies?.get(index),
                startPiecemeal=creditCard?.startPiecemeal?.get(index),
                updateData = {title:String,value:dynamic->
                    updateData(title,value)
                }

            )
        }
    }

}