package org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Parameters(
    @SerialName("accentColor")
    val accentColor: String?,
    @SerialName("activateTipFunction")
    val activateTipFunction: Boolean?,
    @SerialName("additionName")
    val additionName: String?,
    @SerialName("advertisingText")
    val advertisingText: String?,
    @SerialName("apaleoPmsProperty")
    val apaleoPmsProperty: String?,
    @SerialName("autoCloseBill")
    val autoCloseBill: Boolean?,
    @SerialName("autoSelectFirstCategory")
    val autoSelectFirstCategory: Boolean?,
    @SerialName("backgroundColor")
    val backgroundColor: String?,
    @SerialName("builtInFonts")
    val builtInFonts: Boolean?,
    @SerialName("cardTextColor")
    val cardTextColor: String?,
    @SerialName("categoryFont")
    val categoryFont: CategoryFont?,
    @SerialName("categoryGridX")
    val categoryGridX: Int?,
    @SerialName("categoryGridY")
    val categoryGridY: Int?,
    @SerialName("city")
    val city: String?,
    @SerialName("closingTime")
    val closingTime: String?,
    @SerialName("closingType")
    val closingType: String?,
    @SerialName("companyName")
    val companyName: String?,
    @SerialName("country")
    val country: Int?,
    @SerialName("creditCards")
    val creditCards: CreditCards?,
    @SerialName("designProject")
    val designProject: String?,
    @SerialName("disallowLoyaltyCards")
    val disallowLoyaltyCards: Boolean?,
//    @SerialName("discountForm")
//    val discountForm: DiscountForm?,
    @SerialName("displayArticle")
    val displayArticle: Int?,
    @SerialName("displayEitherCategoriesOrProducts")
    val displayEitherCategoriesOrProducts: Boolean?,
    @SerialName("drawerCloseCheck")
    val drawerCloseCheck: Boolean?,
    @SerialName("egumaCreditId")
    val egumaCreditId: String?,
    @SerialName("employeeLoyaltyProductId")
    val employeeLoyaltyProductId: String?,
    @SerialName("foreignCurrency")
    val foreignCurrency: ForeignCurrency?,
    @SerialName("gloryTerminalAddress")
    val gloryTerminalAddress: String?,
    @SerialName("gloryTerminalConnected")
    val gloryTerminalConnected: Boolean?,
    @SerialName("gloryTerminalPassword")
    val gloryTerminalPassword: String?,
    @SerialName("gloryTerminalUser")
    val gloryTerminalUser: String?,
    @SerialName("guradoCreditId")
    val guradoCreditId: String?,
    @SerialName("hasBarcodeByReceiptTotal")
    val hasBarcodeByReceiptTotal: Boolean?,
    @SerialName("hasFastLine")
    val hasFastLine: Boolean?,
    @SerialName("hideOpenDrawerBtn")
    val hideOpenDrawerBtn: Boolean?,
    @SerialName("idleMinutesBeforeLock")
    val idleMinutesBeforeLock: Int?,
    @SerialName("inCafeImg")
    val inCafeImg: String?,
    @SerialName("installedPrinters")
    val installedPrinters: List<String?>?,
    @SerialName("isActiveTimeAssist")
    val isActiveTimeAssist: Boolean?,
    @SerialName("isCashierHasManyDrawers")
    val isCashierHasManyDrawers: Boolean?,
    @SerialName("isDiscountFormDisabled")
    val isDiscountFormDisabled: Boolean?,
    @SerialName("isDrawerOpenDuringClosing")
    val isDrawerOpenDuringClosing: Boolean?,
    @SerialName("isEgumaActive")
    val isEgumaActive: Boolean?,
    @SerialName("isEmployeeClosingActive")
    val isEmployeeClosingActive: Boolean?,
    @SerialName("isGuradoActive")
    val isGuradoActive: Boolean?,
    @SerialName("isHospitalityReceiptEnabled")
    val isHospitalityReceiptEnabled: Boolean?,
    @SerialName("isInCafeConfirmationBeforePayment")
    val isInCafeConfirmationBeforePayment: Boolean?,
    @SerialName("isInCafeMode")
    val isInCafeMode: Boolean?,
    @SerialName("isInCafeModeDefault")
    val isInCafeModeDefault: Boolean?,
    @SerialName("isInCafeModeInt")
    val isInCafeModeInt: Int?,
    @SerialName("isMainScreenWithVideo")
    val isMainScreenWithVideo: Boolean?,
    @SerialName("isQRCodeBillEnabled")
    val isQRCodeBillEnabled: Boolean?,
    @SerialName("isReturnButtonDeleted")
    val isReturnButtonDeleted: Boolean?,
    @SerialName("isSellProductInWorkingTime")
    val isSellProductInWorkingTime: Boolean?,
    @SerialName("isTSEStillLive")
    val isTSEStillLive: Boolean?,
    @SerialName("itemLoyaltyCard")
    val itemLoyaltyCard: String?,
    @SerialName("language")
    val language: String?,
    @SerialName("localIPAdress")
    val localIPAdress: String?,
    @SerialName("loginMode")
    val loginMode: Int?,
    @SerialName("loginOnlyAfterTimeRecording")
    val loginOnlyAfterTimeRecording: Boolean?,
    @SerialName("logo")
    val logo: Logo?,
    @SerialName("logoutOnlyAfterTimeRecording")
    val logoutOnlyAfterTimeRecording: Boolean?,
    @SerialName("loyaltyCardIcon")
    val loyaltyCardIcon: String?,
    @SerialName("loyaltyCardImg")
    val loyaltyCardImg: String?,
    @SerialName("loyaltyProdItemId")
    val loyaltyProdItemId: String?,
    @SerialName("loyaltyProdItemIdOp2")
    val loyaltyProdItemIdOp2: String?,
    @SerialName("loyaltyProdItemIdOp3")
    val loyaltyProdItemIdOp3: String?,
    @SerialName("loyaltyProdItemIdOp4")
    val loyaltyProdItemIdOp4: String?,
    @SerialName("loyaltyProdItemIdOp5")
    val loyaltyProdItemIdOp5: String?,
    @SerialName("mainScreenLogo")
    val mainScreenLogo: String?,
    @SerialName("multiLanguage")
    val multiLanguage: Boolean?,
    @SerialName("nonGuestTransfer")
    val nonGuestTransfer: Boolean?,
    @SerialName("normalVat")
    val normalVat: Double?,
    @SerialName("optional1")
    val optional1: String?,
    @SerialName("optional2")
    val optional2: String?,
    @SerialName("paymentBackgroundImg")
    val paymentBackgroundImg: String?,
    @SerialName("paymentTerminal")
    val paymentTerminal: PaymentTerminal?,
    @SerialName("phone")
    val phone: String?,
    @SerialName("pmsAutoPrintBills")
    val pmsAutoPrintBills: Boolean?,
    @SerialName("pmsProtelIP")
    val pmsProtelIP: String?,
    @SerialName("pmsProtelPort")
    val pmsProtelPort: String?,
    @SerialName("pmsType")
    val pmsType: String?,
    @SerialName("postCode")
    val postCode: String?,
    @SerialName("preoderPickupProduct")
    val preoderPickupProduct: String?,
    @SerialName("preoderPrepaidProduct")
    val preoderPrepaidProduct: String?,
    @SerialName("priceLevelForm")
    val priceLevelForm: List<PriceLevelForm?>?,
    @SerialName("printer")
    val printer: Printer?,
    @SerialName("productFont")
    val productFont: ProductFont?,
    @SerialName("reducedVat")
    val reducedVat: Double?,
    @SerialName("restaurantBirdViewActive")
    val restaurantBirdViewActive: Boolean?,
    @SerialName("restaurantDefaultRoom")
    val restaurantDefaultRoom: String?,
    @SerialName("restaurantIsMainMode")
    val restaurantIsMainMode: Boolean?,
    @SerialName("retrieveNumberOfGuests")
    val retrieveNumberOfGuests: Boolean?,
    @SerialName("scale")
    val scale: Int?,
    @SerialName("scaleDisplayAlwaysVisible")
    val scaleDisplayAlwaysVisible: Boolean?,
    @SerialName("serviceCallMode")
    val serviceCallMode: Int?,
    @SerialName("settingsPassword")
    val settingsPassword: String?,
    @SerialName("showOrderViewKiosk")
    val showOrderViewKiosk: Boolean?,
    @SerialName("smsNotificationPhoneNumbers")
    val smsNotificationPhoneNumbers: List<String?>?,
    @SerialName("specialDesignType")
    val specialDesignType: String?,
    @SerialName("specialVat")
    val specialVat: Double?,
    @SerialName("street")
    val street: String?,
    @SerialName("takeOutImg")
    val takeOutImg: String?,
    @SerialName("textColor")
    val textColor: String?,
    @SerialName("tseAdminPin")
    val tseAdminPin: String?,
    @SerialName("tseAdminPuk")
    val tseAdminPuk: String?,
    @SerialName("tseEpsonIp")
    val tseEpsonIp: String?,
    @SerialName("tseIsActive")
    val tseIsActive: Int?,
    @SerialName("tsePrintQRCode")
    val tsePrintQRCode: Int?,
    @SerialName("tseTimeAdminPin")
    val tseTimeAdminPin: String?,
    @SerialName("tseTimeAdminPuk")
    val tseTimeAdminPuk: String?,
    @SerialName("tseType")
    val tseType: Int?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("useOldDesign")
    val useOldDesign: Boolean?,
    @SerialName("vatNumber")
    val vatNumber: String?,
    @SerialName("weightTara")
    val weightTara: WeightTara?
)