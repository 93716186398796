package org.hs_soft.runmynesto.pages.components.menu

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.kobweb_aws_cognito.domain.model.menu.SubMenuModel
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Dimen.menuIconSize
import org.hs_soft.runmynesto.domain.config.Dimen.menuItemRowWidth
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.MenuVectorIcon
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun MenuItemRow(
    itemTitle: String,
    imgSrc: String,
    hoverColor: CSSColorValue,
    subData: List<SubMenuModel> = listOf(),
    isMenuExpanded: Boolean = false,
    backgroundColor:CSSColorValue,
    isSelected: Boolean,
    onRowClick: () -> Unit,
)
{
    var isItemHovered by remember { mutableStateOf(false) }
    var expandedVertically by remember { mutableStateOf(false) }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .minHeight(Dimen.menuItemRowHeight)
        ,
        horizontalAlignment = Alignment.Start
    )
    {
        Row(
            modifier =Modifier
                .fillMaxWidth()
                .minHeight(Dimen.menuItemRowHeight)
                .attrsModifier {
                    style {
                        property("transition",
                            "width ${Constants.fastTransition} ease," +
                                    " border-radius ${Constants.fastTransition} ease")

                        property("transition",
                            "background-color ${Constants.mediumTransition} ease")
                        if (isMenuExpanded) {
                            property("border-radius", "0px")
                        } else {
                            property("border-radius", "50%")
                        }
                    }
                }
                .borderRadius(
                    if(isMenuExpanded) 0.px else 50.percent
                )
                .then(
                    when {
                        isSelected ->Modifier.backgroundColor(backgroundColor)
                        isItemHovered -> Modifier.backgroundColor(hoverColor)
                        else -> Modifier
                    }
                )
                .onMouseOver { isItemHovered = true }
                .onMouseLeave { isItemHovered=false }
                .onClick {
                    expandedVertically = !expandedVertically
                    onRowClick()
                }
                .cursor(Cursor.Pointer)

            ,
            verticalAlignment = Alignment.CenterVertically
        )
        {
            Box(modifier = Modifier.padding(Dimen.mediumPadding))
            {
                MenuVectorIcon(
                    modifier = Modifier,
                    pathData = imgSrc,
                    color = Theme.Gray61.hex

                )
            }


            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .attrsModifier {
                        style {
                            property("width",
                                if(isMenuExpanded){
                                    menuItemRowWidth.value.toInt()
                                    - menuIconSize.value.toInt()
                                }else 0
                            )
                            property("opacity",
                                if (isMenuExpanded) "1" else "0")
                            property("overflow",
                                "hidden")
                            property("transition",
                                "width ${Constants.fastTransition} ease," +
                                        " opacity ${Constants.fastTransition} ease")

                        }
                    }
            )
            {
                MenuItemTitle(itemTitle)

                Spacer()

                if(subData.isNotEmpty()) {
                    expandingIcon(
                        expandedVertically = expandedVertically,
                        color = Theme.Primary.hex
                    )
                }
            }

        }

        SubMenu(subData, hoverColor,expandedVertically)


    }


}

@Composable
private fun MenuItemTitle(itemTitle: String)
{
    SpanText(
        modifier = Modifier
            .fontFamily(FontFamily.Montserrat)
            .fontWeight(FontWeight.Normal)
            .color(Theme.Secondary.rgb)
            .fontSize(FontSizes.mediumFontSize)
            .lineHeight(FontLineHeight.lineSemiLarge)
            .letterSpacing(LetterSpacing.small),
        text = itemTitle
    )
}

@Composable
private fun expandingIcon(
    expandedVertically: Boolean,
    color:String,
)
{
    MenuVectorIcon(
        modifier = Modifier
            .margin(right = Dimen.mediumPadding)
            .attrsModifier {
                if (!expandedVertically) {
                    style {
                        property(
                            "transform",
                            "rotate(180deg)"
                        )
                        property(
                            "transition",
                            "transform ${Constants.fastTransition} ease"
                        )
                    }
                } else {
                    style {
                        property(
                            "transform",
                            "rotate(0deg)"
                        )
                        property(
                            "transition",
                            "transform ${Constants.fastTransition} ease"
                        )
                    }
                }
            },
        pathData = Res.PathIcon.expandlessFilled,
        color =color
    )
    Image(
        src = Res.PathIcon.expandlessFilled,
    )
}




