package org.hs_soft.runmynesto.di.template

import org.hs_soft.runmynesto.data.api_datasource.template.AddCategoryRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.template.TemplateCategoryRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.template.UpdateListOfCategoriesPositionsRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.template.tab_checkout.UpdateTemplateCheckoutRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.template.tab_options.PriceLevelsRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.template.tab_options.UpdatePriceLevelRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.template.tab_surcharge_in_house.UpdateSurchargeRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.template.tab_general.GetDishPictureRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.template.tab_general.SingleTemplateCategoryRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.template.tab_general.UpdateProductsPositionsRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.template.tab_general.UpdateTemplateCategoryImgRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.template.tab_general.UpdateTemplateCategoryRepositoryImpl
import org.hs_soft.runmynesto.domain.repository.network_datasource.AddCategoryRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.TemplateCategoryRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.UpdateListOfCategoriesPositionsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_ckechout.UpdateTemplateCheckoutRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_options.PriceLevelsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_options.UpdatePriceLevelRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_surcharge_in_house.UpdateSurchargeRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.GetDishPictureRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.SingleTemplateCategoryRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.UpdateProductsPositionsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.UpdateTemplateCategoryImgRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.UpdateTemplateCategoryRepository
import org.koin.dsl.module

val templateRepositoryModule= module{

    single<TemplateCategoryRepository> {
        TemplateCategoryRepositoryImpl(
            apiClient = get(),
            requestManager = get()
//              apolloClient = get()
        )
    }


    single<SingleTemplateCategoryRepository> {
        SingleTemplateCategoryRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<AddCategoryRepository> {
        AddCategoryRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<UpdateProductsPositionsRepository> {
        UpdateProductsPositionsRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }
    single<UpdateTemplateCategoryRepository> {
        UpdateTemplateCategoryRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<UpdateTemplateCategoryImgRepository>{
        UpdateTemplateCategoryImgRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<UpdateSurchargeRepository>{
        UpdateSurchargeRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<PriceLevelsRepository>{
        PriceLevelsRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }
    single<UpdatePriceLevelRepository>{
        UpdatePriceLevelRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<UpdateTemplateCheckoutRepository>{
        UpdateTemplateCheckoutRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<GetDishPictureRepository>{
        GetDishPictureRepositoryImpl(get())
    }

    single<UpdateListOfCategoriesPositionsRepository> {
        UpdateListOfCategoriesPositionsRepositoryImpl(get(),get())
    }

}