package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.product_general_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.AppConstantsEnum
import org.hs_soft.runmynesto.domain.util.toTwoDecimalString
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.DropdownComponent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun Price2(
    windowSizeMode: WindowSizeModeEnum,
    priceInCafe: Double?,
    selectedVat2:String?,
    countryId:String,
    updateVatCode2: (String) -> Unit,
    updatePriceInCafe: (List<Double?>) -> Unit,
) {
    if (windowSizeMode==WindowSizeModeEnum.PHONE)
    {
        Column() {
            content(
                windowSizeMode = windowSizeMode,
                priceInCafe=priceInCafe,
                countryId=countryId,
                updatePriceInCafe=updatePriceInCafe,
                selectedVat2=selectedVat2,
                updateVatCode2=updateVatCode2
            )
        }
    }else{
        Row() {
            content(
                windowSizeMode=windowSizeMode,
                priceInCafe=priceInCafe,
                countryId=countryId,
                updatePriceInCafe=updatePriceInCafe,
                selectedVat2=selectedVat2,
                updateVatCode2=updateVatCode2
            )
        }
    }

}

@Composable
private fun content(
    priceInCafe: Double?,
    windowSizeMode:WindowSizeModeEnum,
    countryId: String,
    updatePriceInCafe: (List<Double?>) -> Unit,
    selectedVat2: String?,
    updateVatCode2: (String) -> Unit
) {
    CustomEditText(
        modifier = Modifier,
        marginBottom = if (windowSizeMode==WindowSizeModeEnum.PHONE)
            0.px else Dimen.mediumPadding
        ,
        width = Dimen.normalEditTextWidth,
        height = Dimen.mediumOutlinedEditTextHeight,
        label = Strings.price2,
        textAlign = TextAlign.End,
        value = priceInCafe.toTwoDecimalString(useDotDigitStatus(countryId)),
        isDecimal = true,
        hasLabelSpace = false,
        usingDotForDigit = useDotDigitStatus(countryId),
        isRequired = false,
        defaultBorderColor = Theme.GrayCD.rgb,
        isOutlinedEditText = true,
        onFocusIn = {},
        onFocusOut = {
            updatePriceInCafe(
                listOf<Double?>(
                    if (it.isEmpty()) null
                    else (it.replace(',', '.')).toDouble(),
                )
            )
        },
        onInputChange = {},

        )

    DropdownComponent(
        modifier = Modifier.margin(left =if (windowSizeMode==WindowSizeModeEnum.PHONE)
            0.px else
            Dimen.normalPadding
        ),
        options = AppConstantsEnum.entries,
        label = Strings.vat,
        selectedItem = AppConstantsEnum.entries.find {
            it.value == selectedVat2
        },
        content = {
            if (it is AppConstantsEnum)
                Text(it.label)
        },
        onOptionClick = {
            val option: AppConstantsEnum? = AppConstantsEnum.entries.find { item ->
                item.toString() == it
            }

            if (option != null) {
                updateVatCode2(option.value)
            }

        }
    )
}