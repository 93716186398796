package org.hs_soft.runmynesto.domain.model.home.home.user_profile_info


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetUserProfileData(
    @SerialName("avatarUrl")
    val avatarUrl: String?,
    @SerialName("company")
    val company: String?,
    @SerialName("name")
    val name: String?,
    @SerialName("email")
    val email: String?,
)