package org.hs_soft.runmynesto.domain.usecase.user.tab_checkout

import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.update_checkout.UpdateUserCheckoutModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.user.tab_checkout.UpdateUserCheckoutRepository

class UpdateUserCheckoutUseCase(
    private val repository: UpdateUserCheckoutRepository
) {
    suspend operator fun invoke(
        userId:String,
        dataValue: MutableSet<String>,
    ): UpdateUserCheckoutModel {
       return repository.updateUserCheckout(
           userId=userId,
           dataValue=dataValue,
        )
    }
}