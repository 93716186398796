package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.google

import Carousel
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config.GetVirtualCardConfig
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.dialog.WalletCardItem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun GoogleWalletDialog(
    windowSizeMode:WindowSizeModeEnum,
    info: GetVirtualCardConfig?,
    closeDialog:()->Unit,
)
{
    var currentCardIndex = remember { mutableStateOf(0) }
    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                closeDialog()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(2f)
    ){
        Column(
            modifier = Modifier
                .onClick {
                    event: SyntheticMouseEvent -> event.stopPropagation()

                }
                .align(Alignment.Center)
                .width(
                    if (windowSizeMode== WindowSizeModeEnum.PHONE)
                        (Dimen.walletDialogWidth.value.toInt()*
                                Constants.Responsive.dialogPhoneResize).px
                    else Dimen.walletDialogWidth
                )
                .height(Dimen.walletDialogHeight)
                .backgroundColor(Theme.White.rgb)
                .zIndex(Constants.ZIndex.smallZIndex)
                .boxShadow(
                    color = Theme.GrayAlpha12.rgb,
                    blurRadius = Dimen.normalBlurRadius,
                    spreadRadius = Dimen.normalSpreadRadius
                )
                .borderRadius(r= Dimen.lowBorderRadius),
            horizontalAlignment = Alignment.Start
        ){
            SpanText(
                text = Strings.previewForGoogleWallet,
                modifier = Modifier
                    .margin(Dimen.mediumPadding)
                    .cursor(Cursor.Pointer)
                    .textAlign(TextAlign.Start)
                    .fontSize(FontSizes.mediumFontSize)
                    .fontFamily(FontFamily.Montserrat)
                    .fontWeight(FontWeight.Medium)
                    .color(Theme.Primary.rgb)
            )

            if (windowSizeMode!=WindowSizeModeEnum.PHONE)
            {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(Dimen.walletCardParentHeight)
                    ,
                    horizontalArrangement = Arrangement.Center
                )
                {
                    WalletCardItem(
                        title = Strings.cardPreview,
                        content = {
                            GoogleCardPreview(
                                info = info,
                            )
                        },
                        backgroundColor = info?.backgroundColor
                    )

                    WalletCardItem(
                        title = Strings.cardInfoPreview,
                        content = {
                            GoogleCardInfoPreview(
                                info = info,
                            )
                        },
                        backgroundColor = info?.backgroundColor
                    )
                }
            }else{
                Carousel(
                    currentIndex=currentCardIndex,
                    height = Dimen.walletCardParentHeight,
                    items = getGoogleCardItems(info=info),
                )
            }


            Spacer()


            Box(
                modifier = Modifier.fillMaxWidth(),
            )
            {
                CustomButton(
                    modifier = Modifier
                        .align(Alignment.CenterEnd),
                    windowSizeMode =windowSizeMode,
                    topBottomMargin = Dimen.normalPadding,
                    shadowColor =  Theme.DarkBlue.rgb,
                    defaultTextColor = Theme.White.rgb,
                    title = Strings.close,
                    onButtonClick ={
                        closeDialog()
                    },
                )

                if (windowSizeMode==WindowSizeModeEnum.PHONE){
                    Row(
                        modifier = Modifier.align(Alignment.Center),
                        horizontalArrangement = Arrangement.Center
                    ) {
                        getGoogleCardItems(info=info).forEachIndexed { index, _ ->
                            Box(
                                modifier = Modifier
                                    .size(8.px)
                                    .margin(leftRight = Dimen.smallPadding)
                                    .backgroundColor(if (index == currentCardIndex.value)
                                        Theme.Primary.rgb else Theme.Gray61.rgb)
                                    .borderRadius(50.percent)
                                    .cursor(Cursor.Pointer)
                                    .onClick {
                                        currentCardIndex.value = index
                                    }
                            )
                        }
                    }
                }

            }





        }
    }

}


private fun getGoogleCardItems(
    info: GetVirtualCardConfig?,
): List<@Composable () -> Unit> {
    return listOf(
        {
            WalletCardItem(
                title = Strings.cardPreview,
                content = {
                    GoogleCardPreview(info)
                },
                info?.backgroundColor
            )
        },
        {
            WalletCardItem(
                title = Strings.cardInfoPreview,
                content = {
                    GoogleCardInfoPreview(info)
                },
                info?.backgroundColor,
            )
        }
    )
}