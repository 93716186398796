package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.grid_customer_card_bar.component


import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.home.customer_card.loyalty_card.LoyaltyCardItem
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.InfiniteScrollGrid
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.overflowY
import org.jetbrains.compose.web.css.vh

@Composable
fun CustomerCardGridItemList(
    items: List<LoyaltyCardItem?>,
    onItemClick: (String) -> Unit,
    id: String,
    contentHeight: Int= Constants.Responsive.gridContentHeight,
    loadMoreData:()->Unit,
) {
    val selectedItemId = remember { mutableStateOf<String?>(null) }

    LaunchedEffect(id) {
        selectedItemId.value = id
    }

    Box(
        modifier=
        VerticalScrollBehavior
            .toModifier()
            .position(Position.Relative)
            .height(contentHeight.vh)
            .styleModifier {
                flexGrow(1)
                overflowY("auto")
            }
            .fillMaxWidth()
    )
    {
        InfiniteScrollGrid(
            items = items,
            onLoadMore = {
                loadMoreData()
            }
        ){index->
            val model = items[index]
            CustomerCardGridItem(
                model = model,
                isSelected =selectedItemId.value==model?.id,
                onItemClick = { id ->
                    onItemClick(id)
                },
            )
        }

    }



}


