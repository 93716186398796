package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.draggable
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.closing_reports.CurrencyTypeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.ListingLayoutsEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.WaiterDeviceTypeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.GetCategory
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component.desktop.DesktopWaiterListTypeItem
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component.mobile.MobileWaiterListTypeItem
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.article_component.mobile.MobileWaiterTilesTypeItem
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun ArticlePreviewForMWAndDW(
    windowSizeMode: WindowSizeModeEnum,
    productId:MutableState<String>,
    selectedDeviceType: WaiterDeviceTypeEnum,
    selectedLayoutType: ListingLayoutsEnum,
    selectedCategory: GetCategory?,
    currencyType: CurrencyTypeEnum,
    showImgUrl: suspend (String) -> String,
    deleteGridProduct: (Int) -> Unit,
    setGridProduct:(String,Int)->Unit,
) {
    val listingLayoutsChunk=3
    val desktopWaiterListChunk=when(windowSizeMode) {
        WindowSizeModeEnum.Desktop ->6
        else ->2
    }
    val maxItems=400

    Column(
        modifier = VerticalScrollBehavior.toModifier()
            .thenIf(
                windowSizeMode==WindowSizeModeEnum.Desktop,
                Modifier.margin(right = Dimen.semiExtraLargePadding)
            )
            .borderSetting()
            .fillMaxWidth()
            .fillMaxHeight()
            .thenIf(
                windowSizeMode!=WindowSizeModeEnum.Desktop,
                    Modifier.height(Dimen.TemplateArticleListHeight)
            )
        ,
        horizontalAlignment = Alignment.CenterHorizontally,

    )
    {
        when(selectedDeviceType)
        {

            WaiterDeviceTypeEnum.MOBILE_WAITER->{
                when(selectedLayoutType){

                    ListingLayoutsEnum.LIST -> {

                        MobileWaiterListLayout(
                            productId=productId,
                            maxItems=maxItems,
                            selectedCategory=selectedCategory,
                            currencyType=currencyType,
                            showImgUrl={key:String->
                                showImgUrl(key)
                            },
                            deleteGridProduct={position:Int->
                                deleteGridProduct(position)
                            },
                            setGridProduct = {productId:String,position:Int->
                                setGridProduct(
                                    productId,position
                                )
                            }
                        )

                    }

                    ListingLayoutsEnum.TILES->{


                        MobileWaiterTilesLayout(
                            productId=productId,
                            maxItems=maxItems,
                            selectedCategory=selectedCategory,
                            listingLayoutsChunk=listingLayoutsChunk,
                            currencyType=currencyType,
                            showImgUrl={key:String->
                                showImgUrl(key)
                            },
                            deleteGridProduct = {position:Int->
                                deleteGridProduct(position)
                            },
                            setGridProduct = {productId:String,position:Int->
                                setGridProduct(
                                    productId,position
                                )
                            }

                        )

                    }
                }
            }
            WaiterDeviceTypeEnum.DESKTOP_WAITER->{

                DesktopWaiter(
                    productId=productId,
                    maxItems=maxItems,
                    selectedCategory=selectedCategory,
                    desktopWaiterListChunk=desktopWaiterListChunk,
                    showImgUrl={key:String->
                        showImgUrl(key)
                    },
                    deleteGridProduct={position:Int->
                        deleteGridProduct(position)
                    },
                    setGridProduct = {productId:String,position:Int->
                        setGridProduct(
                            productId,position
                        )
                    }

                )

            }
        }

    }



}

@Composable
private fun DesktopWaiter(
    productId:MutableState<String>,
    maxItems: Int,
    selectedCategory: GetCategory?,
    desktopWaiterListChunk: Int,
    showImgUrl: suspend (String) -> String,
    deleteGridProduct: ( Int) -> Unit,
    setGridProduct:(String,Int)->Unit,
) {

    val positionMap = remember(selectedCategory?.gridProducts) {
        selectedCategory?.gridProducts
            ?.sortedBy { it.position }
            ?.associateBy { it.position } ?: emptyMap()
    }

    (1..maxItems).chunked(desktopWaiterListChunk).forEach { rowPositions ->
        Row(
            horizontalArrangement = Arrangement.Start
        ) {
            rowPositions.forEach { position ->
                when (val model = positionMap[position]) {
                    null -> {
                        Box(
                            modifier = Modifier
                                .margin(Dimen.semiSemiNormalPadding)
                                .backgroundColor(Theme.GrayAlpha02.rgb)
                                .width(Dimen.desktopWaiterTilesTypeItemWidth)
                                .height(Dimen.desktopWaiterTilesTypeItemHeight)
                                .borderRadius(Dimen.normalBorderRadius)
                                .border(
                                    width = Dimen.smallThickness,
                                    style = LineStyle.Solid,
                                    color = Theme.GrayAlpha12.rgb
                                )
                                .onMouseOver {
                                    if (productId.value.isNotEmpty()){
                                        setGridProduct(
                                            productId.value,
                                            position,
                                        )
                                    }
                                }
                                .onMouseLeave {
                                    productId.value=""
                                }
                        )
                    }
                    else -> {
                        DesktopWaiterListTypeItem(
                            model = model,
                            showImgUrl = { key: String ->
                                showImgUrl(key)
                            },
                            deleteGridProduct = {
                                deleteGridProduct(
                                    model.position,
                                )

                            }
                        )
                    }
                }
            }
        }
    }
}

@Composable
private fun MobileWaiterTilesLayout(
    productId:MutableState<String>,
    maxItems: Int,
    selectedCategory: GetCategory?,
    listingLayoutsChunk: Int,
    currencyType: CurrencyTypeEnum,
    showImgUrl: suspend (String) -> String,
    deleteGridProduct: (Int) -> Unit,
    setGridProduct:(String,Int)->Unit,
) {
    val positionMap = remember(selectedCategory?.gridProducts) {
        selectedCategory?.gridProducts
            ?.sortedBy { it.position }
            ?.associateBy { it.position } ?: emptyMap()
    }

    (1..maxItems).chunked(listingLayoutsChunk).forEach { rowPositions ->
        Row(
            horizontalArrangement = Arrangement.Start
        ) {
            rowPositions.forEach { position ->
                when (val model = positionMap[position]) {
                    null -> {
                        Box(
                            modifier = Modifier
                                .margin(Dimen.semiSemiNormalPadding)
                                .size(Dimen.mobileWaiterTilesTypeItemSize)
                                .border(
                                    width = Dimen.smallThickness,
                                    style = LineStyle.Solid,
                                    color = Theme.GrayAlpha12.rgb
                                )
                                .backgroundColor(Theme.GrayAlpha02.rgb)
                                .borderRadius(Dimen.normalBorderRadius)
                                .onMouseOver {
                                    if (productId.value.isNotEmpty()){
                                        setGridProduct(
                                            productId.value,
                                            position,
                                        )
                                    }
                                }
                                .onMouseLeave {
                                    productId.value=""
                                }
                        )
                    }
                    else -> {
                        MobileWaiterTilesTypeItem(
                            model = model,
                            currencyType = currencyType,
                            showImgUrl = { key: String ->
                                showImgUrl(key)
                            },
                            deleteGridProduct = {
                                deleteGridProduct(
                                    model.position,
                                )

                            }
                        )
                    }
                }
            }
        }
    }
}

@Composable
private fun MobileWaiterListLayout(
    productId:MutableState<String>,
    maxItems: Int,
    selectedCategory: GetCategory?,
    currencyType: CurrencyTypeEnum,
    showImgUrl: suspend (String) -> String,
    deleteGridProduct: (Int) -> Unit,
    setGridProduct:(String,Int)->Unit,
) {

    Column(
        modifier = Modifier
            .padding(Dimen.smallPadding)
    )
    {
        (1..maxItems).forEach { position ->
            val matchingModel = selectedCategory?.gridProducts
                ?.sortedBy { it.position }
                ?.find { it.position == position }

            if (matchingModel != null) {

                Box(
                    modifier = Modifier
                        .draggable(true)
                ){
                    MobileWaiterListTypeItem(
                        model = matchingModel,
                        currencyType = currencyType,
                        showImgUrl = { key: String ->
                            showImgUrl(key)
                        },
                        deleteGridProduct = {
                            deleteGridProduct(
                                matchingModel.position,
                            )

                        }
                    )
                }

            } else {
                Box(
                    modifier = Modifier
                        .border(
                            width = Dimen.smallThickness,
                            color = Theme.GrayAlpha12.rgb,
                            style = LineStyle.Solid,
                        )
                        .borderRadius(Dimen.normalBorderRadius)
                        .backgroundColor(Theme.GrayAlpha02.rgb)
                        .margin(
                            topBottom = Dimen.semiSemiNormalPadding,
                        )
                        .width(Dimen.mobileWaiterListTypeItemWidth)
                        .height(Dimen.mobileWaiterListTypeItemHeight)
                        .onMouseOver {
                            if (productId.value.isNotEmpty()){
                                setGridProduct(
                                    productId.value,
                                    position,
                                )
                            }
                        }
                        .onMouseLeave {
                            productId.value=""
                        }
                )
            }
        }
    }
}
