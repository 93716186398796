package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_restaurant

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location.PrinterLocationModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_restaurant.PrinterLocationNamesRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class PrinterLocationNamesRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): PrinterLocationNamesRepository {


    override suspend fun getPrinterLocationsName(): PrinterLocationModel {


        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
            query getPrinterLocationsNames {
              cashAssist {
                getAppConfig {
                  cashRegisterGlobal {
                    printerLocations {
                      ${ApiUtils.Field.dbIndex}
                      ${ApiUtils.Field.value}
                      ${ApiUtils.Field.__typename}
                    }
                     ${ApiUtils.Field.__typename}
                  }
                   ${ApiUtils.Field.__typename}
                }
                 ${ApiUtils.Field.__typename}
              }
            }
        """.trimIndent())

            putJsonObject("variables") {}
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<PrinterLocationModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }
    }

}

