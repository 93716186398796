package org.hs_soft.runmynesto.pages.home.sub_page.product

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.GridProductBar
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.ProductContent
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.ProductDialogs
import org.hs_soft.runmynesto.pages.home.sub_page.product.mvi.ProductEvent
import org.hs_soft.runmynesto.pages.home.sub_page.product.mvi.ProductState
import org.hs_soft.runmynesto.pages.home.sub_page.product.mvi.ProductViewModel

@Composable
fun Product(
    windowSizeMode: WindowSizeModeEnum,
    viewModel: ProductViewModel,
)
{
    val state by viewModel.state.collectAsState()

    LaunchedEffect(viewModel){
        viewModel.onEvent(ProductEvent.GetProductsCategoryList)
        viewModel.onEvent(ProductEvent.GetCompanyProfileId)
        viewModel.onEvent(ProductEvent.GetCheckoutData)
    }

    Column(
        modifier = Modifier
            .scrollBehavior(ScrollBehavior.Smooth)
            .fillMaxSize()
            .padding(
                topBottom = Dimen.normalPadding,
                leftRight = Dimen.largePadding,
            )
    )
    {

        Row(
            modifier = Modifier
                .fillMaxHeight()
                .fillMaxWidth()
                .margin(top = Dimen.smallPadding),
                horizontalArrangement =
                    if (windowSizeMode==WindowSizeModeEnum.PHONE)
                        Arrangement.Center
                    else Arrangement.Start
        )
        {
            if (state.firstPageActive) {
                GridProductBar(
                    windowSizeMode=windowSizeMode,
                    selectedItem = state.selectedProduct,
                    searchValue = state.mainGridSearchValue,
                    infoList =state.productsInfo ,
                    titleDescending = state.isMainGridNameSortedAsc,
                    numberDescending = state.isMainGridNumberSortedAsc,
                    showNumberSortIcon = state.showMainGridNumberSortIcon,
                    showTitleSortIcon = state.showMainGridNameSortIcon,
                    showFilterComponent = state.showItemsFilterComponent,
                    selectedFilterOption=state.selectedFilterItemsType,
                    onSearchValueChange = {
                        viewModel.onEvent(ProductEvent.OnSearchProductInputChange(it))
                    },
                    onProductClick = { id ->
                        viewModel.onEvent(
                            ProductEvent.OnProductItemClick(
                                id = id,
                                switchTheSecondPage = {
                                    if (windowSizeMode == WindowSizeModeEnum.PHONE)
                                        viewModel.onEvent(ProductEvent.OnChangePageClick)
                                }
                            )
                        )
                    },
                    onAddRecord = {
                        viewModel.onEvent(ProductEvent.OnAddRecordIconClick)
                    },
                    onTitleSortClick = {
                        viewModel.onEvent(ProductEvent.OnMainGridTitleSortClick)
                    },
                    onNumberSortClick = {
                        viewModel.onEvent(ProductEvent.OnMainGridNumberSortClick)
                    },
                    refreshList = {
                        viewModel.onEvent(ProductEvent.RefreshProductsCategoryList)
                    },
                    onSwipeRight = {
                        viewModel.onEvent(ProductEvent.OnChangePageClick)
                    },
                    onFilterComponentClick = {
                        viewModel.onEvent(
                            ProductEvent.SetFilterItemsComponentStatus(
                            !state.showItemsFilterComponent)
                        )
                    },
                    onCloseFilterClick = {
                        viewModel.onEvent(ProductEvent.SetFilterItemsComponentStatus(false))
                    },
                    selectFilterType = {
                        viewModel.onEvent(ProductEvent.SelectFilterType(it))
                    },
                    submitFilterItems = {
                        viewModel.onEvent(ProductEvent.SubmitFilterItems)
                    }
                )

                if (windowSizeMode != WindowSizeModeEnum.PHONE) {
                    content(
                        windowSizeMode=windowSizeMode,
                        state=state,
                        event=viewModel::onEvent
                    )

                }

            } else {

                 content(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=viewModel::onEvent,
                    onSwipeLeft = {
                        viewModel.onEvent(ProductEvent.OnChangePageClick)
                    }
                )


            }

        }



    }

    LoadingProgress(
        visibility = state.dataLoadingProgressVisible
    )

    ProductDialogs(
        state=state,
        windowSizeMode=windowSizeMode,
        event=viewModel::onEvent
    )





}


@Composable
private fun content(
    state: ProductState,
    windowSizeMode: WindowSizeModeEnum,
    event: (ProductEvent) -> Unit,
    onSwipeLeft: () -> Unit={}
) {
    ProductContent(
        ingredients = state.ingredients,
        selectedProduct = state.selectedProduct,
        windowSizeMode = windowSizeMode,
        salesGroup = state.productSalesGroup,
        properties = state.properties,
        selectedTab = state.selectedTab,
        checkoutSearchValue = state.checkOutSearchValue,
        checkOutData = state.cashList,
        printerLocation = state.printersLocation,
        isCheckoutExpandAll = state.checkOutExpandAll,
        restaurantData = state.restaurantData,
        selectedSaleGroupId = state.selectedSaleGroupId,
        profileCountryId = state.companyProfileId,
        checkedIngredients = state.checkedIngredients,
        selectedVat = state.selectedVat,
        selectedVat2 = state.selectedVat2,
        showNumberSortIcon = state.showAllergensNumberSortIcon,
        tempItemsDescendingSort = state.restaurantTempItemsDescendingSort,
        showNameSortIcon = state.showAllergensNameSortIcon,
        nameAscending = state.isAllergensNameSortedAsc,
        numberAscending = state.isAllergensNumberSortedAsc,
        kitchenInfo = state.kitchenInfo,
        getPrinterLocations = {
            event(ProductEvent.GetPrinterLocationNames)
        },
        getRestaurantData = {
            event(ProductEvent.GetRestaurantData)
        },
        updateActivation = {
            event(
                ProductEvent.UpdateProductInfo(
                    title = ApiUtils.Field.isActive,
                    value = it
                )
            )
        },
        updateCodeValue = {
            event(
                ProductEvent.UpdateProductInfo(
                    title = ApiUtils.Field.code,
                    value = it
                )
            )
        },
        onTabClick = {
            event(ProductEvent.OnTabClick(it))
        },
        updateReceiptName = {
            event(
                ProductEvent.UpdateProductInfo(
                    title = ApiUtils.Field.name,
                    value = it
                )
            )
        },
        updateNoDiscountPossible = {
            event(
                ProductEvent.UpdateProductInfo(
                    title = ApiUtils.Field.isNetto,
                    value = it
                )
            )
        },
        updateEmployeeNetItems = {
            event(
                ProductEvent.UpdateProductInfo(
                    title = ApiUtils.Field.isNettoForUser,
                    value = it
                )
            )
        },
        updateEmployeeConsumption = {
            event(
                ProductEvent.UpdateProductInfo(
                    title = ApiUtils.Field.isEmployeeArticle,
                    value = it
                )
            )
        },
        updateProductCheckout = {
            event(ProductEvent.UpdateCheckout(it))
        },
        onCheckoutSearch = {
            event(ProductEvent.OnSearchCheckoutItems(it))
        },
        updateClickAndCollect = { minOrder, maxOrder, timeLeadDays ->
            event(
                ProductEvent.UpdateProductProperties(
                    title = ApiUtils.Field.clickCollectLeadDaysCount,
                    minOrder = minOrder,
                    maxOrder = maxOrder,
                    timeLeadDays = timeLeadDays,
                )
            )
        },
        updatePrintOnlyInCafe = {
            event(
                ProductEvent.UpdateRestaurantData(
                    title = ApiUtils.Field.isPrintOnlyInCafe,
                    value = it
                )
            )
        },
        updateIsAdditionRequired = {
            event(
                ProductEvent.UpdateRestaurantData(
                    title = ApiUtils.Field.isAdditionRequired,
                    value = it
                )
            )
        },
        updatePrinterLocation = {
            event(
                ProductEvent.UpdateRestaurantData(
                    title = ApiUtils.Field.printerLocations,
                    value = it
                )
            )
        },
        getSalesGroupData = {
            event(ProductEvent.GetProductSalesGroup)
        },
        updateSaleGroup = {
            event(
                ProductEvent.UpdateProductInfo(
                    ApiUtils.Field.salesGroupId,
                    it
                )
            )
        },
        updateVatCode = {
            event(
                ProductEvent.UpdateProductInfo(
                    title = ApiUtils.Field.codeMWSt,
                    value = it
                )
            )
        },
        updateVatCode2 = {
            event(
                ProductEvent.UpdateProductInfo(
                    title = ApiUtils.Field.codeMWSt2,
                    value = it
                )
            )
        },
        updatePrice = {
            event(
                ProductEvent.UpdateProductPrice(
                    title = ApiUtils.Field.price,
                    value = it
                )
            )
        },
        updatePriceInCafe = {
            event(
                ProductEvent.UpdateProductPrice(
                    title = ApiUtils.Field.priceInCafe,
                    value = it
                )
            )
        },
        getIngredients = {
            event(ProductEvent.GetIngredients)
        },
        onSearchInIngredients = {
            event(ProductEvent.OnSearchIngredientItems(it))
        },
        onNameSortClick = {
            event(ProductEvent.OnIngredientsNameHeaderClick)
        },
        onNumberSortClick = {
            event(ProductEvent.OnIngredientsNumberHeaderClick)
        },
        onSwipeLeft = {
            onSwipeLeft()
        },
        showImgAIGeneratorDialog = {
            event(ProductEvent.SetGenerateCashBidAIImgDialogStatus(true))
        },
        updateAllergenContent = {content,ingredientId->
            event(ProductEvent.UpdateAllergenContent(
                content = content,
                ingredientId = ingredientId,
            ))
        },
        getCheckedIngredients = {
            event(ProductEvent.GetCheckedIngredients)
        },
        getKitchenInfo = {
            event(ProductEvent.GetKitchenInfo)
        },
        getImgUrl = { imageUrl: MutableState<String>, key:String->
            event(ProductEvent.GetCachedBidImg(imageUrl,key))
        },
        onKitchenInfoTitleSortClick = {
            event(ProductEvent.OnKitchenInfoTitleClick)
        },
        onSearchKitchenInfo = {
            event(ProductEvent.OnSearchKitchenInfo(it))
        },
        onRefreshKitchenInfoClick = {
            event(ProductEvent.OnRefreshKitchenInfoClick)
        },
        onAddClick = {
            event(ProductEvent.OnAddKitchenItemIconClick)
        },
        onKitchenInfoItemClick = {
            event(ProductEvent.OnKitchenInfoItemClick(it))
        },
        activeKitchenInfo = {
            event(ProductEvent.UpdateRestaurantData(
                title = ApiUtils.Field.additions,
                value = listOf(mapOf(ApiUtils.Field.productAddionId to it))
            ))
        },
        deActiveKitchenInfo = {
            event(ProductEvent.UpdateRestaurantData(
                title = ApiUtils.Field.additions,
                value = listOf(mapOf(ApiUtils.Field.id to it))
            ))
        }

    )
}