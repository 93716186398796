package org.hs_soft.runmynesto.domain.model.home.statistic

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.TitleEnum

enum class StatisticHeaderEnum(override val title: String) : TitleEnum {

    ABC_STATISTIC(
        title = Strings.abcStatistic.uppercase()
    ),
    BILL_SEARCH(
        title=Strings.billSearch.uppercase()
    ),
}