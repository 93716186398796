package org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.series

import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.series.create_customer_card.CreateCustomerCardModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.series.CreateCustomerCardRepository



class CreateCustomerCardUseCase(
    private val repository: CreateCustomerCardRepository
)
{
    suspend operator fun invoke(
        type:Int,
        from:Int,
        to:Int,
    ): CreateCustomerCardModel {
        return repository.createCustomerCard(
            type=type,
            from=from,
            to=to,
        )
    }

}