package org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.billPdfModel


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("getBillPdf")
    val getBillPdf: GetBillPdf?,
    @SerialName("__typename")
    val typename: String?
)