package org.hs_soft.runmynesto.domain.usecase.customer_card.tabs.series

import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.series.update_range_customer_card.UpdateRangeLoyaltyCardModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.customer_card.tabs.series.UpdateRangeLoyaltyCardRepository

class UpdateRangeLoyaltyCardUseCase(
    private val repository:UpdateRangeLoyaltyCardRepository
) {
    suspend operator fun invoke(
        balance:Int?=null,
        from:Int,
        to:Int,
        description:String?=null,
        name:String?=null,
    ):UpdateRangeLoyaltyCardModel
    {
        return repository.updateRangeLoyaltyCard(
            balance=balance,
            from=from,
            to=to,
            description=description,
            name = name
        )
    }
}